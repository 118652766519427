import React from 'react';

function Icon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.33329 12.25H11.6666C12.311 12.25 12.8333 11.7277 12.8333 11.0833V5.01667C12.8333 4.37233 12.311 3.85 11.6666 3.85H9.91663L8.45829 1.75H5.54163L4.08329 3.85H2.33329C1.68896 3.85 1.16663 4.37233 1.16663 5.01667V11.0833C1.16663 11.7277 1.68896 12.25 2.33329 12.25Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99996 9.91667C8.28862 9.91667 9.33329 8.872 9.33329 7.58333C9.33329 6.29467 8.28862 5.25 6.99996 5.25C5.71129 5.25 4.66663 6.29467 4.66663 7.58333C4.66663 8.872 5.71129 9.91667 6.99996 9.91667Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
