import React from 'react';

function Icon() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.31689 10.3275V2.5H11.3353L18.2083 9.20933C18.8409 9.82683 18.8409 10.8282 18.2083 11.4458L12.4808 17.0368C11.8482 17.6544 10.8224 17.6544 10.1899 17.0368L3.31689 10.3275Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74487 5.84674L6.73145 5.83331"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
