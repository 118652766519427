import React, {useMemo} from 'react';
import {
  SalesforceLead,
  SalesforceLeadField,
  useUpdateSalesforceLeadCheckboxFieldMutation,
} from 'api';

interface Props {
  field: SalesforceLeadField;
  salesforceLead: SalesforceLead;
}

export const FieldValue: React.FC<Props> = ({field, salesforceLead}) => {
  const [updateSalesforceLeadCheckboxField] =
    useUpdateSalesforceLeadCheckboxFieldMutation();

  const Value = useMemo(() => {
    const value = salesforceLead.addition[`${field.fieldName}`];

    if (field.fieldType === 'checkbox') {
      return (
        <input
          className="cursor-pointer"
          type="checkbox"
          name={field.label}
          checked={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateSalesforceLeadCheckboxField({
              variables: {
                id: salesforceLead.id,
                attributes: {
                  fieldName: field?.fieldName,
                  value: e.target.checked,
                },
              },
            });
          }}
        />
      );
    } else if (field.fieldType === 'address') {
      const hash = value;
      if (!hash) {
        return null;
      }
      const address = `${hash?.state}${hash?.city}${hash?.street}`;
      return <div>{address}</div>;
    } else {
      return <div>{value}</div>;
    }
  }, [field, salesforceLead.addition]);

  return (
    <div className="flex align-middle mb-4 px-4 text-foreground">
      <div className="w-2/5 text-sm ">{field.label}</div>
      <div className="w-3/5">{Value}</div>
    </div>
  );
};
