import React from 'react';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input, Button} from 'components/antd';
import Tab from '../Tab';
import {useCurrentClientQuery, Team} from 'api';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('必須項目です'),
});

interface Params {
  team?: Team;
  onSubmit: (options?: any) => Promise<any>;
  loading: boolean;
}

export default (params: Params) => {
  const {team, onSubmit, loading} = params;
  const history = useHistory();
  const {teamId} = useParams<{teamId: string}>();
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: team?.name || '',
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      if (!team) {
        onSubmit({
          variables: {
            team: {...values, clientId: currentClient.uuid},
          },
          refetchQueries: ['teams'],
        });
      } else {
        onSubmit({
          variables: {
            uuid: teamId,
            team: values,
          },
        });
      }
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <FormContent>
      <Form
        labelCol={{span: 4}}
        wrapperCol={{span: 12}}
        onFinish={handleSubmit}>
        {!!team && <Tab />}
        <Form.Item
          label="名称"
          required
          validateStatus={errors.name && touched.name ? 'error' : ''}
          help={errors.name && touched.name ? errors.name : ''}>
          <Input
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <ButtonWrapper>
          <BackButton onClick={() => history.push('/settings/teams')}>
            戻る
          </BackButton>
          <SaveTeamButton
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={!isValid || !formik.dirty || loading}>
            保存する
          </SaveTeamButton>
        </ButtonWrapper>
      </Form>
    </FormContent>
  );
};

const FormContent = styled.div`
  .ant-row {
    flex-direction: column;
    margin-left: 10px;
  }
  .ant-col {
    margin-left: 10px;
    min-height: 30px;
  }

  .ant-input {
    width: 420px;
    height: 36px;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }

  .ant-form-item,
  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .ant-form-item-label {
    text-align: left;
    > label {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;

      color: var(--text-color-1);
      &::after {
        content: '';
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 420px;
  margin-left: 20px;
`;

const BackButton = styled(Button)`
  width: 80px;
  height: 39px;
  padding: 6px 13px;
  outline: 0;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  color: var(--text-color-2);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 0.15px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const SaveTeamButton = styled(Button)`
  width: 120px;
  height: 39px;
  padding: 6px 13px;
  border: none;
  outline: 0;
  background: var(--primary);
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.15px;

  &:hover {
    cursor: pointer;
  }
`;
