import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import StatusesFilter from './Statuses';
import MailStatusesFilter from './MailStatuses';
import TelStatusesFilter from './TelStatuses';
import useSearchParams from '../../useSearchParams';
import {useClientAllProjectsQuery} from 'api';

export const filterOptions = [
  {text: 'プレリード', value: 'client_prelead_status_id'},
  {text: 'メール', value: 'mail_status'},
  {text: 'TEL', value: 'client_tel_status_id'},
];

const filterComponents: {[key: string]: any} = {
  client_prelead_status_id: StatusesFilter,
  mail_status: MailStatusesFilter,
  client_tel_status_id: TelStatusesFilter,
};

const Filter = ({
  filter,
  onChange,
}: {
  filter: any;
  onChange: (key: string, value: any) => void;
}) => {
  if (!filter?.status_type) {
    return <BlankFilter />;
  }

  const FilterComponent = filterComponents[filter.status_type];
  return <FilterComponent filter={filter} onChange={onChange} />;
};

export default ({
  filter,
  filters,
  setFilters,
  index,
}: {
  filter: any;
  filters: any[];
  setFilters: React.Dispatch<React.SetStateAction<any[]>>;
  index: number;
}) => {
  const {query, setQuery} = useSearchParams();

  const {data: {clientAllProjects: {projects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      variables: {
        search: {
          status: 'open',
        },
      },
    });

  const availableOptions = React.useMemo(
    () =>
      filterOptions.filter((option) => {
        const values = filters
          ?.filter(
            (projectFilter: any) =>
              projectFilter?.project_id === filter?.project_id,
          )
          ?.map((projectFilter: any) => projectFilter?.status_type);

        return (
          option.value === filter?.status_type ||
          !values?.includes(option.value)
        );
      }),
    [filters],
  );

  const createNewFilter = React.useCallback(
    (key: string, value: any) => {
      const newFilter = Object.assign(filter || {});
      newFilter[key] = value;
      return newFilter;
    },
    [filter],
  );

  const onChange = (key: string, value: any) => {
    const newFilters = [...filters];
    const newFilter = createNewFilter(key, value);
    newFilters[index] = newFilter;

    setFilters(newFilters);

    // status_valuesが変更された時だけ、QueryStringに保存
    if (key === 'status_values') {
      setQuery({projectFilters: newFilters});
    }
  };

  const projectMode = () => {
    if (query.projectMode === '&') {
      return 'AND';
    } else if (query.projectMode === '|') {
      return 'OR';
    }
  };

  return (
    <Container>
      <div>
        {index === 0 && <span>Where</span>}
        {index === 1 && (
          <Select
            value={query.projectMode}
            onChange={(value: string) => setQuery({projectMode: value})}
            dropdownMatchSelectWidth>
            <Select.Option value="&" key="&" label="AND">
              AND
            </Select.Option>
            <Select.Option value="|" key="|" label="OR">
              OR
            </Select.Option>
          </Select>
        )}
        {index > 1 && <span>{projectMode()}</span>}
      </div>
      <Select
        value={filter?.project_id}
        onChange={(value) => onChange('project_id', value)}
        disabled={!query.projectMode && index > 0}
        placeholder="選択してください"
        dropdownMatchSelectWidth>
        {projects.map((project) => (
          <Select.Option
            value={project.id}
            key={project.id}
            label={project.name}>
            {project.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={filter?.status_type}
        onChange={(value) => {
          onChange('status_type', value);
          if (filter?.status_values) {
            onChange('status_values', null);
          }
        }}
        disabled={!filter?.project_id}
        placeholder="選択してください"
        dropdownMatchSelectWidth>
        {availableOptions.map((option) => (
          <Select.Option
            value={option.value}
            key={option.value}
            label={option.text}>
            {option.text}
          </Select.Option>
        ))}
      </Select>
      <Filter filter={filter} onChange={onChange} />
      <svg
        onClick={() => {
          if (filters.length == 2) {
            setQuery({projectMode: '&'});
          }
          const newFilters = [...filters];
          newFilters.splice(index, 1);
          setFilters(newFilters);
          setQuery({projectFilters: newFilters});
        }}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.916016 9.08366L9.08268 0.916992M0.916016 0.916992L9.08268 9.08366L0.916016 0.916992Z"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  font-size: 12px;
  align-items: flex-start;
  margin-bottom: 12px;

  > *:first-child {
    width: 75px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;

    span {
      color: var(--text-color-2);
    }

    div {
      flex: 1;
    }
  }

  > *:nth-child(2) {
    width: 280px;
    margin-right: 0.5rem;
  }

  > *:nth-child(3) {
    width: 138px;
    margin-right: 0.5rem;
  }

  > *:nth-child(4) {
    flex: 1;
  }

  > svg:last-child {
    height: 32px;
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;

const BlankFilter = styled.div`
  flex: 1;
  height: 32px;
  background: #f5f5f5;
  border-radius: 2px;
`;
