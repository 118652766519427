import styled from 'styled-components';

const Container = styled.div``;

const Category = styled.div`
  margin-bottom: 40px;
`;

const CategoryTitle = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color-3);

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 5px;
    right: 3px;
  }
`;

const ChildCategory = styled.div`
  margin-top: 15px;
`;

const ChildCategoryTitleA = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-3);
`;

const ChildCategoryTitleB = styled.span`
  display: inline-block;
  width: 150px;
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-3);
`;

const ChildCategoryItem = styled.span`
  font-size: 12px;
`;

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagItem = styled.span`
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: var(--text-color-1);
`;

export {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  ChildCategoryTitleB,
  ChildCategoryItem,
  TagBox,
  TagItem,
};
