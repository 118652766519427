import React from 'react';
import styled from 'styled-components';
import {ActivityAttachment} from 'api';
import {AudioFile} from 'components/Ui/Icon';
import byteToSize from 'helpers/byteToSize';

interface Props {
  attachment: ActivityAttachment;
}

export default ({attachment}: Props) => {
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (ref?.current) {
      const audio = ref.current as any;
      audio.volume = 0.2;
    }
  }, [ref]);

  return (
    <File>
      <div>
        <AudioFile />
        <div>
          <h3>
            <a href={attachment.file} target="_blank" download>
              {attachment.fileName}
            </a>
          </h3>
          <span>{byteToSize(attachment.fileSize)}</span>
        </div>
      </div>
      <audio controls ref={ref} src={attachment.file} />
    </File>
  );
};

const File = styled.div`
  border: 1px solid var(--border-color);
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 10px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    svg {
      width: 30px;
      height: auto;
      margin-right: 0.5rem;
    }

    h3 {
      margin: 0;
      line-height: 1.2;

      a {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    span {
      color: var(--text-color-2);
    }
  }

  audio {
    width: 100%;
  }
`;
