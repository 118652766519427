import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useAllProjectsAssignedCalledRateReportsQuery} from 'api';
import {useTable} from 'react-table';
import {Info} from 'components/Ui/Icon';
import {Tooltip} from 'components/antd';
import useSearchParams from '../useSearchParams';
import {Download} from 'components/Ui/Icon';
import DownloadModal from './AssignedCalledRateReportDownloadModal';
import {DownloadButton} from '../../style';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const {searchParams} = useSearchParams();

  const {data: {allProjectsAssignedCalledRateReports = []} = {}} =
    useAllProjectsAssignedCalledRateReportsQuery({
      variables: {
        search: searchParams,
      },
    });

  const totalCountPerStatus = (status: string) =>
    allProjectsAssignedCalledRateReports.reduce(
      (total, report: any) => total + report[status],
      0,
    );

  const totalCountRowData = useMemo(() => {
    const assigned = totalCountPerStatus('assigned');
    const called = totalCountPerStatus('called');
    const remained = totalCountPerStatus('remained');

    return {
      isTotalCountRowData: true,
      assigned: assigned,
      called: called,
      remained: remained,
      assignedCalledRate:
        assigned === 0 && called === 0 ? 0 : (called / assigned) * 100,
    };
  }, [allProjectsAssignedCalledRateReports]);

  const data: any = useMemo(
    () =>
      allProjectsAssignedCalledRateReports.map((report) => {
        return {
          firstName: report.firstName,
          lastName: report.lastName,
          assigned: report.assigned,
          called: report.called,
          remained: report.remained,
          assignedCalledRate: report.calledRate,
        };
      }),
    [allProjectsAssignedCalledRateReports],
  );

  data.unshift(totalCountRowData);

  const columns: any = useMemo(
    () => [
      {
        Header: '',
        id: 'userName',
        accessor: (row: any) =>
          row.isTotalCountRowData ? (
            <UserNameCell>
              <div>All</div>
            </UserNameCell>
          ) : (
            <UserNameCell>
              <div>{row.lastName + ' ' + row.firstName}</div>
            </UserNameCell>
          ),
      },
      {
        id: 'assigned',
        Header: (
          <AssignedContainer>
            <span style={{paddingRight: '5px', paddingLeft: '10px'}}>
              割り当て
            </span>
            <Tooltip
              placement="top"
              title={
                <span>
                  プレリード一覧の「割り当て」で担当者を入力すると数字が反映されます。
                </span>
              }>
              <span style={{paddingTop: '5px'}}>
                <Info />
              </span>
            </Tooltip>
          </AssignedContainer>
        ),
        accessor: (row: any) => <CountCell>{row.assigned}</CountCell>,
      },
      {
        id: 'called',
        Header: 'コール済',
        accessor: (row: any) => <CountCell>{row.called}</CountCell>,
      },
      {
        id: 'remained',
        Header: '残り件数',
        accessor: (row: any) => (
          <CountCell style={{color: '#EB5757'}}>{row.remained}</CountCell>
        ),
      },
      {
        id: 'assigned_called_rate',
        Header: '消化率',
        accessor: (row: any) => (
          <CountCell>{row.assignedCalledRate.toFixed(1)}%</CountCell>
        ),
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <>
      <Title>消化率</Title>
      <DownloadButton onClick={() => setModalOpen(true)}>
        <Download />
        ダウンロード
      </DownloadButton>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()} key={j}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </>
  );
};

const Title = styled.div`
  margin-bottom: 17px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: var(--text-color-1);
`;

const TableWrapper = styled.div`
  border: 1px solid #e1e6eb;
  width: 840px;
  height: 315px;
  overflow-y: scroll;
  position: relative;
  top: -40px;
`;

const Table = styled.table`
  width: 837px;
  border-bottom: 1px solid #e1e6eb;

  thead {
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 3;

    tr {
      th {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        width: 220px;
        font-weight: bold;
        font-size: 13px;

        &:first-child {
          width: 153px;
        }

        &:last-child {
          border-right: none;
        }

        svg {
          margin-right: 6px;
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        border-bottom: double 3px #e1e6eb;
        background: #ffffff;
        position: sticky;
        top: 45px;
        z-index: 2;

        td:first-child {
          background: #ffffff;
          position: sticky;
          top: 45px;
          z-index: 2;
        }
      }

      &:nth-child(n + 3) {
        border-top: 1px solid #e1e6eb;
      }

      td {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          left: 0;
          z-index: 1;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        font-weight: 500;
        font-size: 12px;
        color: var(--text-color-1);

        &:last-child {
          border-right: none;
        }
      }
    }
  }
`;

const CountCell = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-right: 10px;
`;

const UserNameCell = styled.div`
  margin-left: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;

  div:first-child {
    display: inline-block;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 13px;
    color: var(--text-color-1);
  }
`;

const AssignedContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;
