import React from 'react';
import {Form, useUpdateFormMutation} from 'api';
import ResetIcon from './ResetIcon';

interface Props {
  form: Form;
}

const upload_size_limit = 5000000;

const Img = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 21L16 10L21 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></rect>
    <circle
      cx="8.5"
      cy="8.5"
      r="1.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></circle>
  </svg>
);

export default ({form}: Props) => {
  const ref = React.useRef(null);
  const [updateForm] = useUpdateFormMutation({refetchQueries: ['form']});

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0) return;

      const file = e.target.files[0];

      if (file.size > upload_size_limit) {
        alert(
          'アップロードしようとしているファイルの容量が5MBを超えています。\n1ファイル5MBまでのアップロードが可能です。',
        );
        return null;
      }
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () =>
        updateForm({
          variables: {
            uuid: form.uuid,
            attributes: {
              formLogoFile: file,
              formLogoWidth: img.width,
            },
          },
        });
    },
    [],
  );

  const reset = () => {
    updateForm({
      variables: {
        uuid: form.uuid,
        attributes: {
          formLogoFile: null,
          formLogoWidth: null,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-1">
      <label className="tracking-wide font-medium text-base text-c-light">
        ロゴ画像
      </label>
      <div
        onClick={(e: any) => {
          e.target.value = null;
          ref.current?.click();
        }}
        className="cursor-pointer bg-[#E1E6EB] w-full min-h-[4rem] rounded flex items-center justify-center text-foreground-lighter">
        <input
          type="file"
          accept="image/*"
          ref={ref}
          className="hidden"
          onChange={onChange}
        />
        {form.formLogoFile ? (
          <img className="object-contain max-w-full" src={form.formLogoFile} />
        ) : (
          <Img />
        )}
      </div>
      {form.formLogoFile && (
        <div className="flex items-center justify-between ">
          {form.formLogoFileIdentifier}
          <div className="cursor-pointer" onClick={() => reset()}>
            <ResetIcon />
          </div>
        </div>
      )}{' '}
      <p className="text-c-light text-sm m-0">
        ※ ファイル形式はpng、gif、jpgです。 最大サイズは「5MB」
      </p>
    </div>
  );
};
