import React from 'react';
import {FormBlock, FormBlockSubmitButton} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockSubmitButton;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;

  const style = {
    color: blockable.textColor || '#FFFFFF',
    backgroundColor: blockable.buttonColor || 'var(--primary)',
  };

  return (
    <div className="flex justify-center">
      <button
        type="button"
        className="hover:opacity-90 focus:outline-none leading-none font-bold py-3 px-8 rounded cursor-pointer transition-all"
        style={style}>
        {blockable.buttonText}
      </button>
    </div>
  );
};
