import React from 'react';

export default () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 6.1965V1.5H6.1965L10.2221 5.5256C10.5927 5.8961 10.5927 6.4969 10.2221 6.86745L6.86745 10.2221C6.4969 10.5927 5.8961 10.5927 5.5256 10.2221L1.5 6.1965Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
