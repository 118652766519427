import React from 'react';
import Status from './Status';
import Priority from './Priority';
import Subject from './Subject';
import {Tab} from '@headlessui/react';

export default () => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const categories = ['ステータス', '優先度', '件名'];

  return (
    <div>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-sm p-1 bg-[#F3F5F7] w-[356px]">
          {categories.map((category, index) => (
            <Tab
              key={index}
              className={({selected}) =>
                classNames(
                  'w-44  py-2.5 text-sm font-medium leading-5 focus:outline-none',
                  selected
                    ? 'text-foreground bg-white'
                    : 'text-foreground-lighter bg-[#F3F5F7]',
                )
              }>
              <span>{category}</span>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel className={'bg-white p-3 focus:outline-none'}>
            <Status />
          </Tab.Panel>
          <Tab.Panel className={'bg-white p-3 focus:outline-none'}>
            <Priority />
          </Tab.Panel>
          <Tab.Panel className={'bg-white p-3 focus:outline-none'}>
            <Subject />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
