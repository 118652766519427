import {Button} from 'components/antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import useSearchParams from './useSearchParams';
import Filter from './Filter';

export default () => {
  const {query, setQuery} = useSearchParams();

  const onClickReset = React.useCallback(() => {
    setQuery({
      projectGroupIds: [],
      projectIds: [],
      requestClientId: null,
      offeredProjectIds: [],
      userIds: [],
      date: moment().format('YYYY-MM-DD'),
    });
  }, []);

  return (
    <Container>
      <div className="flex">
        <h2>Filters</h2>
        <ButtonStyled onClick={onClickReset}>All clear</ButtonStyled>
      </div>
      <Filter />
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  .flex {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: var(--text-color-2);
  }
`;

const ButtonStyled = styled(Button)`
  color: var(--primary);
  font-weight: bold;
  border: none;
  :hover {
    color: #2693ff;
  }
`;
