import React from 'react';
import styled from 'styled-components';
import linkifyHtml from 'linkify-html';
import {useMailCampaignQuery} from 'api';
import {Quill as ReactQuill} from 'components/Ui';
import {useParams} from 'react-router-dom';
import Code from './Code';

interface Props {
  value: string;
  onChange: (value: string) => void;
  modules: any;
}

export default ({value, onChange, modules}: Props) => {
  const ref = React.useRef(null);

  const {mailCampaignId} = useParams<{mailCampaignId: string}>();

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <Container>
      <ReactQuill
        theme="snow"
        ref={ref}
        defaultValue={value}
        placeholder="本文を記入してください"
        onChange={onChange}
        modules={modules}
        bounds={'#quill-container'}
        onBlur={() => onChange(convertUrlToLink(value))}
        formats={[
          'link',
          'bold',
          'italic',
          'underline',
          'size',
          'align',
          'color',
          'header',
          'background',
        ]}
      />
      <div id="quill-container" />
      <Actions>
        <Code
          mailCampaign={mailCampaign}
          onSelect={(value: string) => {
            if (ref.current?.editor.getSelection()) {
              ref.current?.editor.insertText(
                ref.current?.editor.getSelection().index,
                value,
              );
            }
          }}
        />
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  padding: 8px 8px 40px 8px;
  border: solid 1px #e1e6eb;
  position: relative;
  border-radius: 4px;
  z-index: 5;

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }

  .ql-snow .ql-picker.ql-size {
    width: 50px;

    .ql-picker-label {
      padding-left: 0;
    }

    .ql-picker-options {
      width: 50px;
    }
  }

  .ql-snow .ql-picker.ql-header {
    width: 90px;
  }

  .ql-container.ql-snow {
    min-height: 150px;
    border: none;

    .ql-editor {
      min-height: 200px;
      overflow-y: scroll;
      resize: vertical;
      padding: 0;
      font-family:
        monospace,
        -apple-system,
        sans-serif;

      &::before {
        left: 0;
      }
    }
  }

  .ql-toolbar {
    position: absolute;
    bottom: 40px;
    width: 100%;
    transform: translateY(100%);
    border: none;
    background: transparent;
  }

  #quill-container {
    /* reset */
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
    font-family:
      monospace,
      -apple-system,
      sans-serif;

    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    input,
    textarea,
    p,
    blockquote,
    th,
    td {
      margin: 0;
      padding: 0;
    }

    table {
      width: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      border-collapse: collapse;
      border-spacing: 0;
    }

    img {
      border: 0 none;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 100%;
      font-weight: normal;
    }

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.17em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: 0.83em;
    }

    h6 {
      font-size: 0.67em;
    }

    a {
      text-decoration: underline;
    }

    a img {
      border: 0 none;
    }

    .ql-align-center {
      text-align: center;
    }

    .ql-align-right {
      text-align: right;
    }
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  gap: 8px;
`;
