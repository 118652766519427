import React from 'react';
import CheckboxGroup from './CheckboxGroup';
import {
  useLeadSourcesQuery,
  LeadSource,
  useProspectScoreSettingQuery,
  useCreateClientProspectScoreSettingLeadSourceMutation,
  useDestroyClientProspectScoreSettingLeadSourceMutation,
} from 'api';

interface Props {
  priority: number;
}

export default ({priority}: Props) => {
  const [create] = useCreateClientProspectScoreSettingLeadSourceMutation({});
  const [destroy] = useDestroyClientProspectScoreSettingLeadSourceMutation({});

  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );

  const optionProspectTags = [
    ...leadSources.map((leadSource: LeadSource) => ({
      text: leadSource.name,
      value: leadSource.id,
      keyword: leadSource.name,
    })),
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    const variables = {
      priority,
      leadSourceId: value,
    };
    if (checked) {
      create({
        variables,
      });
    } else {
      destroy({
        variables,
      });
    }
  };

  return (
    <CheckboxGroup
      filter
      options={optionProspectTags}
      values={prospectScoreSetting?.leadSourcesGroupByPriority?.[priority]?.map(
        (leadSource: LeadSource) => String(leadSource.id),
      )}
      allSelected={prospectScoreSetting?.leadSourceIds}
      onChange={handleChange}
    />
  );
};
