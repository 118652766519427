import {Project, useProjectQuery} from 'api';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const projectType = (project: Project) =>
    project?.projectSearchCondition?.company?.name
      ? '逆引き検索'
      : project.projectType === 'search'
      ? 'DB'
      : project.projectType === 'upload'
      ? 'CSV'
      : project.projectType === 'prelead_master'
      ? 'プレリードマスター'
      : project.projectType === 'cold_lead' && 'コールドリード';

  if (!project) return null;

  return (
    <Container>
      <TitleBox>
        <Title>営業リスト情報</Title>
      </TitleBox>

      <Body>
        {project.projectType && (
          <ProjectType>{projectType(project)}</ProjectType>
        )}
        <InformationBox>
          <div>
            <Label>作成日</Label>
            <Value>{moment(project.createdAt).format('YYYY年MM月DD日')}</Value>
          </div>
          <div>
            <Label>更新日</Label>
            <Value>{moment(project.updatedAt).format('YYYY年MM月DD日')}</Value>
          </div>
          <div>
            <Label>リスト件数</Label>
            <Value>{project.count.toLocaleString()}件</Value>
          </div>
        </InformationBox>
      </Body>
    </Container>
  );
};

const Container = styled.div``;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: var(--text-color-3);
`;

const Body = styled.div`
  margin-top: 13px;
`;

const ProjectType = styled.span`
  padding: 2px 9px;
  background: #ffffff;
  border: 1px solid var(--text-color-2);
  border-radius: 100px;

  font-weight: bold;
  font-size: 10px;
  color: var(--text-color-2);
`;

const InformationBox = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 165px;

  > div {
    margin-top: 5px;
    display: flex;
  }
`;

const Label = styled.span`
  height: 14px;
  font-size: 10px;
  line-height: 14px;
  color: var(--text-color-1);
`;

const Value = styled.span`
  margin-left: auto;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: var(--text-color-1);
`;
