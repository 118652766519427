import React from 'react';
import styled from 'styled-components';
import {useCompanyQuery} from 'api';
import moment from 'moment';

type Props = {
  companyId: string;
};

export default ({companyId}: Props) => {
  const {data: {company: {pressReleases = []} = {}} = {}} = useCompanyQuery({
    variables: {uuid: companyId},
    skip: !companyId,
  });

  return (
    <Container>
      {pressReleases?.length > 0 && <Title>プレスリリース</Title>}

      {pressReleases?.slice(0, 10).map((pressRelease) => (
        <NewsItem key={pressRelease.id}>
          <NewsTitle href={pressRelease.url} target="_blank">
            {pressRelease.title}
          </NewsTitle>
          <Date>{moment(pressRelease.date).format('YYYY年MM月DD日')}掲載</Date>
        </NewsItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-bottom: 15px;
  color: var(--text-color-3);
  font-weight: bold;
`;

const NewsItem = styled.div`
  margin-bottom: 28px;
`;

const NewsTitle = styled.a`
  display: block;
  line-height: 17px;
  color: #333333;
`;

const Date = styled.div`
  font-size: 10px;
  color: var(--text-color-3);
`;
