import {Account, useUpdateAccountMutation} from 'api';
import React, {useEffect, useRef, useState} from 'react';

interface Props {
  account: Account;
}

export default ({account}: Props) => {
  const [updateAccount] = useUpdateAccountMutation({
    refetchQueries: ['activitiesByProspectPool'],
  });
  const [webSite, setWebSite] = useState(account.webSite);
  const ref = useRef(null);

  useEffect(() => {
    ref.current.value = account.webSite;
  }, [account]);

  return (
    <input
      defaultValue={webSite}
      className="w-60 h-7 rounded hover:border hover:border-border focus:border focus:border-border"
      onChange={(e) => setWebSite(e.target.value)}
      onBlur={(e) =>
        updateAccount({
          variables: {
            id: account.id,
            attributes: {
              webSite: e.target.value,
            },
          },
        })
      }
      ref={ref}
    />
  );
};
