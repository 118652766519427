import React from 'react';
import styled from 'styled-components';
import {Content} from 'api';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const [pdf, setPdf] = React.useState(null);
  const [page, setPage] = React.useState(1);

  const onLoadSuccess = async (pdf: any) => {
    setPdf(pdf);
  };

  return (
    <Container>
      <Pages>
        <Thumbnails>
          <Document
            file={content?.telScript?.file}
            onLoadSuccess={onLoadSuccess}
            loading="">
            {pdf &&
              [...Array(pdf.numPages)].map((_, index) => (
                <Thumbnail
                  key={index}
                  active={index + 1 === page}
                  onClick={() => setPage(index + 1)}>
                  <span>{index + 1}</span>
                  <Page
                    pageNumber={index + 1}
                    width={900}
                    scale={0.1}
                    loading=""
                  />
                </Thumbnail>
              ))}
          </Document>
        </Thumbnails>
      </Pages>
      <Content>
        <Document file={content?.telScript?.file}>
          <Page pageNumber={page} width={600} />
        </Document>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-height: 600px;
  max-height: calc(100vh - 270px);
  display: flex;
`;

const Pages = styled.div`
  width: 150px;
  border-right: solid 1px var(--border-color);
  overflow: scroll;

  > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;

    .react-loading-skeleton {
      margin: 10px auto;
    }
  }
`;

const Thumbnails = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
`;

const Thumbnail = styled.div<{active?: boolean}>`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: solid 2px ${({active}) => (active ? 'var(--primary)' : 'transparent')};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border: solid 2px var(--primary);
  }

  > span {
    margin-right: 5px;
    font-size: 12px;
    color: var(--text-color-2);
  }

  > .react-pdf__Page {
    width: 90px;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 1px 0px;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-height: 100%;
    width: 100%;
    overflow: auto;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      canvas {
        max-width: 100%;
        height: auto !important;
        margin: 2rem 1rem;
        box-shadow: rgba(0, 0, 0, 0.3) 0 0 1px 0px;
      }
    }
  }
`;
