import React from 'react';
import styled from 'styled-components';
import preleadCategoryColor from 'helpers/preleadCategoryColor';
import preleadColor from 'helpers/preleadColor';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const StatusCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const color = preleadProject.clientPreleadStatus
    ? preleadCategoryColor(preleadProject.clientPreleadStatus.category)
    : preleadColor(preleadProject.status);

  return (
    <>
      <StatusMark color={color} />
      {preleadProject.clientPreleadStatus?.name || preleadProject.statusText}
    </>
  );
};

const StatusMark = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  margin-right: 5px;
  ${({color}) => !color && 'border: 1px solid var(--text-color-3)'};
`;

export default StatusCell;
