import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.1111 4H6.88889C5.2934 4 4 5.35878 4 7.03492V22.2095C4 23.8857 5.2934 25.2445 6.88889 25.2445H11.2222L16.4892 30.7777C16.7713 31.0741 17.2287 31.0741 17.5108 30.7777L22.7778 25.2445H27.1111C28.7066 25.2445 30 23.8857 30 22.2095V7.03492C30 5.35878 28.7066 4 27.1111 4Z"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_32468_187800)">
      <path
        d="M19.2082 10.625L17.5832 20.375M12.1665 13.875H20.8332H12.1665ZM12.1665 17.125H20.8332H12.1665ZM15.4165 10.625L13.7915 20.375L15.4165 10.625Z"
        stroke="var(--text-color-2)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_32468_187800">
        <rect width="13" height="13" fill="white" transform="translate(11 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
