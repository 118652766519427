import React from 'react';
import styled from 'styled-components';
import {useUpdateProjectGroupPositionMutation} from 'api';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {Link} from 'react-router-dom';
import {
  ArrowRight,
  OpenFolder,
  LockPrivary,
  GroupDimension,
  Handle,
} from 'components/Ui/Icon';
import {Image} from 'components/antd';
import useClientUser from 'hooks/useClientUser';
import useProjectGroups from 'hooks/useProjectGroups';

export default () => {
  const {isAdmin} = useClientUser();
  const [updateProjectGroupPosition] = useUpdateProjectGroupPositionMutation({
    refetchQueries: ['projectGroups'],
  });
  const {accessibleProjectGroups} = useProjectGroups();

  const [projectGroups, setProjectGroups] = React.useState([]);

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination === null) return;

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...projectGroups];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setProjectGroups(items);
      updateProjectGroupPosition({
        variables: {id: removed.id, position: destIndex},
      });
    },
    [projectGroups],
  );

  React.useEffect(() => {
    if (accessibleProjectGroups.length > 0)
      setProjectGroups(accessibleProjectGroups);
  }, [accessibleProjectGroups]);

  const StatusOpenProjectsCount = (projectGroup: any) => {
    return projectGroup.projects.filter(
      (project: any) => project.status === 'open',
    ).length;
  };

  if (isAdmin)
    return (
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableId">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {projectGroups.map((projectGroup, index) => (
                  <Draggable
                    key={projectGroup.id}
                    draggableId={projectGroup.id}
                    index={index}>
                    {(p, snapshot) => (
                      <DraggableItem
                        ref={p.innerRef}
                        {...p.draggableProps}
                        className={snapshot.isDragging ? 'dragging' : ''}>
                        <HandleWrapper {...p.dragHandleProps}>
                          <Handle />
                        </HandleWrapper>
                        <ImageBox>
                          {projectGroup?.imageUrl ? (
                            <Image src={projectGroup.imageUrl} />
                          ) : (
                            <GroupDimension />
                          )}
                        </ImageBox>
                        <ProjectGroupName
                          to={`/list/groups/${projectGroup.uuid}/basic_information`}>
                          {projectGroup.name}
                        </ProjectGroupName>
                        <LabelBox>
                          {projectGroup.isPrivate ? (
                            <Label backgroud="#FDECEC" color="#EB5757">
                              <LockPrivary />
                              <Space />
                              <span>Private</span>
                            </Label>
                          ) : (
                            <Label backgroud="#E5F8EC" color="#4FCE82">
                              <OpenFolder />
                              <Space />
                              <span>Public</span>
                            </Label>
                          )}
                          <ProjectLink
                            to={`/list/groups/${projectGroup.uuid}/projects`}>
                            <span>
                              {StatusOpenProjectsCount(projectGroup)} リスト
                            </span>
                            <Space />
                            <ArrowRight />
                          </ProjectLink>
                          <MemberLabel>
                            {projectGroup.users.length} members
                          </MemberLabel>
                        </LabelBox>
                      </DraggableItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    );

  return (
    <Container>
      {projectGroups.map((projectGroup) => (
        <Item key={projectGroup.id}>
          <ImageBox>
            {projectGroup?.imageUrl ? (
              <Image src={projectGroup.imageUrl} />
            ) : (
              <GroupDimension />
            )}
          </ImageBox>
          <ProjectGroupName
            to={`/list/groups/${projectGroup.uuid}/basic_information`}>
            {projectGroup.name}
          </ProjectGroupName>
          <LabelBox>
            {projectGroup.isPrivate ? (
              <Label backgroud="#FDECEC" color="#EB5757">
                <LockPrivary />
                <Space />
                <span>Private</span>
              </Label>
            ) : (
              <Label backgroud="#E5F8EC" color="#4FCE82">
                <OpenFolder />
                <Space />
                <span>Public</span>
              </Label>
            )}
            <ProjectLink to={`/list/groups/${projectGroup.uuid}/projects`}>
              <span>{StatusOpenProjectsCount(projectGroup)} Projects</span>
              <Space />
              <ArrowRight />
            </ProjectLink>
            <MemberLabel>{projectGroup.users.length} members</MemberLabel>
          </LabelBox>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const Item = styled.div`
  margin-bottom: 5px;
  padding: 9px 20px 9px 13px;
  display: flex;
  align-items: center;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
`;

const DraggableItem = styled(Item)`
  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const ImageBox = styled.div`
  margin-left: 14x;
  > * {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 42px;
    height: 42px;
  }
`;

const ProjectGroupName = styled(Link)`
  margin-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabelBox = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  > * {
    margin-left: 12px;
  }
`;

const Label = styled.div<{backgroud: string; color: string}>`
  display: flex;
  align-items: center;
  padding: 5px 17px;
  background: ${({backgroud}) => backgroud};
  color: ${({color}) => color};
  border-radius: 100px;

  > svg {
    path {
      stroke: ${({color}) => color};
    }
  }

  > span {
    font-weight: 500;
    font-size: 12px;
  }

  &:last-child {
    margin-left: 6px;
  }
`;

const ProjectLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px 0;
  background: #e3f2ff;
  color: var(--primary);
  border-radius: 100px;
  width: 115px;

  > svg {
    margin-right: auto;

    path {
      stroke: var(--primary);
    }
  }

  > span {
    font-weight: 500;
    font-size: 12px;
    margin-left: auto;
  }

  &:last-child {
    margin-left: 6px;
  }
`;

const MemberLabel = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: var(--text-color-1);
`;

const Space = styled.div`
  width: 6px;
`;
