import Menu from './Menu';
import Check from './Check';
import ProspectName from './ProspectName';
import Status from './Status';
import LatestLeadSource from './LatestLeadSource';
import LatestInflowDate from './LatestInflowDate';
import TodoDate from './TodoDate';
import TrackedDate from './TrackedDate';
import User from './User';
import SalesforceUser from './SalesforceUser';

export default {
  Check,
  Menu,
  ProspectName,
  Status,
  LatestLeadSource,
  LatestInflowDate,
  TodoDate,
  TrackedDate,
  User,
  SalesforceUser,
};
