import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useCurrentClientQuery} from 'api';
import preleadCategoryColor from 'helpers/preleadCategoryColor';

const StatusesFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.preleadStatusIds || []}
      onChange={(value) => setQuery({page: 1, preleadStatusIds: value})}>
      <StatusSelect.Option
        value={'none'}
        key={'none'}
        label={
          <React.Fragment>
            <StatusMark color={preleadCategoryColor('pending')} />
          </React.Fragment>
        }>
        <StatusMark color={preleadCategoryColor('pending')} />
        &nbsp; 未
      </StatusSelect.Option>
      {preleadStatuses.map((preleadStatus) => (
        <StatusSelect.Option
          value={preleadStatus.id}
          key={preleadStatus.id}
          label={
            <React.Fragment>
              <StatusMark
                color={preleadCategoryColor(preleadStatus.category)}
              />
            </React.Fragment>
          }>
          <StatusMark color={preleadCategoryColor(preleadStatus.category)} />
          &nbsp; {preleadStatus.name}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

const StatusMark = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid var(--text-color-3)'};
`;

export default StatusesFilter;
