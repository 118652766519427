import React from 'react';
import styled from 'styled-components';
import {StatusEclipse} from 'components/Ui';
import _ from 'lodash';
import {ArrowRight} from 'components/Ui/Icon';
import {useProjectResponseStatusReportQuery, ProjectResponseStatus} from 'api';

interface Props {
  mailCount: number;
  projectResponseStatusReport: ProjectResponseStatus;
}

export default ({mailCount, projectResponseStatusReport}: Props) => {
  const [isDisplay, setDisplay] = React.useState(false);

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );
    return value;
  };

  const calcurateRate = (clickCount: undefined | number) => {
    if (clickCount === undefined) return 0;
    return clickCount !== 0 && mailCount !== 0
      ? ((clickCount / mailCount) * 100).toFixed(1)
      : 0;
  };

  const category: any = {
    status: 'click',
    label: 'クリックあり',
    color: 'var(--text-color-3)',
    count:
      Object.keys(projectResponseStatusReport).length > 0
        ? totalCount(projectResponseStatusReport.clickCounts)
        : 0,
  };

  const clickCategory = [
    {label: 'クリック1回', value: 1},
    {label: 'クリック2回', value: 2},
    {label: 'クリック3回', value: 3},
    {label: 'クリック4回以上', value: 4},
  ];

  return (
    <Container>
      <h2>クリックステータス</h2>
      <Table>
        <div className="column far-left">
          <div className="category" />
          <div
            className="category openable"
            onClick={() => setDisplay(!isDisplay)}>
            <Open isDisplay={isDisplay}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={category.color} />
            <span>{category.label}</span>
          </div>
          {isDisplay &&
            clickCategory.map((category) => (
              <div key={category.value}>
                <span>{category.label}</span>
              </div>
            ))}
        </div>

        <div className="column">
          <div className="category title">
            <span>プレリード数</span>
          </div>
          <div className="category prelead">
            <span>{category.count}</span>
          </div>
          {isDisplay &&
            clickCategory.map((category) => (
              <div className="prelead" key={category.value}>
                <span>
                  {projectResponseStatusReport?.clickCounts[category.value] ||
                    0}
                </span>
              </div>
            ))}
        </div>

        <div className="column">
          <div className="category title">
            <span>対送信数</span>
          </div>
          <div className="category">
            <span>{calcurateRate(category.count)}%</span>
          </div>
          {isDisplay &&
            clickCategory.map((category) => (
              <div key={category.value}>
                <span>
                  {calcurateRate(
                    projectResponseStatusReport?.clickCounts[category.value],
                  )}
                  %
                </span>
              </div>
            ))}
        </div>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;

  > h2 {
    font-weight: bold;
    font-size: 20px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  width: 410px;

  .column {
    flex: 1;

    > div {
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;
      display: flex;
      align-items: center;

      > span {
        margin: 0 9px 0 auto;
        color: var(--text-color-2);
      }
      &.category > span {
        color: var(--text-color-1);
      }
      &.title > span {
        font-weight: bold;
        margin: 0 auto;
      }
      &.prelead > span {
        color: var(--primary);
      }
    }

    &:last-child > div {
      border-right: none;
    }

    &.far-left > div {
      width: 214px;
      padding: 0 40px;

      &.category {
        padding: 0 13px;

        svg {
          margin-right: 5px;
        }
        span {
          font-weight: bold;
        }
      }
      &.openable {
        cursor: pointer;
      }
      > span {
        margin: 0;
        font-size: 13px;
      }
    }
  }
`;

const Open = styled.div<{isDisplay: boolean}>`
  width: 13px;
  height: 13px;

  > svg {
    ${({isDisplay}) => isDisplay && 'transform: rotate(90deg);'}
    width: 13px;
    height: 13px;
    fill: var(--text-color-3);

    path {
      width: 13px;
      height: 13px;
    }
  }
`;
