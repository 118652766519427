import React from 'react';
import {
  ProspectCustomizeItemNumber,
  PoolProspectCustomizeItem,
  Prospect,
  useUpdateProspectCustomizeItemNumberMutation,
} from 'api';
import {Input as UIInput} from 'components/antd';
import styled from 'styled-components';

interface Props {
  prospect: Prospect;
  poolProspectCustomizeItem: PoolProspectCustomizeItem;
}

export default ({prospect, poolProspectCustomizeItem}: Props) => {
  const customizeItem = prospect.customizeItemNumbers.find(
    (item: ProspectCustomizeItemNumber) =>
      item.poolProspectCustomizeItemId === poolProspectCustomizeItem.id,
  );

  const [value, setValue] = React.useState(
    customizeItem ? Number(customizeItem.value) : null,
  );

  React.useEffect(() => {
    setValue(customizeItem ? Number(customizeItem.value) : null);
  }, [customizeItem]);

  const [updateCustomizeItem] = useUpdateProspectCustomizeItemNumberMutation(
    {},
  );

  const update = (e: any) => {
    updateCustomizeItem({
      variables: {
        uuid: prospect.uuid,
        value: value,
        poolProspectCustomizeItemId: poolProspectCustomizeItem.id,
      },
    });
  };

  return (
    <Input
      value={value}
      onChange={(e: any) => setValue(Number(e.target.value))}
      onBlur={update}
      onPressEnter={update}
      type="number"
      placeholder="未入力"
    />
  );
};

const Input = styled(UIInput)`
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--text-color-3);
  }
`;
