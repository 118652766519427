import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {Button, Checkbox} from 'components/antd';
import {
  useMainCategoriesQuery,
  MainCategory,
  useWorkflowQuery,
  useUpdateWorkflowMutation,
} from 'api';
import {Cross, Check} from 'components/Ui/Icon';
import BASE_CLASSIFICATIONS from 'helpers/classifications';
import _ from 'lodash';
import {useParams} from 'react-router-dom';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();

  const [updateWorkflow] = useUpdateWorkflowMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });

  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({});

  const {
    data: {
      workflow: {
        searchCondition: {
          subCategories: alreadySelectedCategories = [],
          cities,
        } = {},
        searchCondition: originalSearchCondition = {},
      } = {},
    } = {},
  } = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });

  const alreadySelectedCategoryIds = alreadySelectedCategories.map(
    (category) => category.id,
  );

  const classifications = useMemo(() => {
    return BASE_CLASSIFICATIONS(mainCategories);
  }, [mainCategories]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const [categoryIds, setCategoryIds] = useState(alreadySelectedCategoryIds);
  const [activeCategory, setActiveCategory] = useState(classifications[0].name);
  const [categoryPositions, setCategoryPositions] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    if (isModalVisible) {
      setCategoryIds(alreadySelectedCategoryIds);
      setActiveCategory(classifications[0].name);
    }
  }, [isModalVisible]);

  useLayoutEffect(() => {
    if (
      ref.current?.getElementsByTagName('section') &&
      categoryPositions.length === 0
    ) {
      const categoryPositions = Array.prototype.slice
        .call(ref.current?.getElementsByTagName('section'))
        .map((category: any) => ({
          id: category.id,
          top: category.offsetTop - ref.current?.offsetTop,
          bottom:
            category.offsetTop - ref.current?.offsetTop + category.clientHeight,
        }));
      setCategoryPositions(categoryPositions);
    }
  });

  const isSelected = (classification: {
    name: string;
    categories: Array<MainCategory>;
  }) =>
    []
      .concat(
        ...classification.categories.map((category) =>
          category.subCategories.map((subCategory) => subCategory.id),
        ),
      )
      .some((id) => categoryIds.includes(id));

  const handleScroll = () => {
    const activeSection = categoryPositions.find((position: any) => {
      const scroll = ref.current?.scrollTop + 5;
      return position.top <= scroll && position.bottom > scroll;
    });
    setActiveCategory(activeSection ? activeSection.id : activeCategory);
  };

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '830px',
      height: '95vh',
      padding: '25px 30px',
      borderRadius: '4px',
      overflow: 'hidden',
    },
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => closeModal()}
      style={customStyles}>
      <Header>
        <div>業種</div>
        <CrossIconWrapper onClick={() => closeModal()}>
          <Cross />
        </CrossIconWrapper>
      </Header>

      <Main>
        <SideMenu>
          {classifications.map((classification) => (
            <Classification
              key={classification.name}
              isSelected={isSelected(classification)}
              current={activeCategory === classification.name}
              onClick={() =>
                document.getElementById(classification.name).scrollIntoView()
              }>
              {classification.name}
              {isSelected(classification) && <Check />}
            </Classification>
          ))}
        </SideMenu>

        <Content onScroll={handleScroll} ref={ref}>
          {classifications.map((classification) => (
            <section key={classification.name} id={classification.name}>
              <Wrapper>
                <Square />
                <Title>{classification.name}</Title>
              </Wrapper>

              {classification.categories.map((category) => (
                <div key={category.id}>
                  <ParentCategorySection>
                    <CategoryCheckBox
                      checked={
                        _.difference(
                          category.subCategories.map(
                            (subCategory) => subCategory.id,
                          ),
                          categoryIds,
                        ).length === 0
                      }
                      onChange={(e) => {
                        const childCategoryIds = category.subCategories.map(
                          (subCategory) => subCategory.id,
                        );

                        if (e.target.checked) {
                          setCategoryIds(
                            _.union(categoryIds, childCategoryIds),
                          );
                        } else {
                          setCategoryIds(
                            _.difference(categoryIds, childCategoryIds),
                          );
                        }
                      }}>
                      {category.displayName} すべて
                    </CategoryCheckBox>
                  </ParentCategorySection>

                  <ChildCategorySection>
                    {category.subCategories.map((subCategory) => (
                      <ChildCategoryCheckBox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCategoryIds(
                              _.union(categoryIds, [subCategory.id]),
                            );
                          } else {
                            setCategoryIds(
                              _.difference(categoryIds, [subCategory.id]),
                            );
                          }
                        }}
                        key={subCategory.uuid}
                        checked={categoryIds.includes(subCategory.id)}>
                        {subCategory.displayName}
                      </ChildCategoryCheckBox>
                    ))}
                  </ChildCategorySection>
                </div>
              ))}
            </section>
          ))}
        </Content>
      </Main>

      <Action>
        <Button
          style={{width: '110px', borderRadius: '4px'}}
          type="primary"
          onClick={() => {
            const searchCondition = {...originalSearchCondition};
            delete searchCondition.__typename;
            updateWorkflow({
              variables: {
                uuid: workflowId,
                attributes: {
                  searchCondition: {
                    ...searchCondition,
                    subCategories: categoryIds,
                    cities: cities.map((city) => city.id),
                  },
                },
              },
            });
            closeModal();
          }}>
          適用
        </Button>
      </Action>
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  font-weight: bold;
  font-size: 18px;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -15px;
  right: -10px;
`;

const Main = styled.div`
  display: flex;
  gap: 40px;
  height: 87%;
`;

const SideMenu = styled.div`
  width: 250px;
  border-right: 1px solid var(--border-color);
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const Square = styled.div`
  background: var(--text-color-0);
  content: '';
  height: 18px;
  width: 4px;
  margin-right: 13px;
`;

const Content = styled.div`
  overflow-y: scroll;
  width: 480px;
`;

const ParentCategorySection = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e8eb;
`;

const CategoryCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;

  .ant-checkbox {
    top: 0em;
  }

  > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--text-color-0);
  }
`;

const ChildCategorySection = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  padding-bottom: 40px;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const ChildCategoryCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;
  line-height: 35px;

  .ant-checkbox {
    top: 0em;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
`;

const Classification = styled.div<{isSelected: boolean; current: boolean}>`
  cursor: pointer;
  background: ${({isSelected, current}) =>
    isSelected || current ? '#F3F5F7' : ''};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;

  svg {
    path {
      stroke: var(--primary);
    }
  }
`;
