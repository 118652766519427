import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import {Checkbox, DatePicker} from 'components/antd';
import useSearchCondition from '../useSearchCondition';
import MARKETS from 'helpers/markets';
import MONTHS from 'helpers/months';
import moment from 'moment';

const COMPANY_TYPES_1 = [
  {name: '株式会社', value: 'inc'},
  {name: '有限会社', value: 'limited'},
  {name: '合同会社', value: 'limitedliability'},
  {name: '合資会社', value: 'limitedpartnership'},
  {name: '合名会社', value: 'generalpartnership'},
];

const COMPANY_TYPES_2 = [
  {name: '弁護士法人', value: 'legalprofessional'},
  {name: '税理士法人', value: 'taxaccountant'},
  {name: '監査法人', value: 'audit'},
  {name: '特許業務法人', value: 'patentprofessional'},
  {name: '土地家屋調査士法人', value: 'landandhousesurveyor'},
  {name: '司法書士法人', value: 'judicialscrivener'},
  {name: '社会保険労務士法人', value: 'laborandsocialsecurityattorney'},
  {name: '行政書士法人', value: 'administrativescrivener'},
];

const COMPANY_TYPES_3 = [
  {name: '特定目的会社', value: 'specificpurpose'},
  {name: '一般社団法人', value: 'generalincorporatedassociation'},
  {name: '一般財団法人', value: 'generalincorporatedfoundation'},
  {name: '相互会社', value: 'mutual'},
];

const COMPANY_TYPES_4 = [
  {name: '医療法人', value: 'medical'},
  {name: '学校法人', value: 'school'},
];

const COMPANY_TYPES_5 = [{name: 'その他', value: 'other'}];

const SNS = [
  {name: 'Twitter', value: 'twitter'},
  {name: 'Facebook', value: 'facebook'},
  {name: 'YouTube', value: 'youtube'},
  {name: 'Instagram', value: 'instagram'},
  {name: 'LINE', value: 'line'},
  {name: 'LinkedIn', value: 'linkedin'},
] as const;

const INITIAL_PAGE_NUM = 1;

export default () => {
  const {query, setQuery} = useSearchCondition();
  const {RangePicker} = DatePicker;

  const {
    markets = [],
    companyTypes = [],
    accountClosingMonths = [],
    twitter = false,
    facebook = false,
    youtube = false,
    instagram = false,
    line = false,
    linkedin = false,
    isUrl = false,
    isTel = false,
    isEmail = false,
    isRepresentativePerson = false,
    establishedYearMonthFrom = null,
    establishedYearMonthTo = null,
  } = query;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      markets,
      companyTypes,
      accountClosingMonths,
      twitter,
      facebook,
      youtube,
      instagram,
      line,
      linkedin,
      isUrl,
      isTel,
      isEmail,
      isRepresentativePerson,
      establishedYearMonthFrom,
      establishedYearMonthTo,
    },
    onSubmit: (values) => {
      setQuery({...values, page: INITIAL_PAGE_NUM});
    },
  });

  const setAccountClosingMonth = useCallback(
    (checked: boolean, month: string) => {
      let accountClosingMonths = null;
      if (checked) {
        accountClosingMonths = [
          ...(formik.values.accountClosingMonths || []),
          month,
        ];
      } else {
        accountClosingMonths = formik.values.accountClosingMonths.filter(
          (m) => m !== month,
        );
      }
      setQuery({accountClosingMonths, page: INITIAL_PAGE_NUM});
    },
    [formik.values.accountClosingMonths],
  );

  const setMarkets = useCallback(
    (checked: boolean, market: string) => {
      let markets = null;
      if (checked) {
        markets = [...(formik.values.markets || []), market];
      } else {
        markets = formik.values.markets.filter((m) => m !== market);
      }
      setQuery({markets, page: INITIAL_PAGE_NUM});
    },
    [formik.values.markets],
  );

  const setCompanyTypes = useCallback(
    (checked: boolean, companyType: string) => {
      let companyTypes = null;
      if (checked) {
        companyTypes = [...(formik.values.companyTypes || []), companyType];
      } else {
        companyTypes = formik.values.companyTypes.filter(
          (m) => m !== companyType,
        );
      }
      setQuery({companyTypes, page: INITIAL_PAGE_NUM});
    },
    [formik.values.companyTypes],
  );

  const format = 'YYYY-MM';

  return (
    <FilterContainer>
      <div>
        <Title>連絡先</Title>
        <Contact>
          <Checkbox
            className="contact"
            value={true}
            checked={formik.values.isUrl}
            onChange={(e) => {
              formik.setFieldValue('isUrl', e.target.checked);
              formik.handleSubmit();
            }}>
            <span className="label">URL</span>
          </Checkbox>
          <Checkbox
            className="contact"
            value={true}
            checked={formik.values.isEmail}
            onChange={(e) => {
              formik.setFieldValue('isEmail', e.target.checked);
              formik.handleSubmit();
            }}>
            <span className="label">メールアドレス</span>
          </Checkbox>
          <Checkbox
            className="contact"
            value={true}
            checked={formik.values.isTel}
            onChange={(e) => {
              formik.setFieldValue('isTel', e.target.checked);
              formik.handleSubmit();
            }}>
            <span className="label">TEL</span>
          </Checkbox>
          <Checkbox
            className="contact"
            value={true}
            checked={formik.values.isRepresentativePerson}
            onChange={(e) => {
              formik.setFieldValue('isRepresentativePerson', e.target.checked);
              formik.handleSubmit();
            }}>
            <span className="label">代表者名</span>
          </Checkbox>
        </Contact>
      </div>
      <AttributeWrapper>
        <Title>設立年月</Title>
        <RangePicker
          format={format}
          placeholder={['1990-01', '2021-12']}
          picker="month"
          value={
            formik.values.establishedYearMonthFrom &&
            formik.values.establishedYearMonthTo
              ? [
                  moment(establishedYearMonthFrom, format),
                  moment(establishedYearMonthTo, format),
                ]
              : null
          }
          onChange={(value) => {
            if (value) {
              setQuery({
                establishedYearMonthFrom: value[0].format(format),
                establishedYearMonthTo: value[1].format(format),
              });
            } else {
              setQuery({
                establishedYearMonthFrom: null,
                establishedYearMonthTo: null,
              });
            }
          }}
        />
      </AttributeWrapper>
      <AttributeWrapper>
        <Title>決算月</Title>
        <AccountClosingMonth>
          {MONTHS.map((month) => (
            <Checkbox
              key={month}
              className="month"
              value={month}
              checked={formik.values.accountClosingMonths?.includes(month)}
              onChange={(e) => {
                setAccountClosingMonth(e.target.checked, month);
              }}>
              <span className="label">{month}月</span>
            </Checkbox>
          ))}
        </AccountClosingMonth>
      </AttributeWrapper>
      <AttributeWrapper>
        <Title>SNS</Title>
        <Sns>
          {SNS.map((sns, index) => (
            <Checkbox
              key={index}
              className="sns"
              value={true}
              checked={formik.values[sns.value]}
              onChange={(e) => {
                formik.setFieldValue(`${sns.value}`, e.target.checked);
                formik.handleSubmit();
              }}>
              <span className="label">{sns.name}</span>
            </Checkbox>
          ))}
        </Sns>
      </AttributeWrapper>
      <AttributeWrapper>
        <Title>上場区分</Title>
        <Market>
          {MARKETS.map((market) => (
            <Checkbox
              key={market.value}
              className="market"
              value={market.value}
              checked={formik.values.markets?.includes(market.value)}
              onChange={(e) => {
                setMarkets(e.target.checked, market.value);
              }}>
              <span className="label">{market.name}</span>
            </Checkbox>
          ))}
        </Market>
      </AttributeWrapper>
      <AttributeWrapper>
        <Title>会社種別</Title>
        <div className="wrapper">
          <CompanyType>
            {COMPANY_TYPES_1.map((companyType) => {
              return (
                <Checkbox
                  key={companyType.value}
                  className="companyType"
                  value={companyType.value}
                  checked={formik.values.companyTypes?.includes(
                    companyType.value,
                  )}
                  onChange={(e) => {
                    setCompanyTypes(e.target.checked, companyType.value);
                  }}>
                  <span className="label">{companyType.name}</span>
                </Checkbox>
              );
            })}
          </CompanyType>
        </div>
        <div className="wrapper">
          <CompanyType>
            {COMPANY_TYPES_2.map((companyType) => {
              return (
                <Checkbox
                  key={companyType.value}
                  className="companyType"
                  value={companyType.value}
                  checked={formik.values.companyTypes?.includes(
                    companyType.value,
                  )}
                  onChange={(e) => {
                    setCompanyTypes(e.target.checked, companyType.value);
                  }}>
                  <span className="label">{companyType.name}</span>
                </Checkbox>
              );
            })}
          </CompanyType>
        </div>
        <div className="wrapper">
          <CompanyType>
            {COMPANY_TYPES_3.map((companyType) => {
              return (
                <Checkbox
                  key={companyType.value}
                  className="companyType"
                  value={companyType.value}
                  checked={formik.values.companyTypes?.includes(
                    companyType.value,
                  )}
                  onChange={(e) => {
                    setCompanyTypes(e.target.checked, companyType.value);
                  }}>
                  {companyType.name}
                </Checkbox>
              );
            })}
          </CompanyType>
        </div>
        <div className="wrapper">
          <CompanyType>
            {COMPANY_TYPES_4.map((companyType) => {
              return (
                <Checkbox
                  key={companyType.value}
                  className="companyType"
                  value={companyType.value}
                  checked={formik.values.companyTypes?.includes(
                    companyType.value,
                  )}
                  onChange={(e) => {
                    setCompanyTypes(e.target.checked, companyType.value);
                  }}>
                  <span className="label">{companyType.name}</span>
                </Checkbox>
              );
            })}
          </CompanyType>
        </div>
        <div className="wrapper">
          <CompanyType>
            {COMPANY_TYPES_5.map((companyType) => {
              return (
                <Checkbox
                  key={companyType.value}
                  className="companyType"
                  value={companyType.value}
                  checked={formik.values.companyTypes?.includes(
                    companyType.value,
                  )}
                  onChange={(e) => {
                    setCompanyTypes(e.target.checked, companyType.value);
                  }}>
                  <span className="label">{companyType.name}</span>
                </Checkbox>
              );
            })}
          </CompanyType>
        </div>
      </AttributeWrapper>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  height: 585px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  > div {
    width: 743px;
    padding: 1rem;
    border-right: solid 1px #e7eaec;

    .wrapper {
      margin-bottom: 10px;
    }
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
  margin-bottom: 10px;
`;

const AttributeWrapper = styled.div`
  border-top: 1px solid var(--border-color);
`;

const Contact = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;

  .contact {
    margin: 5px 45px 5px 0px;

    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.15px;
      color: var(--text-color-0);
    }
  }
`;

const AccountClosingMonth = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 60%;

  .month {
    width: 70px;
    margin: 6px 0;

    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.15px;
      color: var(--text-color-0);
    }
  }
`;

const Market = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 80%;

  .market {
    width: 170px;
    margin: 6px 0;

    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.15px;
      color: var(--text-color-0);
    }
  }
`;

const CompanyType = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;

  .companyType {
    width: 170px;
    margin: 6px 0;

    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.15px;
      color: var(--text-color-0);
    }
  }
`;

const Sns = styled.div`
  margin-left: 10px;

  .sns {
    margin-right: 33px;
  }
`;
