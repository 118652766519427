import React from 'react';
import styled from 'styled-components';
import {ArrowDown, LaunchLink} from 'components/Ui/Icon';
import {
  SalesforceLeadField,
  SalesforceLead,
  useSalesforceLeadRecordTypeQuery,
} from 'api';
import {FieldValue} from './FieldValue';

interface Props {
  url: string;
  salesforceLead: SalesforceLead;
}

export default ({url, salesforceLead}: Props) => {
  const [showMore, setShowMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const {data: {salesforceLeadRecordType = {}} = {}} =
    useSalesforceLeadRecordTypeQuery({
      variables: {id: salesforceLead?.id},
    });

  const salesforceLeadFields =
    salesforceLeadRecordType?.salesforceLeadFields || [];

  const filteredSalesforceLeadFields: Array<SalesforceLeadField> =
    React.useMemo(() => {
      const filteredSalesforceLeadFields = showMore
        ? salesforceLeadFields
        : salesforceLeadFields.slice(0, 5);

      return filteredSalesforceLeadFields;
    }, [salesforceLeadFields, showMore]);

  return (
    <Lead>
      <Header open={open} onClick={() => setOpen(!open)}>
        <Wrapper>
          <h2>
            {salesforceLead?.lastName} {salesforceLead?.firstName}
          </h2>
          <Stage>
            <span>{salesforceLead?.status}</span>
          </Stage>
        </Wrapper>
        <ArrowDown />
      </Header>

      {open && (
        <>
          <Section />
          {salesforceLead?.salesforceLeadRecordType && (
            <>
              <SalesforceField>
                <Label>レコードタイプ：</Label>
                <Type>{salesforceLead?.salesforceLeadRecordType?.name}</Type>
              </SalesforceField>
              {salesforceLead?.salesforceOwner && (
                <SalesforceField>
                  <Label>所有者： </Label>
                  <Type>
                    {salesforceLead.salesforceOwner.lastName}{' '}
                    {salesforceLead.salesforceOwner.firstName}
                  </Type>
                </SalesforceField>
              )}
              <Section />
            </>
          )}
          <div className="mt-4">
            {filteredSalesforceLeadFields.map((field, i) => (
              <FieldValue
                key={i}
                field={field}
                salesforceLead={salesforceLead}
              />
            ))}
          </div>
          {salesforceLeadFields.length > 5 && (
            <ShowMore
              showMore={showMore}
              onClick={() => setShowMore(!showMore)}>
              <ArrowDown color={'var(--primary)'} />
              <span>
                {showMore ? '一部の項目を表示' : 'すべての項目を表示'}
              </span>
            </ShowMore>
          )}
          <LinkToSalesforce
            href={`${url}/lightning/r/Lead/${salesforceLead?.leadId}/view`}
            target="_blank">
            <LaunchLink color={'var(--primary)'} />
            <span>Salesforceで見る</span>
          </LinkToSalesforce>
        </>
      )}
    </Lead>
  );
};

const Lead = styled.div`
  width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-weight: 500;
  border: 1px solid #e1e6eb;
`;

const Header = styled.div<{open: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
    letter-spacing: 0.15px;
    color: var(--text-color-1);
    margin-bottom: 0px;
  }
`;

const Section = styled.hr`
  border: 1px solid #e1e6eb;
`;

const SalesforceField = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
`;

const Type = styled.div`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;

const LinkToSalesforce = styled.a`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--primary);
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
`;

const ShowMore = styled.div<{showMore: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: pointer;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--primary);
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    transform: rotate(${({showMore}) => (showMore ? '180deg' : '0deg')});
  }
`;

const Stage = styled.div`
  height: 20px;
  padding: 4px;
  background: #eef0f2;
  border-radius: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 8px;
  > span {
    font-size: 10px;
    line-height: 34px;
    letter-spacing: 0.15px;
    color: var(--text-color-2);
  }
`;
