import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  label: string;
  to: string;
  count?: number;
}

export default ({label, to, count}: Props) => {
  const active = location.pathname.startsWith(to);

  return (
    <Link
      to={`${to}${location.search}`}
      className={`flex gap-1 items-center h-9 border-b-[3px] ${
        active ? 'border-primary' : 'border-transparent'
      }`}>
      <span
        className={`text-sm font-bold ${
          active ? 'text-primary' : 'text-foreground-lighter'
        }`}>
        {label}
      </span>
      <span
        className={`text-xs font-bold rounded-full h-4 min-w-[16px] px-1 flex items-center justify-center ${
          active ? 'bg-[#DEEEFC] text-primary' : 'bg-[#EEF0F2] text-foreground-lighter'
        }`}>
        {count}
      </span>
    </Link>
  );
};
