import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import {useUploadMailImageMutation} from 'api';

interface Props {
  onChange: (bodyHtml: string, bodyText: string) => void;
}

export default React.forwardRef(({onChange}: Props, ref: any) => {
  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });

  const fontSizeStyle = ReactQuill.Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  ReactQuill.Quill.register(fontSizeStyle, true);

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            'bold',
            'italic',
            'underline',
            {align: ['', 'center']},
            {color: []},
            'link',
            'image',
            {size: fontSizeStyle.whitelist},
          ],
        ],
      },
    };
  }, []);

  return (
    <Quill
      ref={ref as any}
      theme="snow"
      placeholder="本文を記入してください。"
      onChange={(content, delta, source, editor) => {
        onChange(content, editor.getText());
      }}
      modules={modules}
      bounds={'#quill-container'}
    />
  );
});

const Quill = styled(ReactQuill)`
  .ql-toolbar {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--border-color);
    background: #ffffff;
    button {
      margin: auto 0;
    }
  }
  .ql-container {
    min-height: 100px;
    background: #fff;
    .ql-editor {
      min-height: 100px;
      &::before {
        color: var(--text-color-3);
      }
    }
  }
`;
