import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3334 2.5H1.66669V7.5H18.3334V2.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33331 7.5V16.6667C3.33331 17.1269 3.70641 17.5 4.16665 17.5H15.8333C16.2936 17.5 16.6666 17.1269 16.6666 16.6667V7.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33331 10.8333H11.6666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
