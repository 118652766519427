import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M19.1481 10.3333H10.8518C10.5654 10.3333 10.3333 10.6815 10.3333 11.1111V18.8889C10.3333 19.3184 10.5654 19.6666 10.8518 19.6666H19.1481C19.4344 19.6666 19.6666 19.3184 19.6666 18.8889V11.1111C19.6666 10.6815 19.4344 10.3333 19.1481 10.3333Z"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 12.6667L13.8333 12.6667"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 15L13.8333 15"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 17.3333L16.1667 17.3333"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
