import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useTable} from 'react-table';
import {useHistory} from 'react-router-dom';
import useSearchParams from '../useSearchParams';
import {useProjectTelStatusCallReportsQuery} from 'api';
import {Download} from 'components/Ui/Icon';
import DownloadModal from './CallRateReportDownloadModal';
import {DownloadButton} from '../../style';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const history = useHistory();
  const {searchParams} = useSearchParams();

  const {data: {projectTelStatusCallReports = []} = {}} =
    useProjectTelStatusCallReportsQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
    });

  const totalCountPerStatus = (status: string) =>
    projectTelStatusCallReports.reduce(
      (total, report: any) => total + report[status],
      0,
    );

  const totalCountRowData = useMemo(() => {
    const call = totalCountPerStatus('call');
    const reception = totalCountPerStatus('reception');
    const contact = totalCountPerStatus('contact');
    const document = totalCountPerStatus('document');
    const other = totalCountPerStatus('other');
    const appoint = totalCountPerStatus('appoint');
    const callNg = totalCountPerStatus('callNg');
    const receptionNg = totalCountPerStatus('receptionNg');
    const contactNg = totalCountPerStatus('contactNg');

    return {
      isTotalCountRowData: true,
      call:
        call +
        reception +
        contact +
        appoint +
        document +
        other +
        callNg +
        receptionNg +
        contactNg,
      contact: contact + appoint + document + contactNg,
      document: document,
      appoint: appoint,
    };
  }, [projectTelStatusCallReports]);

  const data: any = useMemo(
    () =>
      projectTelStatusCallReports.map((report) => {
        return {
          projectName: report.projectName,
          projectUuid: report.projectUuid,
          projectGroupUuid: report.projectGroupUuid,
          call: report.total,
          contact:
            report.contact +
            report.appoint +
            report.document +
            report.contactNg,
          document: report.document,
          appoint: report.appoint,
        };
      }),
    [projectTelStatusCallReports],
  );

  data.unshift(totalCountRowData);

  const conversionRate = (before: number, after: number) => {
    if (!before || !after) return 0;

    return Math.round((after / before) * 1000) / 10;
  };

  const columns: any = useMemo(
    () => [
      {
        Header: '',
        id: 'projectName',
        accessor: (row: any) =>
          row.isTotalCountRowData ? null : (
            <ProjectName
              onClick={() => {
                history.push(
                  `/list/groups/${row.projectGroupUuid}/projects/${row.projectUuid}/tel_reports/all/call`,
                );
              }}>
              {row.projectName}
            </ProjectName>
          ),
      },
      {
        id: 'call',
        Header: 'コール・受付',
        accessor: (row: any) => (
          <div style={{textAlign: 'right', paddingRight: '10px'}}>
            {row.call}
          </div>
        ),
      },
      {
        id: 'contact',
        Header: 'キーマン接触',
        accessor: (row: any) => (
          <CountCell>
            <div>{row.contact}</div>
            <div>{conversionRate(row.call, row.contact)}%</div>
          </CountCell>
        ),
      },
      {
        id: 'document',
        Header: '資料送付',
        accessor: (row: any) => (
          <CountCell>
            <div>{row.document}</div>
            <div>{conversionRate(row.call, row.document)}%</div>
          </CountCell>
        ),
      },
      {
        id: 'appoint',
        Header: 'アポ獲得',
        accessor: (row: any) => (
          <CountCell>
            <div>{row.appoint}</div>
            <div>
              <div style={{margin: 'auto'}}>
                {conversionRate(row.call, row.appoint)}%
              </div>
            </div>
          </CountCell>
        ),
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <>
      <Title>カテゴリ別</Title>
      <DownloadButton onClick={() => setModalOpen(true)}>
        <Download />
        ダウンロード
      </DownloadButton>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()} key={j}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </>
  );
};

const Title = styled.div`
  margin-bottom: 17px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: var(--text-color-1);
`;

const TableWrapper = styled.div`
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
  border-left: 1px solid #e1e6eb;
  height: 570px;
  overflow-y: scroll;
  position: relative;
  top: -40px;
`;

const Table = styled.table`
  border-bottom: 1px solid #e1e6eb;

  thead {
    background: #ffffff;
    position: sticky;
    top: 0;
    height: 55px;

    tr {
      th {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          top: 0;
          left: 0;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        width: 160px;
        font-weight: bold;
        font-size: 13px;

        &:first-child {
          width: 258px;
        }

        svg {
          margin-right: 6px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 45px;

      &:first-child {
        border-bottom: double 3px #e1e6eb;
        background: #ffffff;
        position: sticky;
        top: 55px;

        td:first-child {
          background: #ffffff;
          position: sticky;
          top: 45px;
        }
      }

      &:nth-child(n + 3) {
        border-right: 1px solid #e1e6eb;
        border-top: 1px solid #e1e6eb;
      }

      td {
        &:first-child {
          border-right: double 3px #e1e6eb;
        }

        border-right: 1px solid #e1e6eb;
        font-weight: 500;
        font-size: 12px;
        color: var(--text-color-1);
      }
    }
  }
`;

const ProjectName = styled.div`
  color: var(--primary);
  margin-left: 15px;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const CountCell = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: right;
  align-items: center;

  > div {
    width: 50%;
    padding-right: 10px;
  }

  > div:nth-child(2) {
    background: #f9fafb;
    line-height: 40px;
  }
`;
