import React from 'react';
import styled from 'styled-components';
import {Project, useCurrentClientQuery, ProjectTag} from 'api';
import {useHistory} from 'react-router-dom';
import {Row} from 'react-table';
import {Group as GroupIcon, Share} from 'components/Ui/Icon';
import {getTagFontColor} from 'helpers/tagColors';
import useProjectPath from 'hooks/useProjectPath';
import useSearchParams from '../useSearchParams';

interface Props {
  value: number;
  row: Row<Project>;
}

export default ({value, row}: Props) => {
  const project = row.original;
  const history = useHistory();
  const {createPath} = useProjectPath();

  const {query, setQuery} = useSearchParams();

  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  const tabPath = (project: Project) =>
    project.status === 'open'
      ? 'project_summary'
      : project.projectType === 'upload'
      ? 'csv_summary'
      : 'search_condition';

  const projectType = (project: Project) =>
    project?.projectSearchCondition?.company?.name
      ? '逆引き検索'
      : project.projectType === 'search'
      ? 'DB'
      : project.projectType === 'upload'
      ? 'CSV'
      : project.projectType === 'prelead_master'
      ? 'プレリードマスター'
      : project.projectType === 'cold_lead' && 'コールドリード';

  const filterProjects = (tag: ProjectTag) => {
    if (!query.tagIds.some((tagId) => tagId === tag.uuid)) {
      setQuery({tagIds: [...query.tagIds, tag.uuid]});
    }
  };

  return (
    <Container>
      <div>
        <NameLink
          onClick={() => {
            history.push(
              createPath(
                `projects/${project.uuid}/${tabPath(project)}${
                  location.search
                }`,
              ),
            );
          }}>
          {value}
        </NameLink>
      </div>
      <div>
        <SerialNumber>#{project.serialNumber}</SerialNumber>

        {project.projectType && (
          <ProjectType>
            <span>{projectType(project)}</span>
          </ProjectType>
        )}

        {project.projectGroup &&
          (project.requestClients.length === 0 ||
            project.requestClients?.some(
              (requestClient) => requestClient.id === currentClient.id,
            )) && (
            <Group>
              <GroupIcon />
              {project.projectGroup.name}
            </Group>
          )}

        {project.projectTags
          ?.filter((tag) => tag.clientId === currentClient.id)
          ?.map((tag) => (
            <Tag
              key={tag.id}
              color={tag.color}
              onClick={() => filterProjects(tag)}>
              <span>{tag.name}</span>
            </Tag>
          ))}

        {project.offeredClients
          .filter((offeredClient) => offeredClient.id !== currentClient.id)
          .map((offeredClient) => (
            <OfferedClient key={offeredClient.id}>
              <Share />
              <span>{offeredClient.name}</span>
            </OfferedClient>
          ))}

        {project.requestClients
          .filter((requestClient) => requestClient.id !== currentClient.id)
          .map((requestClient) => (
            <RequestClient key={requestClient.id}>
              <Share />
              <span>{requestClient.name}</span>
            </RequestClient>
          ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;

  > div {
    display: flex;
    align-items: center;
    width: calc(100vw - 1000px);

    &:first-child {
      margin-bottom: 6px;
    }
  }
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color-1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NameLink = styled(Name)`
  cursor: pointer;
  &:hover {
    color: var(--primary);
  }
`;

const SerialNumber = styled.span`
  margin-right: 7px;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color-2);
`;

const ProjectType = styled.span`
  margin-right: 7px;
  display: flex;
  padding: 1px 6px;
  background: #ffffff;
  border: 1px solid var(--text-color-3);
  border-radius: 100px;
  span {
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
    color: var(--text-color-2);
  }
`;

const Group = styled.span`
  display: flex;
  align-items: center;
  margin-right: 7px;
  font-weight: bold;
  font-size: 10px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 13px;
    height: 13px;
    margin-right: 2px;
    stroke: var(--text-color-1);
  }
`;

const Tag = styled.span<{color?: string}>`
  margin-right: 6px;
  display: flex;
  padding: 1px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;
  cursor: pointer;

  span {
    font-size: 9px;
    font-weight: bold;
    line-height: 13px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;

const ShareClient = styled.span`
  margin-right: 6px;
  display: flex;
  align-items: center;
  padding: 1px 8px;
  background: #eeeafb;
  border-radius: 2px;
  svg {
    margin-right: 4px;
  }
  span {
    font-weight: bold;
    font-size: 9px;
    color: var(--text-color-1);
  }
`;

const OfferedClient = styled(ShareClient)`
  background: #eeeafb;

  span {
    color: var(--text-color-1);
  }
`;

const RequestClient = styled(ShareClient)`
  background: #def4f7;

  span {
    color: var(--text-color-1);
  }

  svg {
    path {
      stroke: #4ea9af;
      fill: #4ea9af;
    }
  }
`;
