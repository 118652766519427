import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {LinkEllipse} from 'components/Ui/Icon';
import {Activity, SensesDealCreateActivity} from 'api';
import useProjectName from './useProjectName';
import UserName from './UserName';

interface SensesDealCreate extends Activity {
  resource: SensesDealCreateActivity;
}

interface Props {
  activity: SensesDealCreate;
}

const SensesDealCreateActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <>
      <Project>{projectName}</Project>
      <Summary>
        <BadgeWrapper>
          <LinkEllipse />
        </BadgeWrapper>
        <UserNameWrapper>
          <UserName activity={activity} />
        </UserNameWrapper>
        <StatusLabel color="#1A2F3A">Senses連携</StatusLabel>
        <Name>リード</Name>
        <span>{moment(activity.createdAt).format('MM/DD HH:mm')}</span>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  align-items: center;
  height: 30px;
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  color: var(--text-color-2);
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

const UserNameWrapper = styled.div`
  margin-right: 12px;
`;

const StatusLabel = styled.span<{color: string}>`
  margin-right: 12px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid var(--text-color-3)'};
  color: ${({color}) => (color ? 'white' : 'var(--text-color-3)')};
  border-radius: 20px;
  padding: 4px 16px;
  font-size: 10px;
  height: 22px;
`;

const Name = styled.span`
  margin-right: 12px;
  font-weight: 700;
`;

export default SensesDealCreateActivityComponent;
