import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25 4.25C4.25 4.25 4.25 7.60186 4.25 9.16666C4.25 10.7315 5.51853 12 7.08333 12L26.9167 12C28.4815 12 29.75 10.7315 29.75 9.16667L29.75 4.25"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.75 30C29.75 30 29.75 26.6481 29.75 25.0833C29.75 23.5185 28.4815 22.25 26.9167 22.25L7.08333 22.25C5.51853 22.25 4.25 23.5185 4.25 25.0833L4.25 30"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 17L32 17"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
