import React from 'react';
import {PreleadTag, useDestroyPreleadTagMutation} from 'api';
import {Trash} from 'components/Ui/Icon';
import EditInput from './EditInput';

interface Props {
  preleadTag: PreleadTag;
}

export default ({preleadTag}: Props) => {
  const [destroyPreleadTag] = useDestroyPreleadTagMutation({
    refetchQueries: ['currentClient', 'accountPool', 'publicPreleadTags'],
  });

  const handleDeleteTag = () => {
    if (confirm('削除してよろしいですか？')) {
      destroyPreleadTag({
        variables: {
          uuid: preleadTag.uuid,
        },
      });
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-64 p-3">
      <EditInput preleadTag={preleadTag} />
      <button
        className="mt-[3px] flex items-center p-0 font-bold text-[12px] text-[#899098] hover:text-c-base bg-transparent border-none cursor-pointer"
        onClick={handleDeleteTag}>
        <Trash color="#899098" />
        削除
      </button>
    </div>
  );
};
