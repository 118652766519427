import React from 'react';
import styled from 'styled-components';
import {ArrowDown, Setting} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import {
  usePreleadQuery,
  useClientPreleadCustomizeItemsByPreleadQuery,
} from 'api';
import NewModal from './NewModal';
import EditModal from './EditModal';
import Item from './Item';
import usePreleadContext from '../usePreleadContext';

export default () => {
  const {preleadId} = usePreleadContext();
  const [open, setOpen] = React.useState(false);
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [editModalVisible, setEditModalVisible] = React.useState(false);

  const {data: {clientPreleadCustomizeItemsByPrelead = []} = {}} =
    useClientPreleadCustomizeItemsByPreleadQuery({
      variables: {uuid: preleadId},
      fetchPolicy: 'cache-and-network',
    });

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
  });

  return (
    <Container>
      <Header open={open} onClick={() => setOpen(!open)}>
        <span>項目</span>
        <ArrowDown />
      </Header>
      {open && (
        <>
          <Contents>
            <Items>
              {clientPreleadCustomizeItemsByPrelead
                .filter(
                  (item) => item.isDisplay === true && item.isDeleted === false,
                )
                .map((item) => (
                  <Item
                    prelead={prelead}
                    clientPreleadCustomizeItem={item}
                    key={item.id}
                  />
                ))}
            </Items>
          </Contents>
          <CustomItemOptions>
            <Button size="small" onClick={() => setNewModalVisible(true)}>
              + 項目を追加
            </Button>
            <Button size="small" onClick={() => setEditModalVisible(true)}>
              <Setting width="18" height="18" />
            </Button>
          </CustomItemOptions>
        </>
      )}
      {newModalVisible && (
        <NewModal visible={newModalVisible} setVisible={setNewModalVisible} />
      )}
      {editModalVisible && (
        <EditModal
          visible={editModalVisible}
          setVisible={setEditModalVisible}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  padding: 0 22px;
`;

const CustomItemOptions = styled.div`
  margin: 4px 0 14px 0;
  display: flex;
  gap: 5px;
`;

const Header = styled.div<{open: boolean}>`
  display: flex;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > span {
    color: var(--text-color-3);
  }

  & > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Contents = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  max-height: 230px;
  overflow-y: auto;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
