import React from 'react';

export default () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.9999 12.8334C10.2216 12.8334 12.8332 10.2217 12.8332 7.00008C12.8332 3.77842 10.2216 1.16675 6.9999 1.16675C3.77824 1.16675 1.16656 3.77842 1.16656 7.00008C1.16656 10.2217 3.77824 12.8334 6.9999 12.8334Z"
      stroke="var(--text-color-3)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.71875 7.68726C5.18474 7.68726 5.5625 7.3095 5.5625 6.84351C5.5625 6.37752 5.18474 5.99976 4.71875 5.99976C4.25276 5.99976 3.875 6.37752 3.875 6.84351C3.875 7.3095 4.25276 7.68726 4.71875 7.68726Z"
      fill="var(--text-color-3)"
    />
    <path
      d="M6.96875 7.68738C7.43474 7.68738 7.8125 7.30962 7.8125 6.84363C7.8125 6.37764 7.43474 5.99988 6.96875 5.99988C6.50276 5.99988 6.125 6.37764 6.125 6.84363C6.125 7.30962 6.50276 7.68738 6.96875 7.68738Z"
      fill="var(--text-color-3)"
    />
    <path
      d="M9.21875 7.68726C9.68474 7.68726 10.0625 7.3095 10.0625 6.84351C10.0625 6.37752 9.68474 5.99976 9.21875 5.99976C8.75276 5.99976 8.375 6.37752 8.375 6.84351C8.375 7.3095 8.75276 7.68726 9.21875 7.68726Z"
      fill="var(--text-color-3)"
    />
  </svg>
);
