import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectGroupQuery} from 'api';
import {useUpdateProjectGroupMutation} from 'api';
import {Input} from 'components/antd';
const {TextArea} = Input;

export default () => {
  const {projectGroupId} = useParams<{projectGroupId: string}>();
  const {data: {projectGroup = null} = {}} = useProjectGroupQuery({
    variables: {uuid: projectGroupId},
    skip: !projectGroupId || projectGroupId === 'new',
  });

  const [memo, setMemo] = useState(null);
  const [updateProjectGroup] = useUpdateProjectGroupMutation({
    refetchQueries: ['projectGroups', 'currentUser'],
  });

  React.useEffect(() => {
    setMemo(projectGroup?.memo);
  }, [projectGroup]);

  const handleChangeName = (value: string) => {
    setMemo(value);
  };

  const updateName = React.useCallback(
    (e) =>
      updateProjectGroup({
        variables: {uuid: projectGroupId, attributes: {memo: e.target.value}},
      }),
    [projectGroupId],
  );

  if (!projectGroup) return null;

  return (
    <Container>
      <Memo
        value={memo}
        onChange={(e) => handleChangeName(e.target.value)}
        onBlur={updateName}
        onPressEnter={updateName}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const Memo = styled(TextArea)`
  font-size: 14px;
  line-height: 26px;
  border-color: transparent;

  &:hover {
    border-color: var(--border-color);
  }
`;
