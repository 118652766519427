import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, RankChangeProspectActivity} from 'api';

interface RankChangeAcvitity extends ProspectActivity {
  resource: RankChangeProspectActivity;
}

interface Props {
  prospectActivity: RankChangeAcvitity;
}

const RankChangeActivityComponent = ({prospectActivity}: Props) => {
  const {
    resource: {rankText},
    user,
    createdAt,
  } = prospectActivity;

  return (
    <>
      <Icon />
      <Content>
        <span>ランク：{rankText || '未選択'}</span>
        <span>
          {user.lastName} {user.firstName}
        </span>
        <time>{moment(createdAt).format('YYYY/MM/DD HH:mm')}</time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  margin-top: -4px;
  margin-left: 3px;

  span:first-child {
    flex: 1;
    margin: 0;
    color: var(--text-color-1);
    font-weight: 500;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;

export default RankChangeActivityComponent;
