import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {Modal, Input} from 'components/antd';
import {useCreateWorkflowMutation, WorkflowTemplateWorkflow} from 'api';
import useSearchParams from './useSearchParams';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setSelectModal: (selectModal: boolean) => void;
  selectTemplate?: WorkflowTemplateWorkflow | null;
  setSelectTemplate: (selectTemplate: boolean) => void;
}

const NewModalComponent = ({
  visible,
  setVisible,
  setSelectModal,
  selectTemplate,
  setSelectTemplate,
}: Props) => {
  const [name, setName] = React.useState('');
  const [target, setTarget] = React.useState('');
  const {poolId} = useParams<{poolId: string}>();
  const {setQuery} = useSearchParams();
  const [create, {loading}] = useCreateWorkflowMutation({
    refetchQueries: ['workflows'],
    onCompleted: () => {
      setSelectModal(false);
      onClose();
      setQuery({status: 'draft', page: 1});
    },
  });

  const onOk = () =>
    create({
      variables: {
        uuid: poolId,
        attributes: {name, target},
        workflowTemplateWorkflowUuid: selectTemplate?.uuid,
      },
    });

  const onClose = React.useCallback(() => {
    setName('');
    setTarget('');
    setSelectTemplate(null);
    setVisible(false);
  }, []);

  React.useEffect(() => {
    if (selectTemplate) {
      setName(selectTemplate.name);
      setTarget(selectTemplate.target);
    }
  }, [selectTemplate]);

  return (
    <NewModal
      title={null}
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      cancelText="キャンセル"
      width={510}
      okText="作成"
      okButtonProps={{loading, disabled: !name || !target}}
      maskClosable>
      <h3>新規ワークフロー</h3>

      <ItemWrapper>
        <Label>
          ワークフロー名<Required>*</Required>
        </Label>
        <Input
          size="large"
          value={name}
          placeholder="ワークフロー名"
          onChange={(e) => setName(e.target.value)}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label>
          対象リード<Required>*</Required>
        </Label>
        <Target
          onClick={() => setTarget('only_existing_prospects')}
          isActive={target === 'only_existing_prospects'}>
          <div>登録済みリードを対象</div>
          <div>
            セミナー・展示会参加者、掘り起こし営業、失注先・リサイクル営業など
          </div>
        </Target>
        <Target
          onClick={() => setTarget('only_new_prospects')}
          isActive={target === 'only_new_prospects'}>
          <div>新規登録されたリードを対象</div>
          <div>資料ダウンロード・問い合わせフォームなど</div>
        </Target>
        <Target
          onClick={() => setTarget('all_prospects')}
          isActive={target === 'all_prospects'}>
          <div>いずれも対象</div>
        </Target>
      </ItemWrapper>
    </NewModal>
  );
};

const ItemWrapper = styled.div`
  margin-bottom: 23px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: var(--text-color-2);
`;

const Required = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #f85931;
`;

const Target = styled.div<{isActive: boolean}>`
  cursor: pointer;
  border: ${({isActive}) =>
    isActive ? '1px solid var(--primary)' : '1px solid #e1e6eb'};
  box-shadow: ${({isActive}) => (isActive ? '0px 0px 2px var(--primary)' : 'none')};
  border-radius: 4px;
  height: 58px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;

  > div:first-child {
    font-weight: 500;
    font-size: 14px;
  }

  > div:nth-child(2) {
    font-weight: 500;
    font-size: 10px;
    color: var(--text-color-2);
  }
`;

const NewModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 42px 0 42px;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 15px;
  }

  .ant-modal-footer {
    border: none;
    padding-bottom: 33px;
  }
`;

export default NewModalComponent;
