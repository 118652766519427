import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" stroke="#E1E6EB" />
      <path
        d="M16.0007 15.3333C17.4734 15.3333 18.6673 14.1394 18.6673 12.6667C18.6673 11.1939 17.4734 10 16.0007 10C14.5279 10 13.334 11.1939 13.334 12.6667C13.334 14.1394 14.5279 15.3333 16.0007 15.3333Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 22V19.3333C10.666 18.5969 11.263 18 11.9993 18H19.9993C20.7357 18 21.3327 18.5969 21.3327 19.3333V22"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
