import {Dialog} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import React from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  useUpdateClientPreleadCustomizeItemPositionMutation,
  useUpdateClientPreleadCustomizeItemIsDisplayMutation,
  ClientPreleadCustomizeItem,
  useClientPreleadCustomizeItemsQuery,
} from 'api';
import {Handle, DotMenuSide, Trash} from 'components/Ui/Icon';
import {Checkbox, Popover} from 'components/antd';
import PopoverContent from './PopoverContent';

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

export default function ProfileEditorModal({isOpen, setIsOpen}: Props) {
  const [customizeItems, setCustomizeItems] = React.useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState('');
  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setCustomizeItems(clientPreleadCustomizeItems);
      },
    });

  const [updateClientPreleadCustomizeItemPosition] =
    useUpdateClientPreleadCustomizeItemPositionMutation();
  const [updateClientPreleadCustomizeItemIsDisplay] =
    useUpdateClientPreleadCustomizeItemIsDisplayMutation();

  React.useEffect(() => {
    setCustomizeItems(clientPreleadCustomizeItems);
  }, [clientPreleadCustomizeItems.length]);

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...customizeItems];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setCustomizeItems(items);
      updateClientPreleadCustomizeItemPosition({
        variables: {
          id: removed.id,
          position: destIndex,
        },
      });
    },
    [customizeItems],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50 w-full">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4 w-full">
        <Dialog.Panel className="rounded-lg bg-white w-[500px]">
          <div className="flex items-center justify-between bg-[#F3F5F7] h-10 px-6 w-full">
            <Dialog.Title
              className="text-base font-semibold m-0 w-full"
              as="h1">
              カスタム項目の編集
            </Dialog.Title>
            <div
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:bg-gray-100 hover:text-gray-500 flex items-center cursor-pointer">
              <XMarkIcon className="h-6 w-6" />
            </div>
          </div>
          <div className="max-h-[calc(100vh-200px)] overflow-y-auto w-full px-6 py-4">
            <HeaderContentWrapper>
              <Gap width={24} />
              <ItemName>
                <Bold>項目名</Bold>
              </ItemName>
              <Gap width={123} />
              <DataType>
                <Bold>データ形式</Bold>
              </DataType>
              <Gap width={22} />
              <Display>
                <Bold>表示</Bold>
              </Display>
              <Gap width={20} />
            </HeaderContentWrapper>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {customizeItems
                      .filter(
                        (item: ClientPreleadCustomizeItem) =>
                          item.isDeleted === false,
                      )
                      .map((item: any, index: number) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}>
                          {(provided, snapshot) => (
                            <DraggableItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={snapshot.isDragging ? 'dragging' : ''}>
                              <HandleWrapper {...provided.dragHandleProps}>
                                <Handle />
                              </HandleWrapper>
                              <ContentWrapper>
                                <ItemName>{item.name}</ItemName>
                                <Gap width={123} />
                                <DataType>{item.dataTypeText}</DataType>
                                <Gap width={22} />
                                <Display>
                                  <Checkbox
                                    defaultChecked={item.isDisplay}
                                    onChange={(e) => {
                                      updateClientPreleadCustomizeItemIsDisplay(
                                        {
                                          variables: {
                                            id: item.id,
                                            isDisplay: e.target.checked,
                                          },
                                          refetchQueries: [
                                            'clientPreleadCustomizeItems',
                                          ],
                                        },
                                      );
                                    }}
                                  />
                                </Display>
                                <Popover
                                  placement="bottomLeft"
                                  content={PopoverContent(item.id, () =>
                                    setIsPopoverVisible(false),
                                  )}
                                  trigger="click"
                                  visible={
                                    isPopoverVisible && selectedItem === item.id
                                  }
                                  onVisibleChange={(e) => {
                                    setIsPopoverVisible(e);
                                    setSelectedItem(item.id);
                                  }}>
                                  <div
                                    style={{
                                      cursor: 'pointer',
                                      display: 'flex',
                                    }}>
                                    <DotMenuSide
                                      color={
                                        isPopoverVisible &&
                                        selectedItem === item.id
                                          ? '#68b5fb'
                                          : '#899098'
                                      }
                                    />
                                  </div>
                                </Popover>
                              </ContentWrapper>
                            </DraggableItem>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

const ItemName = styled.div`
  width: 160px;
`;

const DataType = styled.div`
  width: 72px;
`;

const Display = styled.div`
  width: 33px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Gap = styled.div<{width: number}>`
  width: ${({width}) => `${width}px`};
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const DraggableItem = styled.div`
  min-height: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;
  margin-bottom: 10px;
  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px #68b4fb;
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;
