import React, {useState} from 'react';
import {
  ClientPreleadCustomizeItem,
  ClientPreleadCustomizeItemSelectOption,
  useAccountPoolQuery,
  useUpdateAccountCustomizeItemSelectMutation,
} from 'api';
import {Input, Popover} from 'components/antd';
import styled from 'styled-components';
import {Cross} from 'components/Ui/Icon';
import SelectOption from './SelectOption';
import {useParams} from 'react-router-dom';

interface Props {
  item: ClientPreleadCustomizeItem;
}

export default ({item}: Props) => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {account = null} = {}} = {}} = useAccountPoolQuery(
    {
      variables: {uuid: accountId},
      skip: !accountId,
    },
  );

  const customizeItem = account?.customizeItemSelects?.find(
    (customizeItemSelect) =>
      customizeItemSelect.clientPreleadCustomizeItemId === item.id,
  );

  const options = customizeItem
    ? item.selectOptions.filter((option) =>
        customizeItem?.clientPreleadCustomizeItemSelectOptionIds.includes(
          option.id,
        ),
      )
    : [];
  const [updateCustomizeItem] = useUpdateAccountCustomizeItemSelectMutation({});

  const [optionName, setOptionName] = useState('');
  const [isPopoverVisible, setIsPopoverVisible] = useState(null);

  const onRemove = (deleteoption: ClientPreleadCustomizeItemSelectOption) => {
    updateCustomizeItem({
      variables: {
        uuid: account.uuid,
        clientPreleadCustomizeItemId: item?.id,
        values: options
          .filter((option) => option.id !== deleteoption.id)
          .map((option) => option.label),
      },
    });
  };

  return (
    <Container>
      <Popover
        content={
          <SelectOption
            account={account}
            clientPreleadCustomizeItem={item}
            optionName={optionName}
            setOptionName={setOptionName}
          />
        }
        trigger="click"
        onVisibleChange={(visible) => setIsPopoverVisible(visible)}
        visible={isPopoverVisible}
        placement="bottom"
        overlayStyle={{
          width: '300px',
        }}>
        <InputBox>
          {options.map((option) => (
            <CreatedTag key={option.id}>
              <span>{option.label}</span>
              <CrossWrapper onClick={() => onRemove(option)}>
                <Cross />
              </CrossWrapper>
            </CreatedTag>
          ))}

          <InputWrapper>
            <TagInput
              type="search"
              name="search"
              bordered={false}
              placeholder={options.length === 0 ? '未入力' : ''}
              defaultValue={''}
              value={optionName}
              autoComplete="off"
            />
          </InputWrapper>
        </InputBox>
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  h3 {
    font-size: 10px;
    color: #899098;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 34px;
  padding: 6px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    border: 1px solid #e1e6eb;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  width: auto;
  background: transparent;
  font-size: inherit;
  line-height: 16px;
  flex: 1 1 60px;
  min-width: 60px;
`;

const TagInput = styled(Input)`
  border: none;
  width: 240px;
  padding: 0;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: #bdc4cb;
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;

const CreatedTag = styled.span`
  margin-right: 6px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #f3f5f7;
  border-radius: 2px;
  span {
    font-size: 12px;
    line-height: 13px;
    color: #495058;
  }
  svg {
    margin-left: 6px;
    width: 7px;
    height: 7px;
    path {
      stroke-width: 2;
      stroke: black;
    }
  }
`;

const CrossWrapper = styled.span`
  cursor: pointer;
`;
