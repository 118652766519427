import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useAutomaticMailSendStatsByWorkflowQuery} from 'api';
import useSearchParams from './useSearchParams';

const Stats = () => {
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {automaticMailSendStatsByWorkflow = {}} = {}} =
    useAutomaticMailSendStatsByWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });
  const {query, setQuery} = useSearchParams();

  return (
    <Container>
      <Item
        onClick={() => setQuery({status: 'sent'})}
        isActive={query.status === 'sent'}>
        <div>
          <em>
            {(
              automaticMailSendStatsByWorkflow.automaticMailSendSentCount || 0
            ).toLocaleString()}
          </em>
          <span>配信済</span>
        </div>
      </Item>
      <Item
        onClick={() => setQuery({status: 'opened'})}
        isActive={query.status === 'opened'}>
        <div>
          <em>
            {(
              automaticMailSendStatsByWorkflow.automaticMailSendOpenCount || 0
            ).toLocaleString()}
          </em>
          <span>開封済</span>
        </div>
      </Item>
      <Item
        onClick={() => setQuery({status: 'clicked'})}
        isActive={query.status === 'clicked'}>
        <div>
          <em>
            {(
              automaticMailSendStatsByWorkflow.automaticMailSendClickCount || 0
            ).toLocaleString()}
          </em>
          <span>クリック</span>
        </div>
      </Item>
      <Item
        onClick={() => setQuery({status: 'bounced'})}
        isActive={query.status === 'bounced'}>
        <div>
          <em>
            {(
              automaticMailSendStatsByWorkflow.automaticMailSendBounceCount || 0
            ).toLocaleString()}
          </em>
          <span>バウンス</span>
        </div>
      </Item>
    </Container>
  );
};

const Item = styled.div<{isActive: boolean}>`
  color: ${({isActive}) => (isActive ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  cursor: pointer;
`;

const Container = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  width: 100%;
  padding-bottom: 10px;

  & > * {
    width: 120px;
    height: 60px;
    padding: 0.5rem 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e1e6eb;

      em {
        font-style: normal;
        font-size: 18px;
        font-weight: bold;
      }

      span {
        font-size: 12px;
        font-weight: medium;
      }
    }
  }
`;

export default Stats;
