import React, {useState} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {Close} from 'components/Icon';
import {useCreateAllProjectsConversionRateTelStatusDailyDownloadMutation} from 'api';
import {Open} from 'components/Ui/Icon';
import moment from 'moment';
import useSearchParams from '../useSearchParams';

type Props = {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

export default ({isOpen, setModal}: Props) => {
  const [success, setResult] = useState(false);

  const {searchParams} = useSearchParams();

  const [
    createAllProjectsConversionRateTelStatusDailyDownload,
    {
      data: {
        createAllProjectsConversionRateTelStatusDailyDownload: {
          reportDownload = {},
        } = {},
      } = {},
      loading,
    },
  ] = useCreateAllProjectsConversionRateTelStatusDailyDownloadMutation({
    onCompleted: () => {
      setResult(true);
    },
  });

  const filename = `日次-転換率-ステータス・カテゴリ別-${moment().format(
    'YYYY-MM-DD',
  )}`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
          zIndex: 100,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '10px',
          width: '654px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
        },
      }}
      contentLabel="modal">
      <ModalHeader>
        <h2>csvダウンロード</h2>
        <Button onClick={() => setModal(false)}>
          <Close />
          閉じる
        </Button>
      </ModalHeader>

      <ModalBody>
        <SuccessBody>
          <SuccessNote>
            <p>
              ダウンロードを行う場合は、 <br />
              ダウンロードボタンをクリックしてください。
            </p>
          </SuccessNote>

          {!loading && success ? (
            <OpenCsvButton
              type="primary"
              onClick={() => {
                window.location.href = `/download/report_downloads/${reportDownload.uuid}/export_all_projects_conversion_rate_tel_status_daily_report`;
              }}>
              <Open />
              CSVファイルをダウンロード
            </OpenCsvButton>
          ) : (
            <OpenCsvButton
              type="primary"
              loading={loading}
              onClick={() => {
                createAllProjectsConversionRateTelStatusDailyDownload({
                  variables: {
                    search: searchParams,
                  },
                });
              }}>
              <Open />
              ダウンロード
            </OpenCsvButton>
          )}

          <FileType>
            ファイル形式: CSV / ファイル名: {`${filename}.csv`}
          </FileType>
        </SuccessBody>
      </ModalBody>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  padding: 2rem 3rem 0;
  position: relative;

  h2 {
    font-weight: bold;
    font-size: 17.5px;
  }

  button {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    box-shadow: none;
    border: none;
    display: flex;
    svg {
      margin: auto 0;
      height: 16px;
      width: 25px;
      path {
        fill: var(--text-color-3);
      }
    }

    span {
      margin: auto 0;
      color: var(--text-color-3);
    }
  }
`;

const ModalBody = styled.div``;

const DefaultButton = styled(Button)`
  border-radius: 4px;
  height: 35px;
`;

const SuccessBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessNote = styled.div`
  margin-top: 2rem;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  text-align: center;
`;

const OpenCsvButton = styled(DefaultButton)`
  height: 45px;
  width: 240px;
  margin: 1rem 0;

  svg {
    margin-right: 10px;
    position: relative;
    top: 4px;
  }
`;

const FileType = styled.div`
  font-size: 12px;
  color: var(--text-color-3);
  margin-bottom: 3rem;
`;
