import React from 'react';
import {useHistory} from 'react-router';
import {ContentForm} from 'components/Ui/Icon';
import {useCreateContentMailFormMutation} from 'api';

export default () => {
  const history = useHistory();

  const [createContentMailForm] = useCreateContentMailFormMutation({
    onCompleted: ({createContentMailForm: {content = {}}}) => {
      history.push(`/garage/contents/${content.uuid}/mail_form`);
    },
  });

  return (
    <div onClick={() => createContentMailForm()}>
      <ContentForm color="var(--primary)" className="active" />
      <ContentForm color="var(--text-color-2)" className="inactive" />
      <p>
        メールテンプレート
        <br />
        (フォーム送信用)
      </p>
    </div>
  );
};
