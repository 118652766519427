import gql from 'graphql-tag';

export default gql`
  fragment accountScoreSettingFragment on AccountScoreSetting {
    id
    subCategoryIds
    accountClosingMonthBefore
    weightSubCategory
    weightEmployeeRange
    employeeRangesGroupByPriority
    capitalFundRangesGroupByPriority
    subCategoriesGroupByPriority
    weightCapitalFundRange
    weightAccountClosingMonth
    updateStatus
  }
`;
