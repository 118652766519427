import React from 'react';
import styled from 'styled-components';
import {Link, Route, Switch} from 'react-router-dom';
import CallReport from './CallReport';
import PreleadReport from './PreleadReport';
import ConversionRateReport from './ConversionRateReport';
import CallRateReport from './CallRateReport';

export default () => {
  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('/call')}>
          <Link to={`/report/call/monthly/call${location.search}`}>
            コール数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/prelead')}>
          <Link to={`/report/call/monthly/prelead${location.search}`}>
            プレリード数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/conversion_rate')}>
          <Link to={`/report/call/monthly/conversion_rate${location.search}`}>
            転換率
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/call_rate')}>
          <Link to={`/report/call/monthly/call_rate${location.search}`}>
            対コール率
          </Link>
        </TabItem>
      </Tabs>

      <div>
        <Switch>
          <Route path="/report/call/monthly/call" component={CallReport} />
          <Route
            path="/report/call/monthly/prelead"
            component={PreleadReport}
          />
          <Route
            path="/report/call/monthly/conversion_rate"
            component={ConversionRateReport}
          />
          <Route
            path="/report/call/monthly/call_rate"
            component={CallRateReport}
          />
        </Switch>
      </div>
    </Container>
  );
};

const Container = styled.div``;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
