import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Content} from 'api';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  return (
    <Container>
      <h4>コンテンツ情報</h4>
      <DL>
        <dt>登録日</dt>
        <dd>
          {moment(content.mailTemplate.createdAt).format('YYYY/MM/DD HH:mm')}
        </dd>
        <dt>更新日</dt>
        <dd>
          {moment(content.mailTemplate.updatedAt).format('YYYY/MM/DD HH:mm')}
        </dd>
        <dt>作成者</dt>
        <dd>
          {content.mailTemplate.user.lastName}{' '}
          {content.mailTemplate.user.firstName}
        </dd>
      </DL>
    </Container>
  );
};

const Container = styled.div`
  h4 {
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: solid 1px #e1e6eb;
  }
`;

const DL = styled.dl`
  font-size: 13px;

  dt {
    color: var(--text-color-3)
  }

  dd {
    margin-bottom 1rem;
    display: flex;
    align-items: center;

    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
