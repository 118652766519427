import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_15957:216917)">
      <path
        d="M13.3498 0.695022L0.633789 4.70569L1.83699 8.52049L14.553 4.50982L13.3498 0.695022Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 9.40002H9.33335"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66669 8.40005V14.1334C2.66669 14.5334 2.93335 14.8001 3.33335 14.8001H12.6667C13.0667 14.8001 13.3334 14.5334 13.3334 14.1334V6.80005H7.60002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15957:216917">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
