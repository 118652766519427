import React from 'react';
import styled from 'styled-components';
import {
  MailCampaignBlock,
  MailCampaignBlockButton,
  MailCampaignBlockButtonAttributes,
  useUpdateMailCampaignBlockButtonMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input} from 'components/antd';
import ColorInput from './ColorInput';
import PaddingInput from './PaddingInput';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockButton;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {
    bodyColor,
    textColor,
    text,
    link,
    buttonColor,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = block.blockable;

  const [update] = useUpdateMailCampaignBlockButtonMutation();

  const formik = useFormik({
    initialValues: {
      text,
      link,
      bodyColor,
      textColor,
      buttonColor,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    },
    validationSchema: Yup.object().shape({
      text: Yup.string(),
      bodyColor: Yup.string().nullable(),
      textColor: Yup.string().nullable(),
      buttonColor: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      update({
        variables: {
          uuid: block.uuid,
          attributes: values,
        },
      });
    },
  });

  const debouncedValue = useDebounce<MailCampaignBlockButtonAttributes>(
    formik.values,
    500,
  );

  React.useEffect(() => {
    formik.submitForm();
  }, [debouncedValue]);

  return (
    <Container>
      <h2>ボタンを編集</h2>
      <Form layout="vertical">
        <Form.Item label="ボタンテキスト">
          <Input {...formik.getFieldProps('text')} />
        </Form.Item>
        <Form.Item label="リンク">
          <Input
            placeholder="例：https://leadpad.io/"
            {...formik.getFieldProps('link')}
          />
        </Form.Item>
        <Form.Item label="余白">
          <PaddingInput
            values={{
              paddingTop: formik.values.paddingTop,
              paddingBottom: formik.values.paddingBottom,
              paddingLeft: formik.values.paddingLeft,
              paddingRight: formik.values.paddingRight,
            }}
            onChange={(values) => {
              formik.setValues({...formik.values, ...values});
            }}
          />
        </Form.Item>
        <Form.Item label="ボタンの色">
          <ColorInput
            color={formik.values.buttonColor}
            onChange={(color) => formik.setFieldValue('buttonColor', color)}
          />
        </Form.Item>
        <Form.Item label="背景色">
          <ColorInput
            color={formik.values.bodyColor}
            onChange={(color) => formik.setFieldValue('bodyColor', color)}
          />
        </Form.Item>
        <Form.Item label="文字色">
          <ColorInput
            color={formik.values.textColor}
            onChange={(color) => formik.setFieldValue('textColor', color)}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  label {
    font-size: 14px;
    color: var(--text-color-2);
    font-weight: 500;
  }
`;
