import React from 'react';
import styled from 'styled-components';
import SentCount from '../SentCount';
import PreleadCount from '../PreleadCount';
import ClickCount from '../ClickCount';
import {useParams} from 'react-router';
import {
  useProjectMailStatusCountReportsQuery,
  useProjectUrlClickReportQuery,
} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectMailStatusSummaryReport = {}} = {}} =
    useProjectMailStatusCountReportsQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'network-only',
    });

  const mailCount = projectMailStatusSummaryReport?.mailCount || 0;
  const clickCount = projectMailStatusSummaryReport?.clickCount || 0;
  const clickRate = mailCount ? ((clickCount / mailCount) * 100).toFixed(1) : 0;

  const {data: {projectUrlClickReport = []} = {}} =
    useProjectUrlClickReportQuery({
      variables: {
        projectUuid: projectId,
      },
    });

  return (
    <>
      <CountSection>
        <PreleadCount />
        <SentCount mailCount={mailCount} />
        <ClickCount mailCount={mailCount} clickCount={clickCount} />
      </CountSection>

      <Container>
        <ClickUrlContainer>
          <h2>クリック状況</h2>
          <Table>
            <div className="column far-left">
              <div className="category" />
              <div className="category">
                <span>クリックあり</span>
              </div>
              {projectUrlClickReport.map((report) => (
                <React.Fragment key={report.url}>
                  <div className="category">
                    <a href={report.url} target="_blank">
                      {report.url}
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className="column">
              <div className="category title">
                <span>プレリード数</span>
              </div>
              <div className="category">
                <span>{clickCount}</span>
              </div>
              {projectUrlClickReport.map((report) => (
                <React.Fragment key={report.url}>
                  <div className="category">
                    <span>{report.preleadCount}</span>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className="column">
              <div className="category title">
                <span>対送信数</span>
              </div>
              <div className="category">
                <span>{clickRate}%</span>
              </div>
              {projectUrlClickReport.map((report) => (
                <React.Fragment key={report.url}>
                  <div className="category">
                    <span>
                      {((report.preleadCount / mailCount) * 100).toFixed(1)}%
                    </span>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </Table>
        </ClickUrlContainer>
      </Container>
    </>
  );
};

const CountSection = styled.div`
  margin-bottom: 37px;
  display: flex;

  > div:not(:first-child) {
    margin-left: 10px;
  }
`;

const Container = styled.div`
  display: flex;

  > div:first-child {
    margin-bottom: 59px;
  }
`;

const ClickUrlContainer = styled.div`
  flex-direction: column;

  > h2 {
    font-weight: bold;
    font-size: 20px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  width: 872px;

  .column {
    flex: 1;

    > div {
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;
      display: flex;
      align-items: center;

      > span {
        margin: 0 9px 0 auto;
      }
      &.title > span {
        font-weight: bold;
        margin: 0 auto;
      }
      &:nth-child(3) {
        border-top: 3px double #e1e6eb;
      }
    }

    &:last-child > div {
      border-right: none;
    }

    &.far-left > div {
      width: 684px;
      padding: 0 30px;

      &.category {
        svg {
          margin-right: 5px;
        }
        span {
          font-weight: bold;
        }
      }
      > span {
        margin: 0;
        font-size: 13px;
      }
    }
  }
`;
