import React, {useEffect, useMemo, useState} from 'react';
import {Quill, QuillWrapper, StatusEclipse} from 'components/Ui';
import {
  Button,
  DatePicker,
  Select,
  TimePicker,
  Upload,
  Tooltip,
} from 'components/antd';
import styled from 'styled-components';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {Paperclip, Cross, Refresh} from 'components/Ui/Icon';
import {
  usePreleadQuery,
  useGetPreleadProjectsQuery,
  useProjectQuery,
  useCreateTelCommentActivityMutation,
} from 'api';
import telCategoryColor from 'helpers/telCategoryColor';
import TimelineItem from 'components/TimelineItem';
import {SelectProject} from '../SelectProject';
import ReceptionNgModal from './ReceptionNgModal';
import ContactNgModal from './ContactNgModal';
import CallNgModal from './CallNgModal';
import usePreleadContext from '../usePreleadContext';
import _ from 'lodash';

export default () => {
  const {preleadId} = usePreleadContext();
  const [comment, setComment] = useState('');
  const [bodyPlainText, setBodyPlainText] = useState(null);
  const [date, setDate] = useState(moment().format('YYYY/MM/DD'));
  const [time, setTime] = useState(moment().format('HH:mm'));
  const [status, setStatus] = useState(null);
  const [files, setFiles] = useState([]);
  const [preleadProjectId, setPreleadProjectId] = useState(null);
  const [projectUuid, setProjectUuid] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [callNgReason, setCallNgReason] = useState(null);
  const [receptionNgReason, setReceptionNgReason] = useState(null);
  const [contactNgReason, setContactNgReason] = useState(null);
  const [ngCallModalVisible, setCallNgModalVisible] = useState(false);
  const [ngReceptionModalVisible, setReceptionNgModalVisible] = useState(false);
  const [ngContactModalVisible, setContactNgModalVisible] = useState(false);

  const {
    data: {prelead = {}} = {},
    refetch,
    loading: loadingPrelead,
  } = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
    fetchPolicy: 'cache-and-network',
  });

  const {data: {prelead: {preleadProjects = [], projects = []} = {}} = {}} =
    useGetPreleadProjectsQuery({
      variables: {uuid: preleadId},
      skip: !preleadId,
    });

  const uniqProjects = useMemo(() => _.uniq(projects), [projects]);

  const handleSelectProject = (selectedProjectId: string) => {
    if (!selectedProjectId) return;

    const projectUuid = uniqProjects.find(
      (project) => project.id === selectedProjectId,
    )?.uuid;
    setProjectUuid(projectUuid);
    const preleadProject = preleadProjects.find(
      (preleadProject) => preleadProject.projectId === selectedProjectId,
    );
    if (!preleadProject) return;

    setPreleadProjectId(preleadProject.uuid);
    setProjectId(selectedProjectId);
  };

  const [createTelCommentActivity, {loading}] =
    useCreateTelCommentActivityMutation({
      onCompleted: () => {
        setComment('');
        setBodyPlainText(null);
        setDate(moment().format('YYYY/MM/DD'));
        setTime(moment().format('HH:mm'));
        setStatus(null);
        setFiles([]);
        refetch();
      },
    });

  const addTelActivity = () => {
    const recordedAt = moment(`${date} ${time}`, 'YYYY/MM/DD HH:mm');

    createTelCommentActivity({
      variables: {
        uuid: preleadProjectId,
        files,
        telCommentInput: {
          body: comment === `<p><br></p>` ? '' : comment,
          bodyPlainText: bodyPlainText,
          recordedAt,
        },
        telStatusChangeInput: {
          clientTelStatusId: status,
          projectCallNgReasonId: callNgReason?.id,
          projectReceptionNgReasonId: receptionNgReason?.id,
          projectContactNgReasonId: contactNgReason?.id,
        },
      },
    });
  };

  const {data: {project: {telStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectUuid},
    skip: !projectUuid,
  });

  useEffect(() => {
    if (!status) return;

    const telStatus = telStatuses.find((telStatus) => telStatus.id === status);
    if (!telStatus) return;

    if (telStatus?.category === 'call_ng') {
      setCallNgModalVisible(true);
    } else if (telStatus?.category === 'reception_ng') {
      setReceptionNgModalVisible(true);
    } else if (telStatus?.category === 'contact_ng') {
      setContactNgModalVisible(true);
    }

    setCallNgReason(null);
    setReceptionNgReason(null);
    setContactNgReason(null);
  }, [status]);

  useEffect(() => {
    if (prelead?.lastProjectId) {
      handleSelectProject(prelead.lastProjectId);
    }
  }, [prelead?.id, preleadProjects]);

  if (!preleadId || !prelead) return null;

  return (
    <Container>
      <RefreshActions>
        <Button
          onClick={() => refetch()}
          icon={<Refresh spin={loadingPrelead} />}
          disabled={loadingPrelead}
        />
      </RefreshActions>
      <SelectProject
        setProjectId={handleSelectProject}
        projects={uniqProjects}
        value={projectId}
      />

      <QuillWrapper>
        <Quill
          placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
          theme="snow"
          value={comment}
          onChange={(content, delta, source, editor) => {
            setComment(content);
            setBodyPlainText(editor.getText());
          }}
          modules={{
            toolbar: [
              [{header: [1, 2, 3, false]}],
              ['bold', 'italic', 'underline'],
              ['code-block'],
              ['link'],
            ],
          }}
          formats={[
            'header',
            'list',
            'bold',
            'italic',
            'underline',
            'strike',
            'color',
            'background',
            'code-block',
            'link',
          ]}
          bounds={'#quill-container'}
        />
        <div id="quill-container" />
        {files.map((file) => (
          <File key={file.uid}>
            <FileName>{file.name}</FileName>
            <div onClick={() => setFiles(files.filter((f) => f !== file))}>
              <Cross />
            </div>
          </File>
        ))}
        <Actions>
          <StatusSelect
            value={status}
            onSelect={(value: any) => setStatus(value)}
            dropdownMatchSelectWidth={false}
            dropdownClassName="tel-status-dropdown"
            optionLabelProp="label">
            {telStatuses?.map((telStatus) => (
              <StatusSelect.Option
                value={telStatus.id}
                key={telStatus.id}
                label={
                  <React.Fragment>
                    <StatusEclipse
                      color={telCategoryColor(telStatus.category)}
                    />
                    &nbsp; {telStatus.name}
                    {callNgReason && `  |  ${callNgReason.reason}`}
                    {receptionNgReason && `  |  ${receptionNgReason.reason}`}
                    {contactNgReason && `  |  ${contactNgReason.reason}`}
                  </React.Fragment>
                }>
                <StatusEclipse color={telCategoryColor(telStatus.category)} />
                &nbsp; {telStatus.name}
              </StatusSelect.Option>
            ))}
          </StatusSelect>
          <DatePicker
            allowClear={false}
            defaultValue={moment()}
            format="YYYY/MM/DD"
            value={moment(date, 'YYYY/MM/DD')}
            locale={locale}
            style={{width: '110px'}}
            onChange={(_, dateText) => setDate(dateText)}
          />
          <TimePicker
            allowClear={false}
            defaultValue={moment()}
            value={moment(time, 'HH:mm')}
            format="HH:mm"
            minuteStep={15}
            locale={locale}
            style={{width: '75px'}}
            onChange={(_, timeText) => setTime(timeText)}
          />
          <Upload
            name={'files'}
            customRequest={() => false}
            showUploadList={false}
            accept=".pdf, .mp3"
            onChange={(info) => {
              const addedFile = info.file.originFileObj;
              const upload_size_limit = 25000000; // 25MB

              let fileSize = addedFile.size;
              files.forEach((file) => (fileSize += file.size));
              if (fileSize > upload_size_limit) {
                alert(
                  'ファイルの容量が25MBを超えています。\n合計25MBまでの添付が可能です。',
                );
                return null;
              }

              setFiles([...files, addedFile]);
            }}>
            <Tooltip
              title="ファイルを添付(25MBまで)"
              color={'var(--text-color-0)'}
              placement="top">
              <Button icon={<Paperclip />} style={{marginRight: 10}} />
            </Tooltip>
          </Upload>
          <Button
            disabled={
              !projectId ||
              (!comment.replace(/<\/?[^>]+(>|$)/g, '') &&
                !status &&
                files.length === 0) ||
              loading
            }
            onClick={addTelActivity}>
            登録
          </Button>
        </Actions>
      </QuillWrapper>

      {prelead?.telActivities?.map((activity) => (
        <TimelineItem key={activity.uuid} activity={activity} />
      ))}

      {ngCallModalVisible && (
        <CallNgModal
          projectId={projectUuid}
          isModalVisible={ngCallModalVisible}
          setModalVisible={setCallNgModalVisible}
          ngReason={callNgReason}
          setNgReason={setCallNgReason}
        />
      )}

      {ngReceptionModalVisible && (
        <ReceptionNgModal
          projectId={projectUuid}
          isModalVisible={ngReceptionModalVisible}
          setModalVisible={setReceptionNgModalVisible}
          ngReason={receptionNgReason}
          setNgReason={setReceptionNgReason}
        />
      )}

      {ngContactModalVisible && (
        <ContactNgModal
          projectId={projectUuid}
          isModalVisible={ngContactModalVisible}
          setModalVisible={setContactNgModalVisible}
          ngReason={contactNgReason}
          setNgReason={setContactNgReason}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Actions = styled.div`
  margin-top: 10px;
`;

const StatusSelect = styled(Select)`
  flex: 1;

  .ant-select-selector {
    max-width: 232px;
  }

  .ant-select-arrow {
    svg {
      margin-left: 5px;
      width: 10px;
      height: 10px;

      path {
        fill: var(--text-color-1);
      }
    }
  }
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;

  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;

    path {
      stroke: var(--text-color-2);
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: var(--primary);
`;

const RefreshActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
