import {Breadcrumb} from 'components/Ui';
import React from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import Menu from './Menu';
import {
  BlockGroupMember,
  useBlockGroupMemberQuery,
  useBlockGroupMembersQuery,
} from 'api';
import {useQueryParams} from 'use-query-params';
import Detail from './detail';
import {
  blockMembersQueryParamsDef,
  blockMembersSearchParams,
} from './blockMembersQueryParams';
import GridTable from 'components/Ui/GridTable';
import {sideMenuWidth} from 'helpers/sideMenu';
import {
  Cell as CellType,
  useBlockLayout,
  useResizeColumns,
  useTable,
} from 'react-table';
import Cell from './Cell';
import {useRecoilState} from 'recoil';
import checkedBlockMembersAtom from './checkedBlockMembersAtom';

export default () => {
  const {blockGroupId, blockGroupMemberId} = useParams<{
    blockGroupId: string;
    blockGroupMemberId: string;
  }>();

  const [query, setQuery] = useQueryParams(blockMembersQueryParamsDef);

  const [, setCheckedBlockMemberIds] = useRecoilState(checkedBlockMembersAtom);

  const {data: {blockGroupMember = null} = {}} = useBlockGroupMemberQuery({
    variables: {uuid: blockGroupMemberId},
    fetchPolicy: 'cache-and-network',
    skip: !blockGroupMemberId,
  });

  const {
    data: {
      blockGroup = {},
      blockGroupMembers: {blockGroupMembers = [], pagination = {}} = {},
    } = {},
    loading,
  } = useBlockGroupMembersQuery({
    variables: {
      uuid: blockGroupId,
      page: query.page,
      search: blockMembersSearchParams(query),
    },
    skip: !blockGroupId,
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setCheckedBlockMemberIds([]);
    },
  });

  const data: any[] = React.useMemo(
    () =>
      blockGroupMembers.map((blockGroupMember, i) => {
        return {
          index: i,
          key: blockGroupMember.id,
          ...blockGroupMember,
        };
      }),
    [blockGroupMembers],
  );

  const columns: any[] = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'uuid',
        width: 38,
        align: 'center',
        visible: true,
        sticky: true,
        Cell: Cell.Check,
      },
      {
        Header: '社名',
        accessor: 'name',
        width: 330,
        visible: true,
        sticky: true,
        Cell: ({value, row}: CellType<BlockGroupMember>) => (
          <CompanyName
            to={`/block/block_groups/${blockGroupId}/block_members/${row.original.uuid}${location.search}`}>
            {value}
          </CompanyName>
        ),
      },
      {
        Header: '名寄せ',
        accessor: 'companyId',
        width: 56,
        align: 'center',
        visible: true,
        Cell: Cell.Nayose,
      },
      {
        Header: 'URL',
        accessor: 'webSite',
        width: 200,
        visible: true,
      },
      {
        Header: 'TEL',
        accessor: 'telephoneNumber',
        width: 160,
        visible: true,
      },
      {
        Header: '法人番号',
        accessor: 'corpNumber',
        width: 175,
        visible: true,
      },
      {
        Header: '住所',
        accessor: 'address',
        minWidth: 600,
        visible: true,
      },
    ],
    [],
  );

  const tableData = useTable({columns, data}, useBlockLayout, useResizeColumns);

  const paginationData = React.useMemo(() => {
    return {
      total: pagination.totalCount,
      current: pagination.currentPage,
      pageSize: 20,
      onChange: (page: number) => setQuery({page}),
    };
  }, [pagination]);

  return (
    <Container>
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/block/block_groups/`}>ブロックグループ</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/block/block_groups/${blockGroupId}`}>
              {blockGroup.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {blockGroupMember ? (
              <Link
                to={`/block/block_groups/${blockGroupId}/block_members${location.search}`}>
                ブロックリスト
              </Link>
            ) : (
              <span className="current">ブロックリスト</span>
            )}
          </Breadcrumb.Item>
          {blockGroupMember && (
            <Breadcrumb.Item>
              <span className="current">{blockGroupMember.name}</span>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </Header>
      <Menu />
      <Body id="block-list-table">
        <GridTable {...tableData} {...paginationData} loading={loading} />
        <Detail />
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'menu'
    'body';
  grid-template-rows: 70px 50px calc(100vh - 180px);
`;

const Header = styled.div`
  padding-left: 32px;
  border-bottom: 1px solid #e1e6eb;
  background: #f3f5f7;
`;

const Body = styled.div`
  width: calc(100vw - ${sideMenuWidth}px);
  position: relative;
  display: flex;
`;

const CompanyName = styled(Link)`
  color: var(--text-color-0);
  flex: 1;
`;
