import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Table} from 'components/antd';
import Tab from '../Tab';
import {TeamUser, useTeamQuery, useResendSignupMailMutation} from 'api';
import {Mail, Trash} from 'components/Ui/Icon';
import RemoveUserModal from './RemoveUserModal';

export default () => {
  const {teamId} = useParams<{teamId: string}>();

  const [targetUser, setTargetUser] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const {data: {team = null} = {}} = useTeamQuery({
    variables: {uuid: teamId},
    fetchPolicy: 'cache-and-network',
  });

  const [resendSignupMail] = useResendSignupMailMutation();

  if (!team) return null;

  const columns = [
    {
      title: 'email',
      dataIndex: 'email',
      render: (_: any, record: TeamUser) => <div>{record.user.email}</div>,
    },
    {
      title: 'ユーザー名',
      dataIndex: 'userName',
      render: (_: any, record: TeamUser) => <div>{record.user.userName}</div>,
    },
    {
      title: '名前',
      render: (_: any, record: TeamUser) => (
        <ContentWrapper>
          <div>
            {record.user.lastName} {record.user.firstName}
          </div>
          <TableActionWrapper>
            {!record.user.confirmedAt && (
              <ResendSignupMailButton
                type="button"
                onClick={() => {
                  confirm('サインアップメールを再送信しますか？') &&
                    resendSignupMail({
                      variables: {user: {email: record.user.email}},
                    });
                }}>
                <Mail />
                <span>再送信</span>
              </ResendSignupMailButton>
            )}
            <RemoveUserFromTeamButton
              onClick={() => {
                setTargetUser(record.user);
                setModalOpen(true);
              }}>
              <Trash />
            </RemoveUserFromTeamButton>
          </TableActionWrapper>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <Container>
      <Tab />
      <TableWrapper>
        {isModalOpen && (
          <RemoveUserModal
            isOpen={isModalOpen}
            setModal={setModalOpen}
            user={targetUser}
          />
        )}
        <Table
          size="middle"
          rowKey="id"
          pagination={false}
          columns={columns}
          dataSource={team.teamUsers}></Table>
      </TableWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100vw - 350px);
  padding: 28px 70px 45px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.15px;

  .ant-table-thead {
    > tr > th {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      color: var(--text-color-1);
    }
  }

  .ant-table-tbody {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableActionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 20px;
`;

const ResendSignupMailButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  background: #fff;
  padding: 8px 10px;
  outline: 0;
  margin-right: 38px;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    color: var(--text-color-1);
  }

  &:hover {
    cursor: pointer;
  }
`;

const RemoveUserFromTeamButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
