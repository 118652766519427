import React from 'react';
import {
  SalesforceAccountRecordType,
  useSalesforceAccountRecordTypeQuery,
} from 'api';
import AccountField from './AccountField';
import CompanyUpdateSetting from './CompanyUpdateSetting';

interface Props {
  salesforceAccountRecordType: SalesforceAccountRecordType;
}

export default ({salesforceAccountRecordType}: Props) => {
  const {
    data: {
      salesforceAccountRecordType: {salesforceAccountLeadpadFields = []} = {},
    } = {},
  } = useSalesforceAccountRecordTypeQuery({
    variables: {
      id: salesforceAccountRecordType?.id,
    },
    skip: !salesforceAccountRecordType,
  });

  return (
    <div>
      {salesforceAccountLeadpadFields.map(
        (salesforceAccountLeadpadField, i) => {
          return (
            <div
              className="flex items-center border-b border-b-[#F3F5F7] py-4"
              key={i}>
              <div className="text-foreground-dark w-96 px-6 text-base">
                {salesforceAccountLeadpadField.leadpadFieldText}
              </div>
              <AccountField
                salesforceAccountLeadpadField={salesforceAccountLeadpadField}
                salesforceAccountRecordType={salesforceAccountRecordType}
              />
              <CompanyUpdateSetting />
            </div>
          );
        },
      )}
    </div>
  );
};
