import React from 'react';

function Icon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M1.8666 12.1334C1.5166 12.1334 1.22493 12.0167 0.991602 11.7834C0.758268 11.55 0.641602 11.2584 0.641602 10.9084V3.0917C0.641602 2.7417 0.758268 2.45003 0.991602 2.2167C1.22493 1.98337 1.5166 1.8667 1.8666 1.8667H4.08327C4.43327 1.8667 4.72494 1.98337 4.95827 2.2167C5.1916 2.45003 5.30827 2.7417 5.30827 3.0917V3.67503H9.50827C9.85827 3.67503 10.1499 3.7917 10.3833 4.02503C10.6166 4.25837 10.7333 4.55003 10.7333 4.90003V7.4667H12.4833C12.6583 7.4667 12.8333 7.52503 13.0083 7.58337C13.1249 7.6417 13.2416 7.75837 13.2999 7.93337C13.3583 8.05003 13.3583 8.1667 13.3583 8.28337C13.3583 8.5167 13.2999 8.6917 13.1249 8.92503L11.0249 11.4917C10.8499 11.725 10.6166 11.8417 10.3249 12.0167C10.0333 12.1334 9.7416 12.25 9.50827 12.25H1.8666V12.1334Z"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.69163 12.1335C1.4583 12.1335 1.2833 12.0751 1.1083 11.9585C0.874967 11.8418 0.758301 11.6085 0.758301 11.3168C0.758301 11.1418 0.816634 10.9085 0.991634 10.7335L3.09163 8.1668C3.26663 7.93346 3.49997 7.8168 3.79163 7.6418C4.0833 7.52513 4.37497 7.4668 4.6083 7.4668H12.3083C12.5416 7.4668 12.7166 7.52513 12.8916 7.6418C13.125 7.75846 13.2416 7.9918 13.2416 8.28346C13.2416 8.5168 13.1833 8.6918 13.0083 8.92513L10.9083 11.4918C10.7333 11.7251 10.5 11.9001 10.2083 12.0168C9.91664 12.1335 9.62497 12.2501 9.39163 12.2501H1.69163V12.1335Z"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
