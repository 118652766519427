import React from 'react';
import styled from 'styled-components';
import {useTable} from 'react-table';
import {Pagination as UIPagination} from 'components/antd';
import {ProspectPool, Pagination} from 'api';

interface Props {
  prospectPools: ProspectPool[];
  pagination: Pagination;
  setPage: (page: number) => void;
  perPage: number;
}

export default ({prospectPools, pagination, setPage, perPage}: Props) => {
  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: '',
        accessor: (row: any) =>
          `${row.prospect.lastName} ${row.prospect.firstName || ''}`,
      },
      {
        Header: '',
        accessor: 'prospect.account.name',
      },
      {
        Header: '',
        accessor: 'prospect.email',
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<ProspectPool>({columns, data: prospectPools || []});

  return (
    <Container>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <th {...column.getHeaderProps()} key={j}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={perPage}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 0.5rem 1rem;
    margin: 0px;

    &:first-child {
      padding-left: 0;
    }
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: var(--text-color-2);
        text-align: left;
        border-bottom: solid 1px #e1e6eb;

        &:first-child {
          padding: 3px 1rem 3px 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: solid 1px #e1e6eb;
        cursor: pointer;
      }
    }
  }
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;
