import React from 'react';
import styled from 'styled-components';
import {useCurrentClientQuery} from 'api';
import {Link} from 'react-router-dom';
import OfficeBuildingPurple from './OfficeBuildingPurple';
import {Share} from 'components/Ui/Icon';

export default () => {
  const {data: {currentClient: {offeredClients = []} = {}} = {}} =
    useCurrentClientQuery();

  return (
    <Container>
      <h3>依頼先</h3>
      {offeredClients.map((client) => (
        <Item key={client.id}>
          <ImageBox>
            <OfficeBuildingPurple />
          </ImageBox>
          <Name to={`/list/share/offered/${client.uuid}/projects`}>
            {client.name}
          </Name>
          <LabelBox>
            <ProjectLink to={`/list/share/offered/${client.uuid}/projects`}>
              <Share />
              <span>Projects</span>
            </ProjectLink>
          </LabelBox>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  width: 100%;

  > h3 {
    margin-left: 35px;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: var(--text-color-3);
  }
`;

const Item = styled.div`
  margin-bottom: 5px;
  padding: 9px 20px 9px 13px;
  display: flex;
  align-items: center;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
`;

const ImageBox = styled.div`
  margin-left: 14px;
  display: flex;
  width: 42px;
  height: 42px;

  background: #e6ddff;
  border: 1px solid var(--border-color);
  border-radius: 4px;

  > * {
    margin: auto;
  }
`;

const Name = styled(Link)`
  margin-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabelBox = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  > * {
    margin-left: 12px;
  }
`;

const ProjectLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #eeeafb;
  border-radius: 2px;
  svg {
    margin-right: 4px;
  }
  span {
    font-weight: bold;
    font-size: 9px;
    color: var(--text-color-1);
  }
`;
