import styled from 'styled-components';
import {Checkbox} from 'components/antd';

export default styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--primary);
    border-radius: 3px;
    border-color: var(--primary);
  }
  .ant-checkbox-checked::after {
    border-color: var(--primary);
  }
  .ant-checkbox-wrapper:hover {
    border-color: var(--primary) !important;
  }
`;
