import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router';
import * as Yup from 'yup';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Close} from 'components/Icon';
import {useFormik} from 'formik';
import {Button, Form, Input, message, Tooltip} from 'components/antd';
import {
  User,
  TeamUser,
  useTeamQuery,
  useUsersByClientAllQuery,
  useInviteUserToTeamMutation,
  useCreateAndInviteUserToTeamMutation,
  Team,
} from 'api';
import {useParams} from 'react-router';
import {Mail, Plus, Search} from 'components/Ui/Icon';
import useContract from 'hooks/useContract';

type Props = {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

const validateSchema = Yup.object().shape({
  email: Yup.string()
    .email('メールアドレス形式ではありません')
    .required('必須項目です'),
});

const InTeam = (user: User, teamUsers: TeamUser[]) => {
  const team_user_ids = teamUsers.map((team_user) => team_user.userId);
  return team_user_ids.includes(user.id);
};

export default ({isOpen, setModal}: Props) => {
  const history = useHistory();
  const {teamId} = useParams<{teamId: string}>();
  const [keyword, setKeyword] = useState('');

  const {data: {team = null} = {}} = useTeamQuery({
    variables: {uuid: teamId},
    fetchPolicy: 'cache-and-network',
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const {isUserAddable} = useContract();

  const filterUsers: User[] = useMemo(() => {
    let tempUsers = [...users];

    if (keyword) {
      tempUsers = tempUsers.filter(
        (user: User) =>
          user?.userName?.indexOf(keyword) > -1 ||
          user?.email?.indexOf(keyword) > -1,
      );
    }
    return tempUsers;
  }, [users, keyword]);

  const [createAndInviteUserToTeam, {loading, data}] =
    useCreateAndInviteUserToTeamMutation({
      refetchQueries: ['usersByClient', 'usersByClientAll'],
      onCompleted: (data: any) => {
        if (!data?.createAndInviteUserToTeam?.error) {
          setModal(false);
          message.success('招待しました');
          history.push(`/settings/teams/${teamId}/users`);
        }
      },
    });

  const [inviteUserToTeam] = useInviteUserToTeamMutation({
    onCompleted: (data: any) => {
      if (!data?.inviteUserToTeam?.error) {
        setModal(false);
        message.success('招待しました');
        history.push(`/settings/teams/${teamId}/users`);
      }
    },
  });

  const addUserToTeam = (user: User, team: Team) => {
    inviteUserToTeam({
      variables: {
        userId: user.id,
        teamId: team.id,
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      clientRole: 'member',
      teamIds: [team.id],
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      createAndInviteUserToTeam({
        variables: {user: values},
      });
    },
  });

  const {
    dirty,
    isValid,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '4px',
          width: '466px',
          height: '625px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
        },
      }}
      contentLabel="modal">
      <ModalContent>
        <ModalHeader>
          <Button onClick={() => setModal(false)}>
            <Close />
          </Button>
        </ModalHeader>
        <ModalBody>
          <ModalTitle>ユーザを追加</ModalTitle>
          <SearchTextBox
            type="text"
            name="search"
            onChange={(event: any) => setKeyword(event.target.value)}
            placeholder="ユーザ名"
            prefix={<Search />}
            bordered={false}
          />
          <UserList>
            {filterUsers.map((user: User) => {
              return (
                <UserListContent key={user.uuid}>
                  <UserInfo>
                    <UserName>{user.userName}</UserName>
                    <UserEmail>{user.email}</UserEmail>
                  </UserInfo>
                  {InTeam(user, team?.teamUsers) ? (
                    <div></div>
                  ) : (
                    <InviteToTeam onClick={() => addUserToTeam(user, team)}>
                      <Plus color="#fff" />
                    </InviteToTeam>
                  )}
                </UserListContent>
              );
            })}
          </UserList>
          <Form onFinish={handleSubmit}>
            <Form.Item
              required
              validateStatus={errors.email && touched.email ? 'error' : ''}
              help={errors.email && touched.email ? errors.email : ''}>
              <EmailTextBox
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="新規ユーザーをメールアドレスから招待"
                prefix={<Mail />}
              />
              {data?.createAndInviteUserToTeam?.error && !errors.email && (
                <Alert>{data.createAndInviteUserToTeam.error}</Alert>
              )}
            </Form.Item>
            <ButtonWrapper>
              {isUserAddable ? (
                <InviteButton
                  htmlType="submit"
                  disabled={!isValid || loading || !dirty}
                  loading={loading}>
                  招待
                </InviteButton>
              ) : (
                <Tooltip
                  title="登録人数が上限に達しました。"
                  color={`#EB5757`}
                  placement="top">
                  <InviteButton
                    htmlType="submit"
                    disabled={true}
                    loading={loading}>
                    招待
                  </InviteButton>
                </Tooltip>
              )}
            </ButtonWrapper>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ModalHeader = styled.div`
  height: 50px;
  display: flex;

  button {
    margin: auto 0 auto auto;
    box-shadow: none;
    border: none;
    display: flex;

    svg {
      margin: auto 0;
      height: 16px;
      width: 25px;

      path {
        fill: var(--text-color-3);
      }
    }

    span {
      margin: auto 0;
      color: var(--text-color-3);
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 51px;

  &&& {
    .ant-form {
      display: flex;
      justify-content: space-between;
      .ant-form-item-has-error {
        .ant-input-affix-wrapper {
          border-color: #ba4047;
        }
      }

      .ant-row {
        display: block;
        margin: 0;

        .ant-col {
          width: 100%;
          max-width: 100%;
          margin: 0;
          flex: 0 1 100%;
          display: flex;
          justify-content: flex-start;

          .ant-form-item-control-input {
            display: block;
            width: 100%;
          }

          .ant-input-affix-wrapper {
            align-items: center;
            width: 270px;

            .ant-input-prefix {
              height: 18px;
              width: 18px;
              margin-right: 10px;
            }
          }
        }

        .ant-form-item-explain.ant-form-item-explain-error {
          color: #ba4047;
          font-size: 14px;
        }
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 39px;
`;

const InviteButton = styled(Button)`
  display: block;
  height: 39px;
  padding: 11px 19px;
  border: none;
  border-radius: 4px;
  background: var(--primary);
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  text-align: center;
  outline: 0;
  height: auto;
  cursor: pointer;
`;

const Alert = styled.div`
  color: #ba4047;
  font-size: 14px;
`;

const SearchTextBox = styled(Input)`
  height: 39px;
  border-radius: 80px;
  border: none;
  background: #f3f5f7;
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-3);
  padding: 0px 0px 0px 30px;
  margin-bottom: 20px;
`;

const EmailTextBox = styled(Input)`
  height: 39px;
  border-radius: 4px;
  border: 1px solid var(--text-color-3);
  box-sizing: border-box;
  background: #fff;
  box-shadow: none;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: var(--text-color-3);
  margin-bottom: 10px;
  &:focus {
    outline: none;
  }
`;

const UserList = styled.div`
  height: 350px;
  padding: 10px;
  margin-bottom: 20px;
  overflow: auto;
  > div {
    height: 70px;
    padding: 15px 5px;
    border-bottom: 1px solid var(--border-color);
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
    border: none;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--text-color-3);
    border-radius: 18px;
  }
`;

const UserListContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #000000;
`;

const UserEmail = styled.div`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;

  color: var(--text-color-2);
`;

const InviteToTeam = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: var(--primary);
  color: #ffffff;
  cursor: pointer;
`;
