import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {usePreleadProjectQuery, useProjectQuery} from 'api';

export default () => {
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });

  let customColumns: any = [];
  project?.projectColumns?.forEach(
    (projectColumn) =>
      projectColumn.dataColumnKey !== null && customColumns.push(projectColumn),
  );

  const {data: {preleadProject: {data} = {}} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    fetchPolicy: 'cache-and-network',
  });

  const isUrl = (str: string | null) => /^https?:\/\//.test(str);

  return (
    <Container>
      <h2>CSV項目</h2>

      {customColumns?.map((customColumn: any) => (
        <Item key={customColumn.id}>
          <Title>{customColumn.title}</Title>
          {data && (
            <Data>
              {isUrl(data[customColumn.dataColumnKey]) ? (
                <a href={data[customColumn.dataColumnKey]} target="_blank">
                  {data[customColumn.dataColumnKey]}
                </a>
              ) : (
                <span>{data[customColumn.dataColumnKey]}</span>
              )}
            </Data>
          )}
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > h2 {
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-1);
  }
`;

const Title = styled.div`
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--text-color-3);
  padding-bottom: -5px;
`;

const Item = styled.div`
  padding-top: 28px;
`;

const Data = styled.div`
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  padding-top: 7px;
  width: 343px;
  overflow-wrap: break-word;
`;
