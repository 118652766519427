import React from 'react';
import styled from 'styled-components';
import {Button, Radio, Tooltip} from 'components/antd';
import {
  Client,
  Project,
  useCurrentClientQuery,
  useTransferProjectMutation,
} from 'api';

export default ({
  project,
  onClose,
}: {
  project: Project;
  onClose: () => void;
}) => {
  const [selectedClientId, setSelectedClientId] = React.useState(null);

  const {data: {currentClient: {offeredClients = []} = {}} = {}} =
    useCurrentClientQuery();

  const {offeredClients: projectOfferedClients = []} = project;

  const handleClose = () => {
    setSelectedClientId(null);
    onClose();
  };

  const [transferProject, {loading}] = useTransferProjectMutation({
    onCompleted: handleClose,
  });

  const onChange = React.useCallback(
    (e: any) => setSelectedClientId(e.target.value),
    [],
  );

  const onSubmit = React.useCallback(() => {
    if (
      confirm(
        '営業リストに紐づいている情報は移管されません。\n移管してもよろしいですか？',
      )
    ) {
      transferProject({
        variables: {
          projectId: project.id,
          selectedClientId: selectedClientId,
        },
      });
    }
  }, [selectedClientId]);

  return (
    <Container>
      <h3>移管先を選択</h3>
      <ScrollWrapper>
        <List>
          {offeredClients?.map((client: Client) => (
            <Radio
              key={client.id}
              value={client.id}
              checked={client.id === selectedClientId}
              onChange={onChange}>
              {client.name}
            </Radio>
          ))}
        </List>
      </ScrollWrapper>
      <Action>
        {projectOfferedClients.length > 0 ? (
          <Tooltip
            title="依頼中の営業リストは移管できません。"
            color={`#EB5757`}
            placement="top">
            <Button
              type="primary"
              size="small"
              disabled={true}
              loading={loading}>
              移管
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            size="small"
            disabled={!selectedClientId}
            loading={loading}
            onClick={onSubmit}>
            移管
          </Button>
        )}
      </Action>
    </Container>
  );
};

const Container = styled.div`
  width: 240px;
  padding: 20px;

  h3 {
    color: var(--text-color-2);
    font-size: 12px;
    font-weight: bold;
  }
`;

const ScrollWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;
  border-bottom: solid 1px #e1e6eb;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;
