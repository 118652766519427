import React from 'react';
import {useClientPreleadCustomizeItemsQuery} from 'api';
import InputField from './InputField';
import {Plus, Setting} from './Icon';
import EditModal from './EditModal';
import SettingModal from './SettingModal';

export default () => {
  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery({});

  const availableItems = () => {
    return clientPreleadCustomizeItems?.filter((item) => item?.isDisplay);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const [settingOpen, setSettingOpen] = React.useState(false);

  return (
    <div className="bg-white border border-c-border rounded">
      <div className="flex justify-between items-center border-b border-c-border px-4">
        <h2 className="h-10 flex items-center text-sm font-bold text-c-light">
          カスタム項目
        </h2>
        <div className="flex items-center">
          <button
            className="flex items-center bg-white cursor-pointer"
            onClick={() => setSettingOpen(true)}>
            <Setting />
          </button>
          <button
            className="flex items-center bg-white text-c-base border-c-border border cursor-pointer"
            onClick={() => setIsOpen(true)}>
            <Plus />
            追加
          </button>
        </div>
      </div>
      <div className="p-4 flex flex-col gap-5">
        {availableItems()?.map((item) => {
          return <InputField key={item.id} item={item} />;
        })}
      </div>
      <EditModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <SettingModal isOpen={settingOpen} setIsOpen={setSettingOpen} />
    </div>
  );
};
