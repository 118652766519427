import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useSalesforceContactRecordTypesQuery,
  SalesforceContactField,
  useSalesforceContactRecordTypeByRecordTypeIdQuery,
} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery({
      variables: {
        poolUuid: poolId,
      },
    });

  const {
    data: {
      salesforceContactRecordTypeByRecordTypeId: {
        salesforceContactFields = [],
      } = {},
    } = {},
  } = useSalesforceContactRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceContactRecordTypeId: query.salesforceContactRecordTypeId,
    },
    skip: !query.salesforceContactRecordTypeId,
  });

  if (!query?.salesforceContactRecordTypeId) {
    return null;
  }

  const targetSalesforceContactRecordType = () => {
    return salesforceContactRecordTypes.find(
      (salesforceContactRecordType) =>
        salesforceContactRecordType.id === query.salesforceContactRecordTypeId,
    );
  };

  const customizeItemById = (id: string): SalesforceContactField => {
    const field: SalesforceContactField = salesforceContactFields.find(
      (field) => field.id === id,
    );
    return field;
  };

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">取引先責任者</h4>
        <button
          onClick={() => setFilterType('salesforceContact')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              salesforceContactRecordTypeId: '',
              salesforceContactSearchConditions: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1 flex-col">
        <div className="flex items-center gap-2">
          <span className="text-c-light">レコードタイプ：</span>
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {targetSalesforceContactRecordType()?.name}
          </span>
        </div>
        <div className="flex flex-wrap flex-col gap-2">
          {query?.salesforceContactSearchConditions.map((item, index) => {
            return (
              <div key={index} className="flex gap-3">
                {customizeItemById(item['salesforce_contact_field_id'])
                  ?.fieldType === 'multi_select' && (
                  <>
                    <div className="text-foreground-light text-sm min-w-28 text-center truncate">
                      {item['values']?.length > 0 &&
                        `${customizeItemById(
                          item['salesforce_contact_field_id'],
                        )?.label}:`}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {item['values']?.length > 0 &&
                        item['values'].map((option: string, index: number) => {
                          return (
                            <div
                              key={index}
                              className="min-w-16 h-5 bg-[#F3F5F7] border border-border text-center truncate text-sm px-2 rounded-sm">
                              {
                                customizeItemById(
                                  item['salesforce_contact_field_id'],
                                )?.salesforceContactFieldSelectOptions?.find(
                                  (item) => item.id === option,
                                )?.label
                              }
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
                {customizeItemById(item['salesforce_contact_field_id'])
                  ?.fieldType === 'select' && (
                  <>
                    <div className="text-foreground-light text-sm min-w-28 text-center truncate">
                      {item['field_select_option_id'] &&
                        `${customizeItemById(
                          item['salesforce_contact_field_id'],
                        )?.label}:`}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {
                        <div
                          key={index}
                          className="min-w-16 h-5 bg-[#F3F5F7] border border-border text-center truncate text-sm px-2 rounded-sm">
                          {
                            customizeItemById(
                              item['salesforce_contact_field_id'],
                            )?.salesforceContactFieldSelectOptions?.find(
                              (option) =>
                                option.id === item['field_select_option_id'],
                            )?.label
                          }
                        </div>
                      }
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
