import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.00033 10.8334C5.46056 10.8334 5.83366 10.4603 5.83366 10.0001C5.83366 9.53984 5.46056 9.16675 5.00033 9.16675C4.54009 9.16675 4.16699 9.53984 4.16699 10.0001C4.16699 10.4603 4.54009 10.8334 5.00033 10.8334Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0003 10.8334C10.4606 10.8334 10.8337 10.4603 10.8337 10.0001C10.8337 9.53984 10.4606 9.16675 10.0003 9.16675C9.54009 9.16675 9.16699 9.53984 9.16699 10.0001C9.16699 10.4603 9.54009 10.8334 10.0003 10.8334Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0003 10.8334C15.4606 10.8334 15.8337 10.4603 15.8337 10.0001C15.8337 9.53984 15.4606 9.16675 15.0003 9.16675C14.5401 9.16675 14.167 9.53984 14.167 10.0001C14.167 10.4603 14.5401 10.8334 15.0003 10.8334Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
