import React from 'react';
import {Task} from 'api';

interface Props {
  task: Task;
}

const Status = ({task}: Props) => {
  switch (task.status) {
    case 'not_started':
      return (
        <div className="flex items-center rounded-sm text-[#0DC8F1] bg-[#DCF9FF] h-5 text-xs px-2">
          {task.statusText}
        </div>
      );
    case 'in_progress':
      return (
        <div className="flex items-center rounded-sm text-[#2693FF] bg-[#D7EBFF] h-5 text-xs px-2">
          {task.statusText}
        </div>
      );
    case 'completed':
      return (
        <div className="flex items-center rounded-sm text-[#4FCE82] bg-[#DDF4E6] h-5 text-xs px-2">
          {task.statusText}
        </div>
      );
    case 'expired':
      return (
        <div className="flex items-center rounded-sm text-foreground-light bg-[#EAEEF2] h-5 text-xs px-2">
          {task.statusText}
        </div>
      );
    case 'icebox':
      return (
        <div className="flex items-center rounded-sm text-foreground-light bg-[#EAEEF2] h-5 text-xs px-2">
          {task.statusText}
        </div>
      );
  }
};

export default Status;
