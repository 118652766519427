import React from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import Header from './Header';
import Target from './Target';
import Prospects from './Prospects';
import Segmented from './Segmented';
import {
  useMaintenanceProspectPoolsQuery,
  useMergeMaintenanceMutation,
  useUpdateMaintenanceProspectPoolToMutation,
} from 'api';
import {ArrowPathIcon} from '@heroicons/react/24/outline';
import {Button, Tooltip} from 'antd';
import Info from 'components/Ui/Icon/Info';

const Modal = () => {
  const [segment, setSegment] = React.useState('対象');

  const history = useHistory();
  const routeMatch = useRouteMatch<{uuid: string}>('/maintenance/:uuid');
  const uuid = routeMatch?.params?.uuid || '';
  const onClose = () => history.push(`/maintenance${location.search}`);
  const {
    data: {maintenanceProspectPools: {maintenanceProspectPools = []} = {}} = {},
  } = useMaintenanceProspectPoolsQuery({
    variables: {
      uuid,
    },
    fetchPolicy: 'cache-and-network',
    skip: !uuid,
  });

  const toMaintenanceProspectPool = maintenanceProspectPools.filter(
    (maintenanceProspectPool) => {
      return maintenanceProspectPool.merge === 'to';
    },
  )[0];

  const fromMaintenanceProspectPools = maintenanceProspectPools.filter(
    (maintenanceProspectPool) => {
      return maintenanceProspectPool.merge === 'from';
    },
  );

  const [mergeMaintenance, {loading}] = useMergeMaintenanceMutation({
    variables: {uuid: uuid},
    refetchQueries: ['maintenances', 'maintenanceProspectPoolCounts'],
    onCompleted: () => {
      onClose();
    },
  });

  const [
    updateMaintenanceProspectPoolTo,
    {loading: updateMaintenanceProspectPoolToLoading},
  ] = useUpdateMaintenanceProspectPoolToMutation({
    variables: {
      uuid: uuid,
      prospectPoolToId: '0',
    },
    refetchQueries: ['maintenanceProspectPools'],
  });

  const handleUpdateMaintenanceProspectPool = (prospectPoolToId: string) => {
    updateMaintenanceProspectPoolTo({
      variables: {
        uuid: uuid,
        prospectPoolToId: prospectPoolToId,
      },
    });
  };

  const toolTipTitle = `重複判定で検出された名寄せ対象の件数です。
    名寄せ対象から除外されたリードは含まれていません。

    【名寄せ対象外】
    ・Salesforce取引先責任者→Salesforceリード
    ・Salesforce取引先責任者→LEADPAD
    ・Salesforceリード→LEADPAD
    ・Salesforceレコードタイプが異なるリード`;

  return (
    <Transition appear show={!!uuid} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="flex flex-col relative w-full max-w-3xl h-[96vh] transform rounded bg-white text-left align-middle shadow-xl transition-all overflow-hidden">
                <Header onClose={onClose} />
                <div className="px-6 pt-6 bg-c-bg rounded-b full overflow-y-scroll flex-1 flex flex-col gap-4">
                  {toMaintenanceProspectPool && (
                    <Target
                      maintenanceProspectPool={toMaintenanceProspectPool}
                    />
                  )}
                  <div className="flex-1 overflow-y-scroll bg-white p-5">
                    <h1 className="w-full text-base font-normal">名寄せ元</h1>
                    <div className="mb-1">
                      <Segmented
                        currentSegment={segment}
                        setSegment={setSegment}
                        counts={[
                          fromMaintenanceProspectPools.filter(
                            (maintenanceProspectPool) =>
                              maintenanceProspectPool.isTarget,
                          ).length,
                          fromMaintenanceProspectPools.filter(
                            (maintenanceProspectPool) =>
                              !maintenanceProspectPool.isTarget,
                          ).length,
                        ]}
                      />
                      <Tooltip
                        overlayStyle={{
                          whiteSpace: 'pre-line',
                        }}
                        overlayInnerStyle={{
                          width: '440px',
                        }}
                        title={toolTipTitle}
                        color={'rgb(0 0 0 / 50%)'}
                        placement="top">
                        <Button type="link" shape="circle" icon={<Info />} />
                      </Tooltip>
                    </div>
                    {fromMaintenanceProspectPools && (
                      <Prospects
                        maintenanceProspectPools={
                          fromMaintenanceProspectPools.filter(
                            (maintenanceProspectPool) => {
                              if (segment === '対象') {
                                return maintenanceProspectPool.isTarget;
                              } else {
                                return !maintenanceProspectPool.isTarget;
                              }
                            },
                          ) || []
                        }
                        handleUpdateMaintenanceProspectPool={
                          handleUpdateMaintenanceProspectPool
                        }
                        updateMaintenanceProspectPoolToLoading={
                          updateMaintenanceProspectPoolToLoading
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between px-6 py-4 gap-4 bg-c-bg rounded-b">
                  <button
                    className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold text-foreground-light"
                    onClick={onClose}>
                    閉じる
                  </button>
                  <button
                    className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold disabled:opacity-50"
                    disabled={loading}
                    onClick={() =>
                      confirm('マージ処理を実行してよろしいですか?') &&
                      mergeMaintenance()
                    }>
                    名寄せ処理実行
                    <ArrowPathIcon
                      className={`w-4 h-4 ${
                        loading ? 'animate-spin ml-2' : 'hidden'
                      }`}
                    />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
