import React from 'react';
import {useDestroyAllClientAccountScoreSettingSubCategoriesMutation} from 'api';

type Props = {
  onClose: () => void;
};
const Footer = ({onClose}: Props) => {
  const [destroyCategories] =
    useDestroyAllClientAccountScoreSettingSubCategoriesMutation({});
  return (
    <div className="flex items-center justify-between px-6 py-4 gap-4 bg-c-bg rounded-b">
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold"
        onClick={() => onClose()}>
        閉じる
      </button>
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded border-none bg-transparent font-bold text-[#0075E2] text-base"
        onClick={() => {
          destroyCategories({});
        }}>
        条件をクリア
      </button>
    </div>
  );
};

export default Footer;
