import React from 'react';
import styled from 'styled-components';
import {KeyPerson} from 'api';
import {Row} from 'react-table';
import {DotMenuSide} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import EditModal from './EditModal';

interface Props {
  row: Row<KeyPerson>;
}

const MenuCell = ({row}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const Content = () => {
    return (
      <>
        <PopoverContent>
          <div className="cursor-pointer" onClick={() => setIsOpen(true)}>
            <span>編集</span>
          </div>
          <div>
            <span>リードとして登録</span>
          </div>
        </PopoverContent>
        <EditModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          keyPerson={row.original}
        />
      </>
    );
  };

  return (
    <>
      <Popover placement="bottomRight" content={Content} trigger="click">
        <div style={{cursor: 'pointer'}}>
          <DotMenuSide />
        </div>
      </Popover>
    </>
  );
};

const PopoverContent = styled.div`
  width: 140px;
  font-size: 14px;
  color: var(--text-color-2);
  font-weight: normal;
  cursor: pointer;
  > div {
    padding: 4px;
    &:hover {
      background: #f3f5f7;
    }
    > span {
      margin-left: 4px;
    }
  }
`;

export default MenuCell;
