import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  useClientPhaseProspectsQuery,
  useUpdateClientPhaseProspectPositionMutation,
} from 'api';
import {Handle} from 'components/Ui/Icon';
import New from './New';
import Update from './Update';
import {PlusIcon} from '@heroicons/react/20/solid';

export default () => {
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [updateModalItem, setUpdateModalItem] = React.useState(null);
  const [phases, setPhases] = React.useState([]);

  useClientPhaseProspectsQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPhases(data.clientPhaseProspects);
    },
  });

  const [updatePosition] = useUpdateClientPhaseProspectPositionMutation();

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    const items = [...phases];
    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destIndex, 0, removed);

    setPhases(items);
    updatePosition({
      variables: {id: removed.id, position: destIndex},
    });
  };

  return (
    <div>
      <div className="font-bold text-[24px]">フェーズ</div>
      <div className="flex mb-4 items-center justify-between">
        <h3>リードのフェーズを定義することができます</h3>

        <button
          className="bg-c-primary text-white border-c-primary outline-none cursor-pointer px-4 py-2 rounded flex items-center hover:opacity-80 transition-all justify-center gap-1"
          onClick={() => {
            setNewModalVisible(true);
          }}>
          <PlusIcon className="w-4 h-4" />
          新規作成
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {phases?.map((phase, index) => (
                <Draggable key={phase.id} draggableId={phase.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={`min-h-[48px] pr-4 shadow-[0_0_1px_#444b52] flex items-center gap-4 bg-white border-l-[3px] border-white cursor-pointer [&.dragging]:bg-[#f3f9ff] [&.dragging]:shadow-[0_0_10px_rgba(0,0,0,0.13)] [&.dragging]:border-l-[3px] [&.dragging]:border-l-primary ${
                        snapshot.isDragging ? 'dragging' : ''
                      }`}
                      ref={provided.innerRef}
                      onClick={() => setUpdateModalItem(phase)}
                      {...provided.draggableProps}>
                      <div
                        className="flex items-center [&>svg]:h-[24px]"
                        {...provided.dragHandleProps}>
                        <Handle />
                      </div>
                      <span className="w-64 truncate">{phase.name}</span>
                      <span className="text-c-light truncate flex-1">
                        {phase.description}
                      </span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <New
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
      <Update
        phase={updateModalItem}
        onClose={() => setUpdateModalItem(null)}
      />
    </div>
  );
};
