import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Category from './Category';
import Employee from './Employee';
import CapitalFund from './CapitalFund';
import {useSearchCountQuery} from 'api';
import useSearchCondition from '../useSearchCondition';
import {Loading} from 'components/Icon';

export default () => {
  const {query} = useSearchCondition();
  const {companyId} = useParams<{companyId: string}>();

  const {data: {searchCount = 0} = {}, loading} = useSearchCountQuery({
    variables: {
      search: query,
    },
    skip: !companyId,
  });

  return (
    <Container>
      <Section>
        <h2>類似企業の検索合計数</h2>
        {loading ? (
          <Loading />
        ) : (
          <span>{`${searchCount?.toLocaleString()}件`}</span>
        )}
      </Section>
      <Category />
      <Employee />
      <CapitalFund />
    </Container>
  );
};

const Container = styled.div`
  padding: 22px 32px;
  width: 700px;
  height: 750px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

const Section = styled.div`
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #e1e6eb;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color-1);

  span {
    margin-left: auto;
    margin-right: 48px;
  }
`;
