import React from 'react';
import {CustomizeItem, usePreleadQuery, useUpdatePreleadMutation} from 'api';
import {DatePicker} from 'components/antd';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import usePreleadContext from '../../usePreleadContext'

export default ({customizeItem}: {customizeItem: CustomizeItem}) => {
  const {preleadId} = usePreleadContext()

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const [updatePrelead] = useUpdatePreleadMutation();

  const updateCustomizeItemDate = React.useCallback(
    (value: any) => {
      const attributes: {[key: string]: any} = {};
      attributes[_.camelCase(customizeItem.columnName)] = value;
      updatePrelead({
        variables: {uuid: prelead?.uuid, attributes},
      });
    },
    [prelead],
  );

  const date = React.useMemo(
    //@ts-ignore
    () => prelead[_.camelCase(customizeItem.columnName)],
    [prelead, customizeItem],
  );

  return (
    <InputDate
      placeholder=""
      suffixIcon=""
      format="YYYY/MM/DD"
      value={date && moment(date, 'YYYY/MM/DD')}
      style={{width: '100%'}}
      onChange={(date) => updateCustomizeItemDate(date)}
    />
  );
};

const InputDate = styled(DatePicker)`
  border-color: transparent;

  &:hover {
    border-color: var(--border-color);
  }
`;
