import React from 'react';
import {ProspectPool} from 'api';
import styled from 'styled-components';
import {ArrowDown} from 'components/Ui/Icon';
import CustomizeItem from './CustomizeItem';

type Props = {
  prospectPool: ProspectPool;
};

export default ({prospectPool}: Props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Container>
      <Header onClick={() => setOpen(!open)}>
        <ToggleItem open={open}>項目</ToggleItem>
        <ArrorwDownWrappper open={open}>
          <ArrowDown />
        </ArrorwDownWrappper>
      </Header>
      {open && <CustomizeItem prospectPool={prospectPool} />}
    </Container>
  );
};

const ToggleItem = styled.div<{open: boolean}>`
  color: ${({open}) => (open ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  font-weight: 700;
  letter-spacing: 0.15px;
`;

const Header = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  cursor: pointer;
  column-gap: 15px;

  & > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--text-color-1);
  }
`;

const ArrorwDownWrappper = styled.div<{open: boolean}>`
  margin-left: auto;
  & > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 8px;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
`;
