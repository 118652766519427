import React from 'react';

function Icon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00008 6.00001L11.3334 11.3333M11.3334 0.666672L6.00008 6.00001L11.3334 0.666672ZM6.00008 6.00001L0.666748 11.3333L6.00008 6.00001ZM6.00008 6.00001L0.666748 0.666672L6.00008 6.00001Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
