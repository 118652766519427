import React from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Status from './Status';
import NgReasonTemplate from './NgReasonTemplate';

export default () => {
  const history = useHistory();

  return (
    <Container>
      <Tabs>
        <Tab
          active={location.pathname.endsWith('/settings/status/tel_status/tel')}
          onClick={() => history.push(`/settings/status/tel_status/tel`)}>
          <span>TEL</span>
        </Tab>
        <Tab
          active={location.pathname.includes(
            '/settings/status/tel_status/ng_reason_template',
          )}
          onClick={() =>
            history.push(
              `/settings/status/tel_status/ng_reason_template/call_ng`,
            )
          }>
          <span>NG理由テンプレート</span>
        </Tab>
      </Tabs>
      <Switch>
        <Route path="/settings/status/tel_status/tel" component={Status} />
        <Route
          path="/settings/status/tel_status/ng_reason_template"
          component={NgReasonTemplate}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div``;

const Tabs = styled.div`
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 28px;
  display: flex;
  width: 225px;
  height: 36px;

  background: #f3f5f7;
  border-radius: 6px;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  ${({active}) => active && 'background: #FFFFFF'};
  border-radius: 4px;
  cursor: pointer;

  span {
    padding: 7px 14px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: var(--text-color-1);
    color: ${({active}) => (active ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  }
`;
