import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41797 18.582C4.99609 18.582 4.65234 18.2383 4.65234 17.8164V15.1445H1.94141C0.886719 15.1445 0.03125 14.2891 0.03125 13.2344V9.79688C0.03125 8.74219 0.886719 7.88672 1.94141 7.88672H4.65234V1.78125C4.65234 1.35937 4.99609 1.01562 5.41797 1.01562H12.293C13.9766 1.01562 15.3477 2.38672 15.3477 4.07031V7.89062H18.0586C19.1133 7.89062 19.9688 8.74609 19.9688 9.80078V13.2383C19.9688 14.293 19.1133 15.1484 18.0586 15.1484H15.3477V17.8203C15.3477 18.2422 15.0039 18.5859 14.582 18.5859H5.41797V18.582ZM13.8203 17.0547V14H6.17969V17.0547H13.8203ZM18.0586 13.6172C18.2695 13.6172 18.4414 13.4453 18.4414 13.2344V9.79688C18.4414 9.58594 18.2695 9.41406 18.0586 9.41406H1.94141C1.73047 9.41406 1.55859 9.58594 1.55859 9.79688V13.2344C1.55859 13.4453 1.73047 13.6172 1.94141 13.6172H4.65234V13.2344C4.65234 12.8125 4.99609 12.4688 5.41797 12.4688H14.582C15.0039 12.4688 15.3477 12.8125 15.3477 13.2344V13.6172H18.0586ZM13.8203 7.89062V4.83594H12.293C11.8711 4.83594 11.5273 4.49219 11.5273 4.07031V2.54297H6.17969V7.89062H13.8203ZM13.6133 3.30859C13.4805 3.07812 13.2852 2.88672 13.0547 2.75V3.30859H13.6133Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M18.0586 8.08203H15.1562V4.07031C15.1562 2.49219 13.8711 1.20703 12.293 1.20703H5.41797C5.10156 1.20703 4.84375 1.46484 4.84375 1.78125V8.08203H1.94141C0.992187 8.08203 0.222656 8.85156 0.222656 9.80078V13.2383C0.222656 14.1875 0.992187 14.957 1.94141 14.957H4.84375V17.8203C4.84375 18.1367 5.10156 18.3945 5.41797 18.3945H14.582C14.8984 18.3945 15.1562 18.1367 15.1562 17.8203V14.957H18.0586C19.0078 14.957 19.7773 14.1875 19.7773 13.2383V9.80078C19.7773 8.85156 19.0078 8.08203 18.0586 8.08203ZM13.9102 3.5H12.8633V2.44922C13.3516 2.625 13.7383 3.01172 13.9102 3.5ZM5.98828 2.35156H11.7187V4.07031C11.7187 4.38672 11.9766 4.64453 12.293 4.64453H14.0117V8.08203H5.98828V2.35156ZM14.0117 17.2461H5.98828V13.8086H14.0078V17.2461H14.0117ZM18.6328 13.2383C18.6328 13.5547 18.375 13.8125 18.0586 13.8125H15.1562V13.2383C15.1562 12.9219 14.8984 12.6641 14.582 12.6641H5.41797C5.10156 12.6641 4.84375 12.9219 4.84375 13.2383V13.8125H1.94141C1.625 13.8125 1.36719 13.5547 1.36719 13.2383V9.80078C1.36719 9.48438 1.625 9.22656 1.94141 9.22656H18.0586C18.375 9.22656 18.6328 9.48438 18.6328 9.80078V13.2383Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M14.582 11.7109C15.0049 11.7109 15.3477 11.3682 15.3477 10.9453C15.3477 10.5225 15.0049 10.1797 14.582 10.1797C14.1592 10.1797 13.8164 10.5225 13.8164 10.9453C13.8164 11.3682 14.1592 11.7109 14.582 11.7109Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M14.582 11.5195C14.8992 11.5195 15.1563 11.2624 15.1563 10.9453C15.1563 10.6282 14.8992 10.3711 14.582 10.3711C14.2649 10.3711 14.0078 10.6282 14.0078 10.9453C14.0078 11.2624 14.2649 11.5195 14.582 11.5195Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M12.293 11.7109C12.7158 11.7109 13.0586 11.3682 13.0586 10.9453C13.0586 10.5225 12.7158 10.1797 12.293 10.1797C11.8701 10.1797 11.5273 10.5225 11.5273 10.9453C11.5273 11.3682 11.8701 11.7109 12.293 11.7109Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M12.293 11.5195C12.6101 11.5195 12.8672 11.2624 12.8672 10.9453C12.8672 10.6282 12.6101 10.3711 12.293 10.3711C11.9758 10.3711 11.7188 10.6282 11.7188 10.9453C11.7188 11.2624 11.9758 11.5195 12.293 11.5195Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M7.70703 16.293C7.28516 16.293 6.94141 15.9492 6.94141 15.5273C6.94141 15.1055 7.28516 14.7617 7.70703 14.7617H12.2891C12.7109 14.7617 13.0547 15.1055 13.0547 15.5273C13.0547 15.9492 12.7109 16.293 12.2891 16.293H7.70703Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M12.293 14.957H7.70703C7.39062 14.957 7.13281 15.2148 7.13281 15.5313C7.13281 15.8477 7.39062 16.1055 7.70703 16.1055H12.2891C12.6055 16.1055 12.8633 15.8477 12.8633 15.5313C12.8633 15.2148 12.6094 14.957 12.293 14.957Z"
        fill="var(--text-color-3)"
      />
    </svg>
  );
}

export default Icon;
