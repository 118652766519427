import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Note, PenWithLine} from 'components/Ui/Icon';
import {useProjectQuery} from 'api';
import Edit from './Edit';

export const DetailInformation = () => {
  const {projectId} = useParams<{projectId: string}>();
  const [isEditing, setEditing] = useState(false);
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  if (!project) return null;

  const Show = () => (
    <>
      <ItemMemo>
        <Note />
        <Memo dangerouslySetInnerHTML={{__html: project.memoHtml}} />
      </ItemMemo>
    </>
  );

  const Content = () =>
    isEditing ? <Edit closeEditMode={() => setEditing(false)} /> : <Show />;

  return (
    <Container>
      <EditButton onClick={() => setEditing(!isEditing)}>
        <PenWithLine />
      </EditButton>
      <Content />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 16px;
  width: 26px;
  height: 26px;
  background: #f9f9f9;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const Memo = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color-0);
  width: 100%;
  white-space: pre-wrap;

  p {
    margin-bottom: 0;
  }
`;
