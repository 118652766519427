import React from 'react';
import styled from 'styled-components';
import {Step, useUpdateStepMutation} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';

interface Props {
  step: Step;
}

export default ({step}: Props) => {
  const [status, setStatus] = React.useState(step.status);
  const [updateStep] = useUpdateStepMutation();
  const {isMember} = useClientUser();

  const updateStatus = (value: 'open' | 'closed') => {
    setStatus(value);
    updateStep({
      variables: {uuid: step.uuid, attributes: {status: value}},
    });
  };

  return (
    <CustomSelect
      status={status}
      value={status}
      onChange={(value: 'open' | 'closed') => {
        if (value === 'closed' && confirm('OFFにしてよろしいですか？')) {
          updateStatus(value);
        } else if (value === 'open') {
          updateStatus(value);
        }
      }}
      bordered={false}
      size={'small'}
      disabled={!isMember}>
      <Select.Option value={'open'}>ON</Select.Option>
      <Select.Option value={'closed'}>OFF</Select.Option>
    </CustomSelect>
  );
};

const CustomSelect = styled(Select)<{status: string}>`
  &&& {
    background: ${({status}) => (status === 'open' ? '#EAF4FF' : '#E1E6EB')};
    color: ${({status}) => (status === 'open' ? '#2693FF' : 'var(--text-color-2)')};
    border-radius: 2px;
    font-weight: bold;
    font-size: 10px;
    width: 48px;
    padding-right: 0;

    .ant-select-arrow {
      color: ${({status}) => (status === 'open' ? '#2693FF' : 'var(--text-color-2)')};
      font-size: 9px;
      margin-top: -4px;
      right: 5px;
    }
    .ant-select-selector,
    .ant-select-selection-item {
      padding-right: 0;
    }
  }
`;
