import React from 'react';
import {
  Category,
  GroupDimension,
  LockPrivary,
  OpenFolder,
} from 'components/Ui/Icon';
import styled from 'styled-components';
import {ProjectGroup, useCurrentClientQuery} from 'api';
import {useHistory, useParams} from 'react-router-dom';
import {Image, Select} from 'components/antd';
import OfficeBuildingGreen from '../share/OfficeBuildingGreen';
import OfficeBuildingPurple from '../share/OfficeBuildingPurple';
import useProjectGroups from 'hooks/useProjectGroups';

export default () => {
  const history = useHistory();
  const {projectGroupId, requestClientId, offeredClientId} = useParams<{
    projectGroupId: string;
    requestClientId: string;
    offeredClientId: string;
  }>();
  const {accessibleProjectGroups: projectGroups} = useProjectGroups();
  const {
    data: {
      currentClient: {offeredClients = [], requestActiveClients = []} = {},
    } = {},
  } = useCurrentClientQuery();

  const projectGroup: ProjectGroup = React.useMemo(
    () =>
      projectGroups.find(
        (projectGroup) => projectGroup.uuid === projectGroupId,
      ),
    [projectGroupId, projectGroups],
  );

  const offeredClient = React.useMemo(
    () => offeredClients.find((client) => client.uuid === offeredClientId),
    [offeredClientId, offeredClients],
  );

  const requestClient = React.useMemo(
    () =>
      requestActiveClients.find((client) => client.uuid === requestClientId),
    [requestClientId, requestActiveClients],
  );

  if (requestClientId)
    return (
      <Container>
        <h1>
          <div className="flex">
            <div className="share-box request">
              <OfficeBuildingGreen />
            </div>
            <span className="title">{requestClient?.name}</span>
          </div>
        </h1>
      </Container>
    );

  if (offeredClientId)
    return (
      <Container>
        <h1>
          <div className="flex">
            <div className="share-box offered">
              <OfficeBuildingPurple />
            </div>
            <span className="title">{offeredClient?.name}</span>
          </div>
          <div className="select-wrapper">
            <Select
              suffixIcon={<Category />}
              dropdownClassName="offered-client-select-dropdown"
              dropdownStyle={{minWidth: '350px'}}
              onChange={(value) =>
                history.push(
                  `/list/share/offered/${value}/projects?page=1&status=open`,
                )
              }>
              {offeredClients?.map((client) => (
                <Select.Option
                  value={client.uuid}
                  key={client.uuid}
                  label={<React.Fragment>&nbsp; {client.name}</React.Fragment>}>
                  &nbsp; {client.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </h1>
      </Container>
    );

  if (projectGroup)
    return (
      <Container>
        <h1>
          <div className="flex">
            <div className="image-box">
              {projectGroup?.imageUrl ? (
                <Image src={projectGroup.imageUrl} />
              ) : (
                <GroupDimension />
              )}
            </div>
            <span className="title">{projectGroup?.name}</span>
          </div>
          <div className="flex">
            <Select
              suffixIcon={<Category />}
              dropdownClassName="project-projectGroup-select-dropdown"
              dropdownStyle={{minWidth: '350px'}}
              onChange={(value) =>
                history.push(
                  `/list/groups/${value}/projects?page=1&status=open`,
                )
              }>
              {projectGroups?.map((projectGroup) => (
                <Select.Option
                  value={projectGroup.uuid}
                  key={projectGroup.uuid}
                  label={
                    <React.Fragment>&nbsp; {projectGroup.name}</React.Fragment>
                  }>
                  &nbsp; {projectGroup.name}
                </Select.Option>
              ))}
            </Select>
            {projectGroup.isPrivate ? (
              <Label backgroud="#FDECEC" color="#EB5757">
                <LockPrivary />
                <Space />
                <span>Private</span>
              </Label>
            ) : (
              <Label backgroud="#E5F8EC" color="#4FCE82">
                <OpenFolder />
                <Space />
                <span>Public</span>
              </Label>
            )}
          </div>
        </h1>
      </Container>
    );

  return <div style={{fontWeight: 700, fontSize: '20px'}}>営業リスト</div>;
};

const Container = styled.div`
  width: 100%;
  margin: 8px;
  h1 {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    margin: 0;

    .flex {
      margin: 0;
    }

    .title {
      margin-left: 18px;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: var(--text-color-1);
      width: calc(100vw - 635px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .image-box {
      width: 68px;
      height: 68px;
      > svg {
        border: 1px solid var(--border-color);
        border-radius: 4px;
        width: 68px;
        height: 68px;
      }
    }
    .share-box {
      display: flex;
      width: 68px;
      height: 68px;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      &.request {
        background: #def4f7;
      }
      &.offered {
        background: #e6ddff;
      }
      > svg {
        margin: auto;
        width: 40px;
        height: 40px;
      }
    }
    .ant-select {
      margin-bottom: 10px;
      .ant-select-selector {
        margin-left: 22px;
        width: 22px !important;
        height: 18px !important;
        background: none !important;
        border: none !important;
      }
    }
    .select-wrapper {
      width: 100px;
    }
  }
`;

const Label = styled.div<{backgroud: string; color: string}>`
  margin-left: 11px;
  display: flex;
  align-items: center;
  padding: 5px 17px;
  background: ${({backgroud}) => backgroud};
  color: ${({color}) => color};
  border-radius: 100px;
  > svg {
    path {
      stroke: ${({color}) => color};
    }
  }
  > span {
    font-weight: 500;
    font-size: 12px;
  }
`;

const Space = styled.div`
  width: 1px;
`;
