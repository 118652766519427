import {Dialog} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import React from 'react';
import {useFormik} from 'formik';
import {useCreateClientPreleadCustomizeItemMutation} from 'api';
import * as Yup from 'yup';

interface FormikValues {
  name: string;
  dataType: string;
}

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};
export default function ProfileEditorModal({isOpen, setIsOpen}: Props) {
  const [createItem, {loading}] = useCreateClientPreleadCustomizeItemMutation({
    refetchQueries: ['clientPreleadCustomizeItems'],
    onCompleted: () => {
      formik.resetForm();
      setIsOpen(false);
    },
  });
  const initialValues: FormikValues = {
    name: '',
    dataType: 'text',
  };

  const dataTypeOptions = [
    {
      id: 'text',
      label: 'テキスト',
    },
    {
      id: 'number',
      label: '数値',
    },
    {
      id: 'date',
      label: '日付',
    },
    {
      id: 'select',
      label: 'セレクト',
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      dataType: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) => {
      createItem({
        variables: {
          attributes: {
            name: values.name,
            dataType: values.dataType,
          },
        },
      });
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded-lg bg-white">
          <div className="flex items-center justify-between bg-[#F3F5F7] h-10 px-4">
            <Dialog.Title className="text-base font-semibold m-0" as="h1">
              カスタム項目の追加
            </Dialog.Title>
            <div
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:bg-gray-100 hover:text-gray-500 flex items-center cursor-pointer">
              <XMarkIcon className="h-6 w-6" />
            </div>
          </div>
          <div className="max-h-[calc(100vh-200px)] overflow-y-auto px-6 py-4">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <div className="flex gap-2 items-center">
                  <label className="text-sm font-medium text-gray-700">
                    項目名
                  </label>
                  <span className="bg-[#c51717] text-[8px] text-white font-bold w-5 h-3 text-center">
                    必須
                  </span>
                </div>
                <input
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formik.values.name}
                  data-1p-ignore
                  onChange={(e: any) =>
                    formik.setFieldValue('name', e.target.value)
                  }
                />
              </div>
              <div className="grid gap-2">
                <div className="flex gap-2 items-center">
                  <label className="text-sm font-medium text-gray-700">
                    データ形式
                  </label>
                  <span className="bg-[#c51717] text-[8px] text-white font-bold w-5 h-3 text-center">
                    必須
                  </span>
                </div>
                <select
                  className="w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white cursor-pointer form-select"
                  onChange={(e) => {
                    formik.setFieldValue('dataType', e.target.value);
                  }}>
                  {dataTypeOptions.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="px-6 py-4 flex justify-end items-center">
            <button
              className="w-20 rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 cursor-pointer"
              disabled={!formik.dirty || !formik.isValid || loading}
              onClick={() => {
                formik.submitForm();
              }}>
              追加
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
