import React from 'react';
import styled from 'styled-components';
import {
  useAllProjectsTelStatusCallDailyReportsQuery,
  useTelStatusesByClientIdQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import moment from 'moment';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import _ from 'lodash';
import {ArrowRight, Download} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import DownloadModal from './CallReportDownloadModal';
import {telStatusCategoryHash} from 'helpers/telStatusCategoryHash';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const {query, searchParams, setQuery} = useSearchParams();
  const [displayCategories, setDisplayCategories] = React.useState([]);
  const {data: {allProjectsTelStatusCallDailyReports = []} = {}} =
    useAllProjectsTelStatusCallDailyReportsQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'network-only',
    });

  const {data: {telStatusesByClientId = []} = {}} =
    useTelStatusesByClientIdQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'network-only',
    });

  const categorizedTelStatuses = React.useCallback(
    (category: string) =>
      telStatusesByClientId.filter(
        (telStatus) => telStatus.category === category,
      ),
    [telStatusesByClientId],
  );

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );
    if (!value) return null;

    return value;
  };

  const toggle = React.useCallback(
    (category: string) => {
      if (displayCategories.includes(category)) {
        setDisplayCategories(
          displayCategories.filter(
            (displayCategory) => displayCategory != category,
          ),
        );
      } else {
        setDisplayCategories([...displayCategories, category]);
      }
    },
    [displayCategories],
  );

  if (allProjectsTelStatusCallDailyReports.length === 0) return null;

  const weekButton = () => {
    setQuery({range: 'week'});
  };

  const monthButton = () => {
    setQuery({range: 'month'});
  };

  const todayButton = () => {
    setQuery({
      date: moment().format('YYYY-MM-DD'),
    });
  };

  const leftButton = () => {
    if (query.range === 'month') {
      const date = moment(query.date).subtract(1, 'months');
      setQuery({
        date: date.startOf('month').format('YYYY-MM-DD'),
      });
    } else {
      const date = moment(query.date).subtract(7, 'days');
      setQuery({
        date: date.format('YYYY-MM-DD'),
      });
    }
  };

  const rightButton = () => {
    if (query.range === 'month') {
      const date = moment(query.date).add(1, 'months');
      setQuery({
        date: date.startOf('month').format('YYYY-MM-DD'),
      });
    } else {
      const date = moment(query.date).add(7, 'days');
      setQuery({
        date: date.format('YYYY-MM-DD'),
      });
    }
  };

  const displayMonth = () => {
    if (query.range === 'month') return moment(query.date).format('YYYY年MM月');
    return moment(query.date).startOf('isoWeek').format('MM') ===
      moment(query.date).endOf('isoWeek').format('MM')
      ? moment(query.date).format('YYYY年MM月')
      : `${moment(query.date)
          .startOf('isoWeek')
          .format('YYYY年MM月')} ~ ${moment(query.date)
          .endOf('isoWeek')
          .format('MM月')}`;
  };

  return (
    <Container>
      <div>
        <h2>ステータス・カテゴリ別</h2>
        <div>
          <Display>
            <span>{displayMonth()}</span>
            <Button type="text" className="left" onClick={leftButton}>
              <ArrowRight />
            </Button>
            <Button type="text" onClick={rightButton}>
              <ArrowRight />
            </Button>

            <Button className="today" onClick={todayButton}>
              今日
            </Button>
            <Tab onClick={weekButton} current={query.range === 'week'}>
              週
            </Tab>
            <Tab onClick={monthButton} current={query.range === 'month'}>
              月
            </Tab>
          </Display>

          <DownloadButton onClick={() => setModalOpen(true)}>
            <Download />
            ダウンロード
          </DownloadButton>
        </div>
      </div>

      <Table>
        <div className="column far-left">
          <div className="far-top">
            <span>{displayMonth()}</span>
          </div>

          {Object.keys(telStatusCategoryHash).map((category) => (
            <React.Fragment key={category}>
              <div
                className={
                  'category ' + (!category.includes('ng') && 'openable')
                }
                onClick={() => toggle(category)}>
                {!category.includes('ng') && (
                  <Open
                    category={category}
                    displayCategories={displayCategories}>
                    <ArrowRight />
                  </Open>
                )}
                <StatusEclipse color={telCategoryColor(category)} />
                <span>{telStatusCategoryHash[category]}</span>
              </div>
              {displayCategories.includes(category) &&
                !category.includes('ng') &&
                categorizedTelStatuses(category).map((telStatus) => (
                  <div key={telStatus.id}>
                    <span>{telStatus.name}</span>
                  </div>
                ))}
            </React.Fragment>
          ))}
        </div>

        {allProjectsTelStatusCallDailyReports.map((report: any) => (
          <div
            className={
              'column ' +
              ((moment(report.countDate).weekday() === 5 ||
                moment(report.countDate).weekday() === 6) &&
                'weekend')
            }
            key={report.countDate}>
            <div
              className={
                'far-top ' +
                (moment(report.countDate).isSame(new Date(), 'day') && 'today')
              }>
              <span>{moment(report.countDate).format('DD')}</span>
              <span>{moment(report.countDate).format('ddd')}</span>
            </div>

            {Object.keys(telStatusCategoryHash).map((category) => (
              <React.Fragment key={category}>
                <div className="category">
                  <span>
                    {report[_.camelCase(category)] &&
                      totalCount(report[_.camelCase(category)])}
                  </span>
                </div>
                {displayCategories.includes(category) &&
                  !category.includes('ng') &&
                  categorizedTelStatuses(category).map((telStatus) => (
                    <div key={telStatus.id}>
                      <span>
                        {report[category] && report[category][telStatus.id]}
                      </span>
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </div>
        ))}
      </Table>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > h2 {
      margin: auto auto auto 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: var(--text-color-1);
    }

    > div {
      position: relative;
      margin: auto 0 auto auto;
      display: flex;
    }
  }
`;

const Display = styled.div`
  margin-left: 10px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-1);
  }

  > button {
    padding: 5.6px 6px;

    svg {
      path {
        stroke: var(--text-color-2);
      }
    }

    &.left {
      svg {
        transform: rotate(180deg);
      }
    }

    &.today {
      margin-left: 10px;
      padding: 5.6px 10px;
      border: 1px solid #e1e6eb;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`;

const DownloadButton = styled(Button)`
  position: absolute;
  top: -50px;
  right: 0px;

  display: flex;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > svg {
    margin-right: 6px;
  }

  > span {
    margin: auto 0;
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border-left: 1px solid #e1e6eb;
  overflow: scroll;
  min-height: 300px;
  max-height: 600px;

  .column {
    display: flex;
    flex-direction: column;

    > div {
      width: 50px;
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      align-items: center;

      &:first-child {
        > span {
          margin: 0 auto;
        }

        &.far-top {
          top: 0;
          z-index: 3;
          position: sticky;
          background: #ffffff;
          border-bottom: 1px solid #e1e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin: auto;
          }

          &.today {
            border-top: 2px solid var(--text-color-1);

            span {
              color: var(--text-color-1);
              font-weight: bold;
            }
          }
        }
      }

      &:nth-child(2) {
        border-top: none;
      }

      > span {
        margin: 0 9px 0 auto;
        color: var(--text-color-2);
      }

      &.category {
        > span {
          color: var(--text-color-1);
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid #e1e6eb;
        }
      }
    }

    &:last-child {
      > div {
        border-right: 1px solid #e1e6eb;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 200px;
        display: flex;
        align-items: center;
        background: #fff;

        &:not(:first-child) {
          padding: 0 13px 0 40px;
        }

        &.category {
          padding: 0 13px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        &.openable {
          cursor: pointer;
        }

        > span {
          margin: 0;
          font-size: 13px;
        }
      }
    }

    &.weekend {
      background: #fbfcfd !important;

      .far-top {
        background: #fbfcfd !important;
      }
    }
  }
`;

const Open = styled.div<{category: string; displayCategories: any}>`
  display: flex;
  width: 13px;
  height: 13px;
  > svg {
    ${({category, displayCategories}) =>
      displayCategories.includes(category) && 'transform: rotate(90deg);'}

    width: 13px;
    height: 13px;
    fill: var(--text-color-3);
    path {
      width: 13px;
      height: 13px;
    }
  }
`;

const Tab = styled.div<{current?: boolean}>`
  height: 32px;
  cursor: pointer;
  background: ${({current}) => (current ? '#E1F1FF' : '#fff')};
  color: ${({current}) => (current ? 'var(--primary)' : 'var(--text-color-3)')};
  font-weight: bold;
  display: inline-block;
  padding: 5.6px 10px;
  width: 54px;
  text-align: center;
  border: 1px solid #e1e6eb;

  &:nth-of-type(1) {
    margin-left: 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
