import React from 'react';
import styled from 'styled-components';
import {
  AnalyticsGraphChart,
  Building,
  Notice,
  PersonPhoto,
  CsvFileInfo,
  KeyPerson,
} from 'components/Ui/Icon';
import {StringParam, useQueryParam} from 'use-query-params';
import {useParams} from 'react-router-dom';
import {useCurrentClientQuery, useCurrentUserQuery, useProjectQuery} from 'api';

export default () => {
  const [information, setInformation] = useQueryParam(
    'information',
    StringParam,
  );

  const {projectId} = useParams<{
    projectId: string;
  }>();

  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const clickOpenButton = (path: string) => {
    if (information === path) {
      setInformation('');
    } else {
      setInformation(path);
    }
  };
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {data: {currentClient: {currentLeadContract} = {}} = {}} =
    useCurrentClientQuery();
  return (
    <Container>
      <Item
        active={information === 'company'}
        width={129}
        onClick={() => {
          clickOpenButton('company');
        }}>
        <div>
          <span>企業情報</span>
          <Building />
        </div>
      </Item>
      {(currentUser?.role === 'admin' ||
        currentLeadContract?.keyPersonDisplayable) && (
        <Item
          active={information === 'key_person'}
          width={164}
          onClick={() => {
            clickOpenButton('key_person');
          }}>
          <div>
            <span>キーマン情報</span>
            <KeyPerson />
          </div>
        </Item>
      )}
      {project?.projectType === 'upload' && (
        <Item
          active={information === 'csvfile'}
          width={129}
          onClick={() => {
            clickOpenButton('csvfile');
          }}>
          <div>
            <span>CSV項目</span>
            <CsvFileInfo />
          </div>
        </Item>
      )}
      <Item
        active={information === 'jobs'}
        width={130}
        onClick={() => {
          clickOpenButton('jobs');
        }}>
        <div>
          <span>求人情報</span>
          <PersonPhoto />
        </div>
      </Item>
      <Item
        active={information === 'pressrelease'}
        width={164}
        onClick={() => {
          clickOpenButton('pressrelease');
        }}>
        <div>
          <span>プレスリリース</span>
          <Notice />
        </div>
      </Item>
      <Item
        active={information === 'fiscal'}
        width={106}
        onClick={() => {
          clickOpenButton('fiscal');
        }}>
        <div>
          <span>決算</span>
          <AnalyticsGraphChart />
        </div>
      </Item>
      <Space />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 100%;
  background-color: #fff;
`;

const Item = styled.div<{active?: boolean; width?: number}>`
  position: relative;
  display: flex;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #e1e6eb;
  border-left: ${({active}) =>
    active ? 'none' : '1px solid var(--border-color)'};
  cursor: pointer;

  div {
    position: absolute;
    top: -1px;
    right: 0;
    z-index: 100;
    display: flex;
    width: 50px;
    height: 51px;
    transition:
      background 0.15s ease-out,
      width 0.15s ease-in;
    white-space: nowrap;
    overflow: hidden;

    span {
      display: none;
      padding: 15px;
      font-weight: bold;
      font-size: 12px;
      color: var(--text-color-1);
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      margin: auto;
      path {
        stroke: ${({active}) =>
          active ? 'var(--primary)' : 'var(--text-color-3)'};
      }
    }
  }

  &:hover {
    div {
      width: ${({width}) => width && width}px;
      background: #fff;
      border-top: 1px solid #e1e6eb;
      border-bottom: 1px solid #e1e6eb;
      border-left: 1px solid #e1e6eb;

      span {
        display: inline;
      }
    }
  }
`;

const Space = styled.div`
  flex: 1;
  border-left: 1px solid var(--border-color);
`;
