import React from 'react';

function Icon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 11.3333L14.6666 14.6666L11.3333 11.3333ZM12.9999 7.16665C12.9999 10.3883 10.3883 13 7.16659 13C3.94493 13 1.33325 10.3883 1.33325 7.16665C1.33325 3.94499 3.94493 1.33331 7.16659 1.33331C10.3883 1.33331 12.9999 3.94499 12.9999 7.16665Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
