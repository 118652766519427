import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {useUserLeadSourceReportsQuery, useLeadSourceSumReportQuery} from 'api';
import React from 'react';
import useLeadSourceHeaders from './useLeadSourceHeaders';

const sumValues = (values: number[]): number => {
  const total = values
    .filter(Boolean)
    .reduce((accumulator, currentValue) => accumulator + currentValue, null);
  return total;
};

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = useLeadSourceHeaders();
  const {data: {userLeadSourceReports = []} = {}} =
    useUserLeadSourceReportsQuery({});
  const {data: {leadSourceSumReport = null} = {}} = useLeadSourceSumReportQuery(
    {},
  );
  const sumReportData = React.useMemo(() => {
    if (!leadSourceSumReport) {
      return;
    }
    return {
      label: leadSourceSumReport.name,
      values: [
        sumValues(leadSourceSumReport.values),
        ...leadSourceSumReport.values,
      ],
      callProps: {
        style: {
          borderBottom: 'double 3px var(--text-color-2)',
        },
      },
    };
  }, [leadSourceSumReport]);

  const reportData = React.useMemo(() => {
    return userLeadSourceReports.map((report) => {
      return {
        label: report.name,
        values: [sumValues(report.values), ...report.values],
      };
    });
  }, [userLeadSourceReports]);

  const data: ReportTableData[] = [sumReportData, ...reportData].filter(
    Boolean,
  );

  return {
    data,
    headers,
  };
};

export default useTableData;
