import React from 'react';

interface Props {
  children?: React.ReactNode;
  active: boolean;
  onClick?: () => void;
}

export default ({children, active, onClick}: Props) => (
  <div
    onClick={onClick}
    className={`flex flex-col items-center justify-center gap-2 h-20 text-sm leading-none cursor-pointer ${
      active
        ? 'opacity-100 border-b-2 border-foreground'
        : 'opacity-30 border-b-2 border-transparent'
    }`}>
    {children}
  </div>
);
