import React, {useMemo} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {useQueryParams, DelimitedArrayParam} from 'use-query-params';
import {MainCategory, SubCategory} from 'api';
import {ChildCategory} from './ChildCategory';
import {Checkbox} from 'components/Ui';

type Props = {
  mainCategory: MainCategory;
};
const ParentCategory = ({mainCategory}: Props) => {
  const [query, setQuery] = useQueryParams({
    subCategoryIds: DelimitedArrayParam,
  });

  const {subCategoryIds = []} = query;

  const childrenIds = useMemo(() => {
    return mainCategory.subCategories.map(
      (subCategory: SubCategory) => subCategory.id,
    );
  }, [mainCategory]);

  const subCategories = useMemo(() => {
    return mainCategory.subCategories;
  }, [mainCategory]);

  const checkAll = async (checked: boolean) => {
    let targetIds = [];
    if (checked) {
      targetIds = await [...(subCategoryIds || []), ...childrenIds];
    } else {
      targetIds = await subCategoryIds.filter(
        (id) => !childrenIds.includes(id),
      );
    }
    setQuery({subCategoryIds: [...new Set(targetIds)]});
  };

  const checked = useMemo(() => {
    return _.difference(childrenIds, subCategoryIds).length === 0;
  }, [subCategoryIds]);

  const selectCategory = async (
    checked: boolean,
    selectedSubCategory: SubCategory,
  ) => {
    let targetIds: string[] = null;

    if (checked) {
      targetIds = await subCategoryIds.concat(selectedSubCategory.id);
    } else {
      targetIds = await subCategoryIds.filter(
        (sub_category_id) => sub_category_id !== selectedSubCategory.id,
      );
    }

    setQuery({subCategoryIds: [...new Set(targetIds)]});
  };

  return (
    <Container id={mainCategory.id}>
      <ParentCategorySection>
        <CategoryCheckBox
          checked={checked}
          onChange={(e) => checkAll(e.target.checked)}>
          {mainCategory.displayName} すべて
        </CategoryCheckBox>
        <Count>{mainCategory?.count}件</Count>
      </ParentCategorySection>
      <ChildCategorySection key={mainCategory.id}>
        {subCategories.map((subCategory) => (
          <ChildCategory
            key={subCategory.uuid}
            checked={subCategoryIds?.includes(subCategory.id)}
            subCategory={subCategory}
            onSelect={selectCategory}
          />
        ))}
      </ChildCategorySection>
    </Container>
  );
};

export {ParentCategory};

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  width: 400px;
`;

const ParentCategorySection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #e7e8eb;
`;

const CategoryCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;

  .ant-checkbox {
    top: 0em;
  }

  > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--text-color-0);
  }
`;

const Count = styled.div`
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary);
`;

const ChildCategorySection = styled.div`
  margin-top: 10px;
  margin-left: 22px;
  display: flex;
  flex-wrap: wrap;

  &&& {
    .category {
      margin: 5px 0;
      font-size: 12px;
      flex-basis: 45%;
    }

    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
`;
