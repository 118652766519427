import React, {useState} from 'react';
import styled from 'styled-components';
import {Plus} from 'components/Ui/Icon';
import Modal from 'react-modal';
import NewBlockGroup from './NewBlockGroup';
import {useHistory} from 'react-router';
import {sideMenuWidth} from 'helpers/sideMenu';

const NewButton = () => {
  const history = useHistory();

  const [IsOpen, setModalVisible] = useState(false);

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px',
      height: '500px',
      padding: '0',
    },
  };

  return (
    <Container>
      <Modal
        isOpen={IsOpen || location.pathname.endsWith('/new')}
        onRequestClose={() => {
          history.push(`/block/block_groups`);
          setModalVisible(false);
        }}
        style={customStyles}>
        <NewBlockGroup setModalVisible={setModalVisible} />
      </Modal>

      <New
        onClick={() => {
          history.push(`/block/block_groups/new`);
          setModalVisible(true);
        }}>
        <Plus color="#fff" />
      </New>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  bottom: 20px;
  left: ${sideMenuWidth + 20}px;
`;

const New = styled.div<{open?: boolean}>`
  cursor: pointer;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  &:hover {
    background-color: #2693ff;
  }

  transition: all 0.2s;
`;

export default NewButton;
