import React, {SVGProps} from 'react';

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.16667 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V10.8333"
      stroke="var(--text-color-3)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91675 9.58336L14.5834 2.91669C15.2737 2.22634 16.3931 2.22634 17.0834 2.91669C17.7737 3.60705 17.7737 4.72634 17.0834 5.41669L10.4167 12.0834L6.66675 13.3334L7.91675 9.58336Z"
      stroke="var(--text-color-3)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
