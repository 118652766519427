import React, {useState} from 'react';
import useSearchParams from '../useSearchParams';
import {Filter} from 'components/Ui/Icon';
import Modal from './Modal';

export default () => {
  const [visible, setVisible] = useState(false);
  const {query} = useSearchParams();

  const numberOfActionFilersInUse = [
    query.mailCampaignUuid !== undefined && query.mailCampaignUuid !== null,
    query.firstOpenedFrom || query.firstOpenedTo,
    query.clickedFrom || query.clickedTo,
  ].filter(Boolean).length;

  const numberOfFiltersInUse = numberOfActionFilersInUse;

  return (
    <>
      <div className="flex items-center gap-4">
        <button
          onClick={() => setVisible(true)}
          className="relative border border-c-border rounded w-9 h-9 bg-white text-c-lighti cursor-pointer flex items-center justify-center">
          <Filter color={numberOfFiltersInUse > 0 ? 'var(--primary)' : 'var(--text-color-3)'} />
          {numberOfFiltersInUse > 0 && (
            <span className="absolute right-[-5px] top-[-5px] rounded bg-c-primary min-w-[14px] h-[14px] flex items-center justify-center text-xs text-white">
              {numberOfFiltersInUse}
            </span>
          )}
        </button>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
