import React from 'react';
import styled from 'styled-components';
import {Click} from 'components/Ui/Icon';

export default ({
  mailCount,
  clickCount,
}: {
  mailCount: number;
  clickCount: number;
}) => {
  const clickRate = mailCount ? ((clickCount / mailCount) * 100).toFixed(1) : 0;

  return (
    <Container>
      <div>
        <Click />
      </div>
      <div>
        <span>クリック数</span>
        <span>
          <em>{clickCount}</em>件
        </span>
      </div>
      <div>
        <span>クリック率</span>
        <span>
          <em>{clickRate}</em>%
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 284px;
  height: 134px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  padding: 40px 0 40px 21px;

  > div:nth-child(1) {
    margin-right: 14px;
    width: 54px;
    height: 54px;
    display: flex;
    background: #f8f5ff;
    border-radius: 6px;

    svg {
      margin: auto;
      path {
        stroke: #8966ef;
      }
    }
  }

  > div:not(:nth-child(1)) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      color: var(--text-color-2);
    }

    > span:nth-child(2) {
      font-weight: bold;
      font-size: 14px;
      color: var(--text-color-1);

      em {
        margin-right: 6px;
        font-style: normal;
        font-size: 30px;
      }
    }
  }

  > div:nth-child(2) {
    margin-right: 10px;
  }
`;
