import React from 'react';

const colors = {
  A: 'var(--score-green-foreground)',
  B: 'var(--score-blue-foreground)',
  C: 'var(--score-orange-foreground)',
  D: 'var(--score-red-foreground)',
};

const ScoreCircle = ({score, rank}: {score: number; rank: string}) => {
  // 半径と円周の計算 (円周 = 2 * π * r)
  const radius = 15.91549430918954;
  const circumference = 2 * Math.PI * radius;

  // strokeDashoffsetをscoreに基づいて計算
  const strokeDashoffset = circumference * (1 - score / 100);

  const color = colors[rank as keyof typeof colors];

  return (
    <svg width="56px" height="56px" viewBox="0 0 40 40">
      {/* ドーナツの穴 */}
      <circle cx="20" cy="20" r={radius} fill="#fff"></circle>

      {/* ドーナツのリング */}
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="transparent"
        strokeWidth="3.5"
        className="stroke-c-border"></circle>

      {/* ドーナツのセグメント */}
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="transparent"
        strokeWidth="3.5"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        stroke={color}
        transform="rotate(-90 20 20)"></circle>

      {/* ドーナツ内のテキスト */}
      <g>
        {/* 大きい文字 'A' */}
        <text y="45%" transform="translate(0, 2)">
          <tspan
            x="50%"
            textAnchor="middle"
            fill={color}
            className="text-sm font-bold">
            {rank}
          </tspan>
        </text>
        {/* 下の小さい文字 */}
        <text y="70%" transform="translate(0, 2)">
          <tspan x="50%" textAnchor="middle" className="text-[8px]">
            {score}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default ScoreCircle;
