import React from 'react';
import styled from 'styled-components';
import {
  ProjectUserTelStatus,
  useProjectTelStatusCallDailyReportsQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import moment from 'moment';
import {ArrowRight} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import {useParams} from 'react-router';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();
  const {data: {projectTelStatusCallDailyReports = []} = {}} =
    useProjectTelStatusCallDailyReportsQuery({
      variables: {
        uuid: projectId,
        search: query,
      },
      fetchPolicy: 'network-only',
    });

  const totalCount = (object: any): any =>
    Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );

  const totalCategoryCountOfMonth = React.useCallback(
    (category: string) =>
      projectTelStatusCallDailyReports.reduce(
        (total: number, report: any) => total + totalCount(report[category]),
        0,
      ),
    [projectTelStatusCallDailyReports],
  );

  const callCount = React.useCallback((report: ProjectUserTelStatus) => {
    let count = 0;

    count += totalCount(report.call);
    count += totalCount(report.reception);
    count += totalCount(report.contact);
    count += totalCount(report.appoint);
    count += totalCount(report.document);
    count += totalCount(report.callNg);
    count += totalCount(report.receptionNg);
    count += totalCount(report.contactNg);

    return count;
  }, []);

  const contactCount = React.useCallback((report: ProjectUserTelStatus) => {
    let count = 0;

    count += totalCount(report.contact);
    count += totalCount(report.appoint);
    count += totalCount(report.document);
    count += totalCount(report.contactNg);

    return count;
  }, []);

  const totalCallCountOfMonth = React.useMemo(() => {
    let count = 0;

    count += totalCategoryCountOfMonth('call');
    count += totalCategoryCountOfMonth('reception');
    count += totalCategoryCountOfMonth('contact');
    count += totalCategoryCountOfMonth('appoint');
    count += totalCategoryCountOfMonth('document');
    count += totalCategoryCountOfMonth('callNg');
    count += totalCategoryCountOfMonth('receptionNg');
    count += totalCategoryCountOfMonth('contactNg');

    return count;
  }, [projectTelStatusCallDailyReports]);

  const totalContactCountOfMonth = React.useMemo(() => {
    let count = 0;

    count += totalCategoryCountOfMonth('contact');
    count += totalCategoryCountOfMonth('appoint');
    count += totalCategoryCountOfMonth('document');
    count += totalCategoryCountOfMonth('contactNg');

    return count;
  }, [projectTelStatusCallDailyReports]);

  const conversionRate = (before: number, after: number) => {
    if (!before || !after) return 0;

    return Math.round((after / before) * 1000) / 10;
  };

  if (projectTelStatusCallDailyReports.length === 0) return null;

  return (
    <Container>
      <div>
        <h2>ステータス・カテゴリ別</h2>
        <div>
          <Display>
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
            <Button
              type="text"
              className="left"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .startOf('month')
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>
            <Button
              type="text"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .startOf('month')
                    .add(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>

            <Button
              className="today"
              onClick={() =>
                setQuery({date: moment().startOf('month').format('YYYY-MM-DD')})
              }>
              今月
            </Button>
          </Display>
        </div>
      </div>

      <Table>
        <div className="column far-left">
          <div className="far-top">
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
          </div>

          <div className="category">
            <span>コール数</span>
            <div>
              <span>{totalCallCountOfMonth}</span>
            </div>
          </div>
          <div className="category">
            <span>キーマン接触</span>
            <div>
              <div className="top">
                <span>{totalContactCountOfMonth}</span>
              </div>
              <div className="bottom">
                <span>
                  {conversionRate(
                    totalCallCountOfMonth,
                    totalContactCountOfMonth,
                  )}
                  %
                </span>
              </div>
            </div>
          </div>
          <div className="category">
            <span>資料送付</span>
            <div>
              <div className="top">
                <span>{totalCategoryCountOfMonth('document')}</span>
              </div>
              <div className="bottom">
                <span>
                  {conversionRate(
                    totalCallCountOfMonth,
                    totalCategoryCountOfMonth('document'),
                  )}
                  %
                </span>
              </div>
            </div>
          </div>
          <div className="category">
            <span>アポ獲得</span>
            <div>
              <div className="top">
                <span>{totalCategoryCountOfMonth('appoint')}</span>
              </div>
              <div className="bottom">
                <span>
                  {conversionRate(
                    totalCallCountOfMonth,
                    totalCategoryCountOfMonth('appoint'),
                  )}
                  %
                </span>
              </div>
            </div>
          </div>
        </div>

        {projectTelStatusCallDailyReports.map((report: any) => (
          <div
            className={
              'column ' +
              ((moment(report.countDate).weekday() === 5 ||
                moment(report.countDate).weekday() === 6) &&
                'weekend')
            }
            key={report.countDate}>
            <div
              className={
                'far-top ' +
                (moment(report.countDate).isSame(new Date(), 'day') && 'today')
              }>
              <span>{moment(report.countDate).format('DD')}</span>
              <span>{moment(report.countDate).format('ddd')}</span>
            </div>

            <div>
              <div>
                {!!callCount(report) && <span>{callCount(report)}</span>}
              </div>
            </div>

            <div>
              <div className="top">
                {!!callCount(report) && <span>{contactCount(report)}</span>}
              </div>
              <div className="bottom">
                {!!callCount(report) && (
                  <span>
                    {conversionRate(callCount(report), contactCount(report))}%
                  </span>
                )}
              </div>
            </div>

            <div>
              <div className="top">
                {!!callCount(report) && (
                  <span>{totalCount(report.document)}</span>
                )}
              </div>
              <div className="bottom">
                {!!callCount(report) && (
                  <span>
                    {conversionRate(
                      callCount(report),
                      totalCount(report.document),
                    )}
                    %
                  </span>
                )}
              </div>
            </div>

            <div>
              <div className="top">
                {!!callCount(report) && (
                  <span>{totalCount(report.appoint)}</span>
                )}
              </div>
              <div className="bottom">
                {!!callCount(report) && (
                  <span>
                    {conversionRate(
                      callCount(report),
                      totalCount(report.appoint),
                    )}
                    %
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > h2 {
      margin: auto auto auto 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: var(--text-color-1);
    }

    > div {
      position: relative;
      margin: auto 0 auto auto;
      display: flex;
    }
  }
`;

const Display = styled.div`
  margin-left: 10px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-1);
  }

  > button {
    padding: 5.6px 6px;

    svg {
      path {
        stroke: var(--text-color-2);
      }
    }

    &.left {
      svg {
        transform: rotate(180deg);
      }
    }

    &.today {
      margin-left: 10px;
      padding: 5.6px 10px;
      border: 1px solid #e1e6eb;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  // height: 303px;
  width: 969px;

  .column {
    display: flex;
    flex-direction: column;

    > div {
      width: 63px;
      min-height: 72px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-child {
        min-height: 43px;
        > span {
          margin: 0 auto;
        }

        &.far-top {
          top: 0;
          z-index: 3;
          position: sticky;
          background: #ffffff;
          border-bottom: 1px solid #e1e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin: auto;
          }

          &.today {
            border-top: 2px solid var(--text-color-1);

            span {
              color: var(--text-color-1);
              font-weight: bold;
            }
          }
        }
      }

      &:nth-child(2) {
        border-top: none;
      }

      > div {
        display: flex;
        width: 100%;
        height: 100%;
        color: var(--text-color-2);

        &.top {
        }

        &.bottom {
          background: #fbfcfd;
          span {
            font-weight: bold;
            color: var(--text-color-1);
          }
        }

        > span {
          margin: auto 6px auto auto;
        }
      }

      &.category {
        display: flex;

        > span {
          color: var(--text-color-1);
          font-weight: bold;
        }
      }
    }

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 220px;
        display: flex;
        align-items: center;
        padding: 0 40px;

        background: #fff;

        &.category {
          padding: 0;
          flex-direction: initial;

          > *:first-child {
            flex: 1;
            padding-left: 13px;
          }

          > *:last-child {
            width: 60px;
            flex-direction: column;

            > div {
              display: flex;
              width: 100%;
              flex: 1;
              &.top {
              }

              &.bottom {
                background: #fbfcfd;
                span {
                  font-weight: bold;
                  color: var(--text-color-1);
                }
              }

              > span {
                margin: auto 6px auto auto;
              }
            }
          }
        }

        > span {
          margin: auto auto auto 0;
          font-size: 13px;
        }
      }
    }

    &.weekend {
      background: #fbfcfd !important;

      .far-top {
        background: #fbfcfd !important;
      }
    }
  }
`;
