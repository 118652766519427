import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33341 16.5H16.6667C17.5872 16.5 18.3334 15.7538 18.3334 14.8333V5.66667C18.3334 4.74619 17.5872 4 16.6667 4H9.16675L7.74747 1.87108C7.59292 1.63925 7.33272 1.5 7.0541 1.5H3.33341C2.41294 1.5 1.66675 2.24619 1.66675 3.16667V14.8333C1.66675 15.7538 2.41294 16.5 3.33341 16.5Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
