import React from 'react';
import {CustomizeItem, usePreleadQuery, useUpdatePreleadMutation} from 'api';
import {Input} from 'components/antd';
import _ from 'lodash';
import styled from 'styled-components';
import usePreleadContext from '../../usePreleadContext'

export default ({customizeItem}: {customizeItem: CustomizeItem}) => {
  const {preleadId} = usePreleadContext()

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const [value, setValue] = React.useState('');

  const [updatePrelead] = useUpdatePreleadMutation();

  const updateCustomizeItemText = React.useCallback(() => {
    const attributes: {[key: string]: any} = {};
    attributes[_.camelCase(customizeItem.columnName)] = value;
    updatePrelead({
      variables: {uuid: prelead?.uuid, attributes},
    });
  }, [prelead, customizeItem, value]);

  const setCustomizeItemText = React.useCallback(
    (e: any) => {
      setValue(e.target.value);
    },
    [prelead, customizeItem],
  );

  React.useEffect(() => {
    //@ts-ignore
    setValue(prelead[_.camelCase(customizeItem.columnName)]);
  }, [prelead, customizeItem]);

  return (
    <InputText
      value={value}
      onChange={setCustomizeItemText}
      onBlur={updateCustomizeItemText}
    />
  );
};

const InputText = styled(Input)`
  border-color: transparent;

  &:hover {
    border-color: var(--border-color);
  }
`;
