import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button, Radio} from 'components/antd';
import {
  Client,
  Project,
  useCurrentClientQuery,
  useRequestProjectToClientMutation,
} from 'api';

export default ({
  project,
  onClose,
}: {
  project: Project;
  onClose: () => void;
}) => {
  const [selectedClientId, setSelectedClientId] = React.useState(null);

  const {data: {currentClient: {offeredClients = []} = {}} = {}} =
    useCurrentClientQuery();

  const {offeredClients: projectOfferedClients = []} = project;

  useEffect(() => {
    setSelectedClientId(projectOfferedClients[0]?.id);
  }, [projectOfferedClients]);

  const handleClose = () => {
    setSelectedClientId(null);
    onClose();
  };

  const [requestProjectToClient, {loading}] = useRequestProjectToClientMutation(
    {
      onCompleted: handleClose,
      refetchQueries: ['project', 'currentUser'],
    },
  );

  const onChange = React.useCallback(
    (e: any) => setSelectedClientId(e.target.value),
    [],
  );

  const onSubmit = React.useCallback(() => {
    const message =
      '依頼した営業リスト内で登録したタイムラインの情報は、\n依頼先のタイムラインには当該営業リスト内にしか反映されません。\n依頼してもよろしいですか。';

    if (selectedClientId && !confirm(message)) return false;
    requestProjectToClient({
      variables: {
        projectId: project.id,
        offeredClientId: selectedClientId,
      },
    });
  }, [selectedClientId]);

  return (
    <Container>
      <h3>依頼先を設定</h3>
      <ScrollWrapper>
        <List>
          {offeredClients?.map((client: Client) => (
            <Radio
              key={client.id}
              value={client.id}
              checked={client.id === selectedClientId}
              onChange={onChange}>
              {client.name}
            </Radio>
          ))}
        </List>
      </ScrollWrapper>
      <Radio
        checked={!selectedClientId}
        onChange={() => setSelectedClientId(null)}>
        未設定
      </Radio>
      <Action>
        <Button
          type="primary"
          size="small"
          loading={loading}
          onClick={onSubmit}>
          設定
        </Button>
      </Action>
    </Container>
  );
};

const Container = styled.div`
  width: 240px;
  padding: 20px;

  h3 {
    color: var(--text-color-2);
    font-size: 12px;
    font-weight: bold;
  }
`;

const ScrollWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;
  border-bottom: solid 1px #e1e6eb;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;
