import React from 'react';
import {useTable} from 'react-table';
import Cell from './Cell';
import {usePoolQuery, useProspectPoolsByAccountPoolQuery} from 'api';
import {useParams} from 'react-router-dom';

interface Column {
  id: string;
  Header: string | React.ReactNode;
  Cell: React.FC<any>;
  style?: React.CSSProperties;
}

export default () => {
  const {poolId, accountId} = useParams<{
    poolId: string;
    accountId: string;
  }>();
  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const {data: {prospectPoolsByAccountPool = []} = {}} =
    useProspectPoolsByAccountPoolQuery({
      variables: {
        uuid: accountId,
      },
    });

  const getColumns = (isSalesforceActive: boolean): Column[] => {
    const columns: Column[] = [
      // {
      //   id: 'check',
      //   Header: '',
      //   Cell: Cell.Check,
      //   style: {
      //     width: '32px',
      //   },
      // },
      {
        id: 'name',
        Header: '名前',
        style: {
          minWidth: '360px',
        },
        Cell: Cell.ProspectName,
      },
      // {
      //   id: 'score',
      //   Header: 'スコア',
      //   Cell: (row: any) => (
      //     <div className="text-lg font-bold flex items-center justify-center h-6 w-6 text-[#53B77B] bg-[#F0FFF6]">
      //       A
      //     </div>
      //   ),
      //   style: {
      //     width: '60px',
      //   },
      // },
      {
        id: 'status',
        Header: 'ステージ',
        Cell: Cell.Status,
        style: {
          width: '80px',
        },
      },
      {
        id: 'latestLeadSource',
        Header: '最新リードソース',
        Cell: Cell.LatestLeadSource,
      },
      {
        id: 'latestInflowDate',
        Header: '最新流入日',
        Cell: Cell.LatestInflowDate,
        style: {
          width: '100px',
        },
      },
      {
        id: 'taskDate',
        Header: 'ToDo日時',
        Cell: Cell.TodoDate,
        style: {
          width: '100px',
        },
      },
      {
        id: 'visitDate',
        Header: 'Web訪問日',
        Cell: Cell.TrackedDate,
        style: {
          width: '100px',
        },
      },
      // {
      //   id: 'clickDate',
      //   Header: (
      //     <span style={{lineHeight: 1}}>
      //       メール開封
      //       <br />
      //       クリック日時
      //     </span>
      //   ),
      //   Cell: (row: any) => {
      //     return <div>2021/09/01</div>;
      //   },
      //   style: {
      //     width: '100px',
      //   },
      // },
      // {
      //   id: 'callDate',
      //   Header: '最新コール日時',
      //   Cell: (row: any) => {
      //     return <div>2021/09/01</div>;
      //   },
      //   style: {
      //     width: '100px',
      //   },
      // },
      ...(isSalesforceActive
        ? [
            {
              id: 'salesforceUser',
              Header: 'Salesforce所有者',
              Cell: Cell.SalesforceUser,
              style: {
                width: '100px',
              },
            },
          ]
        : []),
      {
        id: 'user',
        Header: '担当者',
        Cell: Cell.User,
        style: {
          width: '100px',
        },
      },
      {
        id: 'menu',
        Header: '',
        Cell: Cell.Menu,
        style: {
          width: '80px',
        },
      },
    ];
    return columns;
  };

  const columns = React.useMemo(
    () => getColumns(pool?.poolSalesforceSetting?.isActive ?? false),
    [pool?.poolSalesforceSetting?.isActive],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<any>({columns, data: prospectPoolsByAccountPool || []});

  return (
    <div className="flex flex-col items-center">
      <table
        {...getTableProps()}
        cellSpacing="0"
        cellPadding="0"
        className="w-full table-fixed border-separate border-spacing-0 border-l border-r border-c-border">
        <thead className="text-c-light text-xs">
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    className="p-2 text-xs text-c-light border-y text-left border-c-border bg-white leading-tight h-9"
                    {...column.getHeaderProps([
                      {
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="text-sm">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      className="min-w-[50px] h-20 p-2 border-b border-c-border truncate bg-white first-of-type:px-4"
                      {...cell.getCellProps()}
                      key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
