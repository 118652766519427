import React, {useState} from 'react';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router';
import {useProjectGroupQuery} from 'api';
import {useUpdateProjectGroupMutation} from 'api';
import {Input, Upload, Image} from 'components/antd';
import {
  ArrowRight,
  OpenFolder,
  LockPrivary,
  DrawerClose,
  GroupDimension,
  CameraPhoto,
} from 'components/Ui/Icon';
import {Link} from 'react-router-dom';

export default () => {
  const history = useHistory();
  const {projectGroupId} = useParams<{projectGroupId: string}>();
  const {data: {projectGroup = null} = {}} = useProjectGroupQuery({
    variables: {uuid: projectGroupId},
    skip: !projectGroupId || projectGroupId === 'new',
  });

  const [projectGroupName, setProjectGroupName] = useState(null);
  const [updateProjectGroup, {loading}] = useUpdateProjectGroupMutation({
    refetchQueries: ['projectGroups', 'currentUser'],
  });

  React.useEffect(() => {
    setProjectGroupName(projectGroup?.name);
  }, [projectGroup]);

  const handleChangeName = (value: string) => {
    setProjectGroupName(value);
  };

  const updateName = React.useCallback(
    (e) => {
      if (e.target.value) {
        updateProjectGroup({
          variables: {uuid: projectGroupId, attributes: {name: e.target.value}},
        });
      } else {
        alert('グループ名は必須です。');
      }
    },
    [projectGroupId],
  );

  const closeModal = () => history.push(`/list/groups${location.search}`);

  const StatusOpenProjectsCount = (projectGroup: any) => {
    return projectGroup?.projects.filter(
      (project: any) => project.status === 'open',
    ).length;
  };

  if (!projectGroup && !loading) return null;

  return (
    <Container>
      <LeftSide>
        <ImageBox>
          {projectGroup?.imageUrl ? (
            <Image src={projectGroup.imageUrl} />
          ) : (
            <GroupDimension />
          )}
          <Upload
            accept="image/*"
            showUploadList={false}
            className="upload"
            disabled={loading}
            customRequest={() => false}
            onChange={(info) => {
              const file = info.file.originFileObj;
              updateProjectGroup({
                variables: {uuid: projectGroupId, attributes: {image: file}},
              });
            }}>
            <CameraPhoto />
          </Upload>
        </ImageBox>
      </LeftSide>
      <RightSide>
        <Name
          value={projectGroupName}
          onChange={(e) => handleChangeName(e.target.value)}
          onBlur={updateName}
          onPressEnter={updateName}
        />
        <div>
          {projectGroup?.isPrivate ? (
            <Label backgroud="#FDECEC" color="#EB5757">
              <LockPrivary />
              <Space />
              <span>Private</span>
            </Label>
          ) : (
            <Label backgroud="#E5F8EC" color="#4FCE82">
              <OpenFolder />
              <Space />
              <span>Public</span>
            </Label>
          )}
          <ProjectLink to={`/list/groups/${projectGroup?.uuid}/projects`}>
            <span>{StatusOpenProjectsCount(projectGroup)} リスト</span>
            <Space />
            <ArrowRight />
          </ProjectLink>
        </div>
      </RightSide>

      <CloseButtonWrapper onClick={closeModal}>
        <DrawerClose />
      </CloseButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 40px 40px 0 40px;
`;

const LeftSide = styled.div``;

const RightSide = styled.div`
  margin-left: 25px;
  flex: 1;
  flex-direction: column;

  > div {
    &:last-child {
      display: flex;
    }
  }
`;

const ImageBox = styled.div`
  position: relative;
  margin-left: 14x;
  width: 71px;
  height: 71px;

  > * {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 71px;
    height: 71px;
  }

  .upload {
    position: absolute;
    bottom: -9px;
    right: -13px;
    display: flex;
    width: 26px;
    height: 26px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer;

    .ant-upload-select {
      display: flex;
      width: 100%;
      height: 100%;

      > span {
        margin: 5px auto auto auto;
      }
    }
  }
`;

const Name = styled(Input)`
  font-size: 18px;
  line-height: 26px;
  border-color: transparent;

  &:hover {
    border-color: var(--border-color);
  }
`;

const Label = styled.div<{backgroud: string; color: string}>`
  display: flex;
  align-items: center;
  padding: 5px 17px;
  background: ${({backgroud}) => backgroud};
  color: ${({color}) => color};
  border-radius: 100px;

  > svg {
    path {
      stroke: ${({color}) => color};
    }
  }

  > span {
    font-weight: 500;
    font-size: 12px;
  }

  &:last-child {
    margin-left: 6px;
  }
`;

const ProjectLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px 17px;
  color: var(--primary);
  background: #e3f2ff;
  border-radius: 100px;

  > svg {
    path {
      stroke: var(--primary);
    }
  }

  > span {
    font-weight: 500;
    font-size: 12px;
  }

  &:last-child {
    margin-left: 6px;
  }
`;

const Space = styled.div`
  width: 6px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
`;
