import React, {useEffect} from 'react';
import {useParams, Switch, Route} from 'react-router';
import {useProjectQuery} from 'api';
import {Link} from 'react-router-dom';
import ProjectSummary from './projectSummary';
import BasicInformation from './basicInformation';
import Sidemenu from './sidemenu';
import CsvSummary from './csvSummary';
import SearchCondition from './searchCondition';
import preleadMasterSearchCondition from './preleadMasterSearchCondition';
import {Breadcrumb} from 'components/antd';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {StringParam, useQueryParam} from 'use-query-params';
import {Drawer} from 'components';
import {DrawerClose} from 'components/Ui/Icon';
import {DetailInformation} from './basicInformation/DetailInformation';
import {BoxReport} from './boxReport';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {projectId} = useParams<{projectId: string}>();
  const [status] = useQueryParam('status', StringParam);
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'network-only',
    skip: !projectId || projectId === 'new',
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      }
    },
  });

  if (!projectId) return null;

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  const isOpen = !!project;
  const closeModal = () =>
    history.push(createPath(`projects${location.search}`));

  return (
    <Drawer isOpen={isOpen} onClose={closeModal} width={940}>
      <Container>
        <Header>
          <CustomBreadcrumb separator=">">
            <CustomBreadcrumb.Item>
              <Link to={createPath('projects')}>営業リスト一覧</Link>
            </CustomBreadcrumb.Item>
            <CustomBreadcrumb.Item>
              <span>{project?.name}</span>
            </CustomBreadcrumb.Item>
          </CustomBreadcrumb>
          <CloseButtonWrapper onClick={closeModal}>
            <DrawerClose />
          </CloseButtonWrapper>
        </Header>

        <Body>
          <Main>
            <BasicInformation />
            <Tabs>
              <TabItem active={location.pathname.endsWith('/project_detail')}>
                <Link
                  to={createPath(
                    `projects/${projectId}/project_detail${location.search}`,
                  )}>
                  詳細
                </Link>
              </TabItem>
              {status === 'open' && (
                <TabItem
                  active={location.pathname.endsWith('/project_summary')}>
                  <Link
                    to={createPath(
                      `projects/${projectId}/project_summary${location.search}`,
                    )}>
                    営業リストサマリ
                  </Link>
                </TabItem>
              )}
              {project?.projectType === 'upload' ||
              project?.projectType === 'cold_lead' ? (
                <TabItem active={location.pathname.endsWith('/csv_summary')}>
                  <Link
                    to={createPath(
                      `projects/${projectId}/csv_summary${location.search}`,
                    )}>
                    CSVサマリ
                  </Link>
                </TabItem>
              ) : (
                project?.projectType === 'search' && (
                  <TabItem
                    active={location.pathname.endsWith('/search_condition')}>
                    <Link
                      to={createPath(
                        `projects/${projectId}/search_condition${location.search}`,
                      )}>
                      {status === 'open' || status === 'archived'
                        ? 'リスト条件'
                        : '編集中のリスト条件'}
                    </Link>
                  </TabItem>
                )
              )}
              {project?.projectType === 'prelead_master' && (
                <TabItem
                  active={location.pathname.endsWith(
                    '/master_search_condition',
                  )}>
                  <Link
                    to={createPath(
                      `projects/${projectId}/master_search_condition${location.search}`,
                    )}>
                    マスター条件
                  </Link>
                </TabItem>
              )}
              {status === 'open' && (
                <TabItem active={location.pathname.endsWith('/report')}>
                  <Link
                    to={createPath(
                      `projects/${projectId}/report${location.search}`,
                    )}>
                    レポート
                  </Link>
                </TabItem>
              )}
            </Tabs>

            <ContentWrapper>
              <Switch>
                <Route
                  path={projectRoutePaths('projects/:projectId/project_detail')}
                  component={DetailInformation}
                />
                <Route
                  path={projectRoutePaths(
                    'projects/:projectId/project_summary',
                  )}
                  component={ProjectSummary}
                />
                <Route
                  path={projectRoutePaths('projects/:projectId/csv_summary')}
                  component={CsvSummary}
                />
                <Route
                  path={projectRoutePaths(
                    'projects/:projectId/search_condition',
                  )}
                  component={SearchCondition}
                />
                <Route
                  path={projectRoutePaths(
                    'projects/:projectId/master_search_condition',
                  )}
                  component={preleadMasterSearchCondition}
                />
                <Route
                  path={projectRoutePaths('projects/:projectId/report')}
                  component={BoxReport}
                />
              </Switch>
            </ContentWrapper>
          </Main>

          <Sidemenu />
        </Body>
      </Container>
    </Drawer>
  );
};

const Container = styled.div``;

const Header = styled.div`
  background: #f3f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomBreadcrumb = styled(Breadcrumb)`
  padding: 19px 0 19px 17px;
  background: #f3f5f7;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.15px;

  a {
    &:hover {
      color: var(--primary);
    }
  }

  .ant-breadcrumb-link {
    color: var(--text-color-2);
  }

  .ant-breadcrumb-link > span:last-child {
    font-weight: bold;
    color: var(--text-color-0);
  }

  .ant-breadcrumb-separator {
    margin: 0 25px;
  }
`;

const CloseButtonWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  padding: 35px 50px;
`;

const Main = styled.div`
  margin-right: 65px;
  display: flex;
  flex-direction: column;
  width: 540px;
  word-break: break-all;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #dadce0;
`;

const TabItem = styled.div<{active?: boolean}>`
  flex: 1;
  text-align: center;
  border-bottom: ${({active}) => (active ? '2px solid var(--primary)' : '')};
  a {
    font-size: 12px;
    font-weight: bold;
    line-height: 35px;
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
  }
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;
