const scoreColorForeground = (rank: string) => {
  if (rank === 'A') {
    return 'var(--score-green-foreground)';
  } else if (rank === 'B') {
    return 'var(--score-blue-foreground)';
  } else if (rank === 'C') {
    return 'var(--score-orange-foreground)';
  } else if (rank === 'D') {
    return 'var(--score-red-foreground)';
  } else {
    return 'var(--score-green-foreground)';
  }
};

const scoreColor = (rank: string) => {
  if (rank === 'A') {
    return 'var(--score-green)';
  } else if (rank === 'B') {
    return 'var(--score-blue)';
  } else if (rank === 'C') {
    return 'var(--score-orange)';
  } else if (rank === 'D') {
    return 'var(--score-red)';
  } else {
    return 'var(--score-green)';
  }
};

export {scoreColorForeground, scoreColor};
