import {usePoolQuery, usePreleadProjectQuery} from 'api';
import {ArrowDown} from 'components/Ui/Icon';
import React from 'react';
import {Link, useParams} from 'react-router-dom';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();
  const {data: {preleadProject: {prelead: {accountPool = {}} = {}}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
    });
  const {data: {pool = {}} = {}} = usePoolQuery({});

  if (!accountPool) return null;

  return (
    <div className="ml-24 my-[-10px]">
      <Link
        to={`/pools/${pool?.uuid}/accounts/${accountPool.uuid}`}
        target="_blank">
        企業管理ページ
        <span className="absolute top-[59px]">
          <ArrowDown className="-rotate-90 w-4 h-4" color="var(--link-color)" />
        </span>
      </Link>
    </div>
  );
};
