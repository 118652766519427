import React from 'react';
import styled from 'styled-components';
import {useActionCountsQuery} from 'api';
import {useParams} from 'react-router-dom';
import useSearchParams from './useSearchParams';
import {useRecoilState} from 'recoil';
import checkedActionIdsAtom from './checkedActionIdsAtom';

export default () => {
  const {query, setQuery, searchParams} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();
  const {data: {actionCounts = {}} = {}} = useActionCountsQuery({
    variables: {
      poolUuid: poolId,
      search: searchParams,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [, setCheckedActionIds] = useRecoilState(checkedActionIdsAtom);

  return (
    <Container>
      <Item
        onClick={() => {
          setQuery({status: 'open', page: 1});
          setCheckedActionIds([]);
        }}
        current={query.status === 'open'}>
        <div>
          <em>{actionCounts.openCount || 0}</em>
          <span>開始</span>
        </div>
      </Item>
      <Item
        onClick={() => {
          setQuery({status: 'waiting', page: 1});
          setCheckedActionIds([]);
        }}
        current={query.status === 'waiting'}>
        <div>
          <em>{actionCounts.waitingCount || 0}</em>
          <span>開始待ち</span>
        </div>
      </Item>
      <Item
        onClick={() => {
          setQuery({status: 'expired', page: 1});
          setCheckedActionIds([]);
        }}
        current={query.status === 'expired'}>
        <div>
          <em>{actionCounts.expiredCount || 0}</em>
          <span>期限切れ</span>
        </div>
      </Item>
      <Item
        onClick={() => {
          setQuery({status: 'completed', page: 1});
          setCheckedActionIds([]);
        }}
        current={query.status === 'completed'}>
        <div>
          <em>{actionCounts.completedCount || 0}</em>
          <span>完了</span>
        </div>
      </Item>
      <Item
        onClick={() => {
          setQuery({status: 'canceled', page: 1});
          setCheckedActionIds([]);
        }}
        current={query.status === 'canceled'}>
        <div>
          <em>{actionCounts.canceledCount || 0}</em>
          <span>キャンセル</span>
        </div>
      </Item>
    </Container>
  );
};

const Container = styled.div`
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
  width: 100%;

  & > * {
    display: table-cell;
    width: 100px;
    height: 60px;
    padding: 0.5rem 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e1e6eb;

      em {
        font-style: normal;
        font-size: 18px;
        font-weight: bold;
      }

      span {
        font-size: 12px;
        font-weight: medium;
      }
    }

    &:first-child {
      div {
        border: none;
      }
    }
  }
`;

const Item = styled.div<{current?: boolean}>`
  color: ${({current}) => (current ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  cursor: pointer;
`;
