import React from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const currentPath = (path: string) => location.pathname.indexOf(path) > -1;
  const {createPath} = useProjectPath();

  return (
    <Container>
      <Tab
        current={currentPath('/search/website/multiple') ? 1 : 0}
        to={createPath(`projects/${projectId}/search/website/multiple`)}>
        <span>複数</span>
      </Tab>
      <Tab
        current={currentPath('/search/website/single') ? 1 : 0}
        to={createPath(`projects/${projectId}/search/website/single`)}>
        <span>単数</span>
      </Tab>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #f3f5f7;
  border-bottom: 0.5px solid var(--text-color-3);
`;

const Tab = styled(Link)<{current?: number}>`
  width: 63px;
  height: 51px;
  display: flex;
  cursor: pointer;
  border-bottom: ${({current}) => (current ? '3px solid var(--primary)' : '')};

  &:first-child {
    margin-left: 20px;
  }

  span {
    margin: 18px auto auto auto;
    font-size: 14px;
    font-weight: bold;
    color: ${({current}) => (current ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  }
`;
