import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, AutomaticMailBounceProspectActivity} from 'api';
import {AutomaticMail} from 'components/Ui/Icon';

interface AutomaticMailBounceActivity extends ProspectActivity {
  resource: AutomaticMailBounceProspectActivity;
}

interface Props {
  prospectActivity: AutomaticMailBounceActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      automaticMailSend: {
        automaticMail: {stepAutomaticMail: {step = {}} = {}} = {},
      } = {},
    },
  } = prospectActivity;

  return (
    <>
      <AutomaticMail active={true} width="32" height="32" />
      <Content>
        <ContentHeader>
          <h3>{step.name}</h3>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </ContentHeader>
        <Wrapper>バウンス</Wrapper>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: var(--primary);
    font-weight: bold;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Wrapper = styled.span`
  color: var(--text-color-2);
`;
