import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router';
import {
  Project,
  useDeleteProjectMutation,
  useProjectWithSearchConditionQuery,
  useWebsiteQuery,
} from 'api';
import {DotMenu, Edit, Upload, Trash} from 'components/Ui/Icon';
import {PreleadCsvUploadModal} from 'components/Ui';
import UploadContactsModal from './UploadContactsModal';
import {Popover, message} from 'components/antd';
import {useParams} from 'react-router';
import useProjectPath from 'hooks/useProjectPath';
import useClientUser from 'hooks/useClientUser';

interface Props {
  project: Project;
}

export default ({project}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const history = useHistory();
  const {createPath} = useProjectPath();
  const location = useLocation();
  const {isMember} = useClientUser();
  const [isUploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [isUploadContactsModalVisible, setUploadContactsModalVisible] =
    React.useState(false);
  const {data: {project: {projectSearchCondition = {}} = {}} = {}} =
    useProjectWithSearchConditionQuery({
      variables: {uuid: projectId},
      skip: !projectId,
    });

  const websiteId = projectSearchCondition?.websiteId;

  const {data: {website = null} = {}} = useWebsiteQuery({
    variables: {id: websiteId},
    skip: !websiteId,
  });

  const [deleteProject] = useDeleteProjectMutation({
    refetchQueries: ['projects'],
    onCompleted: () => {
      history.push(createPath(`projects${location.search}`));
      message.info('営業リストを削除しました');
    },
  });

  const handleDeleteProject = () => {
    deleteProject({
      variables: {
        uuid: project.uuid,
      },
    });
  };

  const PopoverDelete = () => (
    <Popover
      content={
        <PopoverMenu>
          <DeleteProjectButton
            onClick={() => {
              if (confirm('営業リストを削除してよろしいですか？')) {
                handleDeleteProject();
              }
            }}>
            <div>
              <Trash />
              <span>営業リストを削除する</span>
            </div>
          </DeleteProjectButton>
        </PopoverMenu>
      }
      placement="bottomLeft"
      trigger="hover">
      <MenuWrapper>
        <DotMenu />
      </MenuWrapper>
    </Popover>
  );

  const pathToList: string = useMemo(() => {
    if (projectSearchCondition?.company) {
      return createPath(
        `projects/${project.uuid}/search/category?specificCompanyId=${projectSearchCondition?.company.uuid}`,
      );
    }
    if (website) {
      return createPath(
        `projects/${project.uuid}/search/website/single/${website.websiteType}`,
      );
    }
    if (projectSearchCondition?.technologies?.length > 0) {
      return createPath(`projects/${project.uuid}/search/technology`);
    } else if (projectSearchCondition?.websiteIds?.length > 0) {
      return createPath(`projects/${project.uuid}/search/website/multiple`);
    } else {
      return createPath(`projects/${project.uuid}/search/category`);
    }
  }, [projectSearchCondition, website]);

  if (!project) return null;

  if (project.projectType === 'search') {
    return (
      <Container>
        <ActionBox
          onClick={() => {
            history.push(pathToList);
          }}>
          <div>
            <IconWrapper>
              <Edit />
            </IconWrapper>
          </div>

          <div>
            <Label>リストの検索条件を指定</Label>
            <Count>{project.count.toLocaleString()}</Count>
          </div>
        </ActionBox>
        {isMember && <PopoverDelete />}
      </Container>
    );
  }

  if (project.projectType === 'upload' && isMember) {
    return (
      <Container>
        <ActionBox onClick={() => setUploadModalVisible(true)}>
          <div>
            <IconWrapper onClick={() => setUploadModalVisible(true)}>
              <Upload />
            </IconWrapper>
          </div>

          <div>
            <Label>リストをアップロード</Label>
            <Count>{project.count.toLocaleString()}</Count>
          </div>
        </ActionBox>
        <PopoverDelete />
        <PreleadCsvUploadModal
          project={project}
          visible={isUploadModalVisible}
          onClose={() => setUploadModalVisible(false)}
        />
      </Container>
    );
  }

  if (project.projectType === 'cold_lead') {
    return (
      <Container>
        <ActionBox onClick={() => setUploadContactsModalVisible(true)}>
          <div>
            <IconWrapper onClick={() => setUploadContactsModalVisible(true)}>
              <Upload />
            </IconWrapper>
          </div>

          <div>
            <Label>連絡先をアップロード</Label>
            <Count>{project.count.toLocaleString()}</Count>
          </div>
        </ActionBox>
        <PopoverDelete />
        <UploadContactsModal
          project={project}
          visible={isUploadContactsModalVisible}
          onClose={() => setUploadContactsModalVisible(false)}
        />
      </Container>
    );
  }
};

const Container = styled.div`
  display: flex;
`;

const ActionBox = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 4px;
  width: 150px;
  height: 50px;
  border: 1px solid #e1e6eb;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  > div:first-child {
    display: flex;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background: #e1e6eb;
  border-radius: 50%;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: var(--text-color-2);
    }
  }
`;

const Count = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color-1);
`;

const Label = styled.span`
  display: block;
  transform: scale(0.7);
  transform-origin: left;
  font-weight: bold;
  font-size: 10px;
  color: var(--text-color-3);
  white-space: nowrap;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const PopoverMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  box-shadow: 0px 0px 4px var(--text-color-3);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  height: 45px;
  cursor: pointer;

  div {
    display: flex;
    width: 100%;
    height: 100%;

    svg {
      margin: auto 13px auto 10px;
    }
    span {
      margin: auto 0;
      font-weight: bold;
      font-size: 12px;
      color: var(--text-color-1);
    }

    &:hover {
      background: #f1f8ff;
      border-radius: 4px;
    }
  }
`;

const DeleteProjectButton = styled(MenuItem)`
  border-top: 1px solid #e1e6eb;

  div {
    svg {
      path {
        stroke: var(--text-color-3);
      }
    }
    span {
      color: var(--text-color-1);
    }
  }
`;
