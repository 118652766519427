import gql from 'graphql-tag';

export default gql`
  fragment prospectScoreSettingFragment on ProspectScoreSetting {
    id
    clientProspectScoreSettingPositionKeywords {
      id
      name
    }
    leadSourceIds
    leadSources {
      id
      name
    }
    prospectTagsGroupByPriority
    leadSourcesGroupByPriority
    prospectTags {
      id
      name
    }
    clientProspectScoreSettingPositionKeywordsByPriority
    updateStatus
    weightPosition
    weightLeadSource
  }
`;
