import React from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router';
import {
  Project,
  useUpdateProjectWithoutReturnObjectMutation,
  useDeleteProjectMutation,
} from 'api';
import {
  DotMenu,
  Download,
  Upload,
  CircleCheck,
  Trash,
  TelLogDownload,
  Transfer,
  Exclusion,
} from 'components/Ui/Icon';
import {PreleadCsvUploadModal} from 'components/Ui';
import {Popover, message} from 'components/antd';
import DownloadModal from './DownloadModal';
import useContract from 'hooks/useContract';
import ActionBoxContent from './ActionBoxContent';
import TelLogDownloadModal from './TelLogDownloadModal';
import useProject from 'hooks/useProject';
import UploadContactsModal from './UploadContactsModal';
import TransferSelect from './TransferSelect';
import useClientUser from 'hooks/useClientUser';
import useProjectPath from 'hooks/useProjectPath';
import ExcludeDuplicateModal from './ExcludeDuplicateModal';

interface Props {
  project: Project;
}

export default ({project}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const {createPath} = useProjectPath();
  const {isAdmin, isMember} = useClientUser();
  const [isUploadModalOpen, setUploadModalOpen] = React.useState(false);
  const [isUploadContactsModalVisible, setUploadContactsModalVisible] =
    React.useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const [isTelLogDownloadModalOpen, setTelLogDownloadModalOpen] =
    React.useState(false);
  const [isExcludeDuplicateModalOpen, setExcludeDuplicateModalOpen] =
    React.useState(false);
  const [isTransferPopoverVisible, setTransferPopoverVisible] =
    React.useState(false);
  const {isPreleadDownloadable, isCsvUploadable, isTelLogDownloadable} =
    useContract();
  const {isOwnProject} = useProject(project.uuid);

  const [update] = useUpdateProjectWithoutReturnObjectMutation({
    refetchQueries: ['projects'],
    onCompleted: () => history.push(createPath(`projects${location.search}`)),
  });

  const [deleteProject] = useDeleteProjectMutation({
    refetchQueries: ['projects'],
    onCompleted: () => {
      history.push(createPath(`projects${location.search}`));
      message.info('営業リストを削除しました');
    },
  });

  const handleDeleteProject = () => {
    deleteProject({
      variables: {
        uuid: project.uuid,
      },
    });
  };

  const makeArchived = React.useCallback(() => {
    update({
      variables: {
        uuid: project.uuid,
        attributes: {
          status: 'archived',
        },
      },
    });
  }, []);

  if (!project) return null;

  return (
    <Container>
      <ActionBox
        onClick={() =>
          history.push(createPath(`projects/${project.uuid}/preleads`))
        }>
        <ActionBoxContent />
      </ActionBox>

      {isOwnProject && isMember && (
        <>
          <Popover
            content={
              <PopoverMenu>
                {isPreleadDownloadable && isAdmin && (
                  <MenuItem onClick={() => setDownloadModalOpen(true)}>
                    <div>
                      <Download />
                      <span>ダウンロード</span>
                    </div>
                  </MenuItem>
                )}

                {isTelLogDownloadable && isAdmin && (
                  <MenuItem onClick={() => setTelLogDownloadModalOpen(true)}>
                    <div>
                      <TelLogDownload />
                      <span>電話ログダウンロード</span>
                    </div>
                  </MenuItem>
                )}

                {project.projectType === 'upload' && isCsvUploadable && (
                  <MenuItem onClick={() => setUploadModalOpen(true)}>
                    <div>
                      <Upload />
                      <span>アップロード</span>
                    </div>
                  </MenuItem>
                )}

                {project.projectType === 'cold_lead' && (
                  <MenuItem onClick={() => setUploadContactsModalVisible(true)}>
                    <div>
                      <Upload />
                      <span>アップロード</span>
                    </div>
                  </MenuItem>
                )}

                {isAdmin && (
                  <Popover
                    content={() => (
                      <TransferSelect
                        project={project}
                        onClose={() => {
                          setTransferPopoverVisible(false);
                          history.push(createPath(`projects`));
                        }}
                      />
                    )}
                    trigger="click"
                    visible={isTransferPopoverVisible}
                    onVisibleChange={(visible) =>
                      setTransferPopoverVisible(visible)
                    }
                    placement="bottomRight">
                    <MenuItem onClick={() => setTransferPopoverVisible(true)}>
                      <div>
                        <Transfer />
                        <span>営業リストを移管</span>
                      </div>
                    </MenuItem>
                  </Popover>
                )}
                <MenuItem onClick={() => setExcludeDuplicateModalOpen(true)}>
                  <div>
                    <Exclusion />
                    <span>重複プレリードを除外</span>
                  </div>
                </MenuItem>
                <CloseButton
                  onClick={() => {
                    if (confirm('営業リストをアーカイブしてよろしいですか？')) {
                      makeArchived();
                    }
                  }}>
                  <div>
                    <CircleCheck />
                    <span>アーカイブ</span>
                  </div>
                </CloseButton>
                <DeleteProjectButton
                  onClick={() => {
                    if (confirm('営業リストを削除してよろしいですか？')) {
                      handleDeleteProject();
                    }
                  }}>
                  <div>
                    <Trash />
                    <span>営業リストを削除する</span>
                  </div>
                </DeleteProjectButton>
              </PopoverMenu>
            }
            placement="bottomLeft"
            trigger="hover">
            <MenuWrapper>
              <DotMenu />
            </MenuWrapper>
          </Popover>

          {isDownloadModalOpen && (
            <DownloadModal
              project={project}
              visible={isDownloadModalOpen}
              onClose={() => setDownloadModalOpen(false)}
            />
          )}

          <PreleadCsvUploadModal
            project={project}
            visible={isUploadModalOpen}
            onClose={() => setUploadModalOpen(false)}
          />

          <UploadContactsModal
            project={project}
            visible={isUploadContactsModalVisible}
            onClose={() => setUploadContactsModalVisible(false)}
          />

          <TelLogDownloadModal
            project={project}
            isOpen={isTelLogDownloadModalOpen}
            setModal={setTelLogDownloadModalOpen}
          />

          <ExcludeDuplicateModal
            project={project}
            isOpen={isExcludeDuplicateModalOpen}
            setModal={setExcludeDuplicateModalOpen}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ActionBox = styled.div<{disabled?: boolean}>`
  margin-right: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 8px 15px;
  width: 150px;
  height: 44px;
  border: 1px solid #e1e6eb;
  box-shadow: ${({disabled}) =>
    disabled ? '' : '1px 1px 3px rgba(0, 0, 0, 0.15)'};
  border-radius: 4px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};

  &:hover {
    opacity: ${({disabled}) => (disabled ? 1.0 : 0.7)};
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const PopoverMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  box-shadow: 0px 0px 4px var(--text-color-3);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  height: 45px;
  cursor: pointer;

  div {
    display: flex;
    width: 100%;
    height: 100%;

    svg {
      margin: auto 13px auto 10px;
    }
    span {
      margin: auto 0;
      font-weight: bold;
      font-size: 12px;
      color: var(--text-color-1);
    }

    &:hover {
      background: #f1f8ff;
      border-radius: 4px;
    }
  }
`;

const CloseButton = styled(MenuItem)`
  border-top: 1px solid #e1e6eb;

  div {
    svg {
      path {
        stroke: #b55d62;
      }
    }
    span {
      color: #b55d62;
    }

    &:hover {
      background: #f5eff0;
    }
  }
`;

const DeleteProjectButton = styled(MenuItem)`
  border-top: 1px solid #e1e6eb;

  div {
    svg {
      path {
        stroke: var(--text-color-3);
      }
    }
    span {
      color: var(--text-color-1);
    }
  }
`;
