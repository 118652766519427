import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {useQueryParam, NumberParam} from 'use-query-params';
import {useCurrentUserQuery, useMyTeamsQuery} from 'api';
import Pagination from 'components/Ui/Pagination';
import {Plus} from 'components/Ui/Icon';
import {sideMenuWidth} from 'helpers/sideMenu';
import Table from './Table';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const history = useHistory();
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  if (!currentUser) return null;
  const {isAdmin} = useClientUser();

  const {data: {myTeams: {pagination = {}} = {}} = {}} = useMyTeamsQuery({
    variables: {page: page || 1},
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <Content>
        <Header>
          <Title>チーム一覧</Title>
          {isAdmin && (
            <CreateTeamButton
              type="button"
              onClick={() => history.push('/settings/teams/new')}>
              <Plus color="#fff" />
              <span>新規作成</span>
            </CreateTeamButton>
          )}
        </Header>
        <Body>
          <Table />
        </Body>
      </Content>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={50}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  overflow-x: hidden;
`;

const Content = styled.div`
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  padding: 45px 70px 90px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 43px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.15px;

  color: var(--text-color-1);
`;

const Body = styled.div`
  margin-top: 25px;

  table {
    width: 100%;

    thead {
      background: #f3f5f7;
      border-bottom: 1px solid #e1e6eb;

      th {
        padding: 12px 13px;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0.15px;

        color: var(--text-color-1);
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e1e6eb;
      }

      td {
        padding: 16px 13px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.15px;

        a {
          color: var(--text-color-0);
        }
      }
    }
  }
`;

const CreateTeamButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 39px;
  padding: 6px 13px;
  border: none;
  outline: 0;
  background: var(--primary);
  border-radius: 4px;
  svg {
    margin-right: 5px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }
`;
