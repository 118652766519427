import React from 'react';
import {
  SalesforceContact,
  SalesforceContactField,
  useUpdateSalesforceContactFieldCheckboxValueMutation
} from 'api';

interface Props {
  salesforceContact: SalesforceContact;
  field: SalesforceContactField;
}

const CheckboxFieldValue = ({ salesforceContact, field }: Props) => {
  const value = salesforceContact.addition[`${field.fieldName}`];
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateFieldValue] =
    useUpdateSalesforceContactFieldCheckboxValueMutation({
      onCompleted: (data) => {
        setErrorMessage(data.updateSalesforceContactFieldCheckboxValue.error);
      },
    });

  return (
    <>
      <input
        className="cursor-pointer"
        type="checkbox"
        name={field.label}
        checked={value}
        disabled={!field.fieldName.endsWith('__c')}
        onChange={(e) =>
          updateFieldValue({
            variables: {
              id: salesforceContact.id,
              attributes: {
                fieldName: field.fieldName,
                value: e.target.checked,
              },
            },
          })
        }
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};

export default CheckboxFieldValue;
