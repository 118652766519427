import React from 'react';

const Icon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M8.5 18.3337H15.0007C15.9173 18.3337 16.6673 17.5837 16.6673 16.667V8.16699C16.6673 7.75033 16.5006 7.33366 16.1673 7.00033L11.334 2.16699C11.0007 1.83366 10.584 1.66699 10.1673 1.66699H5.00065C4.08398 1.66699 3.33398 2.41699 3.33398 3.33366V11"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.834 2.08398V7.50065H15.834"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.91797 15.1663H7.16797M5.5013 13.583V16.833V13.583Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50065 19.3333C7.80184 19.3333 9.66732 17.4679 9.66732 15.1667C9.66732 12.8655 7.80184 11 5.50065 11C3.19946 11 1.33398 12.8655 1.33398 15.1667C1.33398 17.4679 3.19946 19.3333 5.50065 19.3333Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
