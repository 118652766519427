import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import {useContentTelScriptQuery} from 'api';
import InformationPanel from './Information';
import {Information} from 'components/Ui/Icon';

export default () => {
  const {id} = useParams<{id: string}>();

  const [panel, setPanel] = useQueryParam(
    'panel',
    withDefault(StringParam, 'information'),
  );

  const {data: {content = {}} = {}} = useContentTelScriptQuery({
    variables: {
      id,
    },
  });

  return (
    <Container>
      <Main>
        {panel === 'information' && <InformationPanel content={content} />}
      </Main>
      <Menu>
        <MenuButton
          active={panel === 'information'}
          onClick={() => setPanel('information')}>
          <Information />
        </MenuButton>
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-left: solid 1px var(--border-color);
`;

const Main = styled.div`
  width: 300px;
  padding: 1rem;
`;

const Menu = styled.div`
  width: 50px;
  border-left: solid 1px var(--border-color);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuButton = styled.div<{active?: boolean}>`
  margin: 10px 0;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  color: ${({active}) => (active ? '#2D9CDB' : 'var(--text-color-3)')};
  background: ${({active}) => (active ? '#deeffe' : 'transparent')};

  svg {
    path {
      stroke: ${({active}) => (active ? '#2D9CDB' : 'var(--text-color-3)')};
    }
  }
`;
