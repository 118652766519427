import React from 'react';
import styled from 'styled-components';
import {
  MailCampaignBlock,
  MailCampaignBlockHeader,
  MailCampaignBlockHeaderAttributes,
  useUpdateMailCampaignBlockHeaderMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input} from 'components/antd';
import ColorInput from './ColorInput';
import PaddingInput from './PaddingInput';
import ImageInput from './ImageInput';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockHeader;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {
    image,
    imageAlt,
    imageLink,
    imageScale,
    bodyColor,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = block.blockable;

  const [update] = useUpdateMailCampaignBlockHeaderMutation();

  const formik = useFormik({
    initialValues: {
      imageAlt,
      imageLink,
      imageScale,
      bodyColor,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    },
    validationSchema: Yup.object().shape({
      imageAlt: Yup.string().nullable(),
      imageLink: Yup.string().url().nullable(),
      imageScale: Yup.number(),
      bodyColor: Yup.string().nullable(),
      paddingTop: Yup.number(),
      paddingBottom: Yup.number(),
      paddingLeft: Yup.number(),
      paddingRight: Yup.number(),
    }),
    onSubmit: (values) => {
      update({
        variables: {
          uuid: block.uuid,
          attributes: values,
        },
      });
    },
  });

  const debouncedValue = useDebounce<MailCampaignBlockHeaderAttributes>(
    formik.values,
    500,
  );

  React.useEffect(() => {
    formik.submitForm();
  }, [debouncedValue]);

  const uploadFile = React.useCallback(
    (file: File, width: number, height: number) => {
      update({
        variables: {
          uuid: block.uuid,
          attributes: {image: file, imageWidth: width, imageHeight: height},
        },
      });
    },
    [],
  );

  return (
    <Container>
      <h2>ヘッダーを編集</h2>
      <Form layout="vertical">
        <Form.Item label="画像">
          <ImageInput value={image} onSelect={uploadFile} />
        </Form.Item>
        <Form.Item>
          <label>
            画像サイズ <span>{formik.values.imageScale * 100}%</span>
          </label>
          <Input
            type="range"
            min={1}
            max={100}
            value={formik.values.imageScale * 100}
            disabled={!image}
            step={1}
            onChange={(e) =>
              formik.setFieldValue('imageScale', e.target.valueAsNumber / 100)
            }
            style={{padding: '0'}}
          />
        </Form.Item>
        <Form.Item label="Altテキスト">
          <Input {...formik.getFieldProps('imageAlt')} />
        </Form.Item>
        <Form.Item label="リンク">
          <Input
            placeholder="例：https://leadpad.io/"
            {...formik.getFieldProps('imageLink')}
          />
        </Form.Item>
        <Form.Item label="余白">
          <PaddingInput
            values={{
              paddingTop: formik.values.paddingTop,
              paddingBottom: formik.values.paddingBottom,
              paddingLeft: formik.values.paddingLeft,
              paddingRight: formik.values.paddingRight,
            }}
            onChange={(values) => {
              formik.setValues({...formik.values, ...values});
            }}
          />
        </Form.Item>
        <Form.Item label="背景色">
          <ColorInput
            color={formik.values.bodyColor}
            onChange={(color) => formik.setFieldValue('bodyColor', color)}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  label {
    font-size: 14px;
    color: var(--text-color-2);
    font-weight: 500;

    span {
      float: right;
    }
  }
`;
