import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useTable} from 'react-table';

export default ({data}: any) => {
  const columns: any = useMemo(
    () => [
      {
        accessor: 'name',
        Cell: ({value}: {value: string}) => {
          return <Name>{value}</Name>;
        },
      },
      {
        Header: (
          <Header>
            <div>コール数</div>
            <div style={{display: 'flex'}}>
              <span
                style={{margin: 'auto', fontWeight: 500, fontSize: ' 12px'}}>
                件数
              </span>
            </div>
          </Header>
        ),
        accessor: 'callCount',
        Cell: ({value}: {value: number}) => {
          return (
            <div style={{textAlign: 'right', paddingRight: '10px'}}>
              {value}
            </div>
          );
        },
      },
      {
        Header: (
          <Header>
            <div>キーマン接触数</div>
            <div>
              <div>
                <span>件数</span>
              </div>
              <div>
                <span>対コール率</span>
              </div>
            </div>
          </Header>
        ),
        accessor: 'contactCount',
        Cell: ({row}: any) => {
          return (
            <Cell>
              <div>{row.original.contactCount}</div>
              <div>{row.original.contactCallRate} %</div>
            </Cell>
          );
        },
      },
      {
        Header: (
          <Header>
            <div>資料送付</div>
            <div>
              <div>
                <span>件数</span>
              </div>
              <div>
                <span>対コール率</span>
              </div>
            </div>
          </Header>
        ),
        accessor: 'documentCount',
        Cell: ({row}: any) => {
          return (
            <Cell>
              <div>{row.original.documentCount}</div>
              <div>{row.original.documentCallRate} %</div>
            </Cell>
          );
        },
      },
      {
        Header: (
          <Header>
            <div>アポ獲得</div>
            <div>
              <div>
                <span>件数</span>
              </div>
              <div>
                <span>対コール率</span>
              </div>
            </div>
          </Header>
        ),
        accessor: 'appointCount',
        Cell: ({row}: any) => {
          return (
            <Cell>
              <div>{row.original.appointCount}</div>
              <div>{row.original.appointCallRate} %</div>
            </Cell>
          );
        },
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <TableWrapper>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <th {...column.getHeaderProps()} key={j}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  border: 1px solid #e1e6eb;
  max-height: 570px;
  width: 885px;
  overflow-y: scroll;
  position: relative;
`;

const Table = styled.table`
  thead {
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 3;

    tr {
      border-bottom: 1px solid #e1e6eb;
      th {
        border-right: 1px solid #e1e6eb;
        height: 45px;
        width: 195px;
        font-weight: bold;
        font-size: 13px;

        &:first-child {
          width: 200px;
          border-right: 1px solid #e1e6eb;
          background: #ffffff;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3;
        }
        &:nth-child(2) {
          width: 100px;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        border-bottom: 1px solid #e1e6eb;
        background: #ffffff;
        position: sticky;
        top: 45px;
        z-index: 2;

        td:first-child {
          background: #ffffff;
          position: sticky;
          top: 45px;
          z-index: 2;
        }
      }

      td {
        border-right: 1px solid #e1e6eb;
        border-top: 1px solid #e1e6eb;
        height: 45px;
        font-weight: 500;
        font-size: 12px;
        color: var(--text-color-1);

        &:first-child {
          background: #ffffff;
          position: sticky;
          left: 0;
          z-index: 1;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
`;

const Header = styled.div`
  height: 90px;

  > div:first-child {
    height: 45px;
    border-bottom: 1px solid #e1e6eb;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div:nth-child(2) {
    height: 45px;
    display: flex;

    > div {
      width: 95px;
      display: flex;
      font-weight: 500;
      font-size: 12px;

      &:first-child {
        border-right: 1px solid #e1e6eb;
      }
    }
  }

  span {
    margin: auto;
  }
`;

const Cell = styled.div`
  display: flex;

  > div {
    width: 95px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;

    &:first-child {
      border-right: 1px solid #e1e6eb;
    }
  }
`;

const Name = styled.div`
  padding-left: 16px;
  width: calc(200px - 16px);
  font-weight: 500;
  font-size: 13px;
`;
