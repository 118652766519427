import React, {useState} from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {Hubspot} from 'components/Ui/Icon';
import {Button, Form, Input} from 'components/antd';
import {useFormik} from 'formik';
import {
  useCurrentClientQuery,
  useTestHubspotIntegrationMutation,
  useUpdateHubspotSettingMutation,
} from 'api';
import {message} from 'components/antd';
import CircleCloseIcon from './CircleCloseIcon';

export default () => {
  const [testing, setTesting] = useState(false);
  const [testSuccess, setTestSuccess] = useState(false);
  const [alreadyTested, setAlreadyTested] = useState(false);

  const {data: {currentClient: {hubspotSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const [updateHubspotSetting, {loading}] = useUpdateHubspotSettingMutation({
    onCompleted: () => {
      if (testing) {
        testIntegration({});
      } else {
        message.success('更新しました');
      }
    },
  });

  const [testIntegration, {loading: testLoading}] =
    useTestHubspotIntegrationMutation({
      onCompleted: ({testHubspotIntegration: {success}}) => {
        setTesting(false);
        setAlreadyTested(true);
        setTestSuccess(success);
      },
    });

  const clickTestButton = () => {
    setTesting(true);
    handleSubmit();
  };

  const changeHubspotActiveButton = () => {
    values.isActive = !values.isActive;
    updateHubspotSetting({
      variables: {
        attributes: {
          ...values,
        },
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pipeline: hubspotSetting?.pipeline || '',
      stage: hubspotSetting?.stage || '',
      isActive: hubspotSetting?.isActive || false,
    },
    onSubmit: (values) => {
      updateHubspotSetting({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
    },
  });

  const {values, isValid, handleChange, handleBlur, handleSubmit} = formik;

  return (
    <Body>
      <Menu />
      <Content>
        <TitleSection>
          <Hubspot />
          <TitleWrapper>
            <Title>HubSpot</Title>
            <TitleNote>HubSpot連携の設定を行います。</TitleNote>
          </TitleWrapper>
          {values.isActive ? (
            <UnavailableButton onClick={changeHubspotActiveButton}>
              <CircleCloseIcon />
              無効にする
            </UnavailableButton>
          ) : (
            <AvailableButton href="/integrations/hubspot/redirect">
              連携する
            </AvailableButton>
          )}
        </TitleSection>

        <HeadSection>
          <span>連携設定</span>
        </HeadSection>

        <FormSection>
          <FormNote>HubSpot連携に必要な各種項目を設定してください。</FormNote>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 12}}
            onFinish={handleSubmit}>
            <Form.Item label="パイプライン名">
              <Input
                name="pipeline"
                value={values.pipeline}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item label="ステージ名">
              <Input
                name="stage"
                value={values.stage}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <ButtonSection>
              <SaveButton
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={!formik.dirty || !isValid || loading}>
                保存
              </SaveButton>
              <TestButton
                loading={loading || testLoading}
                disabled={
                  !isValid ||
                  loading ||
                  testLoading ||
                  !hubspotSetting?.isActive
                }
                onClick={clickTestButton}>
                テスト送信
              </TestButton>
              {alreadyTested &&
                (testSuccess ? (
                  <TestSuccess>success!</TestSuccess>
                ) : (
                  <TestError>error</TestError>
                ))}
            </ButtonSection>
          </Form>
        </FormSection>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 45px 45px;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

const TitleNote = styled.div`
  margin-top: 5px;
  font-size: 14px;
`;

const AvailableButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #169e34;
  color: #fff;
  height: 39px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin: 0 0 0 auto;
  cursor: pointer;
`;

const UnavailableButton = styled(Button)`
  display: flex;
  align-items: center;
  border: none;
  margin: 0 0 0 auto;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #69717a;
  }
`;

const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 13px 20px;
  margin-top: 14px;

  > span {
    font-weight: bold;
  }

  > button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #69717a;
    font-size: 14px;
    font-weight: bold;

    svg {
      margin-right: 6px;
    }
  }
`;

const FormSection = styled.div`
  margin-top: 25px;
  padding-bottom: 30px;

  form {
    margin-top: 30px;
    .ant-form-item {
      margin: 0;
      display: block;
      height: 95px;

      .ant-form-item-label {
        display: inline;
        label {
          height: 26px;
          font-weight: bold;
          font-size: 14px;
          color: var(--text-color-3);
        }
      }
      input {
        width: 600px;
        height: 48px;
        background: #f3f5f7;
        border: none;
      }
    }
  }
`;

const FormNote = styled.div`
  font-size: 14px;
`;

const ButtonSection = styled.div`
  margin-top: 10px;
  display: flex;
`;

const DefaultButton = styled(Button)`
  margin-right: 20px;
  width: 187px;
  height: 48px;
  border: none;
  border-radius: 4px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

const SaveButton = styled(DefaultButton)`
  background: var(--primary);
  &:hover {
    background: #65aae8;
  }
`;

const TestButton = styled(DefaultButton)`
  border: 2px solid var(--primary);
  span {
    color: var(--primary);
  }
  &:hover {
    border-color: #65aae8;
  }
`;

const TestSuccess = styled.div`
  margin: auto 0;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #108429;
`;

const TestError = styled(TestSuccess)`
  color: #c90e19;
`;
