import React from 'react';
import Position from './Position';
import LeadSource from './LeadSource';
import UpdateScoreButton from './UpdateScoreButton';
import LostSeason from './LostSeason';
import InflowSeason from './InflowSeason';
import ProspectTag from './ProspectTag';

export default () => {
  return (
    <div className="flex flex-col gap-4">
      <UpdateScoreButton />
      <Position />
      <LeadSource />
      <ProspectTag />
      <LostSeason />
      <InflowSeason />
    </div>
  );
};
