import React from 'react';
import moment from 'moment';
import {Approach} from 'api';

interface Props {
  approach: Approach;
}

const Status = ({approach}: Props) => {
  const baseClassName =
    'flex justify-center items-center text-center rounded-sm h-5 text-xs px-2';

  if (approach.status === 'open') {
    const isExpired = moment().isAfter(moment(approach.dueDate), 'day');
    if (isExpired) {
      return (
        <div className={`${baseClassName} text-[#EB5757] bg-[#FDECEC]`}>
          期限切れ
        </div>
      );
    } else {
      return (
        <div className={`${baseClassName} text-[#0DC8F1] bg-[#DCF9FF]`}>
          開始
        </div>
      );
    }
  }

  if (approach.status === 'completed') {
    return (
      <div className={`${baseClassName} text-[#4FCE82] bg-[#DDF4E6]`}>完了</div>
    );
  }

  if (approach.status === 'canceled') {
    return (
      <div className={`${baseClassName} text-foreground-light bg-[#EAEEF2]`}>
        キャンセル
      </div>
    );
  }

  return null;
};

export default Status;
