import gql from 'graphql-tag';

export default gql`
  fragment prospectPoolScoreFragment on ProspectPoolScore {
    id
    positionScore
    leadSourceScore
    totalScore
    latestInflowDateScore
    scoreRank
    tagScore
  }
`;
