import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16675 14.1666H10.8334M1.66675 5.83331H18.3334H1.66675ZM5.00008 9.99998H15.0001H5.00008Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
