import React from 'react';
import {
  useProjectWithSearchConditionQuery,
  useWebsitesQuery,
  Website,
} from 'api';
import {useParams} from 'react-router';
import {Pc} from 'components/Ui/Icon';
import {
  Container,
  Category,
  CategoryTitle,
  TagBox,
  TagItem,
  ChildCategory,
  ChildCategoryTitleA,
} from './styles';
import styled from 'styled-components';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'network-only',
  });

  const {
    projectSearchCondition: {
      websiteId,
      websiteIds = [],
      pageKeywords = [],
      pageNegativeKeywords = [],
      searchWord,
    } = {},
  } = project;

  const {data: {websites: allWebsites = []} = {}} = useWebsitesQuery();

  const website = React.useMemo(
    () => allWebsites?.find((website) => websiteId === website?.id),
    [websiteId, allWebsites],
  );

  const websites = React.useMemo(
    () =>
      websiteIds
        ?.map((websiteId) =>
          allWebsites?.find((website) => websiteId === website?.id),
        )
        ?.filter(Boolean),
    [websiteIds, allWebsites],
  );

  const websitePageTypes = React.useMemo(
    () => [
      ...new Set(websites?.map((website: Website) => website.pageTypeText)),
    ],
    [websites],
  );

  const Tags = (pageType: string) => {
    const categories = websites.filter(
      (website) => website.pageTypeText === pageType,
    );
    return categories.map((category) => (
      <TagItem key={category.id}>{category.name}</TagItem>
    ));
  };

  return (
    <Container>
      {(website ||
        websites?.length > 0 ||
        pageKeywords?.length > 0 ||
        pageNegativeKeywords?.length > 0) && (
        <Category>
          <CategoryTitle>
            <Pc />
            メディア
          </CategoryTitle>

          {searchWord && (
            <Item>
              <Name>検索キーワード：</Name>
              <Keyword>{searchWord}</Keyword>
            </Item>
          )}

          {pageKeywords?.length > 0 && (
            <Item>
              <Name>検索キーワード：</Name>
              {pageKeywords?.map((keyword, index) => (
                <Keyword key={index}>{keyword}</Keyword>
              ))}
            </Item>
          )}

          {pageNegativeKeywords?.length > 0 && (
            <Item>
              <Name>除外キーワード：</Name>
              {pageNegativeKeywords?.map((keyword, index) => (
                <Keyword key={index}>{keyword}</Keyword>
              ))}
            </Item>
          )}

          {websitePageTypes?.length > 0 && <MarginTop />}

          {websitePageTypes?.map((pageType) => (
            <ChildCategory key={pageType}>
              <ChildCategoryTitleA>{pageType}</ChildCategoryTitleA>
              <TagBox>{Tags(pageType)}</TagBox>
            </ChildCategory>
          ))}

          {website && (
            <ChildCategory key={website.pageTypeText}>
              <ChildCategoryTitleA>{website.pageTypeText}</ChildCategoryTitleA>
              <TagBox>
                <TagItem key={website.id}>{website.name}</TagItem>
              </TagBox>
            </ChildCategory>
          )}
        </Category>
      )}
    </Container>
  );
};

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
`;

const Name = styled.div`
  color: var(--text-color-2);
  font-size: 12px;
  letter-spacing: 0.15px;
  height: 21px;
  line-height: 21px;
`;

const Keyword = styled.div`
  display: inline-block;
  padding: 5px 10px;
  margin-left: 3px;
  margin-bottom: 3px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  color: var(--text-color-2);
`;

const MarginTop = styled.div`
  margin-top: 20px;
`;
