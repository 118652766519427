import React from 'react';
import styled from 'styled-components';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import {Content} from 'api';
import UrlsPanel from './Urls';
import VersionsPanel from './Versions';
import MemoPanel from './Memo';
import {Link, List} from 'components/Ui/Icon';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const [panel, setPanel] = useQueryParam(
    'panel',
    withDefault(StringParam, 'url'),
  );

  return (
    <Container>
      <Main>
        {panel === 'url' && <UrlsPanel content={content} />}
        {panel === 'memo' && <MemoPanel content={content} />}
        {panel === 'version' && <VersionsPanel content={content} />}
      </Main>
      <Menu>
        <MenuButton active={panel === 'url'} onClick={() => setPanel('url')}>
          <Link />
        </MenuButton>
        <MenuButton active={panel === 'memo'} onClick={() => setPanel('memo')}>
          <List />
        </MenuButton>
        <MenuButton
          active={panel === 'version'}
          onClick={() => setPanel('version')}>
          Ver
        </MenuButton>
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-left: solid 1px var(--border-color);
`;

const Main = styled.div`
  width: 300px;
  padding: 1rem;
`;

const Menu = styled.div`
  width: 50px;
  border-left: solid 1px var(--border-color);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuButton = styled.div<{active?: boolean}>`
  margin: 10px 0;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  color: ${({active}) => (active ? '#2D9CDB' : 'var(--text-color-3)')};
  background: ${({active}) => (active ? '#deeffe' : 'transparent')};

  svg {
    path {
      stroke: ${({active}) => (active ? '#2D9CDB' : 'var(--text-color-3)')};
    }
  }
`;
