import {useState} from 'react';
import moment from 'moment';
import {
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
  NumericObjectParam,
  ObjectParam,
  encodeDelimitedArray,
  decodeDelimitedArray,
} from 'use-query-params';
import {useClientPreleadCustomizeItemsQuery} from 'api';

// response_status用のカスタムパラメータ
// ['a', 'b'] <=> /?=a,b
const DelimitedCommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

const PAGE_SIZE = 50;

const defaultSearchParams = {
  page: NumberParam,
  mailStatuses: DelimitedArrayParam,
  mailCounts: DelimitedArrayParam,
  mailTerm: ObjectParam,
  statuses: DelimitedArrayParam,
  preleadStatusIds: withDefault(DelimitedArrayParam, []),
  clickCounts: DelimitedArrayParam,
  clickTerm: ObjectParam,
  clickedCampaignIds: DelimitedArrayParam,
  clickedCampaignUrlIds: DelimitedArrayParam,
  responseStatuses: DelimitedCommaArrayParam,
  clickedMailFormVersionUrlIds: DelimitedArrayParam,
  telStatusIds: withDefault(DelimitedArrayParam, []),
  mailCampaignIds: withDefault(DelimitedArrayParam, []),
  latestTelStatusIds: withDefault(DelimitedArrayParam, []),
  telCounts: DelimitedArrayParam,
  telTerm: ObjectParam,
  latestTelTerm: ObjectParam,
  mailCampaignOpenCounts: DelimitedArrayParam,
  mailCampaignClickCounts: DelimitedArrayParam,
  pressReleaseSince: StringParam,
  tagIds: withDefault(DelimitedArrayParam, []),
  searchWord: StringParam,
  sortCategory: StringParam,
  sortOrder: StringParam,
  officesNumber: NumericObjectParam,
  listingMarkets: withDefault(DelimitedArrayParam, []),
  employeeNumber: NumericObjectParam,
  accountClosingMonths: withDefault(DelimitedArrayParam, []),
  establishedDate: ObjectParam,
  capitalFund: NumericObjectParam,
  categoryIds: ObjectParam,
  commentCounts: DelimitedArrayParam,
  isDuplicate: NumberParam,
  remind: ObjectParam,
  assigneeIds: DelimitedArrayParam,
  serialNumber: NumericObjectParam,
  prefectureIds: DelimitedArrayParam,
  mailCampaignSendsCounts: DelimitedArrayParam,
  latestTelUserIds: withDefault(DelimitedArrayParam, []),
  isUnsubscribed: NumberParam,
  isMultiple: NumberParam,
  jobListingsSince: StringParam,
  hasKeyPerson: NumberParam,
  accountStatusIds: withDefault(DelimitedArrayParam, []),
};

const convertDate = (value: string) => {
  if (value === '1d') {
    return moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  if (value === '1w') {
    return moment().subtract(1, 'week').format('YYYY-MM-DD');
  }

  if (value === '2w') {
    return moment().subtract(2, 'week').format('YYYY-MM-DD');
  }

  if (value === '1m') {
    return moment().subtract(1, 'month').format('YYYY-MM-DD');
  }

  if (value === '3m') {
    return moment().subtract(3, 'month').format('YYYY-MM-DD');
  }

  return null;
};

const convertCustomizeItems = (params: any) =>
  Object.keys(params).reduce((prev, key) => {
    if (key.startsWith('c.')) {
      const value = params[key];
      const id = key.replace('c.', '');

      if (!value || value.length === 0) {
        return prev;
      }

      if (Array.isArray(value)) {
        return [
          ...prev,
          {
            id,
            values: value,
          },
        ];
      } else {
        return [
          ...prev,
          {
            id,
            ...params[key],
          },
        ];
      }
    }
    return prev;
  }, []);

const convertParams = (params: any) => {
  params.pressReleaseSince = convertDate(params.pressReleaseSince);
  params.jobListingsSince = convertDate(params.jobListingsSince);

  // customize_items
  params.customizeItems = convertCustomizeItems(params);

  // remove unused values
  return Object.keys(params).reduce((prev, key) => {
    if (key.startsWith('c.')) {
      return prev;
    }
    return {
      ...prev,
      [key]: params[key],
    };
  }, {});
};

const useSearchParams = () => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  useClientPreleadCustomizeItemsQuery({
    onCompleted: ({clientPreleadCustomizeItems = []}) => {
      const customizeItemsParams = clientPreleadCustomizeItems.reduce(
        (prev, curr) => {
          if (curr.dataType === 'select') {
            prev[`c.${curr.id}`] = DelimitedArrayParam;
          } else {
            prev[`c.${curr.id}`] = ObjectParam;
          }
          return prev;
        },
        {} as any,
      );
      setSearchParams({...defaultSearchParams, ...customizeItemsParams});
    },
  });

  const [query, setQuery] = useQueryParams(searchParams);
  const {page, ...restQuery} = query;

  const params = convertParams(restQuery);
  const setPage = (page: number) => setQuery({page});

  return {
    query,
    setQuery,
    searchParams: params,
    page,
    perPage: PAGE_SIZE,
    setPage,
  };
};

export default useSearchParams;
