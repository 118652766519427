import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="27"
    height="17"
    viewBox="0 0 27 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66667 14L12.3333 9.33333M7.66667 2V14V2ZM7.66667 14L3 9.33333L7.66667 14Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9999 2L23.6666 6.66667M18.9999 14V2V14ZM18.9999 2L14.3333 6.66667L18.9999 2Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
