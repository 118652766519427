import React from 'react';
import styled from 'styled-components';
import {Project} from 'api';
import {Speaker, OpenMail, SendMail, PersonPhoto} from 'components/Ui/Icon';
import {Row} from 'react-table';
import {Tooltip} from 'components/antd';

interface Props {
  value: number;
  row: Row<Project>;
}

export default ({row}: Props) => {
  const project = row.original;
  const color = 'rgba(0, 0, 0, 0.8)';

  return (
    <Container>
      <div>
        <Tooltip placement="right" color={color} title="[クリック]一週間以内">
          <OpenMail />
          <Count>{project.numberOfClicksInAWeek?.toLocaleString()}</Count>
        </Tooltip>
      </div>
      <div>
        <Tooltip placement="right" color={color} title="[メール]一週間以内">
          <SendMail />
          <Count>{project.numberOfMailsInAWeek?.toLocaleString()}</Count>
        </Tooltip>
      </div>
      <div>
        <Tooltip
          placement="right"
          color={color}
          title="[プレリリース公開]一週間以内・前日">
          <Speaker />
          <Count>
            {project.numberOfPressReleasesInAWeek?.toLocaleString()}
          </Count>
        </Tooltip>
      </div>
      <div>
        <Tooltip placement="right" color={color} title="[求人情報]一ヶ月以内">
          <PersonPhoto />
          <Count>{project.numberOfJobListingsInAMonth?.toLocaleString()}</Count>
        </Tooltip>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 70px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 7px;
  }
`;

const Count = styled.span`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;
