import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Switch, Button} from 'components/antd';
import {Content} from 'api';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import byteToSize from 'helpers/byteToSize';
import {useUpdateContentDocumentMutation} from 'api';
import {World} from 'components/Ui/Icon';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const [updateDocument] = useUpdateContentDocumentMutation();

  const onChange = React.useCallback(
    () =>
      updateDocument({
        variables: {
          id: content.uuid,
          attributes: {
            status: content.document.status === 'public' ? 'private' : 'public',
          },
        },
      }),
    [content],
  );

  return (
    <Container>
      <h4>コンテンツ情報</h4>
      <PublicationSetting>
        <World />
        <span>公開</span>
        <Switch
          checked={content.document.status === 'public'}
          onChange={onChange}
        />
      </PublicationSetting>
      <DL>
        <dt>URL</dt>
        <dd>
          <span>{content.document.url?.url} </span>
          <CopyToClipboard text={content.document.url?.url}>
            <Button size="small">コピー</Button>
          </CopyToClipboard>
        </dd>
        <dt>公開日</dt>
        <dd>
          {content.document.publishedAt
            ? moment(content.document.publishedAt).format('YYYY/MM/DD HH:mm')
            : '--'}
        </dd>
        <dt>作成日</dt>
        <dd>{moment(content.document.createdAt).format('YYYY/MM/DD HH:mm')}</dd>
        <dt>作成者</dt>
        <dd>
          {content.document.user?.lastName} {content.document.user?.firstName}
        </dd>
        <dt>ファイルサイズ</dt>
        <dd>{byteToSize(content.document.fileSize)}</dd>
      </DL>
    </Container>
  );
};

const Container = styled.div`
  h4 {
    font-size: 13px;
    font-weight: bold;
  }
`;

const DL = styled.dl`
  font-size: 13px;

  dt {
    color: var(--text-color-3)
  }

  dd {
    margin-bottom 1rem;
    display: flex;
    align-items: center;

    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const PublicationSetting = styled.div`
  display: flex;
  align-items: center;
  border-top: solid 1px #e1e6eb;
  border-bottom: solid 1px #e1e6eb;
  height: 45px;
  margin 1rem 0;

  svg {
    margin-right: 8px;
  }

  span {
    flex: 1;
    font-size: 13px;
  }
`;
