import React from 'react';
import {usePoolQuery, useUpdateSalesforceTaskStatusMutation} from 'api';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';

type Status = {
  name: string;
  value?: string;
};

export default () => {
  const {data: {pool: {salesforceTaskStatuses = []} = {}} = {}} =
    usePoolQuery();
  const [update] = useUpdateSalesforceTaskStatusMutation({});

  const statuses: Status[] = [
    {name: '', value: null},
    {name: '未実施', value: 'not_started'},
    {name: '開始', value: 'in_progress'},
    {name: '完了', value: 'completed'},
    {name: '期限切れ', value: 'expired'},
    {name: '保留', value: 'icebox'},
  ];

  const selectedItem = (value: string) => {
    return statuses.find((status) => status.value === value);
  };

  return (
    <div>
      <div className="flex border-b border-b-[#F3F5F7]">
        <div className="text-foreground-light w-96 px-6 text-sm pb-2">Salesforce</div>
        <div className="text-foreground-light w-96 px-6 text-sm pb-2">LEADPAD</div>
      </div>
      {salesforceTaskStatuses.map((status, index) => {
        return (
          <div className="flex border-b border-b-[#F3F5F7] py-4" key={index}>
            <div className="text-foreground-dark w-96 px-6 text-base">
              {status.masterLabel}
            </div>
            <div className="text-foreground-dark w-96 px-6 text-base">
              <Listbox
                value={status.taskStatus}
                onChange={(value: any) => {
                  update({
                    variables: {
                      id: status.id,
                      taskStatus: value,
                    },
                  });
                }}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm min-h-[37.59px]">
                    <span className="block truncate">
                      {selectedItem(status.taskStatus)?.name || ''}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 ">
                      {statuses.map((status, statusIdx) => (
                        <Listbox.Option
                          key={statusIdx}
                          className={({active}) =>
                            `min-h-[37.59px]  relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-gray-100' : 'bg-white'
                            }`
                          }
                          value={status.value}>
                          {({selected}) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}>
                                {status.name}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        );
      })}
    </div>
  );
};
