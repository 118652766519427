import styled from 'styled-components';
import ReactQuill from 'react-quill';

const QuillWrapper = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 4px 4px 4px 4px;

  .ql-container {
    border: none;
  }

  > *:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 10px;

    > * {
      margin: 0 5px;
    }
  }
`;

const Quill = styled(ReactQuill)`
  .ql-toolbar {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--border-color);
    background: #ffffff;
    button {
      margin: auto 0;
    }
  }
  .ql-container {
    min-height: 100px;
    background: #fff;
    .ql-editor {
      min-height: 100px;
      &::before {
        color: var(--text-color-3);
      }
    }
  }
`;

export {QuillWrapper, Quill};
