import React, {useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {CommentBadge, Edit, Trash} from 'components/Ui/Icon';
import {Activity, MailCommentActivity} from 'api';
import {Button} from 'components/antd';
import {Quill, QuillWrapper} from 'components/Ui';
import {
  useUpdateMailCommentActivityMutation,
  useDestroyActivityMutation,
} from 'api';
import useProjectName from './useProjectName';
import {useLocation} from 'react-router';
import UserName from './UserName';
import linkifyHtml from 'linkify-html';

interface MailComment extends Activity {
  resource: MailCommentActivity;
}

interface Props {
  activity: MailComment;
}

const MailCommentActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const [onEdit, setOnEdit] = useState(false);

  const [comment, setComment] = React.useState(activity.resource.body);
  const [bodyPlainText, setBodyPlainText] = React.useState(
    activity.resource.bodyPlainText,
  );

  const [updateMailCommentActivity] = useUpdateMailCommentActivityMutation({
    onCompleted: () => {
      setOnEdit(false);
    },
  });
  const updateMailComment = () => {
    updateMailCommentActivity({
      variables: {
        resourceId: activity.resourceId,
        attributes: {body: comment, bodyPlainText: bodyPlainText},
      },
    });
  };

  const [destroyComment] = useDestroyActivityMutation();

  const [destroyCommentMaster] = useDestroyActivityMutation({
    refetchQueries: ['preleads'],
  });

  const location = useLocation();

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <Comment>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <CommentBadge />
        </BadgeWrapper>
        <Body>
          <UserName activity={activity} />
          がコメントしました。
        </Body>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
      </Summary>
      {onEdit ? (
        <EditorWrapper>
          <QuillWrapper>
            <Quill
              placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
              theme="snow"
              value={comment}
              onChange={(content, delta, source, editor) => {
                setComment(content);
                setBodyPlainText(editor.getText());
              }}
              modules={{
                toolbar: [
                  [{header: [1, 2, 3, false]}],
                  ['bold', 'italic', 'underline'],
                  ['code-block'],
                  ['link'],
                ],
              }}
              formats={[
                'header',
                'list',
                'bold',
                'italic',
                'underline',
                'strike',
                'color',
                'background',
                'code-block',
                'link',
              ]}
              bounds={`#quill-container-${activity.uuid}`}
            />
            <div id={`quill-container-${activity.uuid}`} />
            <MenuButtonWrapper>
              <Button
                disabled={!comment.replace(/<\/?[^>]+(>|$)/g, '') && !status}
                onClick={updateMailComment}>
                更新
              </Button>
              <Button
                onClick={() => {
                  setComment(activity.resource.body);
                  setOnEdit(false);
                }}>
                キャンセル
              </Button>
            </MenuButtonWrapper>
            <div />
          </QuillWrapper>
        </EditorWrapper>
      ) : (
        <React.Fragment>
          <CommentBody
            dangerouslySetInnerHTML={{
              __html: convertUrlToLink(activity.resource.body || ''),
            }}
          />
          <CommentEditBox>
            <IconWrapper>
              <EditIconWrapper onClick={() => setOnEdit(!onEdit)}>
                <Edit />
              </EditIconWrapper>
              {activity.preleadProjectId && (
                <TrashIconWrapper
                  onClick={() => {
                    if (confirm('本当に削除しますか？')) {
                      if (location.pathname.indexOf('project') !== -1) {
                        destroyComment({
                          variables: {
                            uuid: activity.uuid,
                          },
                        });
                      } else {
                        destroyCommentMaster({
                          variables: {
                            uuid: activity.uuid,
                          },
                        });
                      }
                    }
                  }}>
                  <Trash />
                </TrashIconWrapper>
              )}
            </IconWrapper>
          </CommentEditBox>
        </React.Fragment>
      )}
    </Comment>
  );
};

const IconWrapper = styled.div`
  display: flex;
`;

const EditIconWrapper = styled.div``;

const TrashIconWrapper = styled.div``;

const MenuButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  > Button {
    margin: 0px 8px 0px 5px;
  }
`;

const EditorWrapper = styled.div`
  margin: 10px 0px;
  color: rgba(0, 0, 0, 0.85);
`;

const Summary = styled.div`
  margin-left: -3px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 480px;
  height: 30px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const Body = styled.span`
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
`;

const TimeStamp = styled.span`
  margin-left: auto;
`;

const Comment = styled.div`
  margin-left: -30px;
  position: relative;
  padding: 9px 20px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: #ffffff;
`;

const Project = styled.span`
  display: block;
  margin-bottom: 15px;
  font-size: 8px;
  color: var(--text-color-2);
`;

const CommentBody = styled.div`
  margin-top: 15px;
  color: var(--text-color-1);
`;

const CommentEditBox = styled.div`
  margin-top: 20px;

  svg {
    margin-right: 12px;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

export default MailCommentActivityComponent;
