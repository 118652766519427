import React from 'react';
import styled from 'styled-components';
import {Loading} from 'components/Icon';
import useSearchCondition from '../../useSearchCondition';
import {useWebsitePagesQuery} from 'api';
import {useParams} from 'react-router';

export default () => {
  const {websiteType} =
    useParams<{
      websiteType: string;
    }>();
  const {mediaSearchParams, page, perPage} = useSearchCondition();

  const {data: {websitePages: {uniqueCompanyCount} = {}} = {}, loading} =
    useWebsitePagesQuery({
      variables: {
        websiteType: websiteType,
        search: mediaSearchParams,
        page,
        perPage,
      },
    });

  return (
    <Container>
      <div>
        <Caption>企業数</Caption>
        <Count>
          {loading ? (
            <Loading />
          ) : (
            `${
              uniqueCompanyCount ? uniqueCompanyCount.toLocaleString() : 0
            }件`
          )}
        </Count>
      </div>
      <Message>10,000件以下の件数を指定してください</Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 6.64%;
  bottom: 8.88%;
  width: 260px;
  height: 60px;

  background: rgba(255, 255, 255, 0.9);
  opacity: 0.95;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
  border-radius: 5px;

  > div {
    display: flex;
  }
`;

const Caption = styled.div`
  margin: auto 50px;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color-3);
`;

const Count = styled.div`
  margin: auto 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: var(--text-color-0);
`;

const Message = styled.span`
  margin-left: 50px;
  font-weight: bold;
  font-size: 10px;
  color: var(--text-color-3);
`;
