import React, {useState} from 'react';
import {Combobox} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {
  useCreateClientProspectScoreSettingPositionKeywordMutation,
  useDestroyClientProspectScoreSettingPositionKeywordMutation,
  useProspectScoreSettingQuery,
} from 'api';

export default function Input({priority}: {priority: number}) {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const [query, setQuery] = useState('');
  const [createKeyword] =
    useCreateClientProspectScoreSettingPositionKeywordMutation({});
  const [destroyKeyword] =
    useDestroyClientProspectScoreSettingPositionKeywordMutation({});

  return (
    <div className="w-full">
      <Combobox value={null}>
        <div className="relative mt-1 flex items-center flex-shrink-0 gap-1">
          <div className="text-sm font-bold w-12 text-center">
            優先度{priority}
          </div>
          <div className="p-2 border border-c-border rounded-sm flex gap-2 w-full items-center flex-wrap">
            {prospectScoreSetting?.clientProspectScoreSettingPositionKeywordsByPriority?.[
              priority
            ]?.map((keyword: any) => (
              <span
                key={keyword.id}
                className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
                {keyword.name}
                <button
                  onClick={() => {
                    destroyKeyword({
                      variables: {
                        id: keyword.id,
                      },
                    });
                  }}
                  className="flex items-center justify-center cursor-pointer bg-c-bg">
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </span>
            ))}
            <Combobox.Input
              className="flex-1 outline-none w-full"
              value={query}
              displayValue={() => query}
              onChange={(event) => setQuery(event.target.value)}
              onKeyDown={(e: any) => {
                if (
                  e.key === 'Enter' &&
                  e.nativeEvent.isComposing === false &&
                  query
                ) {
                  createKeyword({
                    variables: {
                      priority,
                      name: query,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </Combobox>
    </div>
  );
}
