import React, {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {useLeadSourcesQuery} from 'api';
import useSearchParams from '../useSearchParams';

export default () => {
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({});
  const {query, setQuery} = useSearchParams();

  return (
    <div className="w-36 h-9">
      <Listbox
        value={query.leadSourceIds}
        onChange={(values) => setQuery({leadSourceIds: values})}
        multiple>
        <div className="relative flex-1 w-full h-full">
          <Listbox.Button
            className={`relative bg-white px-3 pr-10 text-left focus:outline-none flex items-center border rounded w-full h-full cursor-pointer ${
              query.leadSourceIds?.length > 0
                ? 'border-[#A7D5FF]'
                : 'border-[#E1E6EB]'
            }`}>
            <span
              className={
                query.leadSourceIds?.length > 0
                  ? 'text-primary'
                  : 'text-foreground-lighter'
              }>
              リードソース
            </span>
            <span
              className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 ${
                query.leadSourceIds?.length > 0
                  ? 'text-primary'
                  : 'text-foreground-lighter'
              }`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="z-20 absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
              {leadSources
                .filter(
                  (leadSource) => !query.leadSourceIds.includes(leadSource.id),
                )
                .map((leadSource) => (
                  <Listbox.Option
                    key={leadSource.id}
                    className={({active}) =>
                      `relative select-none py-2 px-3 cursor-pointer ${
                        active ? 'bg-gray-100' : 'bg-white'
                      }`
                    }
                    value={leadSource.id}>
                    {leadSource.name}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
