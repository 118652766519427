import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2791 13.0485L15.532 2.81599C15.2333 1.70346 13.866 1.29358 13.0026 2.05775L11.2712 3.59011C9.34441 5.29534 7.04638 6.52943 4.55898 7.19464C2.47688 7.75147 1.24293 9.89371 1.80082 11.9718C2.35872 14.0499 4.50052 15.2893 6.58263 14.7325C9.07 14.0672 11.6784 13.9891 14.2013 14.5045L16.4684 14.9675C17.599 15.1984 18.5777 14.161 18.2791 13.0485Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.43115 6.66663L9.58326 18.3333"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
