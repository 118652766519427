import React from 'react';
import styled from 'styled-components';
import {Button, Popover} from 'components/antd';
import {MailCampaign, usePoolProspectCustomizeItemsQuery} from 'api';
import mailVariableOptions from 'helpers/mailVariableOptions';

interface Props {
  onSelect: (variable: string) => void;
  mailCampaign: MailCampaign;
}

export default ({onSelect, mailCampaign}: Props) => {
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({
      variables: {poolId: mailCampaign.pool.uuid},
    });

  const customizeItems = React.useMemo(() => {
    return poolProspectCustomizeItems.filter(
      (item) => item.dataType === 'text',
    );
  }, [poolProspectCustomizeItems]);

  const customizeItemTexts = customizeItems.map((customizeItem) => ({
    title: customizeItem.name,
    code: `{{${customizeItem.name}}}`,
  }));

  const mergedOptions = [...mailVariableOptions, ...customizeItemTexts];

  return (
    <Popover
      content={
        <Content>
          {mergedOptions.map((option) => (
            <ButtonWrapper key={option.title}>
              <CustomButton onClick={() => onSelect(option.code)}>
                <span>{option.code}</span>
                <span>{option.title}</span>
              </CustomButton>
            </ButtonWrapper>
          ))}
        </Content>
      }
      trigger="click"
      placement="topRight">
      <Button
        style={{
          width: 32,
          justifyContent: 'center',
          display: 'flex',
          color: '#BDC4CB',
        }}>{`{ }`}</Button>
    </Popover>
  );
};

const Content = styled.div`
  display: inline-block;
  white-space: nowrap;
  padding: 20px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  span:first-child {
    color: #899098;
    margin-right: 10px;
  }
  span:last-child {
    color: #495058;
  }
`;

const CustomButton = styled(Button)`
  padding: 0 20px;
  text-align: left;
  border: none;
  white-space: nowrap;

  &:hover {
    background: #f1f8ff;
  }
`;
