import React from 'react';
import styled from 'styled-components';
import {Modal, Button, message, Tooltip} from 'components/antd';
import {
  PreleadContact,
  useCreateSalesforceLeadMutation,
  useIntegrateContactToHubspotMutation,
  useIntegrateContactToSensesMutation,
  useProjectWithClientQuery,
} from 'api';
import {StatusEclipse} from 'components/Ui';
import {useParams} from 'react-router';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  preleadContact: PreleadContact;
}

export default ({isModalVisible, setModalVisible, preleadContact}: Props) => {
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const {data: {project: {client = {}} = {}} = {}} = useProjectWithClientQuery({
    variables: {uuid: projectId},
  });

  const [createSalesforceLead, {loading: salesforceLoading}] =
    useCreateSalesforceLeadMutation({
      refetchQueries: ['preleadContacts', 'preleadProject'],
      onCompleted: ({createSalesforceLead: {success}}) => {
        if (success) {
          message.success('登録に成功しました。');
          handleCancel();
        } else {
          message.error('登録に失敗しました。');
        }
      },
    });

  const [integrateContactToHubspot, {loading: hubspotLoading}] =
    useIntegrateContactToHubspotMutation({
      refetchQueries: ['preleadContacts', 'preleadProject'],
      onCompleted: ({integrateContactToHubspot: {success}}) => {
        if (success) {
          message.success('登録に成功しました。');
          handleCancel();
        } else {
          message.error('登録に失敗しました。');
        }
      },
    });

  const [integrateContactToSenses, {loading: sensesLoading}] =
    useIntegrateContactToSensesMutation({
      refetchQueries: ['preleadContacts', 'preleadProject'],
      onCompleted: ({integrateContactToSenses: {success}}) => {
        if (success) {
          message.success('登録に成功しました。');
          handleCancel();
        } else {
          message.error('登録に失敗しました。');
        }
      },
    });

  const handleSalesfoceClick = () => {
    createSalesforceLead({
      variables: {
        attributes: {
          preleadContactUuid: preleadContact.uuid,
          preleadProjectUuid: preleadProjectId,
        },
      },
    });
  };

  const handleHubspotClick = () => {
    integrateContactToHubspot({
      variables: {
        preleadContactUuid: preleadContact.uuid,
        preleadProjectUuid: preleadProjectId,
      },
    });
  };

  const handleSensesClick = () => {
    integrateContactToSenses({
      variables: {
        preleadContactUuid: preleadContact.uuid,
        preleadProjectUuid: preleadProjectId,
      },
    });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const getIntegrationStatus = (
    isActive: boolean,
    isIntegrated: boolean,
  ): string => {
    if (!isActive) return '無効';
    if (isIntegrated) {
      return '連携済み';
    } else {
      return '連携可能';
    }
  };

  return (
    <SettingModal
      title="接続されたアプリ"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={false}
      style={{
        marginRight: '0%',
      }}>
      <Section>
        <Card>
          <div>
            <Name>Salesforce</Name>
          </div>
          <div>
            <StatusBox
              status={getIntegrationStatus(
                client.salesforceActive,
                !!preleadContact.salesforceLeadId,
              )}>
              <StatusEclipse />
              <span>
                {getIntegrationStatus(
                  client.salesforceActive,
                  !!preleadContact.salesforceLeadId,
                )}
              </span>
            </StatusBox>

            {client.salesforceActive && !preleadContact.firstName ? (
              <CustomToolTip
                title="「名」を登録してください。"
                color={`#EB5757`}
                placement="top">
                <IntegrationButton type="primary" disabled>
                  連携
                </IntegrationButton>
              </CustomToolTip>
            ) : (
              <IntegrationButton
                type="primary"
                onClick={handleSalesfoceClick}
                loading={salesforceLoading}
                disabled={
                  !client.salesforceActive ||
                  !!preleadContact.salesforceLeadId ||
                  !preleadContact.firstName ||
                  salesforceLoading
                }>
                連携
              </IntegrationButton>
            )}
          </div>
        </Card>
        <Card>
          <div>
            <Name>HubSpot</Name>
          </div>
          <div>
            <StatusBox
              status={getIntegrationStatus(
                client.hubspotActive,
                !!preleadContact.hubspotContactId,
              )}>
              <StatusEclipse />
              <span>
                {getIntegrationStatus(
                  client.hubspotActive,
                  !!preleadContact.hubspotContactId,
                )}
              </span>
            </StatusBox>

            <IntegrationButton
              type="primary"
              onClick={handleHubspotClick}
              loading={hubspotLoading}
              disabled={
                !client.hubspotActive ||
                !!preleadContact.hubspotContactId ||
                hubspotLoading
              }>
              連携
            </IntegrationButton>
          </div>
        </Card>

        <Card>
          <div>
            <Name>Senses</Name>
          </div>
          <div>
            <StatusBox
              status={getIntegrationStatus(
                client.sensesActive,
                !!preleadContact.sensesContactId,
              )}>
              <StatusEclipse />
              <span>
                {getIntegrationStatus(
                  client.sensesActive,
                  !!preleadContact.sensesContactId,
                )}
              </span>
            </StatusBox>

            <IntegrationButton
              type="primary"
              onClick={handleSensesClick}
              loading={sensesLoading}
              disabled={
                !client.sensesActive ||
                !!preleadContact.sensesContactId ||
                sensesLoading
              }>
              連携
            </IntegrationButton>
          </div>
        </Card>
      </Section>
    </SettingModal>
  );
};

const SettingModal = styled(Modal)`
  .ant-modal-content {
    width: 330px;
    border-radius: 5px;
    padding: 14px 15px 10px 15px;
  }

  .ant-modal-title {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-1);
  }

  .ant-modal-header {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    padding: 0;
    margin-bottom: 18px;
  }

  .ant-modal-footer {
    padding: 0;
    border: none;
  }
`;

const IntegrationButton = styled(Button)`
  margin-left: auto;
  font-size: 10px;
  width: 46px;
  height: 18px;
  border-radius: 3px;
  padding: 0;
`;

const CustomToolTip = styled(Tooltip)`
  margin-left: auto;
`;

const Section = styled.div`
  margin-top: 10px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > div:last-child {
    border-bottom: none;
  }
`;

const Card = styled.div`
  padding: 14px 15px 14px 17px;
  border-bottom: 1px solid #e1e6eb;

  > div {
    display: flex;
    align-items: center;

    &:last-child {
      margin-top: 2px;
    }
  }
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: var(--text-color-1);
`;

const statusColor = (status: string) => {
  switch (status) {
    case '無効':
      return 'var(--text-color-2)';
    case '連携可能':
      return 'var(--primary)';
    case '連携済み':
      return '#27AE60';
  }
};

const StatusBox = styled.div<{status?: string}>`
  svg {
    margin-right: 4px;
    width: 9px;
    height: 9px;
    circle {
      stroke: ${({status}) => status && statusColor(status)};
      fill: ${({status}) => status && statusColor(status)};
    }
  }
`;
