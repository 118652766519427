import React from 'react';
import styled from 'styled-components';
import {useCompanyQuery} from 'api';
import moment from 'moment';

type Props = {
  companyId: string;
};

export default ({companyId}: Props) => {
  const {data: {company = {}, company: {catrs = []} = {}} = {}} =
    useCompanyQuery({
      variables: {uuid: companyId},
      skip: !companyId,
    });

  const toJaNum = (num: string | null) => {
    if (num === null) {
      return null;
    }
    let prefix = '';

    if (num[0] === '-') {
      num = num.slice(1);
      prefix = '-';
    }

    return (
      prefix +
      num
        .slice(0, -12)
        .replace(/^0+/, '')
        .replace(/([0-9]+)/g, '$1兆') +
      num
        .slice(-12, -8)
        .replace(/^0+/, '')
        .replace(/([0-9]+)/g, '$1億') +
      num
        .slice(-8, -4)
        .replace(/^0+/, '')
        .replace(/([0-9]+)/g, '$1万') +
      num.substring(num.length - 4).replace(/^0+/, '')
    );
  };

  return (
    <Container>
      {company && company.accountClosingMonth ? (
        <AccountClosingMonth>
          {`＜`}決算月 : {company?.accountClosingMonth}月{`＞`}
        </AccountClosingMonth>
      ) : (
        ''
      )}
      {catrs?.slice(0, 3).map((catr) => (
        <Fisacl key={catr.id}>
          {catr.date ? (
            <AnnouncementDate>
              {moment(catr.date).format('YYYY年MM月')}
            </AnnouncementDate>
          ) : (
            ''
          )}
          <Item>
            <Name>売上高 :</Name>
            <Value>
              <Price minus={catr.sales?.charAt(0) === '-'}>
                {catr.sales ? `${toJaNum(catr.sales)}円` : ''}
              </Price>
              <Percent minus={catr.salesRate?.charAt(0) === '-'}>
                {catr.salesRate ? `(${catr.salesRate}%)` : ''}
              </Percent>
            </Value>
          </Item>
          <Item>
            <Name>経常利益 :</Name>
            <Value>
              <Price minus={catr.pretaxProfit?.charAt(0) === '-'}>
                {catr.pretaxProfit ? `${toJaNum(catr.pretaxProfit)}円` : ''}
              </Price>
              <Percent minus={catr.pretaxProfitRate?.charAt(0) === '-'}>
                {catr.pretaxProfitRate ? `(${catr.pretaxProfitRate}%)` : ''}
              </Percent>
            </Value>
          </Item>
          <Item>
            <Name>営業利益 :</Name>
            <Value>
              <Price minus={catr.salesProfit?.charAt(0) === '-'}>
                {catr.salesProfit ? `${toJaNum(catr.salesProfit)}円` : ''}
              </Price>
              <Percent minus={catr.salesProfitRate?.charAt(0) === '-'}>
                {catr.salesProfitRate ? `(${catr.salesProfitRate}%)` : ''}
              </Percent>
            </Value>
          </Item>
          <Item>
            <Name>純利益 :</Name>
            <Value>
              <Price minus={catr.profit?.charAt(0) === '-'}>
                {catr.profit ? `${toJaNum(catr.profit)}円` : ''}
              </Price>
              <Percent minus={catr.profitRate?.charAt(0) === '-'}>
                {catr.profitRate ? `(${catr.profitRate}%)` : ''}
              </Percent>
            </Value>
          </Item>
          <Item>
            <Name>利益剰余金 :</Name>
            <Value>
              <Price minus={catr.profitSurplus?.charAt(0) === '-'}>
                {catr.profitSurplus ? `${toJaNum(catr.profitSurplus)}円` : ''}
              </Price>
              <Percent minus={catr.profitSurplusRate?.charAt(0) === '-'}>
                {catr.profitSurplusRate ? `(${catr.profitSurplusRate}%)` : ''}
              </Percent>
            </Value>
          </Item>
          <Item>
            <Name>総資産 :</Name>
            <Value>
              <Price minus={catr.asset?.charAt(0) === '-'}>
                {catr.asset ? `${toJaNum(catr.asset)}円` : ''}
              </Price>
              <Percent minus={catr.assetRate?.charAt(0) === '-'}>
                {catr.assetRate ? `(${catr.assetRate}%)` : ''}
              </Percent>
            </Value>
          </Item>
        </Fisacl>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Fisacl = styled.div`
  margin-top: 16px;
  line-height: 30px;
  border-bottom: 0.5px solid var(--text-color-3);
  padding-bottom: 20px;
`;

const AccountClosingMonth = styled.div`
  font-family: Noto Sans JP;
  font-size: 10px;
  font-weight: bold;
`;

const AnnouncementDate = styled.span`
  padding: 10px 10px;
  margin-bottom: 10px;
  display: inline-block;
  background: #f3f5f7;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;

const Item = styled.div`
  margin-left: 10px;
  display: flex;
`;

const Name = styled.span`
  display: inline-block;
  font-family: Noto Sans JP;
  font-size: 12px;
  letter-spacing: 0.15px;
  width: 120px;
`;

const Value = styled.div`
  display: inline-block;
  font-family: Noto Sans JP;
  font-weight: bold;
  letter-spacing: 0.15px;
`;

const Price = styled.span<{minus?: boolean}>`
  color: ${({minus}) => (minus ? '#c90e19' : '')};
`;

const Percent = styled.span<{minus?: boolean}>`
  color: ${({minus}) => (minus ? '#c90e19' : '')};
`;
