import React from 'react';
import {Project} from 'api';
import {Row} from 'react-table';
import {
  projectStatusBgColor,
  projectStatusFontColor,
} from 'helpers/projectStatusColor';

interface Props {
  value: string;
  row: Row<Project>;
}

export default ({value, row}: Props) => {
  const project = row.original;

  return (
    <div className="text-center">
      <span
        style={{
          backgroundColor: projectStatusBgColor(value),
          color: projectStatusFontColor(value),
        }}
        className="py-1 px-2 rounded-full text-xs font-bold">
        {project.statusText}
      </span>
    </div>
  );
};
