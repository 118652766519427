import React from 'react';
import styled from 'styled-components';
import {ArrowDown, BuildingDomestic} from 'components/Ui/Icon';
import {useHistory} from 'react-router-dom';
import {useCurrentClientQuery} from 'api';
import {Button, Dropdown, Menu} from 'components/antd';

export default () => {
  const history = useHistory();
  const {data: {currentClient: {requestActiveClients = []} = {}} = {}} =
    useCurrentClientQuery({fetchPolicy: 'cache-and-network'});

  return (
    <Container>
      <Dropdown
        placement="bottomRight"
        trigger={['click']}
        overlay={
          <Menu
            onClick={({key}) =>
              history.push(
                `/list/share/request/${key}/projects?page=1&status=open`,
              )
            }>
            {requestActiveClients?.map((client) => (
              <Menu.Item key={client.uuid}>
                <Item>
                  <div>
                    <BuildingDomestic />
                  </div>
                  <div>{client.name}</div>
                </Item>
              </Menu.Item>
            ))}
          </Menu>
        }>
        <Button type="ghost">
          コネクト <ArrowDown />
        </Button>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  .ant-btn-ghost {
    display: flex;
    align-items: center;
    border: 1px solid var(--text-color-3);
    border-radius: 4px;
    color: var(--text-color-1);
    font-weight: 700;
    padding: 5.6px 10px;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: var(--border-color);
    border-radius: 4px;
    margin-right: 7px;

    svg {
      width: 13px;
      height: 13px;
    }
  }
`;
