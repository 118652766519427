import React from 'react';
import styled from 'styled-components';
import StepTypeIcon from 'components/Workflow/Icon/WorkflowTemplateStepTypeIcon';
import {WorkflowTemplateWorkflow, WorkflowTemplateStep} from 'api';
import {Tooltip} from 'components/antd';
import {CalendarCheckedCircle} from 'components/Ui/Icon';
import AutomaticMailModal from './AutomaticMailComponent';

interface Props {
  workflow: WorkflowTemplateWorkflow;
}

export default ({workflow}: Props) => {
  const displayDate = (step: WorkflowTemplateStep) => {
    if (step.isImmediately) return `即時実行`;
    if (step.startDay === 0) return '当日';

    let day = 0;
    let cantDisplay = false;
    workflow.steps.some((s) => {
      if (s.position > step.position) return true;
      if (s.startDate) {
        cantDisplay = true;
        return true;
      }

      day += s.startDay;
    });

    if (cantDisplay) return 'ー';
    return day + '日目';
  };

  const targetDay = (days: number[]) => {
    const day = ['月', '火', '水', '木', '金', '土', '日'];
    return days.map((index) => day[index - 1]).join(', ');
  };

  const [visible, setVisible] = React.useState(false);
  const [automaticMail, setAutomaticMail] = React.useState(null);

  return (
    <Container>
      {workflow.steps.map((step, index) => (
        <StepContainer key={step.uuid}>
          <StepNumber>{index + 1}</StepNumber>
          <span style={{width: '64px', fontSize: '14px'}}>
            {displayDate(step)}
          </span>
          <Calendar>
            <span>{step.startHour && step.startHour + ':00'}</span>
            {step.daysOfWeek.length > 0 && (
              <Tooltip placement="top" title={targetDay(step.daysOfWeek)}>
                <span>
                  <CalendarCheckedCircle />
                </span>
              </Tooltip>
            )}
          </Calendar>
          <Type>
            <StepTypeIcon step={step} active />
          </Type>
          <div>
            <StepName
              onClick={() => {
                if (step.stepType === 'automatic_mail') {
                  setAutomaticMail(step.automaticMail);
                  setVisible(true);
                }
              }}
              isAutomaticMail={step.stepType === 'automatic_mail'}>
              {step.name}
            </StepName>
            <StepMemo>{step.memo}</StepMemo>
          </div>
        </StepContainer>
      ))}
      <AutomaticMailModal
        visible={visible}
        setVisible={setVisible}
        automaticMail={automaticMail}
      />
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #e1e6eb;
  border-bottom: 0;
  margin-bottom: 20px;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #e1e6eb;
`;

const StepNumber = styled.span`
  font-size: 14px;
  color: var(--text-color-2);
  margin-right: 10px;
`;

const Type = styled.div`
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
`;

const StepName = styled.div<{isAutomaticMail: boolean}>`
  font-weight: bold;
  font-size: 14px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${({isAutomaticMail}) => (isAutomaticMail ? 'pointer' : '')};
`;

const StepMemo = styled.div`
  color: var(--text-color-2);
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Calendar = styled.div`
  width: 30px;
  span {
    display: flex;
    align-items: center;
  }
`;
