import React from 'react';
import {useAccountPoolQuery, useCurrentUserQuery} from 'api';
import {useParams} from 'react-router-dom';
import {scoreColorForeground} from 'helpers/scoreColors';

const CircularDonut = ({
  percentage,
  rank,
}: {
  percentage: number;
  rank: string;
}) => {
  // 半径と円周の計算 (円周 = 2 * π * r)
  const radius = 15.91549430918954;
  const circumference = 2 * Math.PI * radius;

  // strokeDashoffsetをpercentageに基づいて計算
  const strokeDashoffset = circumference * (1 - percentage / 100);

  return (
    <svg width="56px" height="56px" viewBox="0 0 40 40">
      {/* ドーナツの穴 */}
      <circle cx="20" cy="20" r={radius} fill="#fff"></circle>

      {/* ドーナツのリング */}
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="transparent"
        strokeWidth="3.5"
        className="stroke-c-border"></circle>

      {/* ドーナツのセグメント */}
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="transparent"
        strokeWidth="3.5"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        style={{stroke: `${scoreColorForeground(rank)}`}}
        transform="rotate(-90 20 20)"></circle>

      {/* ドーナツ内のテキスト */}
      <g>
        {/* 大きい文字 'A' */}
        <text y="45%" transform="translate(0, 2)">
          <tspan
            x="50%"
            textAnchor="middle"
            style={{fill: `${scoreColorForeground(rank)}`}}
            className="text-sm font-bold">
            {rank}
          </tspan>
        </text>
        {/* 下の小さい文字 */}
        <text y="70%" transform="translate(0, 2)">
          <tspan x="50%" textAnchor="middle" className="text-[8px]">
            {percentage}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default () => {
  const {accountId} = useParams<{accountId: string}>();
  const {
    data: {
      accountPool: {account = {}, score = null} = {},
      accountPool = {},
    } = {},
  } = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});
  return (
    <header className="flex gap-2 items-center my-6">
      {currentUser?.role === 'admin' && (
        <CircularDonut percentage={score?.totalScore} rank={score?.scoreRank} />
      )}
      <div className="flex flex-col gap-2 items-start flex-1">
        {currentUser?.role === 'admin' && accountPool?.clientAccountStatus && (
          <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
            {accountPool.clientAccountStatus.name}
          </span>
        )}
        <h1 className="text-xl font-bold m-0 leading-none">{account?.name}</h1>
        {account?.webSite && (
          <a className="leading-none" href={account.webSite} target="_blank">
            {account.webSite}
          </a>
        )}
      </div>
    </header>
  );
};
