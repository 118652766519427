import React from 'react';
import styled from 'styled-components';
import {Action} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Action>;
}

const WorkflowCell = ({row}: Props) => {
  const {
    workflowProspectPool: {workflow},
  } = row.original;

  return <Workflow>{workflow.name}</Workflow>;
};

const Workflow = styled.span`
  color: var(--text-color-2);
  font-size: 10px;
`;

export default WorkflowCell;
