import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Folder, Note, PenWithLine, Calendar} from 'components/Ui/Icon';
import {useBlockGroupQuery} from 'api';
import Edit from './Edit';
import moment from 'moment';

export default () => {
  const {blockGroupId} = useParams<{blockGroupId: string}>();
  const [isEditing, setEditing] = useState(false);
  const {data: {blockGroup = null} = {}} = useBlockGroupQuery({
    variables: {uuid: blockGroupId},
    fetchPolicy: 'cache-and-network',
    skip: !blockGroupId,
  });

  const Show = () => (
    <>
      <Item>
        <Folder />
        <Name>{blockGroup?.name}</Name>
      </Item>
      <ItemMemo>
        <Note />
        <Memo>{blockGroup?.memo}</Memo>
      </ItemMemo>
      <ItemCreatedAt>
        <Calendar />
        <CreatedAt>
          {moment(blockGroup?.updatedAt).format('YYYY年MM月DD日')}
        </CreatedAt>
      </ItemCreatedAt>
    </>
  );

  if (!blockGroup) return null;

  return (
    <Container>
      <StatusWrapper>
        <EditButton onClick={() => setEditing(!isEditing)}>
          <PenWithLine />
        </EditButton>
      </StatusWrapper>
      {isEditing ? <Edit closeEditMode={() => setEditing(false)} /> : <Show />}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
  width: 70%;
`;

const StatusWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 26px;
  height: 26px;
  background: #f9f9f9;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color-0);
  width: 100%;
  overflow-wrap: anywhere;
`;

const Memo = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color-0);
  width: 100%;
  overflow-wrap: anywhere;
`;

const ItemCreatedAt = styled(Item)`
  align-items: start;
`;

const CreatedAt = styled.span`
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-0);
`;
