import React from 'react';

function Icon() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4388 9.16667C10.3247 9.16667 11.8534 7.67428 11.8534 5.83333C11.8534 3.99238 10.3247 2.5 8.4388 2.5C6.55295 2.5 5.02417 3.99238 5.02417 5.83333C5.02417 7.67428 6.55295 9.16667 8.4388 9.16667Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.46313 17.5V14.1667C2.46313 13.2462 3.22753 12.5 4.17045 12.5H12.707C13.65 12.5 14.4144 13.2462 14.4144 14.1667V17.5"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4144 2.5C15.1489 2.68358 15.7999 3.10058 16.2648 3.68526C16.7297 4.26993 16.9821 4.98902 16.9821 5.72917C16.9821 6.46931 16.7297 7.1884 16.2648 7.77307C15.7999 8.35775 15.1489 8.77475 14.4144 8.95833"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9753 12.5H17.829C18.772 12.5 19.5363 13.2462 19.5363 14.1667V17.5"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
