import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, ManualMailCommentProspectActivity} from 'api';
import {ManualMail, Calendar} from 'components/Ui/Icon';
import linkifyHtml from 'linkify-html';

interface ManualMailCommentActivityActivity extends ProspectActivity {
  resource: ManualMailCommentProspectActivity;
}

interface Props {
  prospectActivity: ManualMailCommentActivityActivity;
}

export default ({prospectActivity}: Props) => {
  const {resource, user, action} = prospectActivity;
  const {stageText, recordedAt, commentHtml} = resource;

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <ManualMail active={true} width="32" height="32" />
      <Content>
        <ContentHeader>
          <h3>{action?.step?.name}</h3>
          <span>
            {user.lastName} {user.firstName}
          </span>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </ContentHeader>
        <ContentBox>
          {commentHtml && (
            <div
              dangerouslySetInnerHTML={{__html: convertUrlToLink(commentHtml)}}
            />
          )}
          <ContentMeta>
            <Stage>{stageText}</Stage>
            <RecordedAt>
              <Calendar />
              <span>{moment(recordedAt).format('YYYY/MM/DD HH:mm')}</span>
            </RecordedAt>
          </ContentMeta>
        </ContentBox>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: var(--primary);
    font-weight: bold;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px var(--border-color);
  border-radius: 4px;
  padding: 15px;
`;

const ContentMeta = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 15px;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      height: 18px;
      width: auto;
      margin-right: 4px;
    }
  }
`;

const Stage = styled.span`
  background: #dceeff;
  font-size: 10px;
  color: #2d9cdb;
  height: 20px;
  padding: 0 18px;
  border-radius: 2px;
`;

const RecordedAt = styled.span`
  svg {
    position: relative;
    top: -1px;
  }
`;
