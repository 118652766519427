import React, {useState} from 'react';
import styled from 'styled-components';
import {ProspectPool, usePoolQuery} from 'api';
import {Row} from 'react-table';
import {DotMenuSide, Salesforce, User} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import AssigneeModal from './AssigneeModal';
import SalesforceUserModal from './SalesforceUserModal';
import {useParams} from 'react-router-dom';

interface Props {
  row: Row<ProspectPool>;
}

const MenuCell = ({row}: Props) => {
  const {poolId} = useParams<{
    poolId: string;
  }>();
  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });
  const [visible, setVisible] = useState(false);
  const [assigneeVisible, setAssigneeVisible] = useState(false);
  const [salesforceUserVisible, setSalesforceUserVisible] = useState(false);

  const Content = () => {
    return (
      <>
        <PopoverContent>
          <div
            onClick={() => {
              setAssigneeVisible(true);
              setVisible(false);
            }}>
            <User width="12" height="12" color="var(--text-color-2)" />
            <span>担当者を変更</span>
          </div>
        </PopoverContent>
        {pool?.poolSalesforceSetting?.isActive && (
          <PopoverContent>
            <div
              onClick={() => {
                setSalesforceUserVisible(true);
                setVisible(false);
              }}>
              <Salesforce className={'w-3 h-3'} />
              <span>所有者を変更</span>
            </div>
          </PopoverContent>
        )}
      </>
    );
  };

  return (
    <>
      <Popover
        placement="bottomRight"
        content={Content}
        trigger="click"
        visible={visible}
        onVisibleChange={(e) => setVisible(e)}>
        <div style={{cursor: 'pointer'}}>
          <DotMenuSide color={visible ? 'var(--primary)' : 'var(--text-color-2)'} />
        </div>
      </Popover>
      <AssigneeModal
        prospectPool={row.original}
        isModalVisible={assigneeVisible}
        setIsModalVisible={setAssigneeVisible}
      />
      <SalesforceUserModal
        prospectPool={row.original}
        isModalVisible={salesforceUserVisible}
        setIsModalVisible={setSalesforceUserVisible}
      />
    </>
  );
};

const PopoverContent = styled.div`
  width: 140px;
  font-size: 14px;
  color: var(--text-color-2);
  font-weight: normal;
  padding: 4px 6px;
  cursor: pointer;
  > div {
    padding: 4px 8px;
    &:hover {
      background: #f3f5f7;
    }
    > span {
      margin-left: 4px;
    }
  }
`;

export default MenuCell;
