import styled from 'styled-components';

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 14px 0;
  width: 100%;
  min-height: 46px;
  border-bottom: 1px solid #f3f5f7;

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      display: flex;
      align-items: center;
      width: 135px;

      > svg {
        margin-right: 11px;
        width: 20px;
        path {
          stroke: var(--text-color-3);
        }
      }

      > span {
        flex: 1;
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
        color: var(--text-color-2);
        overflow: hidden;
      }
    }

    &:nth-child(2) {
      flex: 1;

      .ant-picker,
      .ant-input {
        padding: 0;
      }
    }
  }
`;

export {Item};
