import React from 'react';

export default () => (
  <svg
    width="34"
    height="31"
    viewBox="0 0 34 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4 30.4508V22.4008L0 22.4008L0 8.60078L18.4 8.60078V0.550781L33.35 15.5008L18.4 30.4508Z"
      fill="var(--text-color-1)"
    />
  </svg>
);
