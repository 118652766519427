import React from 'react';
import styled from 'styled-components';
import {ActivityAttachment} from 'api';
import {ContentDocument} from 'components/Ui/Icon';

interface Props {
  attachment: ActivityAttachment;
}

export default ({attachment}: Props) => {
  return (
    <File>
      <div>
        <ContentDocument />
        <h3>
          <a href={attachment.file} target="_blank">
            {attachment.fileName}
          </a>
        </h3>
      </div>
    </File>
  );
};

const File = styled.div`
  border: 1px solid var(--border-color);
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 10px;

  > div {
    display: flex;
    align-items: center;

    svg {
      width: 30px;
      height: auto;
      margin-right: 0.5rem;
    }

    h3 {
      margin: 0;

      a {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  audio {
    width: 100%;
  }
`;
