import React from 'react';
import styled from 'styled-components';
import {useAllProjectsTelStatusCallReportQuery} from 'api';
import useSearchParams from '../../useSearchParams';

export default () => {
  const {searchParams} = useSearchParams();
  const {
    data: {
      allProjectsTelStatusCallReport: {
        call = {},
        reception = {},
        contact = {},
        appoint = {},
        document = {},
        other = {},
        callNg = {},
        receptionNg = {},
        contactNg = {},
      } = {},
      allProjectsTelStatusCallReport = {},
    } = {},
  } = useAllProjectsTelStatusCallReportQuery({
    variables: {
      search: searchParams,
    },
  });

  const totalCount = (object: any): any =>
    Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );

  const callCount = React.useMemo(() => {
    let count = 0;

    count += totalCount(call);
    count += totalCount(reception);
    count += totalCount(contact);
    count += totalCount(appoint);
    count += totalCount(document);
    count += totalCount(other);
    count += totalCount(callNg);
    count += totalCount(receptionNg);
    count += totalCount(contactNg);

    return count;
  }, [allProjectsTelStatusCallReport]);

  const contactCount = React.useMemo(() => {
    let count = 0;

    count += totalCount(contact);
    count += totalCount(appoint);
    count += totalCount(document);
    count += totalCount(contactNg);

    return count;
  }, [allProjectsTelStatusCallReport]);

  const appointCount = React.useMemo(() => {
    let count = 0;

    count += totalCount(appoint);
    count += totalCount(document);

    return count;
  }, [allProjectsTelStatusCallReport]);

  const appointBarHeight = React.useMemo(() => {
    if (!contactCount || !totalCount(appoint)) return 0;

    return Math.round((totalCount(appoint) / contactCount) * 1000) / 10 / 2;
  }, [contactCount, appoint]);

  const documentBarHeight = React.useMemo(() => {
    if (!contactCount || !totalCount(document)) return 0;

    return Math.round((totalCount(document) / contactCount) * 1000) / 10 / 2;
  }, [contactCount, document]);

  const conversionRate = (before: number, after: number) => {
    if (!before || !after) return 0;

    return Math.round((after / before) * 1000) / 10;
  };

  return (
    <Container>
      <Box>
        <FixedCount>
          <span>{callCount && callCount.toLocaleString()}件</span>
        </FixedCount>
        <Line />
        <Bar color="#D9DFE6" height={100} />

        <h3>コール・受付</h3>
      </Box>
      <BlankBox>
        <ShiftFigure
          leftHeight={callCount ? 100 : 0}
          rightHeight={contactCount ? 50 : 0}
          color="#E9E9E9"></ShiftFigure>

        <ShiftFigure
          width={394}
          leftHeight={callCount ? 100 : 0}
          rightHeight={documentBarHeight + appointBarHeight}
          color="rgba(228, 230, 233, 0.3)"></ShiftFigure>
      </BlankBox>
      <Box>
        <Count bottom={callCount ? 50 : 5}>
          <span>{contactCount && contactCount.toLocaleString()}件</span>
          <em>{conversionRate(callCount, contactCount)}%</em>
        </Count>
        <Bar color="#34DEE9" height={contactCount ? 50 : 0} />

        <h3>キーマン接触</h3>
      </Box>
      <BlankBox />
      <Box>
        <Count
          bottom={
            documentBarHeight + appointBarHeight
              ? documentBarHeight + appointBarHeight
              : 5
          }>
          <span>{appointCount && appointCount.toLocaleString()}件</span>
          <em>{conversionRate(callCount, appointCount)}%</em>
        </Count>
        <Bar color="#27AE60" height={appointBarHeight} />
        <Bar color="#AA83FF" height={documentBarHeight} />

        <h3>アポ獲得・資料送付</h3>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 240px;
  height: 180px;
  background: #f3f5f7;
  text-align: center;

  > h3 {
    position: absolute;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--text-color-1);

    bottom: -36px;
  }
`;

const BlankBox = styled.div`
  position: relative;
  flex: 1;
  z-index: 20;
`;

const ShiftFigure = styled.div<{
  width?: number;
  leftHeight?: number;
  rightHeight?: number;
  color: string;
}>`
  position: absolute;
  ${({leftHeight}) => !leftHeight && 'display: none'}%;
  background-color: ${({color}) => color};
  width: ${({width}) => (width ? width : 100)}%;
  height: 100%;
  ${({leftHeight, rightHeight}) =>
    `clip-path: polygon(0 ${100 - leftHeight}%, 100% ${
      100 - rightHeight
    }%, 100% 100%, 0% 100%)`};

  transition: all 800ms 0s ease;
`;

const Count = styled.div<{bottom?: number}>`
  position: absolute;
  bottom: ${({bottom}) => bottom}%;
  width: 100%;
  text-align: center;

  > span {
    margin-right: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: var(--text-color-2);
  }

  > em {
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    line-height: 35px;
    color: var(--text-color-1);
  }
`;

const FixedCount = styled.div`
  position: absolute;
  top: 5%;
  width: 100%;
  text-align: center;

  > span {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: var(--text-color-2);
  }
`;

const Line = styled.div`
  width: 105%;
  height: 10px;
  position: absolute;
  top: 23%;
  left: -6px;
  background: #ffffff;
  border-top: 1px dashed var(--text-color-2);
  border-bottom: 1px dashed var(--text-color-2);
  z-index: 30;
`;

const Bar = styled.div<{color: string; height: number}>`
  margin-right: 12px;
  background-color: ${({color}) => color};
  width: 100%;
  height: ${({height}) => height}%;
  ${({color}) => !color && 'border: 1px solid var(--text-color-3)'};
  color: ${({color}) => (color ? 'white' : 'var(--text-color-3)')};
  transition: all 800ms 0s ease;
`;
