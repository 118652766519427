import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {
  usePoolSalesforceSettingQuery,
  useSalesforceClosedTaskStatusesQuery,
  useUpdateSalesforceSettingSalesforceTaskStatusMutation,
} from 'api';
import React from 'react';

export default () => {
  const {data: {salesforceClosedTaskStatuses = []} = {}} =
    useSalesforceClosedTaskStatusesQuery();

  const {data: {poolSalesforceSetting = {}} = {}} =
    usePoolSalesforceSettingQuery();

  const [update] = useUpdateSalesforceSettingSalesforceTaskStatusMutation();

  return (
    <>
      <div className="text-foreground-dark text-base font-[400] mb-6">
        <label>
          LEADPADでタイムラインを登録するときにSalesforceの活動の記録に登録される状況を設定できます。
        </label>
      </div>
      <div className="">
        <div className="flex py-4">
          <div className="text-foreground-dark w-96 text-base">
            <Listbox
              value={
                poolSalesforceSetting.salesforceTaskClosedStatus?.masterLabel
              }
              onChange={(value: any) => {
                update({
                  variables: {
                    salesforceTaskStatusId: value?.id || null,
                  },
                });
              }}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white px-3 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm h-10">
                  <span className="block truncate">
                    {
                      poolSalesforceSetting.salesforceTaskClosedStatus
                        ?.masterLabel
                    }
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={React.Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 ">
                    <Listbox.Option
                      className={({active}) =>
                        `min-h-[37.59px]  relative cursor-pointer select-none p-3 ${
                          active ? 'bg-gray-100' : 'bg-white'
                        }`
                      }
                      value={null}>
                      {({selected}) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}></span>
                        </>
                      )}
                    </Listbox.Option>
                    {salesforceClosedTaskStatuses.map((status, index) => (
                      <Listbox.Option
                        key={index}
                        className={({active}) =>
                          `min-h-[37.59px]  relative cursor-pointer select-none p-3 ${
                            active ? 'bg-gray-100' : 'bg-white'
                          }`
                        }
                        value={status}>
                        {({selected}) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}>
                              {status.masterLabel}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
    </>
  );
};
