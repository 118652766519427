import React from 'react';

const Icon = ({color = 'var(--text-color-3)', className = ''}) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0833 1.83333H4.58333C3.575 1.83333 2.75 2.65833 2.75 3.66666V18.3333C2.75 19.3417 3.575 20.1667 4.58333 20.1667H17.4167C18.425 20.1667 19.25 19.3417 19.25 18.3333V12.8333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.70831 10.5417L16.0416 3.20833C16.775 2.47499 18.0583 2.47499 18.7916 3.20833C19.525 3.94166 19.525 5.22499 18.7916 5.95833L11.4583 13.2917L7.33331 14.6667L8.70831 10.5417Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33331 17.325H14.6666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
