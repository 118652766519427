import React, {useCallback, useMemo, useState} from 'react';
import {
  PoolCustomizeItemSelectOption,
  PoolProspectCustomizeItem,
  Prospect,
  useCreateProspectCustomizeItemSelectMutation,
  useDestroyProspectCustomizeItemSelectMutation,
} from 'api';
import {Input, Popover} from 'components/antd';
import styled from 'styled-components';
import {Cross} from 'components/Ui/Icon';
import SelectOption from './SelectOption';
// import TagEdit from './SelectEdit';

interface Props {
  prospect: Prospect;
  poolProspectCustomizeItem: PoolProspectCustomizeItem;
}

export default ({prospect, poolProspectCustomizeItem}: Props) => {
  const customizeItem = prospect.customizeItemSelects.find(
    (item) => item.poolProspectCustomizeItemId === poolProspectCustomizeItem.id,
  );

  const options = customizeItem
    ? poolProspectCustomizeItem.selectOptions.filter(
        (option) =>
          customizeItem?.poolProspectCustomizeItemSelectOptionIds.includes(
            option.id,
          ),
      )
    : [];

  const [optionName, setOptionName] = useState('');
  const [isPopoverVisible, setIsPopoverVisible] = useState(null);

  const [createCustomizeItem] = useCreateProspectCustomizeItemSelectMutation();
  const [destroyCustomizeItem] =
    useDestroyProspectCustomizeItemSelectMutation();

  const alreadyNameExist = useMemo(
    () =>
      poolProspectCustomizeItem.selectOptions?.some(
        (option) => option.label === optionName,
      ),
    [poolProspectCustomizeItem.selectOptions, optionName],
  );

  const onChange = useCallback((e: any) => setOptionName(e.target.value), []);

  const onCreate = () => {
    if (!!optionName && !alreadyNameExist) {
      createCustomizeItem({
        variables: {
          uuid: prospect.uuid,
          poolProspectCustomizeItemId: poolProspectCustomizeItem.id,
          value: optionName,
        },
        refetchQueries: ['poolProspectCustomizeItems'],
      });
    }
  };

  const onRemove = (option: PoolCustomizeItemSelectOption) => {
    destroyCustomizeItem({
      variables: {
        uuid: prospect.uuid,
        poolCustomizeItemSelectOptionId: option.id,
      },
      refetchQueries: ['poolProspectCustomizeItems'],
    });
  };

  return (
    <Container>
      <Popover
        content={
          <SelectOption
            prospect={prospect}
            poolProspectCustomizeItem={poolProspectCustomizeItem}
            optionName={optionName}
            setOptionName={setOptionName}
          />
        }
        trigger="click"
        onVisibleChange={(visible) => setIsPopoverVisible(visible)}
        visible={isPopoverVisible}
        placement="bottom"
        overlayStyle={{
          width: '300px',
        }}>
        <InputBox>
          {options.map((option) => (
            <CreatedTag key={option.id}>
              <span>{option.label}</span>
              <CrossWrapper onClick={() => onRemove(option)}>
                <Cross />
              </CrossWrapper>
            </CreatedTag>
          ))}

          <InputWrapper>
            <TagInput
              type="search"
              name="search"
              bordered={false}
              placeholder={options.length === 0 ? '未入力' : ''}
              defaultValue={''}
              value={optionName}
              onChange={onChange}
              onPressEnter={() => {
                onCreate;
                setOptionName('');
              }}
            />
          </InputWrapper>
        </InputBox>
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  h3 {
    font-size: 10px;
    color: var(--text-color-2);
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 34px;
  padding: 6px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    border: 1px solid #e1e6eb;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  width: auto;
  background: transparent;
  font-size: inherit;
  line-height: 16px;
  flex: 1 1 60px;
  min-width: 60px;
`;

const TagInput = styled(Input)`
  border: none;
  padding: 0px;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: var(--text-color-3);
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;

const CreatedTag = styled.span`
  margin-right: 6px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #f3f5f7;
  border-radius: 2px;
  span {
    font-size: 12px;
    line-height: 13px;
    color: var(--text-color-1);
  }
  svg {
    margin-left: 6px;
    width: 7px;
    height: 7px;
    path {
      stroke-width: 2;
      stroke: black;
    }
  }
`;

const CrossWrapper = styled.span`
  cursor: pointer;
`;
