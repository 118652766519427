import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useRouteMatch, useHistory} from 'react-router-dom';
import addrs from 'email-addresses';
import {MailThread, GmailImportMailThread} from 'api';

interface GmailImport extends MailThread {
  threadable: GmailImportMailThread;
}

interface Props {
  mailThread: GmailImport;
}

export default ({mailThread}: Props) => {
  const {gmailMessage, members} = mailThread.threadable;
  const history = useHistory();
  const {url} = useRouteMatch();
  const participants = React.useMemo(
    () =>
      members
        ? mailThread.threadable.members
            .map((member) => {
              const addr: any = addrs.parseOneAddress(member);
              return addr.name || addr.local;
            })
            .join(', ')
        : '',
    [members],
  );

  return (
    <Container
      onClick={() =>
        history.push(`${url}/threads/${mailThread.uuid}${location.search}`)
      }>
      <h4>
        <div>{participants}</div>
        <span>
          {moment(mailThread.threadUpdatedAt).format('YYYY/MM/DD HH:mm')}
        </span>
      </h4>
      <h5>{gmailMessage.subject}</h5>
      <p>{gmailMessage.snippet}</p>
    </Container>
  );
};

const Container = styled.div`
  border-bottom: solid 1px var(--border-color);
  padding: 1rem 0 1rem 48px;
  cursor: pointer;

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: 12px;
      color: var(--text-color-2);
      font-weight: normal;
    }
  }

  h5 {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  p {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    color: var(--text-color-2);
  }
`;
