import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {
  AutomaticMailStep,
  useStartWorkflowMutation,
  useStopWorkflowMutation,
  useWorkflowsByStatusQuery,
  Workflow,
} from 'api';
import {useParams} from 'react-router';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const {poolId} = useParams<{
    poolId: string;
  }>();
  const {data: {workflowsByStatus = []} = {}} = useWorkflowsByStatusQuery({
    variables: {poolUuid: poolId, status: ['applying', 'removing']},
    fetchPolicy: 'cache-and-network',
    skip: !poolId,
  });

  const invalidAutomaticMailStep = workflow?.steps
    .filter((step) => step.steppable.__typename === 'AutomaticMailStep')
    ?.filter((step: {steppable: AutomaticMailStep}) => {
      const {automaticMail} = step.steppable;
      return (
        !automaticMail.fromEmail ||
        !automaticMail.subject ||
        !automaticMail.bodyText ||
        !automaticMail.bodyHtml
      );
    });

  // TODO: Workflowのバリデーション考える
  const isValidWorkflow = React.useMemo(
    () =>
      workflow?.status === 'draft' &&
      workflow?.steps.find((step) => step.status === 'open') &&
      invalidAutomaticMailStep.length == 0,
    [workflow],
  );

  const options = location.pathname.endsWith('/steps')
    ? {
        variables: {uuid: workflow?.uuid},
        refetchQueries: ['actionCountsByWorkflow'],
      }
    : {variables: {uuid: workflow?.uuid}};

  const [start, {loading}] = useStartWorkflowMutation(options);

  const [stop, {loading: stopLoading}] = useStopWorkflowMutation(options);

  return (
    <div>
      {workflow.status === 'open' ? (
        <StopButton
          htmlType="button"
          loading={stopLoading}
          onClick={() =>
            workflowsByStatus.length > 0
              ? alert('現在、処理中のワークフローがあるため停止できません')
              : confirm('本当に停止しますか？') && stop()
          }>
          停止
        </StopButton>
      ) : (
        <CustomButton
          htmlType="button"
          type="primary"
          disabled={!isValidWorkflow}
          loading={loading}
          onClick={() =>
            workflowsByStatus.length > 0
              ? alert('現在、処理中のワークフローがあるため開始できません')
              : confirm('本当に開始しますか？') && start()
          }>
          開始
        </CustomButton>
      )}
    </div>
  );
};

const CustomButton = styled(Button)`
  height: 36px;
  width: 96px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1rem;
`;

const StopButton = styled(CustomButton)`
  color: var(--primary);
  border: 1px solid var(--primary);
  background: transparent;
`;
