import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LeadpadIcon from '../../../auth/login/LeadpadIcon';

export default () => {
  return (
    <div className="bg-[#F3F5F7]">
      <Container>
        <Content>
          <Header>
            <LeadpadIcon />
            <p className="text-[26px] mb-2">パスワードの再設定完了</p>
            <p className="text-[14px] font-normal text-foreground">
              パスワードの再設定が完了しました。
              <br />
              新しいパスワードで再度ログインしてください。
            </p>
          </Header>
          <div className="text-center py-2 text-[14px]">
            <Link to={`/`}>ログイン画面へ</Link>
          </div>
        </Content>
      </Container>
    </div>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  &&& {
    margin-top: -100px;
    padding: 40px;
    box-sizing: content-box;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 40px;
`;
