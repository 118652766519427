import React, {useMemo, useState} from 'react';
import {PencilSquareIcon, CheckIcon} from '@heroicons/react/20/solid';
import {Menu, Transition, Popover} from '@headlessui/react';
import {
  useAccountPoolQuery,
  PreleadTag,
  useAddPreleadTagAccountPoolMutation,
  useRemovePreleadTagAccountPoolMutation,
  usePublicPreleadTagsQuery,
} from 'api';
import {useParams} from 'react-router-dom';
import {Input} from 'components/antd';
import New from './New';
import Edit from './Edit';
import {MoreVertical} from 'components/Ui/Icon';

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

const Tag = () => {
  const {accountId} = useParams<{
    poolId: string;
    accountId: string;
  }>();
  const [tagName, setTagName] = useState('');

  const {data: {accountPool = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const {data: {publicPreleadTags = []} = {}} = usePublicPreleadTagsQuery();

  const [addPreleadTagAccountPool] = useAddPreleadTagAccountPoolMutation({
    refetchQueries: ['accountPool'],
  });

  const [removePreleadTagAccountPool] = useRemovePreleadTagAccountPoolMutation({
    refetchQueries: ['accountPool'],
  });

  const onChange = React.useCallback(
    (e: any) => setTagName(e.target.value),
    [],
  );
  const filteredTags = useMemo(
    () =>
      publicPreleadTags
        ?.filter((tag) => kanaToHira(tag.name).includes(kanaToHira(tagName)))
        ?.sort((taga, tagb) =>
          accountPool.preleadTags?.some(
            (preleadTag) => preleadTag.id === taga.id,
          ) &&
          accountPool.preleadTags?.some(
            (preleadTag) => preleadTag.id !== tagb.id,
          )
            ? -1
            : 1,
        ),
    [publicPreleadTags, tagName, accountPool.preleadTags],
  );

  const selectedTags: PreleadTag[] = accountPool.preleadTags;
  const isSelected = (preleadTag: PreleadTag) =>
    selectedTags.map((tag) => tag.id).includes(preleadTag.id);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-c-light">タグ</div>
          <div className="flex gap-2 flex-wrap items-center max-h-[12em] overflow-y-auto">
            {selectedTags?.map((tag) => {
              return (
                <span
                  key={tag.id}
                  style={{background: tag.color}}
                  className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white">
                  {tag.name}
                </span>
              );
            })}
          </div>
        </div>
        <Menu.Button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
          <PencilSquareIcon className="w-5 h-5 text-c-primary" />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute origin-top-right rounded bg-white shadow z-10 w-[calc(100%-3rem)] right-12 top-12 p-4 max-h-[250px] overflow-scroll">
          <div className="flex flex-wrap w-full min-h-[34px] bg-white border border-[#e1e6eb] box-border mb-2">
            <div className="flex items-center border-none w-auto bg-transparent text-inherit leading-4 min-w-[80px]">
              <Input
                type="search"
                name="search"
                bordered={false}
                placeholder={tagName}
                defaultValue={''}
                value={tagName}
                onChange={onChange}
                autoComplete="off"
                onKeyDown={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            </div>
          </div>
          {filteredTags?.map((preleadTag) => (
            <Menu.Item key={preleadTag.id}>
              {({active}) => (
                <div
                  onClick={(e) => {
                    if (isSelected(preleadTag)) {
                      removePreleadTagAccountPool({
                        variables: {
                          accountPoolId: accountPool.id,
                          preleadTagId: preleadTag.id,
                        },
                      });
                    } else {
                      addPreleadTagAccountPool({
                        variables: {
                          accountPoolId: accountPool.id,
                          preleadTagId: preleadTag.id,
                        },
                      });
                    }
                    setTagName('');
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  className={`${
                    active ? 'bg-c-bg' : ''
                  } w-full flex items-center gap-2 py-1 px-2 cursor-pointer`}>
                  <CheckIcon
                    className={`w-4 h-4 ${isSelected(preleadTag) ? 'text-c-primary' : 'text-transparent'}`}
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex text-sm font-normal m-0">
                      <span
                        style={{background: preleadTag.color}}
                        className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white overflow-hidden whitespace-nowrap text-ellipsis">
                        {preleadTag.name}
                      </span>
                      <Popover className="relative ml-auto">
                        {({open}) => (
                          <>
                            <Popover.Button
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="flex justify-center items-center rounded p-0 bg-transparent border-none cursor-pointer hover:bg-slate-200">
                              <MoreVertical className="w-4 h-4 transform rotate-90" />
                            </Popover.Button>
                            {open && (
                              <Popover.Panel className=" absolute z-10 right-0 bottom-0 bg-white shadow-lg rounded">
                                <Edit preleadTag={preleadTag} />
                              </Popover.Panel>
                            )}
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </div>
              )}
            </Menu.Item>
          ))}
          <New tagName={tagName} setTagName={setTagName} />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Tag;
