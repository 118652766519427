import React from 'react';
import {useParams} from 'react-router-dom';
import {useTable} from 'react-table';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import Cell from './Cell';
import {ProspectPool, useProspectPoolsByWorkflowQuery} from 'api';

const PER_PAGE = 20;

export default () => {
  const [page, setPage] = React.useState(1);
  const {workflowId} = useParams<{workflowId: string}>();
  const {
    data: {
      prospectPoolsByWorkflow: {prospectPools = [], pagination = {}} = {},
    } = {},
  } = useProspectPoolsByWorkflowQuery({
    variables: {
      uuid: workflowId,
      page,
      perPage: PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'リード',
        Cell: Cell.Name,
      },
      {
        id: 'stage',
        Header: 'ステージ',
        Cell: Cell.Stage,
      },
      {
        id: 'leadSource',
        Header: 'リードソース',
        Cell: Cell.LeadSource,
      },
      {
        id: 'lastAction',
        Header: '前回のアクション',
        Cell: Cell.LastAction,
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<ProspectPool>({columns, data: prospectPools || []});

  return (
    <Container>
      <ContentsTable {...getTableProps()} cellSpacing="0" cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </ContentsTable>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={PER_PAGE}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentsTable = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 0.5rem 1rem;
    margin: 0px;

    &:first-child {
      padding-left: 0;
    }
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: var(--text-color-2);
        text-align: left;
        border-bottom: solid 1px #e1e6eb;

        &:first-child {
          padding: 3px 1rem 3px 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: solid 1px #e1e6eb;
        cursor: pointer;
      }
    }
  }
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;
