import React from 'react';
import {Button, Tooltip} from 'antd';
import {Copy} from 'components/Ui/Icon';

type Props = {
  isVisible: boolean;
};

export const CopyToolTip = ({isVisible}: Props) => (
  <Tooltip
    overlayInnerStyle={{
      borderRadius: '4px',
      background: '#000000',
      opacity: 0.7,
      padding: '8px 16px',
    }}
    title="コピー"
    placement="top"
    trigger="click"
    visible={isVisible}>
    <Button
      className="shadow-none bg-transparent"
      icon={<Copy width="14" height="14" color="var(--text-color-3)" className="copy" />}
    />
  </Tooltip>
);
