import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.93359 10.0672L10.1336 5.86719"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5333 9.00091L12.8 7.73425C13.4 7.13425 13.7333 6.33425 13.7333 5.46758C13.7333 4.60091 13.4 3.80091 12.8 3.20091C12.2 2.60091 11.4 2.26758 10.5333 2.26758C9.66667 2.26758 8.86667 2.60091 8.26667 3.20091L7 4.46758"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33307 11.2669L7.79974 12.8003C7.19974 13.4003 6.39974 13.7336 5.53307 13.7336C4.66641 13.7336 3.86641 13.4003 3.26641 12.8003C1.99974 11.5336 1.99974 9.46693 3.26641 8.20026L4.53307 6.93359"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
