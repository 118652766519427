import {useUpdateAssigneesMutation, useUsersByClientAllQuery} from 'api';
import {Button, Modal, Select} from 'components/antd';
import React, {useState} from 'react';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import checkedActionIdsAtom from '../checkedActionIdsAtom';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const [userId, setUserId] = useState(null);
  const [checkedActionIds, setCheckActionIds] =
    useRecoilState(checkedActionIdsAtom);

  const [updateAssignees] = useUpdateAssigneesMutation({
    variables: {uuids: checkedActionIds, userId: userId},
    refetchQueries: ['actions'],
    onCompleted: () => {
      setCheckActionIds([]);
      setUserId(null);
      setIsModalVisible(false);
    },
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  return (
    <Modal
      style={{width: '190px'}}
      destroyOnClose
      visible={isModalVisible}
      footer={null}
      onCancel={() => setIsModalVisible(false)}
      centered>
      <Content>
        <h3>ワークフロー担当者を設定</h3>
        <Count>
          {`対象件数: ${
            checkedActionIds?.length &&
            checkedActionIds?.length.toLocaleString()
          }件`}
        </Count>
        <CustomSelect
          value={userId}
          onChange={(value) => setUserId(value)}
          optionFilterProp="children"
          showSearch>
          {users?.map((user) => (
            <Select.Option value={user.id} key={user.id}>
              {user?.lastName} {user.firstName}
            </Select.Option>
          ))}
        </CustomSelect>
        <Action>
          <Button
            type="primary"
            disabled={!(userId && checkedActionIds.length)}
            onClick={() => updateAssignees()}>
            変更
          </Button>
        </Action>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  h3 {
    font-size: 14px;
    color: var(--text-color-2);
    margin-bottom: 1rem;
  }
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin-top: 1rem;
`;

const Action = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: end;
`;

const Count = styled.span`
  color: var(--text-color-2);
  background: #f3f5f7;
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  font-size: 14px;
`;
