import React from 'react';
import styled from 'styled-components';
import {Modal} from 'components/antd';
import {Button, Checkbox} from 'components/Ui';
import {
  Project,
  useClientAllProjectsQuery,
  useApplyBlockGroupMutation,
  useCurrentClientQuery,
} from 'api';
import {useParams} from 'react-router';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default ({visible, onClose}: Props) => {
  const {blockGroupId} = useParams<{blockGroupId: string}>();
  const [clientPreleadStatusIds, setClientPreleadStatusIds] = React.useState([
    '0',
  ]);
  const [projectIds, setProjectIds] = React.useState([]);

  const {data: {clientAllProjects: {projects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        search: {
          status: 'open',
          preleadCreateStatus: 'success',
        },
      },
    });

  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const handleClose = () => {
    setClientPreleadStatusIds(['0']);
    setProjectIds([]);
    onClose();
  };

  const [applyBlockGroup, {loading}] = useApplyBlockGroupMutation({
    onCompleted: handleClose,
    refetchQueries: ['blockGroup', 'currentUser'],
  });

  const handleStatusChange = React.useCallback(
    (e: any) => {
      const newStatuses = e.target.checked
        ? Array.from(new Set([...clientPreleadStatusIds, e.target.name]))
        : clientPreleadStatusIds.filter((val: string) => val !== e.target.name);
      setClientPreleadStatusIds(newStatuses);
    },
    [clientPreleadStatusIds],
  );

  const handleProjectIdsChange = React.useCallback(
    (e: any) => {
      const newProjectIds = e.target.checked
        ? Array.from(new Set([...projectIds, e.target.name]))
        : projectIds.filter((val: string) => val !== e.target.name);
      setProjectIds(newProjectIds);
    },
    [projectIds],
  );

  const selectAllProjects = () => {
    const newProjectIds = projects.map((project: Project) => project.id);
    setProjectIds(newProjectIds);
  };

  const handleSubmit = () => {
    applyBlockGroup({
      variables: {
        uuid: blockGroupId,
        attributes: {
          projectIds: projectIds,
          clientPreleadStatusIds: clientPreleadStatusIds,
        },
      },
    });
  };

  return (
    <ReflectModal
      destroyOnClose
      title={<Title>営業リストに反映</Title>}
      maskClosable={false}
      visible={visible}
      width={540}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0}}
      footer={[
        <CustomButton
          key="submit"
          disabled={projectIds.length < 1 || clientPreleadStatusIds.length < 1}
          onClick={handleSubmit}
          loading={loading}>
          反映する
        </CustomButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <ProjectColumn>
          <h2>営業リスト</h2>
          <div>
            <ProjectList>
              {projects?.map((project: Project) => (
                <ProjectBox key={project.uuid}>
                  <Checkbox
                    name={project.id}
                    checked={projectIds.includes(project.id)}
                    onChange={handleProjectIdsChange}>
                    <ProjectName>{project.name}</ProjectName>
                    <ProjectCount>
                      {project.count.toLocaleString()}社
                    </ProjectCount>
                  </Checkbox>
                </ProjectBox>
              ))}
              <Space></Space>
            </ProjectList>

            <ActionButtons>
              <CustomButton buttonType="outlined" onClick={selectAllProjects}>
                全選択
              </CustomButton>
              <ClearButton
                buttonType="outlined"
                onClick={() => setProjectIds([])}>
                一括クリア
              </ClearButton>
            </ActionButtons>
          </div>
        </ProjectColumn>

        <StatusColumn>
          <h2>ステータス</h2>
          <div>
            <StatusBox>
              <Checkbox
                name={'0'}
                checked={clientPreleadStatusIds.includes('0')}
                onChange={handleStatusChange}>
                <StatusName>未</StatusName>
              </Checkbox>
            </StatusBox>

            {preleadStatuses.map((preleadStatus) => (
              <StatusBox key={preleadStatus.id}>
                <Checkbox
                  name={preleadStatus.id}
                  checked={clientPreleadStatusIds.includes(preleadStatus.id)}
                  onChange={handleStatusChange}>
                  <StatusName>{preleadStatus.name}</StatusName>
                </Checkbox>
              </StatusBox>
            ))}
          </div>
        </StatusColumn>
      </ModalContent>
    </ReflectModal>
  );
};

const ReflectModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      border: none;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 86px;
  height: 24px;

  span {
    font-weight: bold;
    font-size: 10px;
  }
`;

const ClearButton = styled(CustomButton)`
  border: 1px solid var(--text-color-3);
  &:hover {
    background: #fff;
    border: 1px solid var(--text-color-3);
  }
  span {
    color: var(--text-color-3);
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 17.5px;
  line-height: 25px;
`;

const ModalContent = styled.div`
  display: flex;
`;

const Column = styled.div`
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-3);
  }
  > div {
    display: flex;
    flex-direction: column;
    padding: 15px 0px 15px 40px;
    height: 250px;
    border-right: 1px solid #e7eaec;
    border-top: 1px solid #e7eaec;

    position: relative;
    overflow: scroll;
  }
`;

const ProjectColumn = styled(Column)`
  flex: 1;
`;

const StatusColumn = styled(Column)`
  width: 175px;
`;

const ActionButtons = styled.div`
  position: absolute;
  right: 0;
  bottom: 15px;
  display: flex;
  margin: auto 15px 0 auto;
  > button {
    margin-left: 10px;
  }
`;

const ProjectList = styled.div`
  overflow: scroll;
`;

const ProjectBox = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const Space = styled.div`
  height: 20px;
`;

const ProjectName = styled.div`
  display: inline-block;
  margin: 0 20px 0 10px;
  width: 200px;
  font-size: 10px;
`;

const ProjectCount = styled.div`
  display: inline-block;
  font-size: 10px;
`;

const StatusBox = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const StatusName = styled.span`
  font-size: 10px;
  margin-left: 10px;
`;
