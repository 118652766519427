import React from 'react';
import styled from 'styled-components';
import {Switch, Route} from 'react-router-dom';
import usePools from 'hooks/usePools';
import SideMenu from './Sidemenu';
import LeadSources from './leadSources';
import Menu from '../../Menu';
import {ArrowRight} from 'components/Ui/Icon';
import {useHistory} from 'react-router-dom';

export default () => {
  const {selectedPool} = usePools();
  const history = useHistory();

  if (!selectedPool) return null;

  return (
    <Body>
      <Menu />
      <BodyContainer>
        <BackButton
          onClick={() => {
            history.push(`/settings/pools`);
          }}>
          <ArrowRight /> リードプール一覧に戻る
        </BackButton>
        <BodyHeader>{selectedPool.name}</BodyHeader>
        <Contents>
          <SideMenu />
          <Switch>
            <Route
              path="/settings/pools/:poolId/lead_sources"
              component={LeadSources}
            />
          </Switch>
        </Contents>
      </BodyContainer>
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  background: #fff;
`;

const BodyContainer = styled.div`
  padding: 20px 30px;
  background: #f3f5f7;
  width: 100%;
  overflow-y: scroll;
`;

const BodyHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
`;

const Contents = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  min-height: 657px;
  display: flex;
`;

const BackButton = styled.div`
  display: inline-block;
  color: var(--primary);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    transform: rotate(-180deg);

    path {
      stroke: var(--primary);
    }
  }
`;
