import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import {useQueryParams} from 'use-query-params';
import {Button, Form, Input} from 'components/antd';
import {
  companiesQueryParamsDef,
  companiesSearchParams,
  resetCompaniesSearchParams,
} from './companiesQueryParams';
import {useCompaniesQuery} from 'api';

export default () => {
  const [query, setQuery] = useQueryParams(companiesQueryParamsDef);

  const {loading} = useCompaniesQuery({
    variables: {
      page: query.page,
      search: companiesSearchParams(query),
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page: query.page || 1,
      corpNumber: query.corpNumber || '',
      name: query.name || '',
      address: query.address || '',
      telephoneNumber: query.telephoneNumber || '',
      webSite: query.webSite || '',
    },
    onSubmit: (values) => {
      setQuery({...values, ...{page: 1}});
    },
  });

  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    if (isFirstLoading && loading) {
      setIsFirstLoading(true);
    } else {
      setIsFirstLoading(false);
    }
  }, [isFirstLoading && loading]);

  const {isValid, handleSubmit, resetForm} = formik;

  return (
    <Container>
      <h2>企業情報を入力して企業を特定する</h2>
      <Form onFinish={handleSubmit}>
        <Form.Item label="会社名">
          <Input
            name="name"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
        </Form.Item>

        <Form.Item label="住所">
          <Input
            name="address"
            value={formik.values.address}
            onChange={(e) => formik.setFieldValue('address', e.target.value)}
          />
        </Form.Item>

        <Form.Item label="電話番号">
          <Input
            name="telephoneNumber"
            value={formik.values.telephoneNumber}
            onChange={(e) =>
              formik.setFieldValue('telephoneNumber', e.target.value)
            }
          />
        </Form.Item>

        <Form.Item label="ウェブサイト(URL)">
          <Input
            name="webSite"
            value={formik.values.webSite}
            onChange={(e) => formik.setFieldValue('webSite', e.target.value)}
          />
        </Form.Item>

        <Form.Item label="法人番号">
          <Input
            name="corpNumber"
            value={formik.values.corpNumber}
            onChange={(e) => {
              formik.setFieldValue('corpNumber', e.target.value);
            }}
          />
        </Form.Item>

        <ButtonWrapper>
          <Button
            loading={loading}
            onClick={() => {
              setQuery(resetCompaniesSearchParams);
              resetForm();
            }}>
            リセット
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={isFirstLoading ? undefined : loading}
            disabled={
              !formik.dirty ||
              !isValid ||
              (isFirstLoading ? undefined : loading)
            }>
            検索
          </Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 0 20px 42px;
  border-bottom: 1px solid var(--border-color);

  h2 {
    margin: 0 0 10px 0;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    color: var(--text-color-1);
  }

  form {
    display: flex;
    .ant-form-item {
      margin: 0 18px 0 0;
      display: block;

      .ant-form-item-label {
        display: inline;
        label {
          font-size: 10px;
          line-height: 14px;
          height: 0px;
          color: var(--text-color-2);
          &:after {
            display: none;
          }
        }
      }
      .ant-form-item-control {
        max-width: 100% !important;
      }
      input {
        width: 150px;
        border: 1px solid var(--border-color);
        border-radius: 4px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  button:first-child {
    margin-right: 14px;
  }
`;
