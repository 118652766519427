import React from 'react';
import {useHistory} from 'react-router';
import Modal from 'react-modal';
import styled from 'styled-components';
import {message} from 'components/antd';
import {User, Team, useTeamQuery, useRemoveUserFromTeamMutation} from 'api';
import {useParams} from 'react-router';

type Props = {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
  user: User;
};

export default ({isOpen, setModal, user}: Props) => {
  const {teamId} = useParams<{teamId: string}>();
  const history = useHistory();

  const {data: {team = null} = {}} = useTeamQuery({
    variables: {uuid: teamId},
    fetchPolicy: 'cache-and-network',
  });

  const [removeUserFromTeam] = useRemoveUserFromTeamMutation({
    refetchQueries: ['usersByClient'],
    onCompleted: (data: any) => {
      if (!data?.removeUserFromTeam?.error) {
        setModal(false);
        message.success('ユーザをチームから削除しました');
        history.push(`/settings/teams/${teamId}/users`);
      }
    },
  });

  const removeFromTeam = (user: User, team: Team) => {
    removeUserFromTeam({
      variables: {
        userId: user.id,
        teamId: team.id,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '4px',
          width: '468px',
          height: '125px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
        },
      }}
      contentLabel="modal">
      <ModalContent>
        <ModalTitle>ユーザ削除</ModalTitle>
        <ModalText>チームからこのユーザを削除しますか？</ModalText>
        <ButtonWrapper>
          <CancelButton onClick={() => setModal(false)}>
            キャンセル
          </CancelButton>
          <InviteButton onClick={() => removeFromTeam(user, team)}>
            削除
          </InviteButton>
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};

const ModalTitle = styled.h1`
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-3);
`;

const ModalText = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  margin-bottom: 13px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px 21px 16px 21px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 26px;
`;

const CancelButton = styled.div`
  display: block;
  width: 78px;
  height: 26px;
  padding: 6px 12px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
  text-align: center;
  outline: 0;
  height: auto;
  cursor: pointer;
  margin-right: 10px;
`;

const InviteButton = styled.div`
  display: block;
  width: 78px;
  height: 26px;
  padding: 6px 12px;
  background: #b55d62;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #ffffff;
  text-align: center;
  outline: 0;
  height: auto;
  cursor: pointer;
`;
