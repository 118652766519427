import {ProspectPool} from 'api';
import React from 'react';
import {Row} from 'react-table';

interface Props {
  row: Row<ProspectPool>;
}

const SalesforceUser = ({row}: Props) => {
  const prospectPool = row.original;

  const salesforceUser =
    prospectPool.salesforceContact?.salesforceOwner ||
    prospectPool.salesforceLead?.salesforceOwner;

  if (!salesforceUser) {
    return <></>;
  }

  return (
    <div>
      {[salesforceUser.lastName, salesforceUser.firstName]
        .filter(Boolean)
        .join(' ')}
    </div>
  );
};

export default SalesforceUser;
