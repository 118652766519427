import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, MailCommentProspectActivity} from 'api';
import {ProspectCommentBadge} from 'components/Ui/Icon';
import linkifyHtml from 'linkify-html';

interface CommentActivity extends ProspectActivity {
  resource: MailCommentProspectActivity;
}

interface Props {
  prospectActivity: CommentActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {commentHtml, createdAt},
    user,
  } = prospectActivity;

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <ProspectCommentBadge />
      <Content>
        <ContentHeader>
          <h3>メールコメント</h3>
          <span>{user && [user.lastName, user.firstName].join(' ')}</span>
          <time>{moment(createdAt).format('YYYY/MM/DD HH:mm')}</time>
        </ContentHeader>
        <ContentBox>
          <div
            dangerouslySetInnerHTML={{__html: convertUrlToLink(commentHtml)}}
          />
        </ContentBox>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    font-weight: bold;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px var(--border-color);
  border-radius: 4px;
  padding: 15px;

  * {
    margin-bottom: 0;
  }
`;
