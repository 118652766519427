import {useCurrentClientQuery, useCurrentUserQuery} from 'api';
import React from 'react';
import {useRouteMatch, Link, useParams} from 'react-router-dom';

export default () => {
  const active = 'font-bold text-foreground bg-[#FFFFFF]';
  const inActive = 'hover:text-foreground hover:bg-[#FAFBFB] text-foreground-lighter';

  const {path} = useRouteMatch();
  const {poolId, accountId} = useParams<{poolId: string; accountId: string}>();
  const basePath = `/pools/${poolId}/accounts/${accountId}`;
  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});
  const {data: {currentClient: {currentLeadContract} = {}} = {}} =
    useCurrentClientQuery();

  return (
    <div className="flex my-4">
      <div className="flex border-none bg-[#EBEDF2] p-1 cursor-pointer">
        <Link to={`${basePath}`}>
          <div
            className={`flex rounded-sm px-6 py-1 ${useRouteMatch(`${path}`)?.isExact ? active : inActive}`}>
            概要
          </div>
        </Link>
        <Link to={`${basePath}/lead`}>
          <div
            className={`flex rounded-sm px-6 py-1 ${useRouteMatch(`${path}/lead`)?.isExact ? active : inActive}`}>
            リード
          </div>
        </Link>
        {(currentUser?.role === 'admin' ||
          currentLeadContract?.keyPersonDisplayable) && (
          <Link to={`${basePath}/contact`}>
            <div
              className={`flex rounded-sm px-6 py-1 ${useRouteMatch(`${path}/contact`)?.isExact ? active : inActive}`}>
              キーマン情報
            </div>
          </Link>
        )}
        {currentUser?.role === 'admin' && (
          <>
            <Link to={`${basePath}/deal`}>
              <div
                className={`flex rounded-sm px-6 py-1 ${useRouteMatch(`${path}/deal`)?.isExact ? active : inActive}`}>
                商談
              </div>
            </Link>
            <Link to={`${basePath}/signal`}>
              <div
                className={`flex rounded-sm px-6 py-1 ${useRouteMatch(`${path}/signal`)?.isExact ? active : inActive}`}>
                シグナル
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
