import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  ManualMailOpenProspectActivity,
  useDestroyManualMailOpenProspectActivityMutation,
} from 'api';
import {ManualMail, DotMenuSide, Trash} from 'components/Ui/Icon';
import {Menu, Dropdown} from 'components/antd';

interface ManualMailOpenActivity extends ProspectActivity {
  resource: ManualMailOpenProspectActivity;
}

interface Props {
  prospectActivity: ManualMailOpenActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      manualMail: {action},
    },
  } = prospectActivity;

  const [destroy] = useDestroyManualMailOpenProspectActivityMutation({
    refetchQueries: ['prospectPool'],
  });

  return (
    <>
      <ManualMail width={'32px'} height={'32px'} active={true} />
      <Content>
        <ContentHeader>
          <h3>{action?.step?.name}</h3>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
          <MenuContainer>
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem
                    onClick={() =>
                      confirm('本当に削除しますか？') &&
                      destroy({
                        variables: {
                          uuid: prospectActivity.uuid,
                        },
                      })
                    }>
                    <Trash />
                    削除
                  </MenuItem>
                </Menu>
              }
              trigger={['click']}>
              <span>
                <DotMenuSide />
              </span>
            </Dropdown>
          </MenuContainer>
        </ContentHeader>
        <Wrapper>開封</Wrapper>
      </Content>
    </>
  );
};
const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: var(--primary);
    font-weight: bold;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Wrapper = styled.div`
  color: var(--text-color-2);
`;

const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
    svg {
      width: 15px;
      heighht: auto;
      margin-right: 0.5rem;
    }
    &:hover {
      color: var(--primary);
      svg {
        path {
          stroke: var(--primary);
        }
      }
    }
  }
`;
