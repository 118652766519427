import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {MailThread, AutomaticMailSendMailThread} from 'api';
import StepTypeIcon from 'components/Workflow/Icon/StepTypeIcon';

interface AutomaticMailSend extends MailThread {
  threadable: AutomaticMailSendMailThread;
}

interface Props {
  mailThread: AutomaticMailSend;
}

export default ({mailThread}: Props) => {
  const {automaticMailSend, workflow, step} = mailThread.threadable;
  const history = useHistory();
  const {url} = useRouteMatch();

  return (
    <Container
      onClick={() =>
        history.push(`${url}/threads/${mailThread.uuid}${location.search}`)
      }>
      <StepTypeIcon step={step} />
      <div>
        <h4>
          <div>{workflow.name}</div>
          <span>
            {moment(mailThread.threadUpdatedAt).format('YYYY/MM/DD HH:mm')}
          </span>
        </h4>
        <h5>{step.name}</h5>
        <p>{automaticMailSend.bodyText}</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  border-bottom: solid 1px var(--border-color);
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  & > svg {
    width: 40px;
  }

  & > div {
    overflow: hidden;
    flex: 1;
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: 12px;
      color: var(--text-color-2);
      font-weight: normal;
    }
  }

  h5 {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  p {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    color: var(--text-color-2);
  }
`;
