import React from 'react';
import {
  MergeProspectProspectPool,
  useUpdateTargetOfMergeProspectProspectPoolMutation,
  useUpdateProspectPoolMergeProspectMutation,
} from 'api';
import {ArrowUpIcon, AddIcon} from './Icon';
import Prospect from './Prospect';

type Props = {
  mergeProspectProspectPool: MergeProspectProspectPool;
};

export default ({mergeProspectProspectPool}: Props) => {
  const prospectPool = mergeProspectProspectPool.prospectPool;
  const [updateTarget] = useUpdateTargetOfMergeProspectProspectPoolMutation({
    variables: {
      id: mergeProspectProspectPool.id,
      isTarget: !mergeProspectProspectPool.isTarget,
    },
  });

  const [updateProspectPoolMergeProspect] =
    useUpdateProspectPoolMergeProspectMutation({
      variables: {
        mergeProspectProspectPoolId: mergeProspectProspectPool.id,
      },
    });

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="block min-w-full pt-2">
          <div className="overflow-hidden">
            <Prospect prospectPool={prospectPool} />
            <div className="h-14 w-full border border-t-0 text-sm border-[#bec3cb] flex items-center justify-center gap-4">
              <button
                className="bg-white border border-foreground-lighter text-foreground-light font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer gap-1"
                onClick={() => updateProspectPoolMergeProspect()}>
                <ArrowUpIcon />
                <span>名寄せ先にする</span>
              </button>
              {mergeProspectProspectPool.canMerge && (
                <button
                  className="bg-white border border-foreground-lighter text-foreground-light font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer gap-1"
                  onClick={() => updateTarget()}>
                  <AddIcon />
                  <span>名寄せ対象にする</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
