import React from 'react';
import styled from 'styled-components';
import MailStatusCountReport from './mailStatusCount';
import SendCountReport from './sendCount';
import UrlClickReport from './urlClick';
import {useParams, Route, Switch, Link} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();

  return (
    <Container>
      <Tabs>
        <TabItem
          active={location.pathname.includes(
            'form_mail_reports/all/send_count',
          )}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/all/send_count`,
            )}>
            送信数
          </Link>
        </TabItem>
        <TabItem
          active={location.pathname.includes('form_mail_reports/all/click')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/all/click`,
            )}>
            URL別クリック数
          </Link>
        </TabItem>
        <TabItem
          active={location.pathname.includes('form_mail_reports/all/approach')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/all/approach`,
            )}>
            アプローチ結果
          </Link>
        </TabItem>
      </Tabs>

      <Switch>
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/all/send_count',
          )}
          component={SendCountReport}
        />
        <Route
          path={createPath('projects/:projectId/form_mail_reports/all/click')}
          component={UrlClickReport}
        />
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/all/approach',
          )}
          component={MailStatusCountReport}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  padding: 30px;
  min-width: 1200px;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
