import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5789 2H2.42105L1 6.82759C1.47368 9.72414 5.26316 9.72414 5.73684 6.82759C6.21053 9.72414 9.52632 9.72414 10 6.82759C10.4737 9.72414 14.2632 9.72414 14.7368 6.82759C15.2105 9.72414 18.5263 9.72414 19 6.82759L17.5789 2Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
      />
      <path
        d="M2 9V18H8V13.2629H12V18H18V9"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Icon;
