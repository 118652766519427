import React from 'react';
import styled from 'styled-components';
import New from './New';
import ProspectTimelineItem from 'components/ProspectTimelineItem';
import {ProspectPool, useCurrentUserQuery} from 'api';
import {useLocation, Link} from 'react-router-dom';
import NewComment from './NewComment';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const [isNewMail, setIsNewMail] = React.useState(false);
  const [isNewComment, setIsNewComment] = React.useState(false);
  const location = useLocation<{
    isNew: boolean;
  }>();
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  React.useEffect(() => {
    if (location.state?.isNew) {
      setIsNewMail(true);
    }
  }, [location.state]);

  if (!prospectPool) return null;

  const List = () => (
    <>
      <Actions>
        {currentUser?.role === 'admin' && (
          <div style={{flex: 1}}>
            <Link to={`mail_new${location.search}`}>メールスレッドを表示</Link>
          </div>
        )}
        <div className="ant-btn" onClick={() => setIsNewComment(true)}>
          ＋ コメントを登録
        </div>
        <div className="ant-btn" onClick={() => setIsNewMail(true)}>
          ＋ Eメール作成
        </div>
      </Actions>
      <Timeline>
        {prospectPool.mailSendActivities?.map((prospectActivity) => (
          <ProspectTimelineItem
            key={prospectActivity.uuid}
            prospectActivity={prospectActivity}
          />
        ))}
      </Timeline>
    </>
  );

  return isNewMail ? (
    <New setIsNewMail={setIsNewMail} prospectPool={prospectPool} />
  ) : isNewComment ? (
    <NewComment setIsNewComment={setIsNewComment} prospectPool={prospectPool} />
  ) : (
    <List />
  );
};

const Actions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;

  .ant-btn {
    font-weight: 500;
    color: var(--text-color-2);
    border-radius: 4px;
  }
`;

const Timeline = styled.div`
  margin: 15px 0;
`;
