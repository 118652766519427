import React from 'react';

export default () => {
  return (
    <svg
      className="w-5 h-5"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 4.16675H3.33333C2.8731 4.16675 2.5 4.53984 2.5 5.00008V15.0001C2.5 15.4603 2.8731 15.8334 3.33333 15.8334H16.6667C17.1269 15.8334 17.5 15.4603 17.5 15.0001V5.00008C17.5 4.53984 17.1269 4.16675 16.6667 4.16675Z"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 4.58325L9.99999 10.8333L3.33333 4.58325"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
