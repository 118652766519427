import {
  useCreateAndAddPreleadTagAccountPoolMutation,
  usePublicPreleadTagsQuery,
} from 'api';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {tagColors} from 'helpers/tagColors';

interface Props {
  tagName: string;
  setTagName: React.Dispatch<React.SetStateAction<string>>;
}

export default ({tagName, setTagName}: Props) => {
  const {accountId} = useParams<{
    accountId: string;
  }>();

  const {data: {publicPreleadTags = []} = {}} = usePublicPreleadTagsQuery();

  const [CreateAndAddPreleadTagAccountPool] =
    useCreateAndAddPreleadTagAccountPoolMutation({
      onCompleted: () => {
        setTagName('');
      },
      refetchQueries: ['accountPool', 'currentClient', 'publicPreleadTags'],
    });

  const alreadyNameExist: boolean = useMemo(
    () => publicPreleadTags?.some((tag) => tag.name === tagName),
    [publicPreleadTags, tagName],
  );

  const getRandomColor = (): string => {
    const colors = tagColors.map((color) => color.hex);
    const color = colors[Math.floor(Math.random() * colors.length)];
    return color;
  };
  const randomColor = React.useMemo(
    () => getRandomColor(),
    [publicPreleadTags],
  );

  const onCreate = () => {
    if (!!tagName && !alreadyNameExist) {
      CreateAndAddPreleadTagAccountPool({
        variables: {
          accountPoolUuid: accountId,
          attributes: {
            name: tagName,
            color: randomColor,
          },
        },
      });
    }
  };
  return (
    <>
      {tagName && !alreadyNameExist && (
        <div className="mt-2 border-t border-[#e1e6eb]">
          <h3 className="mb-0 py-2 text-xs text-c-light">新規作成</h3>
          <div
            onClick={onCreate}
            className={`flex items-center cursor-pointer hover:bg-c-bg`}>
            <div className=" w-full flex items-center gap-2 pl-8 py-1 px-2 cursor-pointer">
              <div className="flex flex-col">
                <h4 className="text-sm font-normal m-0">
                  <span
                    style={{background: randomColor}}
                    className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white">
                    {tagName}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
