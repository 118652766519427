import React from 'react';
import styled from 'styled-components';
import {useDestroyClientPreleadCustomizeItemMutation} from 'api';
import {Trash} from 'components/Ui/Icon';

const PopoverContent = (id: string, onClose: () => void) => {
  const [destroyClientPreleadCustomizeItemMutation] =
    useDestroyClientPreleadCustomizeItemMutation();

  return (
    <Content>
      <Item
        onClick={() => {
          onClose;
          destroyClientPreleadCustomizeItemMutation({
            variables: {id: id},
            refetchQueries: ['clientPreleadCustomizeItems'],
          });
        }}>
        <TrashWrapper>
          <Trash color="#495058" />
        </TrashWrapper>
        <div>削除</div>
      </Item>
    </Content>
  );
};

const Content = styled.div`
  .ant-popover-placement-bottomLeft {
    padding-top: 0;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 86px;
  height: 43px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f5f7;
  }
`;

const TrashWrapper = styled.div`
  padding-top: 5px;
`;

export default PopoverContent;
