import React from 'react';
import moment from 'moment';
import {Form, useFormPostQuery} from 'api';
import {useParams, useHistory} from 'react-router-dom';
import {XMarkIcon} from '@heroicons/react/20/solid';
import Block from './Block';

interface Props {
  form: Form;
}

const Post = ({form}: Props) => {
  const history = useHistory();
  const {formId, postId} = useParams<{
    formId: string;
    postId: string;
  }>();

  const {data: {formPost} = {}} = useFormPostQuery({variables: {uuid: postId}});

  const formBlocks = form?.formPages?.flatMap((page) => page.formBlocks) || [];

  const onClose = () => history.push(`/form/forms/${formId}/posts`);

  return (
    <div className="fixed top-0 right-0 z-10">
      <div className="w-[560px] h-screen bg-white p-8 relative shadow-xl overflow-scroll">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h2 className="text-base m-0 font-bold leading-0">{form?.title}</h2>
            <div className="text-sm">
              受信日時:{' '}
              {formPost
                ? moment(formPost?.createdAt).format('YYYY/MM/DD HH:mm')
                : ''}
            </div>
          </div>
          <hr className="border-b border-c-border w-full" />
          {formBlocks.map((block) => (
            <Block key={block.uuid} block={block} post={formPost} />
          ))}
        </div>
      </div>
      <button
        className="p-0 w-8 h-8 flex items-center justify-center absolute top-0 -left-8 bg-white cursor-pointer shadow-[-3px_2px_10px_-3px_rgba(0,0,0,0.1)]"
        onClick={onClose}>
        <XMarkIcon className="text-foreground-lighter w-4 h-4 cursor-pointer hover:text-c-base relative top-[1px]" />
      </button>
    </div>
  );
};

export default Post;
