import React, {useRef} from 'react';
import styled from 'styled-components';
import {
  Form,
  Button,
  Input,
  Select,
  Popover,
  Upload,
  Tooltip,
} from 'components/antd';
import {Link, useParams, useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useCurrentUserQuery,
  usePreleadProjectQuery,
  usePreleadContactsQuery,
  useUsersByClientAllQuery,
  useCreateUserMailActivityMutation,
} from 'api';
import ContentsModal from '../../../../../../../garage/contents/mail_form/MessageForm/ContentsModal';
import {Paperclip, AddPaperFile, Cross} from 'components/Ui/Icon';
import useProjectPath from 'hooks/useProjectPath';
import {Quill} from 'components/Ui';
import useMailTemplate from 'hooks/useMailTempate';

export default () => {
  const {templates, templateTitle} = useMailTemplate();
  const history = useHistory();
  const {createPath} = useProjectPath();
  const [cc, setCc] = React.useState(false);
  const [bcc, setBcc] = React.useState(false);
  const [contentModalVisible, setContentModalVisible] = React.useState(false);
  const [variablePopoverVisible, setVariablePopoverVisible] =
    React.useState(false);
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);

  const ref = useRef(null);

  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const {data: {preleadProject: {prelead = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
    });

  const {data: {preleadContacts = []} = {}} = usePreleadContactsQuery({
    variables: {preleadUuid: prelead.uuid},
    skip: !prelead.uuid,
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const [createUserMail, {loading}] = useCreateUserMailActivityMutation({
    onCompleted: () =>
      history.push(
        createPath(
          `projects/${projectId}/preleads/${preleadProjectId}/mail${location.search}`,
        ),
      ),
    refetchQueries: ['preleadProject'],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fromEmail: currentUser?.email,
      fromName: currentUser.email
        ? `${currentUser?.lastName} ${currentUser?.firstName}`
        : '',
      to: [],
      cc: [],
      bcc: [],
      title: '',
      body: '',
      bodyHtml: '',
      files: [],
    },
    validationSchema: Yup.object().shape({
      fromEmail: Yup.string().email().required(),
      to: Yup.array().ensure().of(Yup.string().email()).min(1),
      title: Yup.string().required(),
      body: Yup.string().required(),
    }),
    onSubmit: (values) =>
      createUserMail({variables: {uuid: preleadProjectId, attributes: values}}),
  });

  const preleadContactsThatHaveEmail = preleadContacts.filter(
    (preleadContact) => preleadContact.email,
  );

  const [selectEmailValue, setSelectEmailValue] = React.useState({
    value: formik.values.fromEmail,
    label: `${formik.values.fromName} <${formik.values.fromEmail}>`,
  });

  React.useEffect(() => {
    if (formik.values.fromEmail !== selectEmailValue.value) {
      const targetUser = users.find(
        (user) => user.email === formik.values.fromEmail,
      );
      const fromName = targetUser.lastName
        ? `${targetUser?.lastName} ${targetUser?.firstName}`
        : '';
      const label = targetUser.lastName
        ? `${fromName} <${formik.values.fromEmail}>`
        : formik.values.fromEmail;

      formik.setFieldValue('fromName', fromName);
      setSelectEmailValue({
        value: formik.values.fromEmail,
        label: label,
      });
    } else {
      setSelectEmailValue({
        value: formik.values.fromEmail,
        label: `${formik.values.fromName} <${formik.values.fromEmail}>`,
      });
    }
  }, [formik.values.fromName, formik.values.fromEmail]);

  const FromName = () => (
    <FromNameContainer>
      <h3>差出人</h3>
      <div style={{marginBottom: 18}}>
        <label style={{color: 'var(--text-color-2)'}}>差出人名</label>
        <Input
          id="fromName"
          name="fromName"
          defaultValue={formik.values.fromName}
        />
      </div>
      <div>
        <Button
          style={{marginRight: '10px'}}
          loading={loading}
          onClick={() => setPopoverVisible(false)}>
          キャンセル
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            const fromNameInput: any = document.getElementById('fromName');
            formik.setFieldValue('fromName', fromNameInput.value);
            setPopoverVisible(false);
          }}>
          更新
        </Button>
      </div>
    </FromNameContainer>
  );

  return (
    <Container>
      <Header>
        新規作成
        <Link
          to={createPath(
            `projects/${projectId}/preleads/${preleadProjectId}/mail${location.search}`,
          )}>
          ╳
        </Link>
      </Header>
      <MailForm onFinish={formik.handleSubmit}>
        <FieldGroup>
          <Field>
            <label>宛先</label>
            <Select
              mode="tags"
              bordered={false}
              style={{width: '100%'}}
              value={formik.values.to}
              onChange={(values: [string]) =>
                formik.setFieldValue(
                  'to',
                  values.filter((value) =>
                    Yup.string().trim().email().isValidSync(value),
                  ),
                )
              }>
              {preleadContactsThatHaveEmail.map((contact) => (
                <Select.Option key={contact.uuid} value={contact.email}>
                  {contact.lastName} {contact.firstName}
                  {`<${contact.email}>`}
                </Select.Option>
              ))}
            </Select>
            {!cc && (
              <Button type="link" onClick={() => setCc(true)}>
                Cc
              </Button>
            )}
            {!bcc && (
              <Button type="link" onClick={() => setBcc(true)}>
                Bcc
              </Button>
            )}
          </Field>
          {cc && (
            <Field>
              <label>Cc</label>
              <Select
                mode="tags"
                bordered={false}
                style={{width: '100%'}}
                value={formik.values.cc}
                onChange={(values: [string]) =>
                  formik.setFieldValue(
                    'cc',
                    values.filter((value) =>
                      Yup.string().trim().email().isValidSync(value),
                    ),
                  )
                }>
                {preleadContactsThatHaveEmail.map((contact) => (
                  <Select.Option key={contact.uuid} value={contact.email}>
                    {contact.lastName} {contact.firstName}
                    {`<${contact.email}>`}
                  </Select.Option>
                ))}
                {users.map((user) => (
                  <Select.Option key={user.uuid} value={user.email}>
                    {user.lastName} {user.firstName}
                    {`<${user.email}>`}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          )}
          {bcc && (
            <Field>
              <label>Bcc</label>
              <Select
                mode="tags"
                bordered={false}
                style={{width: '100%'}}
                value={formik.values.bcc}
                onChange={(values: [string]) =>
                  formik.setFieldValue(
                    'bcc',
                    values.filter((value) =>
                      Yup.string().trim().email().isValidSync(value),
                    ),
                  )
                }>
                {preleadContactsThatHaveEmail.map((contact) => (
                  <Select.Option key={contact.uuid} value={contact.email}>
                    {contact.lastName} {contact.firstName}
                    {`<${contact.email}>`}
                  </Select.Option>
                ))}
                {users.map((user) => (
                  <Select.Option key={user.uuid} value={user.email}>
                    {user.lastName} {user.firstName}
                    {`<${user.email}>`}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          )}
        </FieldGroup>
        <Field>
          <label>差出人</label>
          <Select
            style={{width: '100%', maxWidth: '348px'}}
            bordered={false}
            showSearch={true}
            labelInValue
            value={selectEmailValue}
            onSelect={(value: {value: string; label: string}) => {
              formik.setFieldValue('fromEmail', value.value);
            }}>
            {users.map((user) => (
              <Select.Option key={user.uuid} value={user.email}>
                {user.lastName} {user.firstName}
                {`<${user.email}>`}
              </Select.Option>
            ))}
          </Select>
          <Popover
            trigger="click"
            visible={isPopoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            placement="bottomRight"
            content={() => <FromName />}>
            <Button type="text" style={{color: 'var(--primary)'}}>
              差出人名編集
            </Button>
          </Popover>
        </Field>
        <Field>
          <label>件名</label>
          <Input
            id="title"
            name="title"
            value={formik.values.title}
            bordered={false}
            onChange={formik.handleChange}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          />
        </Field>
        <Body>
          <BodyHeader>
            <label>本文</label>
            <div>
              <Select
                style={{display: 'block'}}
                placeholder="メールテンプレート"
                optionFilterProp="children"
                showSearch
                onSelect={(value: any) => {
                  const template = templates.find(
                    (template) => template.uuid === value,
                  );
                  formik.setValues({
                    ...formik.values,
                    title: template.mailTemplate.title,
                  });
                  template.mailTemplate.bodyHtml
                    ? (document.getElementsByClassName(
                        'ql-editor',
                      )[0].innerHTML = template.mailTemplate.bodyHtml)
                    : ref.current?.editor.setText(template.mailTemplate.body);
                }}>
                {templates.map((template) => (
                  <Select.Option key={template.uuid} value={template.uuid}>
                    {templateTitle(template)}
                  </Select.Option>
                ))}
              </Select>
              <p>{'※{{company}}で会社名が挿入されます'}</p>
            </div>
          </BodyHeader>
          <Quill
            ref={ref}
            theme="snow"
            value={formik.values.bodyHtml}
            onChange={(content, delta, source, editor) => {
              formik.setFieldValue('bodyHtml', content);
              formik.setFieldValue('body', editor.getText());
            }}
            modules={{
              toolbar: [
                [{header: [1, 2, 3, false]}],
                ['bold', 'italic', 'underline'],
                ['code-block'],
                ['link'],
              ],
            }}
            formats={[
              'header',
              'list',
              'bold',
              'italic',
              'underline',
              'strike',
              'color',
              'background',
              'code-block',
              'link',
            ]}
            bounds={'#quill-container'}
          />
          {formik.values.files.map((file) => (
            <File key={file.uid}>
              <FileName>{file.name}</FileName>
              <div
                onClick={() =>
                  formik.setFieldValue(
                    'files',
                    formik.values.files.filter((f) => f !== file),
                  )
                }>
                <Cross />
              </div>
            </File>
          ))}
        </Body>
        <Actions>
          <Upload
            name={'files'}
            customRequest={() => false}
            showUploadList={false}
            onChange={(info) => {
              const addedFile = info.file.originFileObj;
              const upload_size_limit = 25000000; // 25MB

              let fileSize = addedFile.size;
              formik.values.files.forEach((file) => (fileSize += file.size));
              if (fileSize > upload_size_limit) {
                alert(
                  'ファイルの容量が25MBを超えています。\n合計25MBまでの添付が可能です。',
                );
                return null;
              }

              formik.setFieldValue('files', [
                ...formik.values.files,
                addedFile,
              ]);
            }}>
            <Tooltip
              title="ファイルを添付(25MBまで)"
              color={'var(--text-color-0)'}
              placement="top">
              <Button icon={<Paperclip />} style={{marginRight: 10}} />
            </Tooltip>
          </Upload>

          <Popover
            content={
              <ContentsModal
                onClick={(text: string) => {
                  ref.current?.editor.insertText(
                    ref.current?.editor.getSelection().index,
                    text,
                    'link',
                    text,
                  );
                }}
              />
            }
            trigger="click"
            visible={contentModalVisible}
            placement="topRight"
            onVisibleChange={setContentModalVisible}>
            <Button icon={<AddPaperFile />} />
          </Popover>

          <Popover
            content={
              <PopoverContent>
                <PopoverSelectButtonWrapper>
                  <PopoverSelectButton
                    onClick={() => {
                      if (ref.current?.editor.getSelection()) {
                        ref.current?.editor.insertText(
                          ref.current?.editor.getSelection().index,
                          '{{company}}',
                        );
                      } else {
                        const title: any = document.getElementById('title');
                        const value =
                          title.value.substr(0, title.selectionStart) +
                          '{{company}}' +
                          title.value.substr(title.selectionStart);
                        formik.setFieldValue('title', value);
                      }
                    }}>
                    <span>{'{{company}}'}</span>
                    <span>会社</span>
                  </PopoverSelectButton>
                </PopoverSelectButtonWrapper>
              </PopoverContent>
            }
            trigger="click"
            visible={variablePopoverVisible}
            placement="topRight"
            onVisibleChange={setVariablePopoverVisible}>
            <PopoverDisplayButton>{`{ }`}</PopoverDisplayButton>
          </Popover>

          <Button
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid || prelead.company?.isUnsubscribed}
            loading={loading}>
            送信
          </Button>
        </Actions>
      </MailForm>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: solid 1px var(--border-color);
  border-radius: 4px;
`;

const Header = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f3f5f8;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: solid 1px var(--border-color);

  a {
    color: rgba(0, 0, 0, 0.85);
  }
`;

const MailForm = styled(Form)`
  margin: 0 20px;
`;

const Field = styled.div`
  min-height: 45px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e1e6eb;

  label {
    width: 56px;
  }

  > *:nth-child(2) {
    flex: 1;
  }

  button {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const FieldGroup = styled.div`
  ${Field} {
    border: none;
  }

  border-bottom: solid 1px #e1e6eb;
`;

const Body = styled.div`
  padding-bottom: 5px;
  border-bottom: solid 1px #e1e6eb;

  textarea {
    padding: 0;
  }

  .ql-container.ql-snow {
    min-height: 200px;

    .ql-editor {
      min-height: 200px;
      overflow-y: scroll;
      resize: vertical;
    }
  }
`;

const BodyHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  label {
    margin-top: -20px;
  }
  div:first-child {
    padding-top: 10px;
  }
  p {
    font-size: 12px;
    color: var(--text-color-2);
    margin: 0;
  }
`;

const Actions = styled.div`
  padding: 10px 0;
  display: flex;

  button:last-child {
    margin-left: auto;
  }
  button svg {
    position: relative;
    top: 2px;
  }
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;

    path {
      stroke: var(--text-color-2);
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: var(--primary);
`;

const PopoverDisplayButton = styled(Button)`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  color: var(--text-color-2);
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const PopoverSelectButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span: first-child {
    color: var(--text-color-2);
    margin-right: 10px;
  }
  span: last-child {
    color: var(--text-color-1);
  }
`;

const PopoverSelectButton = styled(Button)`
  padding: 0;
  padding-left: 10px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;

const FromNameContainer = styled.div`
  width: 315px;
  padding: 12px;

  h3 {
    font-weight: bold;
    font-size: 14px;
  }
  div:last-child {
    display: flex;
    justify-content: end;
  }
`;
