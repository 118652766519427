import React from 'react';
import styled from 'styled-components';
import {StatusEclipse} from 'components/Ui';
import _ from 'lodash';
import {ArrowRight} from 'components/Ui/Icon';
import {useProjectResponseStatusReportQuery, ProjectResponseStatus} from 'api';

interface Props {
  mailCount: number;
  projectResponseStatusReport: ProjectResponseStatus;
}

export default ({mailCount, projectResponseStatusReport}: Props) => {
  const replyCount = projectResponseStatusReport?.reply || 0;
  const appointCount = projectResponseStatusReport?.appoint || 0;
  const refusalCount = projectResponseStatusReport?.refusal || 0;

  const parentCategory: any = [
    {
      status: 'reply',
      label: '返信あり',
      color: '#2D9CDB',
      count: replyCount,
      rate:
        replyCount !== 0 && mailCount !== 0
          ? ((replyCount / mailCount) * 100).toFixed(1)
          : 0,
    },
    {
      status: 'appoint',
      label: 'アポ獲得',
      color: '#27AE60',
      count: appointCount,
      rate:
        appointCount !== 0 && mailCount !== 0
          ? ((appointCount / mailCount) * 100).toFixed(1)
          : 0,
    },
    {
      status: 'refusal',
      label: 'お断り・クレーム',
      color: '#EB5757',
      count: refusalCount,
      rate:
        refusalCount !== 0 && mailCount !== 0
          ? ((refusalCount / mailCount) * 100).toFixed(1)
          : 0,
    },
  ];

  return (
    <Container>
      <h2>フォームメールステータス</h2>
      <Table>
        <div className="column far-left">
          <div className="category" />
          {parentCategory.map((category: any) => (
            <React.Fragment key={category.status}>
              <div className="category">
                <StatusEclipse color={category.color} />
                <span>{category.label}</span>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="column">
          <div className="category title">
            <span>プレリード数</span>
          </div>
          {parentCategory.map((category: any) => (
            <React.Fragment key={category.status}>
              <div className="category prelead">
                <span>{category.count}</span>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="column">
          <div className="category title">
            <span>対送信数</span>
          </div>
          {parentCategory.map((category: any) => (
            <React.Fragment key={category.status}>
              <div className="category">
                <span>{category.rate}%</span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;

  > h2 {
    font-weight: bold;
    font-size: 20px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  width: 410px;

  .column {
    flex: 1;

    > div {
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;
      display: flex;
      align-items: center;

      > span {
        margin: 0 9px 0 auto;
      }
      &.title > span {
        font-weight: bold;
        margin: 0 auto;
      }
      &.prelead > span {
        color: var(--primary);
      }
    }

    &:last-child > div {
      border-right: none;
    }

    &.far-left > div {
      width: 214px;
      padding: 0 30px;

      &.category {
        svg {
          margin-right: 5px;
        }
        span {
          font-weight: bold;
        }
      }
      > span {
        margin: 0;
        font-size: 13px;
      }
    }
  }
`;
