import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, PreleadProjectCreateProspectActivity} from 'api';
import {Link} from 'react-router-dom';

interface PreleadProjectCreateAcvitity extends ProspectActivity {
  resource: PreleadProjectCreateProspectActivity;
}

interface Props {
  prospectActivity: PreleadProjectCreateAcvitity;
}

const PreleadProjectCreateActivityComponent = ({prospectActivity}: Props) => {
  const user = prospectActivity.user;
  const resource = prospectActivity.resource;
  const preleadProject = prospectActivity.resource?.preleadProject;
  const project = resource?.preleadProject?.project;
  const userFullName = user?.firstName
    ? `${user?.lastName} ${user?.firstName}`
    : `${user?.lastName}`;

  return (
    <>
      <Icon />
      <Content>
        <div className="mr-1">リード登録</div>
        {preleadProject && project && (
          <Link
            target="_blank"
            to={`/list/projects/${project.uuid}/preleads/${preleadProject.uuid}/timeline`}>
            {project.name}
          </Link>
        )}
        <span>{userFullName}</span>
        <time>
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 455px;
  margin-top: -4px;
  margin-left: 3px;

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;

export default PreleadProjectCreateActivityComponent;
