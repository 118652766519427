import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useParams, Link, Switch, Route, Redirect} from 'react-router-dom';
import Title from './Title';
import {
  useCurrentUserQuery,
  useWorkflowProspectPoolCountsByStatusQuery,
  useWorkflowQuery,
} from 'api';
import WorkflowStatusIcon from 'components/Workflow/Icon/WorkflowStatusIcon';
import Steps from './steps';
import Assignees from './assignees';
import Prospects from './prospects';
import AutomaticMailSends from './automaticMailSends';
import Settings from './settings';
import SearchConditions from './searchConditions';
import Conditions from './conditions';
import Stats from './Stats';
import Priority from './Priority';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {poolId, workflowId} = useParams<{
    poolId: string;
    workflowId: string;
  }>();

  const {data: {workflow = null} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const {data: {workflowProspectPoolCountsByStatus = {}} = {}} =
    useWorkflowProspectPoolCountsByStatusQuery({
      variables: {
        uuid: workflowId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const workflowStatus =
    workflow?.status === 'applying' || workflow?.status === 'removing'
      ? 'open'
      : workflow?.status;

  if (!workflow) return null;

  return (
    <Container>
      <Back
        to={`/pools/${poolId}/workflows?status=${workflowStatus}`}>{`〈 戻る`}</Back>
      <Title workflow={workflow} />
      <div className="flex items-center gap-4 text-c-light mb-4">
        <WorkflowStatusIcon workflow={workflow} />
        {currentUser.role === 'admin' && <Priority workflow={workflow} />}
        更新 {moment(workflow.updatedAt).format('YYYY/MM/DD HH:mm')}
      </div>
      <Stats />
      <Contents>
        <Tabs>
          <TabItem
            active={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/steps`,
            )}>
            <Link
              to={`/pools/${poolId}/workflows/${workflowId}/steps${location.search}`}>
              ステップ
            </Link>
          </TabItem>
          <TabItem
            active={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/conditions`,
            )}>
            <Link
              to={`/pools/${poolId}/workflows/${workflowId}/conditions/prospect${location.search}`}>
              条件
            </Link>
          </TabItem>
          <TabItem
            active={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/prospects`,
            )}>
            <Link
              className="lead"
              to={`/pools/${poolId}/workflows/${workflowId}/prospects${location.search}`}>
              <div>リード</div>
              <div className="count">
                {workflowProspectPoolCountsByStatus.total?.toLocaleString()}
              </div>
            </Link>
          </TabItem>
          <TabItem
            active={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/assignees`,
            )}>
            <Link
              to={`/pools/${poolId}/workflows/${workflowId}/assignees${location.search}`}>
              担当者
            </Link>
          </TabItem>
          <TabItem
            active={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/automatic_mail_sends`,
            )}>
            <Link
              to={`/pools/${poolId}/workflows/${workflowId}/automatic_mail_sends${location.search}`}>
              自動送信メール
            </Link>
          </TabItem>
          <TabItem
            active={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/settings`,
            )}>
            <Link
              to={`/pools/${poolId}/workflows/${workflowId}/settings${location.search}`}>
              設定
            </Link>
          </TabItem>
        </Tabs>
        <Switch>
          <Route
            path={`/pools/:poolId/workflows/:workflowId/steps`}
            component={Steps}
          />
          <Route
            path={`/pools/:poolId/workflows/:workflowId/search_conditions`}
            component={SearchConditions}
          />
          <Route
            path={`/pools/:poolId/workflows/:workflowId/conditions`}
            component={Conditions}
          />
          <Route
            path={`/pools/:poolId/workflows/:workflowId/assignees`}
            component={Assignees}
          />
          <Route
            path={`/pools/:poolId/workflows/:workflowId/prospects`}
            component={Prospects}
          />
          <Route
            path={`/pools/:poolId/workflows/:workflowId/automatic_mail_sends`}
            component={AutomaticMailSends}
          />
          <Route
            path={`/pools/:poolId/workflows/:workflowId/settings`}
            component={Settings}
          />
          {poolId && workflowId && (
            <Redirect to={`/pools/${poolId}/workflows/${workflowId}/steps`} />
          )}
        </Switch>
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow-y: scroll;
`;

const Contents = styled.div`
  padding: 24px 32px;
  background: #fff;
  border-radius: 4px;
  position: relative;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #e1e6eb;

  .lead {
    display: flex;
    align-items: center;

    .count {
      display: flex;
      align-items: center;
      margin-left: 4px;
      background: #e1e6eb;
      height: 16px;
      padding: 4px 6px;
      border-radius: 100px;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.15px;
      color: var(--text-color-2);
    }
  }
`;

const TabItem = styled.div<{active?: boolean}>`
  text-align: center;
  border-bottom: solid 3px;
  border-color: ${({active}) => (active ? 'var(--text-color-1)' : 'transparent')};
  padding: 0;
  margin-right: 2rem;

  a {
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
    color: ${({active}) => (active ? 'var(--text-color-1)' : 'var(--text-color-3)')};
    padding-bottom: 15px;
    display: inline-block;
  }
`;

const Back = styled(Link)`
  color: var(--primary);
  cursor: pointer;
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
`;
