import React, {useState} from 'react';
import {
  ProspectPool,
  useCurrentUserQuery,
  useProspectScoreSettingQuery,
} from 'api';
import ScoreCircle from 'components/ProspectScore/ScoreCircle';
import {Popover, Transition} from '@headlessui/react';
import ScoreItem from './ScoreItem';

const Score = ({prospectPool}: {prospectPool: ProspectPool}) => {
  const score = prospectPool?.score;

  const [isShowing, setIsShowing] = useState(false);

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  if (currentUser?.role !== 'admin') {
    return null;
  }

  return (
    <div>
      <Popover className="relative">
        <div
          onMouseEnter={() => setIsShowing(true)}
          onMouseLeave={() => setIsShowing(false)}>
          <ScoreCircle score={score?.totalScore} rank={score?.scoreRank} />
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isShowing}>
          <Popover.Panel className="absolute left-0 z-10 mt-2 w-[200px] bg-white rounded-lg p-3 shadow-lg border-c-border border">
            <div className="absolute w-3 h-3 rotate-45 bg-white shadow-[-2px_-2px_4px_rgba(0,0,0,0.08)] rounded-sm -top-1 left-[27px] transform -translate-x-1/2" />
            <div className="flex flex-col">
              <ScoreItem title="業種" score={1} />
              <ScoreItem
                title="役職"
                score={Math.round(score?.positionScore * 0.6)}
                target={
                  !!prospectScoreSetting?.weightPosition &&
                  !!prospectPool.prospect.sectionPosition
                }
                hasSetting={
                  prospectScoreSetting
                    ?.clientProspectScoreSettingPositionKeywords?.length > 0
                }
              />
              <ScoreItem
                title="リードソース"
                score={Math.round(score?.leadSourceScore * 0.6)}
                target={
                  !!prospectScoreSetting?.weightLeadSource &&
                  !!prospectPool.leadSource
                }
                hasSetting={prospectScoreSetting?.leadSources?.length > 0}
              />
              <ScoreItem
                title="最新流入日"
                score={score?.latestInflowDateScore}
                target={
                  !!prospectScoreSetting?.weightLeadSource &&
                  !!prospectPool.latestInflowDate
                }
                hasSetting={true}
              />
              <ScoreItem
                title="タグ"
                score={Math.round(score?.tagScore * 0.6)}
                target={!!prospectPool.prospectTags?.length}
                hasSetting={prospectScoreSetting?.prospectTags?.length > 0}
              />
              <ScoreItem title="決算" score={3} />
              <ScoreItem title="従業員数" score={2} />
              <ScoreItem title="資本金" score={1} />
              <ScoreItem title="事業内容" score={1} />
              <ScoreItem title="エリア" score={3} />
              <ScoreItem title="求人・メディア" score={2} />
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default Score;
