import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Airplane} from 'components/Ui/Icon';
import {Activity, UnsubscribeMailCampaignActivity} from 'api';
import useProjectName from './useProjectName';
import {Link} from 'react-router-dom';

interface MailCampaignActivity extends Activity {
  resource: UnsubscribeMailCampaignActivity;
}

interface Props {
  activity: MailCampaignActivity;
}

const UnsubscribeMailCampaignActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <Summary>
      <BadgeWrapper>
        <Airplane />
      </BadgeWrapper>
      <Body>
        {projectName && <Project>{projectName}</Project>}
        <Campaign>
          <Link
            target="_blank"
            to={`/mail_campaigns/${activity.resource.mailCampaignSend.mailCampaign.uuid}`}>
            {activity.resource.mailCampaignSend.mailCampaign.title}
          </Link>
        </Campaign>
        <Content>配信停止</Content>
      </Body>
      <TimeStamp>{moment(activity.createdAt).format('MM/DD HH:mm')}</TimeStamp>
    </Summary>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  width: 480px;
  span {
    line-height: 30px;
  }
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin: auto 20px auto 0;
  &:before {
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 0;
    display: block;
    width: 1px;
    content: '';
    background-color: #e1e6eb;
    z-index: -1;
  }
`;

const Body = styled.span`
  min-width: 0;
  max-width: 100%;
  margin-top: 4px;
  flex: auto;
  display: flex;
  flex-direction: column;
  * {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 4px;
  }
`;

const Project = styled.div`
  color: var(--text-color-2);
`;

const Campaign = styled.div`
  color: var(--text-color-2);
`;

const TimeStamp = styled.span`
  margin: auto 0 0 auto;
`;

const Content = styled.div`
  color: var(--text-color-2);
`;

export default UnsubscribeMailCampaignActivityComponent;
