import React from 'react';
import UpdateLogo from './UpdateLogo';
import {
  useUpdateProspectPoolScoresMutation,
  useProspectScoreSettingQuery,
} from 'api';

export default () => {
  const [update, {loading}] = useUpdateProspectPoolScoresMutation({});
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {fetchPolicy: 'cache-and-network'},
  );
  return (
    <div className="w-full h-9 flex items-center justify-end">
      <button
        className="h-full border border-[#E6E6E6] rounded bg-white px-3 py-1 cursor-pointer flex items-center justify-between gap-2 leading-none disabled:cursor-not-allowed"
        disabled={loading || prospectScoreSetting?.updateStatus !== 'success'}
        onClick={() => {
          confirm('顧客スコアを更新しますか？') &&
            update({
              refetchQueries: ['prospectScoreSetting'],
            });
        }}>
        <UpdateLogo />
        <span>顧客スコアを更新</span>
      </button>
    </div>
  );
};
