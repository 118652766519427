import React, {useState} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button} from 'antd';
import {Close} from 'components/Icon';
import {Checkbox} from 'components/Ui';
import {
  Project,
  useProjectGroupQuery,
  useExcludeDuplicatePreleadProjectsMutation,
  useProjectProgressLazyQuery,
} from 'api';
import {useParams} from 'react-router-dom';

type Props = {
  project: Project;
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

export default ({project: currentProject, isOpen, setModal}: Props) => {
  const [projectIds, setProjectIds] = useState<string[]>([]);
  const {projectGroupId} = useParams<{projectGroupId: string}>();

  const {data: {projectGroup = {}} = {}} = useProjectGroupQuery({
    fetchPolicy: 'cache-and-network',
    variables: {uuid: projectGroupId || currentProject.projectGroup.uuid},
    skip: !projectGroupId && !currentProject.projectGroup.uuid,
  });

  const projects = projectGroup?.projects || [];

  const filteredProjects = projects.filter(
    (project) => project.status === 'open' && currentProject.id !== project.id,
  );

  const [excludeDuplicatePreleadProjects] =
    useExcludeDuplicatePreleadProjectsMutation({
      variables: {projectId: currentProject.id, projectIds: projectIds},
      refetchQueries: ['project'],
    });

  const [projectProgress] = useProjectProgressLazyQuery({
    variables: {uuid: currentProject.uuid},
    fetchPolicy: 'network-only',
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '24px',
          borderRadius: '10px',
          width: '526px',
          height: '538px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
        },
      }}
      contentLabel="modal">
      <ModalHeader>
        <h2>重複しているプレリードを除外</h2>
        <CloseIconWrapper onClick={() => setModal(false)}>
          <Close />
        </CloseIconWrapper>
      </ModalHeader>

      <ModalBody>
        <Note>
          <div>下記の営業リストと重複しているプレリードを削除できます。</div>
          <div>※一度削除したら戻せません。</div>
        </Note>

        <ProjectList>
          {filteredProjects.map((project) => (
            <ListItem key={project.id}>
              <Checkbox
                checked={projectIds.includes(project.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setProjectIds([...projectIds, project.id]);
                  } else {
                    setProjectIds(projectIds.filter((id) => id !== project.id));
                  }
                }}
              />
              <ProjectName>{project.name}</ProjectName>
            </ListItem>
          ))}
        </ProjectList>

        <Action>
          <Button
            type="primary"
            size="small"
            disabled={!(projectIds.length > 0)}
            onClick={() => {
              if (confirm('削除してよろしいですか？')) {
                excludeDuplicatePreleadProjects();
                projectProgress();
                setProjectIds([]);
                setModal(false);
              }
            }}
            style={{width: '86px', height: '24px'}}>
            実行
          </Button>
        </Action>
      </ModalBody>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  h2 {
    font-weight: bold;
    font-size: 17.5px;
  }
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  width: 17px;
  height: 17px;

  path {
    fill: var(--text-color-3);
  }
`;

const ModalBody = styled.div``;

const Note = styled.div`
  font-size: 13px;
  margin-bottom: 20px;

  div:nth-child(2) {
    color: #eb5757;
  }
`;

const ProjectList = styled.div`
  height: 335px;
  width: 100%;
  overflow-y: scroll;
`;

const ListItem = styled.div`
  font-size: 13px;
  line-height: 19px;
  color: var(--text-color-1);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const ProjectName = styled.div`
  margin-left: 8px;
  max-width: 430px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
`;
