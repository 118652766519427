import React from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import useProjectPath from 'hooks/useProjectPath';

export const Menu = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();

  return (
    <NavMenu>
      <NavItem active={!!location.pathname.includes('/preleads')}>
        <Link to={createPath(`projects/${projectId}/preleads`)}>
          プレリード
        </Link>
      </NavItem>
      <NavItem active={!!location.pathname.includes('/prelead_reports')}>
        <Link
          to={createPath(`projects/${projectId}/prelead_reports/items/tag`)}>
          プレリードレポート
        </Link>
      </NavItem>
      <NavItem active={!!location.pathname.includes('/tel_reports')}>
        <Link to={createPath(`projects/${projectId}/tel_reports/all/call`)}>
          TELレポート
        </Link>
      </NavItem>
      <NavItem active={!!location.pathname.includes('/form_mail_reports')}>
        <Link
          to={createPath(
            `projects/${projectId}/form_mail_reports/all/send_count`,
          )}>
          フォーム送信レポート
        </Link>
      </NavItem>
    </NavMenu>
  );
};

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NavItem = styled.div<{active?: boolean}>`
  display: inline-block;
  a {
    font-size: 14px;
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
  }
`;
