import React from 'react';

export default () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_31109_178695)">
        <path
          d="M1.5 3C1.5 2.44771 1.94771 2 2.5 2H9.5C10.0523 2 10.5 2.44771 10.5 3V10C10.5 10.5523 10.0523 11 9.5 11H2.5C1.94771 11 1.5 10.5523 1.5 10V3Z"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 5H10.5"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 1V3"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 1V3"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_31109_178695">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
