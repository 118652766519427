import React from 'react';
import {Setting, LaunchLink} from 'components/Ui/Icon';
import {
  ProspectPool,
  useHubspotContactByProspectPoolQuery,
  useHubspotContactPropertyDefinitionsQuery,
} from 'api';
import PropertyModal from './ContactPropertyModal';
import Property from './Property';
import useProspectContext from '../../useProspectContext'

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {poolId} = useProspectContext();

  const [isPropertyModalVisible, setIsPropertyModalVisible] =
    React.useState(false);

  const {data: {hubspotContactByProspectPool} = {}} =
    useHubspotContactByProspectPoolQuery({
      variables: {uuid: prospectPool.uuid},
    });

  const {data: {hubspotContactPropertyDefinitions = []} = {}} =
    useHubspotContactPropertyDefinitionsQuery({
      variables: {poolId},
      skip: !poolId,
    });

  if (!hubspotContactByProspectPool) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-2 justify-start">
        <table className="table-auto w-full">
          <tbody>
            {hubspotContactPropertyDefinitions
              .filter((propertyDefinition) => propertyDefinition.isDisplay)
              .map((propertyDefinition) => (
                <tr key={propertyDefinition.id}>
                  <td className="py-2 text-sm">
                    {propertyDefinition.labelText}
                  </td>
                  <td className="py-2 pl-4 text-sm">
                    <Property
                      propertyDefinition={propertyDefinition}
                      hubspotContact={hubspotContactByProspectPool}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex flex-col gap-2">
          <a
            href={hubspotContactByProspectPool?.hubspotUrl}
            target="_blank"
            className="flex items-center gap-1 [&>svg]:w-3">
            <LaunchLink color={'var(--primary)'} />
            <span>Hubspotで見る</span>
          </a>
          <button
            onClick={() => setIsPropertyModalVisible(true)}
            className="cursor-pointer bg-white border border-c-border text-sm text-c-text hover:opacity-50 h-8 w-8 rounded inline-flex items-center justify-center gap-1">
            <Setting />
          </button>
        </div>
      </div>
      <PropertyModal
        isOpen={isPropertyModalVisible}
        closeModal={() => setIsPropertyModalVisible(false)}
      />
    </>
  );
};
