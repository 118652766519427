import React from 'react';
import styled from 'styled-components';
import {
  useWorkflowProspectPoolCountsByStatusQuery,
  useWorkflowQuery,
} from 'api';
import {useParams} from 'react-router-dom';

const Stats = () => {
  const {workflowId} = useParams<{workflowId: string}>();

  const {data: {workflowProspectPoolCountsByStatus = {}} = {}} =
    useWorkflowProspectPoolCountsByStatusQuery({
      variables: {
        uuid: workflowId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <div>
        <div>
          <em>{workflowProspectPoolCountsByStatus.total}</em>
          <span>合計</span>
        </div>
      </div>
      {workflow.status !== 'draft' && (
        <>
          <div>
            <div>
              <em>{workflowProspectPoolCountsByStatus.open}</em>
              <span>実行</span>
            </div>
          </div>
          <div>
            <div>
              <em>{workflowProspectPoolCountsByStatus.closed}</em>
              <span>完了</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  width: 100%;
  padding-bottom: 10px;

  & > * {
    width: 120px;
    height: 60px;
    padding: 0.5rem 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e1e6eb;
      color: var(--text-color-1);

      em {
        font-style: normal;
        font-size: 18px;
        font-weight: bold;
      }

      span {
        font-size: 12px;
        font-weight: medium;
      }
    }
  }
`;

export default Stats;
