import React, {useState} from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  usePreleadMasterColumnsQuery,
  useUpdatePreleadMasterColumnMutation,
  useUpdatePreleadMasterColumnPositionMutation,
} from 'api';
import {Switch} from 'components/antd';
import {columnDef} from '../usePreleadTable';
import {HorizontalThreeLines} from 'components/Ui/Icon';

export default () => {
  const {data: {preleadMasterColumns} = {}} = usePreleadMasterColumnsQuery();
  const [updatePreleadMasterColumn] = useUpdatePreleadMasterColumnMutation();
  const [updatePreleadMasterColumnPosition] =
    useUpdatePreleadMasterColumnPositionMutation({
      refetchQueries: ['preleadMasterColumns'],
    });

  const [columns, setColumns] = useState(preleadMasterColumns);

  const fixedColumn = columns.filter(
    (column) => columnDef[column.dataType]?.sticky,
  );
  const draggableColumn = columns.filter(
    (column) =>
      columnDef[column.dataType] && !columnDef[column.dataType]?.sticky,
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.source || !result.destination) {
      return;
    }

    const sourceIndex = result.source.index + fixedColumn.length;
    const destIndex = result.destination.index + fixedColumn.length;

    const items = [...columns];
    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destIndex, 0, removed);

    setColumns(items);
    updatePreleadMasterColumnPosition({
      variables: {id: removed.id, position: destIndex},
    });
  };

  React.useEffect(() => {
    setColumns(preleadMasterColumns);
  }, [preleadMasterColumns]);

  return (
    <Container>
      <Header>
        <h4>並び替え</h4>
        <h4>表示変更</h4>
      </Header>
      <Columns>
        {fixedColumn.map((column) => (
          <ColumnItem key={column.id}>
            <label>
              <div style={{width: '17px'}} />
              {column.title}
            </label>
            <Switch
              size="small"
              checked={column.isDisplay}
              onChange={(checked) =>
                updatePreleadMasterColumn({
                  variables: {
                    id: column.id,
                    isDisplay: checked,
                  },
                })
              }
            />
          </ColumnItem>
        ))}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {draggableColumn.map((column, index) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}>
                    {(provided, snapshot) => (
                      <ColumnItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={snapshot.isDragging ? 'dragging' : ''}>
                        <label>
                          <div {...provided.dragHandleProps}>
                            <HorizontalThreeLines />
                          </div>
                          <ColumnTitle>{column.title}</ColumnTitle>
                        </label>
                        <Switch
                          size="small"
                          checked={column.isDisplay}
                          onChange={(checked) =>
                            updatePreleadMasterColumn({
                              variables: {
                                id: column.id,
                                isDisplay: checked,
                              },
                            })
                          }
                        />
                      </ColumnItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Columns>
    </Container>
  );
};

const Container = styled.div`
  width: 300px;
  font-size: 12px;
  padding: 25px 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h4 {
    font-size: 12px;
    font-weight: bold;
    color: var(--text-color-3);
  }
`;

const Columns = styled.div`
  margin-top: 1rem;
  max-height: 60vh;
  overflow-y: scroll;
`;

const ColumnItem = styled.div`
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;

  > label {
    margin: 0 !important;
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
    svg:hover {
      cursor: grab;
    }
  }
`;

const ColumnTitle = styled.div`
  width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 100%;
`;
