import React from 'react';
import styled from 'styled-components';
import {ArrowDown, LaunchLink} from 'components/Ui/Icon';
import {SalesforceDeal} from 'api';
import {FieldValue} from './FieldValue';

interface Props {
  salesforceDeal: SalesforceDeal;
  url: string;
}

export default ({salesforceDeal, url}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);

  const salesforceDealFields =
    salesforceDeal.salesforceDealRecordType?.salesforceDealFields || [];

  const salesforceDealVisibleFields = React.useMemo(() => {
    const visibleFields = salesforceDealFields.filter(
      (salesforceDealField) => salesforceDealField.isDisplay === true,
    );

    return visibleFields;
  }, [salesforceDealFields]);

  const filteredSalesforceDealFields = React.useMemo(() => {
    const filteredSalesforceDealFields = showMore
      ? salesforceDealVisibleFields
      : salesforceDealVisibleFields.slice(0, 5);

    return filteredSalesforceDealFields;
  }, [salesforceDealVisibleFields, showMore]);

  return (
    <Deal>
      <Header open={open} onClick={() => setOpen(!open)}>
        <Wrapper>
          <h2>{salesforceDeal?.name}</h2>
          {salesforceDeal?.salesforceOpportunityStage && (
            <Stage>
              <span>
                {salesforceDeal?.salesforceOpportunityStage?.masterLabel}
              </span>
            </Stage>
          )}
        </Wrapper>
        <ArrowDown />
      </Header>
      {open && (
        <>
          <Section />
          <SalesforceField>
            <Label className="w-24 text-left">レコードタイプ</Label>
            <Type>{salesforceDeal?.salesforceDealRecordType?.name}</Type>
          </SalesforceField>
          {salesforceDeal?.salesforceUser && (
            <SalesforceField>
              <Label className="w-24 text-left">商談所有者</Label>
              <Type>{salesforceDeal.salesforceUser.name}</Type>
            </SalesforceField>
          )}
          <Section />
          <SalesforceDealFields>
            {filteredSalesforceDealFields.map((field, i) => (
              <FieldValue
                field={field}
                key={i}
                salesforceDeal={salesforceDeal}
              />
            ))}
          </SalesforceDealFields>
          {salesforceDealVisibleFields.length > 5 && (
            <ShowMore
              showMore={showMore}
              onClick={() => setShowMore(!showMore)}>
              <ArrowDown color={'var(--primary)'} />
              <span>
                {showMore ? '一部の項目を表示' : 'すべての項目を表示'}
              </span>
            </ShowMore>
          )}
          <LinkToDeal
            href={`${url}/lightning/r/Opportunity/${salesforceDeal?.salesforceOpportunityIdString}/view`}
            target="_blank">
            <LaunchLink color={'var(--primary)'} />
            <span>Salesforceで見る</span>
          </LinkToDeal>
        </>
      )}
    </Deal>
  );
};

const Deal = styled.div`
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  margin-bottom: 16px;

  font-weight: 500;
`;

const Header = styled.div<{open: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
    letter-spacing: 0.15px;
    color: var(--text-color-1);
    margin-bottom: 0px;
  }
`;

const Stage = styled.div`
  height: 20px;
  padding: 4px;
  background: #eef0f2;
  border-radius: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 8px;
  > span {
    font-size: 10px;
    line-height: 34px;
    letter-spacing: 0.15px;
    color: var(--text-color-2);
  }
`;

const Section = styled.hr`
  border: 1px solid #e1e6eb;
`;

const SalesforceField = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
`;

const Type = styled.div`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;

const SalesforceDealFields = styled.div`
  margin-top: 16px;
`;

const ShowMore = styled.div<{showMore: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: pointer;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--primary);
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    transform: rotate(${({showMore}) => (showMore ? '180deg' : '0deg')});
  }
`;

const LinkToDeal = styled.a`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--primary);
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
`;
