import styled from 'styled-components';
import {Button} from 'components/antd';

const DownloadButton = styled(Button)`
  top: -115px;
  left: 100%;
  position: relative;
  transform: translateX(-100%);
  width: 127px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-left: 15px;
  svg {
    margin-right: 6px;
  }
  span {
    margin: auto;
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-1);
  }
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 2rem 3rem 0;
  position: relative;
  h2 {
    font-weight: bold;
    font-size: 17.5px;
  }
  button {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    box-shadow: none;
    border: none;
    display: flex;
    svg {
      margin: auto 0;
      height: 16px;
      width: 25px;
      path {
        fill: var(--text-color-3);
      }
    }
    span {
      margin: auto 0;
      color: var(--text-color-3);
    }
  }
`;

const ModalBody = styled.div``;

const DefaultButton = styled(Button)`
  border-radius: 4px;
  height: 35px;
`;

const SuccessBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessNote = styled.div`
  margin-top: 2rem;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  text-align: center;
`;

const OpenCsvButton = styled(DefaultButton)`
  height: 45px;
  width: 240px;
  margin: 1rem 0;
  svg {
    margin-right: 10px;
    position: relative;
    top: 4px;
  }
`;

const FileType = styled.div`
  font-size: 12px;
  color: var(--text-color-3);
  margin-bottom: 3rem;
`;

const Body = styled.div`
  grid-area: body;
  background: #f3f5f7;
  overflow: scroll;
  padding: 0 32px 32px 0;
`;

const Main = styled.div`
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-top: none;
  border-left: none;
  border-radius: 0px 4px 4px 4px;
  margin-top: 36px;

  > div:last-child {
    padding: 30px 40px;

    overflow: scroll;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-top: 1px solid #e1e6eb;
    border-radius: 0px 4px 4px 4px;
  }
`;

const MainTabs = styled.div`
  display: flex;
  width: 101%;
  height: 40px;
  background: #f3f5f7;
`;

const MainTabItem = styled.div<{active?: boolean}>`
  display: flex;
  width: 149.8px;
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  background: ${({active}) => (active ? '#FFFFFF' : 'none')};
  border-radius: 4px 4px 0 0;
  &:first-child {
    border-left: 1px solid var(--border-color);
  }
  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
  }
`;

export {
  DownloadButton,
  ModalHeader,
  ModalBody,
  DefaultButton,
  SuccessBody,
  SuccessNote,
  OpenCsvButton,
  FileType,
  Body,
  Main,
  MainTabs,
  MainTabItem,
};
