import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import Menu from '../Menu';
import {
  Chatwork,
  Hubspot,
  Salesforce,
  Slack,
  Miitel,
  Google,
  ZoomPhone,
} from 'components/Ui/Icon';
import {useCurrentClientQuery, useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Body>
      <Menu />
      <Content>
        <TitleSection>
          <Title>外部連携</Title>
        </TitleSection>
        <ContentSection>
          <ContentLink to="/settings/externals/hubspot">
            <Hubspot />
            <div>
              <span>HubSpot</span>
              <div>
                {currentClient.hubspotActive ? (
                  <>
                    <span className="badge active"></span>
                    <span className="status active">連携中</span>
                  </>
                ) : (
                  <>
                    <span className="badge"></span>
                    <span className="status">未連携</span>
                  </>
                )}
              </div>
            </div>
          </ContentLink>

          <ContentLink to="/settings/externals/salesforce">
            <Salesforce />
            <div>
              <span>Salesforce</span>
              <div>
                {currentClient.salesforceActive ? (
                  <>
                    <span className="badge active"></span>
                    <span className="status active">連携中</span>
                  </>
                ) : (
                  <>
                    <span className="badge"></span>
                    <span className="status">未連携</span>
                  </>
                )}
              </div>
            </div>
          </ContentLink>

          <ContentLink to="/settings/externals/slack">
            <Slack />
            <div>
              <span>Slack</span>
              <div>
                {currentClient.slackActive ? (
                  <>
                    <span className="badge active"></span>
                    <span className="status active">連携中</span>
                  </>
                ) : (
                  <>
                    <span className="badge"></span>
                    <span className="status">未連携</span>
                  </>
                )}
              </div>
            </div>
          </ContentLink>

          <ContentLink to="/settings/externals/chatwork">
            <Chatwork />
            <div>
              <span>Chatwork</span>
              <div>
                {currentClient.chatworkActive ? (
                  <>
                    <span className="badge active"></span>
                    <span className="status active">連携中</span>
                  </>
                ) : (
                  <>
                    <span className="badge"></span>
                    <span className="status">未連携</span>
                  </>
                )}
              </div>
            </div>
          </ContentLink>

          {currentClient.miitelSetting?.isAvailable && (
            <ContentLink to="/settings/externals/miitel">
              <Miitel />
              <div>
                <span>MiiTel</span>
                <div>
                  {currentClient.miitelActive ? (
                    <>
                      <span className="badge active"></span>
                      <span className="status active">連携中</span>
                    </>
                  ) : (
                    <>
                      <span className="badge"></span>
                      <span className="status">未連携</span>
                    </>
                  )}
                </div>
              </div>
            </ContentLink>
          )}

          {currentUser?.role === 'admin' && (
            <ContentLink to="/settings/externals/google">
              <Google />
              <div>
                <span>Google</span>
                <div>
                  {currentClient.googleActive ? (
                    <>
                      <span className="badge active"></span>
                      <span className="status active">連携中</span>
                    </>
                  ) : (
                    <>
                      <span className="badge"></span>
                      <span className="status">未連携</span>
                    </>
                  )}
                </div>
              </div>
            </ContentLink>
          )}

          <ContentLink to="/settings/externals/zoom_phone">
            <ZoomPhone />
            <div>
              <span>Zoom Phone</span>
              <div>
                {currentClient.zoomPhoneActive ? (
                  <>
                    <span className="badge active"></span>
                    <span className="status active">連携中</span>
                  </>
                ) : (
                  <>
                    <span className="badge"></span>
                    <span className="status">未連携</span>
                  </>
                )}
              </div>
            </div>
          </ContentLink>
        </ContentSection>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 350px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 28px 45px 45px;
`;

const TitleSection = styled.div`
  margin-bottom: 31px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: var(--text-color-2);
  margin: 0 23px;
`;

const ContentSection = styled.div`
  background-color: #fafcff;
`;

const ContentLink = styled(Link)`
  &&& {
    display: flex;
    padding: 24px 42px;
    border-bottom: 1px solid #e1e6eb;

    &:last-child {
      border-bottom: none;
    }

    > svg {
      width: 36px;
      height: 36px;
    }

    > div {
      margin-left: 19px;

      > span {
        display: block;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 2px;
        color: var(--text-color-0);
      }

      div {
        display: flex;
        align-items: center;
        height: 15px;

        .badge {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: var(--text-color-2);
          margin-right: 4px;

          &.active {
            background-color: #169e34;
          }
        }

        .status {
          height: 15px;
          font-size: 10px;
          color: var(--text-color-2);

          &.active {
            color: #169e34;
          }
        }
      }
    }
  }
`;
