import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Button, Input, Popover} from 'components/antd';
import {
  Prelead,
  PreleadTag as TagType,
  useAddTagToPreleadMutation,
  useCreatePreleadTagMutation,
  useRemoveTagFromPreleadMutation,
  useProjectQuery,
  PreleadTag,
} from 'api';
import {Cross, MoreVertical} from 'components/Ui/Icon';
import Edit from './Edit';
import {getTagFontColor, tagColors} from 'helpers/tagColors';
import {useParams} from 'react-router-dom';
import useProject from 'hooks/useProject';
import useClientUser from 'hooks/useClientUser';
import useProjectGroups from 'hooks/useProjectGroups';
import usePreleadTags from 'hooks/usePreleadTags';

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

interface Props {
  prelead: Prelead;
}

export default ({prelead}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const [tagName, setTagName] = useState('');
  const [isPopoverVisible, setPopoverVisible] = useState(null);
  const {isOwnProject} = useProject(projectId);
  const {isAdmin} = useClientUser();
  const {isProjectGroupAccessible} = useProjectGroups();
  const {filterPreleadTagsAccessible} = usePreleadTags();

  const {data: {project: {projectGroup, preleadTags = []} = {}} = {}, refetch} =
    useProjectQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (!isPopoverVisible) {
      refetch();
    }
  }, [isPopoverVisible]);

  const getRandomColor = (): string => {
    const colors = tagColors.map((color) => color.hex);
    const color = colors[Math.floor(Math.random() * colors.length)];
    return color;
  };

  const randomColor = React.useMemo(() => getRandomColor(), [preleadTags]);

  const [createPreleadTag] = useCreatePreleadTagMutation({
    refetchQueries: ['project'],
    onCompleted: ({createPreleadTag: {preleadTag = {}} = {}}) => {
      setTagName('');
      onAdd(preleadTag);
    },
  });

  const [addTagToPrelead] = useAddTagToPreleadMutation();

  const [removeTagFromPrelead] = useRemoveTagFromPreleadMutation();

  const filteredTags = useMemo(
    () =>
      filterPreleadTagsAccessible(preleadTags)
        ?.filter((tag) => kanaToHira(tag.name).includes(kanaToHira(tagName)))
        ?.sort((taga, tagb) =>
          prelead.preleadTags?.some(
            (preleadTag) => preleadTag.id === taga.id,
          ) &&
          prelead.preleadTags?.some((preleadTag) => preleadTag.id !== tagb.id)
            ? -1
            : 1,
        ),
    [preleadTags, tagName, prelead.preleadTags],
  );

  const alreadyNameExist: boolean = useMemo(
    () => preleadTags?.some((tag) => tag.name === tagName),
    [preleadTags, tagName],
  );

  const alreadyAdded = React.useCallback(
    (tag: TagType) =>
      prelead.preleadTags?.some(
        (preleadTag: TagType) => preleadTag.id === tag.id,
      ),
    [preleadTags, prelead.preleadTags],
  );

  const onChange = React.useCallback(
    (e: any) => setTagName(e.target.value),
    [],
  );

  const onCreate = () => {
    if (!!tagName && !alreadyNameExist) {
      createPreleadTag({
        variables: {
          attributes: {
            name: tagName,
            color: randomColor,
            projectGroupId: projectGroup.id,
          },
        },
      });
    }
  };

  const onAdd = (tag: TagType) => {
    addTagToPrelead({
      variables: {
        uuid: prelead.uuid,
        tagId: tag.id,
      },
    });
  };

  const onRemove = (tag: TagType) => {
    removeTagFromPrelead({
      variables: {
        uuid: prelead.uuid,
        tagId: tag.id,
      },
    });
  };

  return (
    <Container>
      <PaddingWrapper>
        <InputBox>
          {filterPreleadTagsAccessible(prelead.preleadTags)?.map((tag) => (
            <CreatedTag key={tag.id} color={tag.color}>
              <span>{tag.name}</span>
              <CrossWapper onClick={() => onRemove(tag)}>
                <Cross />
              </CrossWapper>
            </CreatedTag>
          ))}

          <InputWrapper>
            <TagInput
              type="search"
              name="search"
              bordered={false}
              placeholder={tagName}
              defaultValue={''}
              value={tagName}
              onChange={onChange}
              onPressEnter={isAdmin && onCreate}
            />
          </InputWrapper>
        </InputBox>

        <h3>タグを編集</h3>
      </PaddingWrapper>

      <ScrollWrapper>
        <List>
          {filteredTags?.map((tag) => (
            <Tag
              key={tag.id}
              onClick={() => {
                onAdd(tag);
                setTagName('');
              }}
              added={alreadyAdded(tag)}>
              <div>
                <TagRectangle color={tag.color} />
                <TagName>{tag.name}</TagName>
                {isOwnProject && isAdmin && (
                  <Popover
                    content={() => (
                      <Edit tag={tag} onClose={() => setPopoverVisible(null)} />
                    )}
                    trigger="click"
                    visible={isPopoverVisible === tag.id}
                    onVisibleChange={(visible) =>
                      setPopoverVisible(visible ? tag.id : null)
                    }
                    placement="rightTop">
                    <Button
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}>
                      <MoreVertical />
                    </Button>
                  </Popover>
                )}
              </div>
            </Tag>
          ))}
        </List>
      </ScrollWrapper>
      {isOwnProject && tagName && !alreadyNameExist && isAdmin && (
        <NewTag>
          <h3>新規作成</h3>
          <Tag onClick={onCreate}>
            <div>
              <TagRectangle color={randomColor} />
              <TagName>{tagName}</TagName>
            </div>
          </Tag>
        </NewTag>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 240px;

  h3 {
    margin-top: 10px;
    font-size: 10px;
    color: var(--text-color-2);
  }
`;

const PaddingWrapper = styled.div`
  padding: 13px 11px 0 11px;
`;

const InputBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 11px;
  width: 100%;
  min-height: 34px;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  box-sizing: border-box;
  border-radius: 2px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  width: auto;
  background: transparent;
  font-size: inherit;
  line-height: 20px;
  flex: 1 1 60px;
  min-width: 60px;
  margin: 0px 6px 6px 0px;
`;

const TagInput = styled(Input)`
  border: none;
  padding: 0px;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: var(--text-color-3);
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;

const CreatedTag = styled.span<{color?: string}>`
  margin-right: 6px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;
  span {
    font-size: 12px;
    font-weight: bold;
    line-height: 13px;
    color: ${({color}) => getTagFontColor(color)};
  }
  svg {
    margin-left: 6px;
    width: 7px;
    height: 7px;
    path {
      stroke-width: 2;
      stroke: ${({color}) => getTagFontColor(color)};
    }
  }
`;

const CrossWapper = styled.span`
  cursor: pointer;
`;

const ScrollWrapper = styled.div`
  max-height: 200px;
  overflow: scroll;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NewTag = styled.div`
  border-top: solid 1px #e1e6eb;

  h3 {
    margin-bottom: 0px;
    padding: 0px 11px;
  }
`;

const TagRectangle = styled.div<{color?: string}>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({color}) => (color ? `${color}` : 'black')};
`;

const TagName = styled.div`
  font-size: 14px;
  color: var(--text-color-1);
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Tag = styled.div<{added?: boolean}>`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #f2f7fa;
  }

  background: ${({added}) => (added ? '#f2f7fa' : '#fff')};

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    padding: 0px 11px;

    ${TagName} {
      margin-left: 7px;
    }

    > button {
      margin-left: auto;
      padding: 0;
      svg {
        transform: rotate(90deg);
        width: 1rem;
        height: 1rem;
        stroke: var(--text-color-2);
        visibility: visible;
      }
    }
  }
`;
