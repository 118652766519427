import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Content} from 'api';
import byteToSize from 'helpers/byteToSize';

interface Props {
  content: Content;
}

export default ({content}: Props) => (
  <Container>
    <h4>コンテンツ情報</h4>
    <DL>
      <dt>作成日</dt>
      <dd>{moment(content.audio.createdAt).format('YYYY/MM/DD HH:mm')}</dd>
      <dt>作成者</dt>
      <dd>
        {content.audio.user?.lastName} {content.audio.user?.firstName}
      </dd>
      <dt>ファイルサイズ</dt>
      <dd>{byteToSize(content.audio.fileSize)}</dd>
    </DL>
  </Container>
);

const Container = styled.div`
  h4 {
    font-size: 13px;
    font-weight: bold;
  }
`;

const DL = styled.dl`
  font-size: 13px;

  dt {
    color: var(--text-color-3)
  }

  dd {
    margin-bottom 1rem;
    display: flex;
    align-items: center;

    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
