import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <path
        d="M22.3332 7.66663L13.6526 16.3472M22.3332 7.66663L5.6665 13.2222L13.6526 16.3472L22.3332 7.66663ZM22.3332 7.66663L16.7776 24.3333L13.6526 16.3472L22.3332 7.66663Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
