import styled from 'styled-components';
import {Radio} from 'components/antd';

export default styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary);

    &:after {
      background-color: var(--primary);
    }
  }
`;
