import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {Cross, AddPaperFile} from 'components/Ui/Icon';
import {
  Button,
  Select,
  Input,
  Upload,
  Tooltip,
  Popover,
  Modal as UIModal,
} from 'components/antd';
import {
  ClientUser,
  useCreateManualMailProspectActivityMutation,
  useActiveClientUsersQuery,
  useProspectPoolQuery,
  ProspectPool,
  useActionsByProspectPoolQuery,
} from 'api';
import {prospectStages} from 'helpers/prospectStages';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import mailVariableOptions from 'helpers/mailVariableOptions';
import {useLocation} from 'react-router-dom';
import Quill from './Quill';
import TemplateSelect from './TemplateSelect';
import useMailTemplate from 'hooks/useMailTempate';

interface Props {
  visible: boolean;
  onCancel: () => void;
  prospectPool: ProspectPool;
}

export default ({visible, onCancel, prospectPool}: Props) => {
  const {templates} = useMailTemplate();
  const [areCcAndBccSelectable, setAreCcAndBccSelectable] = useState(false);
  const [variablePopoverVisible, setVariablePopoverVisible] =
    React.useState(false);

  const ref = useRef(null);

  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };

  const [createManualMailProspectActivity, {loading}] =
    useCreateManualMailProspectActivityMutation({
      onCompleted: onCancel,
      refetchQueries: ['mailThreadsByProspectPool'],
    });

  const {data: {activeClientUsers: clientUsers = []} = {}} =
    useActiveClientUsersQuery();

  const {data: {prospectPool: {prospect: {email} = {}} = {}} = {}} =
    useProspectPoolQuery({
      variables: {uuid: prospectPool.uuid},
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const location = useLocation<{actionUuid: string}>();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionUuid: null,
      fromEmail: '',
      fromName: '',
      stage: currentStageValue()?.id,
      to: email,
      cc: [],
      bcc: [],
      subject: '',
      bodyText: '',
      bodyHtml: '',
      files: [],
      senderId: null,
    },
    validationSchema: Yup.object().shape({
      fromEmail: Yup.string().email().required(),
      to: Yup.string().email().required(),
      subject: Yup.string().required(),
      bodyText: Yup.string().required(),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      const {actionUuid, ...attributes} = values;

      values.bodyHtml = values.bodyHtml.replace(
        /class="ql-align-center"/g,
        'style="text-align: center"',
      );

      createManualMailProspectActivity({
        variables: {
          uuid: prospectPool.uuid,
          actionUuid,
          attributes,
        },
      });
    },
  });

  React.useEffect(() => {
    if (location?.state?.actionUuid) {
      formik.setFieldValue('actionUuid', location?.state?.actionUuid);
    }
  }, [location?.state]);

  const {data: {actionsByProspectPool = []} = {}} =
    useActionsByProspectPoolQuery({
      variables: {
        prospectPoolUuid: prospectPool.uuid,
        status: 'open',
      },
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const openManualMailActions = actionsByProspectPool.filter(
    (action) => action.actionableType === 'Action::ManualMail',
  );

  const senders = React.useMemo(
    () =>
      clientUsers.reduce((acc: any, clientUser: ClientUser) => {
        const email = clientUser.googleSetting?.email || clientUser.user.email;
        acc[email] = clientUser.user.uuid;
        return acc;
      }, {}),
    [clientUsers],
  );

  const emailOptions = React.useMemo(
    () =>
      clientUsers.map((clientUser) => {
        const user = clientUser.user;
        const googleSetting = clientUser.googleSetting;
        const email = googleSetting?.email || user.email;
        const name = `${googleSetting?.lastName || user.lastName} ${
          googleSetting?.firstName || user.firstName
        }`;
        return {
          key: clientUser.id,
          value: email,
          label: `${name} <${email}>`,
        };
      }),
    [clientUsers],
  );

  return (
    <Modal
      visible={visible}
      destroyOnClose
      onCancel={onCancel}
      title="メール新規作成"
      width="700px"
      onOk={() => formik.handleSubmit()}
      okButtonProps={{disabled: !formik.isValid, loading}}
      cancelText="キャンセル"
      okText="送信">
      <Container>
        <FieldGroup>
          <Field>
            <label>ステップ</label>
            <Select
              value={formik.values.actionUuid}
              onChange={(value) => formik.setFieldValue('actionUuid', value)}>
              <Select.Option value={null}>未選択</Select.Option>
              {openManualMailActions?.map((action) => (
                <Select.Option value={action.uuid} key={action.uuid}>
                  {action.step.name}
                </Select.Option>
              ))}
            </Select>
          </Field>

          <Field>
            <label>ステージ</label>
            <Select
              value={formik.values.stage}
              onChange={(value) => formik.setFieldValue('stage', value)}>
              {prospectStages.map((stage) => (
                <Select.Option key={stage.id} value={stage.id}>
                  {stage.displayName}
                </Select.Option>
              ))}
            </Select>
          </Field>
        </FieldGroup>

        <FieldGroup>
          {areCcAndBccSelectable ? (
            <>
              <Field>
                <label>Cc</label>
                <Select
                  mode="tags"
                  bordered={false}
                  value={formik.values.cc}
                  onChange={(values: [string]) =>
                    formik.setFieldValue(
                      'cc',
                      values.filter((value) =>
                        Yup.string().trim().email().isValidSync(value),
                      ),
                    )
                  }
                  options={emailOptions}
                />
              </Field>

              <Field>
                <label>Bcc</label>
                <Select
                  mode="tags"
                  bordered={false}
                  value={formik.values.bcc}
                  onChange={(values: [string]) =>
                    formik.setFieldValue(
                      'bcc',
                      values.filter((value) =>
                        Yup.string().trim().email().isValidSync(value),
                      ),
                    )
                  }
                  options={emailOptions}
                />
              </Field>
            </>
          ) : (
            <Field
              onClick={() => setAreCcAndBccSelectable(true)}
              style={{cursor: 'pointer'}}>
              <label>Cc Bcc</label>
            </Field>
          )}

          <Field>
            <label>差出人</label>
            <Select
              bordered={false}
              showArrow={false}
              value={formik.values.fromEmail}
              onSelect={(value: any) =>
                formik.setValues({
                  ...formik.values,
                  fromEmail: value,
                  senderId: senders[value],
                })
              }
              options={emailOptions}
            />
          </Field>

          <Field>
            <label>件名</label>
            <Input
              id="subject"
              name="subject"
              value={formik.values.subject}
              bordered={false}
              onChange={(e) => formik.setFieldValue('subject', e.target.value)}
            />
          </Field>
        </FieldGroup>
        <TemplateSelect
          onSelect={(value: any) => {
            const template = templates.find(
              (template) => template.uuid === value,
            );
            formik.setValues({
              ...formik.values,
              subject: template.mailTemplate.title,
            });
            template.mailTemplate.bodyHtml
              ? (document.getElementsByClassName('ql-editor')[0].innerHTML =
                  template.mailTemplate.bodyHtml)
              : ref.current?.editor.setText(template.mailTemplate.body);
          }}
        />

        <Quill
          ref={ref}
          onChange={(bodyHtml, bodyText) => {
            formik.setFieldValue('bodyHtml', bodyHtml);
            formik.setFieldValue('bodyText', bodyText);
          }}
        />
        {formik.values.files.map((file) => (
          <File key={file.uid}>
            <FileName>{file.name}</FileName>
            <div
              onClick={() =>
                formik.setFieldValue(
                  'files',
                  formik.values.files.filter((f) => f !== file),
                )
              }>
              <Cross />
            </div>
          </File>
        ))}
        <Actions>
          <Popover
            content={
              <Content>
                {mailVariableOptions.map((option) => (
                  <PopoverSelectButtonWrapper key={option.code}>
                    <PopoverSelectButton
                      onClick={() => {
                        if (ref.current?.editor.getSelection()) {
                          ref.current?.editor.insertText(
                            ref.current?.editor.getSelection().index,
                            option.code,
                          );
                        } else {
                          const subject: any =
                            document.getElementById('subject');
                          const value =
                            subject.value.substr(0, subject.selectionStart) +
                            option.code +
                            subject.value.substr(subject.selectionStart);
                          formik.setFieldValue('subject', value);
                        }
                      }}>
                      <span>{option.code}</span>
                      <span>{option.title}</span>
                    </PopoverSelectButton>
                  </PopoverSelectButtonWrapper>
                ))}
              </Content>
            }
            trigger="click"
            visible={variablePopoverVisible}
            placement="topRight"
            onVisibleChange={setVariablePopoverVisible}>
            <PopoverDisplayButton>{`{ }`}</PopoverDisplayButton>
          </Popover>
          <Upload
            name={'files'}
            customRequest={() => false}
            showUploadList={false}
            onChange={(info) => {
              const addedFile = info.file.originFileObj;
              const upload_size_limit = 25000000; // 25MB

              let fileSize = addedFile.size;
              formik.values.files.forEach((file) => (fileSize += file.size));
              if (fileSize > upload_size_limit) {
                alert(
                  'ファイルの容量が25MBを超えています。\n合計25MBまでの添付が可能です。',
                );
                return null;
              }

              formik.setFieldValue('files', [
                ...formik.values.files,
                addedFile,
              ]);
            }}>
            <Tooltip
              title="ファイルを添付(25MBまで)"
              color={'var(--text-color-0)'}
              placement="top">
              <Button icon={<AddPaperFile />} style={{marginRight: 2}} />
            </Tooltip>
          </Upload>
        </Actions>
      </Container>
    </Modal>
  );
};

const Modal = styled(UIModal)`
  .ant-modal-body {
    padding: 0 9px;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  label {
    color: var(--text-color-2);
    font-weight: 500;
    font-size: 12px;
  }

  .ql-toolbar {
    border: none;
    position: absolute;
    bottom: 40px;
    transform: translateY(100%);
  }
  .ql-snow {
    border: none;
    padding: 0;
  }
  .ql-editor {
    padding: 10px 15px;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }

  .ql-snow .ql-picker.ql-size {
    width: 50px;

    .ql-picker-label {
      padding-left: 0;
    }

    .ql-picker-options {
      width: 50px;
    }
  }
`;

const Field = styled.div`
  flex: 1;
  min-height: 45px;
`;

const FieldGroup = styled.div`
  margin: 0 15px;

  &:first-child {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #e1e6eb;

    label {
      display: block;
    }

    &&& {
      .ant-select {
        width: 210px;
      }
    }
  }

  .ql-picker-label {
    padding-left: 15px;
  }

  &:nth-child(2) {
    ${Field} {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e6eb;
    }

    label {
      width: 45px;
    }

    &&& {
      .ant-select,
      .ant-input {
        width: 100%;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 2px 10px 0;
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;
    path {
      stroke: var(--text-color-2);
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: var(--primary);
`;

const PopoverDisplayButton = styled(Button)`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  color: var(--text-color-2);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const PopoverSelectButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span:first-child {
    color: var(--text-color-2);
    margin-right: 10px;
  }
  span:last-child {
    color: var(--text-color-1);
  }
`;

const PopoverSelectButton = styled(Button)`
  padding: 0;
  padding-left: 20px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;
