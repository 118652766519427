import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Button} from 'components/antd';
import {useProjectGroupQuery} from 'api';
import PrivateSettingModal from './PrivateSettingModal';
import DeleteModal from './DeleteModal';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {projectGroupId} = useParams<{projectGroupId: string}>();
  const {isAdmin} = useClientUser();
  const [isPrivateModalVisible, setPrivateModalVisible] = React.useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);

  const {data: {projectGroup = null} = {}} = useProjectGroupQuery({
    variables: {uuid: projectGroupId},
    skip: !projectGroupId || projectGroupId === 'new',
  });

  return (
    <Container>
      <Item>
        <div>
          <h2>公開設定</h2>
          <span>
            プライベート設定にすると、他のグループからプレリード、タイムラインなどの情報はすべて参照できなくなります。
          </span>
        </div>
        <Button
          danger
          disabled={
            !isAdmin ||
            projectGroup?.isPrivate ||
            projectGroup?.projectGroupType === 'default'
          }
          onClick={() => setPrivateModalVisible(true)}
          size="large">
          Privateに変更
        </Button>
      </Item>

      <Item>
        <div>
          <h2>グループを削除</h2>
          <span>
            所属している営業リスト、プレリード情報はすべて削除されます。
          </span>
        </div>
        <Button
          danger
          disabled={!isAdmin || projectGroup?.projectGroupType === 'default'}
          onClick={() => setDeleteModalVisible(true)}
          size="large">
          削除
        </Button>
      </Item>

      {isPrivateModalVisible && (
        <PrivateSettingModal
          projectGroup={projectGroup}
          isOpen={isPrivateModalVisible}
          setModal={setPrivateModalVisible}
        />
      )}

      {isDeleteModalVisible && (
        <DeleteModal
          projectGroup={projectGroup}
          isOpen={isDeleteModalVisible}
          setModal={setDeleteModalVisible}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  &:first-child {
    border-bottom: 1px solid #e1e6eb;
  }

  > div {
    width: 440px;

    > h2 {
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      color: var(--text-color-1);
    }

    > span {
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      color: var(--text-color-1);
    }
  }

  > button {
    margin-left: auto;
  }
`;
