import React from 'react';

export default () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0137 10.0137H21.4581"
      stroke="var(--text-color-2)"
      strokeWidth="2.75892"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0137 15.7363H15.7359"
      stroke="var(--text-color-2)"
      strokeWidth="2.75892"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1798 4.29199H7.15211C5.57197 4.29199 4.29102 5.57295 4.29102 7.15309V21.4586C4.29102 23.0388 5.57197 24.3197 7.15211 24.3197H11.4438L16.6601 29.536C16.9395 29.8154 17.3924 29.8154 17.6718 29.536L22.8881 24.3197H27.1798C28.76 24.3197 30.0409 23.0388 30.0409 21.4586V7.15309C30.0409 5.57295 28.76 4.29199 27.1798 4.29199Z"
      stroke="var(--text-color-2)"
      strokeWidth="2.75892"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
