import React from 'react';
import moment from 'moment';
import {Row} from 'react-table';
import {MailCampaign} from 'api';
import styled from 'styled-components';

interface Props {
  row: Row<MailCampaign>;
}

const displaySendDateTime = (mailCampaign: MailCampaign) => {
  if (mailCampaign.status === 'sent' || 'ready') {
    return mailCampaign.sendAt;
  } else {
    return null;
  }
};

const Cell = ({row}: Props) => {
  const mailCampaign = row.original;
  const sendDateTime = displaySendDateTime(mailCampaign);

  return (
    <Date>
      {sendDateTime && (
        <>
          <p>{moment(sendDateTime).format('YYYY/MM/DD')}</p>
          <p>{moment(sendDateTime).format('HH:mm')}</p>
        </>
      )}
    </Date>
  );
};

const Date = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.15px;
  color: var(--text-color-2);

  p {
    margin-bottom: 0;
  }
`;

export default Cell;
