const customTheme = {
  baseColor: 'var(--neutral-gray-800)',
  themeColor: {
    project: 'var(--leadpad-purple-400)',
    prelead: '#66CCEF',
    report: '#4FCE82',
    block: '#F88C5C',
    mail: '#5AA99A',
    garage: '#FFC600',
  },
  status: {
    project: {
      background: {
        open: 'var(--label08)',
        archived: 'var(--label05)',
        draft: 'var(--neutral-gray-100)',
      },
      fontColor: {
        open: 'var(--label08-foreground)',
        archived: 'var(--label05-foreground)',
        draft: 'var(--neutral-gray-700)',
      },
    },
    prelead: {
      pending: '',
      approaching: '#68b5fb',
      appoint: '#27ae60',
      lead: '#27ae60',
      webinar: '#27ae60',
      download: '#27ae60',
      interview: '#27ae60',
      recyclelead: '#27ae60',
      inquiry: '#2f80ed',
      refusal: 'var(--neutral-gray-300)',
      block: '#c90e19',
      prospect: '#F2C94C',
    },
    response: {
      no_response: '',
      reply: '#2D9CDB',
      inquiry: '#2d9cdb',
      appoint: '#27ae60',
      refusal: 'var(--leadpad-red-500)',
    },
    tel: {
      pending: '',
      calling: 'var(--neutral-gray-300)',
      request_callback: 'var(--neutral-gray-300)',
      answering_machine: 'var(--neutral-gray-300)',
      unreachable: 'var(--neutral-gray-300)',
      called: 'var(--neutral-gray-300)',
      no_solicitors: '#2d9cdb',
      sending_documents: '#2d9cdb',
      no_call: '#2d9cdb',
      absence: '#2d9cdb',
      done_no_appointment: '#27ae60',
      done_appointment: '#27ae60',
      done_reschedule: '#27ae60',
      done_sent_documents: '#27ae60',
    },
    mail: {
      unsent: '',
      sent: 'var(--leadpad-purple-400)',
      mail: 'var(--leadpad-purple-400)',
      cannotsend: 'var(--neutral-gray-300)',
      cannotread: 'var(--neutral-gray-300)',
      invalid: 'var(--neutral-gray-300)',
      shortage: 'var(--neutral-gray-300)',
      notfound: 'var(--neutral-gray-300)',
      charover: 'var(--neutral-gray-300)',
      recruit: 'var(--neutral-gray-300)',
      cannotaccept: 'var(--neutral-gray-300)',
      unknown: 'var(--neutral-gray-300)',
      duplicate: 'var(--neutral-gray-300)',
      block: 'var(--neutral-gray-300)',
      nourl: 'var(--neutral-gray-300)',
    },
    preleadCategory: {
      pending: '',
      approaching: '#68b5fb',
      appoint: '#27ae60',
      inquiry: '#2f80ed',
      refusal: 'var(--neutral-gray-300)',
      block: '#c90e19',
      prospect: '#F2C94C',
    },
    telCategory: {
      call: '#bdc4cb', // <- var(--neutral-gray-300)
      reception: '#66D7EF',
      contact: '#2d9cdb',
      appoint: '#27ae60',
      document: '#8966EF', // <- var(--leadpad-purple-400),
      other: '#3E3A39',
      call_ng: '#FFC75A',
      reception_ng: '#FF8F00',
      contact_ng: '#EB5757',
    },
    letter: {
      unsent: '',
      sent: '#27AE60',
    },
  },
};

export default customTheme;
