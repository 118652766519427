import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import OfferedClientList from './OfferedClientList';
import RequestClientList from './RequestClientList';
import {Header, Container, Breadcrumb} from 'components/Ui';

export default () => {
  return (
    <Container page="share">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link
              className="name"
              to={
                location.pathname.includes('offered')
                  ? `/list/share/offered`
                  : `/list/share/request`
              }>
              {location.pathname.includes('offered') ? '依頼先' : '依頼元'}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="current">シェア</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Body>
        <h1>シェア一覧</h1>
        <Tabs>
          <TabItem active={location.pathname.includes('offered')}>
            <Link to={`/list/share/offered`}>Offered</Link>
          </TabItem>
          <TabItem active={location.pathname.includes('request')}>
            <Link to={`/list/share/request`}>Request</Link>
          </TabItem>
        </Tabs>

        {location.pathname.includes('request') ? (
          <RequestClientList />
        ) : (
          location.pathname.includes('offered') && <OfferedClientList />
        )}
      </Body>
    </Container>
  );
};

const Body = styled.div`
  grid-area: body;
  display: flex;
  flex-direction: column;
  background: #f3f5f7;
  padding: 0px 32px 12px 0px;
  overflow: scroll;
  > h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: var(--text-color-0);
  }
`;

const Tabs = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
`;

const TabItem = styled.div<{active?: boolean}>`
  text-align: center;
  cursor: pointer;
  width: 60px;
  border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
  &:last-child {
    margin-left: 20px;
  }
  a {
    font-size: 13px;
    font-weight: bold;
    line-height: 35px;
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
  }
`;
