import React from 'react';
import moment from 'moment';
import {prospectStages} from 'helpers/prospectStages';
import {ProspectPool} from 'api';

type Props = {
  prospectPool: ProspectPool;
};

const Stage: React.FC<Props> = ({prospectPool}) => {
  const stage = prospectStages.find(
    (stage) => stage.name === prospectPool.stage,
  );

  return (
    <div className="px-4 my-4 flex flex-col gap-1 border-r border-c-border w-[calc((100%-100px)/4)]">
      <div className="flex items-center gap-2">
        <div className="text-sm text-c-light">ステージ</div>
        <div
          className="flex items-center px-2 h-5 rounded-sm text-xs leading-none font-bold tracking-tight"
          style={{backgroundColor: stage?.bg, color: stage?.color}}>
          {stage?.displayName}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-sm text-c-light">ToDo</div>
        <div className="bg-[#E5E9F4] flex items-center px-2 h-3 rounded-full text-xs leading-none font-bold tracking-tight">
          {prospectPool?.task?.dueDate
            ? moment(prospectPool.task.dueDate).format('YYYY/MM/DD')
            : '-'}
        </div>
      </div>
    </div>
  );
};

export default Stage;
