import React from 'react';

function Icon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="var(--text-color-3)" />
      <circle cx="11.9999" cy="12" r="4.61538" fill="white" />
    </svg>
  );
}

export default Icon;
