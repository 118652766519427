import React from 'react';

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 11.3791C9.31368 11.3791 12 8.83178 12 5.68955C12 2.5473 9.31368 0 6 0C2.68629 0 0 2.5473 0 5.68955C0 7.20468 0.624564 8.5815 1.64287 9.60112L0.436086 10.9072C0.266166 11.091 0.403884 11.3791 0.661728 11.3791H6Z"
      fill="var(--text-color-3)"
    />
  </svg>
);
