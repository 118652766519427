import React from 'react';
import styled from 'styled-components';
import StepTypeIcon from 'components/Workflow/Icon/StepTypeIcon';
import ActionStatusIcon from './Icon/ActionStatusIcon';
import {
  Step,
  ProspectPool,
  useStopWorkflowProspectPoolMutation,
  useWorkflowProspectPoolQuery,
  useCancelActionMutation,
  useWorkflowProspectPoolStepDatesQuery,
} from 'api';
import {Button, Tooltip, Popover} from 'components/antd';
import {CalendarCheckedCircle, DotMenuSide} from 'components/Ui/Icon';
import moment from 'moment';
import {
  PlusCircle,
  Tel as TelIcon,
  Mail as MailIcon,
  Comment as CommentIcon,
  Task as TaskIcon,
} from './Icon';
import NewActivityModal from './NewActivityModal';
import CreateMailModal from './CreateMailModal';

interface Props {
  setDisplaySteps: React.Dispatch<React.SetStateAction<boolean>>;
  workflowProspectPoolId: string;
  prospectPool: ProspectPool;
}

export default ({
  setDisplaySteps,
  workflowProspectPoolId,
  prospectPool,
}: Props) => {
  const {
    data: {
      workflowProspectPool = {},
      workflowProspectPool: {actions, workflow} = {},
    } = {},
  } = useWorkflowProspectPoolQuery({
    variables: {id: workflowProspectPoolId},
    fetchPolicy: 'cache-and-network',
  });

  const [cancelAction] = useCancelActionMutation({
    refetchQueries: ['actionCounts', 'actions'],
  });

  const stepAction = (step: Step) =>
    actions ? actions.find((action) => action.stepId === step.id) : null;

  const [stopWorkflowProspectPool, {loading}] =
    useStopWorkflowProspectPoolMutation({
      variables: {id: workflowProspectPool.id},
    });

  const {data: {workflowProspectPoolStepDates: {stepDates = {}} = {}} = {}} =
    useWorkflowProspectPoolStepDatesQuery({
      variables: {
        id: workflowProspectPoolId,
      },
    });

  const handleCancelClick = (uuid: string) => {
    if (confirm('アクションをキャンセルしますか？')) {
      setCancelVisible(false);
      cancelAction({
        variables: {
          uuid: uuid,
        },
      });
    }
  };

  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
  const [IsModalVisible, setIsModalVisible] = React.useState(false);
  const [IsCreateMailModalVisible, setIsCreateMailModalVisible] =
    React.useState(false);
  const [actionUuid, setActionUuid] = React.useState('');
  const [activityType, setActivityType] = React.useState('');
  const [clickedStepUuid, setClickedStepUuid] = React.useState('');
  const [cancelVisible, setCancelVisible] = React.useState(false);

  const onClickPopoverItem = (
    activityType: 'tel' | 'mail' | 'comment' | 'task',
    step: Step,
  ) => {
    setIsPopoverVisible(false);
    setIsModalVisible(true);
    setActionUuid(stepAction(step).uuid);
    setActivityType(activityType);
  };

  return (
    <Container>
      <Back onClick={() => setDisplaySteps(false)}>{`〈 戻る`}</Back>
      <TitleContainer>
        <WorkflowName>{workflow?.name}</WorkflowName>
        <Button
          danger
          onClick={() =>
            confirm('本当に停止しますか？') && stopWorkflowProspectPool()
          }
          loading={loading}
          disabled={workflowProspectPool.status === 'closed'}>
          停止
        </Button>
      </TitleContainer>

      {workflow?.steps.map((step, index) => (
        <StepContainer key={step.uuid}>
          <StepNumber>{index + 1}</StepNumber>
          <StepContentContainer
            isClosed={stepAction(step)?.status === 'closed'}>
            {stepAction(step) ? (
              <ActionStatusIcon action={stepAction(step)} />
            ) : (
              <Gap width={63} />
            )}
            {
              <Date>
                <span>{moment(stepDates[step.id]).format('YYYY/MM/DD')}</span>
              </Date>
            }
            <Type>
              <StepTypeIcon step={step} active width="24" height="24" />
            </Type>
            <StepNameContainer>
              <StepName>{step.name}</StepName>
            </StepNameContainer>
            {stepAction(step)?.actionableType === 'Action::ManualMail' &&
            stepAction(step)?.status === 'open' ? (
              <MailPlusButton
                onClick={() => {
                  setActionUuid(stepAction(step)?.uuid);
                  setIsCreateMailModalVisible(true);
                }}>
                ＋ メール作成
              </MailPlusButton>
            ) : (
              <Gap width={81} />
            )}
            <Gap width={10} />
            {stepAction(step) && (
              <div>
                <Popover
                  content={
                    <PopoverContent>
                      <PopoverItem
                        onClick={() => onClickPopoverItem('tel', step)}>
                        <TelIcon />
                        <div>TEL</div>
                      </PopoverItem>
                      <PopoverItem
                        onClick={() => onClickPopoverItem('mail', step)}>
                        <MailIcon />
                        <div>メール</div>
                      </PopoverItem>
                      <PopoverItem
                        onClick={() => onClickPopoverItem('comment', step)}>
                        <CommentIcon />
                        <div>コメント</div>
                      </PopoverItem>
                      <PopoverItem
                        onClick={() => onClickPopoverItem('task', step)}>
                        <TaskIcon />
                        <div>タスク</div>
                      </PopoverItem>
                    </PopoverContent>
                  }
                  trigger="click"
                  visible={isPopoverVisible && step.uuid == clickedStepUuid}
                  onVisibleChange={(visible) => setIsPopoverVisible(visible)}>
                  <>
                    <PlusCircleIconWrapper
                      onClick={() => {
                        setIsPopoverVisible(true);
                        setClickedStepUuid(step.uuid);
                      }}>
                      <PlusCircle />
                    </PlusCircleIconWrapper>
                  </>
                </Popover>
              </div>
            )}
            {stepAction(step) && stepAction(step)?.status === 'open' ? (
              <>
                <Popover
                  placement="bottomRight"
                  content={() => (
                    <>
                      <StepPopoverContent>
                        <StepPopoverItem
                          onClick={() =>
                            handleCancelClick(stepAction(step)?.uuid)
                          }>
                          <span>キャンセル</span>
                        </StepPopoverItem>
                      </StepPopoverContent>
                    </>
                  )}
                  trigger="click"
                  onVisibleChange={(e) => setCancelVisible(e)}>
                  <DotMenuSideWrapper>
                    <DotMenuSide
                      color={cancelVisible ? 'var(--primary)' : 'var(--text-color-2)'}
                    />
                  </DotMenuSideWrapper>
                </Popover>
              </>
            ) : (
              <Gap width={20} />
            )}
          </StepContentContainer>
        </StepContainer>
      ))}

      <NewActivityModal
        isOpen={IsModalVisible}
        setIsOpen={setIsModalVisible}
        actionUuid={actionUuid}
        activityType={activityType}
        setActivityType={setActivityType}
        prospectPool={prospectPool}
      />
      <CreateMailModal
        isOpen={IsCreateMailModalVisible}
        setIsOpen={setIsCreateMailModalVisible}
        prospectPool={prospectPool}
        actionUuid={actionUuid}
      />
    </Container>
  );
};

const Gap = styled.div<{width: number}>`
  width: ${({width}) => `${width}px`};
  height: 100%;
`;

const PopoverContent = styled.div`
  padding: 12px 0;
`;

const MailPlusButton = styled.div`
  width: 81px;
  height: 24px;
  background: var(--primary);
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #fff;
  cursor: pointer;
`;

const DotMenuSideWrapper = styled.div`
  cursor: 'pointer';
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopoverItem = styled.div`
  width: 228px;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding-left: 16px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f9ff;
  }

  svg {
    width: 26px;
    height: 22px;
  }
`;

const PlusCircleIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Back = styled.div`
  color: var(--primary);
  cursor: pointer;
  width: 50px;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const WorkflowName = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color-1);
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:not(:last-of-type):before {
    position: relative;
    top: 35px;
    left: 12px;
    display: block;
    height: 46px;
    width: 1px;
    content: '';
    z-index: 0;
    background-color: var(--text-color-3);
  }
`;

const StepNumber = styled.span`
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-color-3);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: var(--text-color-3);
`;

const StepContentContainer = styled.div<{isClosed: boolean}>`
  display: flex;
  align-items: center;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  padding: 16px 14px 16px 14px;
  width: 97%;
  height: 48px;
  opacity: ${({isClosed}) => (isClosed ? 0.5 : '')};
  background: ${({isClosed}) => (isClosed ? '#FAFBFB' : '#fff')};
  z-index: 1;
`;

const Type = styled.div`
  display: flex;
  margin: 0 7px;
`;

const StepNameContainer = styled.div`
  margin-right: auto;
  width: 120px;
  line-height: 1.3;
`;

const StepName = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  width: 55px;

  span:first-child {
    color: var(--text-color-1);
  }
  div {
    display: flex;

    span:first-child {
      color: var(--text-color-2);
    }
    span:last-child {
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }
`;

const StepPopoverContent = styled.div`
  width: 108px;
  height: 43px;
  font-size: 14px;
  color: var(--text-color-1);
  font-weight: normal;
  cursor: pointer;
  background: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StepPopoverItem = styled.div`
  width: 106px;
  height: 28px;
  background: #f2f7fa;
  > span {
    color: var(--text-color-1);
    line-height: 28px;
    display: inline-block;
    height: 100%;
  }
`;
