import {
  Prelead,
  useSubscribePreleadMutation,
  useUnbouncePreleadMutation,
  useUnsubscribePreleadMutation,
} from 'api';
import {Popover} from 'components/antd';
import {DotMenuSide} from 'components/Ui/Icon';
import React, {useState} from 'react';
import styled from 'styled-components';

interface Props {
  prelead: Prelead;
}

export default ({prelead}: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const [unsubscribePrelead] = useUnsubscribePreleadMutation({
    variables: {
      uuid: prelead.uuid,
    },
    onCompleted: () => {
      setIsPopoverVisible(false);
    },
  });
  const [subscribePrelead] = useSubscribePreleadMutation({
    variables: {
      uuid: prelead.uuid,
    },
    onCompleted: () => {
      setIsPopoverVisible(false);
    },
  });

  const [unbounce] = useUnbouncePreleadMutation({
    variables: {
      uuid: prelead.uuid,
    },
    onCompleted: () => {
      setIsPopoverVisible(false);
    },
  });

  const Content = () => {
    return (
      <>
        {prelead.isUnsubscribed && !prelead.company?.isUnsubscribed && (
          <PopoverContent>
            <PopoverItem
              onClick={() => {
                subscribePrelead();
              }}>
              メール配信停止を解除
            </PopoverItem>
          </PopoverContent>
        )}
        {!prelead.isUnsubscribed && (
          <PopoverContent>
            <PopoverItem
              onClick={() => {
                unsubscribePrelead();
              }}>
              メール配信停止
            </PopoverItem>
          </PopoverContent>
        )}
        {prelead.mailAddress && prelead.isBounced && (
          <PopoverItem
            onClick={() => {
              if (confirm('バウンスを解除しますか？')) {
                unbounce();
              }
            }}>
            バウンスを解除
          </PopoverItem>
        )}
      </>
    );
  };

  return (
    <div>
      <Popover
        placement="bottomRight"
        content={Content}
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={(e) => setIsPopoverVisible(e)}>
        <div style={{cursor: 'pointer', display: 'flex', paddingRight: '10px'}}>
          <DotMenuSide color={isPopoverVisible ? 'var(--primary)' : 'var(--text-color-2)'} />
        </div>
      </Popover>
    </div>
  );
};

const PopoverContent = styled.div`
  padding: 10px 0;
`;

const PopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 170px;
  height: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f5f7;
  }
`;
