import React from 'react';

const Icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="var(--text-color-3)"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7V17M7 12H17"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
    <circle
      cx="12"
      cy="12"
      r="10"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></circle>
  </svg>
);

export default Icon;
