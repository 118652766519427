import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {PostmarkBadge} from 'components/Ui/Icon';
import {Activity, LetterStatusChangeActivity} from 'api';
import useProjectName from './useProjectName';
import letterColor from 'helpers/letterColor';
import UserName from './UserName';

interface LetterStatusChange extends Activity {
  resource: LetterStatusChangeActivity;
}

interface Props {
  activity: LetterStatusChange;
}

const LetterStatusChangeActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <PostmarkBadge />
        </BadgeWrapper>
        <UserName activity={activity} />
        <StatusLabel color={letterColor(activity.resource.letterStatus)}>
          {activity.resource.letterStatusText}
        </StatusLabel>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  width: 480px;
  height: 30px;
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  color: var(--text-color-2);
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const StatusLabel = styled.span<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 20px;
  margin: 4px 12px 0 12px;
  padding: 4px 16px;
  color: white;
  font-size: 10px;
  height: 22px;
`;

const TimeStamp = styled.span`
  padding-top: 5px;
`;

export default LetterStatusChangeActivityComponent;
