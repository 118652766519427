import {Button} from 'components/antd';
import {useProjectTelStatusCallDailyReportsQuery, useProjectQuery} from 'api';
import {StatusEclipse} from 'components/Ui';
import {ArrowRight} from 'components/Ui/Icon';
import telCategoryColor from 'helpers/telCategoryColor';
import {telStatusCategoryHash} from 'helpers/telStatusCategoryHash';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import useSearchParams from '../useSearchParams';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();
  const [displayCategories, setDisplayCategories] = React.useState([]);
  const {data: {projectTelStatusCallDailyReports = []} = {}} =
    useProjectTelStatusCallDailyReportsQuery({
      variables: {
        uuid: projectId,
        search: query,
      },
      fetchPolicy: 'network-only',
    });
  const {data: {project: {telStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const categorizedTelStatuses = React.useCallback(
    (category: string) =>
      telStatuses.filter((telStatus) => telStatus.category === category),
    [telStatuses],
  );

  const telStatusCategories = Object.keys(telStatusCategoryHash).filter(
    (category) => !category.includes('ng'),
  );

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );
    if (!value) return null;

    return value;
  };

  const totalCategoryCountOfMonth = React.useCallback(
    (category: string) =>
      projectTelStatusCallDailyReports.reduce(
        (total: number, report: any) => total + totalCount(report[category]),
        0,
      ),
    [projectTelStatusCallDailyReports],
  );

  const totalAllCategoryCountOfMonth = React.useMemo(
    () =>
      telStatusCategories.reduce(
        (total: number, category: string) =>
          total + totalCategoryCountOfMonth(_.camelCase(category)),
        0,
      ),
    [projectTelStatusCallDailyReports],
  );

  const totalAllCategoryCountOfDay = (report: any) =>
    telStatusCategories.reduce(
      (total: number, category: string) =>
        total + totalCount(report[_.camelCase(category)]),
      0,
    );

  const totalStatusCountOfMonth = React.useCallback(
    (category: string, telStatusId) =>
      projectTelStatusCallDailyReports.reduce(
        (total: number, report: any) =>
          report[category][telStatusId]
            ? total + report[category][telStatusId]
            : total,
        0,
      ),
    [projectTelStatusCallDailyReports],
  );

  const toggle = React.useCallback(
    (category: string) => {
      if (displayCategories.includes(category)) {
        setDisplayCategories(
          displayCategories.filter(
            (displayCategory) => displayCategory != category,
          ),
        );
      } else {
        setDisplayCategories([...displayCategories, category]);
      }
    },
    [displayCategories],
  );

  projectTelStatusCallDailyReports.reduce(
    (total: number, report) => total + totalCount(report.call),
    0,
  );

  if (projectTelStatusCallDailyReports.length < 1) return null;

  return (
    <Container>
      <div>
        <h2>ステータス・カテゴリ別</h2>

        <div>
          <Display>
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
            <Button
              type="text"
              className="left"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .startOf('month')
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>
            <Button
              type="text"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .startOf('month')
                    .add(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>

            <Button
              className="today"
              onClick={() =>
                setQuery({date: moment().startOf('month').format('YYYY-MM-DD')})
              }>
              今月
            </Button>
          </Display>
        </div>
      </div>

      <Table>
        <div className="column far-left">
          <div className="far-top">
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
            <span></span>
            <span className="total-count">
              <span>コール件数</span>
              <span>{totalAllCategoryCountOfMonth}</span>
            </span>
          </div>

          {telStatusCategories.map((category) => (
            <React.Fragment key={category}>
              <div
                className="category openable"
                onClick={() => toggle(category)}>
                <Open category={category} displayCategories={displayCategories}>
                  <ArrowRight />
                </Open>
                <StatusEclipse color={telCategoryColor(category)} />
                <span>{telStatusCategoryHash[category]}</span>
                <span>{totalCategoryCountOfMonth(category)}</span>
              </div>
              {displayCategories.includes(category) &&
                categorizedTelStatuses(category).map((telStatus) => (
                  <div key={telStatus.id}>
                    <span>{telStatus.name}</span>
                    <span>
                      {totalStatusCountOfMonth(category, telStatus.id)}
                    </span>
                  </div>
                ))}
            </React.Fragment>
          ))}
        </div>

        {projectTelStatusCallDailyReports.map((report: any) => (
          <div
            className={
              'column ' +
              ((moment(report.countDate).weekday() === 5 ||
                moment(report.countDate).weekday() === 6) &&
                'weekend')
            }
            key={report.countDate}>
            <div
              className={
                'far-top ' +
                (moment(report.countDate).isSame(new Date(), 'day') && 'today')
              }>
              <span>{moment(report.countDate).format('DD')}</span>
              <span>{moment(report.countDate).format('ddd')}</span>
              <span>{totalAllCategoryCountOfDay(report)}</span>
            </div>

            {telStatusCategories.map((category) => (
              <React.Fragment key={category}>
                <div className="category">
                  <span>
                    {report[_.camelCase(category)] &&
                      totalCount(report[_.camelCase(category)])}
                  </span>
                </div>
                {displayCategories.includes(category) &&
                  categorizedTelStatuses(category).map((telStatus) => (
                    <div key={telStatus.id}>
                      <span>
                        {report[category] && report[category][telStatus.id]}
                      </span>
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </div>
        ))}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > h2 {
      margin: auto auto auto 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: var(--text-color-1);
    }

    > div {
      position: relative;
      margin: auto 0 auto auto;
      display: flex;
    }
  }
`;

const Display = styled.div`
  margin-left: 10px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-1);
  }

  > button {
    padding: 5.6px 6px;

    svg {
      path {
        stroke: var(--text-color-2);
      }
    }

    &.left {
      svg {
        transform: rotate(180deg);
      }
    }

    &.today {
      margin-left: 10px;
      padding: 5.6px 10px;
      border: 1px solid #e1e6eb;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  min-height: 300px;
  max-height: 600px;
  width: 969px;

  .column {
    display: flex;
    flex-direction: column;

    > div {
      width: 50px;
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      align-items: center;

      &:first-child {
        > span {
          margin: 0 auto;
        }

        &.far-top {
          top: 0;
          z-index: 3;
          position: sticky;
          min-height: 80px;
          background: #ffffff;
          border-bottom: 1px solid #e1e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          > span {
            margin: auto;
            height: 18px;
          }

          &.today {
            border-top: 2px solid var(--text-color-1);

            span {
              color: var(--text-color-1);
              font-weight: bold;
            }
          }
        }
      }

      &:nth-child(2) {
        border-top: none;
      }

      > span {
        margin: 0 9px 0 auto;
        color: var(--text-color-2);
      }

      &.category {
        > span {
          color: var(--text-color-1);
          font-weight: bold;
        }
      }
    }

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 200px;
        display: flex;
        align-items: center;
        background: #fff;
        padding: 0 13px 0 40px;

        &:first-child {
          padding: 0 13px;

          .total-count {
            margin: auto 0;
            display: flex;
            width: 100%;

            > span {
              &:first-child {
                margin-right: auto;
              }
              &:last-child {
                margin-left: auto;
                font-weight: bold;
                color: var(--text-color-1);
              }
            }
          }
        }

        &.category {
          padding: 0 13px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        &.openable {
          cursor: pointer;
        }

        > span {
          margin: 0;
          font-size: 13px;

          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &.weekend {
      background: #fbfcfd !important;

      .far-top {
        background: #fbfcfd !important;
      }
    }
  }
`;

const Open = styled.div<{category: string; displayCategories: any}>`
  display: flex;
  width: 13px;
  height: 13px;
  > svg {
    ${({category, displayCategories}) =>
      displayCategories.includes(category) && 'transform: rotate(90deg);'}

    width: 13px;
    height: 13px;
    fill: var(--text-color-3);
    path {
      width: 13px;
      height: 13px;
    }
  }
`;
