import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useCurrentClientQuery} from 'api';

const AccountStatusesFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {currentClient: {accountStatuses = []} = {}} = {}} =
    useCurrentClientQuery({});

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.accountStatusIds || []}
      onChange={(value) => setQuery({page: 1, accountStatusIds: value})}>
      {accountStatuses.map((accountStatus) => (
        <StatusSelect.Option
          value={accountStatus.id}
          key={accountStatus.id}
          label={accountStatus.name}>
          {accountStatus.name}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default AccountStatusesFilter;
