import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {
  ProspectPool,
  useUpdateSalesforceContactOwnerMutation,
  useUpdateSalesforceLeadOwnerMutation,
} from 'api';
import {Cross} from 'components/Ui/Icon';
import {SalesforceUserSelector} from 'components/Prospect/SalesforceUserSelector';

interface Props {
  prospectPool: ProspectPool;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({prospectPool, isModalVisible, setIsModalVisible}: Props) => {
  const [erorrMessage, setErrorMessage] = React.useState('');

  const salesforceContact = prospectPool.salesforceContact;
  const salesforceLead = prospectPool.salesforceLead;
  const salesforceUser =
    salesforceContact?.salesforceOwner || salesforceLead?.salesforceOwner;

  const [updateContactOwner] = useUpdateSalesforceContactOwnerMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceContactOwner.error);
    },
  });
  const [updateLeadOwner] = useUpdateSalesforceLeadOwnerMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceLeadOwner.error);
    },
  });

  const onUpdate = (salesforceUserIdString: string) => {
    if (salesforceContact) {
      updateContactOwner({
        variables: {
          id: salesforceContact.id,
          salesforceUserIdString: salesforceUserIdString,
        },
      });
    } else if (salesforceLead) {
      updateLeadOwner({
        variables: {
          id: salesforceLead.id,
          salesforceUserIdString: salesforceUserIdString,
        },
      });
    }
  };

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '400px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '466px',
      minHeight: '200px',
      padding: '30px 40px',
      borderRadius: '10px',
      overflow: 'visible',
    },
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => closeModal()}
      style={customStyles}>
      <Header>
        <div>Salesforce所有者を変更</div>
        <CrossIconWrapper onClick={() => closeModal()}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <SalesforceUserSelector
        salesforceUser={salesforceUser}
        onChange={(salesforceUserIdString) => onUpdate(salesforceUserIdString)}
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 20px;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -5px;
`;
