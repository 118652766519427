import React from 'react';
import {usePoolProspectCustomizeItemsQuery} from 'api';
import useCondition from '../../useCondition';

export default () => {
  const {condition} = useCondition();
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});

  const customizeItemById = (id: string) => {
    return poolProspectCustomizeItems.find(
      (poolProspectCustomizeItem) => poolProspectCustomizeItem.id === id,
    );
  };

  if (
    !condition?.prospectCustomizeItemSearchConditions ||
    !condition.prospectCustomizeItemSearchConditions.length
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">カスタマイズ項目</h4>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap flex-col gap-2">
          {condition.prospectCustomizeItemSearchConditions.map(
            (item, index) => {
              return (
                <div key={index} className="flex gap-3">
                  <div className="text-foreground-light text-sm min-w-28 text-center truncate">
                    {`${customizeItemById(item['customize_item_id'])?.name}:`}
                  </div>
                  {item.data_type === 'select' && (
                    <div className="flex flex-wrap gap-1">
                      {item.values?.length > 0 &&
                        item.values.map((option: string, index: number) => {
                          return (
                            <div
                              key={index}
                              className="min-w-16 bg-c-bg border border-c-border text-center truncate text-sm px-2 rounded-sm flex justify-center items-center">
                              {
                                customizeItemById(
                                  item['customize_item_id'],
                                )?.selectOptions?.find(
                                  (item) => item.id === option,
                                )?.label
                              }
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {item.data_type === 'date' && (
                    <div className="flex items-center gap-2">
                      <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                        {item.from ? `${item.from}` : '未設定'}
                      </span>
                      <span className="text-c-light">~</span>
                      <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                        {item.to ? `${item.to}` : '未設定'}
                      </span>
                    </div>
                  )}
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};
