import React from 'react';
import styled from 'styled-components';
import {Row} from 'react-table';
import {MailCampaign} from 'api';

const statusStyle: {[key: string]: React.CSSProperties} = {
  draft: {
    background: '#EAECEE',
    color: 'var(--text-color-2)',
  },
  ready: {
    background: '#F9E3C8',
    color: '#FF8F00',
  },
  sent: {
    background: '#D0E8DA',
    color: '#4FCE82',
  },
  sending: {
    background: '#E5EEFA',
    color: '#2F80ED',
  },
  blastengine_ready: {
    background: '#F9E3C8',
    color: '#FF8F00',
  },
};

interface Props {
  row: Row<MailCampaign>;
}

const Cell = ({row}: Props) => {
  const mailCampaign = row.original;
  return (
    <Status style={statusStyle[mailCampaign.status]}>
      {mailCampaign.statusText}
    </Status>
  );
};

const Status = styled.span`
  display: flex;
  height: 24px;
  font-size: 12px;
  width: 80px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
`;

export default Cell;
