import React from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import ArrowRightReport from 'components/Ui/Icon/ArrowRightReport';
import useProjectPath from 'hooks/useProjectPath';

export const BoxReport = () => {
  const {projectId} = useParams<{
    projectId: string;
  }>();
  const {createPath} = useProjectPath();

  return (
    <Box>
      {lists.map((list) => (
        <StyledLink
          key={list.title}
          to={createPath(`projects/${projectId}/${list.pathName}`)}>
          <Tabs key={list.title}>
            <div>
              <p>{list.title}</p>
              <span>{list.body}</span>
            </div>
            <ArrowRightReport />
          </Tabs>
        </StyledLink>
      ))}
    </Box>
  );
};

const lists = [
  {
    title: 'フォーム送信アプローチ結果',
    body: 'フォーム送信結果のサマリを確認することができます。',
    pathName: 'form_mail_reports/all/send_count',
  },
  {
    title: 'TELレポート',
    body: '架電アプローチのサマリや転換率、コール率などを確認することができます。',
    pathName: 'tel_reports/all/call',
  },
  {
    title: '担当者別',
    body: 'メンバーの架電アプローチの詳細を確認することができます。',
    pathName: 'tel_reports/user/call',
  },
  {
    title: '項目別',
    body: 'タグのプレリード数や構成率の詳細を確認することができます。',
    pathName: 'prelead_reports/items/tag',
  },
];

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tabs = styled.div`
  display: flex;
  width: 260px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  padding: 16px;
  margin-right: 10px;
  margin-bottom: 20px;
  p {
    font-size: 14px;
    font-weight: bold;
  }
  span {
    font-size: 12px;
    color: 'var(--text-color-2)';
  }
`;

const StyledLink = styled(Link)`
  color: black;
`;
