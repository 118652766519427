import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {Activity, useCurrentClientQuery} from 'api';

interface Props {
  activity: any;
}

export default ({activity}: Props) => {
  const {offeredClientId} = useParams<{offeredClientId: string}>();

  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  return (
    <Container>
      <UserName>
        {activity.user?.lastName} {activity.user?.firstName}
      </UserName>
      {offeredClientId && currentClient.id !== activity.user?.clientId && (
        <ClientName>{activity.user?.client?.name}</ClientName>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserName = styled.span`
  font-weight: 700;
  display: block;
  line-height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ClientName = styled.span`
  color: var(--text-color-3);
`;
