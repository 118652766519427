import React from 'react';
import styled from 'styled-components';
import {Form} from 'components/antd';
import {ContentMailFormVersion} from 'api';

interface Props {
  version: ContentMailFormVersion;
}

export default ({version}: Props) => {
  const [messageType, setMessageType] = React.useState('long');

  return (
    <Container>
      <Form>
        <Form.Item label="件名" required>
          <Title>{version.title}</Title>
        </Form.Item>
        <Form.Item label="本文" required>
          <div>
            <Menu>
              <MenuItems>
                <MenuItem
                  active={messageType === 'long'}
                  onClick={() => setMessageType('long')}>
                  長文バージョン
                </MenuItem>
                <MenuItem
                  active={messageType === 'short'}
                  onClick={() => setMessageType('short')}>
                  短文バージョン
                </MenuItem>
                <MenuItem
                  active={messageType === 'mail'}
                  onClick={() => setMessageType('mail')}>
                  Emailバージョン
                </MenuItem>
              </MenuItems>
            </Menu>
            {messageType === 'long' && (
              <>
                <Count>文字数:{version.message1.length}</Count>
                <Message>{version.message1}</Message>
              </>
            )}
            {messageType === 'short' && (
              <>
                <Count>文字数:{version.message2.length}</Count>
                <Message>{version.message2}</Message>
              </>
            )}
            {messageType === 'mail' && (
              <>
                <Count>文字数:{version.messageMail.length}</Count>
                <Message>{version.messageMail}</Message>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-size: 13px;
    color: var(--text-color-2);
    font-weight: bold;
  }
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  color: var(--text-color-2);
  margin: 1rem 0 0.5rem;
`;

const Menu = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const MenuItems = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  background-color: #f3f5f7;
  border-radius: 12px;
`;

const MenuItem = styled.div<{active?: boolean}>`
  cursor: pointer;
  background-color: ${({active}) => (active ? '#DADCDE' : 'transparent')};
  margin: 0;
  height: 24px;
  line-height: 24px;
  padding: 0 1rem;
  border-radius: 12px;
  font-size: 10px;
  font-weight: ${({active}) => (active ? '700' : '400')};
  color: ${({active}) => (active ? 'var(--text-color-1)' : 'var(--text-color-2)')};

  &:first-child::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 12px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

const Title = styled.div`
  background: #f4f5f7;
  padding: 5.6px 11px;
  border-radius: 3px;
  min-height: 31.19px;
`;

const Message = styled.p`
  padding: 5.6px 11px;
  border-radius: 3px;
  background: #f4f5f7;
  white-space: pre-wrap;
  min-height: 15rem;
`;
