import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none">
      <circle cx="15" cy="15" r="15" fill="#F4F4F4"></circle>
      <g transform="translate(8, 8)">
        <path
          d="M3 7H12"
          stroke="var(--text-color-2)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 3H12"
          stroke="var(--text-color-2)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 11H7"
          stroke="var(--text-color-2)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Icon;
