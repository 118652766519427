import React from 'react';

function Icon({className}: {className?: string}) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M7.58268 10.4997C7.58268 10.1775 7.32152 9.91634 6.99935 9.91634C6.67718 9.91634 6.41602 10.1775 6.41602 10.4997C6.41602 10.8218 6.67718 11.083 6.99935 11.083C7.32152 11.083 7.58268 10.8218 7.58268 10.4997Z"
        stroke="var(--text-color-2)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58268 6.99967C7.58268 6.67751 7.32152 6.41634 6.99935 6.41634C6.67718 6.41634 6.41602 6.67751 6.41602 6.99967C6.41602 7.32184 6.67718 7.58301 6.99935 7.58301C7.32152 7.58301 7.58268 7.32184 7.58268 6.99967Z"
        stroke="var(--text-color-2)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58268 3.49967C7.58268 3.17751 7.32152 2.91634 6.99935 2.91634C6.67718 2.91634 6.41602 3.17751 6.41602 3.49967C6.41602 3.82184 6.67718 4.08301 6.99935 4.08301C7.32152 4.08301 7.58268 3.82184 7.58268 3.49967Z"
        stroke="var(--text-color-2)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
