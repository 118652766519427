import React from 'react';

function Icon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.7555 1L8.57507 1.06584C8.95832 1.07971 9.29844 1.32173 9.44532 1.6851L9.98334 3.01606C10.1086 3.32579 10.0749 3.67918 9.89352 3.95816L9.20421 5.01874C9.61233 5.60479 10.7224 6.97789 11.8978 7.78164L12.7743 7.24224C12.997 7.10514 13.2637 7.06444 13.5158 7.12904L15.2581 7.57584C15.7215 7.69469 16.0338 8.13909 15.9972 8.62759L15.8853 10.1192C15.846 10.6427 15.4176 11.0534 14.9091 10.9943C8.19628 10.2138 4.25978 0.999985 6.7555 1Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49994 17L7.99994 13.8889M5.49994 9V17V9ZM5.49994 17L2.99994 13.8889L5.49994 17Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
