import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useHistory, useParams} from 'react-router';
import {Breadcrumb, Container} from 'components/Ui';
import {
  useCompanyQuery,
  useFixProjectMutation,
  useProjectQuery,
  useSearchCountQuery,
  useUpdateProjectSearchConditionMutation,
} from 'api';
import Company from './company';
import SearchCondition from './searchCondition';
import {Button, Tooltip} from 'components/antd';
import {Check} from 'components/Icon';
import useSearchCondition from './useSearchCondition';
import useContract from 'hooks/useContract';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId, companyId} = useParams<{
    projectId: string;
    companyId: string;
  }>();
  const {query} = useSearchCondition();
  const {isPreleadFixable, isProjectAddable} = useContract();
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const {data: {company = null} = {}} = useCompanyQuery({
    variables: {uuid: companyId},
    skip: !companyId,
  });

  const {data: {searchCount = 0} = {}, loading} = useSearchCountQuery({
    variables: {
      search: query,
    },
    skip: !query,
  });

  const [updateSearchCondition] = useUpdateProjectSearchConditionMutation({
    variables: {
      uuid: projectId,
      search: query,
    },
    onCompleted: () =>
      history.push(
        createPath(
          `projects/${projectId}/search/category?status=draft&specificCompanyId=${companyId}`,
        ),
      ),
  });

  const [fixProject] = useFixProjectMutation({
    variables: {
      uuid: projectId,
      search: query,
    },
    onCompleted: () =>
      history.push(
        createPath(`projects/${projectId}/project_summary?status=open`),
      ),
  });

  if (!company) return null;

  return (
    <Container page="newProject">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={createPath('projects')}>営業リスト一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={createPath(
                `projects/${project?.uuid}?status=${project.status}`,
              )}>
              {project?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={createPath(`projects/${project?.uuid}/specific_companies`)}>
              企業情報検索
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="current">{company?.name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <ActionWrapper>
        <Button
          onClick={() =>
            history.push(
              createPath(`projects/${project?.uuid}/specific_companies`),
            )
          }>
          戻る
        </Button>

        <Button loading={loading} onClick={() => updateSearchCondition()}>
          検索条件を変更する
        </Button>

        {isPreleadFixable && isProjectAddable ? (
          <Button
            type="primary"
            loading={loading}
            disabled={searchCount > 10000}
            onClick={() => fixProject()}>
            <Check />
            確定する
          </Button>
        ) : isPreleadFixable && !isProjectAddable ? (
          <Tooltip
            title="営業リスト数が上限に達しました。"
            color={`#EB5757`}
            placement="top">
            <DisabledActionButton>
              <Check />
              確定する
            </DisabledActionButton>
          </Tooltip>
        ) : null}
      </ActionWrapper>
      <Body>
        <div>
          <h2>特定した企業情報</h2>
          <Company companyId={companyId} height={'750px'} />
        </div>
        <div>
          <h2>この企業の条件項目</h2>
          <SearchCondition />
        </div>
      </Body>
    </Container>
  );
};

const Header = styled.div`
  padding-left: 32px;
  border-bottom: 1px solid #e1e6eb;
`;

const ActionWrapper = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: #fff;

  button {
    margin-right: 21px;
    display: flex;
    align-items: center;
    &:first-child {
      margin-left: auto;
    }
    svg {
      margin-right: 8px;
      width: auto;
      height: auto;
      path {
        fill: #fff;
      }
    }
  }
`;

const Body = styled.div`
  background: #f3f5f7;
  padding: 36px 42px 18px 42px;
  display: flex;
  overflow: scroll;

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 4px;
    }
    h2 {
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color-1);
    }
  }
`;

const DisabledActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 13px;
  width: 100px;
  height: 36px;
  font-weight: bold;
  background: var(--border-color);
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: #ffffff;

  svg {
    position: relative;
    right: 5px;
    width: 15px;
    height: 15px;
    path {
      stroke: #ffffff;
      fill: #ffffff;
    }
  }
`;
