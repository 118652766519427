import React, {useState} from 'react';
import styled from 'styled-components';
import {Breadcrumb} from 'components/antd';
import {Button} from 'components/antd';
import Table from './Table';
import Title from '../../Title';
import useSearchParams from './useSearchParams';
import SelectModal from './SelectModal';
import NewModal from './NewModal';
import TemplateModal from './TemplateModal';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const [selectModal, setSelectModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState(null);
  const {query, setQuery} = useSearchParams();
  const {isMember} = useClientUser();

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>ワークフロー</Breadcrumb.Item>
      </Breadcrumb>
      <Title title="ワークフロー" />
      <Contents>
        <Header>
          <Menu>
            <NavMenu>
              <NavItem
                active={query.status === 'open' || query.status === undefined}
                onClick={() => setQuery({status: 'open', page: 1})}>
                開始
              </NavItem>
              <NavItem
                active={query.status === 'draft'}
                onClick={() => setQuery({status: 'draft', page: 1})}>
                下書き
              </NavItem>
              <NavItem
                active={query.status === 'closed'}
                onClick={() => setQuery({status: 'closed', page: 1})}>
                終了
              </NavItem>
            </NavMenu>
          </Menu>
          {isMember && (
            <ActionButton
              type="primary"
              ghost
              onClick={() => setSelectModal(true)}>
              + ワークフローを作成
            </ActionButton>
          )}
        </Header>
        <Table />
      </Contents>
      <SelectModal
        visible={selectModal}
        setVisible={setSelectModal}
        setNewModal={setNewModal}
        setTemplateModal={setTemplateModal}
      />
      <NewModal
        visible={newModal}
        setVisible={setNewModal}
        setSelectModal={setSelectModal}
        selectTemplate={selectTemplate}
        setSelectTemplate={setSelectTemplate}
      />
      <TemplateModal
        visible={templateModal}
        setVisible={setTemplateModal}
        setSelectModal={setSelectModal}
        setNewModal={setNewModal}
        setSelectTemplate={setSelectTemplate}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow-y: scroll;
`;

const Contents = styled.div``;

const Header = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

const ActionButton = styled(Button)`
  height: 40px;
  width: 180px;
  border-radius: 4px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  display: flex;
  font-weight: bold;
  color: var(--text-color-3);
  margin-right: auto;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NavItem = styled.div<{active?: boolean}>`
  display: inline-block;
  padding-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
  color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
  border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
  height: 40px;
`;
