import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.50001 9.47511L2.02501 6.00011L0.841675 7.17511L5.50001 11.8334L15.5 1.83345L14.325 0.658447L5.50001 9.47511Z"
      fill={color}
    />
  </svg>
);

export default Icon;
