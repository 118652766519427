import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Cross} from 'components/Ui/Icon';
import {useFormik} from 'formik';
import {Button, Form, Select, DatePicker} from 'components/antd';
import {
  useUpdateCallProspectActivityMutation,
  useCurrentClientQuery,
  CallProspectActivity,
} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {Quill, QuillWrapper} from 'components/Ui';
import {prospectStages} from 'helpers/prospectStages';

interface Props {
  setIsCallEdit: React.Dispatch<React.SetStateAction<boolean>>;
  call: CallProspectActivity;
}

export default ({setIsCallEdit, call}: Props) => {
  const onClose = () => setIsCallEdit(false);

  const dateTimeFormat = 'YYYY-MM-DD HH:mm';

  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const [updateCallProspectActivity, {loading}] =
    useUpdateCallProspectActivityMutation({
      onCompleted: onClose,
    });

  const formik = useFormik({
    initialValues: {
      stage: prospectStages.find((stage) => stage.name === call.stage)?.id,
      recordedAt:
        call?.recordedAt || moment().startOf('hour').format(dateTimeFormat),
      comment: call?.comment,
      commentHtml: call?.commentHtml,
      clientProspectTelStatusId: call?.clientProspectTelStatus?.id,
    },
    onSubmit: (values) =>
      updateCallProspectActivity({
        variables: {
          id: call.id,
          attributes: {
            stage: values.stage,
            recordedAt: values.recordedAt,
            comment: values.comment,
            commentHtml: values.commentHtml,
            clientProspectTelStatusId: values.clientProspectTelStatusId,
          },
        },
      }),
  });

  return (
    <Container>
      <Header>
        <h4>TELのログを編集</h4>
        <CloseButton type="text" onClick={onClose}>
          <Cross />
        </CloseButton>
      </Header>

      <Form onFinish={formik.handleSubmit}>
        <Form.Item
          style={{
            margin: 0,
            paddingLeft: '14px',
          }}>
          <Form.Item
            label="ステップ"
            style={{
              display: 'inline-block',
              width: '222px',
              marginRight: '15px',
            }}>
            <Select disabled />
          </Form.Item>
          <Form.Item
            label="TELステータス"
            style={{display: 'inline-block', width: '226px'}}>
            <Select
              value={formik.values.clientProspectTelStatusId}
              onChange={(value) =>
                formik.setFieldValue('clientProspectTelStatusId', value)
              }>
              <Select.Option value={null}>未選択</Select.Option>
              {prospectTelStatuses.map((telStatus) => (
                <Select.Option key={telStatus.id} value={telStatus.id}>
                  {telStatus.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item style={{margin: 0, paddingLeft: '14px'}}>
          <Form.Item
            label="ステージ"
            style={{
              display: 'inline-block',
              width: '222px',
              marginRight: '15px',
            }}>
            <Select
              value={formik.values.stage}
              onChange={(value) => formik.setFieldValue('stage', value)}>
              {prospectStages.map((stage) => (
                <Select.Option key={stage.id} value={stage.id}>
                  {stage.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="日時" style={{display: 'inline-block'}}>
            <DatePicker
              showTime
              allowClear={false}
              format={dateTimeFormat}
              defaultValue={moment(formik.values.recordedAt, dateTimeFormat)}
              locale={locale}
              style={{width: '226px'}}
              onChange={(value) =>
                formik.setFieldValue(
                  'recordedAt',
                  moment(value).format(dateTimeFormat),
                )
              }
              minuteStep={15}
            />
          </Form.Item>
        </Form.Item>

        <QuillWrapper
          style={{
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: 0,
          }}>
          <Quill
            defaultValue={formik.values.commentHtml}
            placeholder="コメント"
            theme="snow"
            onChange={(content, delta, source, editor) => {
              formik.setFieldValue('commentHtml', content);
              formik.setFieldValue('comment', editor.getText());
            }}
            modules={{
              toolbar: [
                [{header: [1, 2, 3, false]}],
                ['bold', 'italic', 'underline'],
                ['code-block'],
                ['link'],
              ],
            }}
            formats={[
              'header',
              'list',
              'bold',
              'italic',
              'underline',
              'strike',
              'color',
              'background',
              'code-block',
              'link',
            ]}
            bounds={'#quill-container'}
          />
          <div id="quill-container" />
        </QuillWrapper>

        <Actions>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!formik.dirty || !formik.isValid || loading}>
            登録
          </Button>
        </Actions>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 420px;
  border: 1px solid var(--border-color);
  border-radius: 4px;

  h4 {
    font-size: 14px;
    margin: 0;
  }

  form {
    margin: 15px 0;
  }

  .ant-form-item-label {
    padding: 0;

    > label {
      color: var(--text-color-2);
      height: 0;
    }

    > label::after {
      display: none;
    }
  }

  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
  }
`;

const CloseButton = styled(Button)`
  padding: 0;
  height: 8px;
  display: flex;
  align-items: center;

  svg {
    width: 8px;
    height: 8px;

    path {
      stroke: var(--text-color-1);
    }
  }
`;

const Actions = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: row-reverse;

  button {
    margin-right: 15px;
    width: 113px;
    height: 32px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f5f7;
  padding: 10px 15px;
`;
