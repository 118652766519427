import React from 'react';
import styled from 'styled-components';
import {Form} from 'components/antd';
import {ContentMailFormVersion} from 'api';

interface Props {
  version: ContentMailFormVersion;
}

export default ({version}: Props) => (
  <Container>
    <Form labelCol={{span: 4}} wrapperCol={{span: 20}} layout="horizontal">
      <Form.Item label="会社名" required>
        <Input>{version.companyName}</Input>
      </Form.Item>
      <Form.Item label="会社名(かな)" required>
        <Input>{version.companyHiragana}</Input>
      </Form.Item>
      <Form.Item label="会社名(カナ)" required>
        <Input>{version.companyKana}</Input>
      </Form.Item>
      <Form.Item label="名前(姓)" required>
        <Input>{version.lastName}</Input>
      </Form.Item>
      <Form.Item label="名前(名)" required>
        <Input>{version.firstName}</Input>
      </Form.Item>
      <Form.Item label="名前(姓)(かな)" required>
        <Input>{version.lastNameKana}</Input>
      </Form.Item>
      <Form.Item label="名前(名)(かな)" required>
        <Input>{version.firstNameKana}</Input>
      </Form.Item>
      <Form.Item label="名前(姓)(カナ)" required>
        <Input>{version.lastNameKana2}</Input>
      </Form.Item>
      <Form.Item label="名前(名)(カナ)" required>
        <Input>{version.firstNameKana2}</Input>
      </Form.Item>
      <Form.Item label="部署">
        <Input>{version.section}</Input>
      </Form.Item>
      <Form.Item label="役職">
        <Input>{version.sectionPosition}</Input>
      </Form.Item>
      <Form.Item label="メールアドレス" required>
        <Input>{version.email}</Input>
      </Form.Item>
      <Form.Item label="TEL" required>
        <Input>
          {version.tel1}-{version.tel2}-{version.tel3}
        </Input>
      </Form.Item>
      <Form.Item label="郵便番号" required>
        <Input>
          {version.zipcode1}-{version.zipcode2}
        </Input>
      </Form.Item>
      <Form.Item label="都道府県" required>
        <Input>{version.state}</Input>
      </Form.Item>
      <Form.Item label="市区町村" required>
        <Input>{version.city}</Input>
      </Form.Item>
      <Form.Item label="番地" required>
        <Input>{version.street}</Input>
      </Form.Item>
      <Form.Item label="ビル">
        <Input>{version.building}</Input>
      </Form.Item>
      <Form.Item label="URL" required>
        <Input>{version.url}</Input>
      </Form.Item>
    </Form>
  </Container>
);

const Container = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-size: 13px;
    color: var(--text-color-2);
    font-weight: bold;
  }
`;

const Input = styled.div`
  background: #f4f5f7;
  padding: 5.6px 11px;
  border-radius: 3px;
  min-height: 31.19px;
`;
