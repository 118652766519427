import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Popover} from 'components/antd';
import mailColor from 'helpers/mailColor';
import {StatusEclipse} from 'components/Ui';
import {Calendar} from 'components/Ui/Icon';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const MailCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const color = mailColor(preleadProject.mailStatus);
  const text = preleadProject.mailStatusText;

  const mailSendAndMailFormSendActivities = React.useMemo(
    () =>
      preleadProject.prelead.activities
        .filter(
          (activity) =>
            (activity.resourceType === 'Activity::MailFormSend' ||
              activity.resourceType === 'Activity::MailSend' ||
              activity.resourceType === 'Activity::ExtMailFormSend') &&
            activity.preleadProjectId === preleadProject.id,
        )
        .reverse(),
    [preleadProject],
  );

  const showActivities = React.useMemo(
    () =>
      preleadProject.prelead.activities
        .map((activity) => activity.preleadProjectId)
        .includes(preleadProject.id) &&
      mailSendAndMailFormSendActivities.length > 0,
    [preleadProject, mailSendAndMailFormSendActivities],
  );

  return (
    <Popover
      trigger="hover"
      placement="bottom"
      content={
        showActivities && (
          <PopoverContent>
            <PopoverTitle>
              <Calendar />
              送信日時
            </PopoverTitle>
            <PopoverItems>
              {mailSendAndMailFormSendActivities.map((activity, index) => {
                return (
                  <PopoverItem key={index}>
                    {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
                  </PopoverItem>
                );
              })}
            </PopoverItems>
          </PopoverContent>
        )
      }>
      <Cell>
        <StatusEclipse color={color} />
        {text}
      </Cell>
    </Popover>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

const PopoverContent = styled.div`
  width: 207px;
  text-align: center;
  max-height: 300px;
  overflow-y: scroll;
`;

const PopoverTitle = styled.div`
  display: inline-block;
  width: 120px;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--text-color-3);
  margin-top: 12px;
  padding-bottom: 7px;
  border-bottom: 1px solid var(--border-color);

  svg {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: 4px;
  }
`;

const PopoverItems = styled.div`
  margin-top: 8px;
  padding-bottom: 15px;
`;

const PopoverItem = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin: 10px 0px;
  color: var(--text-color-1);
`;

export default MailCell;
