import React from 'react';
import styled from 'styled-components';
import {Route, Switch, Link} from 'react-router-dom';
import employeeRangeReport from './employeeRange';

export default () => {
  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('employee_range')}>
          <Link to={'/report/mail/company_profile/employee_range'}>
            従業員数別
          </Link>
        </TabItem>
      </Tabs>

      <Switch>
        <Route
          path={'/report/mail/company_profile/employee_range'}
          component={employeeRangeReport}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div``;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
