import React from 'react';
import {Setting} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {
  ContentMail,
  ContentDocument,
  ContentAudio,
  ContentTelScript,
} from 'components/Ui/Icon';
import Modal from './Modal';
import {useProjectWithContentsQuery} from 'api';
import {Link} from 'react-router-dom';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const {isMember} = useClientUser();

  const {
    data: {
      project: {
        contentMailFormVersion = null,
        contentDocuments = [],
        contentAudio = null,
        contentTelScript = null,
      } = {},
    } = {},
  } = useProjectWithContentsQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Title>
        <h3>コンテンツ</h3>
        {isMember && (
          <Button type="text" onClick={() => setModalVisible(true)}>
            <Setting />
          </Button>
        )}
        {modalVisible && (
          <Modal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
        )}
      </Title>
      <List>
        {contentMailFormVersion && (
          <div>
            <ContentMail />
            <em>V{contentMailFormVersion.version}</em>
            <Link
              to={`/garage/contents/${contentMailFormVersion.mailForm.content.uuid}/mail_form`}>
              <span>{contentMailFormVersion.mailForm.content.title}</span>
            </Link>
          </div>
        )}
        {contentDocuments.map((contentDocument) => (
          <div key={contentDocument.id}>
            <ContentDocument />
            <Link
              to={`/garage/contents/${contentDocument.content.uuid}/document`}>
              <span>{contentDocument.content.title}</span>
            </Link>
          </div>
        ))}
        {contentAudio && (
          <div>
            <ContentAudio />
            <Link to={`/garage/contents/${contentAudio.content.uuid}/audio`}>
              <span>{contentAudio.content.title}</span>
            </Link>
          </div>
        )}
        {contentTelScript && (
          <div>
            <ContentTelScript />
            <Link
              to={`/garage/contents/${contentTelScript.content.uuid}/tel_script`}>
              <span>{contentTelScript.content.title}</span>
            </Link>
          </div>
        )}
      </List>
    </>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-3);
    flex: 1;
  }

  button {
    padding: 0;
    &:hover {
      background: transparent;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: var(--text-color-2);
  }
`;

const List = styled.div`
  > * {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    svg {
      width: 20px;
      height: auto;
      margin-right: 0.5rem;
    }

    em {
      display: inline-block;
      font-style: normal;
      color: #fff;
      background: var(--text-color-2);
      border-radius: 2px;
      height: 18px;
      line-height: 18px;
      font-size: 11px;
      font-weight: bold;
      padding: 0 3px;
      margin-right: 0.5rem;
    }

    a {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
