import React from 'react';
const CommentIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 1.91667V11.1292C0.75 11.389 1.06417 11.5191 1.2479 11.3354L3.49581 9.08752C3.60521 8.97815 3.75358 8.91667 3.90829 8.91667H10.0833C10.7277 8.91667 11.25 8.39435 11.25 7.75V1.91667C11.25 1.27233 10.7277 0.75 10.0833 0.75H1.91667C1.27233 0.75 0.75 1.27233 0.75 1.91667Z"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CommentIcon;
