import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Tabs>
      <TabItem active={location.pathname.includes('/report/call')}>
        <Link to={`/report/call/all/call`}>TEL</Link>
      </TabItem>
      <TabItem active={location.pathname.includes('/report/mail')}>
        <Link to={`/report/mail/project`}>フォーム送信</Link>
      </TabItem>
    </Tabs>
  );
};

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 20px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
