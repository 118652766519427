import React from 'react';
import styled from 'styled-components';
import Tab from './Tab';
import ProjectSearch from './Search';
import ProjectList from './ProjectList';
import Detail from './detail';
import Title from './Title';
import {Link, useParams, Switch, Route} from 'react-router-dom';
import useProjectGroup from 'hooks/useProjectGroup';
import useClientUser from 'hooks/useClientUser';
import useProjectGroups from 'hooks/useProjectGroups';
import {RecoilRoot} from 'recoil';
import projectRoutePaths from 'helpers/projectRoutePaths';
import Preleads from './detail/preleads';
import Search from './detail/search';
import SpecificCompanies from './detail/specificCompanies';
import SpecificCompany from './detail/specificCompanies/detail';
import {
  Header,
  Container,
  Breadcrumb,
  ConnectButton,
  NewProjectModal,
} from 'components/Ui';
import {ArrowDown} from 'components/Ui/Icon';
import {
  ProjectGroup,
  useCurrentClientQuery,
  useCreateProjectMutation,
} from 'api';
import TelReport from './detail/telReport';
import PreleadReport from './detail/preleadReport';
import FormMailReport from './detail/formMailReport';
import {useHistory} from 'react-router';
import useProjectPath from 'hooks/useProjectPath';
import {Button, Popover} from 'components/antd';

const Projects = () => {
  const {
    projectGroupId: projectGroupUuid,
    requestClientId,
    offeredClientId,
  } = useParams<{
    projectGroupId: string;
    requestClientId: string;
    offeredClientId: string;
  }>();
  const {isAdmin, isMember} = useClientUser();
  const {isProjectGroupAccessible} = useProjectGroup(projectGroupUuid);
  const {
    accessibleProjectGroups: projectGroups,
    defaultProjectGroup = {},
    selectedProjectGroup = {},
  } = useProjectGroups();

  const [createProject] = useCreateProjectMutation({
    variables: {
      attributes: {
        name: '新規営業リスト',
        status: 'draft',
        projectType: 'search',
        projectGroupId: selectedProjectGroup.id || defaultProjectGroup.id,
      },
    },
    onCompleted: ({createProject: {project}}) => {
      history.push(createPath(`projects/${project.uuid}/search/category`));
    },
  });

  const [createProjectBySpecificCompany] = useCreateProjectMutation({
    variables: {
      attributes: {
        name: '新規営業リスト',
        status: 'draft',
        projectType: 'search',
        projectGroupId: selectedProjectGroup.id || defaultProjectGroup.id,
      },
    },
    onCompleted: ({createProject: {project}}) => {
      history.push(createPath(`projects/${project.uuid}/specific_companies`));
    },
  });

  const {
    data: {
      currentClient: {offeredClients = [], requestActiveClients = []} = {},
    } = {},
  } = useCurrentClientQuery();

  const projectGroup: ProjectGroup = React.useMemo(
    () =>
      projectGroups.find(
        (projectGroup) => projectGroup.uuid === projectGroupUuid,
      ),
    [projectGroupUuid, projectGroups],
  );

  const offeredClient = React.useMemo(
    () => offeredClients.find((client) => client.uuid === offeredClientId),
    [offeredClients],
  );
  const requestClient = React.useMemo(
    () =>
      requestActiveClients.find((client) => client.uuid === requestClientId),
    [requestClientId],
  );

  const history = useHistory();
  const {createPath} = useProjectPath();

  const [isNewProjectModalOpen, setIsNewProjectModalOpen] =
    React.useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

  if (!isAdmin && !isProjectGroupAccessible) return null;

  return (
    <Container
      page={
        location.pathname.startsWith('/list/projects') ? 'projects' : 'group'
      }>
      {!location.pathname.startsWith('/list/projects') && (
        <Header>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                className="name"
                to={
                  projectGroup
                    ? `/list/groups`
                    : offeredClient
                      ? `/list/share/offered`
                      : `/list/share/request`
                }>
                {projectGroup && 'グループ'}
                {offeredClient && '依頼先'}
                {requestClient && '依頼元'}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="current">
                {projectGroup && projectGroup?.name}
                {offeredClient && offeredClient.name}
                {requestClient && requestClient.name}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Header>
      )}

      <Body>
        <div className="flex">
          <Title />
          {!location.pathname.includes('list/share/offered') && (
            <div style={{display: 'flex'}}>
              <ConnectButton />
              {isMember && (
                <div style={{display: 'flex'}}>
                  <Button
                    className="ant-btn-left"
                    type="primary"
                    onClick={() => setIsNewProjectModalOpen(true)}>
                    新規作成
                  </Button>
                  <Popover
                    content={
                      <PopoverContent>
                        <PopoverItem
                          onClick={() => {
                            createProject();
                            setIsPopoverVisible(false);
                          }}>
                          <div>企業データベースを検索して作成</div>
                        </PopoverItem>
                        <PopoverItem
                          onClick={() => {
                            createProjectBySpecificCompany();
                            setIsPopoverVisible(false);
                          }}>
                          <div>類似企業を検索して作成</div>
                        </PopoverItem>
                      </PopoverContent>
                    }
                    trigger="click"
                    visible={isPopoverVisible}
                    onVisibleChange={(visible) => setIsPopoverVisible(visible)}
                    placement="bottomRight">
                    <Button
                      className="ant-btn-right"
                      type="primary"
                      onClick={() => setIsPopoverVisible(true)}>
                      <ArrowDown />
                    </Button>
                  </Popover>
                </div>
              )}
            </div>
          )}
        </div>
        <Tab />
        <ProjectSearch />
        <ProjectList />
      </Body>
      <NewProjectModal
        isNewProjectModalOpen={isNewProjectModalOpen}
        setIsNewProjectModalOpen={setIsNewProjectModalOpen}
      />
      <Detail />
    </Container>
  );
};

export default () => {
  return (
    <Switch>
      <Route
        path={projectRoutePaths('projects/:projectId/prelead_reports')}
        component={PreleadReport}
      />
      <Route
        path={projectRoutePaths('projects/:projectId/tel_reports')}
        component={TelReport}
      />
      <Route
        path={projectRoutePaths('projects/:projectId/form_mail_reports')}
        component={FormMailReport}
      />

      <Route
        path={[
          ...projectRoutePaths(
            'projects/:projectId/preleads/:preleadProjectId',
          ),
          ...projectRoutePaths('projects/:projectId/preleads'),
        ]}>
        <RecoilRoot>
          <Preleads />
        </RecoilRoot>
      </Route>

      <Route
        path={projectRoutePaths('projects/:projectId/search')}
        component={Search}
      />

      <Route
        path={projectRoutePaths(
          'projects/:projectId/specific_companies/:companyId',
        )}
        component={SpecificCompany}
      />
      <Route
        path={projectRoutePaths('projects/:projectId/specific_companies')}
        component={SpecificCompanies}
      />

      <Route
        path={projectRoutePaths('projects/:projectId')}
        component={Projects}
        key={'detail'}
      />

      <Route component={Projects} />
    </Switch>
  );
};

const Body = styled.div`
  grid-area: body;
  display: flex;
  flex-direction: column;
  background: #f3f5f7;
  padding: 30px 32px 12px 0px;
  overflow: scroll;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .ant-btn-left {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-left: 10px;
  }

  .ant-btn-right {
    width: 35px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid var(--leadpad-blue-200);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      width: 14px;
      height: 14px;
      path {
        stroke: #ffffff;
      }
    }
  }
`;

const PopoverContent = styled.div`
  padding: 12px 0;
`;

const PopoverItem = styled.div`
  width: 275px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f9ff;
    color: var(--primary);
  }
`;
