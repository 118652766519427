import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  ConvertPreleadContactToProspectProspectActivity,
} from 'api';
import {Link} from 'react-router-dom';

interface ConvertPreleadContactToProspectActivity extends ProspectActivity {
  resource: ConvertPreleadContactToProspectProspectActivity;
}

interface Props {
  prospectActivity: ConvertPreleadContactToProspectActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      preleadProject = {},
      preleadProject: {project = {}} = {},
      preleadProject: {project: {projectGroup = {}} = {}},
    },
  } = prospectActivity;

  return (
    <>
      <Icon />
      <Content>
        <Link
          to={`/list/groups/${projectGroup.uuid}/projects/${project.uuid}/preleads/${preleadProject.uuid}`}>
          {project.name}
        </Link>
        <time>
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 455px;
  margin-top: -4px;
  margin-left: 3px;

  a {
    flex: 1;
    margin: 0;
    font-weight: 500;
    width: 375px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;
