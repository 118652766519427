import React from 'react';

export default () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.44424 12.0006L7.55542 8.88939M4.44424 4.0004L4.44424 12.0006L4.44424 4.0004ZM4.44424 12.0006L1.33307 8.88939L4.44424 12.0006Z"
        stroke="var(--primary)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 4.00027L15.1111 7.11145M11.9999 12.0004L11.9999 4.00027L11.9999 12.0004ZM11.9999 4.00027L8.88873 7.11145L11.9999 4.00027Z"
        stroke="var(--primary)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
