import React from 'react';
import {useTable} from 'react-table';
import Cell from './Cell';
import {CheckCircleIcon} from '@heroicons/react/20/solid';
import {useParams} from 'react-router-dom';
import {
  useKeyPersonsByAccountPoolQuery,
  KeyPerson,
  useCurrentUserQuery,
} from 'api';
import moment from 'moment';

export default () => {
  const {accountId} = useParams<{
    accountId: string;
  }>();
  const {data: {keyPersonsByAccountPool = []} = {}} =
    useKeyPersonsByAccountPoolQuery({
      variables: {
        uuid: accountId,
      },
    });
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const isUserRoleAdmin = currentUser?.role === 'admin';

  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: '名前/部署・役職',
        style: {
          minWidth: '360px',
        },
        Cell: (row: any) => {
          const keyPerson: KeyPerson = row.row.original;
          return (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                {isUserRoleAdmin && (
                  <CheckCircleIcon className="w-4 h-4 text-[#4fce82]" />
                )}
                {isUserRoleAdmin ? (
                  <a
                    href="#"
                    className="m-0 text-base text-c-base leading-none truncate font-bold">
                    {keyPerson.lastName} {keyPerson.firstName}
                  </a>
                ) : (
                  <span className="m-0 text-base text-c-base leading-none truncate font-bold">
                    {keyPerson.lastName} {keyPerson.firstName}
                  </span>
                )}
              </div>
              <span className="text-xs text-c-light truncate leading-none">
                {keyPerson.department}
              </span>
              <span className="text-xs text-c-light truncate leading-none">
                {keyPerson.position}
              </span>
            </div>
          );
        },
      },
      {
        id: 'social',
        Header: 'SNSリンク',
        Cell: (row: any) => Cell.Sns(row),
        style: {
          width: '200px',
        },
      },
      {
        id: 'media',
        Header: '掲載メディア',
        Cell: (row: any) => {
          const keyPerson: KeyPerson = row.row.original;
          return (
            <>
              {keyPerson.articles?.map((article) => (
                <div>
                  <a
                    href={`${article.url}`}
                    className="truncate"
                    target="_blank">
                    {article.caseStudy.siteName}
                  </a>
                </div>
              ))}
            </>
          );
        },
        style: {
          width: '100px',
        },
      },
      {
        id: 'mediaDate',
        Header: '掲載日',
        Cell: (row: any) => {
          const keyPerson: KeyPerson = row.row.original;
          return <div>{keyPerson?.article?.publishedDate}</div>;
        },
        style: {
          width: '100px',
        },
      },
      ...(isUserRoleAdmin
        ? [
            {
              id: 'note',
              Header: '備考',
              Cell: (row: any) => {
                return (
                  <div className="whitespace-pre-wrap">
                    電話よりもメールでの連絡を好む。レスポンスは迅速。
                  </div>
                );
              },
              style: {
                width: '280px',
              },
            },
            {
              id: 'tel',
              Header: '部署電話番号',
              Cell: (row: any) => {
                return <div>03-0000-0000</div>;
              },
              style: {
                width: '100px',
              },
            },
          ]
        : []),

      {
        id: 'updateDate',
        Header: '更新日',
        Cell: (row: any) => {
          const keyPerson: KeyPerson = row.row.original;
          return <div>{moment(keyPerson?.updatedAt).format('YYYY/MM/DD')}</div>;
        },
        style: {
          width: '100px',
        },
      },
      ...(isUserRoleAdmin
        ? [
            {
              id: 'menu',
              Header: '',
              Cell: Cell.Menu,
              style: {
                width: '80px',
              },
            },
          ]
        : []),
    ],
    [currentUser],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<any>({columns, data: keyPersonsByAccountPool || []});

  return (
    <div className="flex flex-col items-center">
      <table
        {...getTableProps()}
        cellSpacing="0"
        cellPadding="0"
        className="w-full table-fixed border-separate border-spacing-0 border-l border-r border-c-border">
        <thead className="text-c-light text-xs">
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    className="py-2 px-4 text-xs text-c-light border-y text-left border-c-border bg-white leading-tight h-9"
                    {...column.getHeaderProps([
                      {
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="text-sm">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      className="min-w-[50px] h-20 py-2 px-4 border-b border-c-border truncate bg-white"
                      {...cell.getCellProps()}
                      key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
