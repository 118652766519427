import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <path
        d="M20.5556 10.1389H9.44444C9.06091 10.1389 8.75 10.4498 8.75 10.8333V19.1667C8.75 19.5502 9.06091 19.8611 9.44444 19.8611H20.5556C20.9391 19.8611 21.25 19.5502 21.25 19.1667V10.8333C21.25 10.4498 20.9391 10.1389 20.5556 10.1389Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5554 10.4861L14.9999 15.6944L9.44434 10.4861"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
