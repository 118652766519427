import React, {useState} from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {Checkbox} from 'components/Ui';
import {Slack} from 'components/Ui/Icon';
import {Button, Form, Input} from 'components/antd';
import {useFormik} from 'formik';
import {
  useUpdateSlackSettingMutation,
  useTestSlackIntegrationMutation,
  useCurrentClientQuery,
} from 'api';
import * as Yup from 'yup';
import {message} from 'components/antd';
import CircleCloseIcon from './CircleCloseIcon';

const validateSchema = Yup.object().shape({
  apiToken: Yup.string().required('必須項目です'),
  channel: Yup.string().required('必須項目です'),
});

export default () => {
  const [testing, setTesting] = useState(false);
  const [testSuccess, setTestSuccess] = useState(false);
  const [alreadyTested, setAlreadyTested] = useState(false);

  const {data: {currentClient: {slackSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const [updateSlackSetting, {loading}] = useUpdateSlackSettingMutation({
    onCompleted: ({updateSlackSetting: {slackSetting}}) => {
      if (testing) {
        testIntegration({});
      } else if (slackSetting) {
        message.success('更新しました');
      }
    },
  });

  const [testIntegration, {loading: testLoading}] =
    useTestSlackIntegrationMutation({
      onCompleted: ({testSlackIntegration: {success}}) => {
        setTesting(false);
        setAlreadyTested(true);
        setTestSuccess(success);
      },
    });

  const clickTestButton = () => {
    setTesting(true);
    handleSubmit();
  };

  const changeSlackActiveButton = () => {
    values.isActive = !values.isActive;
    updateSlackSetting({
      variables: {
        attributes: {
          ...values,
        },
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      apiToken: slackSetting?.apiToken || '',
      channel: slackSetting?.channel || '',
      isActive: slackSetting?.isActive || false,
      notifyPreleadProjectsCreated:
        slackSetting?.notifyPreleadProjectsCreated || false,
      notifyBlockListImported: slackSetting?.notifyBlockListImported || false,
      notifyMailStatusChanged: slackSetting?.notifyMailStatusChanged || false,
      notifyTelStatusChanged: slackSetting?.notifyTelStatusChanged || false,
      notifyPreleadProjectStatusChanged:
        slackSetting?.notifyPreleadProjectStatusChanged || false,
      notifyCommented: slackSetting?.notifyCommented || false,
      notifyPreleadContactCreated:
        slackSetting?.notifyPreleadContactCreated || false,
      notifyMailClicked: slackSetting?.notifyMailClicked || false,
      notifyMailStatusSummary: slackSetting?.notifyMailStatusSummary || false,
      notifyTelStatusSummary: slackSetting?.notifyTelStatusSummary || false,
      notifyPressReleaseSummary:
        slackSetting?.notifyPressReleaseSummary || false,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      updateSlackSetting({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Body>
      <Menu />
      <Content>
        <TitleSection>
          <Slack />
          <TitleWrapper>
            <Title>Slack</Title>
            <TitleNote>Slack連携の設定を行います。</TitleNote>
          </TitleWrapper>
          {values.isActive ? (
            <UnavailableButton onClick={changeSlackActiveButton}>
              <CircleCloseIcon />
              無効にする
            </UnavailableButton>
          ) : (
            <AvailableButton onClick={changeSlackActiveButton}>
              有効にする
            </AvailableButton>
          )}
        </TitleSection>

        <HeadSection>
          <span>連携設定</span>
        </HeadSection>

        <FormSection>
          <FormNote>Slack連携に必要な各種項目を設定してください。</FormNote>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 12}}
            onFinish={handleSubmit}>
            <Form.Item
              label="APIトークン"
              required
              validateStatus={
                errors.apiToken && touched.apiToken ? 'error' : ''
              }
              help={errors.apiToken && touched.apiToken ? errors.apiToken : ''}>
              <Input
                name="apiToken"
                value={values.apiToken}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="チャンネル"
              required
              validateStatus={errors.channel && touched.channel ? 'error' : ''}
              help={errors.channel && touched.channel ? errors.channel : ''}>
              <Input
                name="channel"
                value={values.channel}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Space />
            <CheckBoxSection>
              <span>リアルタイム通知</span>

              <div>
                <div>
                  <span>営業タイミングの通知</span>
                  <Checkbox
                    name="notifyMailClicked"
                    checked={values.notifyMailClicked}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyMailClicked',
                        e.target.checked,
                      );
                    }}>
                    フォーム送信クリック
                  </Checkbox>
                </div>
                <div>
                  <span>プレリード情報変更の通知</span>
                  <Checkbox
                    name="notifyPreleadProjectStatusChanged"
                    checked={values.notifyPreleadProjectStatusChanged}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyPreleadProjectStatusChanged',
                        e.target.checked,
                      );
                    }}>
                    プレリードステータス変更
                  </Checkbox>

                  <Checkbox
                    name="notifyTelStatusChanged"
                    checked={values.notifyTelStatusChanged}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyTelStatusChanged',
                        e.target.checked,
                      );
                    }}>
                    TELステータス変更
                  </Checkbox>
                  <Checkbox
                    name="notifyMailStatusChanged"
                    checked={values.notifyMailStatusChanged}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyMailStatusChanged',
                        e.target.checked,
                      );
                    }}>
                    フォームメールステータス変更
                  </Checkbox>
                  <Checkbox
                    name="notifyCommented"
                    checked={values.notifyCommented}
                    onChange={(e) => {
                      formik.setFieldValue('notifyCommented', e.target.checked);
                    }}>
                    タイムラインコメント投稿
                  </Checkbox>

                  <SpaceBlock />

                  <Checkbox
                    name="notifyPreleadContactCreated"
                    checked={values.notifyPreleadContactCreated}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyPreleadContactCreated',
                        e.target.checked,
                      );
                    }}>
                    連絡先登録
                  </Checkbox>
                </div>
                <div>
                  <span>営業リスト作業完了の通知</span>
                  <Checkbox
                    name="notifyPreleadProjectsCreated"
                    checked={values.notifyPreleadProjectsCreated}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyPreleadProjectsCreated',
                        e.target.checked,
                      );
                    }}>
                    プレリード登録
                  </Checkbox>

                  <Checkbox
                    name="notifyBlockListImported"
                    checked={values.notifyBlockListImported}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'notifyBlockListImported',
                        e.target.checked,
                      );
                    }}>
                    ブロックリストインポート完了
                  </Checkbox>
                </div>
              </div>
            </CheckBoxSection>

            <CheckBoxSection>
              <span>定期通知</span>

              <div>
                <div>
                  <AttentionContainer>
                    <span>※翌朝9:00に投稿されます</span>
                    <Checkbox
                      name="notifyMailStatusSummary"
                      checked={values.notifyMailStatusSummary}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'notifyMailStatusSummary',
                          e.target.checked,
                        );
                      }}>
                      フォームメールステータスサマリ
                    </Checkbox>
                  </AttentionContainer>
                  <AttentionContainer>
                    <span>※当日18:00に投稿されます</span>
                    <Checkbox
                      name="notifyTelStatusSummary"
                      checked={values.notifyTelStatusSummary}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'notifyTelStatusSummary',
                          e.target.checked,
                        );
                      }}>
                      TELステータスサマリ
                    </Checkbox>
                  </AttentionContainer>
                  <AttentionContainer>
                    <span>※毎朝9:00に投稿されます</span>
                    <Checkbox
                      name="notifyPressReleaseSummary"
                      checked={values.notifyPressReleaseSummary}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'notifyPressReleaseSummary',
                          e.target.checked,
                        );
                      }}>
                      プレスリリースサマリ
                    </Checkbox>
                  </AttentionContainer>
                </div>
              </div>
            </CheckBoxSection>

            <ButtonSection>
              <SaveButton
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={!formik.dirty || !isValid || loading}>
                保存
              </SaveButton>
              <TestButton
                loading={loading || testLoading}
                disabled={!isValid || loading || testLoading}
                onClick={clickTestButton}>
                テスト送信
              </TestButton>
              {alreadyTested &&
                (testSuccess ? (
                  <TestSuccess>success!</TestSuccess>
                ) : (
                  <TestError>error</TestError>
                ))}
            </ButtonSection>
          </Form>
        </FormSection>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 45px 45px;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

const TitleNote = styled.div`
  margin-top: 5px;
  font-size: 14px;
`;

const AvailableButton = styled.button`
  background-color: #169e34;
  color: #fff;
  height: 39px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin: 0 0 0 auto;
  cursor: pointer;
`;

const UnavailableButton = styled(Button)`
  display: flex;
  align-items: center;
  border: none;
  margin: 0 0 0 auto;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #69717a;
  }
`;

const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 13px 20px;
  margin-top: 14px;

  > span {
    font-weight: bold;
  }

  > button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #69717a;
    font-size: 14px;
    font-weight: bold;

    svg {
      margin-right: 6px;
    }
  }
`;

const FormSection = styled.div`
  margin-top: 25px;
  padding-bottom: 30px;

  form {
    margin-top: 30px;
    .ant-form-item {
      margin: 0;
      display: block;
      // height: 95px;

      .ant-form-item-label {
        display: inline;
        label {
          height: 26px;
          font-weight: bold;
          font-size: 14px;
          color: var(--text-color-3);
        }
      }
      .ant-form-item-control {
        max-width: 100% !important;
      }
      input {
        width: 600px;
        height: 48px;
        background: #f3f5f7;
        border: none;
      }
    }
  }
`;

const FormNote = styled.div`
  font-size: 14px;
`;

const SpaceBlock = styled.div`
  width: 100%;
`;

const Space = styled.div`
  height: 20px;
`;

const CheckBoxSection = styled.div`
  > span {
    display: block;
    margin-bottom: 11px;
    height: 26px;
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color-3);
  }

  > div {
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;

      > span {
        margin-bottom: 4px;
        font-size: 12px;
        color: var(--text-color-2);
        width: 100%;
      }

      .ant-checkbox-wrapper {
        margin-left: 0px;
        width: 200px;
        line-height: 20px;
      }
    }
  }
`;

const AttentionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled.div`
  margin-top: 40px;
  display: flex;
`;

const DefaultButton = styled(Button)`
  margin-right: 20px;
  width: 187px;
  height: 48px;
  border: none;
  border-radius: 4px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

const SaveButton = styled(DefaultButton)`
  background: var(--primary);
  &:hover {
    background: #65aae8;
  }
`;

const TestButton = styled(DefaultButton)`
  border: 2px solid var(--primary);
  span {
    color: var(--primary);
  }
  &:hover {
    border-color: #65aae8;
  }
`;

const TestSuccess = styled.div`
  margin: auto 0;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #108429;
`;

const TestError = styled(TestSuccess)`
  color: #c90e19;
`;
