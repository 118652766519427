import React from 'react';
const MailIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.42164 3.00586H2.57719C2.34094 3.00586 2.14941 3.19738 2.14941 3.43364V8.56697C2.14941 8.80323 2.34094 8.99475 2.57719 8.99475H9.42164C9.65789 8.99475 9.84941 8.80323 9.84941 8.56697V3.43364C9.84941 3.19738 9.65789 3.00586 9.42164 3.00586Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.42159 3.2207L5.99937 6.42904L2.57715 3.2207"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailIcon;
