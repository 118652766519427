import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.9167 4.25H7.08333C5.51853 4.25 4.25 5.51853 4.25 7.08333V11.1667C4.25 12.7315 5.51853 14 7.08333 14H26.9167C28.4815 14 29.75 12.7315 29.75 11.1667V7.08333C29.75 5.51853 28.4815 4.25 26.9167 4.25Z"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="5.25"
      y1="18.75"
      x2="28.75"
      y2="18.75"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <line
      x1="5.25"
      y1="23.75"
      x2="28.75"
      y2="23.75"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <line
      x1="5.25"
      y1="28.75"
      x2="28.75"
      y2="28.75"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
