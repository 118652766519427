import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import linkifyHtml from 'linkify-html';
import {
  Form,
  Button,
  Input,
  AutoComplete,
  message,
  Popover,
  Select,
  Upload,
  Tooltip,
} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  Step,
  AutomaticMailStep,
  useUsersByClientAllQuery,
  useUpdateAutomaticMailMutation,
  useAddAutomaticMailAttachmentMutation,
  useRemoveAutomaticMailAttachmentMutation,
  useSendAutomaticTestMailMutation,
  useUploadMailImageMutation,
} from 'api';
import {Quill as ReactQuill} from 'components/Ui';
import {Quill} from 'react-quill';
import {Cross, AddPaperFile} from 'components/Ui/Icon';
import mailVariableOptions from 'helpers/mailVariableOptions';
import useClientUser from 'hooks/useClientUser';
import useMailTemplate from 'hooks/useMailTempate';

interface AutomaticMailStepType extends Step {
  steppable: AutomaticMailStep;
}

interface Props {
  step: AutomaticMailStepType;
  onFinish: () => void;
}

export default ({step, onFinish}: Props) => {
  const {templates, templateTitle} = useMailTemplate();
  const {isMember} = useClientUser();
  const mail = step.steppable?.automaticMail;
  const [update, {loading}] = useUpdateAutomaticMailMutation({
    onCompleted: () => onFinish(),
  });
  const [addAttachment] = useAddAutomaticMailAttachmentMutation();
  const [removeAttachment] = useRemoveAutomaticMailAttachmentMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fromEmail: mail?.fromEmail || '',
      fromName: mail?.fromName || '',
      subject: mail?.subject || '',
      bodyText: mail?.bodyText || '',
      bodyHtml: mail?.bodyHtml || '',
      cc: mail?.cc || [],
      bcc: mail?.bcc || [],
    },
    validationSchema: Yup.object().shape({
      fromEmail: Yup.string().email().required('必須項目です'),
      subject: Yup.string().required('必須項目です'),
      bodyText: Yup.string(),
      bodyHtml: Yup.string(),
    }),
    onSubmit: (values) => {
      values.bodyHtml = convertBodyHtml(values.bodyHtml);
      update({
        variables: {id: mail.id, attributes: values},
      });
    },
  });

  const convertBodyHtml = (text: string) =>
    convertUrlToLink(convertClassToInlineStyle(text));

  const convertClassToInlineStyle = (text: string) =>
    text.replace(/class="ql-align-center"/g, 'style="text-align: center"');

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const [sendTestMail, {loading: sendTestMailLoading}] =
    useSendAutomaticTestMailMutation({
      onCompleted: () => message.info('確認メールを送信しました'),
    });

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });

  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };

  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  Quill.register(fontSizeStyle, true);

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            {size: fontSizeStyle.whitelist},
            'bold',
            'italic',
            'underline',
            {align: ['', 'center']},
            {color: []},
            'link',
            'image',
          ],
        ],
      },
    };
  }, []);

  const onClickSendMail = React.useCallback(() => {
    formik.values.bodyHtml = convertClassToInlineStyle(formik.values.bodyHtml);
    confirm(
      `${formik.values.fromEmail}にテストメールを送信します。\nよろしいですか？`,
    ) && sendTestMail({variables: {attributes: formik.values}});
  }, [formik.values]);

  const [contentModalVisible, setContentModalVisible] = React.useState(false);
  const [isCcFormVisible, setIsCcFormVisible] = React.useState(false);
  const [isBccFormVisible, setIsBccFormVisible] = React.useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (mail?.cc?.length > 0) {
      setIsCcFormVisible(true);
    }
    if (mail?.bcc?.length > 0) {
      setIsBccFormVisible(true);
    }
  }, [mail]);

  useEffect(() => {
    // 差出人の変更が検知されるようにしています。
    formik.validateForm();
  }, [formik.values]);

  const [isPopoverVisible, setPopoverVisible] = React.useState(false);

  const setFrom = (value: string) => {
    formik.setFieldValue('fromEmail', value);

    const user = users.find((user) => user.email == value);
    if (!user) return;

    const fromName = !user.firstName
      ? user.lastName
      : !user.lastName
        ? user.firstName
        : user.lastName + ' ' + user.firstName;
    formik.setFieldValue('fromName', fromName);
  };

  const FromName = () => (
    <FromNameContainer>
      <h3>差出人</h3>
      <div style={{marginBottom: 18}}>
        <label style={{color: 'var(--text-color-2)'}}>差出人名</label>
        <Input
          id="fromName"
          name="fromName"
          defaultValue={formik.values.fromName}
        />
      </div>
      <div>
        <Button
          style={{marginRight: '10px'}}
          loading={loading}
          onClick={() => setPopoverVisible(false)}>
          キャンセル
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            const fromNameInput: any = document.getElementById('fromName');
            formik.setFieldValue('fromName', fromNameInput.value);
            setPopoverVisible(false);
          }}>
          更新
        </Button>
      </div>
    </FromNameContainer>
  );

  return (
    <Container>
      <Form onFinish={formik.handleSubmit}>
        <Field>
          <label>差出人</label>
          <AutoComplete
            bordered={false}
            options={users.map((user) => ({
              label: `${user.lastName} ${user.firstName} <${user.email}>`,
              value: user.email,
            }))}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            value={formik.values.fromEmail}
            onChange={(value) => setFrom(value)}
            disabled={!isMember}
          />
          {!isCcFormVisible && isMember && (
            <div
              style={{marginRight: 5}}
              onClick={() => setIsCcFormVisible(true)}>
              Cc
            </div>
          )}
          {!isBccFormVisible && isMember && (
            <div onClick={() => setIsBccFormVisible(true)}>Bcc</div>
          )}
          {isMember && (
            <Popover
              trigger="click"
              visible={isPopoverVisible}
              onVisibleChange={(visible) => setPopoverVisible(visible)}
              placement="bottomRight"
              content={() => <FromName />}>
              <Button type="text" style={{color: 'var(--primary)'}}>
                差出人名編集
              </Button>
            </Popover>
          )}
        </Field>

        {isCcFormVisible && (
          <Field>
            <label>Cc</label>
            <Select
              mode="tags"
              bordered={false}
              value={formik.values.cc}
              onChange={(values: [string]) =>
                formik.setFieldValue(
                  'cc',
                  values.filter((value) =>
                    Yup.string().trim().email().isValidSync(value),
                  ),
                )
              }>
              {users.map((user) => (
                <Select.Option key={user.uuid} value={user.email}>
                  {user.lastName} {user.firstName}
                  {`<${user.email}>`}
                </Select.Option>
              ))}
            </Select>
          </Field>
        )}

        {isBccFormVisible && (
          <Field>
            <label>Bcc</label>
            <Select
              mode="tags"
              bordered={false}
              value={formik.values.bcc}
              onChange={(values: [string]) =>
                formik.setFieldValue(
                  'bcc',
                  values.filter((value) =>
                    Yup.string().trim().email().isValidSync(value),
                  ),
                )
              }>
              {users.map((user) => (
                <Select.Option key={user.uuid} value={user.email}>
                  {user.lastName} {user.firstName}
                  {`<${user.email}>`}
                </Select.Option>
              ))}
            </Select>
          </Field>
        )}

        <Field>
          <Select
            style={{width: '100%'}}
            placeholder="メールテンプレート"
            disabled={!isMember}
            optionFilterProp="children"
            showSearch
            onSelect={(value: any) => {
              const template = templates.find(
                (template) => template.uuid === value,
              );
              formik.setValues({
                ...formik.values,
                subject: template.mailTemplate.title,
              });
              template.mailTemplate.bodyHtml
                ? (document.getElementsByClassName('ql-editor')[0].innerHTML =
                    template.mailTemplate.bodyHtml)
                : ref.current?.editor.setText(template.mailTemplate.body);
            }}>
            {templates.map((template) => (
              <Select.Option key={template.uuid} value={template.uuid}>
                {templateTitle(template)}
              </Select.Option>
            ))}
          </Select>
        </Field>
        <Field>
          <label>件名</label>
          <Input
            id="subject"
            name="subject"
            bordered={false}
            {...formik.getFieldProps('subject')}
            disabled={!isMember}
          />
        </Field>
        <Body>
          <BodyHeader>
            <label>本文</label>
          </BodyHeader>
          <ReactQuill
            ref={ref}
            theme="snow"
            value={formik.values.bodyHtml}
            placeholder="本文を記入してください"
            onChange={(content, delta, source, editor) => {
              formik.setFieldValue('bodyHtml', content);
              formik.setFieldValue('bodyText', editor.getText());
            }}
            modules={modules}
            bounds={'#quill-container'}
            readOnly={!isMember}
          />
          {mail?.mailAttachments.map((attachment) => (
            <File key={attachment.uuid}>
              <FileName
                onClick={() =>
                  (window.location.href = `/automatic_mail_attachments/${attachment.uuid}/export`)
                }>
                {attachment.filename}
              </FileName>
              <div
                onClick={() => {
                  removeAttachment({
                    variables: {
                      attachmentUuid: attachment.uuid,
                    },
                    refetchQueries: ['workflow'],
                  });
                }}>
                <Cross />
              </div>
            </File>
          ))}
        </Body>
        <PopoverWrapper>
          <Popover
            content={
              <Content>
                {mailVariableOptions.map((option) => (
                  <ButtonWrapper key={option.title}>
                    <CustomButton
                      onClick={() => {
                        if (ref.current?.editor.getSelection()) {
                          ref.current?.editor.insertText(
                            ref.current?.editor.getSelection().index,
                            option.code,
                          );
                        } else {
                          const subject: any =
                            document.getElementById('subject');
                          const value =
                            subject.value.substr(0, subject.selectionStart) +
                            option.code +
                            subject.value.substr(subject.selectionStart);
                          formik.setFieldValue('subject', value);
                        }
                      }}>
                      <span>{option.code}</span>
                      <span>{option.title}</span>
                    </CustomButton>
                  </ButtonWrapper>
                ))}
              </Content>
            }
            trigger="click"
            visible={contentModalVisible}
            placement="topRight"
            onVisibleChange={setContentModalVisible}>
            <Button
              style={{
                width: 32,
                justifyContent: 'center',
                display: 'flex',
                color: 'var(--text-color-3)',
              }}>{`{ }`}</Button>
          </Popover>
          <Upload
            customRequest={() => false}
            showUploadList={false}
            onChange={(info) => {
              const addedAttachment = info.file.originFileObj;
              const upload_size_limit = 25000000; // 25MB

              let attachmentSize = addedAttachment.size;
              mail?.mailAttachments.forEach(
                (attachment) => (attachmentSize += attachment.size),
              );
              if (attachmentSize > upload_size_limit) {
                alert(
                  'ファイルの容量が25MBを超えています。\n合計25MBまでの添付が可能です。',
                );
                return null;
              }

              addAttachment({
                variables: {id: mail.id, attachment: addedAttachment},
                refetchQueries: ['workflow'],
              });
            }}>
            <Tooltip
              title="ファイルを添付(25MBまで)"
              color={'var(--text-color-0)'}
              placement="top">
              <Button icon={<AddPaperFile />} style={{marginLeft: 10}} />
            </Tooltip>
          </Upload>
        </PopoverWrapper>
        {isMember && (
          <Actions className="sticky bottom-0 pt-2 pb-4 bg-white right-0">
            <Button
              ghost
              type="primary"
              htmlType="button"
              loading={sendTestMailLoading}
              disabled={!formik.isValid || sendTestMailLoading}
              onClick={onClickSendMail}>
              テストメールを送信
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid || !formik.dirty}
              loading={loading}>
              保存
            </Button>
          </Actions>
        )}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }

  .ql-snow .ql-picker.ql-size {
    width: 50px;

    .ql-picker-label {
      padding-left: 0;
    }

    .ql-picker-options {
      width: 50px;
    }
  }
`;

const Field = styled.div`
  min-height: 45px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e1e6eb;

  label {
    width: 50px;
  }

  > *:nth-child(2) {
    flex: 1;
  }

  button {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &:first-child {
    > div {
      cursor: pointer;
      font-weight: 700;
    }
    > div:last-child {
      margin-left: 10px;
    }
  }

  .ant-select-selection-item {
    background-color: #fff;
    border: 1px solid #e1e6eb;
    border-radius: 100px;
  }
`;

const Body = styled.div`
  padding-bottom: 5px;
  position: relative;
  margin-bottom: 41px;

  textarea {
    padding: 0;
    border: none;
  }

  .ql-container.ql-snow {
    min-height: 200px;
    border: none;

    .ql-editor {
      min-height: 200px;
      overflow-y: scroll;
      resize: vertical;
      padding: 0;

      &::before {
        left: 0;
      }
    }
  }

  .ql-toolbar {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    border: none;
    z-index: 50;
    width: fit-content;
  }
`;

const BodyHeader = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
`;

const PopoverWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span: first-child {
    color: var(--text-color-2);
    margin-right: 10px;
  }
  span: last-child {
    color: var(--text-color-1);
  }
`;

const CustomButton = styled(Button)`
  padding: 0;
  padding-left: 20px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;
    path {
      stroke: var(--text-color-2);
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  max-width: 580px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: var(--primary);
  cursor: pointer;
`;

const FromNameContainer = styled.div`
  width: 315px;
  padding: 12px;

  h3 {
    font-weight: bold;
    font-size: 14px;
  }
  div:last-child {
    display: flex;
    justify-content: end;
  }
`;
