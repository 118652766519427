import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Activity, PreleadCompanyUpdateActivity} from 'api';

interface PreleadCompanyUpdate extends Activity {
  resource: PreleadCompanyUpdateActivity;
}

interface Props {
  activity: PreleadCompanyUpdate;
}

const PreleadCompanyUpdateActivityComponent = ({activity}: Props) => {
  return (
    <>
      <Summary>
        <BadgeWrapper>
          <Circle />
        </BadgeWrapper>
        <Message>会社情報が更新されました</Message>
        <span>{moment(activity.createdAt).format('MM/DD HH:mm')}</span>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  align-items: center;
  width: 480px;
  height: 30px;
`;

const Message = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  margin-right: 16px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: var(--text-color-3);
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  -o-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px;
`;

export default PreleadCompanyUpdateActivityComponent;
