import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.08301 29.7503L22.6663 14.167L29.7497 21.2503"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9167 4.25H7.08333C5.51853 4.25 4.25 5.51853 4.25 7.08333V26.9167C4.25 28.4815 5.51853 29.75 7.08333 29.75H26.9167C28.4815 29.75 29.75 28.4815 29.75 26.9167V7.08333C29.75 5.51853 28.4815 4.25 26.9167 4.25Z"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.042 14.167C13.2156 14.167 14.167 13.2156 14.167 12.042C14.167 10.8684 13.2156 9.91699 12.042 9.91699C10.8684 9.91699 9.91699 10.8684 9.91699 12.042C9.91699 13.2156 10.8684 14.167 12.042 14.167Z"
      stroke="var(--text-color-2)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
