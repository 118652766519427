import React from 'react';
import {
  SalesforceLeadRecordType,
  useSalesforceLeadRecordTypeByRecordTypeIdQuery,
} from 'api';
import LeadField from './LeadField';
import CompanyUpdateSetting from './CompanyUpdateSetting';

interface Props {
  salesforceLeadRecordType: SalesforceLeadRecordType;
}

export default ({salesforceLeadRecordType}: Props) => {
  const {
    data: {
      salesforceLeadRecordTypeByRecordTypeId: {salesforceLeadLeadpadFields = []} = {},
    } = {},
  } = useSalesforceLeadRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceLeadRecordTypeId: salesforceLeadRecordType?.id,
    },
    skip: !salesforceLeadRecordType,
  });

  return (
    <div>
      {salesforceLeadLeadpadFields.map(
        (salesforceLeadLeadpadField, i) => {
          return (
            <div
              className="flex items-center border-b border-b-[#F3F5F7] py-4"
              key={i}>
              <div className="text-foreground-dark w-96 px-6 text-base">
                {salesforceLeadLeadpadField.leadpadFieldText}
              </div>
              <LeadField
                salesforceLeadLeadpadField={salesforceLeadLeadpadField}
                salesforceLeadRecordType={salesforceLeadRecordType}
              />
              <CompanyUpdateSetting />
            </div>
          );
        },
      )}
    </div>
  );
};
