import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectQuery} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {project: {count = 0} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  return (
    <Container>
      <div>
        <span>
          <em>{count.toLocaleString()}</em>件
        </span>
        <span>リスト総数</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 47px;
  background: #ffffff;
  border-right: 1px solid #e1e6eb;
  padding: 32px 28px;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color-1);

      em {
        margin-right: 6px;
        font-style: normal;
        font-size: 20px;
        line-height: 43px;
      }
    }

    > span:nth-child(2) {
      font-weight: bold;
      font-size: 12px;
      color: var(--text-color-2);
    }
  }
`;
