import React from 'react';
import {Action, AutomaticMailSend} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';

interface Props {
  row: Row<AutomaticMailSend>;
}
export default ({row}: Props) => {
  const step = row.original.actionAutomaticMail.action.step;

  return (
    <Container>
      <span>{step.position + 1}</span>
      <span>{step.name}</span>
    </Container>
  );
};

const Container = styled.span`
  span {
    &:first-child {
      font-weight: bold;
      color: var(--text-color-3);
      border-right: 1px solid var(--text-color-3);
      padding-right: 8px;
      margin-right: 8px;
    }
  }
`;
