import React from 'react';
import styled from 'styled-components';
import Table from './Table';
import Summary from './Summary';
import Filter from './Filter';
import {Button} from 'components/antd';
import useSearchParams from './useSearchParams';
import Assignee from './Assignee';
import {useHistory, Route, useParams, useLocation} from 'react-router-dom';
import Prospect from 'components/Prospect';
import {useUpdateStageProspectPoolMutation, useActionQuery} from 'api';
import Tab from 'components/Action/Tab';

interface Props {
  toggleFilter: () => void;
}

export default ({toggleFilter}: Props) => {
  const {numberOfFiltersInUse} = useSearchParams();
  const history = useHistory();
  const location = useLocation();
  const [updateStage] = useUpdateStageProspectPoolMutation();

  const {poolId, actionId} = useParams<{
    poolId: string;
    actionId: string;
  }>();

  const {data: {action: {prospectPool = null} = {}} = {}} = useActionQuery({
    variables: {uuid: actionId},
    skip: !actionId,
  });

  return (
    <Container>
      <Tab />
      <Header isFiltered={numberOfFiltersInUse > 0}>
        <Filter />
        <Assignee />
      </Header>
      <Summary />
      <Table />
      {prospectPool && (
        <Route path={`/pools/:poolId/action/actions/${actionId}/prospect`}>
          <Prospect
            prospectId={prospectPool?.uuid}
            poolId={poolId}
            updateStage={updateStage}
            onClose={() =>
              history.push(`/pools/${poolId}/action/actions${location.search}`)
            }
          />
        </Route>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
`;

const Header = styled.div<{isFiltered: boolean}>`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 4px;

  button {
    margin: 0;
    padding: 0;

    svg {
      position: relative;
      top: 2px;
    }

    svg {
      path {
        stroke: ${({isFiltered}) => isFiltered && 'var(--primary)'};
      }
    }
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NavItem = styled.div<{active?: boolean}>`
  display: inline-block;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
  border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
  height: 40px;
`;

const Count = styled.span`
  font-weight: 500;
  font-size: 10px;
  padding: 3px 11px;
  border-radius: 10px;
  color: var(--primary);
  background: #e6f3ff;
`;
