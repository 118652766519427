import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectGroupQuery, useRemoveUserFromProjectGroupMutation} from 'api';
import {Trash, UserMaru} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import Modal from './AddMemberModal';
import AddUserIcon from './AddUserIcon';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {projectGroupId} = useParams<{projectGroupId: string}>();
  const {isAdmin} = useClientUser();
  const {data: {projectGroup: {users = []} = {}, projectGroup} = {}} = useProjectGroupQuery({
    variables: {uuid: projectGroupId},
    skip: !projectGroupId || projectGroupId === 'new',
  });

  const [removeUserFromProjectGroup, {loading}] = useRemoveUserFromProjectGroupMutation({
    refetchQueries: ['projectGroup'],
  });

  const [isModalVisible, setModalVisible] = React.useState(false);

  if (!projectGroup) return null;

  return (
    <Container>
      <AddButton
        type="text"
        disabled={!isAdmin}
        onClick={() => setModalVisible(true)}>
        <AddUserIcon />
      </AddButton>
      <List>
        {users?.map((user) => (
          <Item key={user.id}>
            <UserMaru />
            <span>
              {user.lastName} {user.firstName}
            </span>
            <Button
              type="text"
              loading={loading}
              disabled={!isAdmin}
              onClick={() =>
                removeUserFromProjectGroup({
                  variables: {
                    uuid: projectGroup.uuid,
                    userId: user.id,
                  },
                })
              }>
              <Trash />
            </Button>
          </Item>
        ))}
      </List>

      {isModalVisible && (
        <Modal projectGroup={projectGroup} isOpen={isModalVisible} setModal={setModalVisible} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const AddButton = styled(Button)`
  height: auto;
  padding: 5.6px 9px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    // margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Item = styled.div<{added?: boolean}>`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;

  &:hover {
    background: #f9f9f9;
  }

  > * {
    &:nth-child(1) {
      width: 30px;
      height: 30px;
      overflow: initial;
      circle {
        fill: none;
        stroke: var(--text-color-3);
      }
    }
    &:nth-child(2) {
      flex: 1;
      margin-left: 13px;
    }
    &:nth-child(3) {
      margin-left: auto;
    }
  }
`;
