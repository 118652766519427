import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Radio,
  DatePicker,
} from 'components/antd';
import {useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Step, AutomaticMailStep, useUpdateStepMutation} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import useClientUser from 'hooks/useClientUser';

interface AutomaticMailStepType extends Step {
  steppable: AutomaticMailStep;
}

interface Props {
  step: AutomaticMailStepType;
  onFinish: () => void;
}

export default ({step, onFinish}: Props) => {
  const {isMember} = useClientUser();
  const today = React.useMemo(() => moment(), []);
  const {stepId} = useParams<{stepId: string}>();
  const [update, {loading}] = useUpdateStepMutation({
    onCompleted: () => onFinish(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: step?.name || '',
      memo: step?.memo || '',
      scheduleType: step?.isImmediately
        ? 'isImmediately'
        : step?.startDate
          ? 'date'
          : 'period',
      startDay: step?.startDay || 1,
      startHour: step?.startHour || 9,
      startDate: step?.startDate ? moment(step?.startDate) : today,
      daysOfWeek: step?.daysOfWeek || [1, 2, 3, 4, 5],
      isImmediately: step?.isImmediately || false,
      isContinuedByEvent: step?.steppable.isContinuedByEvent || false,
      eventToContinue: step?.steppable.eventToContinue || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      daysOfWeek: Yup.array().when('scheduleType', {
        is: (scheduleType: string) => scheduleType === 'period',
        then: (schema) =>
          schema.ensure().of(Yup.number()).min(1, '必須項目です'),
      }),
      eventToContinue: Yup.string().when('isContinuedByEvent', {
        is: (isContinuedByEvent: boolean) => isContinuedByEvent,
        then: (schema) => schema.required('必須項目です'),
      }),
    }),
    onSubmit: (values) => {
      const copy = Object.assign({}, values);
      if (values.scheduleType === 'isImmediately') {
        copy.isImmediately = true;
        copy.startDate = null;
        copy.startDay = null;
        copy.startHour = null;
        copy.daysOfWeek = [];
      } else if (values.scheduleType === 'period') {
        copy.startDate = null;
        copy.isImmediately = false;
      } else {
        copy.startDay = null;
        copy.isImmediately = false;
      }

      const isContinuedByEvent = copy.isContinuedByEvent;
      const eventToContinue = copy.eventToContinue;

      delete copy.scheduleType;
      delete copy.eventToContinue;
      delete copy.isContinuedByEvent;

      update({
        variables: {
          uuid: stepId,
          attributes: copy,
          automaticMailStepAttributes: {
            isContinuedByEvent,
            eventToContinue,
          },
        },
      });
    },
  });

  React.useEffect(() => {
    // 「formik.values.isContinuedByEvent」の変更が検知されるようにしています。
    formik.validateForm();
  }, [formik.values]);

  return (
    <Container>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item
          label="ステップ名"
          validateStatus={formik.errors.name && 'error'}
          help={formik.errors.name}>
          <Input {...formik.getFieldProps('name')} disabled={!isMember} />
        </Form.Item>
        <Form.Item label="送信タイミング">
          <Radio.Group
            {...formik.getFieldProps('scheduleType')}
            disabled={!isMember}>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value="period" />
              <InputNumber
                min={1}
                style={{width: '6rem', marginRight: '0.5rem'}}
                value={formik.values.startDay}
                onChange={(value) => formik.setFieldValue('startDay', value)}
                disabled={
                  formik.values.scheduleType === 'isImmediately' || !isMember
                }
              />
              日後に実行
            </Form.Item>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value="date" />
              <DatePicker
                value={formik.values.startDate}
                locale={locale}
                allowClear={false}
                style={{width: '200px', marginRight: '0.5rem'}}
                onChange={(date) => formik.setFieldValue('startDate', date)}
                disabled={!isMember}
                disabledDate={(current) =>
                  current && current < moment().endOf('day')
                }
              />
              に送信
            </Form.Item>
            {step.position === 0 && (
              <Form.Item style={{marginBottom: '0'}}>
                <Radio value="isImmediately" />
                即時送信
              </Form.Item>
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="送信時間">
          <Form.Item noStyle>
            <Select
              style={{width: '6rem', marginRight: '0.5rem'}}
              value={formik.values.startHour}
              onChange={(value) => formik.setFieldValue('startHour', value)}
              disabled={
                formik.values.scheduleType === 'isImmediately' || !isMember
              }>
              {[...Array(24)].map((_, i) => {
                return (
                  <Select.Option key={i} value={i}>
                    {i}時
                  </Select.Option>
                );
              })}
            </Select>
            に送信
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="この曜日のみ送信します"
          validateStatus={formik.errors.daysOfWeek && 'error'}
          help={formik.errors.daysOfWeek}>
          <p>※送信タイミングがそのチェックがある曜日に実行されます</p>
          <Checkbox.Group
            options={[
              {label: '月', value: 1},
              {label: '火', value: 2},
              {label: '水', value: 3},
              {label: '木', value: 4},
              {label: '金', value: 5},
              {label: '土', value: 6},
              {label: '日', value: 0},
            ]}
            value={formik.values.daysOfWeek}
            onChange={(values) => formik.setFieldValue('daysOfWeek', values)}
            disabled={!(formik.values.scheduleType === 'period') || !isMember}
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.eventToContinue && 'error'}
          help={formik.errors.eventToContinue}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <div>
              <Checkbox
                checked={formik.values.isContinuedByEvent}
                onChange={(e) => {
                  formik.setFieldValue('isContinuedByEvent', e.target.checked);
                  if (!e.target.checked) {
                    formik.setFieldValue('eventToContinue', '');
                  }
                }}
                style={{fontWeight: 'bold'}}
                disabled={!isMember}>
                送信後ステータスによる分岐
              </Checkbox>
            </div>
            <div>
              <Select
                style={{
                  width: '185px',
                  marginRight: '0.5rem',
                  marginLeft: '24px',
                }}
                value={formik.values.eventToContinue}
                onChange={(value) =>
                  formik.setFieldValue('eventToContinue', value)
                }
                disabled={!formik.values.isContinuedByEvent}>
                {[
                  {label: '開封', value: 'open'},
                  {label: 'クリック', value: 'click'},
                ].map((event) => (
                  <Select.Option key={event.value} value={event.value}>
                    {event.label}
                  </Select.Option>
                ))}
              </Select>
              で次のステップを開始
            </div>
          </div>
        </Form.Item>
        <Form.Item label="メモ">
          <Input.TextArea
            {...formik.getFieldProps('memo')}
            disabled={!isMember}
          />
        </Form.Item>
        <Actions>
          {isMember && (
            <Button
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid || !formik.dirty}
              loading={loading}>
              保存
            </Button>
          )}
        </Actions>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .ant-form-item-label label {
    font-weight: bold;
  }

  p {
    color: var(--text-color-2);
  }

  .ant-input-number {
    width: 46px !important;
    height: 35px !important;

    input {
      text-align: center;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
`;
