import React from 'react';
import styled from 'styled-components';
import {Prelead, useClientAllProjectsQuery} from 'api';
import {Row} from 'react-table';
import {Popover} from 'components/antd';
import {Link} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';
interface Props {
  row: Row<Prelead>;
}

const ProjectCountCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const {createPath} = useProjectPath();

  const projectIds = React.useMemo(() => {
    const projectIds = prelead.preleadProjects?.map(
      (preleadProject) => preleadProject.projectId,
    );
    return Array.from(new Set(projectIds));
  }, [prelead]);

  const {data: {clientAllProjects: {projects: allProjects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      variables: {
        search: {
          status: 'open',
        },
      },
    });

  const projects = React.useMemo(
    () => allProjects.filter((project) => projectIds.includes(project.id)),
    [projectIds, allProjects],
  );

  if (projects.length === 0) return null;

  return (
    <Popover
      trigger="hover"
      placement="bottom"
      content={
        true && (
          <PopoverContent>
            <PopoverTitle>営業リスト名</PopoverTitle>
            <PopoverItems>
              {prelead.preleadProjects.map((preleadProject) => {
                return projects.map((project) => {
                  if (project.id === preleadProject.projectId) {
                    return (
                      <PopoverItem key={project.id}>
                        <Link
                          to={createPath(
                            `groups/${project.projectGroup.uuid}/projects/${project.uuid}/preleads/${preleadProject.uuid}/timeline?page=1&searchWord=${prelead.name}`,
                          )}>
                          {project.name}
                        </Link>
                      </PopoverItem>
                    );
                  }
                });
              })}
            </PopoverItems>
          </PopoverContent>
        )
      }>
      <Cell>{projects.length}</Cell>
    </Popover>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

const PopoverContent = styled.div`
  width: 300px;
  text-align: center;
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const PopoverTitle = styled.div`
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--text-color-3);
  margin-top: 12px;
  padding-bottom: 7px;
  border-bottom: 1px solid var(--border-color);

  svg {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: 4px;
  }
`;

const PopoverItems = styled.div`
  margin-top: 8px;
  padding-bottom: 15px;
`;

const PopoverItem = styled.div`
  text-align: left;
  padding: 0 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin: 10px 0px;
  color: var(--text-color-1);
`;

export default ProjectCountCell;
