import React from 'react';

function Icon() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.5" cy="17.5" r="17" fill="white" stroke="var(--text-color-3)" />
      <path
        d="M10.5 18.0001L16.3333 23.8334M25.5 18.0001H10.5H25.5ZM10.5 18.0001L16.3333 12.1667L10.5 18.0001Z"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
