import {Task, useDestroyProspectTaskMutation} from 'api';
import React from 'react';

interface Props {
  onClose: () => void;
  task: Task;
}

export default ({onClose, task}: Props) => {
  const [destroyTask] = useDestroyProspectTaskMutation({
    refetchQueries: ['taskCounts', 'prospectPool', 'tasks'],
    onCompleted: () => {
      onClose();
    },
  });
  return (
    <button
      type="button"
      onClick={() =>
        task.salesforceTaskId
          ? alert('Salesforceから作られたToDoは削除できません')
          : confirm('削除してよろしいですか?') &&
            destroyTask({variables: {uuid: task.uuid}})
      }
      className="cursor-pointer bg-white border border-[#EB5757] text-[#EB5757] text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24">
      削除
    </button>
  );
};
