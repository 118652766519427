import React from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  useUpdateProspectTelStatusCategoryPositionMutation,
  useProspectTelStatusCategoriesQuery,
} from 'api';
import {Handle} from 'components/Ui/Icon';
import New from './New';
import Update from './Update';
import {Button} from 'components/antd';
import {PlusOutlined} from '@ant-design/icons';

export default () => {
  const [updateCategoryItem, setUpdateCategoryItem] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [newModalVisible, setNewModalVisible] = React.useState(false);

  const {data: {prospectTelStatusCategories = []} = {}} =
    useProspectTelStatusCategoriesQuery();

  const [updateProspectTelStatusCategoryPosition] =
    useUpdateProspectTelStatusCategoryPositionMutation();

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...categories];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);
      setCategories(items);
      updateProspectTelStatusCategoryPosition({
        variables: {id: removed.id, position: destIndex},
      }).then((response) => {
        setCategories(
          response.data.updateProspectTelStatusCategoryPosition
            .prospectTelStatusCategories,
        );
      });
    },
    [categories],
  );

  React.useEffect(() => {
    setCategories(prospectTelStatusCategories);
  }, [prospectTelStatusCategories]);

  return (
    <div>
      <PageHeader>
        <div>
          <h3>リードのTELステータスを編集、新規追加することができます。</h3>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setNewModalVisible(true);
          }}>
          新規作成
        </Button>
      </PageHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories?.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={category.id}
                  index={index}>
                  {(provided, snapshot) => (
                    <DraggableItem
                      ref={provided.innerRef}
                      onClick={() => setUpdateCategoryItem(category)}
                      {...provided.draggableProps}
                      className={snapshot.isDragging ? 'dragging' : ''}>
                      <div className="w-8">
                        <HandleWrapper {...provided.dragHandleProps}>
                          <Handle />
                        </HandleWrapper>
                      </div>
                      <div className="w-32 flex items-center">
                        <span
                          className="w-[14px] h-[14px] rounded-full inline-block mr-2"
                          style={{
                            backgroundColor: category.color,
                          }}
                        />
                        {category.name}
                      </div>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <New
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
      <Update
        category={updateCategoryItem}
        onClose={() => setUpdateCategoryItem(null)}
      />
    </div>
  );
};

const DraggableItem = styled.div`
  min-height: 48px;
  box-shadow: 0 0 1px #444b52;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;

  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px var(--primary);
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const PageHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  > div {
    flex: 1;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;
