import React from 'react';
import {
  Prelead,
  ProjectGroupPreleadCustomizeItem,
  useUpdateProjectGroupPreleadCustomizeItemSelectMutation,
} from 'api';
import {Select as UISelect} from 'components/antd';
import styled from 'styled-components';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prelead: Prelead;
  projectGroupPreleadCustomizeItem: ProjectGroupPreleadCustomizeItem;
}

export default ({prelead, projectGroupPreleadCustomizeItem}: Props) => {
  const {isAdmin} = useClientUser();
  const [updateCustomizeItem] =
    useUpdateProjectGroupPreleadCustomizeItemSelectMutation();

  const customizeItem = prelead.projectGroupCustomizeItemSelects.find(
    (item) =>
      item.projectGroupPreleadCustomizeItemId ===
      projectGroupPreleadCustomizeItem.id,
  );

  const tags = customizeItem
    ? projectGroupPreleadCustomizeItem.selectOptions
        .filter(
          (option) =>
            customizeItem?.projectGroupPreleadCustomizeItemSelectOptionIds.includes(
              option.id,
            ),
        )
        .map((option) => option.label)
    : [];

  const [values, setValues] = React.useState(tags);

  React.useEffect(() => {
    updateCustomizeItem({
      variables: {
        uuid: prelead.uuid,
        projectGroupPreleadCustomizeItemId: projectGroupPreleadCustomizeItem.id,
        values: values,
      },
      refetchQueries: ['projectGroupPreleadCustomizeItems'],
    });
  }, [values]);

  return (
    <Select
      mode={isAdmin ? 'tags' : 'multiple'}
      style={{width: '100%'}}
      placeholder="未入力"
      value={tags}
      onChange={(value) => setValues(value as string[])}>
      {projectGroupPreleadCustomizeItem.selectOptions.map((option) => (
        <Select.Option key={option.id} value={option.label}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const Select = styled(UISelect)`
  .ant-select-selector {
    border-color: transparent !important;
    border-radius: 4px !important;
    width: 100%;
  }

  &:hover {
    border: 1px solid var(--text-color-3);
  }
`;
