import React from 'react';
import {Tooltip} from 'components/antd';
import styled from 'styled-components';
import {prospectStages} from 'helpers/prospectStages';
import useSearchParams from './useSearchParams';
import {ProspectPoolsStageCount, useProspectPoolsStageCountQuery} from 'api';
import {useParams} from 'react-router-dom';
import {CountTooltip} from './CountTooltip';

export default () => {
  const {query, setQuery, searchParams} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();
  const {
    data: {prospectPoolsStageCount, prospectPoolsStageWorkflowCount} = {},
  } = useProspectPoolsStageCountQuery({
    variables: {
      uuid: poolId,
      search: searchParams,
    },
    fetchPolicy: 'cache-and-network',
  });

  const stageCount = (targetObject: ProspectPoolsStageCount, stage: string) => {
    if (!targetObject) return 0;
    switch (stage) {
      case 'pool':
        return targetObject.pool || 0;
      case 'approach':
        return targetObject.approach || 0;
      case 'deal':
        return targetObject.deal || 0;
      case 'ng':
        return targetObject.ng || 0;
      case 'won':
        return targetObject.won || 0;
      case 'lost':
        return targetObject.lost || 0;
      case 'recycle':
        return targetObject.recycle || 0;
      case 'cancel':
        return targetObject.cancel || 0;
      case 'invalid':
        return targetObject.invalid || 0;
      default:
        return 0;
    }
  };

  const notWorkflowCount = (stage: string) =>
    stageCount(prospectPoolsStageCount, stage) -
    stageCount(prospectPoolsStageWorkflowCount, stage);

  const diffrenceCount = () =>
    prospectPoolsStageCount?.sum - prospectPoolsStageWorkflowCount?.sum || 0;

  return (
    <Container>
      <Item current={query.stage === null || query.stage === undefined}>
        <Tooltip
          title={
            <CountTooltip
              workflowCount={prospectPoolsStageWorkflowCount?.sum || 0}
              notWorkflowCount={diffrenceCount() || 0}
            />
          }>
          <div>
            <TotalCount
              current={query.stage === null && query.workflow === null}
              onClick={() => setQuery({page: 1, stage: null, workflow: null})}>
              {prospectPoolsStageCount?.sum?.toLocaleString() || 0}
            </TotalCount>
            <DisplayName>合計</DisplayName>
            <Count
              onClick={() => {
                setQuery({stage: null, page: 1, workflow: true});
              }}
              current={
                (query.stage === null || query.stage === undefined) &&
                query.workflow === true
              }>
              <Circle color="green" />
              <div>
                {prospectPoolsStageWorkflowCount?.sum?.toLocaleString()}
              </div>
            </Count>
            <Count
              onClick={() => setQuery({workflow: false, stage: null, page: 1})}
              current={
                (query.stage === null || query.stage === undefined) &&
                query.workflow === false
              }>
              <Circle color="grey" />
              <div>{diffrenceCount()?.toLocaleString()}</div>
            </Count>
          </div>
        </Tooltip>
      </Item>
      {prospectStages.map((stage) => (
        <Item key={stage.id} current={query.stage === stage.name}>
          <Tooltip
            title={
              <CountTooltip
                workflowCount={stageCount(
                  prospectPoolsStageWorkflowCount,
                  stage.name,
                )}
                notWorkflowCount={notWorkflowCount(stage.name)}
              />
            }>
            <div>
              <TotalCount
                current={query.stage === stage.name && query.workflow === null}
                onClick={() =>
                  setQuery({page: 1, stage: stage.name, workflow: null})
                }>
                {stageCount(
                  prospectPoolsStageCount,
                  stage.name,
                ).toLocaleString()}
              </TotalCount>
              <DisplayName>{stage.displayName}</DisplayName>
              <Count
                onClick={() =>
                  setQuery({workflow: true, stage: stage.name, page: 1})
                }
                current={query.stage === stage.name && query.workflow}>
                <Circle color="green" />
                <div>
                  {stageCount(
                    prospectPoolsStageWorkflowCount,
                    stage.name,
                  ).toLocaleString()}
                </div>
              </Count>
              <Count
                onClick={() =>
                  setQuery({workflow: false, stage: stage.name, page: 1})
                }
                current={
                  query.stage === stage.name && query.workflow === false
                }>
                <Circle color="grey" />
                <div>{notWorkflowCount(stage.name).toLocaleString()}</div>
              </Count>
            </div>
          </Tooltip>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
  display: table;
  width: 100%;

  & > * {
    display: table-cell;
    width: calc(100% / 10);
    height: 60px;
    padding: 0.5rem 0;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e1e6eb;

      em {
        font-style: normal;
        font-size: 20px;
        font-weight: bold;
      }
    }

    &:first-child {
      div {
        border: none;
      }
    }
  }
`;

const Item = styled.div<{current?: boolean}>`
  color: ${({current}) => (current ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  background-color: ${({current}) => (current ? '#f4faff' : '')};
`;

const TotalCount = styled.em<{current?: boolean}>`
  color: ${({current}) => (current ? 'var(--primary)' : '')};
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
`;

const DisplayName = styled.span`
  font-size: 10px;
  margin-bottom: 10px;
`;

const Count = styled.div<{current?: boolean}>`
  color: ${({current}) => (current ? 'var(--primary)' : '')};
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 60px;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
`;

const Circle = styled.div<{color: string}>`
  background-color: #4fce82;
  background-color: ${({color}) => (color === 'green' ? '#4fce82' : 'var(--text-color-3)')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
`;
