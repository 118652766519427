import React from 'react';
import {useTable} from 'react-table';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import Cell from './Cell';
import {useMailCampaignsQuery, MailCampaign, useCurrentUserQuery} from 'api';
// @ts-ignore
import useSearchParams from './useSearchParams';
import moment from 'moment';

export default () => {
  const {perPage, setQuery, query} = useSearchParams();
  const {
    data: {mailCampaigns: {mailCampaigns = [], pagination = {}} = {}} = {},
  } = useMailCampaignsQuery({
    variables: {
      page: query.page,
      perPage: perPage,
      status: query.status || null,
      orderBy: query.orderBy || 'created_at_desc',
      target: query.target,
      searchWord: query.searchWord,
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery();

  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'キャンペーン名',
        Cell: Cell.Title,
      },
      {
        id: 'status',
        Header: '',
        Cell: Cell.Status,
        style: {width: '120px'},
      },
      {
        id: 'date',
        Header: '送信日時',
        Cell: Cell.Date,
        style: {width: '160px'},
      },
      {
        id: 'count',
        Header: '配信',
        accessor: (row: MailCampaign) => (
          <div style={{textAlign: 'right', fontWeight: 700}}>
            {row.status === 'sent' && row.target == 'prospects' ? (
              <a
                href={`/pools/${row.pool.uuid}/prospects?mailCampaignId=${row.uuid}`}
                target="_blank">
                {row.mailCampaignSendsCount.toLocaleString()}
              </a>
            ) : (
              row.mailCampaignSendsCount.toLocaleString()
            )}
          </div>
        ),
        style: {width: '100px'},
      },
      {
        id: 'open',
        Header: '開封',
        accessor: (row: MailCampaign) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <SendedCount>
              {row.status === 'sent' && row.target == 'prospects' ? (
                <a
                  href={`/pools/${row.pool.uuid}/prospects?mailCampaignId=${
                    row.uuid
                  }&mailCampaignOpenedToDate=${moment().format('YYYY-MM-DD')}`}
                  target="_blank">
                  {row.mailCampaignSendsOpenedCount.toLocaleString()}
                </a>
              ) : (
                row.mailCampaignSendsOpenedCount.toLocaleString()
              )}
            </SendedCount>
            <SendedPersent>
              (
              {row.mailCampaignSendsCount > 0 &&
              row.mailCampaignSendsOpenedCount > 0
                ? (
                    (row.mailCampaignSendsOpenedCount /
                      row.mailCampaignSendsCount) *
                    100
                  ).toFixed(1)
                : 0}
              %)
            </SendedPersent>
          </div>
        ),
        style: {width: '100px'},
      },
      {
        id: 'click',
        Header: 'クリック',
        accessor: (row: MailCampaign) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <SendedCount>
              {row.status === 'sent' && row.target == 'prospects' ? (
                <a
                  href={`/pools/${row.pool.uuid}/prospects?mailCampaignId=${
                    row.uuid
                  }&mailCampaignClickedToDate=${moment().format('YYYY-MM-DD')}`}
                  target="_blank">
                  {row.mailCampaignSendsClickedCount.toLocaleString()}
                </a>
              ) : (
                row.mailCampaignSendsClickedCount.toLocaleString()
              )}
            </SendedCount>
            <SendedPersent>
              (
              {row.mailCampaignSendsCount > 0 &&
              row.mailCampaignSendsClickedCount > 0
                ? (
                    (row.mailCampaignSendsClickedCount /
                      row.mailCampaignSendsCount) *
                    100
                  ).toFixed(1)
                : 0}
              %)
            </SendedPersent>
          </div>
        ),
        style: {width: '100px'},
      },
      {
        id: 'bounce',
        Header: 'バウンス',
        accessor: (row: MailCampaign) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <SendedCount>
              {row.status === 'sent' && row.target == 'prospects' ? (
                <a
                  href={`/pools/${row.pool.uuid}/prospects?mailCampaignId=${row.uuid}&isBouncedMailCampaignSend=1`}
                  target="_blank">
                  {row.mailCampaignSendsBouncedCount.toLocaleString()}
                </a>
              ) : (
                row.mailCampaignSendsBouncedCount.toLocaleString()
              )}
            </SendedCount>
            <SendedPersent>
              (
              {row.mailCampaignSendsCount > 0 &&
              row.mailCampaignSendsBouncedCount > 0
                ? (
                    (row.mailCampaignSendsBouncedCount /
                      row.mailCampaignSendsCount) *
                    100
                  ).toFixed(1)
                : 0}
              %)
            </SendedPersent>
          </div>
        ),
        style: {width: '100px'},
      },
      {
        id: 'unsubscribe',
        Header: '配信停止',
        accessor: (row: MailCampaign) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <SendedCount>
              {row.status === 'sent' && row.target == 'prospects' ? (
                <a
                  href={`/pools/${row.pool.uuid}/prospects?mailCampaignId=${row.uuid}&isUnsubscribedMailCampaignSend=1`}
                  target="_blank">
                  {row.mailCampaignSendsUnsubscribedCount.toLocaleString()}
                </a>
              ) : (
                row.mailCampaignSendsUnsubscribedCount.toLocaleString()
              )}
            </SendedCount>
            <SendedPersent>
              (
              {row.mailCampaignSendsCount > 0 &&
              row.mailCampaignSendsUnsubscribedCount > 0
                ? (
                    (row.mailCampaignSendsUnsubscribedCount /
                      row.mailCampaignSendsCount) *
                    100
                  ).toFixed(1)
                : 0}
              %)
            </SendedPersent>
          </div>
        ),
        style: {width: '100px'},
      },
      {
        id: 'action',
        Header: '',
        Cell: Cell.Action,
        style: {width: '60px'},
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<MailCampaign>({columns, data: mailCampaigns || []});

  return (
    <Container>
      <ContentsTable {...getTableProps()} cellSpacing="0" cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </ContentsTable>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={perPage}
        onChange={(page) => {
          setQuery({page});
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentsTable = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 1rem;
    margin: 0px;
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: var(--text-color-2);
        text-align: left;
        border-bottom: solid 1px #e1e6eb;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: solid 1px #e1e6eb;
        font-weight: 700;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
`;

const SendedCount = styled.div`
  font-weight: 700;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  justify-content: flex-end;
`;

const SendedPersent = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  justify-content: flex-end;
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;
