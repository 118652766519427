import React from 'react';
import styled from 'styled-components';

type Props = {
  color?: string;
  width: number;
  disabled?: boolean;
  className?: string;
  style?: any;
};

export default ({...props}: Props) => {
  return <Progressbar {...props} />;
};

const Progressbar = styled.span<Props>`
  width: ${({width}) => `${width * 10}px`};
  height: 8px;
  background: ${({color}) => (color ? color : 'var(--text-color-3)')} !important;
  border-radius: 30px;
  display: inline-block;
`;
