import React from 'react';
import {useHistory, useParams} from 'react-router';
import styled from 'styled-components';
import {Cross} from 'components/Ui/Icon';
import {message, Form, Select, Tooltip, Button} from 'components/antd';
import {useUserQuery, useUpdateClientUserMutation} from 'api';
import {useQueryParams, NumberParam} from 'use-query-params';
import useContract from 'hooks/useContract';
import {useFormik} from 'formik';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {userId} = useParams<{userId: string}>();
  const {data: {user = {}} = {}} = useUserQuery({
    variables: {uuid: userId},
  });
  const history = useHistory();
  const {isUserAddable} = useContract();
  const {isAdmin} = useClientUser();

  const [query] = useQueryParams({
    active: NumberParam,
  });

  const {active} = query;

  const [updateClientUser] = useUpdateClientUserMutation({
    refetchQueries: ['usersByClient', 'allClientUsers'],
    onCompleted: (data) => {
      message.success('更新しました');
      if (data.updateClientUser.clientUser.isActive) {
        history.push(`/settings/users?active=0`);
      } else {
        history.push(`/settings/users?active=1`);
      }
      onClose();
    },
  });

  const clientUser = user?.clientUser;

  const onClose = () => history.push(`/settings/users/${location.search}`);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isActive: clientUser?.isActive,
      role: clientUser?.role,
    },
    onSubmit: (values) =>
      updateClientUser({
        variables: {
          id: clientUser?.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Container>
      <Header>
        <Title>ユーザー詳細</Title>
        <CloseButton onClick={onClose}>
          <Cross />
        </CloseButton>
      </Header>

      <Description>
        <FullName>
          {user.lastName} {user.firstName}
        </FullName>
        <IsActiveLabel isActive={clientUser?.isActive}>
          {clientUser?.isActive ? '有効' : '無効'}
        </IsActiveLabel>
      </Description>

      <Form onFinish={formik.handleSubmit}>
        <List>
          <Divider />
          <Item>
            <Attribute>ユーザーアカウント</Attribute>
            <div>
              {active == 0 ? (
                <Select
                  value={formik.values.isActive ? '有効' : '無効'}
                  onChange={(value: any) =>
                    formik.setFieldValue(
                      'isActive',
                      value === 'true' ? true : false,
                    )
                  }
                  style={{width: '130px'}}
                  size="middle">
                  <Select.Option value={'true'}>有効</Select.Option>
                  <Select.Option value={'false'}>無効</Select.Option>
                </Select>
              ) : (
                <Tooltip
                  title={!isUserAddable ? '登録人数が上限に達しました' : null}
                  color={`#EB5757`}
                  placement="top">
                  <Select
                    value={formik.values.isActive ? '有効' : '無効'}
                    onChange={(value: any) =>
                      formik.setFieldValue(
                        'isActive',
                        value === 'true' ? true : false,
                      )
                    }
                    style={{width: '130px'}}
                    size="middle"
                    disabled={!isUserAddable}>
                    <Select.Option value={'true'}>有効</Select.Option>
                    <Select.Option value={'false'}>無効</Select.Option>
                  </Select>
                </Tooltip>
              )}
            </div>
          </Item>
          <Divider />
          <Item>
            <Attribute>権限</Attribute>
            <div>
              <Select
                value={formik.values.role}
                onSelect={(value: string) =>
                  formik.setFieldValue('role', value)
                }
                style={{width: '130px'}}
                size="middle">
                <Select.Option value="owner">オーナー</Select.Option>
                <Select.Option value="member">メンバー</Select.Option>
                <Select.Option value="guest">ゲスト</Select.Option>
              </Select>
            </div>
          </Item>
          <Divider />
          <Item>
            <Attribute>email</Attribute>
            <div>{user.email}</div>
          </Item>
          <Divider />
          <Item>
            <Attribute>ユーザー名</Attribute>
            <div>{user.userName}</div>
          </Item>
          <Divider />
          <Item>
            <Attribute>姓</Attribute>
            <div>{user.lastName}</div>
          </Item>
          <Divider />
          <Item>
            <Attribute>名</Attribute>
            <div>{user.firstName}</div>
          </Item>
        </List>
        <Actions>
          <Button onClick={onClose}>キャンセル</Button>
          {isAdmin && (
            <Button
              type="primary"
              htmlType="submit"
              style={{marginLeft: '1rem', padding: '0 31px'}}>
              保存
            </Button>
          )}
        </Actions>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: max(600px, calc(65vw - 500px));
  height: 100vh;
  background: #ffffff;
  z-index: 1;
  border-left: 1px solid var(--border-color);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  border-bottom: 1px solid var(--border-color);
`;

const Title = styled.div`
  margin-left: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: var(--text-color-2);
`;

const CloseButton = styled.span`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 25px;

  svg {
    margin: 9px;
  }
`;

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
`;

const FullName = styled.div`
  font-size: 20px;
  margin-left: 40px;
`;

const IsActiveLabel = styled.div<{isActive?: boolean}>`
  background: #ffffff;
  border: 1px solid ${({isActive}) => (isActive ? '#4FCE82' : '#B55D62')};
  color: ${({isActive}) => (isActive ? '#4FCE82' : '#B55D62')};
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 2rem;
  padding: 6px 26px;
`;

const List = styled.div`
  padding: 0 34px 0 24px;

  .ant-form-item {
    margin-bottom: 30px;
  }
  .ant-select-arrow {
    top: 58%;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 15px 0;

  div {
    font-size: 14px;
    border: 0;
    width: 64%;
  }
`;

const Attribute = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  color: var(--text-color-2);
  padding: 0 21px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: end;
  padding: 1rem 2rem 0 0;

  button {
    height: 35px;
    width: 120px;
    border-radius: 4px;
    font-size: 14px;
  }
`;

const Divider = styled.hr`
  border: none;
  background-color: var(--border-color);
  height: 1px;
`;
