import React from 'react';
import styled from 'styled-components';
import {Route, Switch, useParams, Link} from 'react-router-dom';
import {Quill, QuillWrapper, StatusEclipse} from 'components/Ui';
import {Button, Select} from 'components/antd';
import {
  useCreateMailCommentActivityMutation,
  usePreleadProjectQuery,
} from 'api';
import TimelineItem from 'components/TimelineItem';
import usePreleadProject from 'hooks/usePreleadProject';
import New from './New';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';
import {Refresh} from 'components/Ui/Icon';

const responseStatusOptions = [
  {text: '未選択', value: null, color: ''},
  {text: '返信あり', value: 'reply', color: '#2D9CDB'},
  {text: 'アポ獲得', value: 'appoint', color: '#27ae60'},
  {text: 'お断り・クレーム', value: 'refusal', color: '#EB5757'},
];

export default () => {
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();
  const {createPath} = useProjectPath();
  const {timeLineMailActivities} = usePreleadProject(preleadProjectId);
  const [comment, setComment] = React.useState('');
  const [bodyPlainText, setBodyPlainText] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [createMailCommentActivity, {loading}] =
    useCreateMailCommentActivityMutation({
      refetchQueries: ['preleadProject'],
      onCompleted: () => {
        setComment('');
        setBodyPlainText(null);
        setStatus(null);
      },
    });
  const addComment = () =>
    createMailCommentActivity({
      variables: {
        uuid: preleadProjectId,
        responseStatus: status,
        attributes: {body: comment, bodyPlainText: bodyPlainText},
      },
    });

  const {
    data: {
      preleadProject = null,
      preleadProject: {prelead: {mailActivities = []} = {}} = {},
    } = {},
    refetch,
    loading: loadingPreleadProject,
  } = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
    notifyOnNetworkStatusChange: true,
  });

  if (!preleadProject) return null;

  return (
    <Container>
      <Actions>
        <Button
          onClick={() => refetch()}
          icon={<Refresh spin={loadingPreleadProject} />}
          disabled={loadingPreleadProject}
        />
        <Link
          className="ant-btn"
          to={createPath(
            `projects/${projectId}/preleads/${preleadProjectId}/mail/new${location.search}`,
          )}>
          + Eメール作成
        </Link>
      </Actions>
      <Switch>
        <Route
          path={projectRoutePaths(
            'projects/:projectId/preleads/:preleadProjectId/mail/new',
          )}
          component={New}
        />
        <Route default>
          <>
            <QuillWrapper>
              <Quill
                placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
                theme="snow"
                value={comment}
                onChange={(content, delta, source, editor) => {
                  setComment(content);
                  setBodyPlainText(editor.getText());
                }}
                modules={{
                  toolbar: [
                    [{header: [1, 2, 3, false]}],
                    ['bold', 'italic', 'underline'],
                    ['code-block'],
                    ['link'],
                  ],
                }}
                formats={[
                  'header',
                  'list',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'color',
                  'background',
                  'code-block',
                  'link',
                ]}
                bounds={'#quill-container'}
              />
              <div id="quill-container" />
              <div>
                <StatusSelect
                  value={status}
                  onSelect={(value: any) => setStatus(value)}
                  optionLabelProp="label">
                  {responseStatusOptions.map((option) => (
                    <StatusSelect.Option
                      value={option.value}
                      key={option.value}
                      label={
                        <React.Fragment>
                          <StatusEclipse color={option.color} />
                          &nbsp; {option.text}
                        </React.Fragment>
                      }>
                      <StatusEclipse color={option.color} />
                      &nbsp; {option.text}
                    </StatusSelect.Option>
                  ))}
                </StatusSelect>
                <Button
                  disabled={!comment.replace(/<\/?[^>]+(>|$)/g, '') || loading}
                  onClick={addComment}>
                  登録
                </Button>
              </div>
            </QuillWrapper>
            {timeLineMailActivities(mailActivities).map((activity) => (
              <TimelineItem key={activity.uuid} activity={activity} />
            ))}
          </>
        </Route>
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StatusSelect = styled(Select)`
  min-width: 150px !important;

  .ant-select-arrow {
    svg {
      margin-left: 5px;
      width: 10px;
      height: 10px;

      path {
        fill: var(--text-color-1);
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
