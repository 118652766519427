import React from 'react';

const Icon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.75 11.25L11.25 0.75M0.75 0.75L11.25 11.25L0.75 0.75Z"
      stroke="var(--text-color-3)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
