import React from 'react';
import styled from 'styled-components';
import HubspotDeal from './HubspotDeal';

export default () => {
  return (
    <Container>
      <Title>対象を選択</Title>
      <Item>
        <ItemTitle>
          <Label>取引</Label>
        </ItemTitle>
        <HubspotDeal />
      </Item>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 34px;
`;

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 500;
`;

const Item = styled.div`
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  min-height: 130px;
  padding: 25px;
`;

const ItemTitle = styled.div`
  display: flex;
  gap: 8px;
  height: 20px;
  margin-bottom: 20px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 700;
  font-size: 16px;
  color: var(--text-color-0);
  display: inline-block;
  line-height: 20px;
`;
