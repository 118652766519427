import {PenWithLine} from 'components/Ui/Icon';
import React, {useState} from 'react';
import EditTelModal from './EditTelModal';

interface Props {
  telephoneNumber: string;
}

export default ({telephoneNumber}: Props) => {
  const [editTelModalVisible, setEditTelModalVisible] = useState(false);

  return (
    <>
      <div
        className="w-5 h-5 rounded cursor-pointer invisible group-hover:visible hover:bg-[#D6EBFF] flex items-center justify-center"
        onClick={() => setEditTelModalVisible(true)}>
        <PenWithLine color="var(--primary)" />
      </div>
      <EditTelModal
        telephoneNumber={telephoneNumber}
        visible={editTelModalVisible}
        onClose={() => setEditTelModalVisible(false)}
      />
    </>
  );
};
