import {
  PreleadTag,
  usePublicPreleadTagsQuery,
  useUpdatePreleadTagMutation,
} from 'api';
import {Input, message} from 'components/antd';
import React, {useMemo, useState} from 'react';

interface Props {
  preleadTag: PreleadTag;
}

export default ({preleadTag}: Props) => {
  const [tagName, setTagName] = useState(preleadTag.name);
  const {data: {publicPreleadTags = []} = {}} = usePublicPreleadTagsQuery();

  const [updatePreleadTag] = useUpdatePreleadTagMutation({
    refetchQueries: ['currentClient', 'accountPool'],
  });

  const handleChangeTagName = () => {
    updatePreleadTag({
      variables: {
        uuid: preleadTag.uuid,
        attributes: {
          name: tagName,
        },
      },
    });
  };

  const alreadyNameExist: boolean = useMemo(
    () => publicPreleadTags?.some((tag) => tag.name === tagName),
    [publicPreleadTags, tagName],
  );

  return (
    <div className="p-2">
      <Input
        type="title"
        name="tag-edit"
        value={tagName}
        autoComplete="off"
        onChange={(e: any) => setTagName(e.target.value)}
        onBlur={() => {
          if (!tagName) return;
          if (!alreadyNameExist) {
            handleChangeTagName();
          }
        }}
        onPressEnter={() => {
          if (!tagName) return;
          if (!alreadyNameExist) {
            handleChangeTagName();
          } else {
            message.info('既に同じ名前のタグが存在します。');
          }
        }}
      />
    </div>
  );
};
