import React from 'react';
import {useExecuteMergeProspectMutation, useMergeProspectQuery} from 'api';
import {CheckedProspectPoolsContext} from '../../CheckedProspectPoolContext';
import {MergeProspectContext} from '../MergeProspectContext';

interface Props {
  onClose: () => void;
}

export default ({onClose}: Props) => {
  const {mergeProspectUuid} = React.useContext(MergeProspectContext);
  const {data: {mergeProspect = {}} = {}} = useMergeProspectQuery({
    variables: {uuid: mergeProspectUuid},
  });
  const mergeProspectProspectPools =
    mergeProspect?.mergeProspectProspectPools || [];
  const isTargetMergeProspectPools = React.useMemo(() => {
    return mergeProspectProspectPools.filter(
      (item) => item.isTarget && item.merge === 'from',
    );
  }, [mergeProspectProspectPools]);
  const {setCheckedProspectPoolIds} = React.useContext(
    CheckedProspectPoolsContext,
  );
  const [execute, {loading}] = useExecuteMergeProspectMutation({
    variables: {uuid: mergeProspectUuid},
    onCompleted: () => {
      setCheckedProspectPoolIds([]);
      onClose();
    },
    refetchQueries: ['prospectPools', 'prospectPoolsStageCount'],
  });

  return (
    <div className="flex items-center justify-between px-6 py-4 gap-4 bg-c-bg rounded-b">
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold text-foreground-light"
        onClick={onClose}>
        閉じる
      </button>
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold disabled:opacity-50"
        disabled={isTargetMergeProspectPools?.length === 0 || loading}
        onClick={() =>
          confirm('名寄せ処理を実行してよろしいですか?') && execute()
        }>
        名寄せ処理実行
      </button>
    </div>
  );
};
