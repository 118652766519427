import React from 'react';

const Icon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25 14.25H11.75C12.4404 14.25 13 13.6904 13 13V6.64277C13 6.31124 12.8683 5.99331 12.6339 5.75888L8.99112 2.11612C8.75669 1.88169 8.43875 1.75 8.10725 1.75H4.25C3.55964 1.75 3 2.30964 3 3V13C3 13.6904 3.55964 14.25 4.25 14.25Z"
      fill="var(--text-color-3)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.625 2.0625V6.125H12.375"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.75 9.875L7 11L9.5 8.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
