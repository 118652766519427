import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import usePools from 'hooks/usePools';

export default () => {
  const {selectedPool} = usePools();
  const history = useHistory();

  return (
    <Container>
      <ItemWrapper>
        <Title>リード</Title>
        {/* <Item>ステージ</Item>
        <Item>失注理由</Item>
         */}
        <Item
          onClick={() =>
            history.push(`/settings/pools/${selectedPool.uuid}/lead_sources`)
          }
          active={location.pathname.startsWith(
            `/settings/pools/${selectedPool.uuid}/lead_sources`,
          )}>
          リードソース
        </Item>
        {/* <Item>TELステータス</Item> */}
      </ItemWrapper>

      {/* <ItemWrapper>
        <Title>ToDo</Title>
        <Item>ステータス</Item>
      </ItemWrapper>

      <ItemWrapper>
        <Title>メモ</Title>
        <Item>事前調査項目</Item>
        <Item>ヒアリング項目</Item>
        <Item>引き継ぎ項目</Item>
      </ItemWrapper> */}
    </Container>
  );
};

const Container = styled.div`
  margin-right: 58px;
`;

const ItemWrapper = styled.div`
  margin-bottom: 30px;
`;

const Item = styled.div<{active?: boolean}>`
  cursor: pointer;
  width: 162px;
  height: 35px;
  background: ${({active}) => (active ? '#f3f7fb' : '')};
  border-radius: 4px;
  padding-left: 5px;

  color: ${({active}) => (active ? 'var(--text-color-1)' : 'var(--text-color-2)')};
  font-weight: ${({active}) => (active ? 'bold' : '500')};
  font-size: 14px;
  line-height: 35px;
`;

const Title = styled.div`
  margin-bottom: 10px;
  padding-left: 5px;
  font-weight: 500;
  font-size: 12px;
  color: var(--text-color-3);
`;
