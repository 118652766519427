import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 3.33337H3.33333C2.8731 3.33337 2.5 3.70647 2.5 4.16671V12.5C2.5 12.9603 2.8731 13.3334 3.33333 13.3334H16.6667C17.1269 13.3334 17.5 12.9603 17.5 12.5V4.16671C17.5 3.70647 17.1269 3.33337 16.6667 3.33337Z"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 16.6666H18.3337"
        stroke="var(--text-color-3)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
