import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Button, Input, message} from 'components/antd';
import {
  PreleadTag,
  useUpdatePreleadTagMutation,
  useDestroyPreleadTagMutation,
  useCurrentClientQuery,
} from 'api';
import {GithubPicker} from 'react-color';
import {Trash} from 'components/Ui/Icon';
import {tagColors} from 'helpers/tagColors';

interface Props {
  tag: PreleadTag;
  onClose: () => void;
}

export default ({tag, onClose}: Props) => {
  const [tagName, setTagName] = useState(tag.name);

  const handleClose = () => {
    setTagName(null);
    onClose();
  };

  const {data: {currentClient: {preleadTags = []} = {}} = {}} =
    useCurrentClientQuery({fetchPolicy: 'cache-and-network'});

  const [updatePreleadTag] = useUpdatePreleadTagMutation({
    refetchQueries: ['preleadProject'],
  });

  const [destroyPreleadTag, {loading}] = useDestroyPreleadTagMutation({
    refetchQueries: ['preleadProject'],
    onCompleted: handleClose,
  });

  const alreadyNameExist: boolean = useMemo(
    () => preleadTags?.some((tag) => tag.name === tagName),
    [preleadTags, tagName],
  );

  const handleDeleteTag = () => {
    if (confirm('削除してよろしいですか？')) {
      destroyPreleadTag({
        variables: {
          uuid: tag.uuid,
        },
      });
    }
  };

  const handleChangeTagName = () => {
    updatePreleadTag({
      variables: {
        uuid: tag.uuid,
        attributes: {
          name: tagName,
        },
      },
    });
  };

  const handleChangeTagColor = (colorObject: any) => {
    updatePreleadTag({
      variables: {
        uuid: tag.uuid,
        attributes: {
          color: colorObject.hex,
        },
      },
    });
  };

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <TagInput
        type="title"
        name="tag-edit"
        placeholder={tagName}
        value={tagName}
        onChange={(e: any) => setTagName(e.target.value)}
        onBlur={() => {
          if (!tagName) return;
          if (!alreadyNameExist) {
            handleChangeTagName();
          }
        }}
        onPressEnter={() => {
          if (!tagName) return;
          if (!alreadyNameExist) {
            handleChangeTagName();
          } else {
            message.info('既に同じ名前のタグが存在します。');
          }
        }}
      />

      <DeleteButton type="text" loading={loading} onClick={handleDeleteTag}>
        <Trash />
        削除
      </DeleteButton>
      <h3>色を選択</h3>
      <ColorPicker
        onChange={handleChangeTagColor}
        colors={tagColors.map((color) => color.hex)}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 260px;
  padding: 13px 11px;

  h3 {
    margin-top: 5px;
    font-size: 10px;
    color: var(--text-color-2);
  }
`;

const TagInput = styled(Input)`
  display: flex;
  align-items: center;
  margin-right: 11px;
  width: 100%;
  height: 34px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  box-sizing: border-box;
  border-radius: 2px;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: var(--text-color-3);
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;

const DeleteButton = styled(Button)`
  margin-top: 3px;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-2);

  svg {
    margin-right: 4px;
    path {
      stroke: var(--text-color-2);
    }
  }
`;

const ColorPicker = styled(GithubPicker)`
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;

  > div {
    display: none;
  }

  > span {
    > div {
      width: 20px !important;
      height: 20px !important;
      margin-right: 9px;
      margin-bottom: 8px;

      > span {
        > div {
          border-radius: 2px;
        }
      }
    }

    &:nth-child(10),
    &:nth-child(18) {
      > div {
        margin-right: 0px !important;
      }
    }
    &:nth-last-child(-n + 9) {
      > div {
        margin-bottom: 0px;
      }
    }
  }
`;
