import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = [
    '2024年',
    '合計',
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  const data: ReportTableData[] = [
    {
      label: '合計',
      values: [687, 34, 22, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      cellProps: {
        style: {
          borderBottom: 'solid 1px var(--text-color-2)',
        },
      },
    },
  ];

  return {
    data,
    headers,
  };
};

export default useTableData;
