import React from 'react';
import styled from 'styled-components';
import {
  MailCampaignBlock,
  MailCampaignBlockDivider,
  MailCampaignBlockDividerAttributes,
  useUpdateMailCampaignBlockDividerMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input} from 'components/antd';
import ColorInput from './ColorInput';
import PaddingInput from './PaddingInput';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockDivider;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {
    bodyColor,
    lineColor,
    lineWidth,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = block.blockable;

  const [update] = useUpdateMailCampaignBlockDividerMutation();

  const formik = useFormik({
    initialValues: {
      bodyColor,
      lineColor,
      lineWidth,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    },
    validationSchema: Yup.object().shape({
      bodyColor: Yup.string().nullable(),
      lineColor: Yup.string().nullable(),
      lineWidth: Yup.number(),
    }),
    onSubmit: (values) => {
      update({
        variables: {
          uuid: block.uuid,
          attributes: values,
        },
      });
    },
  });

  const debouncedValue = useDebounce<MailCampaignBlockDividerAttributes>(
    formik.values,
    500,
  );

  React.useEffect(() => {
    formik.submitForm();
  }, [debouncedValue]);

  return (
    <Container>
      <h2>区切り線を編集</h2>
      <Form layout="vertical">
        <Form.Item label="余白">
          <PaddingInput
            values={{
              paddingTop: formik.values.paddingTop,
              paddingBottom: formik.values.paddingBottom,
              paddingLeft: formik.values.paddingLeft,
              paddingRight: formik.values.paddingRight,
            }}
            onChange={(values) => {
              formik.setValues({...formik.values, ...values});
            }}
          />
        </Form.Item>
        <Form.Item>
          <label>
            線の幅 <span>{formik.values.lineWidth}px</span>
          </label>
          <Input
            type="range"
            min={1}
            max={50}
            value={formik.values.lineWidth}
            step={1}
            onChange={(e) =>
              formik.setFieldValue('lineWidth', e.target.valueAsNumber)
            }
          />
        </Form.Item>
        <Form.Item label="線の色">
          <ColorInput
            color={formik.values.lineColor}
            onChange={(color) => formik.setFieldValue('lineColor', color)}
          />
        </Form.Item>
        <Form.Item label="背景色">
          <ColorInput
            color={formik.values.bodyColor}
            onChange={(color) => formik.setFieldValue('bodyColor', color)}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  label {
    font-size: 14px;
    color: var(--text-color-2);
    font-weight: 500;

    span {
      float: right;
    }
  }
`;
