import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Cross} from 'components/Ui/Icon';
import {
  Tel as TelIcon,
  Mail as MailIcon,
  SnsAndOthers as SnsAndOthersIcon,
  Comment as CommentIcon,
  Task as TaskIcon,
} from '../Icon';
import TelForm from './TelForm';
import MailForm from './MailForm';
import CommentForm from './CommentForm';
import TaskForm from './TaskForm';
import {ProspectPool} from 'api';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  actionUuid: string;
  prospectPool: ProspectPool;
  activityType: string;
  setActivityType: React.Dispatch<React.SetStateAction<string>>;
};

export default ({
  isOpen,
  setIsOpen,
  actionUuid,
  activityType,
  setActivityType,
  prospectPool,
}: Props) => {
  const onClose = () => {
    setIsOpen(false);
    setActivityType('tel');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      style={customStyles}>
      <Header>
        <div>ステップの記録</div>
        <div onClick={() => onClose()}>
          <Cross />
        </div>
      </Header>
      <Tab>
        <TabItem
          onClick={() => setActivityType('tel')}
          isActive={activityType === 'tel'}>
          <TelIcon />
          <div>TEL</div>
        </TabItem>
        <TabItem
          onClick={() => setActivityType('mail')}
          isActive={activityType === 'mail'}>
          <MailIcon />
          <div>メール</div>
        </TabItem>
        <TabItem
          onClick={() => setActivityType('comment')}
          isActive={activityType === 'comment'}>
          <CommentIcon />
          <div>コメント</div>
        </TabItem>
        <TabItem
          onClick={() => setActivityType('task')}
          isActive={activityType === 'task'}>
          <TaskIcon />
          <div>タスク</div>
        </TabItem>
        {/* <TabItem
          onClick={() => setActivityType('snsAndOthers')}
          style={{opacity: 0, cursor: 'default'}}
          isActive={activityType === 'snsAndOthers'}>
          <SnsAndOthersIcon />
          <div>SNS・その他ログ</div>
        </TabItem> */}
      </Tab>
      <Content>
        {activityType === 'tel' && (
          <TelForm actionUuid={actionUuid} prospectPool={prospectPool} />
        )}
        {activityType === 'mail' && (
          <MailForm actionUuid={actionUuid} prospectPool={prospectPool} />
        )}
        {/* {activityType === 'snsAndOthers' && <SnsAndOthersForm />} */}
        {activityType === 'comment' && (
          <CommentForm actionUuid={actionUuid} prospectPool={prospectPool} />
        )}
        {activityType === 'task' && (
          <TaskForm actionUuid={actionUuid} prospectPool={prospectPool} />
        )}
      </Content>
    </Modal>
  );
};

const customStyles = {
  overlay: {
    background: 'rgba(1, 1, 1, 0.5)',
    zIndex: 100,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '538px',
    padding: '0',
  },
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f5f7;
  height: 41px;
  padding: 0 27px;

  > div:first-child {
    font-weight: 500;
    font-size: 14px;
  }

  > div:last-child {
    cursor: pointer;

    svg {
      width: 9px;
      height: 9px;

      path {
        stroke: var(--text-color-1);
      }
    }
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 65px;
  border-bottom: 1px solid #e1e6eb;
  height: 90px;
`;

const TabItem = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  opacity: ${({isActive}) => !isActive && '0.3'};
  border-bottom: ${({isActive}) => isActive && '2px solid var(--text-color-1)'};
`;

const Content = styled.div``;
