import React from 'react';
import moment from 'moment';
import {ProspectPool} from 'api';

type Props = {
  prospectPool: ProspectPool;
};

const Tracking: React.FC<Props> = ({prospectPool}) => {
  const openedDate = Math.max(
    prospectPool.workflowMailOpenedDate
      ? moment(prospectPool.workflowMailOpenedDate).unix()
      : 0,
    prospectPool.mailCampaignOpenedAt
      ? moment(prospectPool.mailCampaignOpenedAt).unix()
      : 0,
  );

  const clickedDate = Math.max(
    prospectPool.workflowMailClickedDate
      ? moment(prospectPool.workflowMailClickedDate).unix()
      : 0,
    prospectPool.mailCampaignClickedAt
      ? moment(prospectPool.mailCampaignClickedAt).unix()
      : 0,
  );

  return (
    <div className="px-4 my-4 flex flex-col gap-1 border-r border-c-border w-[calc((100%-100px)/4)]">
      <div className="flex items-center gap-2">
        <div className="text-sm text-c-light">Web訪問日</div>
        <div className="bg-[#E5E9F4] flex items-center px-2 h-3 rounded-full text-xs leading-none font-bold tracking-tight">
          {prospectPool.trackedAt
            ? moment(prospectPool.trackedAt).format('YYYY/MM/DD')
            : '-'}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-sm text-c-light">開封</div>
        <div className="bg-[#E5E9F4] flex items-center px-2 h-3 rounded-full text-xs leading-none font-bold tracking-tight">
          {openedDate ? moment.unix(openedDate).format('YYYY/MM/DD') : '-'}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-sm text-c-light">クリック</div>
        <div className="bg-[#E5E9F4] flex items-center px-2 h-3 rounded-full text-xs leading-none font-bold tracking-tight">
          {clickedDate ? moment.unix(clickedDate).format('YYYY/MM/DD') : '-'}
        </div>
      </div>
    </div>
  );
};

export default Tracking;
