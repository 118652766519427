import React from 'react';
import {ProspectPool, useCurrentUserQuery} from 'api';
import ProspectScore from 'components/ProspectScore';

type Props = {
  prospectPool: ProspectPool;
};

const Score: React.FC<Props> = ({prospectPool}) => {
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  if (currentUser?.role !== 'admin') {
    return null;
  }

  return (
    <div className="px-4 my-4 flex flex-col gap-1 border-r border-c-border w-[100px] items-center justify-center">
      <ProspectScore prospectPool={prospectPool} />
    </div>
  );
};

export default Score;
