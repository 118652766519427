import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useAccountPoolQuery,
  ClientPreleadCustomizeItem,
  useUpdateAccountCustomizeItemNumberMutation,
} from 'api';

export default ({item}: {item: ClientPreleadCustomizeItem}) => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {account = {}} = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const [update] = useUpdateAccountCustomizeItemNumberMutation({});

  return (
    <input
      type="number"
      className="rounded p-2 w-60 h-7 focus:outline-none focus:outline-[#E6E6E6] focus:outline-1"
      placeholder="未入力"
      data-1p-ignore
      defaultValue={
        account?.customizeItemPayload
          ? account?.customizeItemPayload[item.id]
          : ''
      }
      onChange={(e) => {
        update({
          variables: {
            uuid: account?.uuid,
            clientPreleadCustomizeItemId: item.id,
            value: e.target.value ? Number(e.target.value) : null,
          },
        });
      }}
    />
  );
};
