import React from 'react';
import {ProspectPool} from 'api';
import useMergeModalContext from '../useMergeModalContext';
import CheckIcon from './CheckIcon';
import ProspectInfo from './ProspectInfo';

interface Props {
  prospectPool: ProspectPool;
  onClick?: () => void;
  prospectPoolUuid: string;
}

const Prospect = ({prospectPool, onClick, prospectPoolUuid}: Props) => {
  const {mergeFrom, mergeTo} = useMergeModalContext();
  const active = [prospectPoolUuid, mergeFrom, mergeTo].includes(
    prospectPool.uuid,
  );

  return (
    <div className="my-2">
      {mergeFrom === prospectPool.uuid && (
        <div className="bg-primary rounded-t w-20 h-5 text-white flex items-center justify-center leading-5 gap-1">
          <CheckIcon />
          <span>名寄せ元</span>
        </div>
      )}
      {mergeTo === prospectPool.uuid && (
        <div className="bg-primary rounded-t w-20 h-5 text-white flex items-center justify-center leading-5 gap-1">
          <CheckIcon />
          <span>名寄せ先</span>
        </div>
      )}
      <div
        className={`px-6 py-4 flex flex-col gap-1 relative border cursor-pointer ${
          active
            ? 'bg-[#F2F9FF] border-c-primary border-2'
            : 'border-c-border bg-white hover:bg-[#FAFBFB]'
        }`}
        onClick={onClick}>
        <ProspectInfo prospectPool={prospectPool} />
      </div>
    </div>
  );
};

export default Prospect;
