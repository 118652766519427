import React from 'react';
import {useProspectScoreSettingQuery, ProspectTag} from 'api';

export default () => {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <div className="flex flex-col gap-2">
      {Object.entries(
        prospectScoreSetting?.prospectTagsGroupByPriority ?? {},
      )?.map(([priority, tags]: any) => {
        return (
          <div className="flex gap-2" key={priority}>
            <div className="font-bold text-sm">優先度{priority}</div>
            <div className="flex items-center gap-2 text-sm">
              {tags
                .map((tag: ProspectTag) => {
                  return tag.name;
                })
                ?.join(' | ')}
            </div>
          </div>
        );
      })}
    </div>
  );
};
