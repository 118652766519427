import {usePoolQuery, usePreleadQuery} from 'api';
import React from 'react';
import usePreleadContext from '../usePreleadContext';
import {Link} from 'react-router-dom';
import {ArrowDown} from 'components/Ui/Icon';

export default () => {
  const {preleadId} = usePreleadContext();
  const {data: {pool = {}} = {}} = usePoolQuery({});
  const {data: {prelead: {accountPool = {}} = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  if (!accountPool) return null;

  return (
    <div className="ml-[6.5rem]">
      <Link
        to={`/pools/${pool?.uuid}/accounts/${accountPool.uuid}`}
        target="_blank">
        企業管理ページ
        <span className="absolute top-[49px]">
          <ArrowDown className="-rotate-90 w-4 h-4" color="var(--link-color)" />
        </span>
      </Link>
    </div>
  );
};
