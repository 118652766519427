import React from 'react';
import {SalesforceUserSelector} from 'components/Prospect/SalesforceUserSelector';
import {
  SalesforceUser,
  useAccountPoolQuery,
  useUpdateSalesforceAccountOwnerMutation,
  useUpdateSalesforceLeadOwnerMutation,
} from 'api';
import {useParams} from 'react-router-dom';

interface Props {
  salesforceUser: SalesforceUser;
}

export default ({salesforceUser}: Props) => {
  const [erorrMessage, setErrorMessage] = React.useState('');
  const {accountId} = useParams<{
    accountId: string;
  }>();
  const {data: {accountPool = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const [updateAccountOwner] = useUpdateSalesforceAccountOwnerMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceAccountOwner.error);
    },
  });
  const [updateLeadOwner] = useUpdateSalesforceLeadOwnerMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceLeadOwner.error);
    },
  });

  const onUpdate = (salesforceUserIdString: string) => {
    if (accountPool.salesforceAccount) {
      updateAccountOwner({
        variables: {
          id: accountPool.salesforceAccount.id,
          salesforceUserIdString: salesforceUserIdString,
        },
      });
    } else if (accountPool.salesforceLead) {
      updateLeadOwner({
        variables: {
          id: accountPool.salesforceLead.id,
          salesforceUserIdString: salesforceUserIdString,
        },
      });
    }
  };
  return (
    <>
      <div className="p-4">
        <span className="font-bold text-c-light">Salesforce所有者</span>
        <SalesforceUserSelector
          salesforceUser={salesforceUser}
          onChange={(salesforceUserIdString) =>
            onUpdate(salesforceUserIdString)
          }
        />
      </div>
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};
