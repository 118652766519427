import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery} from 'api';
import {Button} from 'components/antd';
import LeadField from './LeadField';
import LeadSelectOption from './LeadSelectOption';
import Close from './Close';
import useClientUser from 'hooks/useClientUser';
import LeadCheckboxOption from './LeadCheckboxOption';
import {
  SearchCondition,
  SelectSearchCondition,
  CheckboxSearchCondition,
} from './Types';

type Props = {
  salesforceLeadSearchConditions: SearchCondition[];
  setSalesforceLeadSearchConditions: React.Dispatch<
    React.SetStateAction<SearchCondition[]>
  >;
};

export default ({
  salesforceLeadSearchConditions,
  setSalesforceLeadSearchConditions,
}: Props) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <>
      <div className="flex mt-2">
        <Label>条件項目</Label>
        <div className="flex flex-col gap-1">
          {salesforceLeadSearchConditions?.map(
            (condition: SearchCondition, index: number) => {
              return (
                <div className="flex" key={index}>
                  <LeadField condition={condition} />
                  {condition.field_type === 'select' && (
                    <LeadSelectOption
                      condition={condition as SelectSearchCondition}
                    />
                  )}
                  {condition.field_type === 'checkbox' && (
                    <LeadCheckboxOption
                      condition={condition as CheckboxSearchCondition}
                    />
                  )}
                  {isConditionUpdatable && isMember && (
                    <Close
                      setSalesforceLeadSearchConditions={
                        setSalesforceLeadSearchConditions
                      }
                      salesforceLeadSearchConditions={
                        salesforceLeadSearchConditions
                      }
                      index={index}
                    />
                  )}
                </div>
              );
            },
          )}
          <AddButton
            disabled={
              !searchCondition.salesforceLeadRecordTypeId ||
              !isConditionUpdatable ||
              !isMember
            }
            onClick={() =>
              setSalesforceLeadSearchConditions([
                ...salesforceLeadSearchConditions,
                {} as SelectSearchCondition,
              ])
            }>
            + 条件を追加
          </AddButton>
        </div>
      </div>
    </>
  );
};

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  padding-right: 8px;
  width: 110px;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
  width: 110px;
`;
