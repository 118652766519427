import React from 'react';
import { Menu } from '@headlessui/react';
import { useAccountPoolQuery } from 'api';
import { useParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import User from './User';

const SalesforceUser = () => {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { data: { accountPool = {} } = {} } = useAccountPoolQuery({
    variables: { uuid: accountId },
    skip: !accountId,
  });

  const salesforceUser =
    accountPool?.salesforceAccount?.salesforceOwner ||
    accountPool?.salesforceLead?.salesforceOwner;

  if (!salesforceUser) return null;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-c-light">Salesforce所有者</div>
          {salesforceUser && (
            <span className={`py-1 rounded-full leading-none tracking-tight`}>
              {[salesforceUser?.lastName, salesforceUser?.firstName]
                .filter(Boolean)
                .join(' ')}
            </span>
          )}
        </div>
        <Menu.Button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
          <PencilSquareIcon className="w-5 h-5 text-c-primary" />
        </Menu.Button>
      </div>

      <Menu.Items className="absolute origin-top-right rounded bg-white shadow z-10 w-[calc(100%-3rem)] right-12">
        <Menu.Item>
          <User salesforceUser={salesforceUser} />
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default SalesforceUser;
