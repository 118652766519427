import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Airplane} from 'components/Ui/Icon';
import {Activity, ExtMailFormSendActivity} from 'api';
import useProjectName from './useProjectName';

interface ExtMailFormSend extends Activity {
  resource: ExtMailFormSendActivity;
}

interface Props {
  activity: ExtMailFormSend;
}

const ExtMailFormSendActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <Container>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <Airplane />
        </BadgeWrapper>
        <Body>プラグインを利用してフォーム送信しました</Body>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
      </Summary>

      <BodyBox>
        <BodyTitle>本文</BodyTitle>
        <BodyBody>{activity.resource.body}</BodyBody>
      </BodyBox>
    </Container>
  );
};

const Container = styled.div`
  margin-left: -30px;
  position: relative;
  padding: 10px 20px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: #ffffff;
`;

const Project = styled.span`
  display: block;
  margin-bottom: 15px;
  font-size: 8px;
  color: var(--text-color-2);
`;

const Summary = styled.div`
  display: flex;
  width: 480px;
  height: 30px;
  span {
    line-height: 30px;
  }
`;

const BadgeWrapper = styled.div`
  margin: auto 16px auto -3px;
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
`;

const Body = styled.span`
  margin: auto 0;
  min-width: 0;
  max-width: 100%;
  flex: auto;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
  }
  > a {
    margin-left: 8px;
  }
`;

const TimeStamp = styled.span`
  margin: auto 0;
`;

const CampaignBox = styled.div`
  margin-top: 15px;
  display: flex;
`;

const CampaignTitle = styled.div`
  margin: auto 30px auto 0;
  width: 80px;
  font-weight: bold;
  font-size: 12px;

  color: var(--text-color-3);
`;

const CampaignBody = styled.div`
  margin: auto 0;
  color: var(--text-color-1);
`;

const BodyBox = styled.div`
  margin-top: 15px;
`;

const BodyTitle = styled.div`
  width: 80px;
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-3);
`;

const BodyBody = styled.div`
  margin-top: 13px;
  color: var(--text-color-1);
  overflow: scroll;
  max-height: 300px;
  white-space: pre-wrap;
`;

export default ExtMailFormSendActivityComponent;
