import React, {useState} from 'react';
import styled from 'styled-components';
import {PaperNote, Cross} from 'components/Ui/Icon';
import Modal from 'react-modal';
import ModalContent from '../specificCompanies/detail/company';
import {useQueryParam, StringParam} from 'use-query-params';

export default () => {
  const [isOpen, setModal] = useState(false);
  const [specificCompanyId] = useQueryParam('specificCompanyId', StringParam);

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
    },
  };

  return (
    <>
      <ButtonWrapper onClick={() => setModal(true)}>
        <Title>企業詳細</Title>
        <PaperNote />
      </ButtonWrapper>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setModal(false);
        }}
        style={customStyles}
        contentLabel="modal">
        <ModalHeader>
          <CrossIconWrapper onClick={() => setModal(false)}>
            <Cross />
          </CrossIconWrapper>
        </ModalHeader>
        <ModalContent companyId={specificCompanyId} height={'600px'} />
      </Modal>
    </>
  );
};

const ButtonWrapper = styled.div`
  height: 34px;
  border-right: 1px solid #e1e6eb;
  margin: auto 5px auto 0;
  padding-right: 10px;
  cursor: pointer;
  text-align: center;

  svg {
    margin-top: 2px;
  }
`;

const Title = styled.div`
  font-size: 10px;
  color: var(--text-color-2);
  position: relative;
  bottom: 2px;
`;

const ModalHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CrossIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;

  svg {
    margin: auto;
  }
`;
