import React from 'react';
import useSearchParams from '../../../../../useSearchParams';
import PlusIcon from './PlusIcon';
import CustomizeItemField from './CustomizeItemField';
import Close from './Close';
import CustomizeItemSelectOption from './CustomizeItemSelectOption';
import CustomizeItemCheckboxOption from './CustomizeItemCheckboxOption';

const FormFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="text-c-base font-bold">条件項目</div>
        <div className="flex flex-col gap-5">
          {query?.salesforceLeadSearchConditions.map(
            (customizeItem: any, index: number) => {
              return (
                <div key={index} className="flex gap-3">
                  <CustomizeItemField
                    leadFieldId={customizeItem['lead_field_id']}
                    index={index}
                  />
                  {customizeItem['field_type'] === 'select' && (
                    <CustomizeItemSelectOption
                      salesforceLeadFieldId={customizeItem['lead_field_id']}
                      customizeItemValue={
                        customizeItem['field_select_option_id']
                      }
                      index={index}
                    />
                  )}
                  {customizeItem['field_type'] === 'checkbox' && (
                    <CustomizeItemCheckboxOption
                      salesforceLeadFieldId={customizeItem['lead_field_id']}
                      customizeItemValue={customizeItem['value']}
                      index={index}
                    />
                  )}
                  <Close index={index} />
                </div>
              );
            },
          )}
        </div>
        <button
          className="h-7 w-28 text-center rounded bg-white border border-border flex justify-center items-center"
          disabled={!query.salesforceLeadRecordTypeId}
          onClick={() => {
            setQuery({
              ...query,
              salesforceLeadSearchConditions: [
                ...query.salesforceLeadSearchConditions,
                {},
              ],
            });
          }}>
          <PlusIcon /> <span className="text-foreground-light">条件を追加</span>
        </button>
      </div>
    </>
  );
};

export default FormFilter;
