import React from 'react';
import {useAccountScoreSettingQuery} from 'api';

export default () => {
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="flex flex-col gap-2 py-5">
      {Object.entries(
        accountScoreSetting?.subCategoriesGroupByPriority ?? {},
      )?.map(([priority, subCategories]: any) => {
        return (
          <div className="flex gap-2 items-center" key={priority}>
            <div className="font-bold text-sm w-12">優先度{priority}</div>
            <div className="flex items-center gap-2 text-sm w-full">
              {subCategories
                .map((subCategory: any) => {
                  return subCategory.display_name;
                })
                ?.join(' | ')}
            </div>
          </div>
        );
      })}
    </div>
  );
};
