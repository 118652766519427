import React, {useState, useEffect} from 'react';
import {Modal, Checkbox, Popover} from 'components/antd';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {Handle, DotMenuSide, Trash} from 'components/Ui/Icon';
import {
  useClientPreleadCustomizeItemsByProjectQuery,
  useUpdateClientPreleadCustomizeItemPositionMutation,
  useUpdateClientPreleadCustomizeItemIsDisplayMutation,
  useDestroyClientPreleadCustomizeItemMutation,
  ClientPreleadCustomizeItem,
} from 'api';
import {useParams} from 'react-router';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export default ({visible, setVisible}: Props) => {
  const {projectId} = useParams<{
    projectId: string;
  }>();

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const {data: {clientPreleadCustomizeItemsByProject = []} = {}} =
    useClientPreleadCustomizeItemsByProjectQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setClientPreleadCustomizeItems(clientPreleadCustomizeItemsByProject);
      },
    });

  useEffect(() => {
    setClientPreleadCustomizeItems(clientPreleadCustomizeItemsByProject);
  }, [clientPreleadCustomizeItemsByProject.length]);

  const [clientPreleadCustomizeItems, setClientPreleadCustomizeItems] =
    useState([]);

  const [destroyClientPreleadCustomizeItemMutation] =
    useDestroyClientPreleadCustomizeItemMutation();

  const [selectedItem, setSelectedItem] = useState('');

  const [updateClientPreleadCustomizeItemPosition] =
    useUpdateClientPreleadCustomizeItemPositionMutation();
  const [updateClientPreleadCustomizeItemIsDisplay] =
    useUpdateClientPreleadCustomizeItemIsDisplayMutation();
  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...clientPreleadCustomizeItems];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setClientPreleadCustomizeItems(items);
      updateClientPreleadCustomizeItemPosition({
        variables: {
          id: removed.id,
          position: destIndex,
        },
      });
    },
    [clientPreleadCustomizeItems],
  );

  const Content = (id: string) => {
    return (
      <PopoverContent>
        <PopoverItem
          onClick={() => {
            setIsPopoverVisible(false);
            destroyClientPreleadCustomizeItemMutation({
              variables: {id: id},
              refetchQueries: [
                'clientPreleadCustomizeItemsByProject',
                'project',
              ],
            });
          }}>
          <TrashWrapper>
            <Trash color="var(--text-color-1)" />
          </TrashWrapper>
          <div>削除</div>
        </PopoverItem>
      </PopoverContent>
    );
  };

  return (
    <Modal
      title={'項目の設定'}
      visible={visible}
      onCancel={() => setVisible(false)}
      okText={'保存'}
      cancelText={'キャンセル'}
      footer={null}
      width={500}>
      <HeaderContentWrapper>
        <Gap width={24} />
        <ItemName>
          <Bold>項目名</Bold>
        </ItemName>
        <Gap width={123} />
        <DataType>
          <Bold>データ形式</Bold>
        </DataType>
        <Gap width={22} />
        <Display>
          <Bold>表示</Bold>
        </Display>
        <Gap width={20} />
      </HeaderContentWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {clientPreleadCustomizeItems
                .filter(
                  (item: ClientPreleadCustomizeItem) =>
                    item.isDeleted === false,
                )
                .map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <DraggableItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={snapshot.isDragging ? 'dragging' : ''}>
                        <HandleWrapper {...provided.dragHandleProps}>
                          <Handle />
                        </HandleWrapper>
                        <ContentWrapper>
                          <ItemName>{item.name}</ItemName>
                          <Gap width={123} />
                          <DataType>{item.dataTypeText}</DataType>
                          <Gap width={22} />
                          <Display>
                            <Checkbox
                              defaultChecked={item.isDisplay}
                              onChange={(e) => {
                                updateClientPreleadCustomizeItemIsDisplay({
                                  variables: {
                                    id: item.id,
                                    isDisplay: e.target.checked,
                                  },
                                  refetchQueries: [
                                    'clientPreleadCustomizeItemsByProject',
                                  ],
                                });
                              }}
                            />
                          </Display>
                          <Popover
                            placement="bottomLeft"
                            content={Content(item.id)}
                            trigger="click"
                            visible={
                              isPopoverVisible && selectedItem === item.id
                            }
                            onVisibleChange={(e) => {
                              setIsPopoverVisible(e);
                              setSelectedItem(item.id);
                            }}>
                            <div style={{cursor: 'pointer', display: 'flex'}}>
                              <DotMenuSide
                                color={
                                  isPopoverVisible && selectedItem === item.id
                                    ? 'var(--primary)'
                                    : 'var(--text-color-2)'
                                }
                              />
                            </div>
                          </Popover>
                        </ContentWrapper>
                      </DraggableItem>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};

const ItemName = styled.div`
  width: 160px;
`;

const DataType = styled.div`
  width: 72px;
`;

const Display = styled.div`
  width: 33px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Gap = styled.div<{width: number}>`
  width: ${({width}) => `${width}px`};
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const DraggableItem = styled.div`
  min-height: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;
  margin-bottom: 10px;
  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px var(--primary);
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const PopoverContent = styled.div`
  .ant-popover-placement-bottomLeft {
    padding-top: 0;
  }
`;

const PopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 86px;
  height: 43px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f5f7;
  }
`;

const TrashWrapper = styled.div`
  padding-top: 5px;
`;
