import React from 'react';
import SelectedSubCategories from '../SelectedSubCategories';

export default () => {
  return (
    <div className="h-24">
      <div className="bg-[#F3F5F7] border-c-border w-20 text-center mb-2">
        選択中の業種
      </div>
      <SelectedSubCategories />
    </div>
  );
};
