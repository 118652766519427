import React from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<ProspectPool>;
}

const LeadSourceCell = ({row}: Props) => {
  const {leadSource} = row.original;

  return <Path>{leadSource?.name}</Path>;
};

const Path = styled.span`
  color: var(--text-color-2);
  font-size: 10px;
`;

export default LeadSourceCell;
