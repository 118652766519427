import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import useSearchParams from '../useSearchParams';

const sortCategories = [
  {text: '未選択', value: ''},
  {text: '最終更新日', value: 'activity_updated_at'},
  {text: 'フォーム送信クリック回数', value: 'click_count'},
  {text: 'フォーム送信数', value: 'mail_count'},
  {text: 'フォーム送信期間', value: 'mail_updated_at'},
  {text: 'TEL件数', value: 'tel_count'},
  {text: 'TEL更新日', value: 'tel_updated_at'},
  {text: '資本金', value: 'capital_fund'},
  {text: '事業所数', value: 'offices_number'},
];

const sortOrders = [
  {text: '少ない順（昇順）', value: 'asc'},
  {text: '多い順（降順）', value: 'desc'},
];

export default () => {
  const {query, setQuery} = useSearchParams();

  const [sortCategory, setSortCategory] = React.useState(
    query.sortCategory || '',
  );

  const setCategory = (value: string) => {
    if (value === '') {
      setQuery({
        sortCategory: '',
        sortOrder: '',
        page: 1,
      });
    } else if (query.sortOrder) {
      setQuery({
        sortCategory: value,
        sortOrder: query.sortOrder,
        page: 1,
      });
    }
    setSortCategory(value);
  };

  const setSortOrder = (value: string) => {
    setQuery({
      sortCategory: sortCategory,
      sortOrder: value,
      page: 1,
    });
  };

  return (
    <Container>
      <h3>並び替え</h3>

      <div>
        <Select
          value={sortCategory}
          onChange={setCategory}
          placeholder="選択してください"
          dropdownMatchSelectWidth>
          {sortCategories.map((category) => (
            <Select.Option
              value={category.value}
              key={category.value}
              label={category.text}>
              {category.text}
            </Select.Option>
          ))}
        </Select>

        <Select
          value={query.sortOrder}
          disabled={!sortCategory}
          onChange={setSortOrder}
          placeholder="選択してください"
          dropdownMatchSelectWidth>
          {sortOrders.map((order) => (
            <Select.Option
              value={order.value}
              key={order.value}
              label={order.text}>
              {order.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: white;
  width: 395px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 12p;

  h3 {
    font-weight: bold;
    color: var(--text-color-3);
    margin-bottom: 0.5rem;
  }

  > *:nth-child(2) {
    flex: 1;
    display: flex;

    > div {
      flex: 1;

      &:first-child {
        margin-right: 12px;
      }
    }
  }
`;
