import React from 'react';
import {useApproachCountsQuery} from 'api';
import useSearchParams from './useSearchParams';

const Item = ({
  status,
  label,
  count,
}: {
  status: string;
  label: string;
  count: number;
}) => {
  const {query, setQuery} = useSearchParams();

  return (
    <div
      className={`cursor-pointer table-cell w-24 h-12 border-l border-c-border first-of-type:border-none ${
        query.status === status ? 'text-foreground' : 'text-foreground-lighter'
      }`}
      onClick={() => {
        setQuery({status: status, page: 1});
      }}>
      <div className="flex flex-col items-center justify-center">
        <em className="text-xl font-bold not-italic">{count || 0}</em>
        <span className="text-sm font-medium">{label}</span>
      </div>
    </div>
  );
};

export default () => {
  const {searchParams} = useSearchParams();
  const {data: {approachCounts = {}} = {}} = useApproachCountsQuery({
    variables: {
      search: {...searchParams, status: null},
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="w-full border-y border-c-border py-2">
      <Item status="open" label="開始" count={approachCounts.openCount || 0} />
      <Item
        status="waiting"
        label="開始待ち"
        count={approachCounts.waitingCount || 0}
      />
      <Item
        status="expired"
        label="期限切れ"
        count={approachCounts.expiredCount || 0}
      />
      <Item
        status="completed"
        label="完了"
        count={approachCounts.completedCount || 0}
      />
      <Item
        status="canceled"
        label="キャンセル"
        count={approachCounts.canceledCount || 0}
      />
    </div>
  );
};
