import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 4.16669H3.33333C2.8731 4.16669 2.5 4.53978 2.5 5.00002V15C2.5 15.4603 2.8731 15.8334 3.33333 15.8334H16.6667C17.1269 15.8334 17.5 15.4603 17.5 15V5.00002C17.5 4.53978 17.1269 4.16669 16.6667 4.16669Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 4.58331L10 10.8333L3.33337 4.58331"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
