import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {useLeadSumReportQuery, useEmployeeReportsQuery} from 'api';
import React from 'react';
import employeeRangeOptions from 'helpers/employeeRangeOptions';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = ['従業員数', ''];
  const {data: {employeeReports = []} = {}} = useEmployeeReportsQuery({});
  const necessaryEmployeeReports = employeeReports.slice(1);
  const {data: {leadSumReport = null} = {}} = useLeadSumReportQuery({});
  const sumReportData = React.useMemo(() => {
    if (!leadSumReport) {
      return;
    }
    return {
      label: leadSumReport.name,
      values: leadSumReport.values,
      callProps: {
        style: {
          borderBottom: 'double 3px var(--text-color-2)',
        },
      },
    };
  }, [leadSumReport]);

  const reportData = React.useMemo(() => {
    return necessaryEmployeeReports.map((employeeReport) => {
      return {
        label: employeeRangeOptions.find(
          (employeeRangeOption) =>
            employeeRangeOption.value === employeeReport.name,
        )?.label,
        values: employeeReport.values,
      };
    });
  }, [necessaryEmployeeReports]);

  const data: ReportTableData[] = [sumReportData, ...reportData].filter(
    Boolean,
  );

  return {
    data,
    headers,
  };
};

export default useTableData;
