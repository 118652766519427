import React from 'react';
import styled from 'styled-components';
import {useProjectQuery, useProjectTelStatusCallReportQuery} from 'api';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import {ArrowRight} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import {telStatusCategoryHash} from 'helpers/telStatusCategoryHash';

export default () => {
  const [displayCategories, setDisplayCategories] = React.useState([]);
  const {projectId} = useParams<{projectId: string}>();
  const {data: {projectTelStatusCallReport = {}} = {}} =
    useProjectTelStatusCallReportQuery({
      variables: {
        projectUuid: projectId,
      },
    });
  const report: any = projectTelStatusCallReport;

  const {
    data: {
      project: {
        callNgReasons = [],
        receptionNgReasons = [],
        contactNgReasons = [],
        telStatuses = [],
      } = {},
    } = {},
  } = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const callNgName = React.useCallback(
    (id: string) =>
      callNgReasons.find((callNgReason) => callNgReason.id == id)?.reason,
    [callNgReasons],
  );

  const receptionNgName = React.useCallback(
    (id: string) =>
      receptionNgReasons.find((receptionNgReason) => receptionNgReason.id == id)
        ?.reason,
    [receptionNgReasons],
  );

  const contactNgName = React.useCallback(
    (id: string) =>
      contactNgReasons.find((contactNgReason) => contactNgReason.id == id)
        ?.reason,
    [contactNgReasons],
  );

  const toggle = React.useCallback(
    (category: string) => {
      if (displayCategories.includes(category)) {
        setDisplayCategories(
          displayCategories.filter(
            (displayCategory) => displayCategory != category,
          ),
        );
      } else {
        setDisplayCategories([...displayCategories, category]);
      }
    },
    [displayCategories],
  );

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );

    return value;
  };

  const telStatusCallNgId = telStatuses.find(
    (telStatus) => telStatus.category === 'call_ng',
  )?.id;

  const telStatusReceptionNgId = telStatuses.find(
    (telStatus) => telStatus.category === 'reception_ng',
  )?.id;

  const telStatusContactNgId = telStatuses.find(
    (telStatus) => telStatus.category === 'contact_ng',
  )?.id;

  return (
    <Container>
      <h2>NG理由別</h2>

      <Table>
        <div className="column far-left">
          {/* callNG */}
          <div className="category openable" onClick={() => toggle('call_ng')}>
            <Open category={'call_ng'} displayCategories={displayCategories}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={telCategoryColor('call_ng')} />
            <span>{telStatusCategoryHash['call_ng']}</span>
          </div>
          {displayCategories.includes('call_ng') &&
            Object.keys(report['callNg'])?.map((reasonId: any) => (
              <div key={reasonId}>
                <span>{callNgName(reasonId)}</span>
              </div>
            ))}

          {/* receptionNg */}
          <div
            className="category openable"
            onClick={() => toggle('reception_ng')}>
            <Open
              category={'reception_ng'}
              displayCategories={displayCategories}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={telCategoryColor('reception_ng')} />
            <span>{telStatusCategoryHash['reception_ng']}</span>
          </div>
          {displayCategories.includes('reception_ng') &&
            Object.keys(report['receptionNg'])?.map((reasonId: any) => (
              <div key={reasonId}>
                <span>{receptionNgName(reasonId)}</span>
              </div>
            ))}

          {/* contactNg */}
          <div
            className="category openable"
            onClick={() => toggle('contact_ng')}>
            <Open category={'contact_ng'} displayCategories={displayCategories}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={telCategoryColor('contact_ng')} />
            <span>{telStatusCategoryHash['contact_ng']}</span>
          </div>
          {displayCategories.includes('contact_ng') &&
            Object.keys(report['contactNg'])?.map((reasonId: any) => (
              <div key={reasonId}>
                <span>{contactNgName(reasonId)}</span>
              </div>
            ))}
        </div>

        <div className="column">
          {/* callNg */}
          <div className="category">
            <span>
              {report['callNg'] && (
                <a
                  href={`/list/projects/${projectId}/preleads?page=1&telStatusIds=${telStatusCallNgId}`}
                  target="_blank">
                  {totalCount(report['callNg'])}
                </a>
              )}
            </span>
          </div>
          {displayCategories.includes('call_ng') &&
            Object.keys(report['callNg'])?.map((reasonId: any) => (
              <div key={reasonId}>
                <span>{report['callNg'] && report['callNg'][reasonId]}</span>
              </div>
            ))}

          {/* receptionNg */}
          <div className="category">
            <span>
              {report['receptionNg'] && (
                <a
                  href={`/list/projects/${projectId}/preleads?page=1&telStatusIds=${telStatusReceptionNgId}`}
                  target="_blank">
                  {totalCount(report['receptionNg'])}
                </a>
              )}
            </span>
          </div>
          {displayCategories.includes('reception_ng') &&
            Object.keys(report['receptionNg'])?.map((reasonId: any) => (
              <div key={reasonId}>
                <span>
                  {report['receptionNg'] && report['receptionNg'][reasonId]}
                </span>
              </div>
            ))}

          {/* contactNg */}
          <div className="category">
            <span>
              {report['contactNg'] && (
                <a
                  href={`/list/projects/${projectId}/preleads?page=1&telStatusIds=${telStatusContactNgId}`}
                  target="_blank">
                  {totalCount(report['contactNg'])}
                </a>
              )}
            </span>
          </div>
          {displayCategories.includes('contact_ng') &&
            Object.keys(report['contactNg'])?.map((reasonId: any) => (
              <div key={reasonId}>
                <span>
                  {report['contactNg'] && report['contactNg'][reasonId]}
                </span>
              </div>
            ))}
        </div>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;

  > h2 {
    margin-top: 46px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  width: 407px;
  background: #fff;

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
      width: 100%;
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      align-items: center;

      &:first-child {
      }

      &:nth-child(2) {
      }

      > span {
        margin: 0 9px 0 auto;
        color: var(--text-color-2);
      }

      &.category {
        > span {
          color: var(--text-color-1);
          font-weight: bold;
        }
      }
    }

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 272px;
        display: flex;
        align-items: center;
        padding: 0 40px;

        background: #fff;

        &.category {
          padding: 0 13px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        &.openable {
          cursor: pointer;
        }

        > span {
          margin: 0;
          font-size: 13px;
        }
      }
    }
  }
`;

const Open = styled.div<{category: string; displayCategories: any}>`
  display: flex;
  width: 13px;
  height: 13px;
  > svg {
    ${({category, displayCategories}) =>
      displayCategories.includes(category) && 'transform: rotate(90deg);'}

    width: 13px;
    height: 13px;
    fill: var(--text-color-3);
    path {
      width: 13px;
      height: 13px;
    }
  }
`;
