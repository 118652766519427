import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {useCurrentUserQuery} from 'api';
import {Google} from 'components/Ui/Icon';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  return (
    <Body>
      <Menu />
      <ContentWrapper>
        <BodyHeader>
          <h2>メール設定</h2>
          <p>
            Eメールを連携すると、メールプロバイダ上で送受信したメールがLEADPAD上にも連携され、メールアドレスが一致するリードのタイムライン上に記録されます。
            <br />
            またLEADPAD上から送信したメールはメールプロバイダの送信済みフォルダにも残ります。
            <br />
            なお、タイムライン上に記録されたメールの履歴は、全てのユーザーが閲覧することができます。
          </p>
        </BodyHeader>
        <Content>
          <TitleSection>
            <TitleWrapper>
              <Title>接続するEメールプロバイダを設定</Title>
              <TitleNote>
                4つのプロバイダ種類のうち、どれか1つしか連携できません。
                LEADPADにユーザー登録していないアドレスも連携可能です。
              </TitleNote>
            </TitleWrapper>
          </TitleSection>
          <Integrations>
            {currentUser?.clientUser?.googleSetting?.isActive ? (
              <Integration active>
                <span>
                  <Google />
                </span>
                <div>
                  &#9679; 連携中
                  <h4>
                    {currentUser?.clientUser?.googleSetting?.lastName}{' '}
                    {currentUser?.clientUser?.googleSetting?.firstName}
                  </h4>
                  <h5>{currentUser?.clientUser?.googleSetting?.email}</h5>
                </div>
              </Integration>
            ) : (
              <Integration>
                <a href="/integrations/google/redirect">
                  <Google />
                </a>
                &#9679; 未連携
              </Integration>
            )}
          </Integrations>
        </Content>
      </ContentWrapper>
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  background: #fff;
  display: flex;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 45px;
`;

const Content = styled.div`
  background: #fff;
  min-height: 480px;
`;

const TitleSection = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 18px;
`;

const TitleNote = styled.div`
  font-size: 12px;
`;

const BodyHeader = styled.div`
  margin-bottom: 10px;

  > p {
    font-size: 12px;
  }

  > h2 {
    margin-top: 1rem;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
  }
`;

const Integrations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;

const Integration = styled.div<{active?: boolean}>`
  display: flex;
  gap: 1rem;
  color: ${({active}) => (active ? 'var(--primary)' : 'var(--text-color-2)')};

  > a,
  > span {
    border: solid 1px #e1e6eb;
    border-radius: 4px;

    svg {
      width: 64px;
      height: 56px;
    }
  }

  > a:hover {
    border: solid 1px var(--primary);
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
`;
