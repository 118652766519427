import React from 'react';
import styled from 'styled-components';
import {ProspectPool, useUpdateProspectMutation} from 'api';
import {Input} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const [updateProspect] = useUpdateProspectMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      lastName: prospectPool?.prospect.lastName,
      firstName: prospectPool?.prospect.firstName,
      section: prospectPool?.prospect.section,
      sectionPosition: prospectPool?.prospect.sectionPosition,
      telephoneNumber: prospectPool?.prospect.telephoneNumber,
      email: prospectPool?.prospect.email,
    },
    validationSchema: Yup.object().shape({
      lastName: Yup.string().required(),
    }),
    onSubmit: (values) => {
      updateProspect({
        variables: {
          prospectUuid: prospectPool?.prospect.uuid,
          attributes: values,
        },
      });
    },
  });

  return (
    <Container>
      <h3>リード情報</h3>
      <h4>姓</h4>
      <Input
        value={formik.values.lastName}
        onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
        onBlur={(e) => {
          if (e.target.value) {
            formik.handleSubmit();
          } else {
            alert('姓は必須です。');
          }
        }}
        onPressEnter={(e: any) => {
          if (e.target.value) {
            formik.handleSubmit();
          } else {
            alert('姓は必須です。');
          }
        }}
      />
      <h4>名</h4>
      <Input
        value={formik.values.firstName}
        onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
        onBlur={() => formik.handleSubmit()}
        onPressEnter={() => formik.handleSubmit()}
      />
      <h4>会社名</h4>
      <div>{prospectPool?.prospect.account.name}</div>
      <h4>部署</h4>
      <Input
        value={formik.values.section}
        onChange={(e) => formik.setFieldValue('section', e.target.value)}
        onBlur={() => formik.handleSubmit()}
        onPressEnter={() => formik.handleSubmit()}
      />
      <h4>役職</h4>
      <Input
        value={formik.values.sectionPosition}
        onChange={(e) =>
          formik.setFieldValue('sectionPosition', e.target.value)
        }
        onBlur={() => formik.handleSubmit()}
        onPressEnter={() => formik.handleSubmit()}
      />
      <h4>電話</h4>
      <Input
        value={formik.values.telephoneNumber}
        onChange={(e) =>
          formik.setFieldValue('telephoneNumber', e.target.value)
        }
        onBlur={() => formik.handleSubmit()}
        onPressEnter={() => formik.handleSubmit()}
      />
      <h4>Email</h4>
      <Input
        value={formik.values.email}
        onChange={(e) => formik.setFieldValue('email', e.target.value)}
        onBlur={() => formik.handleSubmit()}
        onPressEnter={() => formik.handleSubmit()}
      />
      <h4>リードソース</h4>
      {prospectPool?.leadSource && (
        <LeadSource>{prospectPool.leadSource.name}</LeadSource>
      )}
    </Container>
  );
};

const Container = styled.div`
  > h3 {
    font-size: 14px;
    color: var(--text-color-2);
    font-weight: bold;
    margin-top: 20px;
  }
  > h4 {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-3);
    margin-bottom: 10px;
  }
  h4 + div {
    font-size: 14px;
    color: var(--text-color-0);
    margin-bottom: 10px;
  }

  .ant-input {
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    left: -12px;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid var(--text-color-3);
    }
  }
`;

const LeadSource = styled.span`
  padding: 4px 10px;
  background: #f3f5f7;
  border-radius: 4px;
`;
