import React from 'react';

const Icon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#E8F1F9" />
    <path
      d="M17.917 25.25V14.75L26.0837 20L17.917 25.25Z"
      stroke="var(--primary)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
