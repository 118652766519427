import React from 'react';
import {
  ProspectTelStatus,
  ProspectPoolUserDailyReportTelStatusCount,
  useCurrentClientQuery,
} from 'api';
import {createColumnHelper} from '@tanstack/react-table';

const ProspectTelStatusColumns = () => {
  const columnHelper = createColumnHelper<any>();
  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery({});

  const columns = prospectTelStatuses.map(
    (prospectTelStatus: ProspectTelStatus) => {
      return columnHelper.accessor(prospectTelStatus.id, {
        header: () => (
          <div className="h-20 w-20 flex justify-center items-end bg-[#FAFBFB] border-y border-gray-300 p-0">
            <div className="h-10 font-normal text-xs flex justify-center items-center text-foreground-light border-t border-l w-full border-gray-300">
              {prospectTelStatus.name}
            </div>
          </div>
        ),
        enableHiding: true,
        cell: ({row}) => {
          return (
            <div>
              {row.original.telStatusCounts.find(
                (telStatusCount: ProspectPoolUserDailyReportTelStatusCount) =>
                  telStatusCount.clientProspectTelStatusId ===
                  `${prospectTelStatus.id}`,
              )?.count || 0}
            </div>
          );
        },
      });
    },
  );
  return [
    columnHelper.accessor('notSelected', {
      header: () => (
        <div className="h-20 w-20 flex justify-center items-end bg-[#FAFBFB] border-y border-gray-300 p-0">
          <div className="h-10 font-normal text-xs flex justify-center items-center text-foreground-light border-t border-l w-full border-gray-300">
            未選択
          </div>
        </div>
      ),

      enableHiding: true,
      cell: ({row}) => {
        return (
          <div>
            {row.original.telStatusCounts.find(
              (telStatusCount: ProspectPoolUserDailyReportTelStatusCount) =>
                telStatusCount.clientProspectTelStatusId === null,
            )?.count || 0}
          </div>
        );
      },
    }),
    ...columns,
  ];
};

export default ProspectTelStatusColumns;
