import React from 'react';
import {
  useAccountScoreSettingQuery,
  useCreateClientAccountScoreSettingCapitalFundRangeMutation,
  useDestroyClientAccountScoreSettingCapitalFundRangeMutation,
} from 'api';
import Slider from './Slider';

const Setting = () => {
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const priorities = [1, 2, 3];
  const [create] = useCreateClientAccountScoreSettingCapitalFundRangeMutation(
    {},
  );
  const [destroy] = useDestroyClientAccountScoreSettingCapitalFundRangeMutation(
    {},
  );

  const options = [
    {label: '5,000万円未満', value: 'under_fifty_million'},
    {
      label: '5,000万円~1億円未満',
      value: 'fifty_million_to_one_hundred_million',
    },
    {
      label: '1億円~5億円未満',
      value: 'one_hundred_million_to_five_hundred_million',
    },
    {label: '5億円~10億円未満', value: 'five_hundred_million_to_one_billion'},
    {label: '10億円~50億円未満', value: 'one_billion_to_fifty_billion'},
    {label: '50億円以上', value: 'over_fifty_billion'},
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 items-start">
        <div className="flex w-full items-center justify-between">
          <div>
            <h2 className="text-base m-0 font-bold">資本金</h2>
            <p className="m-0 text-sm">
              選択した資本金の重要度を高めてスコアを算出します。
            </p>
          </div>
          <Slider />
        </div>
        <div className="fix flex-row w-full grid grid-cols-5">
          {priorities.map((priority) => {
            const selected =
              accountScoreSetting?.capitalFundRangesGroupByPriority?.[
                priority
              ]?.map((capitalFundRange: any) => capitalFundRange.enum_value) ??
              [];
            return (
              <div className="flex flex-col gap-2" key={priority}>
                <h1 className="text-sm font-bold">優先度{priority}</h1>
                {options.map((option, index) => {
                  return (
                    <label className="flex items-center text-base" key={index}>
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
                        checked={selected.includes(option.value)}
                        disabled={
                          accountScoreSetting?.capitalFundRanges?.some(
                            (range) => range.enumValue === option.value,
                          ) && !selected.includes(option.value)
                        }
                        onChange={(e) => {
                          const variables = {
                            capitalFundRange: option.value,
                            priority,
                          };
                          if (e.target.checked) {
                            create({
                              variables,
                            });
                          } else {
                            destroy({
                              variables,
                            });
                          }
                        }}
                      />
                      <span className="ml-2">{option.label}</span>
                    </label>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Setting;
