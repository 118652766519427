import React from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  useCurrentClientQuery,
  useUpdatePreleadStatusPositionMutation,
} from 'api';
import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'components/antd';
import StatusEclipse from 'components/Ui/StatusEclipse';
import preleadColor from 'helpers/preleadColor';
import {Handle} from 'components/Ui/Icon';
import New from './New';
import Update from './Update';

export default () => {
  const [preleadStatues, setPreleadStatues] = React.useState([]);
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [updateModalItem, setUpdateModalItem] = React.useState(null);

  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  const [updatePreleadStatusPosition] =
    useUpdatePreleadStatusPositionMutation();

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...preleadStatues];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setPreleadStatues(items);
      updatePreleadStatusPosition({
        variables: {id: removed.id, position: destIndex},
      });
    },
    [preleadStatues],
  );

  React.useEffect(() => {
    setPreleadStatues(currentClient?.preleadStatuses || []);
  }, [currentClient]);

  return (
    <Container>
      <PageHeader>
        <div>
          <h3>プレリードのステータスを編集、新規追加することができます</h3>
        </div>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setNewModalVisible(true)}>
          新規作成
        </Button>
      </PageHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {preleadStatues.map((preleadStatus, index) => (
                <Draggable
                  key={preleadStatus.id}
                  draggableId={preleadStatus.id}
                  index={index}>
                  {(provided, snapshot) => (
                    <DraggableItem
                      ref={provided.innerRef}
                      onClick={() => setUpdateModalItem(preleadStatus)}
                      {...provided.draggableProps}
                      className={snapshot.isDragging ? 'dragging' : ''}>
                      <HandleWrapper {...provided.dragHandleProps}>
                        <Handle />
                      </HandleWrapper>
                      <PreleadStatus>
                        <StatusEclipse
                          color={preleadColor(preleadStatus.category)}
                        />
                        <span>{preleadStatus.name}</span>
                      </PreleadStatus>
                      <Description>{preleadStatus.description}</Description>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <New
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
      <Update
        preleadStatus={updateModalItem}
        onClose={() => setUpdateModalItem(null)}
      />
    </Container>
  );
};

const Container = styled.div``;

const PageHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  > div {
    flex: 1;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;

const DraggableItem = styled.div`
  min-height: 48px;
  box-shadow: 0 0 1px #444b52;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;

  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px var(--primary);
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const PreleadStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  span {
    margin-left: 8px;
    width: 275px;
  }
`;

const Description = styled.div`
  color: var(--text-color-2);
  padding: 15px 15px 15px 0;
`;
