import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useProjectSummaryQuery} from 'api';
import {OpenMail, SendMail, PersonPhoto, Speaker} from 'components/Ui/Icon';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectSummaryQuery({
    variables: {uuid: projectId},
  });

  return (
    <Container>
      <Title>サマリ（営業タイミング通知）</Title>

      <Body>
        <Item>
          <OpenMail />
          <Name>[フォーム送信クリック] 一週間以内</Name>
          <Count>{project?.numberOfClicksInAWeek?.toLocaleString()}</Count>
        </Item>
        <Item>
          <SendMail />
          <Name>[フォーム送信] 一週間以内</Name>
          <Count>{project?.numberOfMailsInAWeek?.toLocaleString()}</Count>
        </Item>
        <Item>
          <Speaker />
          <Name>
            [プレスリリース公開] 一週間以内・<PreviousDay>前日</PreviousDay>
          </Name>
          <Count>
            {project?.numberOfPressReleasesInAWeek?.toLocaleString()}
          </Count>
          <PreviousDayCount>
            {project?.numberOfPressReleasesInThePreviousDay?.toLocaleString()}
          </PreviousDayCount>
        </Item>
        <Item>
          <PersonPhoto />
          <Name>[求人情報] 一ヶ月以内</Name>
          <Count>
            {project?.numberOfJobListingsInAMonth?.toLocaleString()}
          </Count>
        </Item>
      </Body>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-3);
`;

const Item = styled.div`
  margin-top: 15px;
  display: flex;

  svg {
    margin-right: 23px;
    position: relative;
    top: 3px;
  }
`;

const Body = styled.div`
  width: 378px;
`;

const Name = styled.span`
  font-size: 14px;
`;

const PreviousDay = styled.span`
  color: #eb5757;
  font-weight: bold;
`;

const Count = styled.span`
  margin-left: auto;
  font-size: 14px;
`;

const PreviousDayCount = styled.span`
  display: inline-block;
  margin-left: 4px;
  padding: 0 5px 0 5px;
  background: #fceded;
  border-radius: 100px;
  text-align: center;
  position: relative;
  top: 4px;
  left: 4px;
  font-size: 9px;
  font-weight: bold;
  color: #eb5757;
  height: 14px;
`;
