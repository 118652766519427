import React from 'react';
import styled from 'styled-components';
import useSearchParams from '../useSearchParams';
import Filter from './Filter';
import {useRecoilState} from 'recoil';
import filterOptionsAtom from './Filter/filterOptionsAtom';
import {useCurrentClientQuery} from 'api';

export default () => {
  const {setQuery, filterParams} = useSearchParams();
  const [filters, setFilters] = React.useState([null]);
  const [filterOptions] = useRecoilState(filterOptionsAtom);

  const {data: {currentClient: {currentTrackingContract} = {}} = {}} =
    useCurrentClientQuery();

  React.useEffect(() => {
    const filterOptionValues = filterOptions.map((option) => option.value);
    let currentFilters = Object.entries(filterParams)
      .filter((entry) =>
        Array.isArray(entry[1])
          ? entry[1].length
          : entry[1] && filterOptionValues.includes(entry[0]),
      )
      .map((entry) => entry[0]);

    if (filterParams.taskDueDateTo || filterParams.taskDueDateFrom) {
      currentFilters = [...currentFilters, 'taskDueDate'];
    }

    if (filterParams.telFrom || filterParams.telTo) {
      currentFilters = [...currentFilters, 'telTerm'];
    }

    if (filterParams.mailTermFrom || filterParams.mailTermTo) {
      currentFilters = [...currentFilters, 'mailTerm'];
    }

    if (filterParams.clickTermFrom || filterParams.clickTermTo) {
      currentFilters = [...currentFilters, 'clickTerm'];
    }

    if (
      filterParams.establishedYearMonthFrom ||
      filterParams.establishedYearMonthTo
    ) {
      currentFilters = [...currentFilters, 'establishedYearMonth'];
    }

    if (filterParams.remindFrom || filterParams.remindTo) {
      currentFilters = [...currentFilters, 'remind'];
    }

    if (filterParams.trackedAtFrom || filterParams.trackedAtTo) {
      currentFilters = [...currentFilters, 'trackedAt'];
    }

    if (
      filterParams.mailCampaignOpenedAtFrom ||
      filterParams.mailCampaignOpenedAtTo
    ) {
      currentFilters = [...currentFilters, 'mailCampaignOpenedAt'];
    }

    if (
      filterParams.mailCampaignClickedAtFrom ||
      filterParams.mailCampaignClickedAtTo
    ) {
      currentFilters = [...currentFilters, 'mailCampaignClickedAt'];
    }

    if (currentFilters.length > 0) {
      setFilters(currentFilters);
    }
  }, []);

  const availableFilterOptions = filterOptions.filter(
    (option) =>
      option.value !== 'trackedAt' || !!currentTrackingContract?.isActive,
  );

  return (
    <PopoverContent>
      <h3>項目でフィルタ</h3>
      {filters.map((filter, index) => (
        <Filter
          key={index}
          filter={filter}
          filters={filters}
          index={index}
          availableOptions={availableFilterOptions.filter(
            (option) => filter || !filters.includes(option.value),
          )}
          onChange={(value: string) => {
            const newFilters = [...filters];

            if (filter === 'telTerm') {
              setQuery({telFrom: null, telTo: null});
            } else if (filter === 'taskDueDate') {
              setQuery({taskDueDateFrom: null, taskDueDateTo: null});
            } else if (filter === 'mailTerm') {
              setQuery({mailTermFrom: null, mailTermTo: null});
            } else if (filter === 'clickTerm') {
              setQuery({clickTermFrom: null, clickTermTo: null});
            } else if (filter === 'establishedYearMonth') {
              setQuery({
                establishedYearMonthFrom: null,
                establishedYearMonthTo: null,
              });
            } else if (filter === 'remind') {
              setQuery({
                remindFrom: null,
                remindTo: null,
              });
            } else if (filter === 'trackedAt') {
              setQuery({
                trackedAtFrom: null,
                trackedAtTo: null,
              });
            } else if (filter === 'mailCampaignOpenedAt') {
              setQuery({
                mailCampaignOpenedAtFrom: null,
                mailCampaignOpenedAtTo: null,
              });
            } else if (filter === 'mailCampaignClickedAt') {
              setQuery({
                mailCampaignClickedAtFrom: null,
                mailCampaignClickedAtTo: null,
              });
            } else if (filter) {
              setQuery({[filters[index]]: null});
            }

            if (value) {
              newFilters[index] = value;
            } else {
              newFilters.splice(index, 1);
            }
            setFilters(newFilters);
          }}
        />
      ))}
      <div>
        <a
          onClick={() => {
            setFilters([...filters, null]);
            setQuery({mode: 'and'});
          }}>
          フィルタ項目を追加
        </a>
      </div>
    </PopoverContent>
  );
};

const PopoverContent = styled.div`
  background: white;
  width: 700px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 12px;

  h3 {
    font-weight: bold;
    color: var(--text-color-3);
    margin-bottom: 0.5rem;
  }
`;
