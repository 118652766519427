import styled from 'styled-components';
import {Breadcrumb} from 'components/antd';

export default styled(Breadcrumb)`
  padding: 24px 0 24px 0px;
  margin-right: 25px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: var(--text-color-3);
  span {
    .current {
      color: var(--text-color-2);
    }
  }

  a {
    color: var(--text-color-3);
    font-weight: 500px;
    font-size: 12px;
    &:hover {
      color: var(--primary);
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 25px;
    color: var(--text-color-3);
  }
`;
