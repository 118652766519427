import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import {useUserReportsQuery, useLeadSumReportQuery} from 'api';
import React from 'react';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = ['担当者', ''];
  const {data: {userReports = []} = {}} = useUserReportsQuery({});
  const {data: {leadSumReport = null} = {}} = useLeadSumReportQuery({});
  const sumReportData = React.useMemo(() => {
    if (!leadSumReport) {
      return;
    }
    return {
      label: leadSumReport.name,
      values: leadSumReport.values,
      callProps: {
        style: {
          borderBottom: 'double 3px var(--text-color-2)',
        },
      },
    };
  }, [leadSumReport]);

  const reportData = React.useMemo(() => {
    return userReports.map((report) => {
      return {
        label: report.name,
        values: report.values,
      };
    });
  }, [userReports]);

  const data: ReportTableData[] = [sumReportData, ...reportData].filter(
    Boolean,
  );

  return {
    data,
    headers,
  };
};

export default useTableData;
