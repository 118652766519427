import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';
import {Checkbox} from 'components/antd';
import SalesforceDeal from './SalesforceDeal';
import SalesforceLead from './SalesforceLead';
import SalesforceContact from './SalesforceContact';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{workflowId: string}>();

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  return (
    <Container>
      <Title>対象を選択</Title>
      <Item>
        <ItemTitle>
          <Checkbox
            checked={searchCondition.hasSalesforceDeal}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(e: any) => {
              updateWorkflowSearchCondition({
                variables: {
                  uuid: workflowId,
                  attributes: {
                    hasSalesforceDeal: e.target.checked,
                    hasSalesforceLead: false,
                    hasSalesforceContact: false,
                    salesforceLeadRecordTypeId: null,
                  },
                },
              });
            }}
          />
          <Label>商談</Label>
        </ItemTitle>
        <SalesforceDeal />
      </Item>
      <Item>
        <ItemTitle>
          <Checkbox
            checked={searchCondition.hasSalesforceContact}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(e: any) => {
              updateWorkflowSearchCondition({
                variables: {
                  uuid: workflowId,
                  attributes: {
                    hasSalesforceContact: e.target.checked,
                    hasSalesforceLead: false,
                    hasSalesforceDeal: false,
                    salesforceLeadRecordTypeId: null,
                  },
                },
              });
            }}
          />
          <Label>取引先責任者</Label>
        </ItemTitle>
        <SalesforceContact />
      </Item>
      <Item>
        <ItemTitle>
          <Checkbox
            checked={searchCondition.hasSalesforceLead}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(e: any) => {
              updateWorkflowSearchCondition({
                variables: {
                  uuid: workflowId,
                  attributes: {
                    hasSalesforceLead: e.target.checked,
                    hasSalesforceContact: false,
                    hasSalesforceDeal: false,
                    salesforceLeadRecordTypeId: null,
                  },
                },
              });
            }}
          />
          <Label>リード</Label>
        </ItemTitle>
        <SalesforceLead />
      </Item>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 34px;
`;

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 500;
`;

const Item = styled.div`
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  min-height: 130px;
  padding: 25px;
`;

const ItemTitle = styled.div`
  display: flex;
  gap: 8px;
  height: 20px;
  margin-bottom: 20px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 700;
  font-size: 16px;
  color: var(--text-color-0);
  display: inline-block;
  line-height: 20px;
`;
