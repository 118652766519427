import React from 'react';
import {Input, Form} from 'components/antd';
const {TextArea} = Input;
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Folder, Note} from 'components/Ui/Icon';
import {useBlockGroupQuery, useUpdateBlockGroupMutation} from 'api';
import {Button} from 'components/Ui';
import * as Yup from 'yup';
import {useFormik} from 'formik';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('必須項目です'),
});

type Props = {
  closeEditMode: () => void;
};

export default ({closeEditMode}: Props) => {
  const {blockGroupId} = useParams<{blockGroupId: string}>();
  const {data: {blockGroup = null} = {}} = useBlockGroupQuery({
    variables: {uuid: blockGroupId},
    fetchPolicy: 'cache-and-network',
    skip: !blockGroupId,
  });

  const [updateBlockGroup, {loading}] = useUpdateBlockGroupMutation({
    onCompleted: closeEditMode,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: blockGroup?.name,
      memo: blockGroup?.memo,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      updateBlockGroup({
        variables: {
          uuid: blockGroupId,
          attributes: {
            ...values,
          },
        },
      });
    },
  });

  const {values, isValid, handleChange, handleSubmit} = formik;

  if (!blockGroup) return null;

  return (
    <Container>
      <Form onFinish={handleSubmit}>
        <Item>
          <Folder />
          <NameInput name="name" value={values.name} onChange={handleChange} />
        </Item>
        <ItemMemo>
          <Note />
          <MemoInput
            name="memo"
            value={values.memo}
            onChange={handleChange}
            autoSize={{minRows: 1, maxRows: 6}}
          />
        </ItemMemo>
        <ButtonWrapper>
          <CustomButton
            buttonType="ghost"
            onClick={closeEditMode}
            loading={loading}
            disabled={loading}>
            キャンセル
          </CustomButton>
          <CustomButton
            htmlType="submit"
            loading={loading}
            disabled={!isValid || loading}>
            保存
          </CustomButton>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};

const Container = styled.div``;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const NameInput = styled(Input)`
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: var(--border-color);
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--primary);
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const MemoInput = styled(TextArea)`
  height: 130px !important;
  word-break: break-all;
  border-color: var(--border-color);
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--primary);
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: 70px;
    height: 26px;
    font-size: 9px;
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 70px;
  height: 26px;
  font-size: 9px;
  &:first-child {
    margin-right: 8px;
  }
`;
