import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Radio,
  DatePicker,
} from 'components/antd';
import {useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useCreateAutomaticMailStepMutation, useWorkflowQuery} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';

interface Props {
  onFinish: () => void;
}

export default ({onFinish}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'network-only',
  });
  const [create, {loading}] = useCreateAutomaticMailStepMutation({
    refetchQueries: ['workflow'],
  });
  const formik = useFormik({
    initialValues: {
      name: '自動メール',
      memo: '',
      scheduleType: 'period',
      startDay: 3,
      startHour: 9,
      startDate: moment(),
      daysOfWeek: [1, 2, 3, 4, 5],
      isImmediately: false,
      isContinuedByEvent: false,
      eventToContinue: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      daysOfWeek: Yup.array().ensure().of(Yup.number()).min(1, '必須項目です'),
      eventToContinue: Yup.string().when('isContinuedByEvent', {
        is: (isContinuedByEvent: boolean) => isContinuedByEvent,
        then: (schema) => schema.required('必須項目です'),
      }),
    }),
    onSubmit: (values, {resetForm}) => {
      if (values.scheduleType === 'isImmediately') {
        values.isImmediately = true;
        values.startDate = null;
        values.startDay = null;
        values.startHour = null;
        values.daysOfWeek = [];
      } else if (values.scheduleType === 'period') {
        values.startDate = null;
      } else {
        values.startDay = null;
      }

      const isContinuedByEvent = values.isContinuedByEvent;
      const eventToContinue = values.eventToContinue;

      delete values.isContinuedByEvent;
      delete values.eventToContinue;
      delete values.scheduleType;

      create({
        variables: {
          uuid: workflowId,
          attributes: values,
          automaticMailStepAttributes: {
            isContinuedByEvent,
            eventToContinue,
          },
        },
      });
      values['scheduleType'] = 'period';
      resetForm();
      onFinish();
    },
  });

  React.useEffect(() => {
    // 「formik.values.isContinuedByEvent」の変更が検知されるようにしています。
    formik.validateForm();
  }, [formik.values]);

  return (
    <Container>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item
          label="ステップ名"
          validateStatus={formik.errors.name && 'error'}
          help={formik.errors.name}>
          <Input {...formik.getFieldProps('name')} />
        </Form.Item>
        <Form.Item label="送信タイミング">
          <Radio.Group {...formik.getFieldProps('scheduleType')}>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value="period" />
              <InputNumber
                min={1}
                style={{width: '6rem', marginRight: '0.5rem'}}
                value={formik.values.startDay}
                onChange={(value) => formik.setFieldValue('startDay', value)}
                disabled={formik.values.scheduleType === 'isImmediately'}
              />
              日後に送信
            </Form.Item>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value="date" />
              <DatePicker
                value={formik.values.startDate}
                locale={locale}
                allowClear={false}
                style={{width: '200px', marginRight: '0.5rem'}}
                onChange={(date) => formik.setFieldValue('startDate', date)}
                disabledDate={(current) =>
                  current && current < moment().endOf('day')
                }
              />
              に送信
            </Form.Item>
            {workflow.steps?.length === 0 && (
              <Form.Item style={{marginBottom: '0'}}>
                <Radio value="isImmediately" />
                即時送信
              </Form.Item>
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="送信時間">
          <Form.Item noStyle>
            <Select
              style={{width: '6rem', marginRight: '0.5rem'}}
              value={formik.values.startHour}
              onChange={(value) => formik.setFieldValue('startHour', value)}
              disabled={formik.values.scheduleType === 'isImmediately'}>
              {[...Array(24)].map((_, i) => {
                return (
                  <Select.Option key={i} value={i}>
                    {i}時
                  </Select.Option>
                );
              })}
            </Select>
            に送信
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="この曜日のみ送信します"
          validateStatus={formik.errors.daysOfWeek && 'error'}
          help={formik.errors.daysOfWeek}>
          <p>※送信タイミングがそのチェックがある曜日に実行されます</p>
          <Checkbox.Group
            options={[
              {label: '月', value: 1},
              {label: '火', value: 2},
              {label: '水', value: 3},
              {label: '木', value: 4},
              {label: '金', value: 5},
              {label: '土', value: 6},
              {label: '日', value: 0},
            ]}
            value={formik.values.daysOfWeek}
            onChange={(values) => formik.setFieldValue('daysOfWeek', values)}
            disabled={!(formik.values.scheduleType === 'period')}
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.eventToContinue && 'error'}
          help={formik.errors.eventToContinue}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <div>
              <Checkbox
                checked={formik.values.isContinuedByEvent}
                onChange={(e) => {
                  formik.setFieldValue('isContinuedByEvent', e.target.checked);
                  if (!e.target.checked) {
                    formik.setFieldValue('eventToContinue', '');
                  }
                }}
                style={{fontWeight: 'bold'}}>
                送信後ステータスによる分岐
              </Checkbox>
            </div>
            <div>
              <Select
                style={{
                  width: '185px',
                  marginRight: '0.5rem',
                  marginLeft: '24px',
                }}
                value={formik.values.eventToContinue}
                onChange={(value) =>
                  formik.setFieldValue('eventToContinue', value)
                }
                disabled={!formik.values.isContinuedByEvent}>
                {[
                  {label: '開封', value: 'open'},
                  {label: 'クリック', value: 'click'},
                ].map((event) => (
                  <Select.Option key={event.value} value={event.value}>
                    {event.label}
                  </Select.Option>
                ))}
              </Select>
              で次のステップを開始
            </div>
          </div>
        </Form.Item>
        <Form.Item label="メモ">
          <Input.TextArea {...formik.getFieldProps('memo')} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
            loading={loading}>
            登録
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item-label label {
    font-weight: bold;
  }

  p {
    color: var(--text-color-2);
  }

  .ant-input-number {
    width: 46px !important;
    height: 35px !important;

    input {
      text-align: center;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;
