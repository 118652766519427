import React from 'react';
import {Link, LinkProps, useLocation} from 'react-router-dom';

interface Props extends LinkProps {
  label: string;
  count?: number;
}

export default ({label, count, ...restProps}: Props) => {
  const location = useLocation();
  const active = location.pathname.startsWith(restProps.to.toString());

  return (
    <Link
      {...restProps}
      className={`flex h-14 items-center gap-1 border-b-[3px] bg-transparent ${
        active ? 'border-foreground' : 'border-transparent'
      }`}
    >
      <span
        className={`font-bold ${active ? 'text-c-base' : 'text-foreground-lighter'}`}
      >
        {label}
      </span>
      {count !== undefined && (
        <span
          className={`flex h-4 min-w-[16px] items-center justify-center rounded-full bg-[#F2F7FB] px-1 text-xs font-bold ${
            active ? 'text-c-base' : 'text-foreground-lighter'
          }`}
        >
          {count}
        </span>
      )}
    </Link>
  );
};
