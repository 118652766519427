import {Pagination} from 'components/antd';
import React from 'react';
import styled from 'styled-components';
import Table from './ProjectTable';
import {CubeLoading} from 'components/Ui/Icon';
import useSearchParams from './useSearchParams';
import Cell from './Cell';
import useProjects from './useProjects';

export default () => {
  const {query, setQuery} = useSearchParams();
  const {status} = query;
  React.useEffect(() => {
    !status && setQuery({status: 'open'});
  }, []);

  const {accessibleProjects: projects, pagination, loading} = useProjects();

  const data = React.useMemo(
    () =>
      projects.map((project, i) => {
        return {
          index: i,
          key: project.id,
          ...project,
        };
      }),
    [projects],
  );

  let columns: any = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'status',
        isVisible: false,
        width: 200,
        style: {
          width: '80px',
        },
        visible: true,
        Cell: Cell.Status,
      },
      {
        Header: '営業リスト名',
        accessor: 'name',
        visible: true,
        Cell: Cell.Name,
      },
      {
        Header: 'リマインド',
        visible: true,
        style: {
          width: '76px',
        },
        Cell: Cell.Remind,
      },
      {
        Header: 'リスト件数',
        accessor: 'count',
        style: {
          width: '90px',
        },
        visible: true,
        Cell: Cell.Count,
      },
      {
        Header: '最新メールキャンペーン',
        accessor: 'mailCampaign',
        style: {
          width: '160px',
          textAlign: 'left',
        },
        visible: true,
        Cell: Cell.MailCampaign,
      },
      {
        Header: '作成日',
        accessor: 'index',
        style: {
          width: '160px',
          textAlign: 'left',
        },
        visible: true,
        Cell: Cell.CreatedAt,
      },
    ],
    [projects],
  );

  columns = React.useMemo(
    () => columns.filter((column: any) => column.visible),
    [columns],
  );

  if (!projects) return;

  return (
    <Container>
      <TableWrapper>
        {!data && loading ? (
          <Loading>
            <CubeLoading />
          </Loading>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </TableWrapper>
      <PaginationWrapper>
        <CustomPagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={20}
          onChange={(page) => setQuery({page})}
        />
      </PaginationWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const PaginationWrapper = styled.div``;

const CustomPagination = styled(Pagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;

const Loading = styled.div`
  margin-top: 30px;
  text-align: center;

  svg {
    display: inline-block;
  }
`;
