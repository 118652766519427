import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  title: string;
  path: string;
  category: string;
}

const ReportItem = ({title, path, category}: Props) => {
  return (
    <div className="h-14 w-full flex items-center bg-white rounded  px-5">
      <Link to={path} className="w-1/3 block">
        <div className="block font-medium text-base text-foreground mr-40">
          {title}
        </div>
      </Link>
      <div className="text-xs text-foreground-light bg-[#F3F5F7] text-center px-2 py-1 rounded-sm flex items-center">
        {category}
      </div>
    </div>
  );
};

export default ReportItem;
