import React from 'react';
import styled from 'styled-components';
import {ProspectActivity, CallProspectActivity} from 'api';
import moment from 'moment';
import {Tel, Mail, Comment, Task} from './Icon';

interface Props {
  lastProspectActivity: ProspectActivity;
}

export default ({lastProspectActivity}: Props) => {
  const Icon = () => {
    switch (lastProspectActivity.resourceType) {
      case 'ProspectActivity::AutomaticMailSend':
        return <Mail />;
      case 'ProspectActivity::ManualMailSend':
        return <Mail />;
      case 'ProspectActivity::ManualMailComment':
        return <Mail />;
      case 'ProspectActivity::Call':
        return <Tel />;
      case 'ProspectActivity::ZoomPhoneWebhookCall':
        return <Tel />;
      case 'ProspectActivity::MiitelCall':
        return <Tel />;
      case 'ProspectActivity::Comment':
        return <Comment />;
      case 'ProspectActivity::TaskComment':
        return <Task />;
    }
  };

  let activityText = null;
  if (lastProspectActivity.resourceType === 'ProspectActivity::Call') {
    const resource = lastProspectActivity.resource as CallProspectActivity;
    activityText = resource?.telStatusText;
  }

  return (
    <>
      <ActivityWrapper>
        <span>
          <Icon />
        </span>
        {activityText}
      </ActivityWrapper>

      <CreatedAt>
        {lastProspectActivity.createdAt &&
          (moment().diff(moment(lastProspectActivity.createdAt), 'hours') <
          24 ? (
            <span>
              {moment().diff(moment(lastProspectActivity.createdAt), 'hours')}
              時間前
            </span>
          ) : (
            <span>
              {moment().diff(moment(lastProspectActivity.createdAt), 'days')}
              日前
            </span>
          ))}
      </CreatedAt>
    </>
  );
};

const CreatedAt = styled.div`
  height: 20px;
  color: var(--text-color-2);
  background-color: #fff;
  display: flex;
  align-items: center;
  span {
    font-size: 10px;
  }
`;

const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 2px;
  padding: 4px 6px;
  color: var(--text-color-2);
  background-color: #f3f5f7;
  font-size: 10px;

  span {
    margin: 0px 4px;
    font-size: 10px;
    > svg {
      display: flex;
      align-items: center;
    }
  }
`;
