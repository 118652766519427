import React from 'react';
import styled from 'styled-components';
import {useFormik, FormikErrors} from 'formik';
// @ts-ignore
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import {Button, Upload, Modal, Select} from 'components/antd';
import {UploadOutlined} from '@ant-design/icons';
import {useImportCsvMutation} from 'api';
import {useHistory, useParams} from 'react-router';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const options = [
  {value: '', text: ''},
  {value: 'corp_number', text: '法人番号'},
  {value: 'company_name', text: '会社名'},
  {value: 'web_site', text: 'URL'},
  {value: 'address', text: '住所'},
  {value: 'telephone_number', text: '電話番号'},
];

export default ({visible, onClose}: Props) => {
  const history = useHistory();
  const {blockGroupId} = useParams<{blockGroupId: string}>();
  const [csv, setCsv] = React.useState(null);

  const validate = (values: any) => {
    let errors: FormikErrors<{fields: string}> = {};

    ['company_name'].forEach((field) => {
      if (!values.fields?.includes(field)) {
        errors.fields = 'Required';
      }
    });

    return errors;
  };

  const handleClose = () => {
    setCsv(null);
    onClose();
  };

  const [importCsv, {loading}] = useImportCsvMutation({
    refetchQueries: ['blockGroupMembers'],
    onCompleted: () => {
      handleClose();
      history.push(`/block/block_groups/${blockGroupId}${location.search}`);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      importType: 'form',
    },
    validate,
    onSubmit: (values: any) => {
      importCsv({
        variables: {
          file: values.file,
          attributes: {
            type: 'Import::BlockGroupMember',
            rowCount: csv.length,
            fields: values.fields,
            blockGroupId,
          },
        },
      });
    },
  });

  return (
    <UploadModal
      destroyOnClose
      title={<Title>ブロックリストのアップロード</Title>}
      maskClosable={false}
      visible={visible}
      width={640}
      centered
      bodyStyle={{height: '70vh'}}
      footer={[
        <CancelButton size="large" key="cancel" onClick={handleClose}>
          キャンセル
        </CancelButton>,
        <OkButton
          size="large"
          key="submit"
          type="primary"
          disabled={!formik.isValid || !csv}
          onClick={() => formik.handleSubmit(formik.values)}
          loading={loading}>
          登録
        </OkButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <p>
          アップロードしたCSVの列の内容を、ブロックリストの項目に合わせて保存しましょう
        </p>
        <Upload
          name={'file'}
          accept=".csv"
          showUploadList={false}
          customRequest={() => false}
          onChange={(info) => {
            const file = info.file.originFileObj;
            formik.setFieldValue('file', file);

            const reader = new FileReader();
            reader.onload = (e: any) => {
              const codes = new Uint8Array(e.target.result);
              const encoding = Encoding.detect(codes);
              let unicodeString;
              if (encoding === 'UNICODE') {
                unicodeString = new TextDecoder().decode(codes);
              } else {
                unicodeString = Encoding.convert(codes, {
                  to: 'unicode',
                  from: encoding,
                  type: 'string',
                });
              }
              Papa.parse(unicodeString, {
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: (result: any) => {
                  setCsv(result.data);
                  const size = result.data[0].length;
                  formik.setFieldValue('fields', new Array(size));
                },
              });
            };
            reader.readAsArrayBuffer(file);
          }}>
          <UploadButton size="large" htmlType="button">
            <UploadOutlined />
            Click to Upload
          </UploadButton>
        </Upload>
        {csv && (
          <ListWrapper>
            <ListRow>
              <div className="column">CSVの項目</div>
              <div>システム項目</div>
            </ListRow>
            {csv[0].map((header: string, index: number) => (
              <ListRow key={header}>
                <div className="column">{header}</div>
                <div className="value">
                  <Select
                    style={{width: '250px', height: '30px'}}
                    onChange={(value) => {
                      let fields = formik.values.fields;
                      fields[index] = value;
                      formik.setFieldValue('fields', fields);
                    }}>
                    {options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </ListRow>
            ))}
          </ListWrapper>
        )}
      </ModalContent>
    </UploadModal>
  );
};

const UploadModal = styled(Modal)`
  &&& {
    .ant-modal-body {
      overflow: scroll;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-size: 14px;
  }

  .ant-upload {
    margin-bottom: 2rem;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 24px 24px 0 !important;
`;

const ListWrapper = styled.div`
  border: solid 1px var(--border-color);
  border-radius: 8px;
  padding: 1rem 1.5rem;
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid var(--border-color);

  > div {
    &:first-child {
      width: 250px;
    }
    &:last-child {
      flex: 1;
    }
  }

  &:first-child {
    font-weight: bold;
    > div {
      text-align: center;
    }
  }

  &:last-child {
    border: none;
  }
`;

const UploadButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const OkButton = styled(Button)`
  &&& {
    border: none;
    border-radius: 4px;
  }
`;
