import React from 'react';
import styled from 'styled-components';
import {Input, Button, TimePicker, DatePicker} from 'components/antd';
import {Popover} from 'components/antd';
import {
  useCreatePreleadProjectReminderMutation,
  useDestroyPreleadProjectReminderMutation,
  usePreleadQuery,
} from 'api';
import moment from 'moment';
const {TextArea} = Input;
import locale from 'antd/es/date-picker/locale/ja_JP';
import usePreleadContext from '../../usePreleadContext';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const {preleadId} = usePreleadContext();

  const {data: {prelead: {preleadProjectReminder} = {}} = {}} = usePreleadQuery(
    {
      variables: {uuid: preleadId},
      skip: !preleadId,
    },
  );

  const [memo, setMemo] = React.useState('');
  const [date, setDate] = React.useState(moment().format('YYYY/MM/DD'));
  const [time, setTime] = React.useState(
    moment().set({hour: 10, minute: 0}).format('HH:mm'),
  );

  React.useEffect(() => {
    if (preleadProjectReminder) {
      setMemo(preleadProjectReminder.memo);
      setDate(moment(preleadProjectReminder.remindAt).format('YYYY/MM/DD'));
      setTime(moment(preleadProjectReminder.remindAt).format('HH:mm'));
    } else {
      setMemo('');
      setDate(moment().format('YYYY/MM/DD'));
      setTime(moment().set({hour: 10, minute: 0}).format('HH:mm'));
    }
  }, [preleadProjectReminder]);

  const [createPreleadProjectReminder, {loading}] =
    useCreatePreleadProjectReminderMutation({
      onCompleted: () => {
        setModalVisible(false);
      },
    });

  const [destroyPreleadProjectReminder, {loading: destroyLoading}] =
    useDestroyPreleadProjectReminderMutation({
      onCompleted: () => {
        setModalVisible(false);
      },
    });

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    const values = {
      memo: memo,
      remindAt: moment(`${date} ${time}`, 'YYYY/MM/DD HH:mm'),
    };

    createPreleadProjectReminder({
      variables: {
        attributes: values,
        preleadProjectId: preleadProjectReminder?.preleadProjectId,
      },
    });
  };

  const handleDestroy = () => {
    destroyPreleadProjectReminder({
      variables: {
        preleadProjectId: preleadProjectReminder?.preleadProjectId,
      },
      refetchQueries: ['prelead'],
    });
  };

  return (
    <Popover
      content={() => (
        <Body>
          <p>リマインド設定</p>
          <span>
            リマインドを設定すると、9:00、13:00、16:00に通知がきます。
          </span>
          <div>
            <h3>日時</h3>
            <div>
              <DatePicker
                allowClear={false}
                defaultValue={moment()}
                format="YYYY/MM/DD"
                value={moment(date, 'YYYY/MM/DD')}
                disabledDate={(current) =>
                  current && current.valueOf() < Date.now()
                }
                locale={locale}
                style={{width: '110px'}}
                onChange={(_, dateText) => setDate(dateText)}
              />
              <TimePicker
                allowClear={false}
                value={moment(time, 'HH:mm').startOf('hour')}
                format="HH:mm"
                minuteStep={60}
                showNow={false}
                disabledHours={() => [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 24,
                ]}
                locale={locale}
                style={{width: '75px'}}
                onChange={(_, timeText) => setTime(timeText)}
              />
            </div>
          </div>
          <div>
            <h3>メモ</h3>
            <TextArea
              name="memo"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
          <div className="footer">
            <DeleteButton
              key={'delete'}
              onClick={handleDestroy}
              loading={destroyLoading}
              disabled={!preleadProjectReminder || destroyLoading}>
              削除
            </DeleteButton>
            <SaveButton
              key={'submit'}
              loading={loading}
              disabled={!date || !time || loading}
              onClick={handleSubmit}>
              保存
            </SaveButton>
          </div>
        </Body>
      )}
      trigger="click"
      visible={isModalVisible}
      onVisibleChange={handleCancel}
      placement="rightTop"></Popover>
  );
};

const DefaultButton = styled(Button)`
  border-radius: 3px;
  padding: 6px 23px;
`;

const SaveButton = styled(DefaultButton)`
  background: var(--primary);
  margin-left: 8px;

  &:hover {
    background: #65aae8;
  }

  span {
    color: #fff;
  }
`;

const DeleteButton = styled(DefaultButton)`
  border-color: #c90e19;
  &:hover,
  active {
    border-color: #c90e19;
  }

  span {
    color: #c90e19;
  }
`;

const Body = styled.div`
  width: 330px;
  padding: 16px;

  > p {
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color-1);
  }

  > span {
    display: block;
    margin-top: 14px;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.15px;
    color: var(--text-color-2);
  }

  > div {
    margin-top: 19px;
    display: flex;
    flex-direction: column;

    > h3 {
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.15px;
      color: var(--text-color-2);
    }

    > div {
      display: flex;

      > .ant-picker {
        &:first-child {
          width: 211px !important;
        }
        &:last-child {
          margin-left: auto;
        }
      }
    }

    > textarea {
      height: 70px;
    }
  }
  .footer {
    margin-top: 12px;
    display: flex;
    justify-content: end;
    flex-direction: row;
  }
`;
