import React from 'react';
import styled from 'styled-components';
import useSearchParams from '../useSearchParams';
import Filter from './FilterProject';

export default () => {
  const {query} = useSearchParams();
  const [filters, setFilters] = React.useState(query.projectFilters);

  return (
    <PopoverContent>
      <h3>所属営業リストでフィルタ</h3>
      {filters.map((filter, index) => (
        <Filter
          key={index}
          filter={filter}
          filters={filters}
          setFilters={setFilters}
          index={index}
        />
      ))}
      <div>
        <a
          onClick={() => {
            setFilters([...filters, null]);
          }}>
          フィルタ項目を追加
        </a>
      </div>
    </PopoverContent>
  );
};

const PopoverContent = styled.div`
  background: white;
  width: 820px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 12p;

  h3 {
    font-weight: bold;
    color: var(--text-color-3);
    margin-bottom: 0.5rem;
  }
`;
