import React from 'react';
import SelectedTags from '../SelectedTags';

export default () => {
  return (
    <>
      <div className="bg-[#F3F5F7] border-c-border w-20 text-center mb-2">
        選択中のタグ
      </div>
      <SelectedTags />
    </>
  );
};
