import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  SalesforceTaskCallProspectActivity,
  usePoolQuery,
} from 'api';
import {useParams} from 'react-router-dom';
import {Call} from 'components/Ui/Icon';

interface SalesforceTaskCallActivity extends ProspectActivity {
  resource: SalesforceTaskCallProspectActivity;
}

interface Props {
  prospectActivity: SalesforceTaskCallActivity;
}

export default ({prospectActivity}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const {
    resource: {activityDate, description, salesforceTaskIdString, subject},
  } = prospectActivity;
  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const url = pool?.poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  return (
    <>
      <Call />
      <Content>
        <ContentHeader>
          <a
            href={`${url}/lightning/r/Task/${salesforceTaskIdString}/view`}
            target="_blank">
            <h3>{subject}</h3>
          </a>
          {activityDate && (
            <time>{moment(activityDate).format('YYYY/MM/DD')}</time>
          )}
        </ContentHeader>
        <ContentBox>{description}</ContentBox>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 30px;
  margin-top: 6px;

  h3 {
    flex: 1;
    margin: 0;
    font-weight: bold;
    color: var(--primary);
  }

  span,
  time {
    margin: 10px 0px;
    color: var(--text-color-2);
  }
`;

const ContentBox = styled.div`
  border: solid 1px var(--border-color);
  border-radius: 4px;
  padding: 15px;
  white-space: pre-wrap;
`;
