import React from 'react';
import styled from 'styled-components';
import {Project} from 'api';
import {Row} from 'react-table';
import moment from 'moment';

interface Props {
  value: string;
  row: Row<Project>;
}

export default ({row}: Props) => {
  const project = row.original;

  return (
    <Container>
      <span>{moment(project.createdAt).format('YYYY/MM/DD')}</span>
    </Container>
  );
};

const Container = styled.div`
  width: 80px;
  span {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color-2);
  }
`;
