import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {
  ProspectActivity,
  FormPostProspectActivity,
  useFormPostQuery,
} from 'api';
import {CollectedForm} from 'components/Ui/Icon';
import Block from './Block';

interface FormPostActivity extends ProspectActivity {
  resource: FormPostProspectActivity;
}

interface Props {
  prospectActivity: FormPostActivity;
}

export default ({prospectActivity}: Props) => {
  const REGEXP_URL = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;

  const autoLink = (str: string) => {
    const regexpMakeLink = (url: string) =>
      `<a href="${url}" target="_blank">${url}</a>`;
    return str.replace(REGEXP_URL, regexpMakeLink);
  };

  const isUrl = (str: string) => REGEXP_URL.test(str);

  const {data: {formPost} = {}} = useFormPostQuery({
    variables: {
      uuid: prospectActivity.resource.formPost.uuid,
    },
  });

  const formBlocks =
    formPost?.form?.formPages?.flatMap((page) => page.formBlocks) || [];

  return (
    <>
      <CollectedForm />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-2 min-h-[32px]">
          <h3 className="flex-1 font-bold m-0">
            {formPost ? (
              <Link to={`/form/forms/${formPost.form.uuid}`}>
                {formPost.form.title}
              </Link>
            ) : (
              '読み込み中...'
            )}
          </h3>
          <time className="text-c-light">
            {formPost && moment(formPost.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </div>
        <div className="border border-c-border rounded p-4">
          {formBlocks.map((block) => (
            <Block key={block.uuid} block={block} post={formPost} />
          ))}
        </div>
      </div>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  h4,
  p {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    width: calc(100% - 100px);
  }
  h4 {
    width: 100px;
    color: var(--text-color-2);
    font-size: 12px;
  }
  a {
    cursor: pointer;
  }
`;
