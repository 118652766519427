import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Input, Button} from 'components/antd';
import {useHistory, useParams} from 'react-router';
import {
  useCopyProjectMutation,
  useCurrentClientQuery,
  useProjectQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import useProjectPath from 'hooks/useProjectPath';
import useProjectGroups from 'hooks/useProjectGroups';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const [projectName, setProjectName] = useState('');
  const {searchParams} = useSearchParams();
  const {projectId, offeredClientId} =
    useParams<{projectId: string; offeredClientId: string}>();
  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });
  const {data: {currentClient: {offeredClients = []} = {}} = {}} =
    useCurrentClientQuery();
  const {selectedProjectGroup} = useProjectGroups();

  const offeredClient = React.useMemo(
    () => offeredClients.find((client) => client.uuid === offeredClientId),
    [offeredClientId, offeredClients],
  );

  useEffect(() => {
    setProjectName('(コピー) ' + project.name);
  }, [project]);

  const [copyProject, {loading}] = useCopyProjectMutation({
    refetchQueries: ['currentUser'],
    onCompleted: ({copyProject: {project = {}}}) => {
      history.push(
        createPath(`projects/${project.uuid}/project_summary?status=open`),
      );
    },
  });

  const handleCopy = () => {
    setModalVisible(false);
    copyProject({
      variables: {
        uuid: projectId,
        offeredClientId: offeredClient?.id,
        attributes: {
          name: projectName,
          projectType: project.projectType,
          status: 'open',
          projectGroupId: selectedProjectGroup?.id,
        },
        search: searchParams,
      },
    });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <CopyModal
      title="営業リストコピー"
      visible={isModalVisible}
      closable={false}
      footer={[
        <DefaultButton key="back" onClick={handleCancel}>
          キャンセル
        </DefaultButton>,
        <CopyButton key="submit" loading={loading} onClick={handleCopy}>
          コピー
        </CopyButton>,
      ]}>
      <Title>営業リスト名</Title>
      <ProjectInput
        value={projectName}
        onChange={(e: any) => setProjectName(e.target.value)}></ProjectInput>
      <Anotation>
        現在の検索結果のみを新規営業リストとしてコピーします
      </Anotation>
    </CopyModal>
  );
};

const CopyModal = styled(Modal)`
  .ant-modal-content {
    width: 450px;
    border-radius: 5px;
    padding: 18px 48px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-3);
  }

  .ant-modal-header {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    padding: 0;
    margin: 18px 0;
  }

  .ant-modal-footer {
    padding: 0;
    border: none;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-1);
`;

const ProjectInput = styled(Input)`
  margin: 3px 0 14px;
  border: 0.5px solid var(--text-color-3);
  box-sizing: border-box;
  border-radius: 3px;
`;

const Anotation = styled.span`
  font-size: 10px;
  color: var(--text-color-2);
`;

const DefaultButton = styled(Button)`
border-radius: 3px;
padding: 6px 23px;
`;

const CopyButton = styled(DefaultButton)`
  background: var(--primary);

  &:hover {
    background: #65aae8;
  }

  span {
    color: #fff;
  }
`;
