import React from 'react';
import styled from 'styled-components';
import {Category, Filter as FilterIcon, Note} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import useSearchParams from '../useSearchParams';
import FilterPopover from './FilterPopover';
import FilterProjectPopover from './FilterProjectPopover';
import {useClientPreleadCustomizeItemsQuery} from 'api';
import {useRecoilState} from 'recoil';
import filterOptionsAtom from './Filter/filterOptionsAtom';
import _ from 'lodash';
import useFilterAcitve from './useFilterActive';
import useFilterProjectActive from './useFilterProjectActive';

const FilterMenuPopover = () => {
  const {filterParams} = useSearchParams();
  const {filterActive} = useFilterAcitve(filterParams);
  const {filterProjectActive} = useFilterProjectActive();

  return (
    <PopoverContent>
      <PopoverButton
        content={FilterPopover}
        placement="bottom"
        trigger="click"
        className={filterActive ? 'active' : ''}>
        <Category color={filterActive ? 'var(--primary)' : 'var(--text-color-3)'} />
        <span>項目</span>
      </PopoverButton>
      <PopoverButton
        content={FilterProjectPopover}
        placement="bottom"
        trigger="click"
        className={filterProjectActive ? 'active' : ''}>
        <Note color={filterProjectActive ? 'var(--primary)' : 'var(--text-color-3)'} />
        <span>所属営業リスト</span>
      </PopoverButton>
    </PopoverContent>
  );
};

export default () => {
  const {query} = useSearchParams();
  const {filterActive} = useFilterAcitve(query);
  const {filterProjectActive} = useFilterProjectActive();

  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery();

  const [filterOptions, setFilterOptions] = useRecoilState(filterOptionsAtom);

  React.useEffect(() => {
    const options = [...filterOptions];
    clientPreleadCustomizeItems.forEach((customizeItem) =>
      options.push({
        text: customizeItem.name,
        value: `c.${customizeItem.id}`,
      }),
    );

    setFilterOptions(options);
  }, [clientPreleadCustomizeItems]);

  const active = React.useMemo(
    () => filterActive || filterProjectActive,
    [filterActive, filterProjectActive],
  );

  return (
    <PopoverButton
      content={FilterMenuPopover}
      placement="bottom"
      trigger="click"
      className={active ? 'active' : ''}>
      <FilterIcon color={active ? 'var(--primary)' : 'var(--text-color-3)'} />
      <span>フィルタ</span>
    </PopoverButton>
  );
};

const PopoverButton = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
    margin-right: 5px;
  }

  &.active {
    span {
      color: var(--primary);
    }
  }
`;

const PopoverContent = styled.div`
  background: white;
  width: 182px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 12px;

  > span {
    justify-content: flex-start;
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-3);

    &:first-child {
      margin-bottom: 20px;
    }
  }
`;
