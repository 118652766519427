import React from 'react';
import styled from 'styled-components';
import {Content} from 'api';
import Item from './Item';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  return (
    <Container>
      <h4>URL</h4>
      <Header>
        <span>添付コンテンツ</span>
        <span>計測</span>
      </Header>
      {content?.mailForm?.urls.map((url) => <Item key={url.id} url={url} />)}
    </Container>
  );
};

const Container = styled.div`
  border-bottom: solid 1px #e1e6eb;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: var(--text-color-2);
  height: 30px;
  border-top: solid 1px #e1e6eb;
`;
