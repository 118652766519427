import React from 'react';
import styled from 'styled-components';
import {Link, Route, Switch} from 'react-router-dom';
import CallReport from './CallReport';
import PreleadReport from './PreleadReport';

export default () => {
  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('/call')}>
          <Link to={`/report/call/weekly_history/call${location.search}`}>
            コール数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/prelead')}>
          <Link to={`/report/call/weekly_history/prelead${location.search}`}>
            プレリード数
          </Link>
        </TabItem>
      </Tabs>

      <div>
        <Switch>
          <Route
            path="/report/call/weekly_history/call"
            component={CallReport}
          />
        </Switch>
        <Switch>
          <Route
            path="/report/call/weekly_history/prelead"
            component={PreleadReport}
          />
        </Switch>
      </div>
    </Container>
  );
};

const Container = styled.div``;

const Tabs = styled.div`
  display: flex;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
