import {ReportChart} from '../../components/Chart';

const useChartData = (): ReportChart => {
  return {
    axis: ['High', 'Middle', 'Low'],
    data: [
      {
        label: '未実施',
        color: '#0DC8F1',
        groupId: 1,
        values: [100, 80, 90, 70, 60, 50, 40, 30, 20, 10, 5, 0],
      },
      {
        label: '開始',
        color: '#2693FF',
        groupId: 1,
        values: [20, 30, 40, 50, 60, 70, 80, 90, 80, 70, 60, 50],
      },
      {
        label: '完了',
        color: '#61CF8D',
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '期限切れ',
        color: '#FF3700',
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '保留',
        color: 'var(--text-color-2)',
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
    ],
  };
};

export default useChartData;
