import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  SalesforceDealProspectActivity,
  usePoolQuery,
} from 'api';
import {LaunchLink} from 'components/Ui/Icon';
import {useParams} from 'react-router-dom';

interface SalesforceDealActivity extends ProspectActivity {
  resource: SalesforceDealProspectActivity;
}

interface Props {
  prospectActivity: SalesforceDealActivity;
}

export default ({prospectActivity}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const {resource: {salesforceDeal} = {}} = prospectActivity;

  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const url = pool?.poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  return (
    <>
      <Icon />
      <Content>
        <div>
          <span>商談</span>
          <a
            href={`${url}/lightning/r/Opportunity/${salesforceDeal.salesforceOpportunityIdString}/view`}
            target="_blank">
            <span>{salesforceDeal.name}</span> <LaunchLink />
          </a>
        </div>
        <time className="text-[var(--text-color-2)] ml-4 w-24">
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  margin-top: -4px;
  margin-left: 5px;
  flex: 1;

  div {
    flex: 1;
    display: flex;
    width: 315px;
    span:first-child {
      margin-right: 5px;
    }
    a {
      display: flex;
      align-items: center;
      span {
        max-width: 225px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      svg {
        height: 14px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;
