import React from 'react';
import {ProspectPool} from 'api';
import {getTagFontColor} from 'helpers/tagColors';
import Header from './Header';
import Stage from './Stage';
import LeadSource from './LeadSource';
import Tracking from './Tracking';
import Call from './Call';
import Score from './Score';

interface Props {
  prospectPool: ProspectPool;
}

const TableItem: React.FC<Props> = ({prospectPool}) => {
  return (
    <div className="flex flex-col w-full border-c-border border-t border-x last-of-type:border-b">
      <Header prospectPool={prospectPool} />
      <div className="flex flex-col">
        <div className="flex">
          <Score prospectPool={prospectPool} />
          <Stage prospectPool={prospectPool} />
          <Tracking prospectPool={prospectPool} />
          <Call prospectPool={prospectPool} />
          <LeadSource prospectPool={prospectPool} />
        </div>
        <div className="flex flex-wrap gap-1 py-4 min-h-[52px] mx-4 border-t border-c-border">
          {prospectPool.prospectTags.length === 0 && (
            <span className="text-sm text-c-lighter">タグ未設定</span>
          )}
          {prospectPool.prospectTags?.map((tag) => (
            <span
              key={tag.id}
              className="flex items-center px-2 h-5 rounded-sm text-xs leading-none font-bold tracking-tight"
              style={{
                backgroundColor: tag.color || 'black',
                color: getTagFontColor(tag.color),
              }}>
              {tag.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableItem;
