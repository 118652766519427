import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useProjectWithContentsQuery} from 'api';
import {AudioFile, ContentDocument} from 'components/Ui/Icon';
import byteToSize from 'helpers/byteToSize';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {
    data: {project: {contentAudio = null, contentTelScript = null} = {}} = {},
  } = useProjectWithContentsQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
  });

  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (ref?.current) {
      const audio = ref.current as any;
      audio.volume = 0.2;
    }
  }, [ref]);

  return (
    <Container>
      <h4>コンテンツ一覧</h4>
      {contentTelScript && (
        <File>
          <div>
            <ContentDocument />
            <h3>
              <a href={contentTelScript.file} target="_blank">
                {contentTelScript.fileName}
              </a>
            </h3>
          </div>
        </File>
      )}
      {contentAudio && (
        <File>
          <div>
            <AudioFile />
            <div>
              <h3>
                <a href={contentAudio.file} target="_blank" download>
                  {contentAudio.fileName}
                </a>
              </h3>
              <span>{byteToSize(contentAudio.fileSize)}</span>
            </div>
          </div>
          <audio controls ref={ref} src={contentAudio.file} />
        </File>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  font-size: 12px;
  padding: 1.5rem;

  h4 {
    font-weight: bold;
    color: var(--text-color-1);
  }
`;

const File = styled.div`
  border: 1px solid var(--border-color);
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 10px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    svg {
      width: 30px;
      height: auto;
      margin-right: 0.5rem;
    }

    h3 {
      margin: 0;
      line-height: 1.2;

      a {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    span {
      color: var(--text-color-2);
    }
  }

  audio {
    width: 100%;
  }
`;
