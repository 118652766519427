import React from 'react';
import styled from 'styled-components';
import {Button, Popover} from 'components/antd';
import {MailCampaign} from 'api';
import mailVariableOptions from 'helpers/mailVariableOptions';

const preleadMailVariableOptions = [{title: '会社', code: '{{company}}'}];

interface Props {
  onSelect: (variable: string) => void;
  mailCampaign: MailCampaign;
}

export default ({onSelect, mailCampaign}: Props) => (
  <Popover
    content={
      <Content>
        {(mailCampaign.target === 'prospects'
          ? mailVariableOptions
          : preleadMailVariableOptions
        ).map((option) => (
          <ButtonWrapper key={option.title}>
            <CustomButton onClick={() => onSelect(option.code)}>
              <span>{option.code}</span>
              <span>{option.title}</span>
            </CustomButton>
          </ButtonWrapper>
        ))}
      </Content>
    }
    trigger="click"
    placement="topRight">
    <Button
      style={{
        width: 32,
        justifyContent: 'center',
        display: 'flex',
        color: 'var(--text-color-3)',
      }}>{`{ }`}</Button>
  </Popover>
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span: first-child {
    color: var(--text-color-2);
    margin-right: 10px;
  }
  span: last-child {
    color: var(--text-color-1);
  }
`;

const CustomButton = styled(Button)`
  padding: 0;
  padding-left: 20px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;
