import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {Popover} from 'components/antd';
import {Note, Pen, World, Map} from 'components/Ui/Icon/';
import CompanyAttributeFilter from './filters/CompanyAttribute';
import CompanyScaleFilter from './filters/CompanyScale';
import ExtraFilter from './filters/Extra';
import AreaFilter from './filters/Area';
import useSearchCondition from './useSearchCondition';

export default () => {
  const {query} = useSearchCondition();

  const isCompanyAttribute = Boolean(
    query.accountClosingMonths?.length ||
      query.markets?.length ||
      query.companyTypes?.length ||
      query.isUrl ||
      query.isTel ||
      query.isEmail ||
      query.isRepresentativePerson ||
      query.twitter ||
      query.facebook ||
      query.youtube ||
      query.instagram ||
      query.line ||
      query.linkedin ||
      query.establishedYearMonthFrom ||
      query.establishedYearMonthTo,
  );

  const isCompanyScale = Boolean(
    query.employeeRanges?.length ||
      query.capitalFundFrom ||
      query.capitalFundTo ||
      query.officesNumberFrom ||
      query.officesNumberTo,
  );

  const isExtra = Boolean(
    query.monthPressRelease ||
      query.countPressRelease ||
      query.salesRanges?.length ||
      query.profitUp3 ||
      query.profitDown3 ||
      query.profitRateRanges?.length,
  );

  const isArea =
    query.cityIds?.length > 0 || query.prefectureTypeIds?.length > 0;

  return (
    <Container>
      <GlobalStyle />
      <Popover
        placement="bottom"
        content={CompanyAttributeFilter}
        trigger="click"
        overlayClassName="filter-popover">
        <FilterActionButton>
          <IconWrapper active={isCompanyAttribute}>
            <Note />
          </IconWrapper>
          <IconLabel active={isCompanyAttribute}>企業情報</IconLabel>
        </FilterActionButton>
      </Popover>
      <Popover
        placement="bottom"
        content={AreaFilter}
        trigger="click"
        overlayClassName="filter-popover">
        <FilterActionButton>
          <IconWrapper active={isArea}>
            <Map />
          </IconWrapper>
          <IconLabel active={isArea}>エリア</IconLabel>
        </FilterActionButton>
      </Popover>
      <Popover
        placement="bottom"
        content={CompanyScaleFilter}
        trigger="click"
        overlayClassName="filter-popover">
        <FilterActionButton>
          <IconWrapper active={isCompanyScale}>
            <World />
          </IconWrapper>
          <IconLabel active={isCompanyScale}>規模</IconLabel>
        </FilterActionButton>
      </Popover>
      <Popover
        placement="bottom"
        content={ExtraFilter}
        trigger="click"
        overlayClassName="filter-popover">
        <FilterActionButton>
          <PenIconWrapper active={isExtra}>
            <Pen />
          </PenIconWrapper>
          <IconLabel active={isExtra}>その他</IconLabel>
        </FilterActionButton>
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const FilterActionButton = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const IconLabel = styled.span<{active?: boolean}>`
  display: block;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: ${({active}) => (active ? 'var(--primary)' : 'var(--text-color-3)')};
`;

const GlobalStyle = createGlobalStyle`
  .filter-popover {
    .ant-popover-inner {
      border-radius: 5px;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
`;

const IconWrapper = styled.div<{active?: boolean}>`
  height: 14px;
  width: 14px;
  margin-right: 10px;
  margin-bottom: 6px;

  path {
    stroke: ${({active}) => (active ? 'var(--primary)' : 'var(--text-color-3)')};
  }
`;

const PenIconWrapper = styled.div<{active?: boolean}>`
  height: 14px;
  width: 14px;
  margin-right: 8px;

  path {
    stroke: ${({active}) => (active ? 'var(--primary)' : 'var(--text-color-3)')};
  }
`;
