import React from 'react';
import styled from 'styled-components';
import {Form} from 'api';

interface Props extends React.ComponentProps<typeof Container> {
  form?: Form;
}

export default ({form, children, ...props}: Props) => {
  return (
    <Container form={form} {...props}>
      {children}
    </Container>
  );
};

const Container = styled.div<{form?: Form}>`
  font-size: ${({form}) => `${form?.formFontSize || 14}px`};

  input {
    font-size: ${({form}) => `${form?.formFontSize || 14}px`};
    border-color: ${({form}) => form?.formInputBorderColor || 'var(--border-color)'};
  }

  textarea {
    font-size: ${({form}) => `${form?.formFontSize || 14}px`};
    border-color: ${({form}) => form?.formInputBorderColor || 'var(--border-color)'};
  }
`;
