import React from 'react';
import styled from 'styled-components';
import {Loading} from 'components/Icon';
import useSearchCondition from './useSearchCondition';
import {useSearchCountQuery} from 'api';

export default () => {
  const {searchParams} = useSearchCondition();
  const {data: {searchCount = 0} = {}, loading} = useSearchCountQuery({
    variables: {
      search: searchParams,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <div>
        <Caption>企業の検索件数</Caption>
        <Count>
          {loading ? <Loading /> : `${searchCount?.toLocaleString()}件`}
        </Count>
      </div>
      <Message>10,000件以下の件数を指定してください</Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 1.64%;
  bottom: 1.88%;
  width: 366px;
  height: 65px;

  background: #ffffff;
  opacity: 0.95;
  border-radius: 5px;

  > div {
    display: flex;
  }
`;

const Caption = styled.div`
  margin: auto 50px;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color-3);
`;

const Count = styled.div`
  margin: auto 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: var(--text-color-0);
`;

const Message = styled.span`
  margin-left: 50px;
  font-weight: bold;
  font-size: 10px;
  color: var(--text-color-3);
`;
