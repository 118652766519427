import React from 'react';
import styled from 'styled-components';
import {usePreleadQuery} from 'api';
import useJobListing from 'hooks/useJobListing';
import usePreleadContext from '../usePreleadContext';
import moment from 'moment';

export default () => {
  const {preleadId} = usePreleadContext();

  if (!preleadId) return null;

  const {data: {prelead: {crawlerJobListingPages = []} = {}} = {}} =
    usePreleadQuery({
      variables: {uuid: preleadId},
      skip: !preleadId,
    });

  const {aggregatedJobListingsByMedia} = useJobListing(crawlerJobListingPages);

  return (
    <Container>
      {aggregatedJobListingsByMedia?.map((media) => {
        if (media.jobInformations?.length > 0) {
          return (
            <Media key={media.id}>
              <MediaName>{media.name}</MediaName>
              {media.jobInformations.map((info: any) => (
                <React.Fragment key={info.id}>
                  <label className="pl-2 text-c-light">
                    {info.date && moment(info.date).format('YYYY/MM/DD')}
                  </label>
                  <JobTitle key={info.id} href={info.url} target="_blank">
                    {info.title || info.url}
                  </JobTitle>
                </React.Fragment>
              ))}
              <BorderBottom />
            </Media>
          );
        }
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Media = styled.div`
  padding-bottom: 10px;
`;

const MediaName = styled.div`
  padding-bottom: 15px;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: var(--text-color-0);
`;

const JobTitle = styled.a`
  display: block;
  padding-left: 7px;
  margin-bottom: 8px;
  font-family: Noto Sans JP;
  font-size: 11px;
  letter-spacing: 0.15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BorderBottom = styled.div`
  border-bottom: 0.5px solid var(--text-color-3);
  margin-bottom: 20px;
  padding-top: 20px;
`;
