import React from 'react';
import Icon from './Icon';
import Modal from './Modal';
import {CheckedProspectPoolsContext} from '../CheckedProspectPoolContext';
import {MergeProspectContext} from './MergeProspectContext';
import {useCreateMergeProspectMutation, MergeProspect} from 'api';

export default () => {
  const [open, setOpen] = React.useState(false);
  const [mergeProspect, setMergeProspect] = React.useState<MergeProspect>(null);
  const {checkedProspectPoolIds} = React.useContext(
    CheckedProspectPoolsContext,
  );
  const [createMergeProspect] = useCreateMergeProspectMutation({
    variables: {prospectPoolIds: checkedProspectPoolIds},
    onCompleted: (data) => {
      setMergeProspect(data.createMergeProspect.mergeProspect);
      setOpen(true);
    },
  });

  return (
    <>
      <button
        className="w-[86px] h-9 rounded border border-blue-300 bg-blue-50 flex items-center justify-center cursor-pointer gap-1 leading-9"
        onClick={() => createMergeProspect()}>
        <Icon />
        <div className="text-primary">名寄せ</div>
      </button>
      {open && mergeProspect?.uuid && (
        <MergeProspectContext.Provider
          value={{mergeProspectUuid: mergeProspect.uuid}}>
          <Modal onClose={() => setOpen(false)} />
        </MergeProspectContext.Provider>
      )}
    </>
  );
};
