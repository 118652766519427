import React, {useState} from 'react';
import styled from 'styled-components';
import {Action, useCancelActionMutation} from 'api';
import {Row} from 'react-table';
import {DotMenuSide, Error, User} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import UpdateAssigneeModal from '../UpdateAssigneeModal';

interface Props {
  row: Row<Action>;
}

const MenuCell = ({row}: Props) => {
  const action = row.original;
  const [cancelVisible, setCancelVisible] = useState(false);
  const [assigneeVisible, setAssigneeVisible] = useState(false);

  const [cancelAction] = useCancelActionMutation({
    refetchQueries: ['actionCounts', 'actions'],
  });

  const Content = () => {
    return (
      <>
        <PopoverContent>
          <div
            onClick={() => {
              setAssigneeVisible(true);
              setCancelVisible(false);
            }}>
            <User width="12" height="12" color="var(--text-color-2)" />
            <span>担当者を変更</span>
          </div>
          <div onClick={() => handleClick(row.original.uuid)}>
            <Error />
            <span>キャンセル</span>
          </div>
        </PopoverContent>
      </>
    );
  };

  const handleClick = (uuid: string) => {
    if (confirm('アクションをキャンセルしますか？')) {
      setCancelVisible(false);
      cancelAction({
        variables: {
          uuid: uuid,
        },
      });
    }
  };

  return (
    <>
      <Popover
        placement="bottomRight"
        content={Content}
        trigger="click"
        visible={cancelVisible}
        onVisibleChange={(e) => setCancelVisible(e)}>
        <div style={{cursor: 'pointer'}}>
          <DotMenuSide color={cancelVisible ? 'var(--primary)' : 'var(--text-color-2)'} />
        </div>
      </Popover>
      <UpdateAssigneeModal
        visible={assigneeVisible}
        setVisible={setAssigneeVisible}
        action={action}
      />
    </>
  );
};

const PopoverContent = styled.div`
  width: 140px;
  font-size: 14px;
  color: var(--text-color-2);
  font-weight: normal;
  padding: 4px 6px;
  cursor: pointer;
  > div {
    padding: 4px 8px;
    &:hover {
      background: #f3f5f7;
    }
    > span {
      margin-left: 4px;
    }
  }
`;

export default MenuCell;
