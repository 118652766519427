import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useSalesforceCampaignsQuery,
  useSalesforceCampaignMemberFieldsQuery,
} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {salesforceCampaigns = []} = {}} = useSalesforceCampaignsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {data: {salesforceCampaignMemberFields = []} = {}} =
    useSalesforceCampaignMemberFieldsQuery({
      variables: {salesforceCampaignId: query.salesforceCampaignId},
      skip: !query.salesforceCampaignId,
    });

  if (!query?.salesforceCampaignId) {
    return null;
  }

  const targetSalesforceCampaign = () => {
    return salesforceCampaigns.find(
      (salesforceCampaign) =>
        salesforceCampaign.id === query.salesforceCampaignId,
    );
  };

  const customizeItemById = (id: string) => {
    return salesforceCampaignMemberFields.find((field) => field.id === id);
  };

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">キャンペーン</h4>
        <button
          onClick={() => setFilterType('salesforceCampaign')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              salesforceCampaignId: '',
              salesforceCampaignSearchConditions: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap items-center">
          <span
            key={targetSalesforceCampaign()?.id}
            className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {targetSalesforceCampaign()?.name}
          </span>
        </div>
      </div>
      <div className="flex flex-wrap flex-col gap-2">
        {query?.salesforceCampaignSearchConditions.map((item, index) => {
          return (
            <div key={index} className="flex gap-3">
              {customizeItemById(item['salesforce_campaign_member_field_id'])
                ?.fieldType === 'select' && (
                <>
                  <div className="text-foreground-light text-sm min-w-28 text-center truncate">
                    {item['values']?.length > 0 &&
                      `${customizeItemById(
                        item['salesforce_campaign_member_field_id'],
                      )?.label}:`}
                  </div>
                  <div className="flex flex-wrap gap-1">
                    {item['values']?.length > 0 &&
                      item['values'].map((option: string, index: number) => {
                        return (
                          <div
                            key={index}
                            className="min-w-16 h-5 bg-[#F3F5F7] border border-border text-center truncate text-sm px-2 rounded-sm">
                            {
                              customizeItemById(
                                item['salesforce_campaign_member_field_id'],
                              )?.salesforceCampaignMemberFieldSelectOptions?.find(
                                (item) => item.id === option,
                              )?.label
                            }
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
