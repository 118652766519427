import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Select, Form} from 'components/antd';
import {CubeLoading} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import useSearchParams from '../useSearchParams';
import {
  useProjectQuery,
  usePreleadProjectsQuery,
  useAssignUserToProjectBySearchConditionMutation,
  useProjectGroupByProjectUuidQuery,
} from 'api';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {searchParams, page, perPage} = useSearchParams();
  const [user, setUser] = React.useState(null);

  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const {data: {projectGroupByProjectUuid: projectGroup = null} = {}} =
    useProjectGroupByProjectUuidQuery({
      variables: {uuid: project?.uuid},
    });

  const {
    data: {
      preleadProjects: {pagination: preleadProjectsPagination = {}} = {},
    } = {},
  } = usePreleadProjectsQuery({
    variables: {
      uuid: projectId,
      search: searchParams,
      page,
      perPage,
    },
    fetchPolicy: 'cache-only',
  });

  const [assignUser, {loading}] =
    useAssignUserToProjectBySearchConditionMutation({
      refetchQueries: ['preleadProjects'],
      onCompleted: () => setModalVisible(false),
    });

  const onSubmit = () =>
    assignUser({
      variables: {uuid: projectId, search: searchParams, userUuid: user},
    });

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      visible={isModalVisible}
      width={320}
      footer={null}
      closable={!loading}
      onCancel={() => setModalVisible(false)}
      centered>
      <Content>
        {loading ? (
          <CubeLoading />
        ) : (
          <Form layout="vertical" style={{width: '100%'}} onFinish={onSubmit}>
            <Form.Item>
              <TargetCount>
                <span>対象</span>
                <div>
                  {preleadProjectsPagination.totalCount === project.count ? (
                    '全件'
                  ) : (
                    <>
                      <em>
                        {preleadProjectsPagination.totalCount?.toLocaleString()}
                      </em>{' '}
                      / {project.count.toLocaleString()}
                    </>
                  )}
                </div>
              </TargetCount>
            </Form.Item>
            <Form.Item label="担当者を設定">
              <Select value={user} onChange={(value) => setUser(value)}>
                <Select.Option value="">担当者なし</Select.Option>
                {projectGroup?.users.map((user) => (
                  <Select.Option value={user.uuid} key={user.uuid}>
                    {user.lastName} {user.firstName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Submit
              type="primary"
              htmlType="submit"
              disabled={!preleadProjectsPagination.totalCount || user === null}>
              更新
            </Submit>
          </Form>
        )}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  p {
    text-align: center;
  }

  em {
    font-size: 24px;
    font-weight: bold;
    color: #f46868;
    font-style: normal;
    margin-bottom: 1rem;
  }

  button {
    width: 180px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 16px;
      margin-right: 3px;
    }
  }
`;

const TargetCount = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  borer-radius: 4px;
  background: #f3f5f7;

  span {
    color: var(--text-color-3);
    padding: 0 24px 0 11px;
  }

  div {
    color: var(--text-color-1);

    em {
      font-size: 12px;
      color: #8966ef;
    }
  }
`;

const Submit = styled(Button)`
  &&& {
    margin: 0;
    width: 100px;
    float: right;
  }
`;
