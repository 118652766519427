import React from 'react';
import styled from 'styled-components';
import {useParams, Route, Switch, Link} from 'react-router-dom';
import TagReport from './tagReport';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();

  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('/tag')}>
          <Link
            to={createPath(`projects/${projectId}/prelead_reports/items/tag`)}>
            タグ別
          </Link>
        </TabItem>
      </Tabs>

      <Switch>
        <Route
          path={projectRoutePaths(
            'projects/:projectId/prelead_reports/items/tag',
          )}
          component={TagReport}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 1200px;
  background: #ffffff;
  padding: 30px;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
