import React from 'react';
import Modal from './Modal';
import {useProspectScoreSettingQuery, ProspectTag} from 'api';
import SelectedTags from './SelectedTags';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div>
            <h2 className="text-base m-0 font-bold">リードタグ</h2>
            <p className="m-0 text-sm">
              重要度を高めるリードタグを選択してください。
            </p>
          </div>
          <button
            className="bg-white border border-c-border rounded h-8 text-sm px-4 cursor-pointer"
            onClick={() => setVisible(true)}>
            リードタグを選択
          </button>
          <SelectedTags />
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
