import React from 'react';
import {useRouteMatch, useHistory, useParams} from 'react-router-dom';
import {FormBlock, useDestroyFormBlockMutation} from 'api';
import TextBlock from './Text';
import ButtonBlock from './Button';
import ImageBlock from './Image';
import {TrashIcon} from '@heroicons/react/24/outline';

const blockComponents: {[key: string]: any} = {
  FormBlockText: TextBlock,
  FormBlockButton: ButtonBlock,
  FormBlockImage: ImageBlock,
};

const Handle = () => (
  <svg
    width="6"
    height="18"
    viewBox="0 0 6 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2ZM1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6ZM2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9ZM1 14C1.55228 14 2 13.5523 2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14ZM2 17C2 17.5523 1.55228 18 1 18C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16C1.55228 16 2 16.4477 2 17ZM5 2C5.55228 2 6 1.55228 6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2ZM6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5ZM5 10C5.55228 10 6 9.55229 6 9C6 8.44771 5.55228 8 5 8C4.44772 8 4 8.44771 4 9C4 9.55229 4.44772 10 5 10ZM6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13ZM5 18C5.55228 18 6 17.5523 6 17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18Z"
      fill="white"
    />
  </svg>
);

interface Props {
  block: FormBlock;
}

export default ({block}: Props) => {
  const history = useHistory();
  const {formId} = useParams<{formId: string}>();
  const match = useRouteMatch<{page: string}>({
    path: '/form/forms/:formId/:page',
  });
  const matchDetail = useRouteMatch<{blockId: string}>({
    path: '/form/forms/:formId/:page/blocks/:blockId',
  });

  const [destroyFormBlock] = useDestroyFormBlockMutation({
    variables: {uuid: block.uuid},
    onCompleted: () =>
      history.push(`/form/forms/${formId}/${match.params.page}/blocks`),
  });

  const onClickDelete = React.useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      destroyFormBlock();
    },
    [destroyFormBlock],
  );

  const active = React.useMemo(() => {
    if (!matchDetail) {
      return false;
    }
    return matchDetail.params.blockId === block.uuid;
  }, [matchDetail, block]);

  const BlockComponent = blockComponents[block.formBlockable.__typename];

  if (!BlockComponent) return null;

  return (
    <div
      className={`flex w-full bg-transparent cursor-pointer relative ${
        active
          ? 'shadow-sm shadow-c-primary outline-c-primary outline outline-2 z-10'
          : 'outline-transparent hover:outline-c-primary hover:outline-1 hover:outline'
      }`}
      onClick={() =>
        history.push(
          `/form/forms/${formId}/${match.params.page}/blocks/${block.uuid}`,
        )
      }>
      <div
        className={`dragHandle w-4 absolute h-full -left-4 bg-c-primary outline outline-2 outline-c-primary shadow-sm shadow-c-primary cursor-move flex items-center justify-center ${
          active ? 'visible' : 'invisible'
        }`}>
        <Handle />
      </div>
      <div className="m-2 flex-1">
        <BlockComponent block={block} />
      </div>
      {active && (
        <button
          onClick={onClickDelete}
          className="bg-foreground hover:bg-black cursor-pointer border-none absolute -top-3 right-4 h-6 w-6 rounded flex items-center justify-center">
          <TrashIcon className="w-5 h-5 relative -top-[1px] text-white" />
        </button>
      )}
    </div>
  );
};
