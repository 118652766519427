import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  SalesforceContactLeadCreateProspectActivity,
  usePoolQuery,
} from 'api';
import {useParams} from 'react-router-dom';

interface SalesforceContactLeadCreateActivity extends ProspectActivity {
  resource: SalesforceContactLeadCreateProspectActivity;
}

interface Props {
  prospectActivity: SalesforceContactLeadCreateActivity;
}

export default ({prospectActivity}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const {
    resource: {salesforceContact = {}},
  } = prospectActivity;

  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const url = pool?.poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  return (
    <>
      <Icon />
      <Content>
        <a
          href={`${url}/lightning/r/Contact/${salesforceContact.contactId}/view`}
          target="_blank">
          Salesforce
        </a>
        <time>
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 455px;
  margin-top: -4px;
  margin-left: 3px;

  a {
    flex: 1;
    margin: 0;
    font-weight: 500;
    width: 375px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;
