import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {StringParam, useQueryParam} from 'use-query-params';
import BasicInformation from './BasicInformation';
import {Button} from 'components/antd';
import {PenWithLine} from 'components/Ui/Icon';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();

  const [, setInformation] = useQueryParam('information', StringParam);

  if (!preleadProjectId) return null;

  return (
    <Container>
      <div>
        <h2>企業情報</h2>
        <Action>
          <Button type="text" onClick={() => setInformation('company_edit')}>
            <PenWithLine />
          </Button>
        </Action>
      </div>
      <BasicInformation />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    h2 {
      font-weight: bold;
      font-size: 12px;
      color: var(--text-color-1);
    }
  }
`;

const Action = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;
