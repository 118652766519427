import React from 'react';
import {useQueryParam, StringParam, BooleanParam} from 'use-query-params';
import {Route, Link} from 'react-router-dom';
import styled from 'styled-components';
import {Breadcrumb, Header, Container} from 'components/Ui';
import NewButton from './NewButton';
import NewModal from './NewModal';
import {
  Content,
  ContentForm,
  ContentMail,
  ContentDocument,
  ContentArchive,
} from 'components/Ui/Icon';
import Title from './Title';
import Table from './Table';

export default () => {
  const [type] = useQueryParam('type', StringParam);
  const [archived] = useQueryParam('archived', BooleanParam);

  return (
    <Container page="garage">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <span className="current">コンテンツ一覧</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Main>
        <Nav>
          <h3>ファイル種類</h3>
          <Link
            to="/garage/contents"
            className={!type && !archived ? 'active' : ''}>
            <span>
              <Content color={!type ? 'var(--text-color-1)' : 'var(--text-color-2)'} />
            </span>
            ALL
          </Link>
          <Link
            to="/garage/contents?type=mail_template"
            className={type === 'mail_template' ? 'active' : ''}>
            <span>
              <ContentMail
                color={type === 'mail_template' ? 'var(--text-color-1)' : 'var(--text-color-2)'}
              />
            </span>
            メールテンプレート
          </Link>
          <Link
            to="/garage/contents?type=mail_form"
            className={type === 'mail_form' ? 'active' : ''}>
            <span>
              <ContentForm
                color={type === 'mail_form' ? 'var(--text-color-1)' : 'var(--text-color-2)'}
              />
            </span>
            フォームテンプレート
          </Link>
          <Link
            to="/garage/contents?type=document"
            className={type === 'document' ? 'active' : ''}>
            <span>
              <ContentDocument
                color={type === 'document' ? 'var(--text-color-1)' : 'var(--text-color-2)'}
              />
            </span>
            ドキュメント
          </Link>
          <Divider />
          <Link
            to="/garage/contents?archived=1"
            className={archived ? 'active' : ''}>
            <span>
              <ContentArchive color={archived ? 'var(--text-color-1)' : 'var(--text-color-2)'} />
            </span>
            アーカイブ
          </Link>
        </Nav>
        <ContentArea>
          <Title />
          <Table />
        </ContentArea>
      </Main>
      <NewButton />
      <Route path="/garage/contents/new" component={NewModal} />
    </Container>
  );
};

const Main = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'nav content';
`;

const Nav = styled.div`
  grid-area: nav;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem 0;

  h3 {
    font-size: 12px;
    color: var(--text-color-2);
  }

  a {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 0 10px;
    color: var(--text-color-2);
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 5px;

    span {
      margin-right: 10px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      background: #e9ecef;
      color: #484f57;
    }
  }
`;

const ContentArea = styled.div`
  grid-area: content;
  padding: 0rem 2rem 2rem 0;
  overflow: scroll;
`;

const Divider = styled.div`
  border-top: solid 1px #d7dadd;
  margin: 0.5rem 0;
`;
