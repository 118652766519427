import React from 'react';
import styled from 'styled-components';
import {SubCategory} from 'api';
import {Checkbox} from 'components/Ui';

type Props = {
  subCategory: SubCategory;
  checked: boolean;
  onSelect: (
    checked: boolean,
    selectedSubCategory: SubCategory,
  ) => Promise<void>;
};
const ChildCategory = ({subCategory, checked, onSelect}: Props) => {
  return (
    <Container>
      <CategoryCheckBox
        checked={checked}
        className="subCategory"
        onChange={(e) => onSelect(e.target.checked, subCategory)}>
        {subCategory.displayName}
      </CategoryCheckBox>
      <Count>{subCategory.count}件</Count>
    </Container>
  );
};

export {ChildCategory};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CategoryCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;
  padding: 5px 0;
  .ant-checkbox {
    top: 0em;
  }
`;

const Count = styled.span`
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary);
`;
