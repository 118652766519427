import React, {useRef} from 'react';
import Header from '../Header';
import Footer from './Footer';
import useSearchParams from '../../../useSearchParams';

const ProspectScoreFilter = () => {
  const {query, setQuery} = useSearchParams();
  const refFrom = useRef(null);
  const refTo = useRef(null);

  return (
    <>
      <Header title="リードスコア" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <label className="text-c-base font-bold">リードスコア</label>
          <div className="flex items-center">
            <input
              min={1}
              type="number"
              defaultValue={query?.prospectScore?.from}
              onBlur={(e) => {
                const inputValue = e.target.value;
                if (inputValue === '') {
                  const {from, ...restProspectScore} = query.prospectScore;
                  setQuery({
                    ...query,
                    prospectScore: {...restProspectScore},
                  });
                } else if (!isNaN(Number(inputValue))) {
                  setQuery({
                    ...query,
                    prospectScore: {
                      ...query.prospectScore,
                      from: Number(inputValue),
                    },
                  });
                }
              }}
              ref={refFrom}
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
            />
            <span className="mx-2">~</span>
            <input
              min={1}
              type="number"
              defaultValue={query?.prospectScore?.to}
              onBlur={(e) => {
                const inputValue = e.target.value;
                if (inputValue === '') {
                  const {to, ...restProspectScore} = query.prospectScore;
                  setQuery({
                    ...query,
                    prospectScore: {
                      ...restProspectScore,
                    },
                  });
                } else if (!isNaN(Number(inputValue))) {
                  setQuery({
                    ...query,
                    prospectScore: {
                      ...query.prospectScore,
                      to: Number(inputValue),
                    },
                  });
                }
              }}
              ref={refTo}
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
            />
          </div>
        </div>
      </div>
      <Footer
        onClear={() => {
          setQuery({prospectScore: {}});
          refFrom.current.value = '';
          refTo.current.value = '';
        }}
      />
    </>
  );
};

export default ProspectScoreFilter;
