import React from 'react';
import {useProspectScoreSettingQuery} from 'api';
import {Tab} from '@headlessui/react';
import TabContent from './TabContent';
import Tags from './Tags';

export default () => {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const priorities = [1, 2, 3, 4, 5];
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
      <div className="bg-white p-6 rounded flex flex-col gap-2">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-sm p-1 bg-[#F3F5F7] w-[484px]">
            {priorities.map((priority) => (
              <Tab
                className={({selected}) =>
                  classNames(
                    'w-52  py-1 text-sm font-medium leading-5 focus:outline-none cursor-pointer',
                    selected
                      ? 'text-[#495058] bg-white'
                      : 'text-[#BDC4CB] bg-[#F3F5F7]',
                  )
                }>
                <div className="flex items-center gap-2 justify-center">
                  <span>優先度{priority}</span>
                  <span className="bg-[#F3F5F7] rounded-full px-1 text-[10px]">
                    {prospectScoreSetting?.prospectTagsGroupByPriority?.[
                      priority
                    ]?.length ?? 0}
                  </span>
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {[1, 2, 3, 4, 5].map((priority) => {
              return (
                <Tab.Panel
                  className={'bg-white p-3 focus:outline-none'}
                  key={priority}>
                  <div className="flex flex-col">
                    <TabContent priority={priority} />
                    <div className="border my-3 border-[#E6E6E6]"></div>
                    <Tags />
                  </div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
