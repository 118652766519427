import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTable, Cell} from 'react-table';
import {Switch, Route} from 'react-router-dom';
import Detail from './detail';
import styled from 'styled-components';
import {useQueryParams, NumberParam, useQueryParam} from 'use-query-params';
import {
  User,
  ClientUser,
  useUsersByClientQuery,
  useResendSignupMailMutation,
  useCurrentClientQuery,
} from 'api';
import {Tooltip} from 'components/antd';
import Pagination from 'components/Ui/Pagination';
import useContract from 'hooks/useContract';
import {sideMenuWidth} from 'helpers/sideMenu';
// @ts-ignore
import InviteModal from './InviteModal';
// @ts-ignore
import AddIcon from './add.svg';
// @ts-ignore
import EmailIcon from './email.svg';
import useClientUser from 'hooks/useClientUser';
import UserLicenseCount from './UserLicenseCount';

type UserWithClientRole = {
  confirmedAt: string;
  email: string;
  fullName: string;
  role: string;
  uuid: string;
  userName: string;
};

export default () => {
  const {isAdmin} = useClientUser();
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  const [query, setQuery] = useQueryParams({
    active: NumberParam,
  });
  const history = useHistory();

  const [page, setPage] = useQueryParam('page', NumberParam);

  const {active} = query;

  const {data: {usersByClient: {users = [], pagination = {}} = {}} = {}} =
    useUsersByClientQuery({
      variables: {
        search: {
          page: page || 1,
          active: active === 0 ? true : false,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const {isUserAddable} = useContract();

  const [resendSignupMail] = useResendSignupMailMutation();

  const targetRole = (clientUsers: ClientUser[]) => {
    const clientUser = clientUsers.find(
      (clientUser) => clientUser.clientId === currentClient.id,
    );
    return clientUser?.roleText;
  };

  const data = React.useMemo(() => {
    let userData: any = [];
    users.map((user: User) => {
      userData.push({
        confirmedAt: user.confirmedAt,
        email: user.email,
        fullName: `${user.lastName ? user.lastName : ''} ${
          user.firstName ? user.firstName : ''
        }`,
        role: targetRole(user?.clientUsers),
        uuid: user.uuid,
        userName: user.userName,
      });
    });
    return userData;
  }, [users, currentClient]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'ユーザー',
        accessor: 'email',
        Cell: ({value, row}: Cell<UserWithClientRole>) => {
          return (
            <Link to={`/settings/users/${row.original.uuid}${location.search}`}>
              {value}
              <div style={{color: 'var(--text-color-2)', fontSize: '12px'}}>
                {row.original?.userName}
              </div>
            </Link>
          );
        },
      },
      {
        Header: '名前',
        accessor: 'fullName',
        Cell: ({value}: Cell<UserWithClientRole>) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: '権限',
        accessor: 'role',
        Cell: ({value}: Cell<UserWithClientRole>) => {
          return <RoleLabel>{value}</RoleLabel>;
        },
      },
      {
        Header: '',
        accessor: 'active',
        Cell: ({row}: Cell<UserWithClientRole>) => (
          <Wrapper>
            {isAdmin && !row.original?.confirmedAt && (
              <ResendSignupMailButton
                type="button"
                onClick={() => {
                  confirm('サインアップメールを再送信しますか？') &&
                    resendSignupMail({
                      variables: {
                        user: {email: row.original.email},
                      },
                    });
                }}>
                <EmailIcon />
                <span>再送信</span>
              </ResendSignupMailButton>
            )}
          </Wrapper>
        ),
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  React.useEffect(() => {
    if (!active) {
      setQuery({active: 0});
    }
  }, []);

  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <Container>
      <Content>
        <div className="flex justify-between items-center w-full h-[43px] mb-2 text-foreground">
          <div className="font-bold text-[24px]">ユーザー管理</div>
          <UserLicenseCount />
        </div>
        <Header>
          <Tabs>
            <Tab
              active={active === 0}
              onClick={() => {
                setQuery({active: 0});
                history.push(`/settings/users/${location.search}`);
              }}>
              <span>アクティブ</span>
            </Tab>
            <Tab
              active={active === 1}
              onClick={() => {
                setQuery({active: 1});
                history.push(`/settings/users/${location.search}`);
              }}>
              <span>無効</span>
            </Tab>
          </Tabs>
          {isAdmin &&
            (isUserAddable ? (
              <TabButton
                disabled={false}
                onClick={() => {
                  setModalOpen(true);
                }}>
                <AddIcon />
                <span>招待</span>
              </TabButton>
            ) : (
              <Tooltip
                title="登録人数が上限に達しました。"
                color={`#EB5757`}
                placement="top">
                <TabButton disabled>
                  <AddIcon />
                  <span>招待</span>
                </TabButton>
              </Tooltip>
            ))}

          {isModalOpen && (
            <InviteModal isOpen={isModalOpen} setModal={setModalOpen} />
          )}
        </Header>
        <Body>
          <table {...getTableProps}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, j) => (
                    <th {...column.getHeaderProps()} key={j}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`row-${i}`}>
                    {row.cells.map((cell, j) => {
                      return (
                        <td {...cell.getCellProps()} key={`cell-${j}`}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Body>
      </Content>
      <Switch>
        <Route path="/settings/users/:userId" component={Detail} />
      </Switch>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={50}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  height: calc(100vh - 130px);
  overflow-x: hidden;
`;

const Content = styled.div`
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  padding: 45px 70px 90px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #e1e6eb;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: -1px;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 97px;

  span {
    display: block;
    height: 100%;
    border-bottom: ${({active}) => (active ? 'solid 3px var(--primary)' : '')};
    padding: 12px 13px;
    font-size: 14px;
    line-height: 19px;
    color: ${({active}) => (active ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  }

  &:hover {
    cursor: pointer;
  }
`;

const TabButton = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 6px 13px;
  border: none;
  outline: 0;
  background: ${({disabled}) => (disabled ? '#F5F5F5' : 'var(--primary)')};
  border-radius: 4px;

  svg {
    margin-right: 7px;
    path {
      stroke: ${({disabled}) => (disabled ? 'var(--text-color-3)' : '#fff')};
    }
  }

  span {
    font-size: 12px;
    line-height: 12px;
    color: ${({disabled}) => (disabled ? 'var(--text-color-3)' : '#fff')};
  }

  &:hover {
    cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  }
`;

const ResendSignupMailButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  background: #fff;
  padding: 8px 10px;
  outline: 0;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    color: var(--text-color-1);
  }

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const Body = styled.div`
  table {
    width: 100%;

    thead {
      width: 100%;
      border-bottom: 1px solid #e1e6eb;

      th {
        padding: 12px 13px;
        font-size: 12px;
        color: var(--text-color-2);
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e1e6eb;
      }

      td {
        padding: 12px 13px;
        font-size: 14px;

        a {
          color: var(--text-color-0);
        }
      }
    }
  }
`;

const RoleLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 36px;
  padding: 8px 10px;
  border: 1px solid #e1e6eb;
  outline: 0;
  background: #fbfcff;
  border-radius: 4px;
  color: var(--text-color-1);
  width: 87px;
`;
