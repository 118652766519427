import {useSearchCalledUsersByProjectUuidQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import useSearchParams from './useSearchParams';

export default () => {
  const {projectId} = useParams<{
    projectId: string;
  }>();

  const {query, setQuery} = useSearchParams();

  const {data: {searchCalledUsersByProjectUuid: calledUsers = []} = {}} =
    useSearchCalledUsersByProjectUuidQuery({
      variables: {projectUuid: projectId},
    });

  return (
    <Container>
      <h2>コール担当者</h2>
      <div>
        {calledUsers.map((user) => (
          <UserBox
            key={user.id}
            active={query.userIds.includes(user.id)}
            onClick={() => setQuery({userIds: [user.id]})}>
            <span>
              {user.lastName} {user.firstName}
            </span>
          </UserBox>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 33px;
  min-width: 253px;
  max-width: 253px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: var(--text-color-1);
  }

  > div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
`;

const UserBox = styled.div<{active?: boolean}>`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 37px;
  background: ${({active}) => (active ? '#F5FAFF' : '')};
  border-left: ${({active}) => (active ? '2px solid var(--primary)' : '')};

  span {
    margin: auto auto auto 9px;
    font-size: 14px;
    color: var(--text-color-1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
