import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Select, Form, Popover} from 'components/antd';
import {CubeLoading} from 'components/Ui/Icon';
import {useAddTagsToPreleadMutation} from 'api';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from '../checkedPreleadProjectsAtom';
import AddTagModalPopover from './AddTagModalPopover';
import usePreleadTags from 'hooks/usePreleadTags';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const [tagIds, setTagIds] = React.useState([]);
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const [checkedPreleadProjects, setCheckedPreleadProjects] = useRecoilState(
    checkedPreleadProjectsAtom,
  );
  const {preleadTagsAccessible} = usePreleadTags();

  const [addTags, {loading}] = useAddTagsToPreleadMutation({
    refetchQueries: ['preleadProjects'],
    onCompleted: () => {
      setCheckedPreleadProjects([]);
      setModalVisible(false);
    },
  });

  const onSubmit = () =>
    addTags({
      variables: {uuids: checkedPreleadProjects, tagIds: tagIds},
    });

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      visible={isModalVisible}
      width={320}
      footer={null}
      onCancel={() => setModalVisible(false)}
      centered>
      <Content>
        {loading ? (
          <CubeLoading />
        ) : (
          <Form layout="vertical" style={{width: '100%'}} onFinish={onSubmit}>
            <h3>タグを設定</h3>
            <Form.Item>
              <Popover
                content={() => (
                  <AddTagModalPopover tagIds={tagIds} setTagIds={setTagIds} />
                )}
                trigger="click"
                visible={isPopoverVisible}
                onVisibleChange={(visible) => setPopoverVisible(visible)}
                placement="bottom">
                <Select
                  dropdownMatchSelectWidth={false}
                  mode="multiple"
                  allowClear
                  open={false}
                  optionFilterProp="title"
                  placeholder="タグを選択"
                  value={tagIds || []}>
                  {preleadTagsAccessible?.map((tag) => (
                    <Select.Option
                      title={tag.name}
                      value={tag.id}
                      key={tag.id}
                      label={
                        <React.Fragment>
                          <TagRectangle color={tag.color} />
                          &nbsp; {tag.name}
                        </React.Fragment>
                      }>
                      <TagRectangle color={tag.color} />
                      &nbsp; {tag.name}
                    </Select.Option>
                  ))}
                </Select>
              </Popover>
            </Form.Item>
            <Submit
              type="primary"
              htmlType="submit"
              disabled={
                checkedPreleadProjects.length === 0 || tagIds.length === 0
              }>
              登録
            </Submit>
          </Form>
        )}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-3);
  }

  button {
    width: 180px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
      margin-right: 3px;
    }
  }
`;

const TagRectangle = styled.span<{color?: string}>`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({color}) => (color ? `${color}` : 'white')};
`;

const Submit = styled(Button)`
  &&& {
    margin: 0;
    width: 100px;
    float: right;
  }
`;
