import React, {ComponentType} from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import {Input, Button, Form, Select, DatePicker} from 'components/antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {
  useCreateProspectMutation,
  useLeadSourcesQuery,
  useProspectTagsQuery,
  useUsersByClientAllQuery,
} from 'api';
import {Cross} from 'components/Ui/Icon';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const [createProspect, {loading}] = useCreateProspectMutation({
    refetchQueries: ['prospectPools', 'prospectPoolsStageCount'],
  });
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    variables: {uuid: poolId},
    skip: !poolId,
  });

  const {data: {prospectTags = []} = {}} = useProspectTagsQuery({
    variables: {uuid: poolId},
    skip: !poolId,
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const validateSchema = Yup.object().shape({
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    accountName: Yup.string().required(),
    leadSourceId: Yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: null,
      lastName: null,
      email: null,
      telephoneNumber: null,
      accountName: null,
      leadSourceId: null,
      section: null,
      sectionPosition: null,
      webSite: null,
      tagIds: [],
      assigneeId: null,
      inflowDate: null,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      createProspect({variables: {poolUuid: poolId, attributes: values}});
      closeModal();
    },
  });

  const {
    values,
    isValid,
    handleSubmit,
    setFieldValue,
    resetForm,
    getFieldProps,
  } = formik;

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '381px',
      maxHeight: '95vh',
      padding: '20px 40px',
      borderRadius: '4px',
    },
  };

  const closeModal = () => {
    resetForm();
    setIsModalVisible(false);
  };

  const Modal = ReactModal as ComponentType<ReactModal['props']>;

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => false}
      style={customStyles}>
      <Header>
        <div>リードを追加</div>
        <CrossIconWrapper onClick={() => closeModal()}>
          <Cross />
        </CrossIconWrapper>
      </Header>

      <Body>
        <Form layout="vertical">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}>
            <Form.Item label="姓" required>
              <Input {...getFieldProps('lastName')} />
            </Form.Item>

            <Form.Item label="名">
              <Input {...getFieldProps('firstName')} />
            </Form.Item>
          </div>

          <Form.Item label="メールアドレス" required>
            <Input {...getFieldProps('email')} />
          </Form.Item>

          <Form.Item label="リードソース" required>
            <Select
              value={values.leadSourceId}
              onChange={(value) => setFieldValue('leadSourceId', value)}>
              {leadSources.map((leadSource) => (
                <Select.Option key={leadSource.id} value={leadSource.id}>
                  {leadSource.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="流入日">
            <DatePicker
              format={'YYYY/MM/DD'}
              locale={locale}
              onChange={(value) => setFieldValue('inflowDate', value)}
            />
          </Form.Item>

          <Form.Item label="電話番号">
            <Input {...getFieldProps('telephoneNumber')} />
          </Form.Item>

          <Form.Item label="会社名" required>
            <Input {...getFieldProps('accountName')} />
          </Form.Item>

          <Form.Item label="URL">
            <Input {...getFieldProps('webSite')} />
          </Form.Item>

          <Form.Item label="部署名">
            <Input {...getFieldProps('section')} />
          </Form.Item>

          <Form.Item label="役職">
            <Input {...getFieldProps('sectionPosition')} />
          </Form.Item>

          <Form.Item label="タグ">
            <Select
              mode="multiple"
              style={{width: '100%'}}
              allowClear={true}
              showArrow={true}
              onChange={(value) => setFieldValue('tagIds', value)}
              optionFilterProp="label">
              {prospectTags.map((tag) => (
                <Select.Option key={tag.id} value={tag.id} label={tag.name}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <TagColorSquare color={tag.color} />
                    <span>{tag.name}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="担当者">
            <Select
              showSearch
              optionFilterProp="children"
              value={values.assigneeId}
              onChange={(value) => setFieldValue('assigneeId', value)}>
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.lastName} {user.firstName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Body>

      <Action>
        <Button
          style={{width: '80px'}}
          type="primary"
          disabled={!isValid}
          loading={loading}
          onClick={() => handleSubmit()}>
          登録
        </Button>
      </Action>
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 20px;
`;

const Body = styled.div`
  .ant-form-item {
    margin-bottom: 18px;
  }

  .ant-form-item-label {
    padding: 0;

    label {
      color: var(--text-color-2);
      font-size: 10px;
    }
  }
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -15px;
  right: -20px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`;

const TagColorSquare = styled.span<{color: string}>`
  width: 14px;
  height: 14px;
  background: ${({color}) => color};
  border-radius: 2px;
  margin-right: 5px;
`;
