import React from 'react';
import styled from 'styled-components';
import {prospectStages} from 'helpers/prospectStages';
import moment from 'moment';
import {Select, DatePicker} from 'components/antd';
import {
  ProspectPool,
  useUsersByClientAllQuery,
  useAssignUserProspectPoolMutation,
  useUpdateLeadSourceProspectPoolMutation,
  useLeadSourcesQuery,
  useCurrentClientQuery,
  useClientPhaseProspectsQuery,
  UpdateStageProspectPoolMutationFn,
  useUpdateOriginalLeadSourceProspectPoolMutation,
  useUpdateOriginalInflowDateProspectPoolMutation,
  useUpdateLatestInflowDateProspectPoolMutation,
  useUpdatePhaseProspectPoolMutation,
} from 'api';
import useClientUser from 'hooks/useClientUser';
import PreleadTimeline from './PreleadTimeline';
import useProspectContext from '../useProspectContext';
import PreleadInformation from './PreleadInformation';
import PreleadLastAsignee from './PreleadLastAsignee';
import locale from 'antd/es/date-picker/locale/ja_JP';

interface Props {
  prospectPool: ProspectPool;
  updateStage: UpdateStageProspectPoolMutationFn;
}

export default ({prospectPool, updateStage}: Props) => {
  const {isMember, isAdmin} = useClientUser();
  const [assignUser] = useAssignUserProspectPoolMutation();
  const [updateLeadSource] = useUpdateLeadSourceProspectPoolMutation();
  const [updateOriginalLeadSource] =
    useUpdateOriginalLeadSourceProspectPoolMutation();
  const [updateOriginalInflowDate] =
    useUpdateOriginalInflowDateProspectPoolMutation();
  const [updateLatestInflowDate] =
    useUpdateLatestInflowDateProspectPoolMutation();
  const [updatePhase] = useUpdatePhaseProspectPoolMutation();
  const {poolId} = useProspectContext();
  const {data: {currentClient: {currentPreleadContract = {}} = {}} = {}} =
    useCurrentClientQuery();

  const dateFormat = 'YYYY/MM/DD';

  const isUserRoleCanListPreleadMaster =
    currentPreleadContract?.userRoleCanListPreleadMaster === 'member'
      ? isMember
      : isAdmin;

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    variables: {uuid: poolId},
  });

  const {data: {clientPhaseProspects = []} = {}} =
    useClientPhaseProspectsQuery();

  return (
    <Container>
      <Box>
        <h3>ステージ</h3>
        <CustomSelect
          size="small"
          style={{width: 120}}
          value={prospectPool?.stageText}
          onChange={(value: string) =>
            updateStage({
              variables: {
                uuid: prospectPool?.uuid,
                stage: value,
              },
            })
          }>
          {prospectStages.map((stage) => (
            <Select.Option value={stage.name} key={stage.id}>
              {stage.displayName}
            </Select.Option>
          ))}
        </CustomSelect>
        <h3 style={{marginTop: '20px'}}>フェーズ</h3>
        <CustomSelect
          size="small"
          value={
            prospectPool?.clientPhaseProspect?.id
              ? prospectPool.clientPhaseProspect.id
              : ''
          }
          onChange={(value: string) => {
            updatePhase({
              variables: {
                uuid: prospectPool?.uuid,
                clientPhaseProspectId: value,
              },
            });
          }}>
          <Select.Option value={''}>未選択</Select.Option>
          {clientPhaseProspects.map((phase) => (
            <Select.Option value={phase.id} key={phase.id}>
              {phase.name}
            </Select.Option>
          ))}
        </CustomSelect>
      </Box>
      <Box>
        <h3>リード担当者</h3>
        <CustomSelect
          size="small"
          showSearch
          optionFilterProp="children"
          value={
            prospectPool?.user
              ? prospectPool.user.lastName + ' ' + prospectPool.user.firstName
              : ''
          }
          onChange={(value: string) =>
            assignUser({
              variables: {uuid: prospectPool?.uuid, userUuid: value},
            })
          }
          disabled={!isMember}>
          <Select.Option value="">リード担当者なし</Select.Option>
          {users.map((user) => (
            <Select.Option value={user.uuid} key={user.uuid}>
              {user.lastName} {user.firstName}
            </Select.Option>
          ))}
        </CustomSelect>
      </Box>
      <Box>
        <h3>最新リードソース</h3>
        <CustomSelect
          size="small"
          value={prospectPool?.leadSource?.id ? prospectPool.leadSource.id : ''}
          onChange={(value: string) => {
            updateLeadSource({
              variables: {uuid: prospectPool?.uuid, leadSourceId: value},
            });
          }}
          disabled={!isMember}>
          {leadSources.map((leadSource) => (
            <Select.Option value={leadSource.id} key={leadSource.id}>
              {leadSource.name}
            </Select.Option>
          ))}
        </CustomSelect>
        <div className="mt-6">
          <h3 className="font-bold text-sm text-foreground-lighter leading-[17px]">
            最新流入日
          </h3>
          <div className="text-base font-medium">
            <DatePicker
              value={
                prospectPool?.latestInflowDate &&
                moment(prospectPool.latestInflowDate, dateFormat)
              }
              locale={locale}
              disabled={!isMember}
              disabledDate={(date) =>
                prospectPool?.originalInflowDate &&
                moment(prospectPool.originalInflowDate, dateFormat) > date
              }
              format={dateFormat}
              onChange={(date) => {
                const value = date?.format('YYYY/MM/DD') || '';
                updateLatestInflowDate({
                  variables: {
                    uuid: prospectPool?.uuid,
                    latestInflowDate: value,
                  },
                });
              }}
            />
          </div>
        </div>
      </Box>
      <Box>
        <h3>初回リードソース</h3>
        <CustomSelect
          size="small"
          value={
            prospectPool?.originalLeadSource?.id
              ? prospectPool.originalLeadSource.id
              : ''
          }
          onChange={(value: string) => {
            updateOriginalLeadSource({
              variables: {
                poolUuid: poolId,
                uuid: prospectPool?.uuid,
                originalLeadSourceId: value,
              },
            });
          }}
          disabled={!isMember}>
          {leadSources.map((leadSource) => (
            <Select.Option value={leadSource.id} key={leadSource.id}>
              {leadSource.name}
            </Select.Option>
          ))}
        </CustomSelect>
        <div className="mt-6">
          <h3 className="font-bold text-sm text-foreground-lighter leading-[17px]">
            初回流入日
          </h3>
          <div className="text-base font-medium">
            <DatePicker
              value={
                prospectPool?.originalInflowDate &&
                moment(prospectPool.originalInflowDate, dateFormat)
              }
              locale={locale}
              disabled={!isMember}
              disabledDate={(date) =>
                prospectPool?.latestInflowDate &&
                moment(prospectPool.latestInflowDate, dateFormat) < date
              }
              format={dateFormat}
              onChange={(date) => {
                const value = date?.format('YYYY/MM/DD') || '';
                updateOriginalInflowDate({
                  variables: {
                    uuid: prospectPool?.uuid,
                    originalInflowDate: value,
                  },
                });
              }}
            />
          </div>
        </div>
      </Box>
      {isUserRoleCanListPreleadMaster && (
        <Box>
          <PreleadInformation prospectPool={prospectPool} />
          <PreleadLastAsignee prospectPool={prospectPool} />
          <PreleadTimeline prospectPool={prospectPool} />
        </Box>
      )}
    </Container>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  width: 236px;
  height: 100vh;
  padding: 0 16px;
  background: #fff;
`;

const Box = styled.div`
  padding: 16px 0 20px 0;
  border-bottom: 1px solid var(--border-color);
  > h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-3);
  }
  > h4 {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-3);
    margin-bottom: 0;
  }
  h4 + div {
    font-size: 14px;
    color: var(--text-color-1);
    margin-bottom: 10px;
  }
`;

const CustomSelect = styled(Select)`
  &&& {
    width: 190px;
    span {
      font-size: 12px;
      color: var(--text-color-1);
    }
  }
`;
