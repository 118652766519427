import React from 'react';
import {Popover} from 'components/antd';
import {useHistory} from 'react-router-dom';
import {
  useCurrentUserQuery,
  useCurrentClientQuery,
  useSignoutMutation,
} from 'api';
import {useApolloClient} from '@apollo/client';
import styled from 'styled-components';
import {UserMaru} from 'components/Ui/Icon';

export default () => {
  const history = useHistory();
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: {currentClient = {}, currentClient: {currentMailContract} = {}} = {},
  } = useCurrentClientQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [signout] = useSignoutMutation();
  const client = useApolloClient();
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
  const mailRemainingCount: string = currentMailContract?.limit
    ? (currentMailContract.limit - currentMailContract.count).toLocaleString()
    : '-';
  const mailLimit: string = currentMailContract?.limit
    ? currentMailContract.limit.toLocaleString()
    : '-';

  return (
    <Popover
      placement={'bottomRight'}
      content={
        <PopoverContentsWrapper>
          <PopoverMailContent>
            <div>フォーム送信</div>
            <div>
              残り {mailRemainingCount} / {mailLimit}
            </div>
          </PopoverMailContent>
          <PopoverContent
            onClick={() => {
              signout().then(() => {
                history.push('/');
                client.resetStore();
              });
            }}>
            ログアウト
          </PopoverContent>
        </PopoverContentsWrapper>
      }
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={(visible) => setIsPopoverVisible(visible)}>
      <UserInfo>
        <div>
          <div>{currentClient.name}</div>
          <div>{[currentUser.lastName, currentUser.firstName].join(' ')}</div>
        </div>
        <UserIconWrapper>
          <UserMaru />
        </UserIconWrapper>
      </UserInfo>
    </Popover>
  );
};

const PopoverContentsWrapper = styled.div`
  width: 206px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const PopoverMailContent = styled.div`
  padding-left: 12px;
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 63px;

  > div:first-child {
    font-weight: 500;
    font-size: 10px;
    color: var(--text-color-2);
    margin-bottom: 8px;
  }

  > div:last-child {
    font-weight: 500;
    font-size: 14px;
  }
`;

const PopoverContent = styled.div`
  cursor: pointer;
  line-height: 50px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 12px;

  &:hover {
    background: #f3f8fc;
  }
`;

const UserIconWrapper = styled.div`
  margin-left: 10px;
  width: 38px;
  height: 38px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding-left: 22px;
  margin: 0 10px 0 22px;
  border-left: 1px solid #e1e6eb;
  color: var(--text-color-2);
  font-weight: 500;
  cursor: pointer;
`;
