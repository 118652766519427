import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, StageChangeProspectActivity} from 'api';

interface StageChangeAcvitity extends ProspectActivity {
  resource: StageChangeProspectActivity;
}

interface Props {
  prospectActivity: StageChangeAcvitity;
}

const StageChangeActivityComponent = ({prospectActivity}: Props) => {
  const {resource, user} = prospectActivity;
  const {stageText} = resource;

  return (
    <>
      <Icon />
      <Content>
        <span>ステージ：{stageText}</span>
        <span>
          {user.lastName} {user.firstName}
        </span>
        <time>
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  margin-top: -4px;
  margin-left: 3px;

  span:first-child {
    flex: 1;
    margin: 0;
    color: var(--text-color-1);
    font-weight: 500;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;

export default StageChangeActivityComponent;
