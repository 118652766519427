import {
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {
  useLeadSourceReportsQuery,
  useLeadSumReportQuery,
  usePoolQuery,
  ProspectReportLeadSouceReport,
} from 'api';
import React from 'react';
import {Link} from 'react-router-dom';

const useTableData = () => {
  const headers: ReportTableHeader[] = ['リードソース', ''];
  const {data: {leadSourceReports = []} = {}} = useLeadSourceReportsQuery({});
  const {data: {leadSumReport = null} = {}} = useLeadSumReportQuery({});
  const {data: {pool = {}} = {}} = usePoolQuery({});

  const LinkProspects = leadSumReport?.values.map(
    (value, index) =>
      pool &&
      value && (
        <Link to={`/pools/${pool.uuid}/prospects`} key={index} target="_blank">
          {value}
        </Link>
      ),
  );

  const sumReportData = React.useMemo(() => {
    if (!leadSumReport) {
      return;
    }
    return {
      label: leadSumReport.name,
      values: LinkProspects,
      callProps: {
        style: {
          borderBottom: 'double 3px var(--text-color-2)',
        },
      },
    };
  }, [leadSumReport, pool]);

  const LinkProspectsLeadSource = (report: ProspectReportLeadSouceReport) =>
    report.values.map(
      (value, index) =>
        pool &&
        value && (
          <Link
            to={`/pools/${pool.uuid}/prospects?leadSourceIds=${report.id ?? 0}`}
            target="_blank"
            key={index}>
            {value}
          </Link>
        ),
    );
  const reportData = React.useMemo(() => {
    return leadSourceReports.map((report) => {
      return {
        label: report.name,
        values: LinkProspectsLeadSource(report),
      };
    });
  }, [leadSourceReports, pool]);

  const data: ReportTableData[] = [sumReportData, ...reportData].filter(
    Boolean,
  );
  return {headers, data};
};

export default useTableData;
