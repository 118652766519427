import React from 'react';

type Props = {
  title: string;
  score: number;
  hasSetting?: boolean;
  target?: boolean;
};

const ScoreItem = ({
  title,
  score,
  hasSetting = false,
  target = true,
}: Props) => {
  const NotTarget = () => {
    return (
      <span className="text-c-lighter font-bold text-xs mr-8">対象外</span>
    );
  };

  const HasNoSetting = () => {
    return (
      <span className="text-c-lighter font-bold text-xs mr-8">未設定</span>
    );
  };

  const Score = ({score}: {score: number}) => {
    return (
      <div className="flex items-center gap-1 !mr-0">
        <div className="flex gap-[1px] items-center">
          <span
            className={`${score >= 1 ? 'bg-[var(--score-green-foreground)]' : 'bg-gray-200'} h-[6px] w-4 rounded-l-full`}
          />
          <span
            className={`${score >= 2 ? 'bg-[var(--score-green-foreground)]' : 'bg-gray-200'} h-[6px] w-4`}
          />
          <span
            className={`${score == 3 ? 'bg-[var(--score-green-foreground)]' : 'bg-gray-200'} h-[6px] w-4 rounded-r-full`}
          />
        </div>
        <span className="font-bold text-xs w-4">{score}</span>
      </div>
    );
  };

  const Status = () => {
    if (!target) {
      return <NotTarget />;
    } else if (!hasSetting) {
      return <HasNoSetting />;
    }
    return <Score score={score} />;
  };

  return (
    <div className="flex justify-between items-center w-full !mr-0">
      <h4 className="text-xs">{title}</h4>
      <Status />
    </div>
  );
};

export default ScoreItem;
