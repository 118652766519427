import {Button, Form, Input, InputNumber, Radio} from 'antd';
import {useCreateTaskStepMutation} from 'api';
import {useFormik} from 'formik';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

interface Props {
  onFinish: () => void;
}

export default ({onFinish}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const [createTaskStep, {loading}] = useCreateTaskStepMutation({
    refetchQueries: ['workflow'],
  });
  const formik = useFormik({
    initialValues: {
      name: 'タスク',
      memo: '',
      scheduleType: 'period',
      startDay: 3,
      isImmediately: false,
    },
    onSubmit: (values, {resetForm}) => {
      if (values.scheduleType === 'isImmediately') {
        values.isImmediately = true;
        values.startDay = null;
      } else {
        values.isImmediately = false;
      }

      delete values.scheduleType;

      createTaskStep({
        variables: {workflowUuid: workflowId, attributes: values},
      });
      values['scheduleType'] = 'period';
      resetForm();
      onFinish();
    },
  });

  return (
    <Container>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item label="ステップ名">
          <Input {...formik.getFieldProps('name')} />
        </Form.Item>
        <Form.Item label="実行タイミング" style={{marginBottom: '0'}}>
          <Radio.Group {...formik.getFieldProps('scheduleType')}>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value={'period'} />
              <InputNumber
                min={1}
                style={{width: '6rem', marginRight: '0.5rem'}}
                value={formik.values.startDay}
                onChange={(value) => formik.setFieldValue('startDay', value)}
                disabled={formik.values.scheduleType === 'isImmediately'}
              />
              日後に実行
            </Form.Item>
            <Form.Item>
              <Radio value={'isImmediately'} />
              即時実行
            </Form.Item>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="メモ">
          <Input.TextArea {...formik.getFieldProps('memo')} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
            loading={loading}>
            登録
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item-label label {
    font-weight: bold;
  }

  p {
    color: var(--text-color-2);
  }

  .ant-input-number {
    width: 46px !important;
    height: 35px !important;

    input {
      text-align: center;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;
