import gql from 'graphql-tag';
import taskProspectFragment from './task/prospect';
import taskPreleadFragment from './task/prelead';

export default gql`
  fragment taskFragment on Task {
    id
    uuid
    status
    statusText
    priority
    priorityText
    title
    dueDate
    remindAt
    description
    descriptionHtml
    userId
    taskType
    taskTypeText
    taskableId
    taskableType
    salesforceTaskId
    taskable {
      ... on TaskProspect {
        ...taskProspectFragment
      }
      ... on TaskPrelead {
        ...taskPreleadFragment
      }
    }
  }
  ${taskProspectFragment}
  ${taskPreleadFragment}
`;
