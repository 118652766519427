import React from 'react';
import styled from 'styled-components';
import useSearchParams from './useSearchParams';
import {usePoolTaskCountsQuery} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {query, setQuery, searchParams} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();
  const {
    data: {
      poolTaskCounts: {currentUserCount = 0, noAssigneeCount = 0, sum = 0} = {},
    } = {},
  } = usePoolTaskCountsQuery({
    variables: {
      poolUuid: poolId,
      search: searchParams,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <Item
        onClick={() => setQuery({assignee: 'current_user', page: 1})}
        current={query.assignee === 'current_user'}>
        <div>
          <em>{currentUserCount}</em>
          <span>MY ToDo</span>
        </div>
      </Item>
      <Item
        onClick={() => setQuery({assignee: 'none', page: 1})}
        current={query.assignee === 'none'}>
        <div>
          <em>{noAssigneeCount}</em>
          <span>未割り当て</span>
        </div>
      </Item>
      <Item
        onClick={() => setQuery({assignee: 'all', page: 1})}
        current={query.assignee === 'all'}>
        <div>
          <em>{sum}</em>
          <span>すべて</span>
        </div>
      </Item>
    </Container>
  );
};

const Container = styled.div`
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
  width: 100%;

  & > * {
    display: table-cell;
    width: 100px;
    height: 60px;
    padding: 0.5rem 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e1e6eb;

      em {
        font-style: normal;
        font-size: 18px;
        font-weight: bold;
      }

      span {
        font-size: 12px;
        font-weight: medium;
      }
    }

    &:first-child {
      div {
        border: none;
      }
    }
  }
`;

const Item = styled.div<{current?: boolean}>`
  color: ${({current}) => (current ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  cursor: pointer;
`;
