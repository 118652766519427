import React from 'react';
import styled from 'styled-components';
import usePools from 'hooks/usePools';
import {Sync} from 'components/Ui/Icon';
import {
  useImportSalesforceLeadsMutation,
  useCurrentClientQuery,
  useCurrentUserQuery,
} from 'api';
import {Tooltip, message} from 'components/antd';

export default ({title}: {title: string}) => {
  const {selectedPool} = usePools();

  const [ImportSalesforceLeads] = useImportSalesforceLeadsMutation({
    onCompleted: ({importSalesforceLeads: {success}}) => {
      if (!success) {
        message.error(
          'Salesforceへログインできませんでした。Salesforce連携の設定を確認してください。',
        );
      }
    },
  });

  const {data: {currentClient: {currentLeadContract = {}} = {}} = {}} =
    useCurrentClientQuery({fetchPolicy: 'cache-and-network'});

  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  return (
    <Container>
      <Title>{title}</Title>

      <Pool>
        <Wrapper>
          {currentUser?.role === 'admin' &&
            selectedPool?.client.salesforceActive &&
            currentLeadContract?.sfaSynchronizable && (
              <>
                <Tooltip placement="bottom" title="Salesforceと同期">
                  <SvgWrap
                    onClick={() => {
                      message.success('同期を開始します');
                      ImportSalesforceLeads({
                        variables: {
                          poolUuid: selectedPool?.uuid,
                        },
                      });
                    }}>
                    <Sync />
                  </SvgWrap>
                </Tooltip>
              </>
            )}
        </Wrapper>
      </Pool>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  margin: 1rem 0;
  font-size: 20px;
  font-weight: bold;
`;

const Pool = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    path {
      stroke: var(--text-color-1);
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SvgWrap = styled.div`
  height: 24px;
  cursor: pointer;
`;
