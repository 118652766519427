import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {ProjectGroup, useUpdateProjectGroupMutation} from 'api';

type Props = {
  projectGroup: ProjectGroup;
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

export default ({projectGroup, isOpen, setModal}: Props) => {
  const [updateProjectGroup, {loading}] = useUpdateProjectGroupMutation({
    refetchQueries: ['projectGroups', 'currentUser'],
    onCompleted: () => {
      setModal(false);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '10px',
          width: '518px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
          overflowY: 'scroll',
          // maxHeight: '',
        },
      }}
      contentLabel="modal">
      <ModalHeader>
        <h2>Privateに変更しますか？</h2>
      </ModalHeader>
      <ModalBody>
        <span>この変更は元に戻すことができません。</span>
        <Button
          type="primary"
          danger
          disabled={projectGroup.isPrivate}
          loading={loading}
          onClick={() =>
            updateProjectGroup({
              variables: {
                uuid: projectGroup.uuid,
                attributes: {isPrivate: true},
              },
            })
          }>
          Privateに変更
        </Button>
      </ModalBody>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  padding: 2rem 3rem 0;
  position: relative;

  h2 {
    font-weight: bold;
    font-size: 17.5px;
  }

  button {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    box-shadow: none;
    border: none;
    display: flex;
    svg {
      margin: auto 0;
      height: 16px;
      width: 25px;
      path {
        fill: var(--text-color-3);
      }
    }

    span {
      margin: auto 0;
      color: var(--text-color-3);
    }
  }
`;

const ModalBody = styled.div`
  padding: 0rem 3rem 2rem;
  display: flex;
  flex-direction: column;

  > * {
    &:nth-child(1) {
    }
    &:nth-child(2) {
      margin-left: auto;
      margin-top: 31px;
      width: 128px;
    }
  }
`;
