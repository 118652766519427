import React from 'react';

function Icon({color = 'var(--text-color-3)'}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99992 18.3333H14.9999C15.9204 18.3333 16.6666 17.5871 16.6666 16.6666V8.19032C16.6666 7.74828 16.491 7.32437 16.1784 7.0118L11.3214 2.15478C11.0088 1.84222 10.5849 1.66663 10.1429 1.66663H4.99992C4.07944 1.66663 3.33325 2.41282 3.33325 3.33329V16.6666C3.33325 17.5871 4.07944 18.3333 4.99992 18.3333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 2.08337V7.50004H15.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 14.1666H12.5001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 10.8334H12.5001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 7.5H7.50008"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
