import React from 'react';
import styled from 'styled-components';
import CallCount from './CallCount';
import PreleadCount from './PreleadCount';
import {useAllProjectsTelStatusCallReportQuery} from 'api';
import useSearchParams from '../useSearchParams';
import {Link, Route, Switch} from 'react-router-dom';
import CallReport from './CallReport';
import ConversionRateReport from './ConversionRateReport';
import CallRateReport from './CallRateReport';
import PreleadReport from './PreleadReport';

export default () => {
  const {searchParams} = useSearchParams();
  const {data: {allProjectsTelStatusCallReport = {}} = {}} =
    useAllProjectsTelStatusCallReportQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'network-only',
    });

  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('/call')}>
          <Link to={`/report/call/all/call${location.search}`}>コール数</Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/prelead')}>
          <Link to={`/report/call/all/prelead${location.search}`}>
            プレリード数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/conversion_rate')}>
          <Link to={`/report/call/all/conversion_rate${location.search}`}>
            転換率
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/call_rate')}>
          <Link to={`/report/call/all/call_rate${location.search}`}>
            対コール率
          </Link>
        </TabItem>
      </Tabs>

      <div style={{display: 'flex', gap: '24px'}}>
        <CallCount
          allProjectsTelStatusCallReport={allProjectsTelStatusCallReport}
        />
        <PreleadCount />
      </div>

      <div>
        <Switch>
          <Route path="/report/call/all/call" component={CallReport} />
          <Route path="/report/call/all/prelead" component={PreleadReport} />
          <Route
            path="/report/call/all/conversion_rate"
            component={ConversionRateReport}
          />
          <Route path="/report/call/all/call_rate" component={CallRateReport} />
        </Switch>
      </div>
    </Container>
  );
};

const Container = styled.div`
  > div:nth-child(1) {
    margin-bottom: 28px;
  }

  > div:nth-child(2) {
    margin-bottom: 28px;
    display: flex;
  }

  > div:nth-child(3) {
  }
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
