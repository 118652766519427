import React from 'react';
import { Button } from 'components/antd';
import {
  useUpdateMailCampaignMutation,
  useCancelBlastengineBatchMutation,
  useCurrentMailCampaignContractQuery,
  MailCampaign,
} from 'api';

interface Props {
  mailCampaign: MailCampaign,
}

export default ({ mailCampaign }: Props) => {
  const [updateMailCampaign] = useUpdateMailCampaignMutation();
  const [cancelBlastengineBatch] = useCancelBlastengineBatchMutation();

  const { data: { currentMailCampaignContract = {} } = {} } =
    useCurrentMailCampaignContractQuery({
      fetchPolicy: 'cache-and-network',
    });

  const canUseBlastengine = mailCampaign?.target === 'preleads' && currentMailCampaignContract?.canSendBlastengine;
  const cancellableStatuses = ['ready', 'blastengine_ready'];

  const cancel = () => {
    const userConfirmed = confirm(
      '送信予約がキャンセルされ、内容の編集が可能となります。よろしいですか？',
    )
    if (!userConfirmed) return;

    if (canUseBlastengine) {
      cancelBlastengineBatch({
        variables: {
          uuid: mailCampaign.uuid,
        },
      })
    } else {
      updateMailCampaign({
        variables: {
          uuid: mailCampaign.uuid,
          attributes: {
            status: 'draft',
          },
        },
      })
    }
  };

  return (
    cancellableStatuses.includes(mailCampaign?.status) && (
      <Button type="primary" danger onClick={cancel}>
        送信予約をキャンセルする
      </Button>
    )
  );
};
