import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {TimelineBadge} from 'components/Ui/Icon';
import {Activity, StatusChangeActivity} from 'api';
import preleadColor from 'helpers/preleadColor';
import preleadCategoryColor from 'helpers/preleadCategoryColor';
import useProjectName from './useProjectName';
import UserName from './UserName';

interface StatusChange extends Activity {
  resource: StatusChangeActivity;
}

interface Props {
  activity: StatusChange;
}

const StatusChangeActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);
  const channel = activity.resource.channel;
  return (
    <>
      {channel === 'prelead_project' && projectName && (
        <Project>{projectName}</Project>
      )}
      <Summary>
        <BadgeWrapper>
          <TimelineBadge />
        </BadgeWrapper>
        <UserName activity={activity} />
        {activity.resource.clientPreleadStatus ? (
          <StatusLabel
            color={preleadCategoryColor(
              activity.resource.clientPreleadStatus.category,
            )}>
            {activity.resource.clientPreleadStatus.name}
          </StatusLabel>
        ) : activity.resource.status ? (
          <StatusLabel color={preleadColor(activity.resource.status)}>
            {activity.resource.statusText}
          </StatusLabel>
        ) : (
          <StatusLabel color={preleadCategoryColor('')}>未</StatusLabel>
        )}
        <span>{moment(activity.createdAt).format('MM/DD HH:mm')}</span>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  align-items: center;
  width: 480px;
  height: 30px;
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  color: var(--text-color-2);
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

const StatusLabel = styled.span<{color: string}>`
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid var(--text-color-3)'};
  color: ${({color}) => (color ? 'white' : 'var(--text-color-3)')};
  border-radius: 20px;
  margin: 0 12px;
  padding: 4px 16px;
  font-size: 10px;
  height: 22px;
`;

export default StatusChangeActivityComponent;
