import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {useHistory} from 'react-router';

export default () => {
  const history = useHistory();

  return (
    <Container>
      <Menu />
      <Content>
        <Title>プラン</Title>
        <Body>
          {/* <PlanWrapper>
            <div>
              <PlanTitle>契約中のプラン</PlanTitle><PlanName>Starter</PlanName>
            </div>
            <ChangePlanButton
              type="button"
              onClick={() => history.push('/settings/plan/change')}>
              <span>プランを変更する</span>
            </ChangePlanButton>
          </PlanWrapper> */}
          {/* <Border /> */}
          <PlanDetail>
            <tbody>
              {/* <DetailRow>
                <DetailTitle>アカウント数</DetailTitle><DetailBody>2 / 3</DetailBody>
              </DetailRow> */}
              <DetailRow>
                <DetailTitle>自動送信メール件数</DetailTitle>
                <DetailBody>1,500 / 5,000</DetailBody>
              </DetailRow>
            </tbody>
          </PlanDetail>
        </Body>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background: #fff;
  height: calc(100vh - 60px);
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 45px 45px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;

const Body = styled.div`
  padding 36px 44px;
`;

const PlanWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlanTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
`;

const PlanName = styled.span`
  padding-left: 64px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;

const Border = styled.hr`
  margin-top: 32px;
  width: 100%;
  color: var(--text-color-3);
`;

const ChangePlanButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: none;
  outline: 0;
  background: var(--primary);
  border-radius: 4px;

  span {
    display: block
    font-size: 12px;
    line-height: 17px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }
`;

const PlanDetail = styled.table`
  margin-top: 32px;
  table-layout: fixed;
  width: 400px;
`;

const DetailRow = styled.tr`
  height: 40px;
`;

const DetailTitle = styled.th`
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
`;

const DetailBody = styled.td`
  text-align: right;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);

  &:after {
    margin-left: 4px;
    content: '（月）';
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.15px;
    color: var(--text-color-2);
  }
`;
