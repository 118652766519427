const tagColors = [
  {hex: '#F37474', fontColor: '#fff'},
  {hex: '#E6613D', fontColor: '#fff'},
  {hex: '#E8C374', fontColor: '#fff'},
  {hex: '#5CB66A', fontColor: '#fff'},
  {hex: '#47938A', fontColor: '#fff'},
  {hex: '#4795F0', fontColor: '#fff'},
  {hex: '#4861E4', fontColor: '#fff'},
  {hex: '#5943FF', fontColor: '#fff'},
  {hex: '#F9B9B9', fontColor: 'var(--text-color-1)'},
  {hex: '#F2B09E', fontColor: 'var(--text-color-1)'},
  {hex: '#F3E1B9', fontColor: 'var(--text-color-1)'},
  {hex: '#ADDAAE', fontColor: 'var(--text-color-1)'},
  {hex: '#A3C9C4', fontColor: 'var(--text-color-1)'},
  {hex: '#A3CAF8', fontColor: 'var(--text-color-1)'},
  {hex: '#A4B0F2', fontColor: 'var(--text-color-1)'},
  {hex: '#ACA1FF', fontColor: 'var(--text-color-1)'},
];

const getTagFontColor = (color: string): string =>
  tagColors.find(
    (tagColor) => tagColor.hex.toLowerCase() === color.toLowerCase(),
  )?.fontColor;

export {tagColors, getTagFontColor};
