import React from 'react';
import styled from 'styled-components';
import {Popover} from 'components/antd';
import ColumnPopover from './ColumnPopover';

export default () => {
  return (
    <PopoverButton
      content={<ColumnPopover />}
      placement="bottom"
      trigger="click">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M0.833984 10.0007C0.833984 10.0007 4.16732 3.33398 10.0007 3.33398C15.834 3.33398 19.1673 10.0007 19.1673 10.0007"
            stroke="var(--text-color-3)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.833984 10C0.833984 10 4.16732 16.6667 10.0007 16.6667C15.834 16.6667 19.1673 10 19.1673 10"
            stroke="var(--text-color-3)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
            stroke="var(--text-color-3)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span>表示項目</span>
    </PopoverButton>
  );
};

const PopoverButton = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
  }

  &.active {
    span {
      color: var(--primary);
    }
  }
`;
