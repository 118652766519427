import React from 'react';

const Icon = ({className = ''}) => (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M19.6315 3.3682C17.4595 1.19622 14.5715 0 11.4999 0C8.42817 0 5.54031 1.19616 3.36826 3.3682C1.19622 5.54031 0 8.42823 0 11.4999C0 14.5717 1.19622 17.4596 3.36832 19.6317C5.54037 21.8037 8.42823 22.9999 11.5 22.9999C14.5717 22.9999 17.4595 21.8037 19.6317 19.6317C21.8037 17.4596 23 14.5717 23 11.4999C22.9999 8.42823 21.8036 5.54031 19.6315 3.3682ZM18.8924 4.10746C20.867 6.0821 21.9544 8.70747 21.9544 11.4999H19.5848C19.5848 7.04191 15.958 3.41508 11.5 3.41508C11.2355 3.41508 10.974 3.42827 10.7159 3.4532V1.07425C10.9755 1.05513 11.237 1.04545 11.5 1.04545C14.2924 1.04545 16.9177 2.13289 18.8924 4.10746ZM11.4999 18.5393C7.61843 18.5393 4.46059 15.3815 4.46059 11.5C4.46059 7.61848 7.61843 4.46059 11.4999 4.46059C15.3814 4.46059 18.5393 7.61843 18.5393 11.4999C18.5393 15.3814 15.3814 18.5393 11.4999 18.5393Z"
          fill="var(--text-color-3)"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

export default Icon;
