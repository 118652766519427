import React from 'react';

type Props = {
  currentSegment: string;
  setSegment: React.Dispatch<React.SetStateAction<string>>;
  counts: number[];
};

export default ({currentSegment, setSegment, counts}: Props) => {
  const segments = ['対象', '対象外'];

  return (
    <div className="rounded-md border inline-block border-[#E1E6EB] text-sm">
      {segments.map((segment, index) => {
        let buttonClassName =
          'w-24 py-1 font-medium whitespace-nowrap cursor-pointer';
        buttonClassName +=
          currentSegment === segment
            ? ' bg-white text-foreground'
            : ' text-foreground-lighter';
        buttonClassName += index === 0 ? ' rounded-l-md' : ' rounded-r-md';

        let badgeClassName =
          'inline-flex items-center justify-center w-[18px] h-[16px] ms-2 text-xs font-semibold rounded-full';
        badgeClassName +=
          currentSegment === segment
            ? ' text-foreground bg-[#D9DCDF]'
            : ' text-foreground-lighter bg-[#D9DCDF]';

        return (
          <button
            key={segment}
            type="button"
            className={buttonClassName}
            onClick={() => setSegment(segment)}>
            {segment}
            <span className={badgeClassName}>{counts[index]}</span>
          </button>
        );
      })}
    </div>
  );
};
