import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, ManualMailGmailSendProspectActivity} from 'api';
import {
  ManualMail,
  CsvFile,
  Link,
  PhotoImagePicture,
  PdfFile,
  WordFile,
  ExcelFile,
} from 'components/Ui/Icon';

interface ManualMailGmailSendActivity extends ProspectActivity {
  resource: ManualMailGmailSendProspectActivity;
}

interface Props {
  prospectActivity: ManualMailGmailSendActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      manualMail: {subject, from, to, cc, bcc, bodyHtml, mailAttachments = []},
      stageText,
      createdAt,
    },
    user,
    action,
  } = prospectActivity;

  const [showAll, setShowAll] = React.useState(false);

  const extensionIcon = (extension: string) => {
    switch (extension) {
      case 'csv':
        return <CsvFile />;
      case 'jpg':
        return <PhotoImagePicture />;
      case 'pdf':
        return <PdfFile />;
      case 'docs':
        return <WordFile />;
      case 'xlsx':
        return <ExcelFile />;
      default:
        return <Link />;
    }
  };

  return (
    <>
      <ManualMail active={true} width="32" height="32" />
      <Content>
        <ContentHeader>
          <h3>{action?.step?.name}</h3>
          <span>{user && [user.lastName, user.firstName].join(' ')}</span>
          <time>{moment(createdAt).format('YYYY/MM/DD HH:mm')}</time>
        </ContentHeader>
        <ContentBox>
          <Subject>{subject}</Subject>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Stage>{stageText}</Stage>
            <Button onClick={() => setShowAll(!showAll)}>
              {showAll ? '閉じる' : '内容を表示'}
            </Button>
          </div>

          {showAll && (
            <Detail>
              <ItemWrapper>
                <Item>
                  <span>From</span>
                  <span>{from}</span>
                </Item>
                <Item>
                  <span>To</span>
                  <span>{to}</span>
                </Item>
                <Item>
                  <span>Cc</span>
                  {cc.map((cc, i) => (
                    <span key={i}>{cc}</span>
                  ))}
                </Item>
                <Item>
                  <span>Bcc</span>
                  {bcc.map((bcc, i) => (
                    <span key={i}>{bcc}</span>
                  ))}
                </Item>
              </ItemWrapper>

              <div dangerouslySetInnerHTML={{__html: bodyHtml}} />

              {mailAttachments.length > 0 && (
                <FilesWrapper>
                  <div>
                    {mailAttachments.length}
                    個の添付ファイル
                  </div>
                  {mailAttachments.map((mailAttachment) => (
                    <File key={mailAttachment.uuid}>
                      {extensionIcon(mailAttachment.extension)}
                      <span
                        onClick={() =>
                          (window.location.href = `/manual_mail_attachments/${mailAttachment.uuid}/export`)
                        }>
                        {mailAttachment.filename}
                      </span>
                    </File>
                  ))}
                </FilesWrapper>
              )}
            </Detail>
          )}
        </ContentBox>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const Subject = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 15px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: var(--primary);
    font-weight: bold;
  }

  span,
  time {
    color: var(--text-color-2);
    margin-left: 15px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px var(--border-color);
  border-radius: 4px;
  padding: 15px;
`;

const Stage = styled.span`
  background: #dceeff;
  font-size: 10px;
  color: #2d9cdb;
  line-height: 20px;
  padding: 0 18px;
  border-radius: 2px;
`;

const Button = styled.div`
  color: var(--primary);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

const Detail = styled.div`
  margin-top: 15px;
  p {
    margin: 0px;
  }

  img {
    max-width: 100%;
  }
`;

const ItemWrapper = styled.div`
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
  padding: 10px 0;
  margin-bottom: 15px;
`;

const Item = styled.div`
  margin-top: 6px;

  span {
    &:first-child {
      display: inline-block;
      color: var(--text-color-2);
      width: 47px;
    }

    &:not(:first-child, :nth-child(2)) {
      display: block;
      margin-left: 47px;
    }
  }
`;

const FilesWrapper = styled.div`
  border-top: 1px solid #e1e6eb;
  margin-top: 10px;
  padding-top: 8px;
  div:first-child {
    font-size: 12px;
    color: var(--text-color-2);
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    color: var(--primary);
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;
