import {
  Breadcrumb,
  CreatePreleadModal,
  PreleadCsvUploadModal,
  PreleadTelActivityCsvUploadModal,
} from 'components/Ui';
import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {useProjectQuery, ProjectGroup, useCurrentClientQuery} from 'api';
import useProjectPath from 'hooks/useProjectPath';
import useProjectGroups from 'hooks/useProjectGroups';
import Title from './Title';

export default () => {
  const {projectId, projectGroupId, requestClientId, offeredClientId} =
    useParams<{
      projectId: string;
      projectGroupId: string;
      requestClientId: string;
      offeredClientId: string;
    }>();
  const history = useHistory();
  const {createPath} = useProjectPath();

  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      }
    },
  });

  const {accessibleProjectGroups: projectGroups} = useProjectGroups();

  const {
    data: {
      currentClient: {offeredClients = [], requestActiveClients = []} = {},
    } = {},
  } = useCurrentClientQuery();

  const projectGroup: ProjectGroup = React.useMemo(
    () =>
      projectGroups.find(
        (projectGroup) => projectGroup.uuid === projectGroupId,
      ),
    [projectGroupId, projectGroups],
  );

  const offeredClient = React.useMemo(
    () => offeredClients.find((client) => client.uuid === offeredClientId),
    [offeredClients],
  );
  const requestClient = React.useMemo(
    () =>
      requestActiveClients.find((client) => client.uuid === requestClientId),
    [requestClientId],
  );

  const [isCsvUploadModalVisible, setIsCsvUploadModalVisible] =
    React.useState(false);
  const [
    isCsvUploadModalWithInitializeDataVisible,
    setIsCsvUploadWithInitializeDataModalVisible,
  ] = React.useState(false);
  const [isCreatePreleadModalVisible, setIsCreatePreleadModalVisible] =
    React.useState(false);

  const [isPopoverVisible, setIsPopOverVisible] = React.useState(false);

  if (!project) return null;

  return (
    <Container>
      <Header>
        <Breadcrumb separator=">">
          {!location.pathname.startsWith('/list/projects') && (
            <>
              <Breadcrumb.Item>
                <Link
                  className="name"
                  to={
                    projectGroup
                      ? `/list/groups`
                      : offeredClient
                        ? `/list/share/request`
                        : `/list/share/offered`
                  }>
                  {projectGroup && 'グループ'}
                  {offeredClient && '依頼先'}
                  {requestClient && '依頼元'}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {projectGroup ? (
                  <Link to={createPath('basic_information')}>
                    {projectGroup.name}
                  </Link>
                ) : (
                  <Link to={createPath('projects')}>
                    {offeredClient && offeredClient.name}
                    {requestClient && requestClient.name}
                  </Link>
                )}
              </Breadcrumb.Item>
            </>
          )}
          <Breadcrumb.Item>
            <Link to={createPath('projects')}>営業リスト一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={createPath(`projects/${project.uuid}/project_summary`)}>
              {project.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>レポート</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Title
        setIsCsvUploadModalVisible={setIsCsvUploadModalVisible}
        setIsPopOverVisible={setIsPopOverVisible}
        isPopoverVisible={isPopoverVisible}
        project={project}
      />
      <Menu>
        <NavMenu>
          <NavItem active={!!location.pathname.includes('/preleads')}>
            <Link to={createPath(`projects/${projectId}/preleads`)}>
              プレリード
            </Link>
          </NavItem>
          <NavItem active={!!location.pathname.includes('/prelead_reports')}>
            <Link
              to={createPath(
                `projects/${projectId}/prelead_reports/items/tag`,
              )}>
              プレリードレポート
            </Link>
          </NavItem>
          <NavItem active={!!location.pathname.includes('/tel_reports')}>
            <Link to={createPath(`projects/${projectId}/tel_reports/all/call`)}>
              TELレポート
            </Link>
          </NavItem>
          <NavItem active={!!location.pathname.includes('/form_mail_reports')}>
            <Link
              to={createPath(
                `projects/${projectId}/form_mail_reports/all/send_count`,
              )}>
              フォーム送信レポート
            </Link>
          </NavItem>
        </NavMenu>
      </Menu>

      <PreleadCsvUploadModal
        project={project}
        visible={isCsvUploadModalVisible}
        onClose={() => setIsCsvUploadModalVisible(false)}
      />
      <PreleadTelActivityCsvUploadModal
        project={project}
        visible={isCsvUploadModalWithInitializeDataVisible}
        onClose={() => false}
      />
      <CreatePreleadModal
        project={project}
        visible={isCreatePreleadModalVisible}
        onClose={() => setIsCreatePreleadModalVisible(false)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'title'
    'menu';
  grid-template-rows: 70px 36px 50px;
  background: #f3f5f7;
  padding-left: 32px;
`;

const Header = styled.div``;

const Menu = styled.div`
  display: flex;
  font-weight: bold;
  color: var(--text-color-3);
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NavItem = styled.div<{active?: boolean}>`
  display: inline-block;

  a {
    padding-bottom: 15px;
    font-size: 14px;
    color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
    border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
  }
`;
