import React from 'react';
import styled from 'styled-components';
import {SalesforceAccount} from 'api';

interface Props {
  salesforceAccount: SalesforceAccount;
  onSelect: (salesforceAccount: SalesforceAccount) => void;
}

const AccountSelectComponent = ({salesforceAccount, onSelect}: Props) => {
  const address = `${salesforceAccount.billingState || ''}${
    salesforceAccount.billingCity || ''
  }${salesforceAccount.billingStreet || ''}`.trim();

  return (
    <AccountItem
      key={salesforceAccount.id}
      onClick={() => onSelect(salesforceAccount)}>
      <div>
        <strong>{salesforceAccount.name}</strong>
        <span>{salesforceAccount.website}</span>
      </div>
      <div>
        <span>{address || '住所不明'}</span>
        <span>{salesforceAccount.phone || '電話番号不明'}</span>
      </div>
    </AccountItem>
  );
};

const AccountItem = styled.div`
  font-size: 12px;
  color: var(--text-color-2);
  border-bottom: solid 1px #e1e6eb;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background: #f1f7fc;
  }

  strong,
  span {
    margin-right: 1rem;
  }

  strong {
    font-size: 14px;
    color: var(--text-color-1);
  }

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default AccountSelectComponent;
