import React from 'react';
import styled from 'styled-components';
import {useAllProjectsTelStatusCallReportQuery} from 'api';
import useSearchParams from '../../useSearchParams';

export default () => {
  const {searchParams} = useSearchParams();
  const {
    data: {
      allProjectsTelStatusCallReport: {
        call = {},
        reception = {},
        contact = {},
        appoint = {},
        document = {},
        other = {},
        callNg = {},
        receptionNg = {},
        contactNg = {},
      } = {},
      allProjectsTelStatusCallReport = {},
    } = {},
  } = useAllProjectsTelStatusCallReportQuery({
    variables: {
      search: searchParams,
    },
  });

  const totalCount = (object: any): any =>
    Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );

  const callCount = React.useMemo(() => {
    let count = 0;

    count += totalCount(call);
    count += totalCount(reception);
    count += totalCount(contact);
    count += totalCount(appoint);
    count += totalCount(document);
    count += totalCount(other);
    count += totalCount(callNg);
    count += totalCount(receptionNg);
    count += totalCount(contactNg);

    return count;
  }, [allProjectsTelStatusCallReport]);

  const contactCount = React.useMemo(() => {
    let count = 0;

    count += totalCount(contact);
    count += totalCount(appoint);
    count += totalCount(document);
    count += totalCount(contactNg);

    return count;
  }, [allProjectsTelStatusCallReport]);

  const conversionRate = (before: number, after: number) => {
    if (!before || !after) return 0;

    return Math.round((after / before) * 1000) / 10;
  };

  return (
    <Container>
      <Table>
        <div className="column far-left">
          <div className="category">
            <span>コール数</span>
          </div>
          <div className="category">
            <span>コール → キーマン接触</span>
          </div>
          <div className="category">
            <span>キーマン接触 → 資料送付</span>
          </div>
          <div className="category">
            <span>キーマン接触 → アポ獲得</span>
          </div>
        </div>

        <div className="column">
          <div>
            <div>{!!callCount && <span>{callCount}</span>}</div>
          </div>

          <div>
            <div className="top">
              {!!callCount && <span>{contactCount}</span>}
            </div>
            <div className="bottom">
              {!!callCount && (
                <span>{conversionRate(callCount, contactCount)}%</span>
              )}
            </div>
          </div>

          <div>
            <div className="top">
              {!!callCount && <span>{totalCount(document)}</span>}
            </div>
            <div className="bottom">
              {!!callCount && (
                <span>
                  {conversionRate(contactCount, totalCount(document))}%
                </span>
              )}
            </div>
          </div>

          <div>
            <div className="top">
              {!!callCount && <span>{totalCount(appoint)}</span>}
            </div>
            <div className="bottom">
              {!!callCount && (
                <span>
                  {conversionRate(contactCount, totalCount(appoint))}%
                </span>
              )}
            </div>
          </div>
        </div>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  // height: 303px;
  width: 321px;

  .column {
    display: flex;
    flex-direction: column;

    > div {
      width: 99px;
      min-height: 72px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-child {
        min-height: 43px;
        > span {
          margin: 0 auto;
        }
      }

      &:nth-child(2) {
      }

      > div {
        display: flex;
        width: 100%;
        height: 100%;
        color: var(--text-color-2);

        &.top {
        }

        &.bottom {
          background: #fbfcfd;
          span {
            font-weight: bold;
            color: var(--text-color-1);
          }
        }

        > span {
          margin: auto 6px auto auto;
        }
      }

      &.category {
        > span {
          color: var(--text-color-1);
          font-weight: bold;
        }
      }
    }

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 220px;
        display: flex;
        align-items: center;
        padding: 0 40px;

        background: #fff;

        &.category {
          padding: 0 26px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        > span {
          margin: auto auto auto 0;
          font-size: 13px;
        }
      }
    }
  }
`;
