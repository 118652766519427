import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <path
        d="M18.5127 12.7394V11.0953C18.73 10.9938 18.9139 10.8326 19.0432 10.6306C19.1724 10.4285 19.2416 10.1939 19.2427 9.95412V9.9164C19.2427 9.21714 18.6759 8.65028 17.9767 8.65028H17.9389C17.2396 8.65028 16.6728 9.21714 16.6728 9.9164V9.95412C16.6739 10.1939 16.7431 10.4285 16.8723 10.6306C17.0015 10.8326 17.1855 10.9938 17.4028 11.0953V12.7394C16.7783 12.8351 16.1902 13.094 15.698 13.49L11.1889 9.97786C11.221 9.86179 11.2378 9.74243 11.2397 9.62263C11.2402 9.34046 11.1571 9.06446 11.0008 8.82955C10.8444 8.59464 10.622 8.41137 10.3615 8.30292C10.101 8.19447 9.81414 8.16572 9.5373 8.2203C9.26045 8.27487 9.00601 8.41033 8.80616 8.60953C8.60631 8.80874 8.47003 9.06273 8.41456 9.3394C8.35908 9.61606 8.38691 9.90297 8.49451 10.1638C8.60211 10.4247 8.78466 10.6477 9.01906 10.8048C9.25347 10.9619 9.52919 11.046 9.81136 11.0463C10.0582 11.0452 10.3004 10.9793 10.5139 10.8552L14.9541 14.3104C14.1377 15.5435 14.1596 17.1507 15.0091 18.3614L13.6586 19.7122C13.5494 19.6773 13.4357 19.6588 13.3211 19.6572C12.6743 19.6578 12.1504 20.1823 12.1505 20.8291C12.1507 21.4758 12.675 22 13.3217 22.0002C13.9685 22.0003 14.493 21.4764 14.4935 20.8296C14.492 20.715 14.4734 20.6013 14.4385 20.4922L15.7745 19.1557C16.9682 20.0746 18.6077 20.1538 19.8845 19.3542C21.1612 18.5544 21.8055 17.0447 21.4996 15.5696C21.1937 14.0945 20.0021 12.9655 18.5127 12.7394V12.7394ZM17.9589 18.1469C17.7121 18.1535 17.4665 18.1106 17.2365 18.0208C17.0065 17.9309 16.7969 17.7959 16.62 17.6237C16.443 17.4514 16.3024 17.2455 16.2064 17.018C16.1104 16.7906 16.0609 16.5462 16.0609 16.2992C16.0609 16.0523 16.1104 15.8079 16.2064 15.5805C16.3024 15.353 16.443 15.1471 16.62 14.9748C16.7969 14.8026 17.0065 14.6676 17.2365 14.5777C17.4665 14.4879 17.7121 14.445 17.9589 14.4516C18.9536 14.4864 19.742 15.3026 19.7426 16.2979C19.743 17.2931 18.9554 18.1101 17.9608 18.1459"
        fill="var(--text-color-3)"
      />
    </svg>
  );
}

export default Icon;
