import React from 'react';
import {CustomizeItem, usePreleadQuery, useUpdatePreleadMutation} from 'api';
import {Input} from 'components/antd';
import _ from 'lodash';
import styled from 'styled-components';
import usePreleadContext from '../../usePreleadContext'

export default ({customizeItem}: {customizeItem: CustomizeItem}) => {
  const {preleadId} = usePreleadContext()

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const [value, setValue] = React.useState(null);
  const [isValidation, setIsValidation] = React.useState(false);

  const [updatePrelead] = useUpdatePreleadMutation();

  const updateCustomizeItemNumber = React.useCallback(() => {
    if (String(value).length <= 10) {
      const attributes: {[key: string]: any} = {};
      attributes[_.camelCase(customizeItem.columnName)] = value;
      updatePrelead({
        variables: {uuid: prelead?.uuid, attributes},
      });
    }
  }, [prelead, customizeItem, value]);

  const setCustomizeItemNumber = React.useCallback(
    (e: any) => {
      setValue(Number(e.target.value));
      if (String(e.target.value).length <= 10) {
        setIsValidation(false);
      } else {
        setIsValidation(true);
      }
    },
    [prelead, customizeItem],
  );

  React.useEffect(() => {
    //@ts-ignore
    setValue(prelead[_.camelCase(customizeItem.columnName)]);
  }, [prelead, customizeItem]);

  return (
    <Box>
      <InputNumber
        type="number"
        value={value}
        onChange={setCustomizeItemNumber}
        onBlur={updateCustomizeItemNumber}
      />
      {isValidation && (
        <ErrorMessage>10文字以内で数値を入力してください。</ErrorMessage>
      )}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
`;

const InputNumber = styled(Input)`
  border-color: transparent;

  &:hover {
    border-color: var(--border-color);
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 10px;
`;
