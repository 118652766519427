import React from 'react';
import styled from 'styled-components';
import {
  MailCampaignAttributes,
  useUpdateMailCampaignMutation,
  useMailCampaignQuery,
} from 'api';
import {useParams} from 'react-router';
import useDebounce from 'hooks/useDebounce';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input} from 'components/antd';

export default () => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const [update] = useUpdateMailCampaignMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bodyColor: mailCampaign.bodyColor,
      backgroundColor: mailCampaign.backgroundColor,
    },
    validationSchema: Yup.object().shape({
      bodyColor: Yup.string(),
      backgroundColor: Yup.string(),
    }),
    onSubmit: (values) => {
      update({
        variables: {
          uuid: mailCampaign.uuid,
          attributes: values,
        },
      });
    },
  });

  const debouncedValue = useDebounce<MailCampaignAttributes>(
    formik.values,
    1000,
  );

  React.useEffect(() => {
    formik.submitForm();
  }, [debouncedValue]);

  if (!mailCampaign) return null;

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item label="背景色">
          <Input
            type="color"
            value={formik.values.backgroundColor}
            disabled={mailCampaign.status !== 'draft'}
            onChange={(e) =>
              formik.setFieldValue('backgroundColor', e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label="文字の背景色">
          <Input
            type="color"
            value={formik.values.bodyColor}
            disabled={mailCampaign.status !== 'draft'}
            onChange={(e) => formik.setFieldValue('bodyColor', e.target.value)}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  label {
    font-size: 14px;
    color: var(--text-color-2);
    font-weight: 500;
  }
`;
