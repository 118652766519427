import React from 'react';
import styled from 'styled-components';
import {ArrowDown, LaunchLink} from 'components/Ui/Icon';
import {useSalesforceContactRecordTypeQuery, SalesforceContact} from 'api';
import {FieldValue} from './FieldValue';

interface Props {
  salesforceContact: SalesforceContact;
  url: string;
  connected: boolean;
}

export default ({salesforceContact, url, connected}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);

  const {data: {salesforceContactRecordType = {}} = {}} =
    useSalesforceContactRecordTypeQuery({
      variables: {
        salesforceContactId: salesforceContact.id,
      },
      skip: !salesforceContact,
    });

  const salesforceContactFields =
    salesforceContactRecordType?.salesforceContactFields || [];

  const salesforceContactDisplayFields = React.useMemo(() => {
    const displayFields = salesforceContactFields.filter(
      (salesforceContactField) => salesforceContactField.isDisplay === true,
    );

    return displayFields;
  }, [salesforceContactFields]);

  const filteredSalesforceContactFields = React.useMemo(() => {
    const filteredSalesforceContactFields = showMore
      ? salesforceContactDisplayFields
      : salesforceContactDisplayFields.slice(0, 5);

    return filteredSalesforceContactFields;
  }, [salesforceContactDisplayFields, showMore]);

  return (
    <Contact connected={connected}>
      <Header open={open} onClick={() => setOpen(!open)}>
        <Wrapper>
          <h2>
            {salesforceContact?.lastName} {salesforceContact?.firstName}
          </h2>
        </Wrapper>
        <ArrowDown />
      </Header>
      {open && (
        <>
          <Section />
          {salesforceContact?.salesforceContactRecordType && (
            <>
              <SalesforceField>
                <Label>レコードタイプ：</Label>
                <Type>
                  {salesforceContact?.salesforceContactRecordType?.name}
                </Type>
              </SalesforceField>
              {salesforceContact?.salesforceOwner && (
                <SalesforceField>
                  <Label>所有者： </Label>
                  <Type>
                    {salesforceContact.salesforceOwner.lastName}{' '}
                    {salesforceContact.salesforceOwner.firstName}
                  </Type>
                </SalesforceField>
              )}

              <Section />
            </>
          )}
          <Fields>
            {filteredSalesforceContactFields.map((field, i) => (
              <FieldValue
                key={i}
                field={field}
                salesforceContact={salesforceContact}
              />
            ))}
          </Fields>
          {salesforceContactDisplayFields.length > 5 && (
            <ShowMore
              showMore={showMore}
              onClick={() => setShowMore(!showMore)}>
              <ArrowDown color={'var(--primary)'} />
              <span>
                {showMore ? '一部の項目を表示' : 'すべての項目を表示'}
              </span>
            </ShowMore>
          )}
          <LinkToSalesforce
            href={`${url}/lightning/r/Contact/${salesforceContact?.contactId}/view`}
            target="_blank">
            <LaunchLink color={'var(--primary)'} />
            <span>Salesforceで見る</span>
          </LinkToSalesforce>
        </>
      )}
    </Contact>
  );
};

const Contact = styled.div<{connected: boolean}>`
  width: 100%;
  padding: 6px 12px;
  background: ${({connected}) => (connected ? '#FBFDFF' : '')};
  border: ${({connected}) =>
    connected ? '1px solid #AED8FF' : '1px solid #e1e6eb'};
  border-radius: 4px;
  margin-bottom: 16px;

  font-weight: 500;
`;

const Header = styled.div<{open: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
    letter-spacing: 0.15px;
    color: var(--text-color-1);
    margin-bottom: 0px;
  }
`;

const Section = styled.hr`
  border: 1px solid #e1e6eb;
`;

const SalesforceField = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: var(--text-color-2);
`;

const Type = styled.div`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
`;

const Fields = styled.div`
  margin-top: 16px;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 16px;
  margin-bottom: 16px;

  & > div {
    width: 60%;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--text-color-1);
  }

  & > div:first-child {
    width: 40%;
  }
`;

const ShowMore = styled.div<{showMore: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: pointer;
  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--primary);
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    transform: rotate(${({showMore}) => (showMore ? '180deg' : '0deg')});
  }
`;

const LinkToSalesforce = styled.a`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: var(--primary);
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
`;
