import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {
  Modal,
  Input,
  Checkbox,
  Form,
  DatePicker,
  Select,
  Radio,
  Button,
} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useProjectQuery,
  useCreateSalesforceDealPreleadContactMutation,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  useSalesforceAccountsQuery,
  useSalesforceDealRecordTypesQuery,
  useSalesforceContactsQuery,
  PreleadContact,
  SalesforceAccount,
  SalesforceDealField,
} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import AccountSelect from './AccountSelect';

interface Props {
  visible: boolean;
  onClose: () => void;
  preleadContact: PreleadContact;
}

interface FormikValues {
  createAccount: boolean;
  createContact: boolean;
  accountName: string;
  contactLastName: string;
  contactFirstName: string;
  contactEmail: string;
  contactTelephoneNumber: string;
  contactSection: string;
  contactSectionPosition: string;
  name: string;
  salesforceUserId: string;
  salesforceOpportunityStageId: string;
  closeDate: moment.Moment;
  salesforceAccountId: string;
  salesforceContactId: string;
  salesforceDealRecordTypeId: string;
  addition: {[key: string]: any};
}

const NewDealModalComponent = ({visible, onClose, preleadContact}: Props) => {
  const [showAccountSelect, setShowAccountSelect] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const [selectedRecordType, setSelectedRecordType] = React.useState(null);

  const {projectId} = useParams<{projectId: string}>();

  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const pool = project?.projectGroup?.pool;
  const prelead = preleadContact?.prelead;

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: pool?.uuid},
    skip: !pool,
  });

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: pool?.uuid},
      skip: !pool,
    });

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: pool?.uuid},
      skip: !pool,
    });

  const [create, {data, loading}] =
    useCreateSalesforceDealPreleadContactMutation({
      onCompleted: (data: any) => {
        if (!data.createSalesforceDealPreleadContact.error) {
          formik.resetForm();
          onClose();
        }
      },
      refetchQueries: ['preleadContacts'],
    });

  const today = React.useMemo(() => moment(), []);

  const initialValues: FormikValues = {
    createAccount: true,
    createContact: true,
    accountName: prelead?.name || '',
    contactLastName: preleadContact?.lastName || '',
    contactFirstName: preleadContact?.firstName || '',
    contactEmail: preleadContact?.email || '',
    contactTelephoneNumber: preleadContact?.telephoneNumber || '',
    contactSection: preleadContact?.section || '',
    contactSectionPosition: preleadContact?.sectionPosition || '',
    name: '',
    salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
    salesforceOpportunityStageId:
      salesforceOpportunityStages.length > 0
        ? salesforceOpportunityStages[0].id
        : '',
    closeDate: today,
    salesforceAccountId: '',
    salesforceContactId: '',
    salesforceDealRecordTypeId: '',
    addition: {},
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      createAccount: Yup.boolean(),
      accountName: Yup.string().when('createAccount', {
        is: true,
        then: (schema) =>
          schema.required(
            '新規取引先を入力、もしくは既存の取引先を選択してください',
          ),
      }),
      salesforceAccountId: Yup.string().when('createAccount', {
        is: false,
        then: (schema) =>
          schema.required(
            '新規取引先を入力、もしくは既存の取引先を選択してください',
          ),
      }),
      createContact: Yup.boolean(),
      contactLastName: Yup.string().when('createContact', {
        is: true,
        then: (schema) =>
          schema.required(
            '新規取引先責任者を入力、もしくは既存の取引責任者を選択してください',
          ),
      }),
      salesforceContactId: Yup.string().when('createContact', {
        is: false,
        then: (schema) =>
          schema.required(
            '新規取引責任者を入力、もしくは既存の取引責任者を選択してください',
          ),
      }),
      name: Yup.string().required('必須項目です'),
      salesforceUserId: Yup.string().required('必須項目です'),
      salesforceOpportunityStageId: Yup.string().required('必須項目です'),
      closeDate: Yup.date().required('必須項目です'),
    }),
    onSubmit: (values) => {
      const copy = Object.assign({}, values);
      if (copy.createAccount) {
        copy.salesforceAccountId = null;
      } else {
        copy.accountName = null;
      }
      delete copy.createAccount;

      if (copy.createContact) {
        copy.salesforceContactId = null;
      } else {
        copy.contactFirstName = null;
        copy.contactLastName = null;
      }
      delete copy.createContact;

      create({
        variables: {
          uuid: preleadContact.uuid,
          projectGroupUuid: project?.projectGroup.uuid,
          attributes: copy,
        },
      });
    },
  });

  const {data: {salesforceAccounts: {pagination = {totalCount: 0}} = {}} = {}} =
    useSalesforceAccountsQuery({
      variables: {
        uuid: pool?.uuid,
        page: 1,
        perPage: 20,
        search: {keyword: formik.values.accountName},
      },
    });

  const {data: {salesforceContacts = []} = {}} = useSalesforceContactsQuery({
    variables: {
      poolUuid: pool?.uuid,
      salesforceAccountId: formik.values.salesforceAccountId,
    },
    skip: !formik.values.salesforceAccountId,
    fetchPolicy: 'cache-and-network',
  });

  const onOk = React.useCallback(() => formik.submitForm(), []);

  const onCancel = React.useCallback(() => {
    formik.resetForm();
    setSelectedRecordType(null);
    onClose();
  }, []);

  const onAccountSelect = React.useCallback(
    (salesforceAccount: SalesforceAccount) => {
      formik.setFieldValue('createAccount', false);
      formik.setFieldValue('salesforceAccountId', salesforceAccount.id);
      setSelectedAccount(salesforceAccount);
      setShowAccountSelect(false);
    },
    [],
  );

  const onRecordTypeSelect = (id: string) => {
    const selectedRecordType = salesforceDealRecordTypes.find(
      (recordType) => recordType.id === id,
    );
    formik.setFieldValue('addition', {});
    setSelectedRecordType(selectedRecordType);
  };

  const salesforceDealFields = React.useMemo(() => {
    if (!selectedRecordType) {
      return [];
    }
    const visibleFields = selectedRecordType?.salesforceDealFields.filter(
      (salesforceDealField: SalesforceDealField) =>
        salesforceDealField.isDisplay === true,
    );

    return visibleFields;
  }, [selectedRecordType]);

  const dealFieldItem = (field: SalesforceDealField, index: number) => {
    return (
      <Form.Item key={index} label={field.label} required={field.isRequired}>
        {field?.fieldType === 'checkbox' ? (
          <Checkbox
            checked={formik.values.addition[`${field.fieldName}`] || false}
            onChange={(e) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = e.target.checked;
              formik.setFieldValue('addition', addition);
            }}
          />
        ) : field?.fieldType === 'date' ? (
          <Input
            type="date"
            value={formik.values.addition[`${field.fieldName}`]}
            style={{width: '200px'}}
            onChange={(e) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = e.target.value;
              formik.setFieldValue('addition', addition);
            }}
          />
        ) : field?.fieldType === 'select' ? (
          <Select
            value={formik.values.addition[`${field.fieldName}`]}
            onChange={(value) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = value;
              formik.setFieldValue('addition', addition);
            }}
            showSearch
            optionFilterProp="children">
            {field.salesforceDealFieldSelectOptions.map(
              (option) =>
                option?.isActive && (
                  <Select.Option key={option.id} value={option.value}>
                    {option.label}
                  </Select.Option>
                ),
            )}
          </Select>
        ) : field?.fieldType === 'reference' &&
          field?.referenceType === 'user' ? (
          <Select
            value={formik.values.addition[`${field.fieldName}`]}
            onChange={(value) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = value;
              formik.setFieldValue('addition', addition);
            }}
            showSearch
            optionFilterProp="children">
            {salesforceUsers.map((user) => (
              <Select.Option key={user.id} value={user.userId}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input
            value={formik.values.addition[`${field.fieldName}`]}
            onChange={(e) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = e.target.value;
              formik.setFieldValue('addition', addition);
            }}
          />
        )}
      </Form.Item>
    );
  };

  return (
    <Modal
      title="商談を作成"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText="キャンセル"
      width={480}
      okText="登録"
      okButtonProps={{
        loading,
        disabled: !formik.isValid || !formik.dirty,
      }}
      maskClosable
      {...(showAccountSelect ? {footer: null} : {})}>
      {data?.createSalesforceDealPreleadContact?.error && (
        <Error>{data?.createSalesforceDealPreleadContact?.error}</Error>
      )}
      {showAccountSelect ? (
        <AccountSelect
          onClose={() => setShowAccountSelect(false)}
          onSelect={onAccountSelect}
          keyword={formik.values.accountName}
          pool={pool}
        />
      ) : (
        <Form layout="vertical">
          <Form.Item label="取引先" required>
            <Radio.Group {...formik.getFieldProps('createAccount')}>
              <Form.Item
                style={{marginBottom: '0.5rem'}}
                validateStatus={formik.errors.accountName && 'error'}
                help={formik.errors.accountName}>
                <label>
                  <Radio value={true} />
                  新規作成
                </label>
                <Input
                  {...formik.getFieldProps('accountName')}
                  style={{margin: '5px 0 0 24px'}}
                />
              </Form.Item>
              <Form.Item
                style={{marginBottom: '0.5rem'}}
                validateStatus={formik.errors.salesforceAccountId && 'error'}
                help={formik.errors.salesforceAccountId}>
                <label>
                  <Radio value={false} />
                  既存を選択
                </label>
                <Button onClick={() => setShowAccountSelect(true)} type="link">
                  候補{pagination.totalCount}件
                </Button>
                {selectedAccount && (
                  <SelectedAccount>
                    <div>
                      <strong>{selectedAccount.name}</strong>
                      <span>{selectedAccount.website}</span>
                    </div>
                    <div>
                      <span>
                        {`${selectedAccount.billingState || ''}${
                          selectedAccount.billingCity || ''
                        }${selectedAccount.billingStreet || ''}`.trim() ||
                          '住所不明'}
                      </span>
                      <span>{selectedAccount.phone || '電話番号不明'}</span>
                    </div>
                  </SelectedAccount>
                )}
              </Form.Item>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="取引責任者" required>
            <Radio.Group {...formik.getFieldProps('createContact')}>
              <Form.Item
                style={{marginBottom: '0.5rem'}}
                validateStatus={formik.errors.contactLastName && 'error'}
                help={formik.errors.contactLastName}>
                <label>
                  <Radio value={true} />
                  新規作成
                </label>
                <div style={{width: '100%', marginLeft: '26px'}}>
                  <Form.Item noStyle>
                    <label>姓</label>
                    <Input
                      {...formik.getFieldProps('contactLastName')}
                      style={{width: '180px', margin: '0 .5rem'}}
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <label>名</label>
                    <Input
                      {...formik.getFieldProps('contactFirstName')}
                      style={{width: '180px', margin: '0 .5rem'}}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                style={{marginBottom: '0.5rem'}}
                validateStatus={formik.errors.salesforceContactId && 'error'}
                help={formik.errors.salesforceContactId}>
                <label>
                  <Radio value={false} />
                  既存を選択
                </label>
                <Select
                  style={{width: '94%', marginLeft: '26px'}}
                  value={formik.values.salesforceContactId}
                  onChange={(value) =>
                    formik.setFieldValue('salesforceContactId', value)
                  }>
                  <Select.Option value="">取引責任者を選択</Select.Option>
                  {salesforceContacts.map((contact) => (
                    <Select.Option key={contact.id} value={contact.id}>
                      {contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="商談名"
            required
            validateStatus={formik.errors.name && 'error'}
            help={formik.errors.name}>
            <Input {...formik.getFieldProps('name')} />
          </Form.Item>

          <Form.Item
            label="所有者"
            required
            validateStatus={formik.errors.salesforceUserId && 'error'}
            help={formik.errors.salesforceUserId}>
            <Select
              value={formik.values.salesforceUserId}
              showSearch
              optionFilterProp="children"
              onChange={(value) =>
                formik.setFieldValue('salesforceUserId', value)
              }>
              {salesforceUsers.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="フェーズ"
            required
            validateStatus={
              formik.errors.salesforceOpportunityStageId && 'error'
            }
            help={formik.errors.salesforceOpportunityStageId}>
            <Select
              value={formik.values.salesforceOpportunityStageId}
              onChange={(value) =>
                formik.setFieldValue('salesforceOpportunityStageId', value)
              }>
              {salesforceOpportunityStages.map((stage) => (
                <Select.Option key={stage.id} value={stage.id}>
                  {stage.masterLabel}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="完了予定日"
            required
            validateStatus={formik.errors.closeDate && 'error'}
            help={formik.errors.closeDate}>
            <DatePicker
              value={formik.values.closeDate}
              locale={locale}
              style={{width: '200px'}}
              onChange={(date) => formik.setFieldValue('closeDate', date)}
            />
          </Form.Item>

          {salesforceDealRecordTypes.length > 0 && (
            <Form.Item label="レコードタイプ">
              <Select
                onChange={(value: string) => {
                  formik.setFieldValue('salesforceDealRecordTypeId', value);
                  onRecordTypeSelect(value);
                }}>
                {salesforceDealRecordTypes.map((recordType) => (
                  <Select.Option key={recordType.id} value={recordType.id}>
                    {recordType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {salesforceDealFields.map((field: SalesforceDealField, i: number) =>
            dealFieldItem(field, i),
          )}
        </Form>
      )}
    </Modal>
  );
};

const SelectedAccount = styled.div`
  font-size: 12px;
  color: var(--text-color-2);
  padding: 5px 0 5px 24px;

  strong,
  span {
    margin-right: 1rem;
  }

  strong {
    font-size: 14px;
    color: var(--text-color-1);
  }

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Error = styled.div`
  color: red;
  margin-bottom: 8px;
`;

export default NewDealModalComponent;
