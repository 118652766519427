import React from 'react';

function Icon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4111 4.55717L10.354 4.53682L10.1311 4.00466L10.1553 3.94998C10.8306 2.41828 10.7841 2.37259 10.6721 2.26154L9.7712 1.38271C9.73436 1.34617 9.66969 1.32047 9.61394 1.32047C9.56325 1.32047 9.40973 1.32047 8.07922 1.92308L8.02587 1.94703L7.47694 1.72598L7.45491 1.67133C6.82923 0.12207 6.76514 0.12207 6.60944 0.12207H5.33721C5.1821 0.12207 5.11145 0.12207 4.53127 1.67402L4.50943 1.73052L3.96335 1.95313L3.91114 1.93142C3.01057 1.55175 2.48474 1.35922 2.34767 1.35922C2.29205 1.35922 2.22715 1.38417 2.19015 1.42062L1.28845 2.30198C1.17406 2.41481 1.12662 2.46206 1.83735 3.9618L1.86347 4.01742L1.64018 4.54899L1.58554 4.57012C0 5.18285 0 5.24259 0 5.40218V6.64887C0 6.80889 0 6.87535 1.58868 7.44469L1.64547 7.46475L1.8688 7.99477L1.84482 8.04893C1.16945 9.58134 1.21229 9.62282 1.32726 9.73717L2.22676 10.6172C2.26447 10.6534 2.32973 10.6793 2.38538 10.6793C2.43574 10.6793 2.58891 10.6793 3.92029 10.077L3.97361 10.0521L4.5229 10.2742L4.54425 10.3292C5.17032 11.8779 5.23464 11.8779 5.39043 11.8779H6.66305C6.82283 11.8779 6.8891 11.8779 7.46974 10.3249L7.49138 10.2684L8.03833 10.0473L8.09039 10.0685C8.99021 10.4492 9.51578 10.6414 9.65197 10.6414C9.70711 10.6414 9.77259 10.6172 9.8102 10.5799L10.7134 9.69667C10.827 9.58326 10.8743 9.53689 10.1623 8.03857L10.1358 7.98231L10.3587 7.45303L10.412 7.43217C12 6.81622 12 6.75619 12 6.5964V5.35046C12 5.19048 12 5.12441 10.4111 4.55717ZM5.99998 8.02189C4.86298 8.02189 3.93801 7.11461 3.93801 6.00022C3.93801 4.8858 4.86301 3.97971 5.99998 3.97971C7.1365 3.97971 8.06131 4.88599 8.06131 6.00022C8.06134 7.11441 7.13653 8.02189 5.99998 8.02189Z"
        fill="var(--text-color-3)"
      />
    </svg>
  );
}

export default Icon;
