import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E1E6EB" />
      <path
        d="M13.5255 22.0001C18.5548 22.0001 21.3068 17.6363 21.3068 13.8586C21.3068 13.7358 21.3068 13.613 21.3015 13.4903C21.8348 13.0829 22.2988 12.5807 22.6668 12.0115C22.1655 12.2403 21.6375 12.3965 21.0935 12.4635C21.6642 12.1064 22.0908 11.5428 22.2935 10.8787C21.7548 11.2135 21.1682 11.4479 20.5548 11.5707C19.5202 10.4156 17.7868 10.3598 16.6828 11.4479C15.9735 12.1454 15.6695 13.1889 15.8935 14.1822C13.7015 14.065 11.6482 12.9769 10.2615 11.1912C9.53616 12.497 9.90416 14.1711 11.1095 15.0081C10.6722 14.9969 10.2508 14.8741 9.86683 14.6509C9.86683 14.6621 9.86683 14.6733 9.86683 14.6844C9.86683 16.046 10.7842 17.2178 12.0642 17.4913C11.6588 17.6085 11.2375 17.6252 10.8268 17.5415C11.1842 18.7077 12.2135 19.5057 13.3815 19.5281C12.4108 20.326 11.2162 20.7557 9.98416 20.7557C9.7655 20.7557 9.54683 20.7389 9.3335 20.7166C10.5868 21.5592 12.0375 22.0001 13.5255 22.0001Z"
        fill="var(--primary)"
      />
    </svg>
  );
}

export default Icon;
