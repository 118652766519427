import React from 'react';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';
import {CustomizeItemSearchConditionType} from './CustomizeItemSearchConditionType';

export default () => {
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });
  const [update] = useUpdateWorkflowSearchConditionMutation({});
  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const handleClick = () => {
    const newCondition: CustomizeItemSearchConditionType = {};
    const oldItems =
      searchCondition?.prospectCustomizeItemSearchConditions || [];
    update({
      variables: {
        uuid: workflowId,
        attributes: {
          prospectCustomizeItemSearchConditions: [...oldItems, newCondition],
        },
      },
    });
  };
  const Icon = () => {
    return (
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.08984 4.99998H7.94269M5.01627 2.08331V7.91665V2.08331Z"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <button
      className="w-[18px] h-[18px] bg-[#FAFBFB] border border-[#F3F5F7] rounded-sm border-solid flex items-center justify-center p-0 cursor-pointer"
      disabled={!isConditionUpdatable}
      onClick={handleClick}>
      <Icon />
    </button>
  );
};
