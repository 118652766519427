import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Activity, ProspectCreateActivity} from 'api';
import {Link} from 'react-router-dom';

interface ProspectActivity extends Activity {
  resource: ProspectCreateActivity;
}

interface Props {
  activity: ProspectActivity;
}

const ProspectCreateActivityComponent = ({activity}: Props) => {
  const prospectPool = activity.resource.prospectPool;
  const prospect = prospectPool.prospect;
  const pool = prospectPool.pool;

  const user = activity.user;
  const userFullName = user?.firstName
    ? `${user?.lastName} ${user?.firstName}`
    : `${user?.lastName}`;

  const fullName = prospect.firstName
    ? `${prospect.lastName} ${prospect.firstName}`
    : `${prospect.lastName}`;
  return (
    <>
      <Summary>
        <BadgeWrapper>
          <Circle />
        </BadgeWrapper>
        <Message>
          <div>リード登録</div>
          <Link
            target="_blank"
            to={`/pools/${pool.uuid}/prospects/${prospectPool.uuid}/timeline`}>
            {fullName}
          </Link>
        </Message>
        <div className="flex gap-[6px] text-xs">
          <span>{userFullName}</span>
          <span>{moment(activity.createdAt).format('MM/DD HH:mm')}</span>
        </div>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  align-items: center;
  width: 480px;
  height: 30px;
`;

const Message = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--text-color-1);
  margin-right: 16px;
  display: flex;
  gap: 5px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: var(--text-color-3);
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  -o-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px;
`;

export default ProspectCreateActivityComponent;
