import React from 'react';
import {Select} from 'antd';
import styled from 'styled-components';
import {Project} from 'api';

interface Props {
  projects: Project[];
  value?: string;
  setProjectId: (projectId: string) => void;
}
const SelectProject = ({projects, value, setProjectId}: Props) => {
  const onChange = (value: string) => {
    setProjectId(value);
  };

  return (
    <Container>
      <label>営業リスト</label>
      <Select
        showSearch
        placeholder="営業リストを選択"
        optionFilterProp="children"
        value={value}
        onChange={onChange}>
        {projects.map((project) => (
          <Select.Option value={project.id} key={project.id}>
            {project.name}
          </Select.Option>
        ))}
      </Select>
    </Container>
  );
};

export {SelectProject};

const Container = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-size: 10px;
    color: var(--text-color-2);
    margin-bottom: 7px;
  }

  .ant-select {
    width: 100%;
  }
`;
