import React, {useMemo} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {useQueryParams, DelimitedArrayParam} from 'use-query-params';
import {ChildCategory} from './ChildCategory';
import {Checkbox} from 'components/Ui';

type Props = {
  category: any;
};
const ParentCategory = ({category}: Props) => {
  const [query, setQuery] = useQueryParams({
    technologies: DelimitedArrayParam,
  });

  const {technologies = []} = query;

  const technologyValues = useMemo(() => {
    return category.technologies.map((c: any) => c.value);
  }, [category]);

  const checkAll = (checked: boolean) => {
    let targets = [];
    if (checked) {
      targets = [...(technologies || []), ...technologyValues];
    } else {
      targets = technologies.filter(
        (value) => !technologyValues.includes(value),
      );
    }
    setQuery({technologies: [...new Set(targets)]});
  };

  const checked = useMemo(() => {
    return _.difference(technologyValues, technologies).length === 0;
  }, [technologies, technologyValues]);

  const selectTechnology = async (checked: boolean, technology: string) => {
    let targets: string[] = null;

    if (checked) {
      targets = technologies.concat(technology);
    } else {
      targets = technologies.filter((c) => c !== technology);
    }

    setQuery({technologies: [...new Set(targets)]});
  };

  return (
    <Container id={category.value}>
      <ParentCategorySection>
        <CategoryCheckBox
          checked={checked}
          onChange={(e) => checkAll(e.target.checked)}>
          {category.text} すべて
        </CategoryCheckBox>
      </ParentCategorySection>
      <ChildCategorySection key={category.id}>
        {category.technologies.map((technology: any) => (
          <ChildCategory
            key={technology.value}
            checked={technologies?.includes(technology.value)}
            technology={technology}
            onSelect={selectTechnology}
          />
        ))}
      </ChildCategorySection>
    </Container>
  );
};

export {ParentCategory};

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  width: 400px;
`;

const ParentCategorySection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #e7e8eb;
`;

const CategoryCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;

  .ant-checkbox {
    top: 0em;
  }

  > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--text-color-0);
  }
`;

const ChildCategorySection = styled.div`
  margin-top: 10px;
  margin-left: 22px;
  display: flex;
  flex-wrap: wrap;

  &&& {
    .category {
      margin: 5px 0;
      font-size: 12px;
      flex-basis: 45%;
    }

    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
`;
