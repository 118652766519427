import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Button,
  Input,
  InputNumber,
  Checkbox,
  Radio,
} from 'components/antd';
import {useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useCreateCallStepMutation} from 'api';

interface Props {
  onFinish: () => void;
}

export default ({onFinish}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const [create, {loading}] = useCreateCallStepMutation({
    refetchQueries: ['workflow'],
  });
  const formik = useFormik({
    initialValues: {
      name: '電話',
      memo: '',
      scheduleType: 'period',
      startDay: 3,
      daysOfWeek: [1, 2, 3, 4, 5],
      isImmediately: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      daysOfWeek: Yup.array().ensure().of(Yup.number()).min(1, '必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      if (values.scheduleType === 'isImmediately') {
        values.isImmediately = true;
        values.startDay = null;
        values.daysOfWeek = [];
      }

      if (values.scheduleType === 'period') {
        values.isImmediately = false;
      }

      delete values.scheduleType;

      create({variables: {uuid: workflowId, attributes: values}});
      resetForm();
      onFinish();
    },
  });

  return (
    <Container>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item
          label="ステップ名"
          validateStatus={formik.errors.name && 'error'}
          help={formik.errors.name}>
          <Input {...formik.getFieldProps('name')} />
        </Form.Item>
        <Form.Item label="実行タイミング" style={{marginBottom: '0'}}>
          <Radio.Group {...formik.getFieldProps('scheduleType')}>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value="period" />
              <InputNumber
                min={1}
                style={{width: '6rem', marginRight: '0.5rem'}}
                value={formik.values.startDay}
                onChange={(value) => formik.setFieldValue('startDay', value)}
                disabled={formik.values.scheduleType === 'isImmediately'}
              />
              日後に開始
            </Form.Item>
            <Form.Item>
              <Radio value="isImmediately" />
              即時実行
            </Form.Item>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="この曜日のみ実行します"
          validateStatus={formik.errors.daysOfWeek && 'error'}
          help={formik.errors.daysOfWeek}>
          <p>※実行タイミングがチェックのある曜日になります</p>
          <Checkbox.Group
            disabled={formik.values.scheduleType === 'isImmediately'}
            options={[
              {label: '月', value: 1},
              {label: '火', value: 2},
              {label: '水', value: 3},
              {label: '木', value: 4},
              {label: '金', value: 5},
              {label: '土', value: 6},
              {label: '日', value: 0},
            ]}
            value={formik.values.daysOfWeek}
            onChange={(values) => formik.setFieldValue('daysOfWeek', values)}
          />
        </Form.Item>
        <Form.Item label="メモ">
          <Input.TextArea {...formik.getFieldProps('memo')} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
            loading={loading}>
            登録
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item-label label {
    font-weight: bold;
  }

  p {
    color: var(--text-color-2);
  }

  .ant-input-number {
    width: 46px !important;
    height: 35px !important;

    input {
      text-align: center;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;
