import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = [
    '2024年',
    '合計',
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  const data: ReportTableData[] = [
    {
      label: '合計',
      values: [687, 34, 22, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      cellProps: {
        style: {
          borderBottom: 'double 3px var(--text-color-2)',
        },
      },
    },
    {
      label: 'High',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '未実施',
          color: '#0DC8F1',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '開始',
          color: '#2693FF',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '完了',
          color: '#61CF8D',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '期限切れ',
          color: '#FF3700',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '保留',
          color: 'var(--text-color-2)',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: 'Middle',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '未実施',
          color: '#0DC8F1',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '開始',
          color: '#2693FF',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '完了',
          color: '#61CF8D',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '期限切れ',
          color: '#FF3700',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '保留',
          color: 'var(--text-color-2)',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: 'Low',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '未実施',
          color: '#0DC8F1',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '開始',
          color: '#2693FF',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '完了',
          color: '#61CF8D',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '期限切れ',
          color: '#FF3700',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '保留',
          color: 'var(--text-color-2)',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
  ];

  return {
    data,
    headers,
  };
};

export default useTableData;
