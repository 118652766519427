import styled from 'styled-components';
import {Table} from 'components/antd';

export default styled(Table)`
  width: 100%;
  overflow: scroll;
  z-index: 0;

  .active {
    background: #f9fafb !important;

    .ant-table-cell {
      opacity: 0.5;
      background: transparent !important;

      &.ant-table-cell-fix-left {
        opacity: 1;
        background-color: #f9fafb !important;
      }
    }
  }

  [class^='ant-table'],
  [class*='ant-table'] {
    border-color: #e1e6eb !important;
    background: #f3f5f7;
  }

  .ant-table-container {
    border-top: none !important; // tableの上部ボーダーの消去
    border-left: none !important; // tableの左部ボーダーの消去
  }

  // headerの調整
  .ant-table-container table > thead > tr th {
    padding: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-3);
    white-space: pre;

    // フィルター付きコラムの調整
    .ant-table-filter-column {
      display: flex;
      flex-direction: column;

      .ant-table-filter-column-title {
        padding: 16px 16px 6px 16px;
      }

      .ant-table-filter-trigger-container {
        position: unset;

        .ant-table-filter-trigger {
          margin: 0 auto;
        }
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell.align-center {
      padding: 30px 0;
      text-align: center !important;
    }
  }

  // bodyの調整
  .ant-table-tbody {
    font-size: 14px;
  }
`;
