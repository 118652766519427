import React from 'react';
import styled from 'styled-components';
import {Switch, Route, useHistory} from 'react-router-dom';
import Status from './Status';
import Category from './Category';

export default () => {
  const history = useHistory();

  return (
    <div>
      <Tabs>
        <Tab
          active={location.pathname.endsWith(
            '/settings/status/prospect_tel_status/status',
          )}
          onClick={() =>
            history.push(`/settings/status/prospect_tel_status/status`)
          }>
          <span>ステータス</span>
        </Tab>
        <Tab
          active={location.pathname.includes(
            '/settings/status/prospect_tel_status/category',
          )}
          onClick={() =>
            history.push(`/settings/status/prospect_tel_status/category`)
          }>
          <span>種別</span>
        </Tab>
      </Tabs>
      <Switch>
        <Route
          path="/settings/status/prospect_tel_status/category"
          component={Category}
        />
        <Route
          index
          path="/settings/status/prospect_tel_status/status"
          component={Status}
        />
      </Switch>
    </div>
  );
};

const Tabs = styled.div`
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 28px;
  display: flex;
  width: 164px;
  height: 36px;

  background: #f3f5f7;
  border-radius: 6px;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  ${({active}) => active && 'background: #FFFFFF'};
  border-radius: 4px;
  cursor: pointer;

  span {
    padding: 7px 14px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: var(--text-color-1);
    color: ${({active}) => (active ? 'var(--text-color-1)' : 'var(--text-color-3)')};
  }
`;
