import React from 'react';
import styled from 'styled-components';
import {CubeLoading} from 'components/Ui/Icon';
import {TableInstance} from 'react-table';
import {Pagination} from 'components/antd';

interface PaginationType {
  total: number;
  current: number;
  pageSize: number;
  onChange: (page: number) => void;
}

interface TableType extends TableInstance<any> {
  defaultColumn?: any;
}

interface Props extends TableType, PaginationType {
  loading?: boolean;
  getRowProps?: any;
}

const defaultPropGetter = () => ({});

export default ({
  getTableProps,
  getTableBodyProps,
  getRowProps = defaultPropGetter,
  headerGroups,
  rows,
  prepareRow,
  loading = false,
  total,
  current,
  pageSize,
  onChange,
}: Props) => {
  const headerProps = (props: any, {column}: any) => {
    return [
      props,
      {
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: column.sticky ? 'sticky' : 'relative',
          left: column.sticky ? `${column.totalLeft}px` : 'auto',
          zIndex: column.sticky ? 1 : 0,
        },
      },
    ];
  };

  const cellProps = (props: any, {cell}: any) => {
    return [
      props,
      {
        style: {
          justifyContent:
            cell.column.align === 'right'
              ? 'flex-end'
              : cell.column.align === 'center'
              ? 'center'
              : 'flex-start',
          alignItems: 'center',
          display: 'flex',
          position: cell.column.sticky ? 'sticky' : 'relative',
          left: cell.column.sticky ? `${cell.column.totalLeft}px` : 'auto',
          zIndex: cell.column.sticky ? 2 : 0,
        },
      },
    ];
  };

  return (
    <Container>
      <Table {...getTableProps()}>
        <div className="thead">
          {headerGroups.map((headerGroup, i) => (
            <div key={i} {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column, j) => (
                <div
                  key={j}
                  {...column.getHeaderProps(headerProps)}
                  className="th">
                  <div>{column.render('Header')}</div>
                  {
                    // @ts-ignore
                    column.canResize && (
                      <div
                        {
                          // @ts-ignore
                          ...column.getResizerProps()
                        }
                        className="resizer"
                      />
                    )
                  }
                </div>
              ))}
            </div>
          ))}
        </div>

        {loading ? (
          <Loading>
            <CubeLoading />
          </Loading>
        ) : (
          <div {...getTableBodyProps()} className="tbody">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div
                  key={i}
                  {...row.getRowProps([getRowProps(row), {className: 'tr'}])}>
                  {row.cells.map((cell, j) => {
                    return (
                      <div
                        key={j}
                        {...cell.getCellProps(cellProps)}
                        className="td">
                        <div>{cell.render('Cell')}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </Table>
      <PaginationWrapper>
        {total && (
          <>
            <div>
              <em>{Math.min(current * pageSize, total).toLocaleString()}</em> /{' '}
              {total.toLocaleString()}
            </div>
            <Pagination
              showSizeChanger={false}
              showQuickJumper={false}
              size="small"
              pageSize={pageSize}
              total={total}
              current={current}
              onChange={onChange}
            />
          </>
        )}
      </PaginationWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex: 1;
  background: #f3f5f7;
`;

const Table = styled.div`
  font-size: 12px;
  border-spacing: 0;
  position: relative;
  overflow: scroll;
  flex: 1;

  .thead {
    display: flex;
    width: 100%;
    top: 0;
    z-index: 3;
    position: sticky;

    .tr {
      .th {
        background-color: #f3f5f7;
        height: 48px;

        > div {
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .tbody {
    .tr {
      background-color: #fff;
      position: relative;

      .td {
        height: 36px;
        background-color: #fff;

        > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .active {
      border: 1px solid var(--primary);

      .td {
        background: #f4f9ff;
      }
    }
  }

  .tr {
    border-bottom: 1px solid #e1e6eb;
    border-left: solid transparent 1px;
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    border-right: 1px solid #e1e6eb;

    &:last-child {
      border-right: 0;
    }
  }

  input {
    position: relative;
    top: 2px;
  }

  .resizer {
    right: -1px;
    background: transparent;
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    touch-action: none;
  }
`;

const Loading = styled.div`
  margin-top: 70px;
  text-align: center;

  svg {
    display: inline-block;
  }
`;

const PaginationWrapper = styled.div`
  height: 46px;
  border-top: solid 1px var(--border-color);
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 0 10px;
  overflow: hidden;

  > div:first-of-type {
    em {
      color: #8966ef;
      font-style: normal;
      font-weight: bold;
    }
    min-width: 100px;
  }

  &&& {
    .ant-pagination {
      display: flex;
    }

    .ant-pagination-item {
      margin: 0 0.5rem;

      a {
        color: var(--text-color-1);
      }
    }

    .ant-pagination-item-active {
      background-color: var(--border-color);
      border-radius: 50%;
      border: none;
    }
  }
`;
