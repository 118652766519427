import React from 'react';
import styled from 'styled-components';
import {useProjectTelStatusReportQuery, useProjectQuery} from 'api';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import _ from 'lodash';
import {ArrowRight} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import {telStatusCategoryHash} from 'helpers/telStatusCategoryHash';

export default () => {
  const [displayCategories, setDisplayCategories] = React.useState([]);
  const {projectId} = useParams<{
    requestClientId: string;
    projectId: string;
  }>();
  const {data: {projectTelStatusReport = {}} = {}} =
    useProjectTelStatusReportQuery({
      variables: {
        projectUuid: projectId,
      },
    });
  const report: any = projectTelStatusReport;

  const {data: {project: {telStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const categorizedTelStatuses = React.useCallback(
    (category: string) =>
      telStatuses.filter((telStatus) => telStatus.category === category),
    [telStatuses],
  );

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );

    return value;
  };

  const TelStatusIds = (category: string) =>
    telStatuses
      .filter((telStatus) => telStatus.category === category)
      .map((telStatus) => telStatus.id)
      .join('_');

  return (
    <Container>
      <h2>ステータス・カテゴリ別</h2>

      <Table>
        <div className="column far-left">
          {Object.keys(telStatusCategoryHash)
            .filter((category) => !category.includes('ng'))
            .map((category) => (
              <React.Fragment key={category}>
                <div
                  className={'category ' + 'openable'}
                  onClick={() => {
                    if (displayCategories.includes(category)) {
                      setDisplayCategories(
                        displayCategories.filter(
                          (displayCategory) => displayCategory != category,
                        ),
                      );
                    } else {
                      setDisplayCategories([...displayCategories, category]);
                    }
                  }}>
                  <Open
                    category={category}
                    displayCategories={displayCategories}>
                    <ArrowRight />
                  </Open>
                  <StatusEclipse color={telCategoryColor(category)} />
                  <span>{telStatusCategoryHash[category]}</span>
                </div>
                {displayCategories.includes(category) &&
                  categorizedTelStatuses(category).map((telStatus) => (
                    <div key={telStatus.id}>
                      <span>{telStatus.name}</span>
                    </div>
                  ))}
              </React.Fragment>
            ))}
        </div>

        <div className="column">
          {Object.keys(telStatusCategoryHash)
            .filter((category) => !category.includes('ng'))
            .map((category) => (
              <React.Fragment key={category}>
                <div className="category">
                  <span>
                    {report[_.camelCase(category)] && (
                      <a
                        href={`/list/projects/${projectId}/preleads?page=1&telStatusIds=${TelStatusIds(
                          category,
                        )}`}
                        target="_blank">
                        {totalCount(report[_.camelCase(category)])}
                      </a>
                    )}
                  </span>
                </div>
                {displayCategories.includes(category) &&
                  categorizedTelStatuses(category).map((telStatus) => (
                    <div key={telStatus.id}>
                      <span>
                        <a
                          href={`/list/projects/${projectId}/preleads?page=1&telStatusIds=${telStatus.id}`}
                          target="_blank">
                          {report[category] && report[category][telStatus.id]
                            ? report[category][telStatus.id]
                            : 0}
                        </a>
                      </span>
                    </div>
                  ))}
              </React.Fragment>
            ))}
        </div>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;

  > h2 {
    margin-top: 46px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  width: 407px;
  background: #fff;

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
      width: 100%;
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      align-items: center;

      &:first-child {
      }

      &:nth-child(2) {
      }

      > span {
        margin: 0 9px 0 auto;
        color: var(--text-color-2);
      }

      &.category {
        > span {
          color: var(--text-color-1);
          font-weight: bold;
        }
      }
    }

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 272px;
        display: flex;
        align-items: center;
        padding: 0 40px;

        background: #fff;

        &.category {
          padding: 0 13px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        &.openable {
          cursor: pointer;
        }

        > span {
          margin: 0;
          font-size: 13px;
        }
      }
    }
  }
`;

const Open = styled.div<{category: string; displayCategories: any}>`
  display: flex;
  width: 13px;
  height: 13px;
  > svg {
    ${({category, displayCategories}) =>
      displayCategories.includes(category) && 'transform: rotate(90deg);'}

    width: 13px;
    height: 13px;
    fill: var(--text-color-3);
    path {
      width: 13px;
      height: 13px;
    }
  }
`;
