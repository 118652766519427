import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useUpdateWorkflowSearchConditionMutation} from 'api';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  contact_field_id: string;
};

type Props = {
  salesforceContactSearchConditions: SelectSearchCondition[];
  setSalesforceContactSearchConditions: React.Dispatch<
    React.SetStateAction<SelectSearchCondition[]>
  >;
  index: number;
};

export default ({
  salesforceContactSearchConditions,
  setSalesforceContactSearchConditions,
  index,
}: Props) => {
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  return (
    <CrossWrapper>
      <svg
        onClick={() => {
          let newFilters: SelectSearchCondition[] = [
            ...salesforceContactSearchConditions,
          ];
          newFilters = newFilters.filter(
            (item) => Object.keys(item).length !== 0,
          );
          newFilters.splice(index, 1);
          setSalesforceContactSearchConditions([...newFilters]);
          updateWorkflowSearchCondition({
            variables: {
              uuid: workflowId,
              attributes: {
                salesforceContactSearchConditions: newFilters,
              },
            },
          });
        }}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.916016 9.08366L9.08268 0.916992M0.916016 0.916992L9.08268 9.08366L0.916016 0.916992Z"
          stroke="var(--text-color-3)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CrossWrapper>
  );
};

const CrossWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
