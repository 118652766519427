import React from 'react';

function Icon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.8999 11.9H2.4999C2.0999 11.9 1.8999 11.7 1.8999 11.3V3.80001C1.8999 3.40001 2.0999 3.20001 2.4999 3.20001H12.4999C12.8999 3.20001 13.0999 3.40001 13.0999 3.80001V6.90001"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 3.40002L7.5 7.50002L2.5 3.40002"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2998 8.90002L13.1998 12.8"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1998 8.90002L9.2998 12.8"
        stroke="var(--text-color-2)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
