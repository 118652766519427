import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import Header from '../../Header';
import Footer from '../Footer';
import PlusIcon from './PlusIcon';
import CustomizeItemField from './CustomizeItemField';
import Close from './Close';
import CustomizeItemSelectOption from './CustomizeItemSelectOption';
import CampaignSelect from './CampaignSelect';

const FormFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="Salesforceキャンペーン" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <CampaignSelect />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">条件項目</div>
            <div className="flex flex-col gap-5">
              {query?.salesforceCampaignSearchConditions?.map(
                (customizeItem: any, index: number) => {
                  return (
                    <div key={index} className="flex gap-3">
                      <CustomizeItemField
                        salesforceCampaignId={query.salesforceCampaignId}
                        salesforceCampaignMemberFieldId={
                          customizeItem['salesforce_campaign_member_field_id']
                        }
                        index={index}
                      />
                      {customizeItem['field_type'] === 'select' && (
                        <CustomizeItemSelectOption
                          salesforceCampaignMemberFieldId={
                            customizeItem['salesforce_campaign_member_field_id']
                          }
                          customizeItemValue={customizeItem['values']}
                          index={index}
                        />
                      )}
                      <Close index={index} />
                    </div>
                  );
                },
              )}
            </div>
            <button
              className="h-7 w-28 text-center rounded bg-white border border-border flex justify-center items-center"
              disabled={!query.salesforceCampaignId}
              onClick={() => {
                setQuery({
                  ...query,
                  salesforceCampaignSearchConditions: [
                    ...query.salesforceCampaignSearchConditions,
                    {},
                  ],
                });
              }}>
              <PlusIcon /> <span className="text-foreground-light">条件を追加</span>
            </button>
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setQuery({
            ...query,
            salesforceCampaignId: '',
            salesforceCampaignSearchConditions: [],
          })
        }
      />
    </>
  );
};

export default FormFilter;
