import React from 'react';
import styled from 'styled-components';
import Table from './Table';
import {Filter} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import useSearchParams from './useSearchParams';
import Summary from './Summary';
import usePools from 'hooks/usePools';
import {useHistory} from 'react-router-dom';

interface Props {
  toggleFilter: () => void;
}

export default ({toggleFilter}: Props) => {
  const {numberOfFiltersInUse} = useSearchParams();
  const {defaultPool} = usePools();
  const history = useHistory();

  return (
    <Container>
      <Header isFiltered={numberOfFiltersInUse > 0}>
        <Button icon={<Filter />} ghost onClick={toggleFilter} />
        {numberOfFiltersInUse > 0 && <Count>{numberOfFiltersInUse}</Count>}
        {/* <ActionButton>新規作成</ActionButton> */}
      </Header>
      <NavMenu>
        <NavItem
          onClick={() =>
            history.push(`/pools/${defaultPool.uuid}/action/actions`)
          }>
          ワークフロー
        </NavItem>
        <NavItem active>ToDo</NavItem>
      </NavMenu>
      <Summary />
      <Table />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
`;

const Header = styled.div<{isFiltered: boolean}>`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  /* button:last-child {
    margin-left: auto;
  } */

  svg {
    path {
      stroke: ${({isFiltered}) => isFiltered && 'var(--primary)'};
    }
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NavItem = styled.div<{active?: boolean}>`
  display: inline-block;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: ${({active}) => (active ? 'var(--text-color-0)' : 'var(--text-color-3)')};
  border-bottom: ${({active}) => (active ? '3px solid var(--text-color-1)' : '')};
  height: 40px;
`;

// const ActionButton = styled(Button)`
//   height: 40px;
//   width: 180px;
//   border-radius: 4px;
//   background: var(--primary);
//   border: 1px solid var(--primary);
//   padding: 0 15px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   span {
//     font-weight: bold;
//     font-size: 12px;
//     color: #ffffff;
//   }

//   &:hover {
//     background: #65aae8;
//   }
// `;

const Count = styled.span`
  font-weight: 500;
  font-size: 10px;
  padding: 3px 11px;
  border-radius: 10px;
  color: var(--primary);
  background: #e6f3ff;
`;
