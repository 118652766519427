import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {usePreleadProjectQuery, useUpdatePreleadProjectMemoMutation} from 'api';
import {Quill} from 'components/Ui';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const {data: {preleadProject = {}} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isOmit, setIsOmit] = useState(true);
  const [memoAreaHeight, setMemoAreaHeight] = useState(0);
  const [memo, setMemo] = useState(preleadProject.memo);
  const [memoHtml, setMemoHtml] = useState(preleadProject.memoHtml);

  useEffect(() => {
    setIsEdit(false);
    setIsOmit(true);
    setMemo(preleadProject.memo);
    setMemoHtml(preleadProject.memoHtml);
  }, [preleadProject.id]);

  useEffect(() => {
    const children = document.querySelector('.ql-editor')?.children;
    let height = 0;
    for (let i = 0; i < children.length; i++) {
      height += children[i].clientHeight;
    }
    height >= 100 ? setMemoAreaHeight(100) : setMemoAreaHeight(height);
  }, [memoHtml]);

  const [updatePreleadProject] = useUpdatePreleadProjectMemoMutation({});

  const updateMemo = () => {
    updatePreleadProject({
      variables: {
        uuid: preleadProjectId,
        attributes: {memo: memo, memoHtml: memoHtml},
      },
    });
  };

  return (
    <Container>
      <QuillWrapper
        isEdit={isEdit}
        isOmit={isOmit}
        memoAreaHeight={memoAreaHeight}>
        <Quill
          theme="snow"
          value={memoHtml}
          onFocus={() => setIsEdit(true)}
          onBlur={() => {
            updateMemo();
            setIsEdit(false);
          }}
          onChange={(content, delta, source, editor) => {
            setMemoHtml(content);
            setMemo(editor.getText());
          }}
          modules={{
            toolbar: [
              [{header: [1, 2, 3, false]}],
              ['bold', 'italic', 'underline'],
              ['code-block'],
              ['link'],
            ],
          }}
          formats={[
            'header',
            'list',
            'bold',
            'italic',
            'underline',
            'strike',
            'color',
            'background',
            'code-block',
            'link',
          ]}
          bounds={'#quill-container'}
        />
      </QuillWrapper>
      {!isEdit && memoHtml && memoAreaHeight === 100 && (
        <Display onClick={() => setIsOmit(!isOmit)}>
          {isOmit ? 'さらに表示' : '狭く表示'}
        </Display>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  color: var(--text-color-1);
`;

const QuillWrapper = styled.div<{
  isEdit: boolean;
  isOmit: boolean;
  memoAreaHeight: number;
}>`
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: ${({isEdit}) => (isEdit ? 'var(--border-color)' : 'transparent')};

  .ql-toolbar.ql-snow {
    display: ${({isEdit}) => (isEdit ? 'block' : 'none')};
  }
  .ql-container {
    border: none;
  }
  .ql-container.ql-snow {
    height: ${({memoAreaHeight, isOmit, isEdit}) =>
      memoAreaHeight == 100 && isOmit && !isEdit ? '100px' : '100%'};
    min-height: 0;

    .ql-editor {
      min-height: 0;
      resize: ${({isEdit}) => (isEdit ? 'vertical' : 'none')};
      padding: ${({isEdit}) => (isEdit ? '12px 15px' : 0)};
      overflow: ${({isEdit}) => (isEdit ? 'scroll' : 'hidden')};
      height: ${({isEdit, isOmit, memoAreaHeight}) =>
        !isEdit && isOmit && memoAreaHeight == 100
          ? `100px !important`
          : '100%'};
    }
  }
`;

const Display = styled.div`
  margin-top: 5px;
  color: var(--primary);
  cursor: pointer;
`;
