import React from 'react';
import {
  useSalesforceAccountRecordTypeQuery,
  useUpdateSalesforceAccountLeadpadFieldMutation,
  SalesforceAccountRecordType,
  SalesforceAccountField,
  SalesforceAccountLeadpadField,
} from 'api';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';

interface Props {
  salesforceAccountLeadpadField: SalesforceAccountLeadpadField;
  salesforceAccountRecordType: SalesforceAccountRecordType;
}

export default ({
  salesforceAccountLeadpadField,
  salesforceAccountRecordType,
}: Props) => {
  const {
    data: {
      salesforceAccountRecordType: {salesforceAccountFields = []} = {},
    } = {},
  } = useSalesforceAccountRecordTypeQuery({
    variables: {
      id: salesforceAccountRecordType?.id,
    },
    skip: !salesforceAccountRecordType,
  });

  const salesforceAccountField =
    salesforceAccountLeadpadField?.salesforceAccountField;

  const accountFieldOptions = salesforceAccountFields
    .filter(
      (accountField: SalesforceAccountField) =>
        accountField.fieldType === salesforceAccountLeadpadField.fieldType,
    )
    .filter(
      (accountField: SalesforceAccountField) =>
        !accountField.salesforceAccountLeadpadField,
    );

  const [update] = useUpdateSalesforceAccountLeadpadFieldMutation({
    refetchQueries: ['salesforceAccountRecordType'],
  });

  return (
    <div className="text-foreground-dark w-96 px-6 text-base">
      <Listbox
        onChange={(accountField: SalesforceAccountField) => {
          update({
            variables: {
              id: salesforceAccountLeadpadField?.id,
              accountFieldId: accountField?.id,
            },
          });
        }}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm min-h-[37.59px]">
            <span className="block truncate" />
            {salesforceAccountField?.label || ''}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 ">
              {accountFieldOptions.map((accountField) => (
                <Listbox.Option
                  key={accountField.id}
                  className={({active}) =>
                    `min-h-[37.59px]  relative cursor-pointer select-none p-3 ${
                      active ? 'bg-gray-100' : 'bg-white'
                    }`
                  }
                  value={accountField}>
                  {({selected}) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}>
                        {accountField.label}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
