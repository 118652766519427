import React from 'react';
import styled from 'styled-components';
import {useTable} from 'react-table';
import {PreleadProject} from 'api';
import Cell from './Cell';

interface Props {
  preleadProjects: PreleadProject[];
}

export default ({preleadProjects}: Props) => {
  const columns: any = React.useMemo(
    () => [
      {
        id: 'status',
        Header: 'プレリードステータス',
        Cell: Cell.Status,
      },
      {
        id: 'name',
        Header: '会社名',
        accessor: (row: any) => `${row.prelead.name || ''}`,
      },
      {
        id: 'mailAddress',
        Header: 'メールアドレス',
        accessor: (row: any) => `${row.prelead.mailAddress || ''}`,
      },
      {
        id: 'mail',
        Header: 'フォーム送信ステータス',
        Cell: Cell.Mail,
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data: preleadProjects});

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, j) => (
              <th {...column.getHeaderProps()} key={j}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell, j) => {
                return (
                  <td {...cell.getCellProps()} key={j}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 0.5rem 1rem;
    margin: 0px;

    &:first-child {
      padding-left: 0;
    }
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: var(--text-color-2);
        text-align: left;
        border-bottom: solid 1px #e1e6eb;

        &:first-child {
          padding: 3px 1rem 3px 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: solid 1px #e1e6eb;
        cursor: pointer;
      }
    }
  }
`;
