import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import {ColoredSetting} from 'components/Ui/Icon';
import {PreleadContact, useProjectWithClientQuery} from 'api';
import IntegrationSettingModal from './IntegrationSettingModal';
import {useParams} from 'react-router';

interface Props {
  preleadContact: PreleadContact;
}

export default ({preleadContact}: Props) => {
  const {projectId} =
    useParams<{
      projectId: string;
    }>();
  const [isSettingModalVisible, setSettingModalVisible] = useState(false);

  const {
    data: {
      project: {
        client = {},
        client: {
          hubspotSetting = {},
          salesforceSetting = {},
          sensesSetting = {},
        } = {},
      } = {},
    } = {},
  } = useProjectWithClientQuery({
    variables: {uuid: projectId},
  });

  const sensesUrl = useMemo(
    () =>
      sensesSetting?.isSensesPartner
        ? 'https://senses-partner.mazrica.com/'
        : 'https://senses.mazrica.com/',
    [sensesSetting],
  );

  return (
    <Container>
      {client.hubspotActive && (
        <HubspotLink active={!!preleadContact.hubspotContactId}>
          {!!preleadContact.hubspotContactId &&
          !!hubspotSetting.hubspotAccountId ? (
            <a
              href={`https://app.hubspot.com/contacts/${hubspotSetting.hubspotAccountId}/contact/${preleadContact.hubspotContactId}/`}
              target="_blank">
              HubSpot
            </a>
          ) : (
            <span>HubSpot</span>
          )}
        </HubspotLink>
      )}
      {client.salesforceActive && (
        <SalesforceLink active={!!preleadContact.salesforceLeadId}>
          {preleadContact.salesforceLeadId ? (
            <a
              href={`${salesforceSetting.instanceUrl}/lightning/r/Lead/${preleadContact.salesforceLeadId}/view`}
              target="_blank">
              Salesforce
            </a>
          ) : (
            <span>Salesforce</span>
          )}
        </SalesforceLink>
      )}

      {client.sensesActive && sensesSetting.sensesDealTypeId && (
        <SensesLink active={!!preleadContact.sensesContactId}>
          {preleadContact.sensesContactId ? (
            <a
              href={`${sensesUrl}contact/${preleadContact.sensesContactId}`}
              target="_blank">
              Senses
            </a>
          ) : (
            <span>Senses</span>
          )}
        </SensesLink>
      )}
      <div onClick={() => setSettingModalVisible(true)}>
        <ColoredSetting />
      </div>

      <IntegrationSettingModal
        isModalVisible={isSettingModalVisible}
        setModalVisible={setSettingModalVisible}
        preleadContact={preleadContact}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    cursor: pointer;
  }
`;

const IntegrationLink = styled.div`
  margin-right: 5px;
  display: flex;
  height: 18px;
  background: #ffffff;
  border: 1px solid var(--primary);
  border-radius: 100px;

  a,
  span {
    margin: auto;
    padding: 0px 8px;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
    color: var(--primary);
  }
`;

const SalesforceLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? 'var(--primary)' : '#ffffff')};
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : 'var(--primary)')};
  }
`;

const HubspotLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#FF7A59' : '#ffffff')};
  border: 1px solid #ff7a59;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
  }
`;

const SensesLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#1A2F3A' : '#ffffff')};
  border: 1px solid #1a2f3a;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
  }
`;
