import React from 'react';
import styled from 'styled-components';
import {Tel} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import {useProjectTelStatusCallReportQuery} from 'api';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectTelStatusCallReport = {}} = {}} =
    useProjectTelStatusCallReportQuery({
      variables: {
        projectUuid: projectId,
      },
    });

  const getTotalCount = (target: any): any => {
    if (target) {
      const value = Object.values(target).reduce(
        (total: number, value: number) => total + value,
        0,
      );
      return value;
    }
    return 0;
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>
          <Tel />
          総TEL件数
          <span>
            {(
              getTotalCount(projectTelStatusCallReport?.call) +
              getTotalCount(projectTelStatusCallReport?.reception) +
              getTotalCount(projectTelStatusCallReport?.contact) +
              getTotalCount(projectTelStatusCallReport?.appoint) +
              getTotalCount(projectTelStatusCallReport?.document) +
              getTotalCount(projectTelStatusCallReport?.other) +
              getTotalCount(projectTelStatusCallReport?.callNg) +
              getTotalCount(projectTelStatusCallReport?.receptionNg) +
              getTotalCount(projectTelStatusCallReport?.contactNg)
            ).toLocaleString()}
          </span>
        </Title>
      </TitleWrapper>

      <Body>
        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('call')} />
            コール
          </Name>
          <Value>{getTotalCount(projectTelStatusCallReport?.call)}</Value>
        </Item>

        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('reception')} />
            受付
          </Name>

          <Value>{getTotalCount(projectTelStatusCallReport?.reception)}</Value>
        </Item>

        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('contact')} />
            キーマン接触
          </Name>
          <Value>{getTotalCount(projectTelStatusCallReport?.contact)}</Value>
        </Item>

        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('appoint')} />
            アポ獲得
          </Name>

          <Value>{getTotalCount(projectTelStatusCallReport?.appoint)}</Value>
        </Item>
        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('document')} />
            資料送付
          </Name>

          <Value>{getTotalCount(projectTelStatusCallReport?.document)}</Value>
        </Item>
        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('other')} />
            その他
          </Name>

          <Value>{getTotalCount(projectTelStatusCallReport?.other)}</Value>
        </Item>
        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('call_ng')} />
            コールNG
          </Name>

          <Value>{getTotalCount(projectTelStatusCallReport?.callNg)}</Value>
        </Item>

        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('reception_ng')} />
            受付NG
          </Name>
          <Value>
            {getTotalCount(projectTelStatusCallReport?.receptionNg)}
          </Value>
        </Item>

        <Item>
          <Name>
            <StatusEclipse color={telCategoryColor('contact_ng')} />
            キーマン接触NG
          </Name>

          <Value>{getTotalCount(projectTelStatusCallReport?.contactNg)}</Value>
        </Item>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const TitleWrapper = styled.div`
  border-bottom: 1px solid var(--border-color);
`;

const Title = styled.span`
  width: 378px;
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-3);
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 5px;
  svg {
    margin-right: 6px;
    width: 15px;
    height: 15px;
  }
  span {
    margin-left: auto;
  }
`;

const Body = styled.div`
  width: 378px;
`;

const Item = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 15px;
  }
  &:last-child {
    margin-bottom: 40px;
  }
`;

const Name = styled.span`
  margin-right: 15px;
  width: 200px;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color-3);
  padding: 0 4px;
`;

const Value = styled.span`
  margin-left: auto;
  text-align: right;
`;
