import React from 'react';

const Icon = ({color = 'var(--text-color-3)'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.25 2.5H3.75C3.06062 2.5 2.5 3.06062 2.5 3.75V16.25C2.5 16.9394 3.06062 17.5 3.75 17.5H16.25C16.9394 17.5 17.5 16.9394 17.5 16.25V3.75C17.5 3.06062 16.9394 2.5 16.25 2.5ZM8.4375 12.5V10H11.5625V12.5H8.4375ZM11.5625 13.75V16.25H8.4375V13.75H11.5625ZM11.5625 6.25V8.75H8.4375V6.25H11.5625ZM16.25 6.25V8.75H12.8125V6.25H16.25ZM7.1875 8.75H3.74969V6.25H7.1875V8.75ZM3.74969 10H7.1875V12.5H3.74969V10ZM12.8125 10H16.25V12.5H12.8125V10ZM3.75 3.75H16.25V5H3.74938V3.75H3.75ZM3.75 13.75H7.1875V16.25H3.75V13.75ZM12.8125 16.25V13.75H16.25V16.25H12.8125Z"
      fill={color}
    />
  </svg>
);

export default Icon;
