import React from 'react';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';

type Props = React.ComponentProps<typeof UIPagination>;

export default (props: Props) => <Pagination {...props} />;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;
