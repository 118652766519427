import React from 'react';
import Modal from './Modal';
import Slider from './Slider';
import SelectedLeadSources from './SelectedLeadSources';

export default () => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex w-full items-center justify-between">
            <div>
              <h2 className="text-base m-0 font-bold">リードソース</h2>
              <p className="m-0 text-sm">
                重要度を高める最新リードソースを選択してください。
              </p>
            </div>
            <Slider />
          </div>
          <button
            className="bg-white border border-c-border rounded h-8 text-sm px-4 cursor-pointer"
            onClick={() => setVisible(true)}>
            リードソースを選択
          </button>
          <SelectedLeadSources />
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
