import {BlockGroup, useBlockGroupQuery, useBlockGroupsQuery} from 'api';
import {Pagination} from 'components/antd';
import moment from 'moment';
import React from 'react';
import {Cell} from 'react-table';
import styled from 'styled-components';
import Table from './BlockGroupTable';
import {useHistory, useParams, useRouteMatch} from 'react-router';
import {CubeLoading} from 'components/Ui/Icon';
import {useQueryParams, NumberParam} from 'use-query-params';
import Modal from 'react-modal';
import ModalContent from './detail';
import {getTagFontColor} from 'helpers/tagColors';

export default () => {
  const history = useHistory();
  const {blockGroupId} = useParams<{blockGroupId: string}>();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    blockGroupId: NumberParam,
  });

  const {page = 1} = query;

  const {data: {blockGroup = null} = {}} = useBlockGroupQuery({
    variables: {uuid: blockGroupId},
    skip: !blockGroupId,
  });

  const {
    data: {blockGroups: {blockGroups = [], pagination = {}} = {}} = {},
    loading,
  } = useBlockGroupsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: page,
    },
  });

  const isOpen = !!blockGroup;

  const data = React.useMemo(
    () =>
      blockGroups.map((blockGroup) => {
        return {
          key: blockGroup.id,
          ...blockGroup,
        };
      }),
    [blockGroups],
  );

  let columns: any = React.useMemo(
    () => [
      {
        Header: 'グループ名',
        accessor: 'name',
        visible: true,
        Cell: ({value, row}: Cell<BlockGroup>) => (
          <BlockGroupNameBox>
            <BlockGroupName
              onClick={() => {
                history.push(
                  `/block/block_groups/${row.original.uuid}${location.search}`,
                );
              }}>
              {value}
            </BlockGroupName>

            <BlockGroupTagWrapper>
              {row.original.blockGroupTags?.map((tag) => (
                <Tag key={tag.id} color={tag.color}>
                  <span>{tag.name}</span>
                </Tag>
              ))}
            </BlockGroupTagWrapper>
          </BlockGroupNameBox>
        ),
      },
      {
        Header: 'ブロック件数',
        accessor: 'count',
        style: {
          width: '120px',
        },
        visible: true,
        Cell: ({value, row}: Cell<BlockGroup>) => (
          <Count
            onClick={() => {
              history.push(
                `/block/block_groups/${row.original.uuid}/block_members`,
              );
            }}>
            <span>{value.toLocaleString()}件</span>
          </Count>
        ),
      },
      {
        Header: '更新日',
        accessor: 'updatedAt',
        style: {
          width: '150px',
        },
        visible: true,
        Cell: ({value}: Cell<BlockGroup>) => (
          <DateBox>
            <span>{moment(value).format('YYYY年MM月DD日')}</span>
          </DateBox>
        ),
      },
    ],
    [blockGroups],
  );

  columns = React.useMemo(
    () => columns.filter((column: any) => column.visible),
    [columns],
  );

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px',
      height: '570px',
      padding: '0',
    },
  };

  const isRouteMatch = !!(
    !location.pathname.endsWith('/new') &&
    useRouteMatch({
      path: '/block/block_groups/:uuid',
      strict: true,
      exact: true,
    })
  );

  if (!blockGroups) return;

  return (
    <Container>
      <Modal
        isOpen={isOpen || isRouteMatch}
        onRequestClose={() => {
          history.push(`/block/block_groups${location.search}`);
        }}
        style={customStyles}>
        <ModalContent />
      </Modal>

      <TableWrapper>
        {loading ? (
          <Loading>
            <CubeLoading />
          </Loading>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </TableWrapper>
      <PaginationWrapper>
        <CustomPagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={20}
          onChange={(page) => setQuery({page})}
        />
      </PaginationWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 32px;
  margin-top: 30px;
`;

const TableWrapper = styled.div``;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomPagination = styled(Pagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;

const BlockGroupNameBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
`;

const BlockGroupName = styled.div`
  padding-left: 11px;
  margin-bottom: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color-1);

  &:hover {
    color: var(--primary);
  }
`;

const BlockGroupTagWrapper = styled.div`
  display: flex;
  padding-left: 11px;
`;

const Tag = styled.span<{color?: string}>`
  margin-right: 6px;
  display: flex;
  padding: 1px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;

  span {
    font-size: 9px;
    font-weight: bold;
    line-height: 13px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;

const Count = styled.div`
  text-align: right;

  span {
    cursor: pointer;
    margin-right: 39px;
    font-weight: bold;
    font-size: 14px;
    color: var(--primary);
  }
`;

const DateBox = styled.div`
  width: 80px;
  span {
    font-size: 10px;
    line-height: 14px;
    color: var(--text-color-2);
  }
`;

const Loading = styled.div`
  margin-top: 30px;
  text-align: center;

  svg {
    display: inline-block;
  }
`;
