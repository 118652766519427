import React from 'react';
import styled from 'styled-components';
import {Input} from 'components/antd';

interface Padding {
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
}

interface Props {
  values: Padding;
  onChange: (values: Padding) => void;
}

export default ({values, onChange}: Props) => {
  const [padding, setPadding] = React.useState<Padding>(values);

  React.useEffect(() => {
    onChange(padding);
  }, [padding]);

  return (
    <TwoColumn>
      <div>
        <Label>上</Label>
        <Input
          value={padding.paddingTop}
          type="number"
          onChange={(e) =>
            setPadding({...padding, paddingTop: Number(e.target.value)})
          }
        />
      </div>
      <div>
        <Label>下</Label>
        <Input
          value={padding.paddingBottom}
          type="number"
          onChange={(e) =>
            setPadding({...padding, paddingBottom: Number(e.target.value)})
          }
        />
      </div>
      <div>
        <Label>左</Label>
        <Input
          value={padding.paddingLeft}
          type="number"
          onChange={(e) =>
            setPadding({...padding, paddingLeft: Number(e.target.value)})
          }
        />
      </div>
      <div>
        <Label>右</Label>
        <Input
          value={padding.paddingRight}
          type="number"
          onChange={(e) =>
            setPadding({...padding, paddingRight: Number(e.target.value)})
          }
        />
      </div>
    </TwoColumn>
  );
};

const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: var(--text-color-2);
  font-weight: 500;
`;
